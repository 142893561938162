import { Button } from '@mui/material';
import { NotificationColors } from '../../constants';

export const CustomButton = ({
  onClick,
  text,
  type,
  disabled = false,
  size = 'large',
  width = '10rem',
  height = 40,
  variant = 'contained',
  fontWeight = 600,
  id = 'customButton'
}) => {
  let bgcolor = NotificationColors.success.bgcolor;
  let color = NotificationColors.success.color;

  if (type === 'error') {
    bgcolor = NotificationColors.error.bgcolor;
    color = NotificationColors.error.color;
  }

  if (type === 'cancel') {
    bgcolor = NotificationColors.cancel.bgcolor;
    color = NotificationColors.cancel.color;
  }

  if (type === 'neutral') {
    bgcolor = NotificationColors.dark.bgcolor;
    color = NotificationColors.dark.color;
  }

  return (
    <Button
      size={size}
      onClick={() => onClick()}
      variant={variant}
      disabled={disabled}
      id={id}
      sx={{
        width,
        height,
        bgcolor,
        color,
        fontWeight,
        '&.MuiButtonBase-root:hover': {
          bgcolor: bgcolor,
        },
      }}
    >
      {text}
    </Button>
  );
};
