import * as React from 'react';
import {
  Slide,
  Dialog,
  Grid,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseButton from '../closeButton';
import { darkTextColor } from '../../constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const WarningDialog = (props) => {
  const {
    title,
    open,
    setOpen,
    children,
    continueWarning,
    error = false,
    message = '',
    closeButtonText = 'Close',
    continueButtonText = 'Continue',
    referralLoading,
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {}}
        PaperProps={{
          sx: {
            borderRadius: '12px !important',
            p: 2,
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: !error && 1, py: error && 0.5, flexWrap: 'nowrap' }}
        >
          <Typography variant="h5" color={darkTextColor}>
            {title}
          </Typography>
          <IconButton onClick={() => handleClose()} color="secondary">
            <CloseButton />
          </IconButton>
        </Grid>
        <>
          <DialogContent sx={{ minWidth: '20vw', py: 1 }}>
            <Typography
              color={darkTextColor}
              gutterBottom
              variant="h6"
              sx={{ fontWeight: 400 }}
            >
              {message}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              size="large"
              variant="contained"
              width="100%"
              fullWidth
              sx={{
                height: 40,
                bgcolor: 'rgba(47, 47, 47, 0.14) !important',
                color: '#2F2F2F',
              }}
              onClick={() => setOpen(false)}
            >
              {closeButtonText}
            </Button>
            <Button
              color="error"
              size="large"
              variant="contained"
              width="100%"
              fullWidth
              sx={{ height: 40 }}
              disabled={referralLoading}
              onClick={() => continueWarning()}
            >
              {continueButtonText}
            </Button>
          </DialogActions>
        </>
      </Dialog>
    </div>
  );
};

export default WarningDialog;
