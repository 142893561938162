import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid, Typography, Button, InputAdornment } from '@mui/material';
import CustomInputField from '../../components/customInputField';
import { useRef, useState, useContext } from 'react';
import Users from '../../services/users';
import UserContext from '../../contexts/user/context';
import {
  addPaddingZero,
  darkTextColor,
  RESPONSE_STATUS,
} from '../../constants';
import { LoadingButton } from '@mui/lab';
import VerificationCodeField from '../../components/verificationCodeField';
import AlertDialog from '../../components/alertDialog';

export const UpdateEmail = ({
  user,
  setAnchorEmail,
  anchorEmail,
  setOpenDialog,
}) => {
  const emailRef = useRef();
  const { token, setUser } = useContext(UserContext);

  const [sendCodeLoadingEmail, setSendCodeLoadingEmail] = useState(false);
  const [verificationTokenEmail, setVerificationTokenEmail] = useState();
  const [errMessageEmail, setErrMessageEmail] = useState();
  const [enableEmailLoading, setEnableEmailLoading] = useState();
  const [disableEmail, setDisabledEmail] = useState(false);
  const [enableEmailTimer, setEnableEmailTimer] = useState();

  const [emailError, setEmailError] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);

  const authtimerRef = useRef();
  const handleCoutDownEnableEmail = (fromStart = false) => {
    if (authtimerRef?.current) {
      clearInterval(authtimerRef.current);
    }
    if (fromStart) {
      setDisabledEmail(true);
    }
    setEnableEmailTimer(600);
  };

  const updateEmail = async () => {
    if (verificationTokenEmail?.length === 6) {
      try {
        setEnableEmailLoading(true);
        const response = await Users.verifyEmailToken(
          token,
          verificationTokenEmail,
        );

        if (response.status === RESPONSE_STATUS.error) {
          setErrMessageEmail(response.message);
        }
        if (response.status === RESPONSE_STATUS.success) {
          setUser(response.body);
          setAnchorEmail(false);
          setVerificationTokenEmail();
        }
      } catch (e) {
        setErrMessageEmail(e.message);
        console.log(e);
      } finally {
        setEnableEmailLoading(false);
      }
    }
  };

  Yup.addMethod(Yup.string, 'sameEmail', function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
      const { path, createError } = this;

      return (
        (value && value !== user.email) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  useEffect(() => {
    authtimerRef.current = setTimeout(() => {
      if (enableEmailTimer) {
        setEnableEmailTimer(enableEmailTimer - 1);
      }
    }, 1000);
  }, [enableEmailTimer]);

  const formikEmail = useFormik({
    initialValues: {
      email: emailRef.current || '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Work Email must be valid')
        .required('Work Email is required')
        .sameEmail('Work Email must be different'),
    }),
    onSubmit: async (values) => {
      try {
        emailRef.current = values.email.toLowerCase();
        setSendCodeLoadingEmail(true);
        const query = `?email=${values.email.toLowerCase()}`;
        const response = await Users.changeEmailToken(token, query);

        if (response.status === RESPONSE_STATUS.success) {
          handleCoutDownEnableEmail(true);
        }
        if (response.status === RESPONSE_STATUS.error) {
          setEmailError(response.message);
          setIsEmailError(true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setSendCodeLoadingEmail(false);
      }
    },
  });

  useEffect(() => {
    if (errMessageEmail && verificationTokenEmail.length < 5) {
      setErrMessageEmail();
    }
  }, [verificationTokenEmail]);
  if (!anchorEmail) {
    return <></>;
  }
  return (
    <>
      <AlertDialog
        title="Error"
        error={true}
        open={isEmailError}
        message={emailError}
        setOpen={() => setIsEmailError(false)}
      />
      <Typography
        sx={{ my: 2 }}
        color={darkTextColor}
        gutterBottom
        variant="body1"
      >
        {`You’ll receive a 6-digit verification code at ${user.email}.`}
      </Typography>
      <Grid item sx={{ flexGrow: 1, my: 1 }}>
        <Typography color={darkTextColor} gutterBottom variant="body1">
          New work email address
        </Typography>
        <CustomInputField
          error={Boolean(formikEmail.touched.email && formikEmail.errors.email)}
          label="email"
          fullWidth
          width="100%"
          disabled={disableEmail}
          name="email"
          onChange={formikEmail.handleChange}
          value={formikEmail.values.email}
          // onBlur={formikEmail.handleBlur}
          endAdornment={
            <InputAdornment
              position="end"
              sx={{ position: 'absolute', right: 5 }}
            >
              {enableEmailTimer && enableEmailTimer > 570 ? (
                <Button
                  onMouseDown={(event) => event.preventDefault()}
                  disabled
                  sx={{
                    color: 'grey !important',
                    height: '30px',
                  }}
                >
                  {enableEmailTimer ? 'Send code' : ''}
                </Button>
              ) : (
                <LoadingButton
                  aria-label="toggle password visibility"
                  onClick={() => formikEmail.handleSubmit()}
                  loading={sendCodeLoadingEmail}
                  loadingPosition="end"
                  onMouseDown={(e) => e.preventDefault()}
                  sx={{
                    height: '30px',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.01)',
                      boxShadow: 'none',
                    },
                    '& 	.MuiLoadingButton-loadingIndicatorEnd': {
                      color: 'grey !important',
                    },
                  }}
                >
                  {sendCodeLoadingEmail
                    ? ''
                    : `${enableEmailTimer ? 'Resend code' : 'Send code'}`}
                </LoadingButton>
              )}
            </InputAdornment>
          }
        />
        {Boolean(formikEmail.touched.email && formikEmail.errors.email) && (
          <Typography
            color="error"
            gutterBottom
            variant="body2"
            sx={{
              width: '90%',
            }}
          >
            {formikEmail.touched.email && formikEmail.errors.email}
          </Typography>
        )}
        {enableEmailTimer ? (
          <Typography color={darkTextColor} sx={{ mt: 1 }} variant="body2">
            Expires in{' '}
            {`${addPaddingZero(
              Math.floor(enableEmailTimer / 60),
            )}:${addPaddingZero(
              enableEmailTimer - Math.floor(enableEmailTimer / 60) * 60,
            )}`}
          </Typography>
        ) : (
          <></>
        )}
      </Grid>
      <Typography color={darkTextColor} sx={{ mt: 4 }} variant="body1">
        Verification code
      </Typography>
      <VerificationCodeField
        verificationToken={verificationTokenEmail}
        setVerificationToken={setVerificationTokenEmail}
        loading={enableEmailLoading}
        errMessageAuth={errMessageEmail}
      />
      {errMessageEmail ? (
        <Typography sx={{ my: 2 }} color="error" gutterBottom variant="body1">
          {errMessageEmail}
        </Typography>
      ) : (
        <></>
      )}
      <Grid
        container
        gap={1.5}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          flexWrap: 'nowrap',
          p: 2,
          mt: 1,
          position: 'absolute',
          bottom: '0px',
          right: 0,
          height: 'auto',
          width: '100%',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Button
          color="error"
          size="small"
          onClick={() => {
            setOpenDialog(true);
          }}
          variant="contained"
          sx={{
            height: 45,
            flexGrow: 1,
            bgcolor: 'rgba(246,93,94, 0.2) !important',
            color: 'rgb(246,93,94)',
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          size="large"
          loading={enableEmailLoading}
          loadingPosition="end"
          disabled={verificationTokenEmail?.length < 6}
          onClick={() => updateEmail()}
          variant="contained"
          sx={{
            height: 45,
            flexGrow: 3,
            '&:disabled': {
              color: 'black !important',
            },
          }}
        >
          {verificationTokenEmail?.length < 6
            ? `${6 - verificationTokenEmail?.length} digits left`
            : 'Update work email'}
        </LoadingButton>
      </Grid>
    </>
  );
};
