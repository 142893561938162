import {
  Grid,
  Typography,
  Button,
  InputAdornment,
  Stack,
  IconButton,
  Radio,
  DialogContent,
  DialogActions,
  Card,
} from '@mui/material';
import CustomInputField from '../../components/customInputField';
import {
  addPaddingZero,
  darkTextColor,
  RESPONSE_STATUS,
} from '../../constants';
import { useState, useContext, useRef } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AppDrawer from '../../components/appDrawer';
import VerificationCodeField from '../../components/verificationCodeField';
import Users from '../../services/users';
import UserContext from '../../contexts/user/context';
import { LoadingButton } from '@mui/lab';
import { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AlertDialog from '../../components/alertDialog';
import { SmsVerification } from './smsVerfication';
import { EmailVerification } from './emailVerification';
import CancelPrompt from './cancelPrompt';
import { UpdateEmail } from './updateEmail';
import { CircleButton } from '../EditProfile/circleButton';
import { CustomTextFieldNoBorders } from '../../components/customTextField';
import fileDownload from 'js-file-download';
import { FooterVerifiedUser } from '../../components/footer';
const AccountDetails = ({ user, formik, passwordError }) => {
  const [twoFactorType, setTwoFactorType] = useState();
  const { token, setUser } = useContext(UserContext);

  const [anchor, setAnchor] = useState(false);
  const [enableAuthTimer, setEnableAuthTimer] = useState();
  const [errMessageAuth, setErrMessageAuth] = useState();

  const [enableAuthLoading, setEnableAuthLoading] = useState();
  const authtimerRef = useRef();

  const [anchorEmail, setAnchorEmail] = useState(false);

  const [openTurnOffAuth, setOpenTurnOffAuth] = useState();
  const [password, setPassword] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [showPasswordReqs, setShowPasswordReqs] = useState(true);

  const [values, setValues] = useState({
    currentPassword: false,
    showPassword: false,
    showRepeatPassword: false,
    alertPassword: false,
  });

  const handleClickShowAlertPassword = () => {
    setValues({
      ...values,
      alertPassword: !values.alertPassword,
    });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowCurrentPassword = () => {
    setValues({
      ...values,
      currentPassword: !values.currentPassword,
    });
  };

  const handleClickShowRepeatPassword = () => {
    setValues({
      ...values,
      showRepeatPassword: !values.showRepeatPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const disableTwoFactor = async () => {
    if (!password) {
      setErrMessageAuth('Please enter your password');
      return;
    }

    try {
      setEnableAuthLoading(true);
      const response = await Users.disableTwoFactor(token, password);
      if (response.status === RESPONSE_STATUS.error) {
        setErrMessageAuth(response.message);
      }
      if (response.status === RESPONSE_STATUS.success) {
        setOpenTurnOffAuth(false);
        setPassword('');
        setUser(response.body);
      }
    } catch (e) {
      setErrMessageAuth(e.message);
    } finally {
      setEnableAuthLoading(false);
    }
  };

  useEffect(() => {
    authtimerRef.current = setTimeout(() => {
      if (enableAuthTimer) {
        setEnableAuthTimer(enableAuthTimer - 1);
      }
    }, 1000);
  }, [enableAuthTimer]);

  useEffect(() => {
    if (formik.values.password && !formik.errors.password) {
      setShowPasswordReqs(false);
    } else {
      setShowPasswordReqs(true);
    }
  }, [formik]);

  const handleCancel = () => {
    setOpenDialog(false);
    setAnchor(false);
    setTwoFactorType();
    setAnchorEmail(false);
  };

  const handleClose = () => {
    setOpenTurnOffAuth(false);
    setErrMessageAuth('');
    setPassword();
  };

  return (
    <>
      <FooterVerifiedUser />
      <CancelPrompt
        setOpenDialog={setOpenDialog}
        openDialog={openDialog}
        handleCancel={handleCancel}
      />
      <AlertDialog
        title="You’re about to turn off
        two-factor authentication"
        open={openTurnOffAuth}
        setOpen={() => handleClose()}
      >
        <DialogContent sx={{ mb: 3 }}>
          <Typography color={darkTextColor} gutterBottom variant="body1">
            Password
          </Typography>
          <CustomInputField
            label="password"
            fullWidth
            width="100%"
            name="password"
            onChange={(event) => setPassword(event.target.value)}
            type={values.alertPassword ? 'text' : 'password'}
            value={password}
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ position: 'absolute', right: 5 }}
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowAlertPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errMessageAuth && (
            <Typography
              color="error"
              gutterBottom
              variant="body2"
              sx={{
                width: '90%',
              }}
            >
              {errMessageAuth}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="contained"
            sx={{
              flex: 1,
              height: 40,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>

          <LoadingButton
            color="error"
            size="large"
            // loading={loading}
            loadingPosition="end"
            disabled={password?.length < 8}
            onClick={() => disableTwoFactor()}
            variant="contained"
            sx={{
              height: 45,
              flexGrow: 3,
              '&:disabled': {
                color: 'black !important',
              },
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </AlertDialog>
      <AppDrawer
        title="Two-factor authentication"
        anchor={anchor}
        setAnchor={() => setOpenDialog(true)}
      >
        <Typography
          color={darkTextColor}
          variant="h6"
          sx={{ fontWeight: 600, mt: 2 }}
        >
          Choose verification method
        </Typography>
        <Grid
          gap={2}
          container
          sx={{ flexGrow: 1, my: 0 }}
          direction="row"
          spacing={2}
        >
          <Grid item sx={{ flexGrow: 1 }}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              onClick={() => setTwoFactorType('Phone')}
              sx={{
                p: 1,
                px: 2,
                my: 0.5,
                height: '55px',
                borderRadius: '4px',
                cursor: 'pointer',
                background:
                  twoFactorType === 'Phone'
                    ? 'rgba(71, 187, 146, 0.12)'
                    : '#FFFFFF',
                border:
                  twoFactorType === 'Phone'
                    ? '1px solid #47BB92'
                    : '1px solid #E2E2E2',
              }}
            >
              <Typography
                variant="body1"
                color={twoFactorType === 'Phone' ? '#47BB92' : darkTextColor}
              >
                SMS
              </Typography>
              <Radio
                sx={{ p: 0 }}
                onChange={() => setTwoFactorType('Phone')}
                checked={twoFactorType === 'Phone'}
              />
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              onClick={() => setTwoFactorType('Email')}
              sx={{
                p: 1,
                px: 2,
                height: '55px',
                borderRadius: '4px',
                cursor: 'pointer',
                my: 0.5,
                background:
                  twoFactorType === 'Email'
                    ? 'rgba(71, 187, 146, 0.12)'
                    : '#FFFFFF',
                border:
                  twoFactorType === 'Email'
                    ? '1px solid #47BB92'
                    : '1px solid #E2E2E2',
              }}
            >
              <Typography
                variant="body1"
                color={twoFactorType === 'Email' ? '#47BB92' : darkTextColor}
              >
                Email
              </Typography>
              <Radio
                sx={{ p: 0 }}
                onChange={() => setTwoFactorType('Email')}
                checked={twoFactorType === 'Email'}
              />
            </Grid>
          </Grid>
        </Grid>

        {twoFactorType === 'Email' ? (
          <EmailVerification
            setOpenDialog={setOpenDialog}
            user={user}
            twoFactorType={twoFactorType}
            setAnchor={setAnchor}
            setTwoFactorType={setTwoFactorType}
          />
        ) : (
          <></>
        )}

        {twoFactorType === 'Phone' ? (
          <SmsVerification
            setOpenDialog={setOpenDialog}
            twoFactorType={twoFactorType}
            setAnchor={setAnchor}
            setTwoFactorType={setTwoFactorType}
          />
        ) : (
          <></>
        )}
      </AppDrawer>
      <AppDrawer
        title="Update work email"
        anchor={anchorEmail}
        setAnchor={() => handleCancel()}
      >
        <UpdateEmail
          user={user}
          setAnchorEmail={setAnchorEmail}
          anchorEmail={anchorEmail}
          setOpenDialog={() => handleCancel()}
        />
      </AppDrawer>
      <Stack direction="column">
        <Typography
          color={darkTextColor}
          variant="h5"
          sx={{ fontWeight: 600, mb: 2 }}
        >
          Account information
        </Typography>

        <Grid
          maxWidth={true}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{
            bgcolor: '#FFFFFF',
            border: '1px solid #E2E2E2',
            borderRadius: '4px',
            mb: 2,
          }}
          gap={0}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ py: 2, width: '100%' }}
            gap={2}
          >
            <Stack sx={{ width: '100%', flex: 5 }}>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderBottom: '1px solid #ced4da',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Stack direction="column" sx={{ minWidth: '20%' }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontSize="14px"
                      fontWeight="600"
                    >
                      Work email
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{ width: '100%' }}
                    direction="row"
                    flexWrap="wrap"
                    gap={1}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        border: '1px solid #E2E2E2',
                        borderRadius: '4px',
                        width: '100%',
                      }}
                    >
                      <CustomTextFieldNoBorders
                        fullWidth
                        width="100%"
                        name="email"
                        disabled={true}
                        type="email"
                        value={user.email}
                      />
                      <CircleButton
                        onClick={() => setAnchorEmail(true)}
                        text="Update"
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderRadius: 0,
                  boxShadow: 'none',
                  borderBottom: '1px solid #ced4da',
                }}
              >
                <Stack direction="column">
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Current password
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="column"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <CustomInputField
                        error={Boolean(
                          formik.touched.currentPassword &&
                            formik.errors.currentPassword,
                        )}
                        label="currentPassword"
                        fullWidth
                        width="100%"
                        name="currentPassword"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type={values.currentPassword ? 'text' : 'password'}
                        value={formik.values.currentPassword}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{ position: 'absolute', right: 5 }}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowCurrentPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(
                        formik.touched.currentPassword &&
                          formik.errors.currentPassword,
                      ) && (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{
                            width: '90%',
                          }}
                        >
                          {formik.touched.currentPassword &&
                            formik.errors.currentPassword}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        New Password
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="column"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <CustomInputField
                        error={Boolean(
                          formik.touched.password && formik.errors.password,
                        )}
                        label="Password"
                        fullWidth
                        width="100%"
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type={values.showPassword ? 'text' : 'password'}
                        value={formik.values.password}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{ position: 'absolute', right: 5 }}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {showPasswordReqs && (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={
                            {
                              // position: 'absolute !important',
                              // width: '50%',
                            }
                          }
                        >
                          Password must contain at least 8 characters, at least
                          one uppercase, at least one lowercase, at least one
                          number, and one special case character.
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        sx={{ width: '50%' }}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Repeat new Password
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="column"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <CustomInputField
                        error={Boolean(
                          formik.touched.reEnterPassword &&
                            formik.errors.reEnterPassword,
                        )}
                        endAdornment={
                          <InputAdornment
                            position="end"
                            sx={{ position: 'absolute', right: 5 }}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRepeatPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showRepeatPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        variant="standard"
                        label="RepeatPassword"
                        fullWidth
                        width="100%"
                        name="reEnterPassword"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type={values.showRepeatPassword ? 'text' : 'password'}
                        value={formik.values.reEnterPassword}
                      ></CustomInputField>
                      {Boolean(
                        (formik.touched.reEnterPassword &&
                          formik.errors.reEnterPassword) ||
                          passwordError,
                      ) && (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{
                            width: '90%',
                          }}
                        >
                          {(formik.touched.reEnterPassword &&
                            formik.errors.reEnterPassword) ||
                            passwordError}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1, pt: 3 }}>
                  <Stack direction="column" sx={{ minWidth: '20%' }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontSize="14px"
                      fontWeight="600"
                    >
                      Business Associate Agreement
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{ width: '100%' }}
                    direction="row"
                    flexWrap="wrap"
                    gap={1}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        borderRadius: '4px',
                        width: '100%',
                      }}
                    >
                      <Typography color={darkTextColor}>
                        Use our form if you are interested in engaging in a
                        business associate agreement
                      </Typography>
                      <DownloadButton />
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        </Grid>

        <Typography
          color={darkTextColor}
          variant="h5"
          sx={{ fontWeight: 600, mb: 2 }}
        >
          Account security
        </Typography>
        <Grid
          maxWidth={true}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{
            bgcolor: '#FFFFFF',
            border: '1px solid #E2E2E2',
            borderRadius: '4px',
            mb: '60px',
          }}
          gap={0}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{ py: 2, width: '100%' }}
            gap={2}
          >
            <Stack sx={{ width: '100%', flex: 5 }}>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Stack direction="column" sx={{ minWidth: '20%' }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontSize="14px"
                      fontWeight="600"
                    >
                      Two-factor authentication
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{ width: '100%' }}
                    direction="row"
                    flexWrap="wrap"
                    gap={1}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{
                        borderRadius: '4px',
                        width: '100%',
                      }}
                    >
                      <Typography
                        color={darkTextColor}
                        variant="body2"
                        fontSize="14px"
                        sx={{ width: '60%' }}
                      >
                        Once enabled, you’ll be required to give two types of
                        verification when you log into medLynks.
                      </Typography>
                      {user.twoAuth ? (
                        <Button
                          color="primary"
                          size="small"
                          onClick={() => setOpenTurnOffAuth(true)}
                          variant="contained"
                          sx={{
                            height: 45,
                            flexGrow: 1,
                            bgcolor: 'transparent',
                            border: '2px solid rgba(246,93,94, 0.2)',
                            color: 'rgb(246,93,94)',
                            '&:hover': {
                              bgcolor: 'transparent',
                              border: '2px solid rgba(246,93,94, 0.2)',
                              color: 'rgb(246,93,94)',
                            },
                          }}
                        >
                          Turn off
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          size="large"
                          className="custom-button"
                          onClick={() => setAnchor(true)}
                          variant="contained"
                          fullWidth
                          sx={{
                            height: 45,
                            mt: 2,
                            flex: 2,
                            py: { sm: 3, md: 1 },
                            bgcolor: 'transparent',
                            border: '1px solid #2F2F2F',
                            color: '#2F2F2F',
                            '&:hover': {
                              color: '#2F2F2F',
                              bgcolor: 'transparent',
                            },
                          }}
                        >
                          Set up primary method
                        </Button>
                      )}
                      {/* <CircleButton
                        onClick={() => setAnchorEmail(true)}
                        text="Update"
                      /> */}
                    </Stack>
                  </Stack>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    </>
  );
};

const DownloadButton = () => {
  const fileName = 'Medlynks.BUSINESS_ASSOCIATE_AGREEMENT.pdf';

  function handleDownload() {
    const currentUrl = window.location.hostname;
    const fileUrl = `https://medlynks.com/Medlynks.BUSINESS_ASSOCIATE_AGREEMENT.pdf`; // Replace with your own file path

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, fileName);
      });
  }
  return (
    <Button
      size="large"
      variant="contained"
      onClick={() => handleDownload()}
      sx={{
        height: 40,
        background:
          'linear-gradient(0deg, #1665D8, #1665D8), rgba(33, 38, 52, 0.14)',
        color: '#FFFFFF',
        '&:hover': {
          background:
            'linear-gradient(0deg, #1665D8, #1665D8), rgba(33, 38, 52, 0.14)',
        },
      }}
    >
      Download
    </Button>
  );
};

export default AccountDetails;
