import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Doctors from '../../services/doctors';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Link,
  Typography,
  Grid,
  Divider,
  CircularProgress,
  Stack,
} from '@mui/material';
import CustomInputField from '../../components/customInputField';
import Logo from '../../Icons/logo';
import CloseButton from '../../components/closeButton';
import { UnRegistered } from '../../components/doctorSearchCard/unRegistered';
import { Registered } from '../../components/doctorSearchCard/registered';
import { EmptyIcon } from '../../Icons/emptyIcon';
import { CapitalizeFirstLetter, darkTextColor } from '../../constants';
import UserContext from '../../contexts/user/context';
import { LoadingButton } from '@mui/lab';
import { useRef } from 'react';
const stringSimilarity = require('string-similarity');

const DoctorsSearch = () => {
  let { search } = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(search);
  const firstName = query.get('firstName');
  const lastName = query.get('lastName');
  const initial = query.get('initial');
  const { serachDoctorsResult, setSerachDoctorsResult } =
    useContext(UserContext);
  const initialRef = useRef(initial ? 0 : 1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const formik = useFormik({
    initialValues: {
      firstName: CapitalizeFirstLetter(firstName),
      lastName: CapitalizeFirstLetter(lastName),
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(255, 'First Name must be at most 255 characters')
        .required('First Name is required'),
      lastName: Yup.string()
        .max(255, 'Last Name must be at most 255 characters')
        .required('Last Name is required'),
    }),
    onSubmit: (values) => {
      const { firstName, lastName } = values;
      getDoctorsData(firstName, lastName);
    },
  });
  const getDoctorsData = async (first, last) => {
    try {
      navigate(`/doctors?firstName=${first}&lastName=${last}`, {
        replace: true,
      });
      setLoading(true);
      const response = await Doctors.get(
        `?firstName=${first}&lastName=${last}`,
      );
      if (response) {
        if (
          !response?.body?.registeredDoctors?.length &&
          !response?.body?.doctors?.length
        ) {
          navigate(`/sign-up?firstName=${first}&lastName=${last}`);
          return;
        }
        const sortedAsc = response?.body?.doctors
          ?.sort(
            (objA, objB) =>
              Number(new Date(objB.max_load_date)) -
              Number(new Date(objA.max_load_date)),
          )
          ?.filter(
            (doc) =>
              !doc?.userRegistered?.email_verified ||
              !doc?.userRegistered?.doctor_verified,
          );

        const filtered = sortedAsc.filter(
          (doctor) => doctor.max_load_date === sortedAsc[0].max_load_date,
        );

        const resArr = [];
        filtered.filter((item) => {
          var i = resArr.findIndex(
            (x) =>
              x.health_center === item.health_center &&
              x.specialty === item.specialty &&
              x.first_name === item.first_name,
          );
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });
        const tempArr = [...response?.body?.registeredDoctors, ...resArr]
          .map((doctor) => {
            if (doctor.doctor_verified) {
              doctor['similarity'] = stringSimilarity.compareTwoStrings(
                `${formik.values.firstName?.toLocaleLowerCase()}${formik.values.lastName?.toLocaleLowerCase()}`,
                `${doctor.firstName?.toLocaleLowerCase()}${doctor.lastName?.toLocaleLowerCase()}`,
              );
            } else {
              doctor['similarity'] = stringSimilarity.compareTwoStrings(
                `${formik.values.firstName?.toLocaleLowerCase()}${formik.values.lastName?.toLocaleLowerCase()}`,
                `${doctor.first_name?.toLocaleLowerCase()}${doctor.last_name?.toLocaleLowerCase()}`,
              );
            }
            return doctor;
          })
          .sort((a, b) => b.similarity - a.similarity);
        setData(tempArr);
        setSerachDoctorsResult(tempArr);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData(serachDoctorsResult);
    setLoading(false);
  }, []);
  useEffect(() => {
    if (firstName && lastName && initialRef.current) {
      getDoctorsData(firstName, lastName);
    }
    initialRef.current += 1;
  }, [firstName, lastName]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          minWidth: '100vw',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            background: '#FFFFFF',
            flexWrap: 'nowrap',
            position: 'fixed',
            zIndex: '10000',
            px: 10,
            py: 3,
          }}
        >
          <div onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
            <Logo />
          </div>
        </Grid>
        <Grid
          container
          justifyContent="center"
          direction="column"
          sx={{ position: 'relative' }}
        >
          <CloseButton
            styles={{
              position: 'fixed',
              top: '20px',
              right: '40px',
              cursor: 'pointer',
              marginTop: '6em',
            }}
            handleClick={() => navigate('/')}
          />
          <form
            onSubmit={formik.handleSubmit}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '6em',
            }}
          >
            <Grid
              maxWidth={true}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="end"
              gap={1}
              sx={{ py: 3, width: { sm: '75vw', md: '55vw' } }}
            >
              <Stack
                sx={{ width: '100%' }}
                direction="row"
                justifyContent="space-between"
                alignItems="end"
                gap={4}
              >
                <Grid item sx={{ flex: 2 }}>
                  <Typography
                    color={darkTextColor}
                    gutterBottom
                    variant="body1"
                  >
                    First Name
                  </Typography>
                  <CustomInputField
                    error={Boolean(
                      formik.touched.firstName && formik.errors.firstName,
                    )}
                    label="First Name"
                    fullWidth
                    width="100%"
                    name="firstName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="firstName"
                    value={formik.values.firstName}
                  />
                  {Boolean(
                    formik.touched.firstName && formik.errors.firstName,
                  ) && (
                    <Typography
                      color="error"
                      gutterBottom
                      variant="body2"
                      sx={{ position: 'absolute !important' }}
                    >
                      {formik.touched.firstName && formik.errors.firstName}
                    </Typography>
                  )}
                </Grid>
                <Grid item sx={{ flex: 2 }}>
                  <Typography
                    color={darkTextColor}
                    gutterBottom
                    variant="body1"
                  >
                    Last Name
                  </Typography>
                  <CustomInputField
                    error={Boolean(
                      formik.touched.lastName && formik.errors.lastName,
                    )}
                    label="Last Name"
                    width="100%"
                    fullWidth
                    name="lastName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.lastName}
                  />
                  {Boolean(
                    formik.touched.lastName && formik.errors.lastName,
                  ) && (
                    <Typography
                      color="error"
                      gutterBottom
                      variant="body2"
                      sx={{ position: 'absolute !important' }}
                    >
                      {formik.touched.lastName && formik.errors.lastName}
                    </Typography>
                  )}
                </Grid>
                <Grid sx={{ flex: 1 }}>
                  <LoadingButton
                    color="primary"
                    size="large"
                    loadingPosition="end"
                    type="submit"
                    variant="contained"
                    sx={{ width: '100%' }}
                    loading={loading}
                  >
                    Search
                  </LoadingButton>
                </Grid>
              </Stack>
            </Grid>
          </form>
          <Grid container justifyContent="center" direction="row">
            <Grid
              container
              direction="column"
              sx={{ py: 1, width: { sm: '75vw', md: '55vw' } }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="end"
              >
                <Typography color={darkTextColor} variant="h5">
                  Select your profile
                </Typography>
                <Link
                  color="textSecondary"
                  to="/register"
                  variant="subtitle2"
                  underline="hover"
                  onClick={() => navigate('/sign-up')}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  Don't see your name?
                </Link>
              </Grid>
              <Divider
                sx={{ my: 2, borderBottomWidth: 'medium' }}
                variant="fullWidth"
              />
              {loading ? (
                <Grid
                  sx={{ width: '100%', height: '60vh' }}
                  alignItems="center"
                  justifyContent="center"
                  container
                  direction="column"
                >
                  <CircularProgress color="primary" />;
                </Grid>
              ) : (
                <Grid>
                  {data?.length ? (
                    data.map((doctor, i) => {
                      if (doctor.load_date) {
                        const doctorInfo = doctor?.doctorDetail[0];
                        const location =
                          doctor?.doctorLocation.find(
                            (loc) => loc.primary === 'Y',
                          ) || doctor?.doctorLocation[0];
                        return (
                          <React.Fragment key={`${doctor.userId}`}>
                            <UnRegistered
                              curretDoc={doctorInfo}
                              doctor={doctor}
                              location={location}
                              mapIndex={i}
                            />
                            {/* {doctorInfo &&
                                doctorInfo.map((curretDoc) => (
                                ))} */}
                          </React.Fragment>
                        );
                      }
                      if (doctor.doctor_verified) {
                        return <Registered doctor={doctor} />;
                      }
                    })
                  ) : (
                    <Grid
                      sx={{ width: '100%', height: '60vh' }}
                      alignItems="center"
                      justifyContent="center"
                      container
                      direction="column"
                    >
                      <EmptyIcon />
                      <Typography variant="body1" color={darkTextColor}>
                        No results found.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DoctorsSearch;
