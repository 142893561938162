const InsuranceIcon = () => (
  <svg
    width="15.5"
    height="20"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.56">
      <path
        d="M4.60224 1.00038C4.41492 1.01801 4.27185 1.17578 4.27276 1.36399V2.0912C4.27276 2.56123 4.12919 2.80474 3.95455 2.96045C3.77991 3.11617 3.5439 3.18202 3.36367 3.18202H1.3636V3.18215C1.16284 3.18215 1 3.34486 1 3.54575V8.09121C1 13.5101 4.26202 17 7.36365 17C10.4653 17 13.7273 13.5097 13.7273 8.09121V3.54575C13.7273 3.34486 13.5645 3.18215 13.3637 3.18215H11.3636C11.1834 3.18215 10.9474 3.11632 10.7727 2.96058C10.5981 2.80484 10.4545 2.5612 10.4545 2.09132V1.36399C10.4545 1.16309 10.2917 1.00038 10.0909 1.00038H4.63638C4.62496 0.999873 4.61368 0.999873 4.60226 1.00038L4.60224 1.00038ZM4.99997 1.72759H9.72725V2.0912C9.72725 2.72571 9.95871 3.20487 10.2897 3.50023C10.6207 3.79558 11.0211 3.90938 11.3636 3.90938H12.9999V8.09121C12.9999 13.2175 9.90251 16.2727 7.36348 16.2727C4.82445 16.2727 1.72711 13.2175 1.72711 8.09121V3.90938H3.36341C3.70597 3.90938 4.10623 3.79562 4.43724 3.50023C4.76851 3.20485 4.99997 2.72581 4.99997 2.0912V1.72759ZM6.42043 5.90942C6.23311 5.92705 6.09004 6.08482 6.09095 6.27303V7.72761H4.63637C4.4356 7.72761 4.27276 7.89032 4.27276 8.09121V9.9094V9.90927C4.27276 10.1102 4.43561 10.2729 4.63637 10.273H6.09095V11.7276V11.7275C6.09095 11.9282 6.25379 12.0911 6.45455 12.0911H8.27274C8.4735 12.0911 8.63634 11.9282 8.63634 11.7275V10.2729H10.0909V10.273C10.2917 10.2729 10.4545 10.1102 10.4545 9.90927V8.09108V8.09121C10.4545 7.89032 10.2917 7.72761 10.0909 7.72761H8.63634V6.27303C8.63634 6.07226 8.4735 5.90942 8.27274 5.90942H6.45455C6.44314 5.90891 6.43185 5.90891 6.42043 5.90942H6.42043ZM6.81816 6.63663H7.90913V8.09121C7.90913 8.29198 8.07198 8.45482 8.27274 8.45482H9.72732V9.54566H8.27274C8.07197 9.54566 7.90913 9.70851 7.90913 9.90927V11.3638H6.81816V9.90927C6.81816 9.7085 6.65531 9.54566 6.45455 9.54566H4.99997V8.45482H6.45455C6.65532 8.45482 6.81816 8.29197 6.81816 8.09121V6.63663Z"
        fill="#212634"
        stroke="#212634"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export default InsuranceIcon;
