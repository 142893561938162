const Checkmark = ({ fill = 'white' }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00224 9.46756L1.27069 5.73602L0 6.99776L5.00224 12L15.7405 1.26174L14.4787 0L5.00224 9.46756Z"
      fill={fill}
    />
  </svg>
);

export default Checkmark;
