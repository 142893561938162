import React from 'react';
import { Grid, Typography, Card, Stack } from '@mui/material';
import RemoveButton from '../../../components/removeButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { darkTextColor } from '../../../constants';
import { CustomTextFieldNoBorders } from '../../../components/customTextField';
import { CircleButton } from '../../EditProfile/circleButton';

const StaffMembers = (props) => {
  const {
    handleRemoveStaffMember,
    handleAddStaffMember,
    staffMembers,
    isDrawerOpen,
  } = props;

  const formikStaff = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email must be valid')
        .required('Email is a required field'),
      name: Yup.string().required('Name is a required field'),
    }),
    onSubmit: (values, { resetForm }) => {
      resetForm();
      handleAddStaffMember(values);
    },
  });

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="start"
        sx={{
          alignSelf: 'start',
          ml: isDrawerOpen ? 40 : 0,
          overflowY: 'auto',
          px: 1,
          width: '80vw',
          maxWidth: 950,
        }}
      >
        <Stack direction="column" gap={1}>
          <Stack direction="column" sx={{ mt: 2 }}>
            <Typography
              color={darkTextColor}
              variant="h5"
              fontSize="18px"
              sx={{ fontWeight: 600 }}
            >
              Staff Members
            </Typography>
          </Stack>
          <Grid
            maxWidth={true}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{
              bgcolor: '#FFFFFF',
              border: '1px solid #E2E2E2',
              borderRadius: '4px',
              mb: 2,
            }}
            gap={0}
          >
            <Grid
              maxWidth={true}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              gap={2}
              sx={{ py: 2, width: '100%' }}
            >
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Stack direction="column" sx={{ minWidth: '20%' }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                    >
                      Staff Members
                    </Typography>
                  </Stack>
                  <Stack direction="column" sx={{ width: '100%' }} gap={2}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        border: '1px solid #E2E2E2',
                        borderRadius: '4px',
                        px: 1,
                      }}
                    >
                      <CustomTextFieldNoBorders
                        error={Boolean(
                          formikStaff.touched.name && formikStaff.errors.name,
                        )}
                        value={formikStaff.values.name}
                        placeholder="Name"
                        sx={{ flex: 1.25 }}
                        onChange={(e) => {
                          formikStaff.setFieldValue(
                            'name',
                            e.target.value?.slice(0, 255),
                          );
                        }}
                      />
                      <div
                        style={{
                          background: '#B5B5B5',
                          height: '70%',
                          width: '1px',
                        }}
                      />
                      <CustomTextFieldNoBorders
                        error={Boolean(
                          formikStaff.touched.email && formikStaff.errors.email,
                        )}
                        value={formikStaff.values.email}
                        placeholder="Type email address here"
                        sx={{ flex: 2 }}
                        onChange={(e) => {
                          formikStaff.setFieldValue(
                            'email',
                            e.target.value?.slice(0, 255),
                          );
                        }}
                      />
                      <CircleButton
                        onClick={() => formikStaff.handleSubmit()}
                      />
                    </Stack>
                    {Boolean(
                      formikStaff.touched.name && formikStaff.errors.name,
                    ) && (
                      <Typography
                        color="error"
                        gutterBottom
                        variant="body2"
                        sx={{ my: -1 }}
                      >
                        {formikStaff.touched.name && formikStaff.errors.name}
                      </Typography>
                    )}
                    {Boolean(
                      formikStaff.touched.email && formikStaff.errors.email,
                    ) && (
                      <Typography
                        color="error"
                        gutterBottom
                        variant="body2"
                        sx={{ my: -1 }}
                      >
                        {formikStaff.touched.email && formikStaff.errors.email}
                      </Typography>
                    )}
                    <Typography
                      color={darkTextColor}
                      variant="body2"
                      fontSize="14px"
                    >
                      Include staff members that schedule appointments for your
                      patients. For your convenience, accepted referrals will be
                      forwarded to your staff for scheduling purposes.
                    </Typography>
                    {staffMembers?.length ? (
                      staffMembers?.map((member, index) => (
                        <Card
                          key={`${member}-${index}`}
                          sx={{
                            px: 3,
                            py: 2,
                            border: '1px solid #E2E2E2',
                            width: '100%',
                            boxShadow: 'none',
                          }}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Stack direction="row" gap={1} alignItems="center">
                              <Typography
                                color={darkTextColor}
                                sx={{
                                  overflowWrap: 'anywhere',
                                  maxWidth: '75%',
                                }}
                                variant="h6"
                                fontSize="14px"
                              >
                                {member.name}
                              </Typography>
                              <Typography
                                color={darkTextColor}
                                sx={{
                                  overflowWrap: 'anywhere',
                                  opacity: '0.6',
                                }}
                                variant="body2"
                                fontSize="14px"
                              >
                                •
                              </Typography>
                              <Typography
                                color={darkTextColor}
                                sx={{ overflowWrap: 'anywhere' }}
                                variant="body2"
                                fontSize="14px"
                              >
                                {member.email}
                              </Typography>
                            </Stack>
                            <RemoveButton
                              styles={{ alignSelf: 'center' }}
                              handleClick={() => {
                                handleRemoveStaffMember(member.email);
                              }}
                            />
                          </Stack>
                        </Card>
                      ))
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </Stack>
        {/* <Grid
          maxWidth={true}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="end"
          gap={3}
        >
          <Grid
            maxWidth={true}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="end"
            gap={2}
            sx={{ py: 2, width: '100%' }}
          >
            <Card sx={{ width: '100%', mt: 1, p: 2 }}>
              <Typography color={darkTextColor} variant="body1">
                Add a staff member
              </Typography>
              <form
                onSubmit={formik.handleSubmit}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  sx={{ my: 2, mb: 3 }}
                >
                  <Grid item xs={4.5}>
                    <CustomInputField
                      label="Name"
                      error={Boolean(formik.touched.name && formik.errors.name)}
                      fullWidth
                      width="100%"
                      name="name"
                      placeholder="Name"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values.name}
                    />
                    {Boolean(formik.touched.name && formik.errors.name) && (
                      <Typography
                        color="error"
                        gutterBottom
                        variant="body2"
                        sx={{ position: 'absolute !important' }}
                      >
                        {formik.touched.name && formik.errors.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={4.5}>
                    <CustomInputField
                      placeholder="Work email"
                      error={Boolean(
                        formik.touched.email && formik.errors.email,
                      )}
                      label="Work email"
                      fullWidth
                      width="100%"
                      name="email"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      value={formik.values.email}
                    />
                    {Boolean(formik.touched.email && formik.errors.email) && (
                      <Typography
                        color="error"
                        gutterBottom
                        variant="body2"
                        sx={{ position: 'absolute !important' }}
                      >
                        {formik.touched.email && formik.errors.email}
                      </Typography>
                    )}
                  </Grid>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    sx={{ height: 45 }}
                  >
                    Add
                  </Button>
                </Grid>
              </form>
              <Stack alignItems="center" direction="row" gap={1} sx={{ mt: 2 }}>
                <Typography
                  color={darkTextColor}
                  sx={{ opacity: 0.76, display: 'flex', alignItems: 'center' }}
                  variant="body1"
                >
                  Include staff members that schedule appointments for your
                  patients. For your convenience, accepted referrals will be
                  forwarded to your staff for scheduling purposes.
                </Typography>
              </Stack>
            </Card>
            <Grid
              container
              direction="row"
              gap={3}
              justifyContent="space-between"
            >
              {staffMembers?.length ? (
                staffMembers?.map((member, index) => (
                  <Card
                    key={`${member}-${index}`}
                    sx={{
                      p: 3,
                      border: '1px solid #E2E2E2',
                      width: '47.5%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                    }}
                  >
                    <Grid container direction="column">
                      <Typography
                        color={darkTextColor}
                        sx={{ overflowWrap: 'anywhere' }}
                        variant="h6"
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ overflowWrap: 'anywhere' }}
                        variant="body2"
                      >
                        {member.email}
                      </Typography>
                    </Grid>
                    <RemoveButton
                      styles={{ alignSelf: 'center' }}
                      handleClick={() => handleRemoveStaffMember(member)}
                    />
                  </Card>
                ))
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  );
};

export default StaffMembers;
