import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Doctors from '../../services/doctors';
import {
  Box,
  Grid,
  Typography,
  Divider,
  List,
  Avatar,
  Drawer,
  CircularProgress,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Users from '../../services/users';
import UserContext from '../../contexts/user/context';
import { CapitalizeFirstLetter, RESPONSE_STATUS } from '../../constants/index';
import { Specialties } from '../../constants/specialties';

import SetUpAccount from './SetUpAccount';
import ConfirmPage from './ConfirmProfile';
import CreateAccount from './CreateAccount';
import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import Domains from '../../services/domains';
import { TermsOfUseAccepting } from '../../components/footer/termsOfUse';
import ResponsiveDrawer from '../../components/responsiveDrawer';

const drawerWidth = 350;

const getUserSpcialty = (spec, health_center) => {
  if (spec) {
    // eslint-disable-next-line array-callback-return
    const getSpec = Specialties.find((inco) => {
      if (inco?.specialty === spec) {
        return inco;
      }
    });
    if (getSpec && getSpec?.Specialty !== '') {
      return [{ specialty: getSpec.Specialty, sub: [] }];
    }
  }
  return [];
};

const PageWrapper = (props) => {
  const {
    domains,
    data,
    setPage,
    page,
    createAccountPage,
    setCreateAccountPage,
    isDrawerOpen,
  } = props;
  const [confirmProfileData, setConfirmProfileData] = useState();
  const getSpecialties =
    data && data?.specialty
      ? getUserSpcialty(data?.specialty, data?.health_center)
      : [];
  const [specialtiesSelected, setSpecialitiesSelected] = useState(
    getSpecialties.length && getSpecialties[0].specialty !== ''
      ? getSpecialties
      : [],
  );

  const [needsValidation, setNeedsValidation] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setToken, setUser, user, token, serachDoctorsResult } =
    useContext(UserContext);
  const [selectedImage, setSelectedImage] = useState(null);
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const firstName = query.get('firstName');
  const lastName = query.get('lastName');

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [openTerms, setOpenTerms] = React.useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const formikCofirmProfile = useFormik({
    initialValues: {
      firstName: data?.first_name ? data?.first_name : firstName || '',
      lastName: data?.last_name ? data?.last_name : lastName || '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(255, 'First Name must be at most 255 characters')
        .required('First Name is required'),
      lastName: Yup.string()
        .max(255, 'Last Name must be at most 255 characters')
        .required('Last Name is required'),
    }),
    onSubmit: (values) => {
      if (!specialtiesSelected.length) {
        setErrMessage('At least one specialty is required');
        return;
      }
      if (
        values.firstName.trim() &&
        values.lastName.trim() &&
        specialtiesSelected.length
      ) {
        setErrMessage('');
        setConfirmProfileData({
          ...values,
          subspecialties: specialtiesSelected,
        });
        setPage(page + 1);
      }
    },
  });

  const formikCreateAccount = useFormik({
    initialValues: {
      email: '',
      password: '',
      reEnterPassword: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Work Email must be valid')
        .max(255, 'Work Email should be less than 255 characters')
        .required('Work Email is required'),
      password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('Password is a required field')
        .max(24, 'Password should be less than 24 characters')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Password must contain at least 8 characters, at least one uppercase, at least one lowercase, at least one number, and one special case character.',
        ),
      reEnterPassword: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('Repeat password is a required field'),
    }),
    onSubmit: async (values) => {
      handleCreateAccount(values);
    },
  });

  const handleCreateAccount = async (values) => {
    if (values.password !== values.reEnterPassword) {
      setErrMessage('Passwords do not match!');
      return;
    }
    if (needsValidation && !selectedImage) {
      setErrMessage(
        'Please upload a verification image. We only ask new accounts without a whitelisted domain to upload a photo of a work badge or ID.',
      );
      return;
    }
    if (
      values.email.trim() &&
      values.password.trim() &&
      values.reEnterPassword.trim()
    ) {
      if (errorMessage) {
        setErrMessage('');
      }
      try {
        setLoading(true);

        const response = await Users.post(
          CapitalizeFirstLetter(confirmProfileData.firstName),
          CapitalizeFirstLetter(confirmProfileData.lastName),
          values.password,
          values.email,
          confirmProfileData.subspecialties,
          !needsValidation,
          data?.userId || null,
          selectedImage,
          termsAccepted,
        );

        if (response.status === RESPONSE_STATUS.error) {
          setErrMessage(response.message);
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          setErrMessage('');
          setUser(response.body);
          setToken(response.token);
          setPage(page + 1);
        }
      } catch (e) {
        setErrMessage(e.message);
        return;
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGoBack = () => {
    if (!page) {
      if (serachDoctorsResult.length) {
        navigate(-1);
        return;
      }
      if (firstName) {
        navigate('/');
        return;
      }
      navigate('/');
      return;
    }
    if (page === 1) {
      setPage(0);
      return;
    }
    if (page && serachDoctorsResult.length) {
      setPage(page - 1);
    } else {
      navigate('/');
    }
  };
  // Disable Validation
  // useEffect(() => {
  //   if (formikCreateAccount.values.email && !formikCreateAccount.errors.email) {
  //     const emailDomain = formikCreateAccount.values.email.split('@')[1];

  //     if (emailDomain && domains && domains?.includes(`@${emailDomain}`)) {
  //       setNeedsValidation(false);
  //     } else {
  //       if (!needsValidation) {
  //         setNeedsValidation(true);
  //       }
  //     }
  //   }
  // }, [formikCreateAccount, needsValidation]);

  const handleAdd = (selection) => {
    if (selection) {
      setErrMessage('');
    }
    if (specialtiesSelected.length >= 2) {
      setErrMessage('A maximum of 2 specialties can be selected');
      return;
    }
    if (specialtiesSelected.length < 2 && selection.trim() !== '') {
      const updateSpecialties = specialtiesSelected.find(
        (item) => item.specialty === selection,
      );
      if (!updateSpecialties) {
        setSpecialitiesSelected((state) => [
          ...state,
          { specialty: selection, sub: [] },
        ]);
      }
    }
  };

  const handleAddSubselection = (selection, subValue) => {
    const _specialtiesSelected = [...specialtiesSelected];
    const updateSpecialties = _specialtiesSelected.map((item) => {
      if (item.specialty === selection) {
        item.sub.push(subValue);
        return item;
      }
      return item;
    });
    setSpecialitiesSelected(updateSpecialties);
  };

  const removeSelection = (selection) => {
    const _specialtiesSelected = [...specialtiesSelected];
    const updateSpecialties = _specialtiesSelected.filter(
      (item) => item.specialty !== selection,
    );
    setSpecialitiesSelected(updateSpecialties);
  };

  const removeSubSelection = (selection, subSelection) => {
    const _specialtiesSelected = [...specialtiesSelected];
    const updateSpecialties = _specialtiesSelected.filter((item) => {
      if (item.specialty === selection) {
        if (item.sub.length) {
          item.sub = item.sub.filter((item) => item !== subSelection);
          return item;
        }
      }
      return item;
    });
    setSpecialitiesSelected(updateSpecialties);
  };

  const handleConfirm = () => {
    if (!page) {
      formikCofirmProfile.handleSubmit();
      return;
    }

    if (page === 1) {
      if (!termsAccepted) {
        setOpenTerms(true);
        return;
      }
      if (termsAccepted) {
        setOpenTerms(false);
      }
      formikCreateAccount.handleSubmit();
      return;
    }
  };

  const handleClose = () => {
    setOpenTerms(false);
  };

  const handleTermsCheckbox = (value) => {
    setTermsAccepted(value);
  };

  const isConfirmDisabled = () => {
    if (
      page === 1 &&
      formikCreateAccount.values.email &&
      formikCreateAccount.values.password &&
      formikCreateAccount.values.reEnterPassword &&
      !formikCreateAccount.errors.password &&
      !formikCreateAccount.errors.reEnterPassword &&
      !formikCreateAccount.errors.email
    ) {
      return false;
    }
    if (page === 1) {
      return true;
    }
    return false;
  };
  return (
    <>
      <TermsOfUseAccepting
        open={openTerms}
        setOpen={handleClose}
        termsAccepted={termsAccepted}
        handleTermsCheckbox={handleTermsCheckbox}
        handleConfirm={handleConfirm}
      />
      <Grid
        id="sign-up-container"
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          background: '#F7F7F7',
          flexWrap: 'nowrap',
          height: '90vh',
          px: 10,
          overflowX: 'auto',
          py: 6,
        }}
      >
        {/* <CloseButton
          styles={{
            position: 'absolute',
            top: '2.5%',
            right: '2.5%',
            cursor: 'pointer',
          }}
          handleClick={() => handleBackNavigation()}
        /> */}
        {!page && (
          <ConfirmPage
            health_center={data?.health_center}
            formik={formikCofirmProfile}
            data={data}
            removeSelection={removeSelection}
            handleAdd={handleAdd}
            specialtiesSelected={specialtiesSelected}
            removeSubSelection={removeSubSelection}
            handleAddSubselection={handleAddSubselection}
            errMessage={errMessage}
            setErrMessage={setErrMessage}
            isDrawerOpen={isDrawerOpen}
          />
        )}
        {page === 1 && (
          <CreateAccount
            formik={formikCreateAccount}
            needsValidation={needsValidation}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            setErrMessage={setErrMessage}
            errMessage={errMessage}
            isDrawerOpen={isDrawerOpen}
          />
        )}

        {page === 2 && (
          <SetUpAccount
            doctorInfoData={data}
            page={createAccountPage}
            setPages={setCreateAccountPage}
            user={user}
            token={token}
            setUser={setUser}
            isDrawerOpen={isDrawerOpen}
          />
        )}
      </Grid>
      {page !== 2 && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: 'fixed',
            right: 0,
            bottom: '0',
            boxShadow: 3,
            background: '#FFFFFF',
            flexWrap: 'nowrap',
            height: isDrawerOpen ? '10vh' : '7.5vh',
            width: `${
              isDrawerOpen ? `calc( 100% - ${drawerWidth}px)` : '100%'
            }`,
            px: 10,
            py: 1,
          }}
        >
          <Typography
            color="primary"
            variant="subtitle2"
            underline="hover"
            sx={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
            onClick={() => handleGoBack()}
          >
            {page ? 'Previous step' : 'No, this isn’t me'}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={2}
          >
            <Typography
              color="error"
              gutterBottom
              variant="body2"
              sx={{ textTransform: 'capitalize' }}
            >
              {errorMessage}
            </Typography>
            <LoadingButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => handleConfirm()}
              loading={loading}
              disabled={isConfirmDisabled()}
              loadingPosition="end"
              sx={{ width: '10rem', height: 40 }}
            >
              Confirm
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const SignUp = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const doctorId = query.get('id');
  const [data, setData] = useState(null);
  const [page, setPage] = useState(0); // TODO: change back to 0
  const [createAccountPage, setCreateAccountPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [domains, setDomains] = useState([]);

  const [isDrawerOpen, setDrawerOpen] = useState(window.screen.width > 500);

  const handleDrawer = () => {
    setDrawerOpen(window.screen.width > 500);
  };
  useEffect(() => {
    window.addEventListener('resize', handleDrawer);
    return () => {
      window.removeEventListener('resize', handleDrawer);
    };
  });
  useEffect(() => {
    const getDoctorsData = async () => {
      try {
        const response = await Doctors.get(`?userId=${doctorId}`);

        if (response) {
          const _temp = response.body;
          setData(_temp);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    };
    if (doctorId) {
      getDoctorsData();
    } else {
      setLoading(false);
    }
  }, [doctorId]);

  useEffect(() => {
    const getDomains = async () => {
      try {
        const response = await Domains.get();
        if (response?.body) {
          const getDomains = response.body.map((domain) => domain.url);
          setDomains(getDomains);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getDomains();
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          minWidth: '100vw',
        }}
      >
        <ResponsiveDrawer
          createAccountPage={createAccountPage}
          page={page}
          screenWidth={isDrawerOpen}
        />

        {!loading ? (
          <PageWrapper
            createAccountPage={createAccountPage}
            setCreateAccountPage={setCreateAccountPage}
            data={data}
            setPage={setPage}
            page={page}
            domains={domains}
            isDrawerOpen={isDrawerOpen}
          />
        ) : (
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: '90vw',
              ml: 32,
              height: '100vh',
              overflow: 'hidden',
            }}
          >
            <CircularProgress color="primary" />;
          </Stack>
        )}
      </Box>
    </>
  );
};

export default SignUp;
