import React, { useState } from 'react';
import { useEffect } from 'react';
import AppDrawer from '../../../components/appDrawer';
import { ReasonToDecline } from '../../../constants';
import AcceptReferral from './acceptReferral';
import DeclineReferral from './declineRefferal';

const getCurrentStaff = (user) => {
  if (user?.staffs?.length) {
    return Array(user.staffs.length)
      .fill()
      .map((_, i) => true);
  }
  return [];
};

const ReviewPatientReferral = (props) => {
  const {
    anchor,
    setAnchor,
    updateStatus,
    setIsForward,
    referralData,
    user,
    accept,
    referralLoading,
  } = props;
  const [selectedStaff, setSelectedStaff] = useState(getCurrentStaff(user));
  const [declineReason, setDeclineReason] = useState(ReasonToDecline[0]);

  const updateSelectedStaff = (value, index) => {
    const tempSelectedStaff = selectedStaff.map((val, i) => {
      if (i === index) {
        return value;
      }
      return val;
    });
    setSelectedStaff(tempSelectedStaff);
  };

  const handleForwardReferral = () => {
    setIsForward(true);
    setAnchor(false);
  };

  const handleReason = (value) => {
    setDeclineReason(value);
  };

  const deselectAll = () => {
    setSelectedStaff(
      Array(user.staffs.length)
        .fill()
        .map((_, i) => false),
    );
  };

  const selectAll = () => {
    setSelectedStaff(
      Array(user.staffs.length)
        .fill()
        .map((_, i) => true),
    );
  };

  const handleCancel = () => {
    setAnchor(false);
    // if (setIsForward) {
    //   setIsForward(false);
    // }
  };

  useEffect(() => {
    if (selectedStaff?.length !== user?.staffs?.length) {
      setSelectedStaff([...selectedStaff, false]);
    }
  }, [user]);

  return (
    <AppDrawer
      title="Review patient referral"
      anchor={anchor}
      setAnchor={setAnchor}
    >
      {referralData ? (
        <div id="app-drawer">
          {accept ? (
            <AcceptReferral
              referralData={referralData}
              user={user}
              selectedStaff={selectedStaff}
              updateSelectedStaff={updateSelectedStaff}
              deselectAll={deselectAll}
              selectAll={selectAll}
              handleCancel={handleCancel}
              updateStatus={updateStatus}
              referralLoading={referralLoading}
            />
          ) : (
            <DeclineReferral
              referralData={referralData}
              user={user}
              handleReason={handleReason}
              reason={declineReason}
              handleCancel={handleCancel}
              handleForwardReferral={handleForwardReferral}
              updateStatus={updateStatus}
              referralLoading={referralLoading}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </AppDrawer>
  );
};

export default ReviewPatientReferral;
