import { Box, Link, Typography, Grid, Card } from '@mui/material';
import { CustomButton } from '../../components/customButton';
import CustomChip from '../../components/customChip';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  encryptNumber,
  prettyStatus,
} from '../../constants';
import MedicalIcon from '../../Icons/medicalIcon';
import * as moment from 'moment';
import { useNavigate } from 'react-router-dom';

const RefferedTo = ({ referralData }) => {
  let text = 'To';

  if (referralData.forwardRef?.ReferredBy) {
    text = 'Forwarded to';
  }
  if (
    referralData?.unRegisteredUser &&
    referralData?.referredToIdUnRegistered
  ) {
    return `${text} ${CapitalizeFirstLetter(
      referralData?.unRegisteredUser?.first_name,
    )}
  ${CapitalizeFirstLetter(referralData?.unRegisteredUser?.last_name)}${
      referralData?.unRegisteredUser?.doctorDetail[0]?.suffix ? ', ' : ''
    }${
      referralData?.unRegisteredUser?.doctorDetail[0]?.suffix
        ? referralData?.unRegisteredUser?.doctorDetail[0]?.suffix
            ?.replaceAll('.', '')
            ?.replaceAll(',', ', ')
        : ''
    }`;
  }
  return `${text} ${CapitalizeFirstLetter(referralData?.ReferredTo?.firstName)}
  ${CapitalizeFirstLetter(referralData?.ReferredTo?.lastName)},${
    referralData?.ReferredTo?.prefix
      ? ` ${referralData?.ReferredTo?.prefix?.replaceAll(',', ', ')}`
      : ''
  }`;
};
const ReferralCard = ({
  index,
  referralData,
  handleDrawer,
  user,
  handleCancelReferral,
}) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ p: 2, width: 'auto' }} key={`key-${index * referralData?.id}`}>
      <Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ flexWrap: 'nowrap' }}
        >
          <Grid
            gap={1.5}
            container
            direction="row"
            alignItems="flex-start"
            sx={{ flex: '0 100 auto' }}
          >
            <Box>
              <Typography
                variant="h6"
                sx={{ fontWeight: 600 }}
                color={darkTextColor}
              >
                {referralData.firstname} {referralData.lastname}
              </Typography>
            </Box>
            <Grid
              sx={{
                width: 'min-content',
                flexWrap: 'nowrap',
                marginTop: '3px',
              }}
              direction="row"
              container
              gap={1}
            >
              <CustomChip
                type={referralData.levelOfUrgency}
                sx={{ height: '1.7em', width: '75%', fontSize: '0.75em' }}
              />
              {referralData.status === null && (
                <CustomChip
                  type={'Pending'}
                  sx={{ height: '1.7em', width: '75%', fontSize: '0.75em' }}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            sx={{
              width: 'max-content',
              flexWrap: 'nowrap',
              flex: '1 0 auto',
            }}
            direction="row"
            alignItems="flex-start"
          >
            {referralData?.ReferralFrom &&
            referralData?.referralFrom === user.id ? (
              <Typography variant="body2" color={darkTextColor}>
                {`To ${CapitalizeFirstLetter(
                  referralData?.ReferredBy?.firstName,
                )} ${CapitalizeFirstLetter(
                  referralData?.ReferredBy?.lastName,
                )}, ${
                  referralData?.ReferredBy?.prefix?.replaceAll(',', ', ') || ''
                }
                  `}
                {/* {referralData.ReferredBy ? (
                  ) : (
                    <></>
                  )} */}
                {`Forwarded to ${CapitalizeFirstLetter(
                  referralData?.ReferredTo?.firstName,
                )} ${CapitalizeFirstLetter(
                  referralData?.ReferredTo?.lastName,
                )}, ${
                  referralData?.ReferredTo?.prefix?.replaceAll(',', ', ') || ''
                }
                  `}
                {', '}
                {moment(referralData.createdAt).fromNow()}
              </Typography>
            ) : (
              <Typography variant="body2" color={darkTextColor}>
                {referralData.forwardRef?.ReferredBy ? (
                  `By ${CapitalizeFirstLetter(
                    referralData?.forwardRef?.ReferredBy?.firstName,
                  )}
                      ${CapitalizeFirstLetter(
                        referralData?.forwardRef?.ReferredBy?.lastName,
                      )},
                      ${
                        referralData?.forwardRef?.ReferredBy?.prefix?.replaceAll(
                          ',',
                          ', ',
                        ) || ''
                      }${referralData.forwardRef?.ReferredBy ? ', ' : ''}`
                ) : (
                  <></>
                )}
                {user.id === referralData?.doctorId ? (
                  <RefferedTo referralData={referralData} />
                ) : (
                  `${
                    referralData.forwardRef?.ReferredBy ? 'Forwarded by' : 'By'
                  } ${CapitalizeFirstLetter(
                    referralData?.ReferredBy?.firstName,
                  )}
                    ${CapitalizeFirstLetter(
                      referralData?.ReferredBy?.lastName,
                    )},
                    ${
                      referralData?.ReferredBy?.prefix?.replaceAll(',', ', ') ||
                      ''
                    }`
                )}
                {', '}
                {moment(referralData.createdAt).fromNow()}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            backgroundColor: '#F7F7F7',
            borderRadius: '4px',
            p: 1.5,
            mt: 2,
          }}
        >
          <Typography
            variant="body1"
            color={darkTextColor}
            sx={{ overflowWrap: 'anywhere' }}
          >
            {referralData.reason}
          </Typography>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Link
            color="primary"
            variant="subtitle2"
            underline="hover"
            onClick={() => {
              const profileNumber = encryptNumber(referralData.id);
              navigate(`/patient/${profileNumber}`);
            }}
            sx={{
              cursor: 'pointer',
              fontWeight: 400,
            }}
          >
            View referral details
          </Link>
          {user.id !== referralData.doctorId &&
          user.id !== referralData?.referralFrom ? (
            <>
              {!referralData.status ? (
                <Grid
                  container
                  gap={2}
                  sx={{
                    width: 'min-content',
                    flexWrap: 'nowrap',
                  }}
                >
                  <CustomButton
                    type="error"
                    size="medium"
                    text="Decline"
                    height="32.5px"
                    width="8.5rem"
                    fontWeight="500"
                    onClick={() => handleDrawer(referralData, false)}
                  />
                  <CustomButton
                    size="medium"
                    text="Accept"
                    height="32.5px"
                    width="8.5rem"
                    fontWeight="500"
                    onClick={() => handleDrawer(referralData, true)}
                  />
                </Grid>
              ) : (
                <Typography variant="h6" color={darkTextColor}>
                  {prettyStatus(referralData.status)}
                </Typography>
              )}
            </>
          ) : (
            <CustomButton
              size="medium"
              text="Cancel"
              type="error"
              height="32.5px"
              width="8.5rem"
              fontWeight="500"
              onClick={() => handleCancelReferral(referralData.id)}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default ReferralCard;
