import { Typography } from '@mui/material';

export const NotificationNumber = ({
  number,
  variant = 'body1',
  bgcolor = '#FEF1F1',
  color = '#F65D5E',
}) => {
  if (!number) {
    return <></>;
  }
  return (
    <Typography
      variant={variant}
      sx={{
        bgcolor,
        borderRadius: '4px',
        color,
        fontWeight: 500,
        paddingX: 1.2,
        paddingY: 0.2,
        width: 'min-content',
        textAlign: 'center',
      }}
    >
      {number}
    </Typography>
  );
};
