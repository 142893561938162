import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  Stack,
} from '@mui/material';
import * as Yup from 'yup';
import { RESPONSE_STATUS } from '../../constants/index';
import Users from '../../services/users';
import AlertDialog from '../../components/alertDialog';

const textColor = '#212634';

const SuccessPrompt = (props) => {
  const {
    openDialog,
    setOpenDialog,
    successMessage,
    setSuccessMessage,
    setNotificationData,
  } = props;
  const [errorMessage, setErrorMessage] = useState('');

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    setNotificationData({
      type: 'success',
      text: successMessage,
    });
    setOpenDialog(false);
    setSuccessMessage('');
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <AlertDialog
        title="Referral sent"
        open={openDialog}
        setOpen={handleClose}
      >
        <DialogContent sx={{ my: -1, width: { md: '25vw', sm: '100%' } }}>
          <Typography color={textColor} gutterBottom variant="body1">
            {successMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            variant="contained"
            fullWidth
            sx={{ width: '10rem', height: 40 }}
            onClick={() => {
              handleCancel();
            }}
          >
            Done
          </Button>
        </DialogActions>
      </AlertDialog>
    </>
  );
};

export default SuccessPrompt;
