import { darkTextColor, RESPONSE_STATUS, useDebounce } from '../../constants';
import {
  Typography,
  Grid,
  Card,
  Stack,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import AutoCompleteSearch from '../autoCompleteSearch';
import SearchIcon from '../../Icons/serachIcon';
import { useEffect, useState, useContext, useRef } from 'react';
import Users from '../../services/users';
import UserContext from '../../contexts/user/context';
import { useNavigate } from 'react-router-dom';
import LocationIcon from '../../Icons/locationIcon';
import { useLocation } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import LocationSearch from '../autoCompleteSearch/locationSearch';
import InsuranceIcon from '../../Icons/insuranceIcon';
import InsuranceSearch from '../autoCompleteSearch/insuranceSearch';
import { HospitalSystems } from '../../constants/hospital_systems';
import { CustomSelect } from '../../pages/FindASpecialist/customSelect';
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
let latlongRef = [];
const FindSpecialist = ({
  subspecialty,
  isAvailable,
  isMedlynks,
  setIsMedlynks,
  setIsAvailable,
  isInviteFocus = false,
}) => {
  const [isSub, setIsSub] = useState(false);
  const {
    user,
    token,
    setUser,
    allDoctors,
    setAllDoctors,
    setLoadingState,
    loadingState,
    setLocation,
    location,
    lat,
    long,
    setLat,
    setLong,
    setPrimary,
    primary,
    setDistance,
    distance,
    setHealthcareEmployer,
    healthcareEmployer,
    setInsurance,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const uselocation = useLocation();
  let { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const specialty = query.get('specialty');
  const loc = query.get('location');
  const insur = query.get('insurance');

  // const initialSearch = useRef(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [initialSearch, setInitalSearch] = useState(false);
  const [isDistanceDisabled, setIsDistanceDisabled] = useState();
  const [insuranceCurrent, setInsuranceCurrent] = useState(insur ? insur : '');

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  const updateLocation = (value) => {
    setLocation(value);
    if (lat) {
      setLat();
    }
    if (long) {
      setLong();
    }
  };

  useEffect(() => {
    if (lat && long) {
      if (
        latlongRef.toString() !== [...lat, ...long].toString() ||
        latlongRef.length === 0
      ) {
        latlongRef = [...lat, ...long];
        handleSearch();
      }
    }
  }, [lat, long]);

  useEffect(() => {
    if (initialSearch === false && distance) {
      handleSearch();
    }
  }, [distance]);

  useEffect(() => {
    if (allDoctors && pathname.substring(1) === 'find') {
      handleSearch();
    }
  }, []);

  const handleSearch = async (isButton = false) => {
    if (!primary && allDoctors.search && !isButton) {
      return;
    }
    if (
      // !primary ||
      isDisabled ||
      !user.doctor_verified ||
      !user.email_verified
    ) {
      return;
    }
    if (isButton) {
      setLat();
      setLong();
    }
    try {
      let query = '';
      if (primary) {
        query = primary ? query + `?specialty=${primary}` : query;
        query = location && !lat ? query + `&location=${location}` : query;
        query = insuranceCurrent
          ? query + `&insurance=${insuranceCurrent}`
          : query;
      }

      navigate(`/find${query}`);
      setLoadingState(true);

      const response = await Users.getDoctors(
        token,
        primary,
        location,
        subspecialty,
        healthcareEmployer,
        distance,
        isSub,
        lat,
        long,
        isButton,
        insuranceCurrent,
      );

      if (response.status === RESPONSE_STATUS.success) {
        // setNotificationData({});
        setAllDoctors(response.body);
        if (response?.body?.insurance) {
          setInsurance(response?.body?.insurance);
        } else {
          setInsurance('');
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setInitalSearch(false);
      setLoadingState(false);
    }
  };

  useEffect(() => {
    if (user && (user.email_verified === 0 || user.doctor_verified === 0)) {
      setIsDisabled(true);
    }
  }, [user]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleEnter = (e) => {
      if ((e.code === 'Enter' || e.keyCode === 13) && !isInviteFocus) {
        const checkReferralDrawer = document.getElementById('app-drawer');
        const checkForwardDrawer =
          document.getElementById('app-drawer-forward');

        if (checkReferralDrawer || checkForwardDrawer) {
          return;
        }
        handleSearch(true);
      }
    };
    window.addEventListener('keydown', handleEnter);

    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, [primary, location, insuranceCurrent, isInviteFocus]);

  useEffect(() => {
    if (location) {
      setIsDistanceDisabled(false);
    } else {
      setIsDistanceDisabled(true);
    }
  }, [location]);

  const findASpecialistPage = uselocation.pathname === '/find';
  return (
    <Card
      id="find-a-specialist"
      sx={{
        width: '100%',
        height: findASpecialistPage ? 'auto' : 'auto',
        overflow: 'visible',
      }}
    >
      <Grid container direction="column" sx={{ px: 3, py: 2 }}>
        {findASpecialistPage ? (
          <></>
        ) : (
          <Typography
            color={darkTextColor}
            gutterBottom
            variant="h5"
            sx={{ fontWeight: 600 }}
          >
            Find a specialist
          </Typography>
        )}
        <Stack
          className="flex-form"
          direction="row"
          gap={1}
          sx={{
            minHeight: '42px',
            bgcolor: '#FFFFFF',
            border: '1px solid #F2F2F2',
            boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.06)',
          }}
        >
          <Stack direction="row" alignItems="center" style={{ flex: 1.7 }}>
            <Tooltip title="Specialty, subspecialty, doctor..." arrow>
              <div className="search-icon">
                <SearchIcon />
              </div>
            </Tooltip>
            <AutoCompleteSearch
              token={token}
              name={primary}
              setName={setPrimary}
              isDisabled={isDisabled}
              setIsSub={setIsSub}
              isSub={isSub}
              windowDimensions={windowDimensions}
            />
          </Stack>
          <Stack direction="row" alignItems="center" style={{ flex: 1.4 }}>
            <Tooltip title="City, state or zip code" arrow>
              <div className="loction-icon">
                <LocationIcon />
              </div>
            </Tooltip>

            <LocationSearch
              name={location}
              setName={updateLocation}
              token={token}
              isDisabled={isDisabled}
              windowDimensions={windowDimensions}
            />
          </Stack>

          <Stack direction="row" alignItems="center" style={{ flex: 1.2 }}>
            <Tooltip title="In-network insurances" arrow>
              <div className="insurance-icon">
                <InsuranceIcon />
              </div>
            </Tooltip>

            <InsuranceSearch
              name={insuranceCurrent}
              setName={setInsuranceCurrent}
              token={token}
              isDisabled={isDisabled}
              windowDimensions={windowDimensions}
            />
          </Stack>
          <LoadingButton
            color="primary"
            size="large"
            disabled={isDisabled}
            loading={loadingState}
            onClick={() => handleSearch(true)}
            endIcon={<></>}
            variant="contained"
            loadingPosition="end"
            sx={{
              // width: '13vw',
              flex: 0.5,
              // flexGrow: 0.5,
              minHeight: '42px',
              background: '',
              color: (isDisabled || loadingState) && 'black !important',
              '& 	.MuiLoadingButton-loading': {
                color: 'black',
              },
            }}
          >
            Search
          </LoadingButton>
        </Stack>
        {findASpecialistPage && (
          <Stack direction="row" spacing={2} sx={{ py: 2 }}>
            <CustomSelect
              placeHolder="Healthcare employer"
              width={{
                md: '25em',
              }}
              options={HospitalSystems.map((h) => h.name)}
              setState={setHealthcareEmployer}
              currentState={healthcareEmployer}
            />
            <CustomSelect
              placeHolder="Distance"
              isDisabled={isDistanceDisabled}
              width={{
                md: '16em',
              }}
              setState={setDistance}
              currentState={distance}
            />
            <FormControlLabel
              checked={isAvailable}
              onChange={() => setIsAvailable(!isAvailable)}
              control={<Checkbox defaultChecked />}
              label="Available"
              sx={{ color: darkTextColor }}
            />
            <FormControlLabel
              checked={isMedlynks}
              control={<Checkbox defaultChecked />}
              label="medLynks users"
              onChange={() => setIsMedlynks(!isMedlynks)}
              sx={{ color: darkTextColor }}
            />
          </Stack>
        )}
      </Grid>
    </Card>
  );
};

export default FindSpecialist;
