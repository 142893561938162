import { Avatar } from '@mui/material';
import { GetImageFromUrl } from '../../constants';

export const CustomAvatar = ({ curretDoc, variant = 'square', sx }) => {
  if (
    curretDoc?.profile_picture_url !== null &&
    curretDoc?.profile_picture_url !== undefined
  ) {
    let avatar = GetImageFromUrl(curretDoc?.profile_picture_url);
    return <Avatar sx={sx} src={avatar} variant={variant} />;
  }
  if (!curretDoc.image_path) {
    let avatar = GetImageFromUrl(curretDoc?.profile_picture_url);
    return <Avatar sx={sx} src={avatar} variant={variant} />;
  }
  const img = `images/${curretDoc?.health_center}/${curretDoc?.image_path}.jpg`;
  let avatar = GetImageFromUrl(img);
  return <Avatar sx={sx} src={avatar} variant={variant} />;
};
