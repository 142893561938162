import { InputAdornment, Typography } from '@mui/material';
import { useState } from 'react';
import { darkTextColor } from '../../constants';
import CustomInputField from '../customInputField';

export const CharacterLeftTypography = ({
  touched,
  errors,
  value,
  maxLength,
  handleBlur,
  handleChange,
  label,
  name,
  rows = 2,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  return (
    <CustomInputField
      error={Boolean(touched && errors)}
      label={rows}
      fullWidth
      width="100%"
      sx={{ fontSize: '14px' }}
      multiline
      name={name}
      placeholder="Type here"
      onFocus={(e) => setIsSelected(true)}
      onBlur={(e) => {
        if (handleBlur) {
          handleBlur(e);
        }
        setIsSelected(false);
      }}
      onChange={(e) => {
        handleChange(e);
      }}
      type="text"
      inputProps={{
        maxLength: maxLength,
      }}
      rows={rows}
      value={value}
      endAdornment={
        isSelected ? (
          <InputAdornment
            position="end"
            sx={{ position: 'absolute', bottom: -8.5, right: 5 }}
          >
            {value?.length ? (
              <Typography
                color={darkTextColor}
                sx={{ opacity: 0.75 }}
                gutterBottom
                variant="body2"
              >
                {maxLength - value.length} characters left
              </Typography>
            ) : (
              <></>
            )}
          </InputAdornment>
        ) : (
          <></>
        )
      }
    />
  );
};
