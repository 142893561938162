export const specialtyFilters = [
  { name: 'Addiction Medicine', subspecialties: [] },
  {
    name: 'Allergy and Immunology',
    subspecialties: ['Pediatric Allergy and Immunology'],
    problems: [
      'Asthma',
      'Anaphylaxis',
      'Angioedema',
      'Atopic Dermatitis',
      'Chronic cough',
      'Contact dermatitis',
      'Drug hypersensitivity',
      'Food allergy',
      'Allergic Rhinitis',
      'Sinusitis',
      'Urticaria',
    ],
    treatments: ['Allergy testing', 'Allergen immunotherapy', 'Skin testing'],
  },
  {
    name: 'Anesthesiology',
    subspecialties: ['Pain Medicine / Management', 'Pediatric Anesthesiology'],
  },
  { name: 'Audiology', subspecialties: [] },
  { name: 'Bariatric & Obesity Medicine', subspecialties: [] },
  { name: 'Bariatric Surgery', subspecialties: [] },
  { name: 'Breast Surgery', subspecialties: [] },
  {
    name: 'Cardiology',
    subspecialties: [
      'General Cardiology',
      'Clinical Cardiac Electrophysiology (EP)',
      'Advanced Heart Failure & Transplant',
      'Interventional Cardiology',
      'Advanced Imaging (Echo)',
      'Nuclear Cardiology',
      'Pediatric Cardiology',
    ],
  },
  {
    name: 'Cardiothoracic Surgery',
    subspecialties: [
      'Cardiac Surgery',
      'Thoracic Surgery',
      'Pediatric Cardiac Surgery',
    ],
    problems: [
      'Aortic aneurysm',
      'Aortic valve stenosis',
      'Arrhythmias',
      'Atrial fibrillation',
      'Coronary artery disease',
      'Heart failure',
      'Mitral valve prolapse / regurgitation',
    ],
    treatments: [
      'Aortic valve repair/replacement',
      'Aortic surgery',
      'Cardiac transplantation',
      'Lung transplantation',
      'Coronary angiography (heart cath)',
      'Coronary artery bypass grafting (CABG)',
      'Mitral or tricuspid valve replacement/repair',
      'Minimally invasive surgery',
      'Robotic surgery',
      'MitraClip',
      'Left ventricular assist device (LVAD) implantation',
      'Transcatheter aortic valve replacement (TAVR)',
    ],
  },
  { name: 'Colorectal Surgery', subspecialties: [] },
  {
    name: 'Dentistry',
    subspecialties: [
      'General Dentistry',
      'Endodontics',
      'Periodontics',
      'Orthodontics',
    ],
  },
  {
    name: 'Dermatology',
    subspecialties: [
      'Cosmetic/Laser',
      'Dermatologic Oncology',
      'Pediatric Dermatology',
      'Dermatopathology',
      'Immunodermatology',
      'Mohs surgery',
    ],
    problems: [
      'Acne',
      'Basal cell carcinoma',
      'Eczema',
      'Hair loss/alopecia',
      'Keloids',
      'Melanoma',
      'Molluscum contagiosum',
      'Psoriasis',
      'Rosacea',
      'Scabies',
      'Seborrheic keratoses',
      'Tinea veriscolor',
      'Warts',
    ],
    treatments: [],
  },
  {
    name: 'Emergency Medicine',
    subspecialties: ['Pediatric Emergency Medicine'],
  },
  { name: 'Endocrine Surgery', subspecialties: [] },
  {
    name: 'Endocrinology',
    subspecialties: [
      'Diabetes and Metabolism',
      'Neuroendocrinology',
      'Thyroid disease',
      'Pediatric Endocrinology',
    ],
  },
  {
    name: 'Family Medicine',
    subspecialties: ['Primary Care', 'Sports Medicine'],
  },
  {
    name: 'Gastroenterology',
    subspecialties: [
      'General Gastroenterology',
      'Advanced Interventional Endoscopy (Interventional)',
      'Pediatric Gastroenterology',
      'Hepatology / Transplant Hepatology',
      'Inflammatory Bowel Disease (IBD)',
      'Motility Disorders / Esophageal',
    ],
  },
  {
    name: 'General Surgery',
    subspecialties: ['Pediatric General Surgery', 'Laparoscopic Surgery / MIS'],
  },
  { name: 'Geriatrics', subspecialties: [] },
  {
    name: 'Hematology / Oncology',
    subspecialties: [
      'Hematology',
      'Oncology',
      'Pediatric Hematology / Oncology',
      'General / Benign Hematology',
      'Hematologic Oncology',
    ],
  },
  { name: 'Holistic Medicine', subspecialties: [] },
  { name: 'Hospice & Palliative Medicine', subspecialties: [] },
  {
    name: 'Infectious Disease (ID)',
    subspecialties: [
      'HIV/AIDS',
      'Pediatric Infectious Disease',
      'Transplant ID',
    ],
  },
  {
    name: 'Internal Medicine',
    subspecialties: ['Primary Care', 'Hospital Medicine'],
  },
  { name: 'Medical Genetics', subspecialties: [] },
  {
    name: 'Nephrology',
    subspecialties: [
      'Onconephrology',
      'Interventional Nephrology',
      'Dialysis',
      'Kidney Transplantation',
      'Pediatric Nephrology',
    ],
  },
  {
    name: 'Neurological Surgery',
    subspecialties: [
      'General Neurological Surgery',
      'Cerebrovascular',
      'Endovascular',
      'Neurosurgical Oncology (Tumors)',
      'Skull Base Tumors',
      'Pediatric Neurological Surgery',
      'Spine',
      'Functional',
      'Epilepsy',
    ],
  },
  {
    name: 'Neurology',
    subspecialties: [
      'General Neurology',
      'Pediatric Neurology',
      'Alzheimer / Memory disorders',
      'Epilepsy',
      'Headache/Pain',
      'Movement Disorders',
      'Neuro-Immunology / MS',
      'Neuromuscular',
      'Neuro-Oncology',
      'Neurocritical Care',
      'Stroke / Neurovascular',
    ],
  },
  { name: 'Nuclear Medicine', subspecialties: [] },
  {
    name: 'Obstetrics / Gynecology (OB-GYN)',
    subspecialties: [
      'General OB-GYN',
      'Gynecologic Oncology',
      'Minimally Invasive Gynecologic Surgery',
      'Maternal-Fetal Medicine',
      'Reproductive Endocrinology and Infertility (REI)',
      'Female Pelvic & Reconstructive Surgery (Urogynecology)',
      'Complex Family Planning',
    ],
  },
  { name: 'Occupational Medicine', subspecialties: [] },
  {
    name: 'Ophthalmology',
    subspecialties: [
      'General Ophthalmology',
      'Cornea',
      'Retina/Uveitis',
      'Glaucoma',
      'Oculoplastics (Ophthalmic Plastic and Reconstructive Surgery)',
      'Pediatrics / Strabismus',
      'Neuro-Ophthalmology',
    ],
  },
  { name: 'Oral & Maxillofacial Surgery', subspecialties: [] },
  {
    name: 'Orthopedic Surgery',
    subspecialties: [
      'General Orthopedic Surgery',
      'Pediatric Orthopedics',
      'Foot & Ankle',
      'Orthopedic Oncology',
      'Hand Surgery',
      'Hip & Knee',
      'Sports',
      'Spine',
      'Trauma',
      'Shoulder & Elbow',
      'Tumor',
      'Joints',
    ],
  },
  {
    name: 'Otolaryngology',
    subspecialties: [
      'General Otolaryngology',
      'Pediatric Otolaryngology',
      'Neurotology / Otology',
      'Rhinology',
      'Head and Neck / Oncology',
      'Sleep',
      'Laryngology',
      'Facial Plastics and Reconstructive',
    ],
  },
  {
    name: 'Pain Medicine / Management',
    subspecialties: [],
    problems: ['Acute pain', 'Back pain', 'Chronic pain', 'Neck pain'],
    treatments: ['Interventional pain management', 'Medical pain management'],
  },
  { name: 'Pathology', subspecialties: [] },
  {
    name: 'Pediatrics',
    subspecialties: ['Neonatology', 'Pediatric Hospital Medicine'],
  },
  {
    name: 'Physical Medicine and Rehabilitation',
    subspecialties: ['Pain Medicine / Management'],
  },
  {
    name: 'Plastic Surgery',
    subspecialties: [
      'General Plastic Surgery',
      'Hand Surgery',
      'Cranio-Maxillofacial',
      'Microvascular Surgery / Microsurgery',
      'Aesthetic / Cosmetic',
      'Burn',
      'Pediatric Plastic Surgery',
    ],
  },
  { name: 'Podiatry', subspecialties: [] },
  { name: 'Psychiatry', subspecialties: ['Child / Adolescent', 'Addiction'] },
  {
    name: 'Psychology',
    subspecialties: ['Neuropsychology', 'Child / Adolescent'],
  },
  {
    name: 'Pulmonology',
    subspecialties: [
      'General Pulmonology',
      'Critical Care Medicine',
      'Interstitial Lung Disease',
      'Interventional Pulmonology',
      'Lung Transplantation',
      'Pediatric Pulmonology',
      'Obstructive Lung Disease',
      'Sleep-Disordered Breathing',
    ],
  },
  { name: 'Radiation Oncology', subspecialties: [] },
  {
    name: 'Radiology',
    subspecialties: [
      'Interventional Neuroradiology',
      'Interventional Radiology',
      'Breast',
      'Neuroradiology',
      'Pediatric Radiology',
    ],
  },
  { name: 'Rheumatology', subspecialties: ['Pediatric Rheumatology'] },
  { name: 'Sleep Medicine', subspecialties: ['Pediatric Sleep Medicine'] },
  { name: 'Sports Medicine', subspecialties: ['Pediatric Sports Medicine'] },
  { name: 'Surgical Oncology', subspecialties: ['Robotic Surgery'] },
  { name: 'Thoracic Surgery', subspecialties: ['Minimally Invasive'] },
  {
    name: 'Transplant Surgery',
    subspecialties: ['Liver Transplantation', 'Kidney Transplantation'],
  },
  { name: 'Trauma Surgery', subspecialties: ['Burn'] },
  {
    name: 'Urology',
    subspecialties: [
      'Andrology and Infertility',
      'Urologic Oncology',
      'Urogynecology (Female Urology)',
      'Endourology (Minimally Invasive)',
      'Pediatric Urology',
      'Laparoscopy / Robotics',
      'Neurourology',
    ],
  },
  { name: 'Vascular Surgery', subspecialties: [] },
  { name: 'Wound Care', subspecialties: [] },
];
