import React from 'react';
import {
  Slide,
  Dialog,
  Grid,
  Typography,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseButton from '../closeButton';
import { darkTextColor } from '../../constants';
import { Stack } from '@mui/system';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Terms = (props) => {
  const { open, setOpen, confirmation } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        PaperProps={{
          sx: {
            borderRadius: '12px !important',
            p: 2,
            maxWidth: {
              md: '40vw !important',
              xs: '75vw !important',
              sm: '75vw !important',
            },
            maxHeight: '60vh !important',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2, pb: 1, flexWrap: 'nowrap' }}
        >
          <Typography variant="h5" fontSize="24px" color={darkTextColor}>
            Terms of Use
          </Typography>
          <IconButton onClick={() => handleClose()} color="secondary">
            <CloseButton />
          </IconButton>
        </Grid>
        <DialogContent sx={{ py: 1 }}>
          <Stack gap={2}>
            <Typography color={darkTextColor}>
              <p style={{ fontWeight: 600 }}>Introduction</p>MEDLYNKS is a
              physician referral service software for United States healthcare
              professionals. By registering as a MEDLYNKS user (“User”) or
              otherwise accessing or using our website, including any related
              services (collectively the “Service” or “Services”), you are
              entering into a legally binding contract with MEDLYNKS, LLC
              (“MEDLYNKS,” “we,” “us,” or “our”).]
            </Typography>
            <Typography color={darkTextColor} variant="paragraph">
              <p style={{ fontWeight: 600 }}>Disclaimer</p>As a physician, nurse
              practitioner, physician’s assistant or other healthcare provider
              or practice administrator or other staff member of a physician,
              you understand the importance of providing your patients with the
              best medical care possible. In an effort to achieve this goal, you
              have chosen to use a physician referral service to assist you and
              your staff in referring your patients to available specialists on
              this site. You understand that the referral service acts only as
              an intermediary, and it is not responsible for ensuring that your
              patients receive the proper medical treatment from a specialist
              listed on this site. <br />
              <p> &nbsp;</p>
              In other words, use of the site is not a substitute for a
              healthcare provider’s standard practice or professional judgment.
              The referral service does not endorse any specific physician(s) or
              medical practice(s), nor does it guarantee the quality of care or
              outcomes from using the services of any provider listed on our
              site. It is not responsible for the actions or inactions of any
              physician or medical practice and the selection of a particular
              specialist, and any decisions affecting the medical treatment
              received as a result of the validity or reliability of information
              being transmitted is ultimately the healthcare provider’s
              responsibility. <br />
              <p> &nbsp;</p>
              We expressly disclaim any and all warranties regarding: (1) the
              accuracy, reliability, or timeliness of any matches to service
              providers that we make for you; and (2) the accuracy of any
              reviews that may be posted on our Site about specific
              practitioners, and their patients; and (3) whether such
              information will be in full compliance with the HIPAA Privacy
              Rules or any other rules or regulations. We do not screen or
              conduct background checks on medical professionals who are listed
              on our Services, nor can we make any endorsements or
              recommendations, and we cannot make any representation regarding
              the accuracy of any profiles, the quality of any work, or any
              evaluation of any medical professional’s skills. Your business
              dealings with any third party with whom you connect through the
              Services are solely between you and such third party. <br />
              <p> &nbsp;</p>
              We do not make any warranty about the products or services of any
              such third parties. You are solely responsible for conducting your
              own 2 due diligence prior to entering into a business relationship
              with any of our listed specialists or recommending that your
              patient enter into a doctor-patient relationship with any third
              party linked to you through our Services.
              <br /> <p> &nbsp;</p>We also cannot make any warranties or
              representations that any service provider will honor a scheduled
              appointment or respond to an appointment request, or that any
              prospective patient who arranges for an appointment will keep that
              appointment. You solely assume all risks with respect to the
              scheduling and keeping of appointments. You agree that we will not
              be responsible or liable for any loss, damage, or other
              liabilities incurred as a result of doing business with such third
              parties. You assume the sole risk of loss and liability in doing
              business with any third parties or service providers linked to
              these Services.
              <br /> <p> &nbsp;</p>By using this referral service, you expressly
              acknowledge that you have read, accept, and agree to be bound by
              these Terms of Service, as well as any applicable laws hereunder.
              You should not use our Services if you do not agree to these Terms
              of Service. You, your staff and your medical practice are solely
              responsible for the care and treatment of your patients and that
              the referral site acts only as an intermediary in connecting your
              patient with specialists listed on our site.
            </Typography>
            <Typography color={darkTextColor}>
              <p style={{ fontWeight: 600 }}>LIMITATION OF LIABILITY</p>ACCESS
              TO THE MEDLYNKS SERVICES, MEDLYNKS CONTENT, AND THE INFORMATION
              OBTAINED OR TRANSMITTED BY ANY USER BY USE OF THE SITE ARE
              PROVIDED &quot;AS IS” AND “AS AVAILABLE,&quot; WITHOUT ANY
              WARRANTY OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING,
              WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT OR
              NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
              DEALING OR USAGE OF TRADE. THE USER IS SOLELY RESPONSIBLE FOR ANY
              AND ALL ACTS OR OMISSIONS TAKEN OR MADE IN RELIANCE ON THE SITE OR
              THE INFORMATION OBTAINED OR TRANSMITTED BY USE OF THE SITE,
              INCLUDING INACCURATE OR INCOMPLETE INFORMATION. MEDLYNKS MAKES NO
              WARRANTY THAT THE MEDLYNKS SERVICES, MEDLYNKS CONTENT, MEDLYNKS
              SPECIALISTS OR USER CONTENT WILL MEET YOUR REQUIREMENTS OR BE
              AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS.
              MEDLYNKS MAKES NO WARRANTY REGARDING THE QUALITY OF ANY
              SPECIALISTS, SERVICES OR CONTENT OBTAINED THROUGH THE MEDLYNKS
              SERVICES.
              <br />
              <p> &nbsp;</p> IN NO EVENT WILL MEDLYNKS BE LIABLE FOR ANY
              SPECIAL, INDIRECT, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING
              BUT NOT LIMITED TO LOSS OF REVENUE, LOSS OF INFORMATION OR DATA,
              WHETHER A CLAIM FOR ANY SUCH LIABILITY OR DAMAGES IS PREMISED UPON
              BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT
              LIABILITY OR ANY OTHER THEOTY OF LIABILITY. YOU DISCLAIM ANY AND
              ALL LIABILITY FOR ERRONEOUS TRANSMISSIONS ON OUR SITE.
              <br /> <p> &nbsp;</p>YOU ACKNOWLEDGE AND AGREE THAT YOUR SOLE AND
              EXCLUSIVE REMEDY FOR ANY DISPUTE WITH US IS THE CANCELLATION OF,
              OR REMOVAL OF YOUR PROFILE FROM, YOUR MEDLYNKS ACCOUNT. OUR
              CUMULATIVE LIABILITY TO YOU, YOUR PATIENTS OR YOUR MEDICAL
              PRACTICE FOR ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF YOUR
              USE OF THE SERVICES, OR FROM THE YOUR INABILITY TO USE THE
              MEDLYNKS SITE, SERVICES OR CONTENT THEREIN, SHALL NOT EXCEED ONE
              HUNDRED U.S. DOLLARS ($100).
              <br /> <p> &nbsp;</p>No action arising under or in connection with
              this Disclaimer and Terms of Use Agreement, regardless of the
              form, may be brought by you more than one (1) year after the cause
              of action arose; actions brought thereafter are forever barred.
            </Typography>
            <Typography color={darkTextColor} variant="paragraph">
              <p style={{ fontWeight: 600 }}>USE OF SITE</p>These Terms of
              Service (these “Terms”), including our Privacy Policy, define the
              terms by which you, your practice and staff members may use the
              Medlynks.com website, platform, and applications, (collectively,
              the “Services”) and govern your access to and use of the Service.{' '}
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>
                1. Compliance with Laws.
              </span>{' '}
              These Terms of Service (these “Terms”), including our Privacy
              Policy, define the terms by which you, your practice and staff
              members may use the Medlynks.com website, platform, and
              applications, (collectively, the “Services”) and govern your
              access to and use of the Service. <br />
              <p> &nbsp;</p>
              You acknowledge that federal regulations relating to the
              confidentiality of individually identifiable health information
              require covered entities to comply with the Standards for Privacy
              of Individually Identifiable Health Information (45 C.F.R. parts
              160 and 164, subparts A and E)(“the Privacy Rule”) and for
              Security of electronic Protected Health Information (PHI) (45
              C.F.R. parts 160, 162 and 164, subpart C) (“the Security Rule”),
              as that term is defined in section 164.501 of the Privacy
              Regulations, as promulgated by the U.S. Department of Health and
              Human Services(“HHS”) pursuant to the Health Insurance Portability
              and Accountability Act of 1996 (“HIPAA”) and the American Recovery
              and Reinvestment Act of 2009 (pub. L. 111-5), Title XIII of
              Division A and Title IV of Division B (the “Health Information
              Technology for Economic and Clinical Health” or the HITECH Act”),
              as amended, and other applicable law. If you are a Covered Entity
              for which any part of our Services provided to you constitute a
              Business Associate relationship, by using the Services you
              expressly have read, accept, and agree to be bound by our Business
              Associate Agreement available on our website, which constitutes
              part of these Terms of Service. <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>
                2. Permitted Users, Access and Use.
              </span>{' '}
              Use of the site is limited to exchanges of information that are
              allowed by law and that are related to treatment, payment or
              healthcare operations as defined by HIPAA. Users must be at least
              18 years of age and United States physicians, medical students or
              other healthcare providers, or practice administrators or other
              staff members and use a HIPAA-compliant work email platform.
              <br />
              <p> &nbsp;</p>
              All users must keep all MEDLYNKS login credentials confidential
              and agree to notify us immediately if you discover any
              unauthorized use of your login credentials. Disclosing or sharing
              your MEDLYNKS password with any third party or allowing a third
              party to access a password-protected portion of the Services will
              automatically be cause for termination from the site. Users are
              responsible for implementing safeguards that are reasonable and
              appropriate regarding their access and use of the site to ensure
              the security of the site. For example, Users should also not leave
              their computer unattended while they are accessing the MEDLYNKS
              site. Also, any attachments must not exceed 5 MB, and be in any of
              the following formats: JPEG, PNG, GIF, PDF and JPG. <br />
              <br />
              You are not permitted to use this website (including any webpage
              and/or data that passes through the web domain at medlynks.com),
              its underlying computer programs (including application
              programming interfaces ("APIs")), domain names, Uniform Resource
              Locators ("URLs"), databases, functions or its content other than
              for private, non-commercial purposes. Use of any automated system
              or software, whether operated by a third party or otherwise, to
              extract any data from this website for commercial purposes
              ("screen scraping") is strictly prohibited.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>
                3. Accurate and Truthful Information; Reviews.
              </span>{' '}
              Users must provide accurate and truthful information in their
              profiles, and they are responsible to review, update and confirm
              the accuracy of patient information being submitted to the
              referral site, including without limitation, changes to the
              patient health information made at the request of the patient and
              overall changes in the patient’s health condition. MEDLYNKS has no
              responsibility for or assumes no liability related to the
              accuracy, content, completeness of any information being
              exchanged. If information is time sensitive or life-threatening,
              Users should use additional means of communication and not rely
              solely on this site to communicate such information.
              <br />
              <p> &nbsp;</p>
              You may have the opportunity to submit feedback regarding your
              experiences with listed specialists you find through the Services,
              to submit inquiries concerning possible medical needs, and to
              participate in the other interactive or community features of the
              Services (collectively, “Posted Information”). It is important
              that you act responsibly when providing Posted Information.
              MEDLYNKS does not endorse or confirm the accuracy of any
              statements made in such Posted Information; Posted Information
              reflects solely the views or opinions of the User, and not of
              MEDLYNKS. <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>4. Patient Consent.</span> Users
              are responsible for obtaining any and all necessary patient
              consents and authorizations required by law relating to the use
              and exchange of protected patient information, including without
              limitation, consent to release HIV/AIDS/STD related
              information/records; child abuse/neglect; adult abuse with a
              disability; developmental disabilities; genetic test information;
              sexual assault; mental health information/records (including
              psychotherapy notes); substance abuse; and drug/alcohol diagnosis,
              treatment or referral.
              <br />
              <p> &nbsp;</p>
              You represent and warrant to MEDLYNKS, and its referring
              specialists, that you have the legal right and authorization to
              provide and transmit all User Information to MEDLYNKS and its
              specialists for its intended purpose, as set forth herein. While
              we are not responsible for the content and information you submit
              to the Service, we reserve the right to monitor your use of the
              Service and we may suspend or terminate any User’s access to the
              Services, without notice, and without liability to us, if we
              determine that any User has breached these Terms, threatens the
              personal safety of any User or the public, or could create
              liability for us. Restoration of such access or account shall be
              at our sole discretion. <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>
                5. Prohibited Conduct.
              </span>{' '}
              Our Services should only be used for lawful purposes in accordance
              with these Terms. Users may not engage in any illegal, unethical
              or otherwise prohibited conduct, including conduct that violates
              any federal, state or local law or regulation including, without
              limitation, in relation to patient privacy including the Health
              Insurance Portability and Accountability Act (HIPAA).
              <br />
              <p> &nbsp;</p>
              Users may not violate or infringe the privacy, copyright,
              trademark, trade secrets or intellectual property rights of any
              person or entity, including, without limitation, posting text,
              files, images, audio, other copyrightable materials, or content
              (collectively “Content”) which infringes any patent, trademark,
              trade secret, copyright or other proprietary rights of any party
              or otherwise sharing information or initiating communications with
              information you are under an obligation not to disclose. <br />
              <p> &nbsp;</p>
              To the extent you submit, upload, post or transmit any health
              information, medical history, conditions, problems, symptoms,
              personal information, consent forms, agreements, requests,
              comments, ideas, suggestions, information, files, videos, images
              or other materials to us or our Site (“User Information”), you
              agree not to provide any User Information that is misleading,
              false, inaccurate, defamatory, offensive, abusive, libelous,
              unlawful, obscene, threatening, harassing, fraudulent,
              pornographic, discriminatory or otherwise harmful or
              objectionable, or that could encourage criminal or unethical
              behavior.
              <br />
              <p> &nbsp;</p>
              Additionally, you will not (and will not permit anyone else to):
              (a) attempt to obtain unauthorized access to the Service; (b)
              reverse engineer, decompile, disassemble, decipher or otherwise
              attempt to derive the source code for the Services or any related
              technology; (c) access the Service for the purpose of developing a
              competitive product; (d) introduce into the Service any viruses,
              Trojan horses, worms, logic bombs, or other material that is
              malicious or technologically harmful; (e) copy any of the material
              made available through the Service for commercial purposes; (f)
              use any software, devices, scripts, robots or any other means or
              process to view, access or “scrape” the Service or otherwise copy
              information from the Service; (g) reproduce or redistribute
              content provided through the Service, including through “framing”
              or “mirroring” or through third parties; (h) tamper with, breach
              or attempt to probe, scan, or test for vulnerabilities in the
              Service or our computer systems, network, security elements, or
              any other protective measures associated with the Service; or (i)
              attempt to interfere with the proper working or use of the
              Service.​​​
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>
                6. Confidentiality and Privacy.
              </span>{' '}
              Users must respect the confidentiality and privacy of other users
              and patients, and comply with HIPAA and other privacy laws. Our
              Privacy Policy explains how personal information is collected,
              used and shared in connection with the use of the Service. We
              encourage all users to review our Privacy Policy carefully, on our
              website.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>
                7. Intellectual Property Rights.
              </span>{' '}
              Users must respect the intellectual property rights of the
              referral site and others, and may not use any content or materials
              without properauthorization. We will retain all respective right,
              title, and interest in the marks, logos, codes, databases,
              Content, text, designs, photos, and other materials posted to our
              Services (“Intellectual Property”). Except as otherwise expressly
              stated herein, you may only display and view the Intellectual
              Property posted to the Services, and you may not reproduce,
              display, distribute, create derivative works of, misappropriate,
              or otherwise use for any purpose any portion of our Services
              without the express written permission of us or our licensors as
              appropriate. Using the Intellectual Property on our Services on
              any other website or in any other services or product for any
              commercial purpose is expressly prohibited.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>9. Miscellaneous.</span>
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>A. Severability</span>
              <p> &nbsp;</p>
              If any provision of these Terms is found by a court of competent
              jurisdiction or arbitrator to be illegal, void, or unenforceable,
              the unenforceable provision will be modified so as to render these
              Terms enforceable and effective to the maximum extent possible in
              order to effect the intention of the provision; and if a court or
              arbitrator finds the modified provision invalid, illegal, void or
              unenforceable, the validity, legality and enforceability of the
              remaining provisions of these Terms will not be affected in any
              way.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>B. No Waiver</span>
              <p> &nbsp;</p>
              If any provision of these Terms is found by a court of competent
              jurisdiction or arbitrator to be illegal, void, or unenforceable,
              the unenforceable provision will be modified so as to render these
              Terms enforceable and effective to the maximum extent possible in
              order to effect the intention of the provision; and if a court or
              arbitrator finds the modified provision invalid, illegal, void or
              unenforceable, the validity, legality and enforceability of the
              remaining provisions of these Terms will not be affected in any
              way.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>
                C. Relationship of the Parties
              </span>
              <p> &nbsp;</p>
              You agree that no joint venture, partnership, joint
              controllership, employment or agency relationship exists between
              the parties as a result of these Terms or your use of the Service
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>D. Notice</span>
              <p> &nbsp;</p>
              Our notice to you via email, regular mail or notices, posts, or
              links within the Service shall constitute acceptable notice to you
              under these Terms. You must provide any notice to us, in writing,
              and send via overnight courier.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>E. Assignment</span>
              <p> &nbsp;</p>
              You may not assign, delegate or otherwise transfer your account or
              your obligations under these Terms without our prior written
              consent. We have the right, in our sole discretion, to transfer or
              assign all or any part of our rights under these Terms and will
              have the right to delegate or use third parties to fulfill our
              duties and obligations under these Terms and in connection with
              the Service.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>
                F. Changes to Terms of Use
              </span>
              <p> &nbsp;</p>
              MEDLYNKS reserves the right to modify these Terms of Use at any
              time, effective upon posting. Any use of this website after such
              changes will be deemed an acceptance of those changes. You agree
              to review the Terms of Use each time you access this website so
              that you may be aware of any changes to these Terms.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>G. Indemnification</span>
              <p> &nbsp;</p>
              You agree to defend, indemnify and hold MEDLYNKS harmless from and
              against all third party claims, damages and expenses (including
              reasonable attorneys’ fees) against or incurred by MEDLYNKS
              arising out of your breach of these Terms of Use or violation of
              applicable law, or access by anyone accessing the Site using your
              user ID and password.
              <br />
              <p> &nbsp;</p>
              <span style={{ fontWeight: 600 }}>H. Entire Agreement</span>
              <p> &nbsp;</p>
              These Terms, and any supplemental terms, policies, rules,
              including, without limitation, the MEDLYNKS Business Associate
              Agreement and Privacy Policy, each of which are incorporated
              herein by reference, constitute the entire agreement between you
              and us and supersede all previous written or oral agreements. The
              failure of either party to exercise in any respect any right
              provided for herein shall not be deemed a waiver of any further
              rights hereunder.
              <br />
              <p> &nbsp;</p>
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
