import configuration from '../config';
import { DOMAINS_ROUTE } from '../constants/index';

const get = async () => {
  const response = await fetch(`${configuration.api.url}/${DOMAINS_ROUTE}`, {
    method: 'GET',
    headers: {
      'Max-Forwards': '0',
    },
  });

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const Domains = {
  get,
};

export default Domains;
