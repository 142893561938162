import configuration from '../config';
import { CHAT_ROUTE } from '../constants/index';

const get = async (token, id) => {
  const response = await fetch(
    `${configuration.api.url}/${CHAT_ROUTE}/${id}/messages`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const send = async (token, id, message, files, selectedPatient) => {
  const formData = new FormData();
  formData.append('message', message);

  if (selectedPatient) {
    formData.append('patientId', selectedPatient);
  }
  if (files) {
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
  }
  const response = await fetch(
    `${configuration.api.url}/${CHAT_ROUTE}/${id}/sendMessage`,
    {
      method: 'POST',
      body: formData,

      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const readAll = async (token, senderId, patientId) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('patientId', patientId);
  urlencoded.append('receiverId', senderId);
  const response = await fetch(
    `${configuration.api.url}/${CHAT_ROUTE}/markAsRead`,
    {
      method: 'POST',
      body: urlencoded,
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const unread = async (token, id) => {
  const response = await fetch(
    `${configuration.api.url}/${CHAT_ROUTE}/markAsUnread/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const Messages = {
  get,
  send,
  readAll,
  unread,
};

export default Messages;
