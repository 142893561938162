export const MedicalSchoolList = [
  'Albany School of Medicine',
  'Albert Einstein College of Medicine',
  'Augusta Medical College of Georgia',
  'Baylor College of Medicine',
  'Boston University School of Medicine',
  'Brown University Warren Alpert Medical School',
  'California Northsate University School of Medicine',
  'Case Western Reserve University School of Medicine',
  'Central Michigan University College of Medicine',
  'Columbia University College of Physicians and Surgeons',
  'Cooper Medical School of Rowan University',
  'Creighton University School of Medicine',
  'Dartmouth College Geisel School of Medicine',
  'Drexel University College of Medicine',
  'Duke University School of Medicine',
  'East Carolina University Brody School of Medicine',
  'East Tennessee State Quillen College of Medicine',
  'Eastern Virginia School of Medicine',
  'Emory University Schoool of Medicine',
  'Florida Atlantic University Charles Schmidt College of Mediine',
  'Florida International University Wertheim College of Medicine',
  'Florida State University College of Medicine',
  'Geisinger Commonwealth Medical College',
  'George Washington University School of Medicine',
  'Georgetown University School of Medicine',
  'Harvard Medical School',
  'Hofstra University Zucker School of Medicine',
  'Howard University College of Medicine',
  'Icahn School of Medicine at Mount Sinai',
  'Indiana University School of Medicine',
  'Johns Hopkins University School of Medicine',
  'Loma Linda School of Medicine',
  'Lousiana State University New Orleans School of Medicine',
  'Lousiana State University Shreveport School of Medicine',
  'Loyola University Stritch School of Medicine',
  'Marshall University Joan C. Edwards School of Medicine',
  'Mayo Clinic School of Medicine',
  'Medical College of Wisconsin',
  'Medical University of South Carolina',
  'Meharry Medical College',
  'Mercer University School of Medicine',
  'Michigan State University College of Human Medicine',
  'Morehouse School of Medicine',
  'New York Medical College',
  'New York University School of Medicine',
  'North Dakota School of Medicine',
  'Northeast Ohio Medical University School of Medicine',
  'Northwestern University Feinberg School of Medicine',
  'Nova Southeastern University College of Allopathic Medicine',
  'Oakland University William Beaumont School of Medicine ',
  'Ohio State University School of Medicine',
  'Oregon Health & Science University ',
  'Penn State University College of Medicine',
  'Quinnipiac University School of Medicine',
  'Rosalind Franklin University of Medicine',
  'Rush Medical College',
  'Rutgers New Jersey Medical School ',
  'Rutgers Robert Wood Johnson Medical School',
  'Saint Louis University School of Medicine ',
  'Sanford School of Medicine ',
  'Southern Illinois University School of Medicine',
  'Stanford School of Medicine ',
  'Stony Brook University School of Medicine',
  'SUNY Downstate College of Medicine',
  'SUNY Upstate Medical University ',
  'Temple University Lewis Katz School of Medicine',
  'Texas A&M College of Medicine ',
  'Texas Tech University Paul L. Foster School of Medicine',
  'Texas Tech University School of Medicine',
  'Thomas Jefferson University Sidney Kimmel Medical College ',
  'Tufts University School of Medicine ',
  'Tulane University School of Medicine',
  'UC Davis School of Medicine ',
  'UC Irvine School of Medicine',
  'UC Riverside School of Medicine ',
  'UC San Diego School of Medicine ',
  'UCLA David Geffen School of Medicine',
  'UCSF School of Medicine',
  'Uniformed Services University of the Health Sciences',
  'University at Buffalo Jacobs School of Medicine',
  'University of Alabama School of Medicine',
  'University of Arizona College of Medicine Phoenix ',
  'University of Arizona College of Medicine Tucson',
  'University of Arkansas for Medical Science ',
  'University of Central Florida College of Medicine',
  'University of Chicago Pritzker School of Medicine',
  'University of Cincinnati College of Medicine',
  'University of Colorado School of Medicine ',
  'University of Connecticut School of Medicine',
  'University of Florida College of Medicine',
  'University of Hawaii John A. Burns School of Medicine ',
  'University of Illinois College of Medicine',
  'University of Iowa Carver College of Medicine',
  'University of Kansas School of Medicine',
  'University of Kentucky College of Medicine',
  'University of Louisville School of Medicine ',
  'University of Maryland School of Medicine',
  'University of Massachusetts Medical School',
  'University of Miami Miller School of Medicine ',
  'University of Michigan Medical School',
  'University of Minnesota Medical School',
  'University of Mississippi School of Medicine',
  'University of Missouri Kansas City School of Medicine',
  'University of Nebraska Medical Center',
  'University of Nevada',
  'University of New Mexico School of Medicine',
  'University of North Carolina School of Medicine',
  'University of Oklahoma College of Medicine',
  'University of Pennsylvania Perelman School of Medicine',
  'University of Pittsburgh School of Medicine ',
  'University of Rochester School of Medicine ',
  'University of South Alabama College of Medicine ',
  'University of South Carolina School of Medicine ',
  'University of South Carolina School of Medicine Greenville ',
  'University of South Florida Morsani College of Medicine',
  'University of Tennessee College of Medicine',
  'University of Toledo College of Medicine',
  'University of Utah School of Medicine ',
  'University of Vermont College of Medicine',
  'University of Virginia School of Medicine',
  'University of Washington School of Medicine',
  'University of Wisconsin School of Medicine',
  'USC Keck School of Medicine',
  'UT Health Science Center',
  'UT McGovern Medical School ',
  'UT Medical Branch at Galveston ',
  'UT Rio Grande Valley School of Medicine',
  'UT Southwestern Medical School ',
  'Vanderbilt University School of Medicine',
  'Virginia Commonwealth University School of Medicine ',
  'Virginia Tech Carilion School of Medicine',
  'Wake Forest University School of Medicine ',
  'Washington University School of Medicine',
  'Wayne State School of Medicine ',
  'Weill Cornell Medical College',
  'West Virginia University School of Medicine',
  'Western Michigan Homer Stryker School of Medicine',
  'Wright State Boonshoft School of Medicine ',
  'Washingoton State Elson S. Floyd College of Medicine ',
  'Yale School of Medicine',
  'Alabama College of Osteopathic Medicine',
  'Arkansas College of Osteopathic Medicine ',
  'ATSU Kirksville College of Osteopathic Medicine',
  'ATSU School of Osteopathic Medicine',
  'Burrell College of Osteopathic Medicine',
  'Campbell University School of Osteopathic Medicine',
  'Des Moines University College of Osteopathic Medicine',
  'Edward Via College of Osteopathic Medicine - Auburn Campus',
  'Edward Via College of Osteopathic Medicine - Carolinas Campus ',
  'Edward Via College of Osteopathic Medicine - Virginia Campus ',
  'Idaho College of Osteopathic Medicine',
  'Kansas City University of Medicine and Biosciences',
  'Kentucky College of Osteopathic Medicine',
  'Lake Erie College of Osteopathic Medicine - Bradenton Campus ',
  'Lake Erie College of Osteopathic Medicine',
  'Liberty University College of Osteopathic Medicine',
  'Lincoln Memorial University DeBusk College of Osteopathic Medicine ',
  'Marian University Osteopathic Medical School',
  'Michigan State University College of Osteopathic Medicine',
  'Midwestern University Arizona College of Osteopathic Medicine ',
  'Midwestern University Chicago College of Osteopathic Medicine ',
  'Nova Southeastern University College of Osteopathic Medicine ',
  'NYIT College of Osteopathic Medicine',
  'Ohio University Heritage College of Osteopathic Medicine',
  'Oklahoma State University College of Osteopathic Medicine',
  'Pacific Northwest University of Health Sciences ',
  'Philadelphia College of Osteopathic Medicine - Georgia Campus ',
  'Philadelphia College of Osteopathic Medicine ',
  'Rocky Vista University College of Osteopathic Medicine',
  'Rowan University School of Osteopathic Medicine ',
  'TCOM Health Science Center College of Osteopathic Medicine ',
  'Touro College of Osteopathic Medicine',
  'Touro University California',
  'Touro University Nevada College of Osteopathic Medicine ',
  'University of New England College of Osteopathic Medicine',
  'University of the Incarnate Word School of Osteopathic Medicine',
  'West Virginia School of Osteopathic Medicine',
  'Western University College of Osteopathic Medicine of the Pacific ',
  'William Carey University College of Osteopathic Medicine',
  'American University of Antigua',
  'American University of the Caribbean',
  'Medical Univeristy of the Americas',
  'Ross University',
  'Saba University',
  "St. George's University",
  'St. Matthews University',
  'Trinity School of Medicine',
  'University of Medicine and Health Sciences',
  'Xavier University',
  'Avalon University',
  'All Sants University',
  'St. James School of Medicine',
  'International American University',
  'Spartan Health Sciences University',
  'American University of Barbados',
];
