import React from 'react';
import {
  Slide,
  Dialog,
  Grid,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Checkbox,
} from '@mui/material';
import CloseButton from '../closeButton';
import { darkTextColor } from '../../constants';
import { Stack } from '@mui/system';
import { Terms } from './terms';
import { PrivacyPolicy } from './privacyPolicy';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TermsOfUse = (props) => {
  const { open, setOpen, confirmation } = props;
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const [openTerms, setOpenTerms] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        PaperProps={{
          sx: {
            borderRadius: '12px !important',
            p: 2,
            maxWidth: {
              md: '40vw !important',
              xs: '75vw !important',
              sm: '75vw !important',
            },
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2, pb: 1, flexWrap: 'nowrap' }}
        >
          <Typography variant="h5" fontSize="24px" color={darkTextColor}>
            MedLynks User Policy
          </Typography>
          <IconButton onClick={() => handleClose()} color="secondary">
            <CloseButton />
          </IconButton>
        </Grid>
        <DialogContent sx={{ py: 1 }}>
          <Stack
            gap={2}
            sx={{ p: 2, borderRadius: '8px', backgroundColor: '#F7F7F7' }}
          >
            <Typography color={darkTextColor}>
              1. I have read and agree to comply by the{' '}
              <span
                style={{ fontWeight: 600, cursor: 'pointer' }}
                onClick={() => setOpenTerms(true)}
              >
                Terms of Use
              </span>
              .
            </Typography>
            <Typography color={darkTextColor}>
              2. Accuracy: The information I have provided is true and accurate.
              If there is a change in the status of my license, my ability to
              practice, or if my practice closes, I agree to notify medLynks
              immediately.
            </Typography>
            <Typography color={darkTextColor}>
              3. Privacy: I understand that the information will be handled in
              accordance with MedLynks’{' '}
              <span
                style={{ fontWeight: 600, cursor: 'pointer' }}
                onClick={() => setOpenPrivacy(true)}
              >
                Privacy Policy
              </span>
              .
            </Typography>
            <Typography color={darkTextColor}>
              4. Encrypted record: I understand that in transmitting PHI,
              MedLynks will retain an encrypted record of my request on behalf
              of our patient.
            </Typography>
            <Typography color={darkTextColor}>
              5. Contact: I understand that medLynks has the right to contact me
              to verify my identity (as well as my group’s identity).
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
      <PrivacyPolicy open={openPrivacy} setOpen={setOpenPrivacy} />
      <Terms open={openTerms} setOpen={setOpenTerms} />
    </div>
  );
};
// const [termsAccepted, setTermsAccepted] = useState(false);
// const [openTerms, setOpenTerms] = React.useState(false);

export const TermsOfUseAccepting = (props) => {
  const { open, setOpen, handleConfirm, termsAccepted, handleTermsCheckbox } =
    props;
  const [openPrivacy, setOpenPrivacy] = React.useState(false);
  const [openTerms, setOpenTerms] = React.useState(false);
  const handleClose = () => {
    handleTermsCheckbox(false);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        PaperProps={{
          sx: {
            borderRadius: '12px !important',
            p: 2,
            maxWidth: '40vw !important',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2, pb: 1, flexWrap: 'nowrap' }}
        >
          <Typography variant="h5" fontSize="24px" color={darkTextColor}>
            medLynks User Policy
          </Typography>
          <IconButton onClick={() => handleClose()} color="secondary">
            <CloseButton />
          </IconButton>
        </Grid>
        <DialogContent sx={{ py: 1 }}>
          <Typography
            color={darkTextColor}
            gutterBottom
            variant="h6"
            fontSize="16px"
            sx={{ fontWeight: 400 }}
          >
            <Checkbox
              checked={termsAccepted}
              onChange={(e, value) => handleTermsCheckbox(value)}
            />{' '}
            By checking this box, I am confirming the following:
          </Typography>
          <Stack
            gap={2}
            sx={{ p: 2, borderRadius: '8px', backgroundColor: '#F7F7F7' }}
          >
            <Typography color={darkTextColor}>
              1. I have read and agree to comply by the{' '}
              <span
                style={{ fontWeight: 600, cursor: 'pointer' }}
                onClick={() => setOpenTerms(true)}
              >
                Terms of Use
              </span>
              .
            </Typography>
            <Typography color={darkTextColor}>
              2. Accuracy: The information I have provided is true and accurate.
              If there is a change in the status of my license, my ability to
              practice, or if my practice closes, I agree to notify medLynks
              immediately.
            </Typography>
            <Typography color={darkTextColor}>
              3. Privacy: I understand that the information will be handled in
              accordance with MedLynks’{' '}
              <span
                style={{ fontWeight: 600, cursor: 'pointer' }}
                onClick={() => setOpenPrivacy(true)}
              >
                Privacy Policy
              </span>
            </Typography>
            <Typography color={darkTextColor}>
              4. Encrypted record: I understand that in transmitting PHI,
              MedLynks will retain an encrypted record of my request on behalf
              of our patient.
            </Typography>
            <Typography color={darkTextColor}>
              5. Contact: I understand that medLynks has the right to contact me
              to verify my identity (as well as my group’s identity).
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ width: '35%', alignSelf: 'end' }}>
          <Button
            size="large"
            variant="contained"
            width="100%"
            fullWidth
            sx={{
              height: 40,
              backgroundColor: '#E2E2E2',
              color: darkTextColor,
              '&:hover': {
                backgroundColor: '#E2E2E2',
              },
            }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            size="large"
            variant="contained"
            width="100%"
            fullWidth
            sx={{
              height: 40,
              // '&:disabled': {
              //   color: darkTextColor,
              // },
            }}
            disabled={!termsAccepted}
            onClick={() => handleConfirm()}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <PrivacyPolicy open={openPrivacy} setOpen={setOpenPrivacy} />
      <Terms open={openTerms} setOpen={setOpenTerms} />
    </div>
  );
};
