import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Container,
  Typography,
  Grid,
  Stack,
  Card,
  IconButton,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Icons/logo';
import CustomInputField from '../../components/customInputField';
import UserContext from '../../contexts/user/context';
import Doctors from '../../services/doctors';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { darkTextColor } from '../../constants';
import SlidingCarousel from './slidingCarousel';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { faq } from './faq';
import ArrowRight from '../../Icons/arrowRight';
import { Footer } from '../../components/footer';

const bgImage = 'assets/new_bg_min.png';
const bgImageMobile = 'assets/new_bg_mobile.png';

const frame1 = 'assets/frame1.png';
const frame2 = 'assets/frame2.png';
const frame3 = 'assets/frame3.png';

const founderimage = 'assets/founderImage.png';

const iconImg = 'assets/iconImg.svg';

const stringSimilarity = require('string-similarity');

const Marketing = () => {
  const navigate = useNavigate();
  const { user, token, setSerachDoctorsResult } = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(255, 'First Name must be at most 255 characters')
        .required('First Name is required'),
      lastName: Yup.string()
        .max(255, 'Last Name must be at most 255 characters')
        .required('Last Name is required'),
    }),
    onSubmit: (values) => {
      getDoctorsData(values.firstName, values.lastName);
    },
  });

  const [selectedCard, setSelectedCard] = useState(0);
  const getDoctorsData = async (first, last) => {
    try {
      setLoading(true);
      const response = await Doctors.get(
        `?firstName=${first}&lastName=${last}`,
      );
      if (response) {
        if (
          !response?.body?.registeredDoctors?.length &&
          !response?.body?.doctors?.length
        ) {
          navigate(`/sign-up?firstName=${first}&lastName=${last}`);
          return;
        }
        const sortedAsc = response?.body?.doctors
          ?.sort(
            (objA, objB) =>
              Number(new Date(objB.max_load_date)) -
              Number(new Date(objA.max_load_date)),
          )
          ?.filter(
            (doc) =>
              !doc?.userRegistered?.email_verified ||
              !doc?.userRegistered?.doctor_verified,
          );
        const filtered = sortedAsc.filter(
          (doctor) => doctor.max_load_date === sortedAsc[0].max_load_date,
        );

        const resArr = [];
        filtered.filter(function (item) {
          var i = resArr.findIndex(
            (x) =>
              x.health_center === item.health_center &&
              x.specialty === item.specialty &&
              x.first_name === item.first_name,
          );
          if (i <= -1) {
            resArr.push(item);
          }
          return null;
        });
        const tempArr = [...resArr]
          .map((doctor) => {
            if (doctor.createdAt) {
              doctor['similarity'] = stringSimilarity.compareTwoStrings(
                `${formik.values.firstName?.toLocaleLowerCase()}${formik.values.lastName?.toLocaleLowerCase()}`,
                `${doctor.firstName?.toLocaleLowerCase()}${doctor.lastName?.toLocaleLowerCase()}`,
              );
            } else {
              doctor['similarity'] = stringSimilarity.compareTwoStrings(
                `${formik.values.firstName?.toLocaleLowerCase()}${formik.values.lastName?.toLocaleLowerCase()}`,
                `${doctor.first_name?.toLocaleLowerCase()}${doctor.last_name?.toLocaleLowerCase()}`,
              );
            }
            return doctor;
          })
          .sort((a, b) => b.similarity - a.similarity);
        setSerachDoctorsResult(tempArr);
        setSerachDoctorsResult([
          ...response?.body?.registeredDoctors,
          ...resArr,
        ]);
        navigate({
          pathname: '/doctors',
          search: `?firstName=${first}&lastName=${last}&initial=true`,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && user) {
      navigate('/overview');
    }
  }, [navigate, token, user]);

  return (
    <>
      <Box
        component="main"
        justifyContent="right"
        sx={{
          minHeight: '100vh',
          minWidth: '100vw',
          background: '#F7F7F7',
          overflowX: 'hidden',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: '100%',
            flexWrap: 'nowrap',
            px: 10,
            py: 2,
            position: 'fixed',
            backgroundColor: '#F7F7F7',
            zIndex: 10,
          }}
        >
          <Logo />
          <Grid
            container
            direction="row"
            justifyContent="flex-end "
            alignItems="center"
          >
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="outlined"
              sx={{ mr: 4, minWidth: '10%' }}
              onClick={() => navigate('/login')}
            >
              Login
            </Button>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              sx={{ color: '#FFFFFF', minWidth: '10%' }}
              onClick={() => navigate('/sign-up')}
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
        <Container
          maxWidth="xl"
          sx={{
            alignItems: 'center',
            display: 'flex',
            minHeight: '85vh',
            marginTop: '8vh',
          }}
        >
          <Container
            sx={{
              alignItems: 'start',
              display: 'flex',
              flexDirection: 'column',
            }}
            maxWidth="xl"
          >
            <Stack direction="row">
              <Box
                sx={{
                  width: { md: '43%', sm: '90%' },
                  backgroundImage: { sm: `url(${bgImageMobile})`, md: 'none' },
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right' /* Center the image */,
                  backgroundSize: 'contain',
                  minHeight: { md: '85vh', sm: '30vh' },
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <Box sx={{ my: 5, mb: 2, width: { md: '100%%', sm: '60%' } }}>
                    <Typography
                      color={darkTextColor}
                      variant="h2"
                      sx={{ my: 2 }}
                    >
                      Patient referrals simplified for everyone
                    </Typography>
                    <Typography color={darkTextColor} variant="body1">
                      medLynks is your hub for simplified healthcare
                      connections. Find top specialists and enhance patient care
                      through seamless communication and collaboration.
                    </Typography>
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="end"
                    sx={{ flexWrap: 'nowrap' }}
                    gap={0}
                  >
                    <Stack
                      direction="column"
                      sx={{ position: 'relative', mr: '-2em' }}
                    >
                      <CustomInputField
                        error={Boolean(
                          formik.touched.firstName && formik.errors.firstName,
                        )}
                        label="First Name"
                        name="firstName"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="firstName"
                        placeholder="First Name"
                        sx={{ width: '80%' }}
                        inputProps={{ maxLength: 250 }}
                        value={formik.values.firstName}
                      />
                      {Boolean(
                        formik.touched.firstName && formik.errors.firstName,
                      ) && (
                        <Typography
                          color="error"
                          variant="body2"
                          sx={{
                            position: 'absolute !important',
                            bottom: 0,
                            transform: 'translatey(110%)',
                          }}
                        >
                          {formik.touched.firstName && formik.errors.firstName}
                        </Typography>
                      )}
                    </Stack>
                    <Stack
                      direction="column"
                      sx={{ position: 'relative', mr: '-2em' }}
                    >
                      <CustomInputField
                        error={Boolean(
                          formik.touched.lastName && formik.errors.lastName,
                        )}
                        label="Last Name"
                        name="lastName"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        type="text"
                        inputProps={{ maxLength: 250 }}
                        placeholder="Last Name"
                        sx={{ width: '80%', margin: 0 }}
                        value={formik.values.lastName}
                      />
                      {Boolean(
                        formik.touched.lastName && formik.errors.lastName,
                      ) && (
                        <Typography
                          color="error"
                          variant="body2"
                          sx={{
                            position: 'absolute !important',
                            bottom: 0,
                            transform: 'translatey(110%)',
                          }}
                        >
                          {formik.touched.lastName && formik.errors.lastName}
                        </Typography>
                      )}
                    </Stack>
                    <LoadingButton
                      color="primary"
                      size="large"
                      loading={loading}
                      loadingPosition="end"
                      endIcon={<></>}
                      sx={{ width: '10rem', maxHeight: '45px' }}
                      type="submit"
                      variant="contained"
                      onClick={(e) => {
                        formik.handleSubmit();
                      }}
                    >
                      Claim Profile
                    </LoadingButton>
                  </Stack>
                  <Typography
                    color={darkTextColor}
                    variant="body2"
                    sx={{
                      my:
                        formik.errors.lastName || formik.errors.firstName
                          ? 5
                          : 3,
                      width: '100%',
                    }}
                  >
                    Create your free account. No credit card required.
                  </Typography>
                </div>
              </Box>
              <Stack
                sx={{
                  display: { md: 'block', sm: 'none' },
                  backgroundImage: `url(${bgImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center' /* Center the image */,
                  backgroundSize: 'contain',
                  width: '60vw',
                  maxHeight: '85vh',
                  ml: '-10vw',
                }}
              ></Stack>
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <Box sx={{ my: 5, mb: 2, maxWidth: '50%' }}>
                <Typography
                  fontSize="28px"
                  color={darkTextColor}
                  variant="h2"
                  sx={{ my: 2 }}
                >
                  Elevating Patient Care
                </Typography>
                <Typography
                  color={darkTextColor}
                  variant="body1"
                  fontSize="16px"
                >
                  Say goodbye to the hassle of traditional referrals and hello
                  to a new era of streamlined communication, personalized
                  networks, and improved patient outcomes.
                </Typography>
              </Box>
              <SlidingCarousel />
            </Stack>
            <Stack sx={{ width: '100%' }}>
              <Box sx={{ my: 5, mb: 2, maxWidth: '50%' }}>
                <Typography
                  color={darkTextColor}
                  fontSize="28px"
                  variant="h2"
                  sx={{ my: 2 }}
                >
                  We help you
                </Typography>
              </Box>
              <Stack direction="row" gap={2}>
                <Stack
                  direction="column"
                  gap={2}
                  sx={{ minWidth: '30%', flex: 1 }}
                >
                  {buttonData.map((data, i) => (
                    <ButtonCard
                      key={data.text}
                      text={data.text}
                      setSelected={setSelectedCard}
                      selected={i === selectedCard}
                      index={i}
                    />
                  ))}
                </Stack>
                <Box sx={{ minWidth: '71%', marginRight: -2 }}>
                  <img
                    src={buttonData[selectedCard].img}
                    alt="selected-frame"
                    style={{ width: '100%' }}
                  />
                </Box>
              </Stack>
            </Stack>
            <Stack sx={{ width: '100%', my: 4 }} alignItems="center">
              <Box sx={{ my: 5, mb: 2, maxWidth: '50%' }}>
                <Typography color={darkTextColor} variant="h2" fontSize="28px">
                  Get Started Now
                </Typography>
              </Box>
              <Typography
                color={darkTextColor}
                fontSize="16px"
                sx={{ width: '20%', textAlign: 'center' }}
              >
                The easiest way to find and connect with top medical
                specialists.
              </Typography>
              <Stack>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="end"
                  sx={{ flexWrap: 'nowrap', mt: 2 }}
                  gap={0}
                >
                  <Stack
                    direction="column"
                    sx={{ position: 'relative', mr: '-2em' }}
                  >
                    <CustomInputField
                      error={Boolean(
                        formik.touched.firstName && formik.errors.firstName,
                      )}
                      label="First Name"
                      name="firstName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="firstName"
                      placeholder="First Name"
                      inputProps={{ maxLength: 250 }}
                      sx={{ width: '80%' }}
                      value={formik.values.firstName}
                    />
                    {Boolean(
                      formik.touched.firstName && formik.errors.firstName,
                    ) && (
                      <Typography
                        color="error"
                        variant="body2"
                        sx={{
                          position: 'absolute !important',
                          bottom: 0,
                          transform: 'translatey(110%)',
                        }}
                      >
                        {formik.touched.firstName && formik.errors.firstName}
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    direction="column"
                    sx={{ position: 'relative', mr: '-2em' }}
                  >
                    <CustomInputField
                      error={Boolean(
                        formik.touched.lastName && formik.errors.lastName,
                      )}
                      label="Last Name"
                      name="lastName"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="text"
                      inputProps={{ maxLength: 250 }}
                      placeholder="Last Name"
                      sx={{ width: '80%', margin: 0 }}
                      value={formik.values.lastName}
                    />
                    {Boolean(
                      formik.touched.lastName && formik.errors.lastName,
                    ) && (
                      <Typography
                        color="error"
                        variant="body2"
                        sx={{
                          position: 'absolute !important',
                          bottom: 0,
                          transform: 'translatey(110%)',
                        }}
                      >
                        {formik.touched.lastName && formik.errors.lastName}
                      </Typography>
                    )}
                  </Stack>
                  <LoadingButton
                    color="primary"
                    // disabled={formik.isSubmitting}
                    fullWidth
                    size="large"
                    variant="contained"
                    loading={loading}
                    loadingPosition="end"
                    endIcon={<></>}
                    sx={{ width: '10rem', maxHeight: '45px' }}
                    // loadingIndicator="Finding Best Match..."
                    onClick={(e) => {
                      formik.handleSubmit();
                    }}
                  >
                    Claim Profile
                  </LoadingButton>
                </Stack>
                <Typography
                  color={darkTextColor}
                  variant="body2"
                  fontSize="14px"
                  sx={{ my: 3, textAlign: 'center' }}
                >
                  Create your free account. No credit card required.
                </Typography>
              </Stack>
            </Stack>
            <Stack sx={{ width: '100%', mb: 12 }}>
              <Box sx={{ my: 5, mb: 2 }}>
                <Typography
                  color={darkTextColor}
                  fontSize="28px"
                  variant="h2"
                  sx={{ my: 2 }}
                >
                  Frequently Asked Questions
                </Typography>
              </Box>
              <Stack direction="column" gap={2}>
                {faq.map(({ question, answer }) => (
                  <CollapsableCard
                    key={question}
                    question={question}
                    answer={answer}
                  />
                ))}
              </Stack>
            </Stack>
          </Container>
        </Container>
        <div
          style={{
            backgroundColor: '#E0E6E0',
          }}
        >
          <Container maxWidth="xl">
            <Stack
              sx={{ width: '100%', px: 4, py: 8 }}
              direction="row"
              gap={2}
              justifyContent="center"
              alignItems="center"
            >
              <Stack sx={{ width: '40%' }} justifyContent="center" gap={2}>
                <img src={iconImg} style={{ width: '10%' }} />

                <Typography
                  fontSize="28px"
                  fontWeight="600"
                  color={darkTextColor}
                >
                  Our company was founded with the goal of improving patient
                  care and making it easier for medical specialists to connect
                  and collaborate.
                </Typography>
                <Typography
                  fontSize="16px"
                  fontWeight="600"
                  color={darkTextColor}
                >
                  medLynks Founders
                </Typography>
              </Stack>

              {/* <img  style={{ width: '50%' }} /> */}
              <Box
                component="img"
                sx={{
                  width: { md: '50%', sm: '60%', xs: '60%' },
                  height: { md: '100%', sm: '60%', xs: '60%' },
                }}
                alt="The house from the offer."
                src={founderimage}
              />
            </Stack>
          </Container>
        </div>
        <Footer />
      </Box>
    </>
  );
};

const CollapsableCard = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <Card sx={{ p: 2 }} onClick={() => setOpen(!open)} key={question}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        sx={{ width: '100%' }}
      >
        <Typography color={darkTextColor}>{question}</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div style={{ widht: '100%', height: '1px', background: '#DBDBDB' }} />
        {answer.map((text, index) => (
          <Typography key={index} sx={{ py: 1.5 }} color={darkTextColor}>
            {text}
          </Typography>
        ))}
      </Collapse>
    </Card>
  );
};

const buttonData = [
  { text: 'Connect with the right specialist for every patient.', img: frame1 },
  { text: 'Simplify patient referrals and save valuable time.', img: frame2 },
  {
    text: 'Enhance collaboration between healthcare specialists.',
    img: frame3,
  },
];
const ButtonCard = ({ text, selected, setSelected, index }) => {
  return (
    <Card
      sx={{
        p: 2,
        py: 4,
        backgroundColor: selected ? '#47BB92' : '#E0E6E0',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      key={text}
      onClick={() => setSelected(index)}
    >
      <Typography
        color={selected ? 'white' : darkTextColor}
        fontWeight="600"
        fontSize="14px"
      >
        {text}
      </Typography>
      <ArrowRight />
    </Card>
  );
};

export default Marketing;
