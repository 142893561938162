import {
  Avatar,
  Card,
  Stack,
  Typography,
  Grid,
  Link,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import {
  darkTextColor,
  GetImageFromUrl,
  MESSAGES_FOOTER_SIZE,
} from '../../constants';
import React from 'react';
import { useRef, useEffect, useState } from 'react';
import PdfImage from '../../Icons/pdfImage';
import JepgImage from '../../Icons/jepgImage';
import MoreOptions from '../../Icons/moreOptions';
import { LoadingCircularProgressSized } from '../../components/loadingCircularProgress';

const DateMarker = ({ date }) => {
  return (
    <Stack direction="row" gap={2}>
      <div
        style={{
          flex: 1,
          width: '100%',
          borderRadius: '4px',
          alignSelf: 'center',
          height: '1px',
          backgroundColor: '#D9D9D9',
        }}
      />
      <Typography
        variant="body1"
        fontWeight={600}
        color={darkTextColor}
        sx={{ opacity: 0.56 }}
      >
        {moment(date).date() === moment(new Date()).date() && 'Today, '}
        {`${moment(date).format('MMM')} ${moment(date).date()}`}
      </Typography>
      <div
        style={{
          flex: 1,
          width: '100%',
          borderRadius: '4px',
          alignSelf: 'center',
          height: '1px',
          backgroundColor: '#D9D9D9',
        }}
      />
    </Stack>
  );
};
const bottomTextField = 60;

const MessageBody = ({
  chats = { lastMessageTime: null },
  user,
  sender,
  selectedPatient,
  handleMarkAsUnread,
}) => {
  const [hovering, setHovering] = useState(null);
  const [showMessages, setShowMessages] = useState([]);
  const [height, setHeight] = useState();
  const containerRef = useRef();
  const [chat, setChat] = useState([]);

  const [chatBoxLoading, setChatBoxLoading] = useState(false);

  const handleScroll = () => {
    const chatContainer = containerRef.current;
    const scrollTop = chatContainer.scrollTop;

    if (scrollTop === 0) {
      // Calculate the number of messages to show based on the current scroll position
      const currentLength = showMessages.length;
      let numMessagesToShow = currentLength + 50;
      if (numMessagesToShow >= chat.length) {
        numMessagesToShow = chat.length;
      }
      setShowMessages(chat?.slice(0, numMessagesToShow));
    }
  };

  const updateHeight = () => {
    setTimeout(() => {
      const getEle = document.getElementById('scroll-container');
      if (getEle) {
        const elementHeight =
          window.innerHeight -
          getEle.getBoundingClientRect().top -
          bottomTextField -
          MESSAGES_FOOTER_SIZE;
        setHeight(elementHeight);
      }
    }, 100);
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        updateHeight();
      }, 100);
    }
  }, [containerRef, user, selectedPatient]);

  useEffect(() => {
    const handleResize = () => {
      updateHeight();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setShowMessages(chat?.slice(0, 50).reverse());
  }, [chat]);

  const updateChat = () => {
    if (chats.chat) {
      if (selectedPatient) {
        const tempChat = chats?.chat
          .filter((mes) => mes.patientId === selectedPatient)
          .reverse();
        setChat(tempChat);
      } else {
        const tempChat = chats?.chat
          .filter((mes) => mes.patientId === null)
          .reverse();
        setChat(tempChat);
      }
    }
  };
  const { lastMessageTime = null } = chats;
  useEffect(() => {
    updateChat();
  }, [lastMessageTime, chats]);

  useEffect(() => {
    if (sender) {
      updateChat();
      setChatBoxLoading(true);
      const loadingTimeout = setTimeout(() => {
        setChatBoxLoading(false);
        if (containerRef.current) {
          containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
      }, 500);
      return () => {
        clearTimeout(loadingTimeout);
      };
    }
  }, [sender, selectedPatient]);

  if (!chat) {
    return <></>;
  }

  return (
    <Stack
      sx={{
        height: height && height,
        bgcolor: '#F7F7F7',
        overflowY: 'scroll',
      }}
      ref={containerRef}
      id="scroll-container"
    >
      {chatBoxLoading ? (
        <LoadingCircularProgressSized />
      ) : (
        <>
          {showMessages.length < chat.length && (
            <Stack alignItems="center" justifyContent="center">
              <Typography
                color="primary"
                sx={{ pt: 1, cursor: 'pointer' }}
                onClick={() => handleScroll()}
              >
                Show more
              </Typography>
            </Stack>
          )}
          <Stack sx={{ p: 2 }}>
            {showMessages?.map((message, i) => {
              const lastMessage = i !== 0 ? showMessages[i - 1] : {};
              let date = false;
              if (lastMessage?.id) {
                if (
                  moment(lastMessage?.createdAt).date() !==
                  moment(message?.createdAt).date()
                ) {
                  date = true;
                }
              }
              if (message.senderId === user.id) {
                const avatar = GetImageFromUrl(user?.profile_picture_url);
                return (
                  <React.Fragment key={message.id}>
                    {(date || i === 0) && (
                      <DateMarker date={message?.createdAt} />
                    )}
                    <Stack
                      sx={{
                        width: 'auto',
                        maxWidth: '80%',
                        alignSelf: 'flex-end',
                        m: 1,
                      }}
                      gap={2}
                      direction="row"
                    >
                      <Stack
                        direction="column"
                        sx={{ width: '100%', minWidth: '80px' }}
                      >
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            fontWeight={600}
                            variant="body1"
                            color={darkTextColor}
                          >
                            You
                          </Typography>
                          <Typography
                            color={darkTextColor}
                            variant="body2"
                            sx={{ opacity: '0.75' }}
                          >
                            {moment(message.createdAt).format('h:mm A')}
                          </Typography>
                        </Stack>
                        {message.isFile ? (
                          <Grid
                            direction="row"
                            justifyContent="flex-end"
                            gap={2}
                            sx={{ mt: 2 }}
                            container
                          >
                            {message.content ? (
                              message.content.split(',').map((file) => (
                                <Card
                                  sx={{
                                    p: 2,
                                    minWidth: '200px',
                                    width: 'fit-content',
                                    maxWidth: '350px',
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    sx={{ flexWrap: 'nowrap', width: '100%' }}
                                    gap={1}
                                    alignItems="center"
                                  >
                                    {file.split('.').pop().toLowerCase() ===
                                    'pdf' ? (
                                      <PdfImage />
                                    ) : (
                                      <JepgImage />
                                    )}
                                    <Link href={file}>
                                      <Typography
                                        color={darkTextColor}
                                        variant="body2"
                                        // onClick={()=> }
                                      >
                                        {file
                                          ?.split('?Expires=')[0]
                                          ?.replace(/^.*[\\\/]/, '')}
                                      </Typography>
                                    </Link>
                                  </Stack>
                                </Card>
                              ))
                            ) : (
                              <></>
                            )}
                          </Grid>
                        ) : (
                          <Card sx={{ bgcolor: 'rgba(22,101,216, 0.2)', p: 2 }}>
                            <Typography
                              color={darkTextColor}
                              sx={{ overflowWrap: 'anywhere' }}
                            >
                              {message.content}
                            </Typography>
                          </Card>
                        )}
                      </Stack>
                      <Avatar
                        sx={{
                          width: '45px',
                          height: '50px',
                          borderRadius: '4px',
                        }}
                        src={avatar}
                        variant="square"
                      >
                        {user.firstName?.charAt(0)}
                      </Avatar>
                    </Stack>
                  </React.Fragment>
                );
              }
              const avatar = GetImageFromUrl(sender?.profile_picture_url);

              return (
                <React.Fragment key={message.id}>
                  {(date || i === 0) && (
                    <DateMarker date={message?.createdAt} />
                  )}
                  <Stack
                    sx={{
                      alignSelf: 'flex-start',
                      m: 1,
                      width: '100%',
                    }}
                    gap={2}
                    direction="row"
                  >
                    <Avatar
                      sx={{
                        width: '45px',
                        height: '50px',
                        borderRadius: '4px',
                      }}
                      src={avatar}
                      variant="square"
                    >
                      {sender.firstName?.charAt(0)}
                    </Avatar>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      onMouseEnter={() => setHovering(message.id)}
                      onMouseLeave={() => setHovering(null)}
                      sx={{ width: '100%' }}
                    >
                      <Stack
                        direction="column"
                        sx={{ width: 'auto', maxWidth: '80%' }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          gap={1}
                        >
                          <Typography
                            fontWeight={600}
                            variant="body1"
                            color={darkTextColor}
                          >
                            {sender.firstName}&nbsp;{sender.lastName}
                          </Typography>
                          <Typography
                            color={darkTextColor}
                            variant="body2"
                            sx={{ opacity: '0.75' }}
                          >
                            {moment(message.createdAt).format('h:mm A')}
                          </Typography>
                        </Stack>
                        {message.isFile ? (
                          <Grid
                            direction="row"
                            justifyContent="flex-start"
                            gap={2}
                            sx={{ mt: 2 }}
                            container
                          >
                            {message.content ? (
                              message.content.split(',').map((file) => (
                                <Card
                                  sx={{
                                    p: 2,
                                    minWidth: '200px',
                                    width: 'fit-content',
                                    maxWidth: '350px',
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    sx={{ flexWrap: 'nowrap', width: '100%' }}
                                    gap={1}
                                    alignItems="center"
                                  >
                                    {file.split('.').pop().toLowerCase() ===
                                    'pdf' ? (
                                      <PdfImage />
                                    ) : (
                                      <JepgImage />
                                    )}
                                    <Link href={file}>
                                      <Typography
                                        color={darkTextColor}
                                        variant="body2"
                                        // onClick={()=> }
                                      >
                                        {file
                                          ?.split('?Expires=')[0]
                                          ?.replace(/^.*[\\\/]/, '')}
                                      </Typography>
                                    </Link>
                                  </Stack>
                                </Card>
                              ))
                            ) : (
                              <></>
                            )}
                          </Grid>
                        ) : (
                          <Card sx={{ bgcolor: '#FFFFFF', p: 2 }}>
                            <Typography
                              color={darkTextColor}
                              sx={{ overflowWrap: 'anywhere' }}
                            >
                              {message.content}
                            </Typography>
                          </Card>
                        )}
                      </Stack>
                      <MessageMenu
                        id={message.id}
                        selected={hovering}
                        handleMarkAsUnread={handleMarkAsUnread}
                      />
                    </Stack>
                  </Stack>
                </React.Fragment>
              );
            })}
          </Stack>
        </>
      )}
    </Stack>
  );
};

const MessageMenu = ({ id, selected, handleMarkAsUnread }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        size="small"
        sx={{
          height: '30px',
          display: id === selected ? 'block' : 'none',
        }}
        onClick={(event) => handleClick(event)}
      >
        <MoreOptions />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem
          onClick={() => {
            handleMarkAsUnread(id);
            handleClose();
          }}
          sx={{ color: darkTextColor }}
        >
          Mark as unread
        </MenuItem>
      </Menu>
    </>
  );
};
export default MessageBody;
