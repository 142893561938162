const SearchIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.56"
      d="M7.20825 14.3695C8.83903 14.3695 10.3515 13.8259 11.5569 12.8805L14.3929 15.7165C14.582 15.9056 14.8182 16 15.0546 16C15.291 16 15.5273 15.9056 15.7164 15.7165C16.0945 15.3383 16.0945 14.7475 15.7164 14.3693L12.8804 11.5333C13.8021 10.328 14.3693 8.81548 14.3693 7.18466C14.3693 3.23783 11.1551 0 7.18466 0C3.23783 0 6.29432e-09 3.21419 6.29432e-09 7.18466C-0.000164839 11.1552 3.23761 14.3693 7.2083 14.3693L7.20825 14.3695ZM7.20825 1.91446C10.1152 1.91446 12.5022 4.27784 12.5022 7.20842C12.5022 10.1391 10.1388 12.5024 7.20825 12.5024C4.30134 12.5024 1.91429 10.139 1.91429 7.20842C1.91429 4.27779 4.27767 1.91446 7.20825 1.91446Z"
      fill="#212634"
    />
  </svg>
);

export default SearchIcon;
