import { IconButton, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { darkTextColor } from '../../constants';
import CloseLight from '../../Icons/closeLight';
import DragableIcon from '../../Icons/dragableIcon';
import CloseButton from '../closeButton';

const ItemTypes = {
  CARD: 'card',
};
const style = {
  border: '1px solid #E2E2E2',
  padding: '0.5rem 1rem',
  marginBottom: '.5rem',
  backgroundColor: 'white',
  width: '100%',
  cursor: 'move',
};
export const Card = ({ id, text, index, moveCard, removeDegree }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      style={{ ...style, opacity, color: 'black', pointerEvents: 'none' }}
      ref={ref}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={3} alignItems="center">
          <div
            style={{ pointerEvents: 'all', cursor: 'move' }}
            data-handler-id={handlerId}
          >
            <DragableIcon />
          </div>
          <Typography color={darkTextColor}>{text}</Typography>
        </Stack>
        <IconButton
          style={{ pointerEvents: 'all', cursor: 'pointer' }}
          onClick={() => removeDegree(text)}
        >
          <CloseLight />
        </IconButton>
      </Stack>
    </div>
  );
};
// const style = {
//   border: '1px solid #E2E2E2',
//   padding: '0.5rem 1rem',
//   marginBottom: '.5rem',
//   backgroundColor: 'white',
//   width: '100%',
//   cursor: 'move',
// };
