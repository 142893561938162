import Navbar from '../../components/navbar';
import { useEffect, useState, useContext, useRef } from 'react';
import { Typography, Card, Box, Stack, Button } from '@mui/material';
import UserContext from '../../contexts/user/context';
import { darkTextColor, RESPONSE_STATUS } from '../../constants';
import { LoadingButton } from '@mui/lab';
import { Container } from '@mui/system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Users from '../../services/users';
import AlertDialog from '../../components/alertDialog';
import AccountDetails from './accountDetails';
import Staffs from '../../services/staff';
import { LoadingCircularProgress } from '../../components/loadingCircularProgress';

const getPage = (type) => {
  switch (type) {
    case 'account':
      return 1;
    case 'staff':
      return 2;
  }
};
const SettingsContent = () => {
  const { user, token, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [errMessage, setErrMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const [height, setHeight] = useState();
  const containerRef = useRef();
  const [loading, setLoading] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const params = useParams();
  const [passwordError, setPasswordError] = useState('');
  const [staffMembers, setStaffMembers] = useState(
    user?.staffs.map(({ name, email }) => ({
      name,
      email,
    })) || [],
  );
  const { type } = params;
  const page = getPage(type);

  const updateHeight = () => {
    const elementHeight =
      window.innerHeight -
      containerRef.current.getBoundingClientRect().top -
      10;
    setHeight(elementHeight);
  };

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (user && containerRef.current) {
      const getEle = document.getElementById('settings-container');
      if (getEle) {
        const elementHeight =
          window.innerHeight - getEle.getBoundingClientRect().top - 20;
        setHeight(elementHeight);
      }
    }
  }, [user, containerRef.current]);

  // usePrompt('Are you sure you would like to leave?', !isBlocking);

  const handleAddStaffMember = (values) => {
    const isDuplicate = staffMembers.find((f) => f.email === values.email);
    if (isDuplicate) {
      setErrorDialog(true);
      setErrMessage('Staff member already added');
    }
    if (!isDuplicate) {
      setStaffMembers([...staffMembers, values]);
    }
  };

  const formikChangePassword = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      reEnterPassword: '',
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('Password is a required field'),
      password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('Password is a required field')
        .max(24, 'Password should be less than 24 characters')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Password must contain at least 8 characters, at least one uppercase, at least one lowercase, at least one number, and one special case character.',
        ),
      reEnterPassword: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('Repeat password is a required field'),
    }),
    onSubmit: async (values) => {
      const { password, reEnterPassword, currentPassword } = values;

      if (password !== reEnterPassword) {
        setPasswordError('Passwords do not match.');
        return;
      }
      try {
        setLoading(true);
        const response = await Users.updatePassword(
          token,
          currentPassword,
          password,
        );
        if (response.status === RESPONSE_STATUS.success) {
          formikChangePassword.resetForm();
        }
      } catch (e) {
        setErrMessage(e.message);
        setErrorDialog(true);
      } finally {
        setLoading(false);
      }

      // handleCreateAccount(values);
    },
  });
  // useEffect(() => {
  //   if (!changed) {
  //     setIsBlocking(false);
  //   } else {
  //     setIsBlocking(true);
  //   }
  // }, [initialValues, changed]);
  const updateStaff = async () => {
    if (staffMembers) {
      try {
        setLoading(true);
        const response = await Staffs.update(token, staffMembers);

        if (response.status) {
          setUser(response.body);
          setIsBlocking(true);
        }
      } catch {
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSave = () => {
    if (page === 1) {
      formikChangePassword.handleSubmit();
    }
    if (page === 2) {
      updateStaff();
    }
  };

  const handleRemoveStaffMember = (member) => {
    setStaffMembers(staffMembers.filter((item) => item.email !== member.email));
  };

  useEffect(() => {
    if (
      formikChangePassword.values.password ==
      formikChangePassword.values.reEnterPassword
    ) {
      setPasswordError('');
    }
  }, [formikChangePassword]);

  useEffect(() => {
    if (page === 2) {
      const getDefaultStaffs = user?.staffs.map(({ name, email }) => ({
        name,
        email,
      }));
      const getNewStaffs = staffMembers.map(({ name, email }) => ({
        name,
        email,
      }));
      if (JSON.stringify(getDefaultStaffs) !== JSON.stringify(getNewStaffs)) {
        setIsBlocking(false);
      } else {
        setIsBlocking(true);
      }
    }
    if (page === 1) {
      if (
        formikChangePassword?.values.currentPassword &&
        formikChangePassword?.values.password &&
        formikChangePassword?.values.reEnterPassword
      ) {
        setIsBlocking(false);
      } else {
        setIsBlocking(true);
      }
    }
  }, [staffMembers, page, formikChangePassword]);

  useEffect(() => {
    if (page === 1) {
      setStaffMembers(
        user?.staffs.map(({ name, email }) => ({
          name,
          email,
        })) || [],
      );
    }
  }, [page]);

  useEffect(() => {
    if (type !== 1) {
      navigate('/settings/account');
    }
  }, [type]);
  return (
    <>
      <Box
        sx={{
          maxHeight: '100vh',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <AlertDialog
          title="Error"
          open={errorDialog}
          setOpen={setErrorDialog}
          message={errMessage}
          error
        />
        <Navbar />
        <Card sx={{ p: 2, px: 3 }}>
          <Stack
            direction="row"
            sx={{ width: '100%' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              color={darkTextColor}
              variant="h5"
              sx={{ fontWeight: 600 }}
            >
              Settings
            </Typography>
            <Stack direction="row" gap={2}>
              <Button
                id="discard"
                size="large"
                variant="contained"
                disabled={isBlocking}
                onClick={() => {
                  formikChangePassword.resetForm();
                }}
                sx={{
                  width: '10rem',
                  height: 40,
                  bgcolor: 'rgba(47, 47, 47, 0.14)',
                  border: '0px solid #47BB92',
                  color: '#2F2F2F',
                  '&:hover': {
                    bgcolor: 'rgba(47, 47, 47, 0.14)',

                    color: '#2F2F2F',
                  },
                  '&:disabled': {
                    color: darkTextColor,
                    opacity: 0.5,
                  },
                }}
              >
                Discard
              </Button>
              <LoadingButton
                color="primary"
                // disabled={formik.isSubmitting}
                size="large"
                variant="contained"
                onClick={() => handleSave()}
                loading={loading}
                disabled={isBlocking}
                loadingPosition="end"
                sx={{
                  width: '10rem',
                  height: 40,
                  '&:disabled': {
                    color: darkTextColor,
                    opacity: 0.5,
                  },
                }}
                // loadingIndicator="Finding Best Match..."
              >
                Save changes
              </LoadingButton>
            </Stack>
          </Stack>
        </Card>
        <Stack direction="row" sx={{ overflow: 'hidden' }}>
          {/* <PermanentDrawerLeft page={type} /> */}
          <Container
            id="settings-container"
            ref={containerRef}
            maxWidth="md"
            sx={{
              my: 2,
              overflowY: 'scroll',
              height: height ? `${height}px` : '70vh',
            }}
          >
            {page === 1 ? (
              <AccountDetails
                passwordError={passwordError}
                user={user}
                formik={formikChangePassword}
              />
            ) : (
              <></>
            )}
            {/* {page === 2 ? (
              <StaffMembers
                staffMembers={staffMembers}
                handleAddStaffMember={handleAddStaffMember}
                handleRemoveStaffMember={handleRemoveStaffMember}
                isBlocking={isBlocking}
              />
            ) : (
              <></>
            )} */}
          </Container>
        </Stack>
      </Box>
    </>
  );
};
const Settings = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    return <LoadingCircularProgress />;
  }
  return <SettingsContent />;
};

export default Settings;
