import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RESPONSE_STATUS } from '../../constants';
import Auth from '../../services/auth';
import Messages from '../../services/messages';
import Patients from '../../services/patients';
import { UserContext } from './context';
import io from 'socket.io-client';
import configuration from '../../config';

const noAuthEndpoints = ['login', '', 'doctors', 'sign-up'];
const authEndpoints = [
  'overview',
  'find',
  'referrals',
  'verification',
  'doctor',
  'patient',
  'profile',
  'settings',
  'messages',
  'sign-up',
];

export function UserProvider(props) {
  const { children } = props;
  const [token, setToken] = useState('');
  const [user, setUser] = useState(null);
  const [allDoctors, setAllDoctors] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [serachDoctorsResult, setSerachDoctorsResult] = useState([]);
  const [messageChannels, setMessageChannels] = useState([]);
  const [allReferrals, setAllReferrals] = useState([]);
  const [chats, setChats] = useState([]);
  const [isConnected, setIsConnected] = useState();
  const [socket, setSocket] = useState();
  const navigate = useNavigate();
  const [location, setLocation] = useState();
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [acceptReferralDialogue, setAcceptReferralDialogue] = useState();
  const [healthcareEmployer, setHealthcareEmployer] = useState();
  const [distance, setDistance] = useState('');
  const [insurance, setInsurance] = useState('');
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const specialty = query.get('specialty');

  const [primary, setPrimary] = useState(specialty ? specialty : '');
  const [loading, setLoading] = useState(true);

  const [chatLoading, setChatLoading] = useState(true);

  const [siteReferralLoading, setSiteReferralLoading] = useState(true);

  const locationEndpoint = useLocation();

  const handleSetToken = (t) => {
    setToken(t);
  };

  const updateChat = (data) => {
    setChats(data.filter((chat) => chat?.user));
  };

  const handleUpdateChat = useCallback(
    ({ msg, user, patientId }) => {
      msg.patientId = patientId ? parseInt(patientId) : null;
      if (chats.length) {
        const isUserThere = chats.find((chat) => chat.user.id === msg.senderId);
        if (isUserThere) {
          const tempChats = chats
            .filter((chat) => chat.user)
            .map((chat) => {
              if (chat.user.id === msg.senderId) {
                chat.chat.push(msg);
                chat.lastMessageTime = msg.createdAt;

                return chat;
              }
              return chat;
            });
          updateChat(tempChats);
        } else {
          const tempChat = [...chats];
          const newChat = {
            lastMessageTime: msg.createdAt,
            user,
            chat: [msg],
          };
          tempChat.push(newChat);
          updateChat(tempChat);
          //
        }
      } else {
        const tempChat = [...chats];
        const newChat = {
          lastMessageTime: msg.createdAt,
          user,
          chat: [msg],
        };
        tempChat.push(newChat);
        updateChat(tempChat);
        //
      }
    },
    [chats],
  );

  const handleUpdateChatOnRec = useCallback(
    ({ msg, user, patientId }) => {
      msg.patientId = patientId ? parseInt(patientId) : null;
      if (chats.length) {
        const isUserChat = chats.find((chat) => chat?.user?.id === user?.id);
        if (isUserChat) {
          const tempChats = chats.map((chat) => {
            if (chat?.user?.id === user?.id) {
              chat.chat.push(msg);
              chat.lastMessageTime = msg.createdAt;
              return chat;
            }
            return chat;
          });
          updateChat(tempChats);
        } else {
          const tempChat = [...chats];
          const newChat = {
            lastMessageTime: msg.createdAt,
            user,
            chat: [msg],
          };
          tempChat.push(newChat);
          updateChat(tempChat);
        }
      } else {
        const tempChat = [...chats];
        const newChat = {
          lastMessageTime: msg.createdAt,
          user,
          chat: [msg],
        };
        tempChat.push(newChat);
        updateChat(tempChat);
      }
    },
    [chats],
  );

  const updatePatients = useCallback(() => {
    getReferrals();
  }, [token]);
  useEffect(() => {
    if (!token && !loading) {
      if (!noAuthEndpoints.includes(locationEndpoint.pathname.substring(1))) {
        navigate('/');
      }
    }
    if (
      token &&
      user &&
      !loading &&
      (!user?.email_verified || !user?.doctor_verified) &&
      authEndpoints.includes(
        locationEndpoint.pathname.substring(1)?.split('/')[0],
      ) &&
      locationEndpoint.pathname.substring(1)?.split('/')[0] !==
        'verification' &&
      locationEndpoint.pathname.substring(1)?.split('/')[0] !== 'sign-up'
    ) {
      navigate('/verification');
    } else if (token && !loading) {
      if (
        !authEndpoints.includes(
          locationEndpoint.pathname.substring(1)?.split('/')[0],
        )
      ) {
        navigate('/overview');
      }
    }
  }, [locationEndpoint, loading, token, user]);

  useEffect(() => {
    setTimeout(setLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (socket && user) {
      socket.on('newMessage/user/' + user.id, handleUpdateChat);
      socket.on('newMessage/send/' + user.id, handleUpdateChatOnRec);
      socket.on('newPatient' + user.id, updatePatients);

      return () => {
        socket.off('newMessage/send/' + user.id, handleUpdateChatOnRec);
        socket.off('newMessage/user/' + user.id, handleUpdateChat);
        socket.off('newPatient' + user.id, updatePatients);
      };
    }
  }, [socket, chats]);
  useEffect(() => {
    if (socket && user) {
      // socket.on('newMessage/user/' + user.id, handleUpdateChat);
      // socket.on('newMessage/send/' + user.id, handleUpdateChatOnRec);
      socket.on('disconnect', () => {
        setIsConnected(false);
      });

      return () => {
        // socket.off('newMessage/send/' + user.id, handleUpdateChatOnRec);
        // socket.off('newMessage/user/' + user.id, handleUpdateChat);
        socket.off('disconnect');
      };
    }
  }, [socket]);
  useEffect(() => {
    const getSocket = async () => {
      try {
        const tempSocket = await io(`${configuration.api.url}`, {
          transports: ['websocket'],
          path: '/socket-chat',
          // secure: true,
          query: {
            token: `Bearer ${token}`,
          },
        });
        setIsConnected(tempSocket.connected);
        setSocket(tempSocket);
      } catch (e) {
        console.log(e);
      }
      // socketio.connect(`${configuration.api.url}/chat`),
    };
    if (user && token && !isConnected) {
      getSocket();
    }
  }, [user]);

  const getReferrals = async () => {
    try {
      const response = await Patients.get(token);

      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        setAllReferrals(response?.body);
        // updateReferrals(response?.body);
        return;
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      if (siteReferralLoading) {
        setSiteReferralLoading(false);
      }
    }
  };

  const getMessages = async () => {
    try {
      const response = await Messages.get(token, user.id);

      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        setMessageChannels(response.channels);
        const chatsArr = [];
        const tempMessages = response.body;
        tempMessages.forEach((parent) => {
          let reciverId =
            parent.receiverId === user.id ? parent.senderId : parent.receiverId;

          const isAlready = chatsArr.find((arr) =>
            arr.find(
              (x) => x.senderId === reciverId || x.receiverId === reciverId,
            ),
          );
          if (!isAlready) {
            const getChatWithUser = tempMessages.filter(
              (child) =>
                child.senderId === reciverId || child.receiverId === reciverId,
            );
            chatsArr.push(
              getChatWithUser.sort(
                (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
              ),
            );
          }
        });
        const addUserToChatArr = chatsArr
          .map((chat) => {
            const reciverId =
              chat[0]?.receiverId === user.id
                ? chat[0].senderId
                : chat[0].receiverId;
            const currentUser = response.users.find(
              (use) => use.id === reciverId,
            );
            return {
              user: currentUser,
              chat: chat,
              lastMessageTime: chat[chat.length - 1]?.createdAt,
            };
          })
          ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        updateChat(addUserToChatArr);
        return;
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setChatLoading(false);
    }
  };

  useEffect(() => {
    if (user && token) {
      getMessages();
    }
  }, [user]);

  useEffect(() => {
    if (user && token) {
      getReferrals();
      // const intervalId = setInterval(async () => {
      //   getReferrals();
      // }, 30000);

      // return () => clearInterval(intervalId);
    }
  }, [token, user]);

  const handleLogout = (navto = '/') => {
    navigate(`${navto}`);
    sessionStorage.clear();
    setUser();
    setToken();
    setAllReferrals([]);
    setChats([]);
    setSocket();
    setLoading(true);
    setChatLoading(true);
    // sessionStorage.setItem('refresh_token', token);
    // sessionStorage.setItem('user', JSON.stringify(user));
  };
  useEffect(() => {
    const refreshToken = async () => {
      try {
        const response = await Auth.getRefresh(token);
        // console.log('getting refresh token');
        if (response && response.status === 'success') {
          setToken(response.token);
          // console.log('refresh token updated');
        }
      } catch (error) {
        console.log(error);
      }
    };
    let intervalId = 0;
    if (token) {
      const data = JSON.parse(atob(token.split('.')[1]));
      if (data.exp) {
        intervalId = setInterval(
          refreshToken,
          (data.exp - (data.iat + 10)) * 1000,
        );
      }
    }
    return () => clearInterval(intervalId);
  }, [token]);

  useEffect(() => {
    if (token) {
      // console.log('updating session token', token);
      sessionStorage.setItem('refresh_token', token);
    }
    if (user) {
      sessionStorage.setItem('user', JSON.stringify(user));
    }
  }, [token, user]);

  useEffect(() => {
    const getToken = sessionStorage.getItem('refresh_token');
    if (getToken) {
      setToken(getToken);
    }
  }, []);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await Auth.getUser(token);
        if (response && response.status === 'success') {
          setUser(response.user);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingState(false);
      }
    };
    if (token) {
      getUser();
    }
  }, [token]);

  const contextValue = useMemo(
    () => ({
      user,
      token,
      setToken: handleSetToken,
      setUser,
      setAllDoctors,
      allDoctors,
      loadingState,
      serachDoctorsResult,
      setSerachDoctorsResult,
      setLoadingState,
      handleLogout,
      allReferrals,
      setAllReferrals,
      setSocket,
      socket,
      chats,
      setChats,
      setLocation,
      location,
      lat,
      setLat,
      long,
      setLong,
      acceptReferralDialogue,
      setAcceptReferralDialogue,
      setPrimary,
      primary,
      setDistance,
      distance,
      setHealthcareEmployer,
      healthcareEmployer,
      setInsurance,
      insurance,
      siteReferralLoading,
      chatLoading,
      setMessageChannels,
      messageChannels,
    }),
    [
      socket,
      allDoctors,
      allReferrals,
      handleLogout,
      loadingState,
      serachDoctorsResult,
      token,
      user,
      chats,
      location,
      long,
      lat,
      acceptReferralDialogue,
      primary,
      healthcareEmployer,
      insurance,
      siteReferralLoading,
      chatLoading,
      setMessageChannels,
      messageChannels,
    ],
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

export default UserProvider;
