const HealthBuildingLogo = ({ fill = '#615B99' }) => (
  <svg
    width="14"
    height="20"
    viewBox="0 0 14 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5716 4.42847C12.8082 4.42847 13.0001 4.62047 13.0001 4.85709V18.5713C13.0001 18.8081 12.8082 18.9999 12.5716 18.9999H1.42848C1.19185 18.9999 1 18.8081 1 18.5713V4.85709C1 4.62047 1.19186 4.42847 1.42848 4.42847H12.5716ZM12.143 5.28571H1.85724V18.1429H12.143V5.28571Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.28516 3.14283C2.28516 2.90621 2.47701 2.71436 2.71363 2.71436H11.2853C11.522 2.71436 11.7138 2.90621 11.7138 3.14283V4.85717C11.7138 5.0938 11.522 5.28579 11.2853 5.28579H2.71363C2.47701 5.28579 2.28516 5.0938 2.28516 4.85717V3.14283ZM3.14225 3.57145V4.42855H10.8566V3.57145H3.14225Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.85547 1.42862C4.85547 1.19186 5.04732 1 5.28394 1H8.71263C8.94925 1 9.1411 1.19186 9.1411 1.42862V3.14282C9.1411 3.37958 8.94925 3.57144 8.71263 3.57144H5.28394C5.04732 3.57144 4.85547 3.37958 4.85547 3.14282V1.42862ZM5.71257 1.8571V2.71434H8.28401V1.8571H5.71257Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8566 7V9.57144H8.28516V7H10.8566ZM9.9995 7.85724H9.14225V8.71434H9.9995V7.85724Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.71206 7V9.57144H3.14062V7H5.71206ZM4.85497 7.85724H3.99772V8.71434H4.85497V7.85724Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8566 11.2856V13.8571H8.28516V11.2856H10.8566ZM9.9995 12.1427H9.14225V13H9.9995V12.1427Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.71206 11.2856V13.8571H3.14062V11.2856H5.71206ZM4.85497 12.1427H3.99772V13H4.85497V12.1427Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.9995 14.7144C7.94626 14.7144 8.71384 15.4819 8.71384 16.4287V18.5715C8.71384 18.8083 8.52184 19.0001 8.28522 19.0001H5.71378C5.47715 19.0001 5.28516 18.8083 5.28516 18.5715V16.4287C5.28516 15.4819 6.05273 14.7144 6.9995 14.7144ZM7.8566 16.4287C7.8566 15.9553 7.4729 15.5716 6.9995 15.5716C6.5261 15.5716 6.1424 15.9553 6.1424 16.4287V18.143H7.8566V16.4287Z"
      fill={fill}
      stroke={fill}
      strokeWidth="0.25"
    />
  </svg>
);

export default HealthBuildingLogo;
