import Navbar from '../../components/navbar';
import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Grid,
  Typography,
  Autocomplete,
  Button,
  Card,
  Tooltip,
  Chip,
  Box,
  Avatar,
  Stack,
  TextField,
  Popper,
  IconButton,
  FormControlLabel,
  Radio,
  Switch,
} from '@mui/material';
import UserContext from '../../contexts/user/context';
import {
  darkTextColor,
  encryptNumber,
  getEmployer,
  getEmployerList,
  GetImageFromUrl,
  getRangeOfYears,
  handleFormatedAddress,
  RESPONSE_STATUS,
} from '../../constants';
import { LoadingButton } from '@mui/lab';
import { Container } from '@mui/system';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DefaultImage from '../../Icons/defaultImage';
import TooltipIcon from '../../Icons/tooltipIcon';
import CustomInputField from '../../components/customInputField';
import { CustomTextFieldNoBorders } from '../../components/customTextField';
import { MedicalSchoolList } from '../../constants/medicalSchool';
import Close from '../../Icons/close';
import AddressField from '../SignUp/SetUpAccount/addressField';
import CloseLight from '../../Icons/closeLight';
import RemoveButton from '../../components/removeButton';
import { useNavigate } from 'react-router-dom';
import { usePrompt } from '../../hooks/useBlocker';
import { specialtyFilters } from '../../constants/specialties_newest';
import { CharacterLeftTypography } from '../../components/characterLeftTypography';
import { LoadingCircularProgress } from '../../components/loadingCircularProgress';
import {
  DegreeSelection,
  DegreeSelectionNew,
} from '../../components/degreeSelection';
import ListOfInsurances from '../../constants/insurances';
import Selection from './selection';
import Users from '../../services/users';
import AlertDialog from '../../components/alertDialog';
import {
  DragableListContainer,
  DragableListItem,
} from '../../components/dragableList';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CircleButton } from './circleButton';
import { TextMaskCustom } from '../../components/phoneNumberField';
import { FooterVerifiedUser } from '../../components/footer';

const getFellowships = (fellowships) => {
  if (fellowships) {
    return fellowships.map((fellow) => ({
      fellowship: fellow.name,
      year: fellow.year,
    }));
  }
  return [];
};

const getLocations = (locations) => {
  if (locations) {
    return locations.map((location) => location.name);
  }
  return [];
};

const DoctorStatus = [
  'Attending Physician',
  'Fellow',
  'Resident',
  'Nurse Practitioner',
  'Physician Assistant',
];

const defaultAvailability = [
  '1-2 Weeks',
  '1 month',
  '2 months',
  '3 months',
  '6 months',
  'Custom',
];
const EditContent = () => {
  const { user, token, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState();
  const [imageErr, setImageErr] = useState();
  const [errMessage, setErrMessage] = useState('');
  const [errorDialog, setErrorDialog] = useState(false);
  const [hospitalLocations, setHospitalLocation] = useState([]);
  const [insurance, setInsurance] = useState('');
  const [height, setHeight] = useState();
  const containerRef = useRef();
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [changed, setChanged] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [checkErrors, setCheckErrors] = useState(false);

  const [degree, setDegree] = useState('');

  const [specialities, setSpecialities] = useState();
  const [selection, setSelection] = useState();

  const [currentHospital, setCurrentHospital] = useState('');
  const [customAvailablity, setCustomAvailablity] = useState(false);

  const valuesRef = useRef();
  const degreeContainerRef = useRef();
  const [degreeCards, setDegreeCards] = useState(
    user?.prefix?.split(',').map((value, index) => {
      return { id: index, value: value };
    }),
  );

  const handleAddSpec = () => {
    if (user.specialties.length) {
      const filteredData = user.specialties
        .map((x) => {
          if (x.class === 'main') {
            const child = user.specialties
              .filter(
                (sub) => sub.class === 'sub' && sub.parent === x.specialty,
              )
              .map((sub) => ({ specialty: sub.specialty }));
            x.sub = [...child];
            return x;
          }
        })
        .filter((spec) => spec);
      return filteredData;
    }
    return [];
  };

  const [specialtiesSelected, setSpecialitiesSelected] = useState();

  useEffect(() => {
    setSpecialitiesSelected(handleAddSpec());
  }, []);
  const formik = useFormik({
    initialValues: initialValues || {
      about: user?.about || '',
      medicalSchool: user?.medical_school || '',
      medicalSchoolYear: user?.medical_school_year?.toString() || '',
      residencyYear: user?.residency_year?.toString() || '',
      residency: user?.residency || '',
      degree: user?.prefix?.split(',') || '',
      fellowship: '',
      fellowshipYear: null,
      fellowships: getFellowships(user?.fellowships) || [],
      healthCareEmployer: user?.location_name || '',
      locations: getLocations(user?.locations) || [],
      addresses: user?.address || [],
      insurances: getLocations(user?.insurances) || [],
      status: user?.status || '',
      firstName: user?.firstName,
      lastName: user?.lastName,
      availability: user?.isAvailable || false,
      officeNumber: user?.officeNumber || '',
      availabilityTime: user?.availabilityTime || '',
      staffMembers:
        user?.staffs.map(({ name, email }) => ({
          name,
          email,
        })) || [],
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      availability: Yup.boolean(),
      officeNumber: Yup.string()
        .length(14, 'Must be exactly 10 digits')
        .nullable(),
      availabilityTime: Yup.string(),
      staffMembers: Yup.array(),
      about: Yup.string().max(500),
      medicalSchool: Yup.string().required('Medical school is required'),
      status: Yup.string('Doctor status is required').required(
        'Doctor status is required',
      ),
      residency: Yup.string().when('status', {
        is: (status) =>
          status == 'Resident' ||
          status == 'Fellow' ||
          status == 'Attending Physician',
        then: Yup.string().required('Residency is required'),
        otherwise: Yup.string().nullable(true),
      }),
      medicalSchoolYear: Yup.string().required('Year is required'),
      residencyYear: Yup.string().when('status', {
        is: (status) =>
          status == 'Resident' ||
          status == 'Fellow' ||
          status == 'Attending Physician',
        then: Yup.string().required('Year is required'),
        otherwise: Yup.string().nullable(true),
      }),
      degree: Yup.array()
        .min(1, 'Degree field must have at least one item')
        .required('Degree is required'),
      addresses: Yup.array()
        .min(1, 'Address must have at least one item')
        .required('Address is required'),
    }),
    onSubmit: async (values) => {
      try {
        if (!specialtiesSelected.length) {
          setErrMessage('At least one specialty is required');
          setErrorDialog(true);
          return;
        }
        setLoading(true);
        let fellow;
        if (values.fellowships) {
          fellow = values.fellowships.map((fel) => ({
            fellowship: fel.fellowship,
            year: fel.year.toString()?.replace('(expected)', '')?.trim(),
          }));
        }
        const checkPrimaryAddressCheck = values.addresses.find(
          (add) => add.is_primary,
        );
        if (!checkPrimaryAddressCheck) {
          setErrMessage('No primary address selected');
          setErrorDialog(true);
          return;
        }
        const response = await Users.edit(
          token,
          values.about,
          values.residency,
          values.medicalSchool,
          values.residencyYear?.replace('(expected)', '').trim(),
          values.medicalSchoolYear,
          fellow,
          values.degree.toString(),
          selectedImage,
          typeof selectedImage === 'string',
          values.healthCareEmployer,
          values.addresses,
          values.insurances,
          values.locations,
          specialtiesSelected,
          values.status,
          values.firstName,
          values.lastName,
          values.availability,
          values.officeNumber,
          values.availabilityTime,
          values.staffMembers,
        );

        if (response.status === RESPONSE_STATUS.success) {
          setUser(response.body);
          if (response.body.isAvailable !== null) {
            formik.setFieldValue('availability', response.body.isAvailable);
          }
          setChanged(false);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    // enableReinitialze: true,
  });

  const formikStaff = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email must be valid')
        .required('Email is a required field'),
      name: Yup.string().required('Name is a required field'),
    }),
    onSubmit: (values, { resetForm }) => {
      const isEmailThere = formik.values.staffMembers?.find(
        (value) => value.email === values.email,
      );
      if (isEmailThere) {
        return;
      }
      const updatedArray = [...formik.values.staffMembers, values];
      formik.setFieldValue('staffMembers', updatedArray);
      resetForm();
    },
  });

  useEffect(() => {
    if (user) {
      if (user?.profile_picture_url) {
        setSelectedImage(GetImageFromUrl(user?.profile_picture_url));
      }
    }
  }, [user]);

  const handleAddDegree = (value, add = 'default') => {
    if (
      value?.degree ||
      degree?.degree ||
      !formik.values.degree?.includes(value?.degree) ||
      !formik.values.degree?.includes(degree?.degree)
    ) {
      let updatedArray = [...formik.values.degree];

      if (add === 'default') {
        updatedArray = [...formik.values.degree, degree];
      } else {
        updatedArray = [...formik.values.degree, value.degree];
      }
      setDegree('');
      formik.setFieldValue('degree', updatedArray);
    }
  };

  const handleAddInsurance = () => {
    const isDuplicate = formik.values.insurances.find((f) => f === insurance);
    if (isDuplicate) {
      setErrorDialog(true);
      setErrMessage('Please enter different entry');
      setInsurance('');
    }
    if (!isDuplicate) {
      formik.setFieldValue('insurances', [
        ...formik.values.insurances,
        insurance,
      ]);
      setInsurance('');
    }
  };

  const handleRemoveInsurance = (toDel) => {
    formik.setFieldValue(
      'insurances',
      formik.values.insurances.filter((item) => item !== toDel),
    );
  };

  const updatePlaces = (places) => {
    try {
      if (places) {
        const { formatted_address, vicinity, name, formatted_phone_number } =
          places[0];
        const postal_code = places[0].address_components.find((add) =>
          add.types.find((type) => type === 'postal_code'),
        );
        const city = places[0].address_components.find((add) =>
          add.types.find((type) => type === 'locality'),
        );

        const state = places[0].address_components.find((add) =>
          add.types.find((type) => type === 'administrative_area_level_1'),
        );
        const newAddress = {
          formatted_address: vicinity,
          full_address: formatted_address,
          name,
          postal_code: postal_code.long_name || '',
          latitude:
            places[0].geometry.location.lat() ||
            places[0].geometry.viewport.ab.lo,
          longitude:
            places[0].geometry.location.lng() ||
            places[0].geometry.viewport.Fa.lo,
          is_primary: formik?.values?.addresses?.length ? false : true,
          city: city.long_name || '',
          state: state.short_name || '',
          telephone: formatted_phone_number
            ?.replace('(', '')
            .replace(')', '')
            .replace(' ', '-'),
        };
        const findSimilarAdd = formik.values.addresses.filter(
          (add) => add.full_address === newAddress.full_address,
        );
        if (findSimilarAdd.length) {
          setErrorDialog(true);
          setErrMessage('Address already added');
          return;
        }
        formik.setFieldValue('addresses', [
          ...formik.values.addresses,
          newAddress,
        ]);
      } else {
        setErrorDialog(true);
        setErrMessage('Please enter a addresss');
      }
    } catch {
      setErrorDialog(true);
      setErrMessage('Please enter in a full street address.');
    }
  };

  const handleRemoveAddress = (formatted_address) => {
    formik.setFieldValue(
      'addresses',
      formik.values.addresses.filter(
        (item) => item.formatted_address !== formatted_address,
      ),
    );
  };

  const handleSetPrimaryAddress = (formatted_address) => {
    const tempAdd = formik.values.addresses.map((add) => {
      if (add.formatted_address === formatted_address) {
        add.is_primary = true;
        return add;
      }
      add.is_primary = false;
      return add;
    });
    setChanged(true);
    formik.setFieldValue('addresses', tempAdd);
  };

  const handleAddFellowShips = () => {
    if (formik.values.fellowship && formik.values.fellowshipYear) {
      const isDuplicate = formik.values.fellowships.find(
        (f) =>
          f.fellowship === formik.values.fellowship &&
          f.year.toString() ===
            formik.values.fellowshipYear
              .toString()
              ?.replace('(expected)', '')
              ?.trim(),
      );
      if (isDuplicate) {
        setErrorDialog(true);
        setErrMessage('Please enter different entry');
      }
      if (!isDuplicate) {
        formik.setFieldValue('fellowships', [
          ...formik.values.fellowships,
          {
            fellowship: formik.values.fellowship,
            year: formik.values.fellowshipYear
              .replace('(expected)', '')
              ?.trim(),
          },
        ]);
        formik.setFieldValue('fellowship', '');
        formik.setFieldValue('fellowshipYear', null);
      }
    }
  };

  const handleRemoveFellowShip = (name) => {
    formik.setFieldValue(
      'fellowships',
      formik.values.fellowships.filter((item) => item.fellowship !== name),
    );
  };

  const updateHeight = () => {
    const elementHeight =
      window.innerHeight -
      containerRef.current.getBoundingClientRect().top -
      10;
    setHeight(elementHeight);
  };

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (user && containerRef.current) {
      const getEle = document.getElementById('settings-container');
      if (getEle) {
        const elementHeight =
          window.innerHeight - getEle.getBoundingClientRect().top - 20;
        setHeight(elementHeight);
      }
    }
  }, [user, containerRef.current]);

  useEffect(() => {
    if (formik.values.healthCareEmployer) {
      const employer = getEmployer(formik.values.healthCareEmployer);
      if (employer && employer?.locations) {
        setHospitalLocation(employer.locations);
      }
    }
  }, [formik.values.healthCareEmployer]);

  useEffect(() => {
    if (user) {
      setDegreeCards(
        user?.prefix?.split(',').map((value, index) => {
          return { id: index, value: value };
        }),
      );
      if (user?.availabilityTime) {
        if (!defaultAvailability.includes(user?.availabilityTime)) {
          setCustomAvailablity(true);
        }
      }
      setInitialValues({
        about: user?.about || '',
        medicalSchool: user?.medical_school || '',
        medicalSchoolYear: user?.medical_school_year?.toString() || '',
        residencyYear: user?.residency_year?.toString() || '',
        residency: user?.residency || '',
        degree: user?.prefix?.split(',') || '',
        fellowship: '',
        fellowshipYear: null,
        fellowships: getFellowships(user?.fellowships) || [],
        healthCareEmployer: user?.location_name || '',
        locations: getLocations(user?.locations) || [],
        addresses: user?.address || [],
        insurances: getLocations(user?.insurances) || [],
        status: user?.status,
        firstName: user?.firstName,
        lastName: user?.lastName,
        availability: user?.isAvailable || false,
        officeNumber: user?.officeNumber || '',
        availabilityTime: user?.availabilityTime || '',
        staffMembers:
          user?.staffs.map(({ name, email }) => ({
            name,
            email,
          })) || [],
      });
    }
  }, [user]);

  const yearListFromNow = getRangeOfYears(1950).reverse();
  const yearListFrom5Year = getRangeOfYears(
    1950,
    new Date().getFullYear() + 5,
  ).reverse();
  const healthCareEmployerOptions = getEmployerList();

  usePrompt('Are you sure you would like to leave?', isBlocking);

  const disabledField = isSaveDisabled(
    formik?.values,
    initialValues,
    user.specialties ? [...user.specialties] : [],
    specialtiesSelected?.length ? [...specialtiesSelected] : [],
  );
  useEffect(() => {
    if (disabledField && typeof selectedImage === 'string' && !changed) {
      setIsBlocking(false);
    } else {
      setIsBlocking(true);
    }
  }, [formik?.values, initialValues, changed, selectedImage]);

  useEffect(() => {
    const getDoctorsData = () => {
      const toAdd = specialtyFilters.map((spec) => spec.name);
      setSpecialities(toAdd.sort());
    };
    getDoctorsData();
  }, []);

  const handleAdd = (selection) => {
    if (selection) {
      setErrMessage('');
    }
    if (specialtiesSelected.length >= 2) {
      setErrMessage('A maximum of 2 specialties can be selected');
      return;
    }
    if (specialtiesSelected.length < 2 && selection.trim() !== '') {
      const updateSpecialties = specialtiesSelected.find(
        (item) => item.specialty === selection,
      );
      if (!updateSpecialties) {
        setSpecialitiesSelected((state) => [
          ...state,
          { specialty: selection, sub: [] },
        ]);
      }
    }
  };

  const removeSelection = (selection) => {
    const _specialtiesSelected = [...specialtiesSelected];
    const updateSpecialties = _specialtiesSelected.filter(
      (item) => item.specialty !== selection,
    );
    setSpecialitiesSelected(updateSpecialties);
  };

  const removeSubSelection = (selection, subSelection) => {
    const _specialtiesSelected = [...specialtiesSelected];
    const updateSpecialties = _specialtiesSelected.map((item) => {
      if (item.specialty === selection) {
        if (item.sub.length) {
          item.sub = item.sub.filter((sub) => sub.specialty !== subSelection);
          return item;
        }
      }
      return item;
    });
    setSpecialitiesSelected(updateSpecialties);
  };

  const handleUpdateStatus = () => {
    valuesRef.current = formik.values;
  };

  const hideFiled =
    formik.values.status === 'Nurse Practitioner' ||
    formik.values.status === 'Physician Assistant';

  const hideFiledFellow =
    formik.values.status === 'Nurse Practitioner' ||
    formik.values.status === 'Physician Assistant' ||
    formik.values.status === 'Resident';

  useEffect(() => {
    if (
      formik.values.status === 'Nurse Practitioner' ||
      formik.values.status === 'Physician Assistant'
    ) {
      handleUpdateStatus();
      formik.setFieldValue('residency', '');
      formik.setFieldValue('residencyYear', '');
      formik.setFieldValue('fellowship', '');
      formik.setFieldValue('fellowshipYear', '');
      formik.setFieldValue('fellowships', []);
    } else if (formik.values.status === 'Resident') {
      handleUpdateStatus();
      formik.setFieldValue('fellowship', '');
      formik.setFieldValue('fellowshipYear', '');
      formik.setFieldValue('fellowships', []);
      formik.setFieldValue('residency', valuesRef?.current?.residency);
      formik.setFieldValue('residencyYear', valuesRef?.current?.residencyYear);
      // handleUpdateAboutData('fellowship', '');
      // handleUpdateAboutData('year', '');
      // setFellowShips([]);
    } else {
      if (valuesRef.current) {
        formik.setFieldValue('residency', valuesRef?.current?.residency);
        formik.setFieldValue(
          'residencyYear',
          valuesRef?.current?.residencyYear,
        );
        formik.setFieldValue('fellowship', valuesRef?.current?.fellowship);
        formik.setFieldValue(
          'fellowshipYear',
          valuesRef?.current?.fellowshipYear,
        );
        formik.setFieldValue('fellowships', valuesRef?.current?.fellowships);
      }
    }
  }, [formik.values.status]);

  const handleAddSubselection = (selection, subValue) => {
    const _specialtiesSelected = [...specialtiesSelected];
    const updateSpecialties = _specialtiesSelected.filter((item) => {
      if (item.specialty === selection) {
        item.sub.push({ specialty: subValue });
        return item;
      }
      return item;
    });
    setSpecialitiesSelected(updateSpecialties);
  };

  const _onListChange = (newList) => {
    formik.setFieldValue('degree', newList);
  };

  const removeDegree = (value) => {
    const updatedDegrees = formik.values.degree.filter((deg) => deg !== value);
    formik.setFieldValue('degree', updatedDegrees);
  };

  useEffect(() => {
    _onListChange(degreeCards?.map((card) => card.value) || []);
  }, [degreeCards]);

  useEffect(() => {
    if (
      formik.values.degree?.length &&
      formik.values.degree?.length !== degreeCards?.length
    ) {
      setDegreeCards(
        formik.values?.degree?.map((value, index) => {
          return { id: index, value: value };
        }),
      );
    }
  }, [formik.values?.degree]);

  useEffect(() => {
    if (
      healthCareEmployerOptions.includes(formik.values.healthCareEmployer) &&
      formik.values.healthCareEmployer !== 'Community Healthcare'
    ) {
      setShowLocation(true);
    } else {
      setShowLocation(false);
    }
  }, [formik.values.healthCareEmployer]);

  const handleRemoveStaffMember = (selectedEmail) => {
    const updatedStaff = formik.values?.staffMembers?.filter(
      (item) => item.email !== selectedEmail,
    );
    formik.setFieldValue('staffMembers', updatedStaff);
  };

  useEffect(() => {
    if (formik.errors && checkErrors) {
      const errorMess = [];
      Object.keys(formik.errors).forEach((currentKey) => {
        errorMess.push(formik.errors[currentKey]);
      });
      if (!errorMess?.length) return;

      setCheckErrors(false);
      setErrMessage(errorMess?.reverse()?.toString()?.replaceAll(',', `\n`));
      setErrorDialog(true);
    }
  }, [formik.errors, checkErrors]);

  return (
    <Box
      sx={{
        maxHeight: '100vh',
        height: '100vh',
        // overflow: 'hidden'
      }}
    >
      <AlertDialog
        title="Error"
        open={errorDialog}
        setOpen={setErrorDialog}
        message={errMessage}
        error
      />
      <Navbar />
      <Card sx={{ p: 2, px: 3 }}>
        <Stack
          direction="row"
          sx={{ width: '100%' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Typography
              color={darkTextColor}
              variant="h5"
              sx={{ fontWeight: 600 }}
            >
              Profile
            </Typography>
            <Button
              size="large"
              variant="contained"
              onClick={() => {
                const profileNumber = encryptNumber(user.id);

                navigate(`/doctor/${profileNumber}`);
              }}
              sx={{
                // height: 50,
                width: '10rem',
                height: 40,
                bgcolor: 'transparent',
                border: '1px solid rgba(47, 47, 47,0.4)',
                color: '#2F2F2F',
                '&:hover': {
                  border: '1px solid rgba(47, 47, 47,0.4)',
                  color: '#2F2F2F',
                  bgcolor: 'transparent',
                },
              }}
            >
              View as public
            </Button>
          </Stack>
          <Stack direction="row" gap={2}>
            <Button
              size="large"
              variant="contained"
              disabled={
                (disabledField &&
                  (typeof selectedImage === 'string' ||
                    selectedImage === undefined) &&
                  !changed) ||
                loading
              }
              onClick={() => {
                setSelectedImage();
                formik.resetForm();
              }}
              sx={{
                width: '10rem',
                height: 40,
                bgcolor: 'rgba(47, 47, 47, 0.14)',
                border: '0px solid #47BB92',
                color: '#2F2F2F',
                '&:hover': {
                  bgcolor: 'rgba(47, 47, 47, 0.14)',

                  color: '#2F2F2F',
                },
                '&:disabled': {
                  color: darkTextColor,
                  opacity: 0.5,
                },
              }}
            >
              Discard
            </Button>
            <LoadingButton
              color="primary"
              // disabled={formik.isSubmitting}
              size="large"
              variant="contained"
              onClick={() => {
                formik.handleSubmit();
                setCheckErrors(true);
              }}
              // loading={loading}
              disabled={
                (disabledField &&
                  (typeof selectedImage === 'string' ||
                    selectedImage === undefined) &&
                  !changed) ||
                loading
              }
              loadingPosition="end"
              sx={{
                width: '10rem',
                height: 40,
                '&:disabled': {
                  color: darkTextColor,
                  opacity: 0.5,
                },
              }}
              // loadingIndicator="Finding Best Match..."
            >
              {loading ? 'Saving...' : 'Save changes'}
            </LoadingButton>
          </Stack>
        </Stack>
      </Card>
      <Container
        id="settings-container"
        ref={containerRef}
        maxWidth="md"
        sx={{
          my: 2,
          overflowY: 'scroll',
          height: height ? `${height - 35}px` : '78vh',
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            overflowY: 'scroll',
          }}
        >
          <Stack direction="column" gap={1}>
            <Stack direction="column" sx={{ mt: 2 }}>
              <Typography
                color={darkTextColor}
                variant="h5"
                fontSize="18px"
                sx={{ fontWeight: 600 }}
              >
                Availability
              </Typography>
              <Typography color={darkTextColor} fontSize="14px" sx={{ my: 1 }}>
                Specify your availability for consultations and improve
                collaboration with other healthcare providers.
              </Typography>
            </Stack>
            <Grid
              maxWidth={true}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{
                bgcolor: '#FFFFFF',
                border: '1px solid #E2E2E2',
                borderRadius: '4px',
                mb: 2,
              }}
              gap={0}
            >
              <Grid
                maxWidth={true}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                gap={2}
                sx={{ py: 2, width: '100%' }}
              >
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Availability
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{
                          backgroundColor:
                            !user.profile_completed ||
                            !user.email_verified ||
                            !user.doctor_verified
                              ? 'rgba(246, 93, 94, 0.14)'
                              : '#F7F7F7',
                          borderRadius: '4px',
                          width: '100%',
                          p: 1.5,
                        }}
                      >
                        <Typography variant="body1" color={darkTextColor}>
                          {!user.profile_completed ||
                          !user.email_verified ||
                          !user.doctor_verified ||
                          ((user.status === 'Resident' ||
                            user.status === 'Fellow') &&
                            (formik.values.status === 'Resident' ||
                              formik.values.status === 'Fellow'))
                            ? 'Unavailable for new referrals'
                            : 'Available for new referrals'}
                        </Typography>
                        <Switch
                          onColor="#47BB92"
                          offColor="#F65D5E"
                          onChange={(e, val) => {
                            formik.setFieldValue('availability', val);
                          }}
                          disabled={
                            loading ||
                            !user.email_verified ||
                            !user.doctor_verified ||
                            !user.profile_completed ||
                            ((user.status === 'Resident' ||
                              user.status === 'Fellow') &&
                              (formik.values.status === 'Resident' ||
                                formik.values.status === 'Fellow'))
                          }
                          checked={formik.values.availability}
                        />
                      </Stack>
                      {(user.status === 'Resident' ||
                        user.status === 'Fellow') &&
                      (formik.values.status === 'Resident' ||
                        formik.values.status === 'Fellow') ? (
                        <Typography
                          variant="body1"
                          color={darkTextColor}
                          sx={{ opacity: 1, fontWeight: '500' }}
                        >
                          {`${formik.values?.status}s are unavailable to accept referrals`}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Office Number
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ opacity: 0.5 }}
                        variant="body2"
                      >
                        Optional
                      </Typography>
                    </Stack>

                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="space-between"
                      >
                        <CustomInputField
                          error={Boolean(
                            formik.touched.officeNumber &&
                              formik.errors.officeNumber,
                          )}
                          sx={{
                            width: '100%',
                            border: '0px solid #E2E2E2 !important',
                            '& .MuiInputBase-input': {
                              background: 'transparent',
                              border: '0px solid !important',
                            },
                          }}
                          fullWidth
                          hiddenLabel
                          name="officeNumber"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          placeholder="(xxx) xxx-xxxx"
                          value={formik.values.officeNumber}
                          inputComponent={TextMaskCustom}
                        />
                        {Boolean(
                          formik.touched.officeNumber &&
                            formik.errors.officeNumber,
                        ) && (
                          <Typography
                            color="error"
                            gutterBottom
                            variant="body2"
                            sx={{ position: 'relative !important' }}
                          >
                            {formik.touched.officeNumber &&
                              formik.errors.officeNumber}
                          </Typography>
                        )}
                        <Typography
                          color={darkTextColor}
                          variant="body2"
                          fontSize="14px"
                          sx={{ my: 2 }}
                        >
                          Physicians may provide this phone number to patients
                          for follow-up or to ensure continuity of care.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>

                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                        sx={{ minWidth: '20%' }}
                      >
                        Referral-to-Visit Time Estimate:
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ opacity: 0.5 }}
                        variant="body2"
                      >
                        Optional
                      </Typography>
                    </Stack>
                    <Grid
                      container
                      gap={1}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: '100%', mb: 1 }}
                    >
                      <Stack direction="row" flexWrap="wrap" gap={1}>
                        {defaultAvailability.map((text) => (
                          <Chip
                            variant="outlined"
                            color={
                              formik.values.availabilityTime === text ||
                              (text === 'Custom' && customAvailablity)
                                ? 'primary'
                                : 'info'
                            }
                            sx={{ width: 'max-content', px: 2 }}
                            label={text}
                            onClick={() => {
                              if (text === 'Custom') {
                                setCustomAvailablity(true);
                                formik.setFieldValue('availabilityTime', '');
                              } else {
                                if (customAvailablity) {
                                  setCustomAvailablity(false);
                                }
                                formik.setFieldValue('availabilityTime', text);
                              }
                            }}
                          />
                        ))}
                        <Stack
                          sx={{
                            border: '1px solid #E2E2E2',
                            borderRadius: '4px',
                            width: '100%',
                          }}
                        >
                          <CustomTextFieldNoBorders
                            value={formik.values.availabilityTime}
                            fullWidth
                            placeholder="Type here"
                            disabled={!customAvailablity}
                            onChange={(e) => {
                              formik.setFieldValue(
                                'availabilityTime',
                                e.target.value,
                              );
                            }}
                            inputProps={{ maxLength: 225 }}
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
            <Stack direction="column">
              <Typography
                color={darkTextColor}
                variant="h5"
                fontSize="18px"
                sx={{ fontWeight: 600 }}
              >
                About You
              </Typography>
              <Typography color={darkTextColor} fontSize="14px" sx={{ my: 1 }}>
                Add information about yourself to showcase your expertise and
                collaborate more effectively with other healthcare providers.
              </Typography>
            </Stack>
            <Grid
              maxWidth={true}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{
                bgcolor: '#FFFFFF',
                border: '1px solid #E2E2E2',
                borderRadius: '4px',
              }}
              gap={0}
            >
              <Grid
                maxWidth={true}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                gap={2}
                sx={{ py: 2, width: '100%' }}
              >
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                        sx={{ minWidth: '20%' }}
                      >
                        Profile picture
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ opacity: 0.5 }}
                        variant="body2"
                      >
                        Optional
                      </Typography>
                    </Stack>
                    <Grid
                      gap={2}
                      container
                      direction="row"
                      sx={{ flexWrap: 'nowrap' }}
                      alignItems="center"
                    >
                      {selectedImage ? (
                        <>
                          {typeof selectedImage === 'string' &&
                          selectedImage.includes('http') ? (
                            <Avatar
                              sx={{
                                width: '92px',
                                height: '92px',
                                mr: 2.5,
                                justifyContent: 'center',
                                display: 'flex',
                                borderRadius: '4px',
                              }}
                              src={selectedImage}
                              variant="square"
                            />
                          ) : (
                            <Avatar
                              sx={{
                                width: '92px',
                                height: '92px',
                                mr: 2.5,
                                justifyContent: 'center',
                                display: 'flex',
                                borderRadius: '4px',
                              }}
                              src={
                                URL.createObjectURL(selectedImage)
                                  ? URL.createObjectURL(selectedImage)
                                  : ''
                              }
                              variant="square"
                            />
                          )}
                        </>
                      ) : (
                        <DefaultImage />
                      )}
                      <Grid container direction="column">
                        <Button
                          color="primary"
                          variant="contained"
                          component="label"
                          sx={{ width: '8rem', height: 40, mb: 2 }}
                        >
                          Upload
                          <input
                            hidden
                            accept="image/*"
                            onChange={(event) => {
                              if (event.target.files) {
                                const name = event.target.files[0].name;
                                const fileExtension = name.split('.').pop();
                                if (
                                  fileExtension === 'png' ||
                                  fileExtension === 'jpeg' ||
                                  fileExtension === 'svg' ||
                                  fileExtension === 'jpg'
                                ) {
                                  var img = new Image();
                                  img.src = URL.createObjectURL(
                                    event.target.files[0],
                                  );
                                  img.onload = () => {
                                    if (img.width >= 200 && img.height >= 100) {
                                      setImageErr();
                                      setSelectedImage(event.target.files[0]);
                                    } else {
                                      setImageErr(
                                        `Image dimensions should be 200x100px. Image wasn’t uploaded because it is ${img.width}x${img.height}px.`,
                                      );
                                      console.log(
                                        'image dimensions are to small',
                                      );
                                    }
                                  };
                                } else {
                                  setImageErr(
                                    `Files extension dont match. Please upload in the following formats PNG or JPEG`,
                                  );
                                }
                              }
                            }}
                            type="file"
                          />
                        </Button>
                        <Stack direction="column" sx={{ flexWrap: 'wrap' }}>
                          <Grid
                            gap={1}
                            container
                            direction="row"
                            alignItems="flex-start"
                          >
                            <TooltipIcon />
                            <Typography
                              color={darkTextColor}
                              sx={{ opacity: 0.75 }}
                              gutterBottom
                              variant="body2"
                            >
                              PNG or JPEG (min. 200x100px)
                            </Typography>
                          </Grid>
                          {imageErr ? (
                            imageErr.split('.').map((errMess) => (
                              <Typography
                                color="error"
                                sx={{ opacity: 0.75, m: 0 }}
                                gutterBottom
                                variant="body2"
                              >
                                {errMess}
                              </Typography>
                            ))
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                      >
                        About
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ opacity: 0.5 }}
                        variant="body2"
                      >
                        Optional
                      </Typography>
                    </Stack>
                    <Grid item sx={{ width: '100%' }}>
                      <CharacterLeftTypography
                        touched={formik.touched.about}
                        errors={formik.errors.about}
                        value={formik.values.about}
                        handleBlur={formik.handleBlur}
                        handleChange={formik.handleChange}
                        maxLength={500}
                        rows={4}
                        label="About"
                        name="about"
                      />
                      {Boolean(formik.touched.about && formik.errors.about) && (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{ position: 'relative !important' }}
                        >
                          {formik.touched.about && formik.errors.about}
                        </Typography>
                      )}
                      <Typography
                        color={darkTextColor}
                        variant="body2"
                        fontSize="14px"
                        sx={{ my: 2 }}
                      >
                        Describe your education, training background, and scope
                        of practice.
                      </Typography>
                    </Grid>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                      >
                        Your Names
                      </Typography>
                    </Stack>
                    <Stack direction="row" sx={{ width: '100%' }} gap={2}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          border: `1px solid ${
                            formik.errors.firstName ? '#F65D5E' : '#E2E2E2'
                          }`,
                          borderRadius: '4px',
                          px: 1,
                        }}
                      >
                        <Typography
                          color={darkTextColor}
                          variant="body1"
                          sx={{ opacity: 0.6 }}
                        >
                          First Name:
                        </Typography>
                        <CustomTextFieldNoBorders
                          value={formik.values.firstName}
                          placeholder="Type here"
                          onChange={(e) => {
                            formik.setFieldValue('firstName', e.target.value);
                          }}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          border: `1px solid ${
                            formik.errors.lastName ? '#F65D5E' : '#E2E2E2'
                          }`,
                          borderRadius: '4px',
                          px: 1,
                        }}
                      >
                        <Typography
                          color={darkTextColor}
                          variant="body1"
                          sx={{ opacity: 0.6 }}
                        >
                          Last Name:
                        </Typography>
                        <CustomTextFieldNoBorders
                          value={formik.values.lastName}
                          placeholder="Type here"
                          onChange={(e) => {
                            formik.setFieldValue('lastName', e.target.value);
                          }}
                        />
                      </Stack>
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                      sx={{ minWidth: '20%' }}
                    >
                      Status
                    </Typography>
                    <Grid
                      container
                      gap={1}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: '100%', mb: 1 }}
                    >
                      <Stack
                        direction="row"
                        flexWrap="wrap"
                        gap={1}
                        alignItems="center"
                      >
                        {DoctorStatus.map((text) => (
                          <Chip
                            variant="outlined"
                            color={
                              formik.values.status === text ? 'primary' : 'info'
                            }
                            sx={{ width: 'max-content', px: 2 }}
                            label={text}
                            onClick={() => formik.setFieldValue('status', text)}
                          />
                        ))}

                        {Boolean(
                          formik.touched.status && formik.errors.status,
                        ) ? (
                          <Typography
                            color="error"
                            gutterBottom
                            variant="body2"
                            sx={{ position: 'relative !important' }}
                          >
                            {formik.errors.status}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Grid>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                      sx={{ minWidth: '20%' }}
                    >
                      Degree
                    </Typography>
                    <Grid
                      container
                      gap={1}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: '100%' }}
                    >
                      <Stack
                        direction="column"
                        flexWrap="wrap"
                        gap={1}
                        sx={{ width: '100%' }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          sx={{
                            border: '1px solid #E2E2E2',
                            borderRadius: '4px',
                          }}
                        >
                          <DegreeSelectionNew
                            degree={degree}
                            setDegree={setDegree}
                            status={formik.values.status}
                            degrees={formik.values.degree}
                            handleAddDegree={handleAddDegree}
                          />
                          <CircleButton
                            onClick={() => {
                              if (degree) {
                                handleAddDegree();
                              }
                            }}
                          />
                        </Stack>

                        {Boolean(
                          formik.touched.degree && formik.errors.degree,
                        ) ? (
                          <Typography
                            color="error"
                            gutterBottom
                            variant="body2"
                            sx={{ position: 'relative !important' }}
                          >
                            {formik.errors.degree}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        <Typography
                          color={darkTextColor}
                          variant="body2"
                          fontSize="14px"
                        >
                          Add degrees in the order you would like them displayed
                          (if multiple).
                        </Typography>
                        {formik?.values?.degree?.length && (
                          <DndProvider backend={HTML5Backend}>
                            <DragableListContainer
                              cards={degreeCards}
                              setCards={setDegreeCards}
                              removeDegree={removeDegree}
                            />
                          </DndProvider>
                        )}
                      </Stack>
                    </Grid>
                  </Stack>
                </Card>
                {specialities && (
                  <Card
                    sx={{
                      width: '100%',
                      px: 2,
                      py: 1,
                      bgcolor: '#FFFFFF',
                      borderBottom: '1px solid #ced4da',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                  >
                    <Stack direction="row" sx={{ pb: 1 }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                        sx={{ minWidth: '20%' }}
                      >
                        Specialties
                      </Typography>
                      <Grid
                        container
                        gap={1}
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', mb: 1 }}
                      >
                        <Stack
                          direction="column"
                          flexWrap="wrap"
                          gap={1}
                          sx={{ width: '100%' }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                              border: '1px solid #E2E2E2',
                              borderRadius: '4px',
                            }}
                          >
                            <Autocomplete
                              disablePortal
                              id="Subspecialties"
                              PopperComponent={({ style, ...props }) => (
                                <Popper
                                  {...props}
                                  style={{ ...style, height: 0 }}
                                />
                              )}
                              getOptionDisabled={(option) => {
                                const test = specialtiesSelected?.find(
                                  (spec) => spec.specialty === option,
                                );
                                if (test) {
                                  return true;
                                }
                                return false;
                              }}
                              ListboxProps={{ style: { maxHeight: '30vh' } }}
                              options={specialities}
                              value={selection || ''}
                              onInputChange={(event, value) =>
                                setSelection(value)
                              }
                              sx={{ width: '100%', color: 'black', p: 0 }}
                              renderInput={(params) => (
                                <CustomTextFieldNoBorders
                                  {...params}
                                  value={selection || ''}
                                  placeholder="Type to search"
                                />
                              )}
                            />
                            <CircleButton
                              onClick={() => {
                                if (selection) {
                                  handleAdd(selection);
                                  setSelection();
                                }
                              }}
                              disabled={!specialities?.includes(selection)}
                            />
                          </Stack>
                          {specialtiesSelected?.length ? (
                            specialtiesSelected.map((item, index) => (
                              <React.Fragment key={index}>
                                <Selection
                                  selected={item}
                                  index={index}
                                  health_center={user.health_center}
                                  removeSelection={removeSelection}
                                  specialities={specialities}
                                  removeSubSelection={removeSubSelection}
                                  handleAddSubselection={handleAddSubselection}
                                />
                              </React.Fragment>
                            ))
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </Grid>
                    </Stack>
                  </Card>
                )}

                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                    overflow: 'initial',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                      sx={{ minWidth: '20%' }}
                    >
                      {!hideFiled ? 'Medical School' : 'Graduate School'}
                    </Typography>
                    <Grid
                      container
                      gap={1}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: '100%', mb: 1 }}
                    >
                      <Stack
                        sx={{ width: '100%' }}
                        direction="row"
                        flexWrap="wrap"
                        gap={1}
                      >
                        <Stack
                          sx={{
                            border: '1px solid #E2E2E2',
                            p: 0,
                            m: 0,
                            width: '100%',
                          }}
                        >
                          <Autocomplete
                            disablePortal
                            freeSolo
                            id="medicalSchool"
                            name="medicalSchool"
                            ListboxProps={{ style: { maxHeight: '20vh' } }}
                            PopperComponent={({ style, ...props }) => (
                              <Popper
                                {...props}
                                style={{ ...style, height: 0 }}
                              />
                            )}
                            // openOnFocus
                            fullWidth
                            disableClearable
                            // forcePopupIcon={false}
                            options={MedicalSchoolList}
                            value={formik.values.medicalSchool}
                            onChange={(e, value) => {
                              formik.setFieldValue('medicalSchool', value);
                            }}
                            sx={{ width: '100%', color: 'black', p: 0 }}
                            renderInput={(params) => (
                              <CustomTextFieldNoBorders
                                {...params}
                                placeholder="Search for a medical school"
                                fullWidth
                                onInput={(e) => {
                                  e.target.value = e.target.value.slice(0, 75);
                                }}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    'medicalSchool',
                                    e.target.value,
                                  );
                                }}
                                sx={{ border: '0px', width: '100%', p: 0 }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Autocomplete
                                      // disablePortal
                                      // openOnFocus
                                      disableClearable
                                      disablePortal
                                      id="year-select"
                                      ListboxProps={{
                                        style: { maxHeight: '20vh' },
                                      }}
                                      PopperComponent={({
                                        style,
                                        ...props
                                      }) => (
                                        <Popper
                                          {...props}
                                          style={{ ...style, height: 0 }}
                                        />
                                      )}
                                      sx={{
                                        border: '0px',
                                        pointerEvents: 'all',
                                        cursor: 'pointer',
                                        boxShadow: '0px 0px',
                                        padding: 0,
                                        margin: 0,
                                      }}
                                      onChange={(e, value) => {
                                        formik.setFieldValue(
                                          'medicalSchoolYear',
                                          value,
                                        );
                                      }}
                                      onFocus={(event) => {
                                        event.stopPropagation();
                                      }}
                                      value={formik.values.medicalSchoolYear}
                                      options={yearListFromNow}
                                      renderInput={(params) => (
                                        <TextField
                                          onClick={(event) => {
                                            event.stopPropagation();
                                          }}
                                          {...params}
                                          placeholder="Year completed"
                                          sx={{
                                            width: '100%',
                                            minWidth: '90px',
                                            padding: 0,
                                            color: 'black !important',
                                            margin: 0,
                                            backgroundColor: '#FFFFFF',
                                            boxShadow: '0px 0px',
                                            border: '0px !important',
                                            '& .MuiOutlinedInput-root': {
                                              padding: '0px',
                                              color: 'black !important',
                                              border: '0px !important',
                                              backgroundColor: '#FFFFFF',
                                              boxShadow: '0px 0px',
                                              marginTop: '0px',
                                            },
                                            '& .MuiInputBase-input': {
                                              width: '130px !important',
                                              textAlign: 'end',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline':
                                              {
                                                borderColor:
                                                  '#fcfcfb !important',
                                              },
                                          }}
                                        />
                                      )}
                                    />
                                  ),
                                }}
                              />
                            )}
                          />
                        </Stack>

                        <Stack direction="column">
                          {Boolean(
                            formik.touched.medicalSchool &&
                              formik.errors.medicalSchool,
                          ) ||
                          Boolean(
                            formik.touched.medicalSchoolYear &&
                              formik.errors.medicalSchoolYear,
                          ) ? (
                            <Typography
                              color="error"
                              gutterBottom
                              variant="body2"
                              sx={{ position: 'relative !important' }}
                            >
                              {formik.errors.medicalSchool
                                ? formik.errors.medicalSchool
                                : formik.errors.medicalSchoolYear}
                            </Typography>
                          ) : (
                            <></>
                          )}
                          <Typography
                            color={darkTextColor}
                            variant="body2"
                            fontSize="14px"
                          >
                            If your medical school is not listed, please type it
                            in.
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                    overflow: 'initial',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                      sx={{ minWidth: '20%' }}
                    >
                      Residency
                    </Typography>
                    <Grid
                      container
                      gap={1}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: '100%', mb: 1 }}
                    >
                      <Stack
                        sx={{ width: '100%' }}
                        direction="row"
                        flexWrap="wrap"
                        gap={1}
                      >
                        <Stack
                          sx={{
                            border: '1px solid #E2E2E2',
                            p: 0,
                            m: 0,
                            width: '100%',
                          }}
                        >
                          <Autocomplete
                            disablePortal
                            freeSolo
                            ListboxProps={{ style: { maxHeight: '20vh' } }}
                            PopperComponent={({ style, ...props }) => (
                              <Popper
                                {...props}
                                style={{ ...style, height: 0 }}
                              />
                            )}
                            disableClearable
                            options={[]}
                            label="Residency"
                            width="100%"
                            sx={{ width: '100%', color: 'black', p: 0 }}
                            name="residency"
                            value={formik.values.residency}
                            onInput={(e) => {
                              if (!hideFiled) {
                                e.target.value = e.target.value.slice(0, 75);
                              }
                            }}
                            onChange={(e, value) => {
                              if (!hideFiled) {
                                formik.setFieldValue('residency', value);
                              }
                            }}
                            disabled={hideFiled}
                            renderInput={(params) => (
                              <CustomTextFieldNoBorders
                                {...params}
                                placeholder="Type here"
                                fullWidth
                                value={formik.values.residency}
                                onInput={(e) => {
                                  if (!hideFiled) {
                                    e.target.value = e.target.value.slice(
                                      0,
                                      75,
                                    );
                                  }
                                }}
                                onChange={(e) => {
                                  if (!hideFiled) {
                                    formik.setFieldValue(
                                      'residency',
                                      e.target.value,
                                    );
                                  }
                                }}
                                disabled={hideFiled}
                                sx={{ border: '0px' }}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Autocomplete
                                      disablePortal
                                      openOnFocus
                                      disableClearable
                                      id="year-select"
                                      ListboxProps={{
                                        style: {
                                          maxHeight: '20vh',
                                        },
                                      }}
                                      sx={{
                                        border: '0px',
                                        pointerEvents: 'all',
                                        cursor: 'pointer',
                                        boxShadow: '0px 0px',
                                        padding: 0,
                                        margin: 0,
                                      }}
                                      disabled={hideFiled}
                                      onChange={(e, value) => {
                                        if (!hideFiled) {
                                          formik.setFieldValue(
                                            'residencyYear',
                                            value,
                                          );
                                        }
                                      }}
                                      value={
                                        !hideFiled
                                          ? formik.values.residencyYear
                                          : 'Year completed'
                                      }
                                      options={
                                        formik.values.status === 'Resident'
                                          ? yearListFrom5Year
                                          : yearListFromNow
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          placeholder="Year completed"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                          }}
                                          onFocus={(event) => {
                                            event.stopPropagation();
                                          }}
                                          value={
                                            !hideFiled
                                              ? formik.values.residencyYear
                                              : 'Year completed'
                                          }
                                          sx={{
                                            minWidth: '150px',
                                            padding: 0,
                                            color: 'black !important',
                                            margin: 0,
                                            backgroundColor: '#FFFFFF',
                                            boxShadow: '0px 0px',
                                            border: '0px !important',
                                            '& .MuiOutlinedInput-root': {
                                              padding: '0px',
                                              color: 'black !important',
                                              border: '0px !important',
                                              backgroundColor: '#FFFFFF',
                                              boxShadow: '0px 0px',
                                              marginTop: '0px',
                                            },
                                            '& .MuiInputBase-input': {
                                              width: '140px !important',
                                              textAlign: 'end',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline':
                                              {
                                                borderColor:
                                                  '#fcfcfb !important',
                                              },
                                          }}
                                        />
                                      )}
                                    />
                                  ),
                                }}
                              />
                            )}
                          />
                        </Stack>

                        <Stack direction="column">
                          {Boolean(
                            formik.touched.residency && formik.errors.residency,
                          ) ||
                          Boolean(
                            formik.touched.residencyYear &&
                              formik.errors.residencyYear,
                          ) ? (
                            <Typography
                              color="error"
                              gutterBottom
                              variant="body2"
                              sx={{ position: 'relative !important' }}
                            >
                              {formik.errors.residency
                                ? formik.errors.residency
                                : formik.errors.residencyYear}
                            </Typography>
                          ) : (
                            <></>
                          )}
                          <Typography
                            color={darkTextColor}
                            variant="body2"
                            fontSize="14px"
                          >
                            Please type in the name of your residency program.
                          </Typography>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    pb: 0,
                    bgcolor: '#FFFFFF',
                    borderRadius: 0,
                    boxShadow: 'none',
                    overflow: 'initial',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                      >
                        Fellowships
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ opacity: 0.5 }}
                        variant="body2"
                      >
                        Optional
                      </Typography>
                    </Stack>
                    <Grid
                      container
                      gap={1}
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: '100%', mb: 1 }}
                    >
                      <Stack
                        sx={{ width: '100%' }}
                        direction="row"
                        flexWrap="wrap"
                        gap={1}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            border: '1px solid #E2E2E2',
                            p: 0,
                            m: 0,
                            width: '100%',
                          }}
                        >
                          <CustomInputField
                            label="Fellowships"
                            fullWidth
                            width="100%"
                            onBlur={formik.handleBlur}
                            inputProps={{ maxLength: 60 }}
                            onChange={(event) => {
                              if (
                                !hideFiled &&
                                formik.values.status !== 'Resident'
                              ) {
                                formik.setFieldValue(
                                  'fellowship',
                                  event.target.value,
                                );
                              }
                            }}
                            onInput={(e) => {
                              if (
                                !hideFiled &&
                                formik.values.status !== 'Resident'
                              ) {
                                e.target.value = e.target.value.slice(0, 75);
                              }
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                border: '0px !important',
                              },
                            }}
                            placeholder="Type here"
                            name="fellowship"
                            type="text"
                            value={formik.values.fellowship}
                            disabled={
                              hideFiled
                                ? true
                                : formik.values.status === 'Resident'
                                ? true
                                : false
                            }
                            endAdornment={
                              <Autocomplete
                                disablePortal
                                openOnFocus
                                disableClearable
                                id="year-select"
                                ListboxProps={{
                                  style: {
                                    maxHeight: '20vh',
                                    postion: 'absolute',
                                  },
                                }}
                                sx={{
                                  border: '0px',
                                  pointerEvents: 'all',
                                  cursor: 'pointer',
                                  boxShadow: '0px 0px',
                                  padding: 0,
                                  margin: 0,
                                  maxHeight: '5vh',

                                  position: 'absolute',
                                  right: '10px',
                                  top: '50%',
                                  transform: 'translate(-0%,-45%)',
                                }}
                                disabled={
                                  hideFiled
                                    ? true
                                    : formik.values.status === 'Resident'
                                    ? true
                                    : false
                                }
                                onChange={(e, value) => {
                                  if (
                                    !hideFiled &&
                                    formik.values.status !== 'Resident'
                                  ) {
                                    formik.setFieldValue(
                                      'fellowshipYear',
                                      value,
                                    );
                                  }
                                }}
                                options={yearListFrom5Year}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    onChange={(e) => {
                                      formik.setFieldValue(
                                        'fellowshipYear',
                                        e.target.value,
                                      );
                                    }}
                                    placeholder="Year completed"
                                    sx={{
                                      width: '100%',
                                      minWidth: '90px',
                                      color: 'black !important',
                                      margin: 0,
                                      backgroundColor: '#FFFFFF',
                                      boxShadow: '0px 0px',
                                      border: '0px !important',
                                      '& .MuiOutlinedInput-root': {
                                        padding: '0% 1%',
                                        color: 'black !important',
                                        border: '0px !important',
                                        backgroundColor: '#FFFFFF',
                                        boxShadow: '0px 0px',
                                        marginTop: '0px',
                                      },
                                      '& .MuiInputBase-input': {
                                        padding: '0px !important',
                                        color: 'black !important',
                                        border: '0px !important',
                                        backgroundColor: '#FFFFFF',
                                        boxShadow: '0px 0px',
                                        marginTop: '0px',
                                        width: '140px !important',
                                        textAlign: 'end',
                                      },

                                      '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fcfcfb !important',
                                      },
                                    }}
                                  />
                                )}
                              />
                            }
                          />
                          <CircleButton
                            onClick={() => handleAddFellowShips()}
                            disabled={
                              formik?.values?.fellowships?.length > 4 ||
                              hideFiledFellow
                            }
                          />
                        </Stack>
                        {formik?.values?.fellowships &&
                          formik?.values?.fellowships?.map((item) => (
                            <Card
                              justifyContent="space-between"
                              sx={{
                                width: '100%',
                                py: 1,
                                px: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                boxShadow: 0,
                                border: '1px solid #E2E2E2',
                              }}
                            >
                              <Typography color={darkTextColor} variant="body1">
                                {item.fellowship}
                              </Typography>
                              <Grid item>
                                <Grid
                                  container
                                  direction="row"
                                  gap={4}
                                  alignItems="center"
                                >
                                  <Typography
                                    color={darkTextColor}
                                    sx={{ p: 0, m: 0 }}
                                    variant="body1"
                                  >
                                    {new Date().getFullYear() < item.year
                                      ? `${item.year} (expected)`
                                      : item.year}
                                  </Typography>
                                  <IconButton
                                    disableRipple
                                    onClick={() =>
                                      handleRemoveFellowShip(item.fellowship)
                                    }
                                  >
                                    <CloseLight />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Card>
                          ))}
                      </Stack>
                    </Grid>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
            <Stack direction="column" sx={{ mt: 2 }}>
              <Typography
                color={darkTextColor}
                variant="h5"
                fontSize="18px"
                sx={{ fontWeight: 600 }}
              >
                Your practice
              </Typography>
              <Typography color={darkTextColor} fontSize="14px" sx={{ my: 1 }}>
                Share your office locations and accepted insurances for easy
                referrals.
              </Typography>
            </Stack>
            <Grid
              maxWidth={true}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{
                bgcolor: '#FFFFFF',
                border: '1px solid #E2E2E2',
                borderRadius: '4px',
              }}
              gap={0}
            >
              <Grid
                maxWidth={true}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                gap={2}
                sx={{ py: 2, width: '100%' }}
              >
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Healthcare Employer
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        sx={{
                          border: '1px solid #E2E2E2',
                          p: 0,
                          m: 0,
                          width: '100%',
                        }}
                      >
                        <Autocomplete
                          freeSolo
                          disablePortal
                          id="healthCareEmployer"
                          name="healthCareEmployer"
                          PopperComponent={({ style, ...props }) => (
                            <Popper
                              {...props}
                              style={{ ...style, height: 0 }}
                            />
                          )}
                          options={healthCareEmployerOptions || []}
                          value={formik.values.healthCareEmployer || ''}
                          onChange={(e, value) => {
                            formik.setFieldValue('healthCareEmployer', value);
                            formik.setFieldValue('locations', []);
                            if (healthCareEmployerOptions.includes(value)) {
                              setShowLocation(true);
                            } else {
                              setShowLocation(false);
                            }
                          }}
                          sx={{ width: '100%', color: 'black', p: 0 }}
                          renderInput={(params) => (
                            <CustomTextFieldNoBorders
                              {...params}
                              onInput={(e) => {
                                e.target.value = e.target.value.slice(0, 75);
                              }}
                            />
                          )}
                        />
                      </Stack>
                      {Boolean(formik.touched.about && formik.errors.about) && (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{ position: 'relative !important' }}
                        >
                          {formik.touched.about && formik.errors.about}
                        </Typography>
                      )}
                      <Typography
                        color={darkTextColor}
                        variant="body2"
                        fontSize="14px"
                      >
                        Feel free to add in your employer if it is not in the
                        list.
                      </Typography>
                    </Stack>
                  </Stack>
                </Card>
                {showLocation && (
                  <Card
                    sx={{
                      width: '100%',
                      px: 2,
                      py: 1,
                      bgcolor: '#FFFFFF',
                      borderBottom: '1px solid #ced4da',
                      borderRadius: 0,
                      boxShadow: 'none',
                    }}
                  >
                    <Stack direction="row" sx={{ pb: 1 }}>
                      <Stack direction="column" sx={{ minWidth: '20%' }}>
                        <Typography
                          color={darkTextColor}
                          variant="body1"
                          fontWeight="600"
                          fontSize="14px"
                          sx={{ minWidth: '20%' }}
                        >
                          Hospital Locations
                        </Typography>
                        <Typography
                          color={darkTextColor}
                          sx={{ opacity: 0.5 }}
                          variant="body2"
                        >
                          Optional
                        </Typography>
                      </Stack>

                      <Grid
                        container
                        gap={1}
                        direction="row"
                        justifyContent="space-between"
                        sx={{ width: '100%', mb: 1 }}
                      >
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          gap={2}
                          sx={{
                            border: '1px solid #E2E2E2',
                            borderRadius: '4px',
                          }}
                        >
                          <Grid item sx={{ flexGrow: 9 }}>
                            <Autocomplete
                              freeSolo
                              id="locations-auto-complete"
                              options={hospitalLocations || []}
                              PopperComponent={({ style, ...props }) => (
                                <Popper
                                  {...props}
                                  style={{ ...style, height: 0 }}
                                />
                              )}
                              value={currentHospital || ''}
                              ListboxProps={{ style: { maxHeight: '20vh' } }}
                              onInputChange={(event, value) =>
                                setCurrentHospital(value)
                              }
                              sx={{
                                width: '100%',
                                color: 'black',
                                p: 0,
                              }}
                              renderInput={(params) => (
                                <CustomTextFieldNoBorders
                                  value={currentHospital || ''}
                                  {...params}
                                  placeholder="Search for a hospital"
                                />
                              )}
                            />
                          </Grid>
                          <CircleButton
                            onClick={() => {
                              if (currentHospital) {
                                const updatedArray = [
                                  ...formik.values.locations,
                                  currentHospital,
                                ];
                                setCurrentHospital('');
                                formik.setFieldValue('locations', updatedArray);
                              }
                            }}
                          />
                        </Grid>

                        {formik?.values?.locations &&
                          formik?.values?.locations?.map((item) => (
                            <Card
                              justifyContent="space-between"
                              sx={{
                                width: '100%',
                                py: 1,
                                px: 3,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                boxShadow: 0,
                                border: '1px solid #E2E2E2',
                              }}
                            >
                              <Typography color={darkTextColor} variant="body1">
                                {item}
                              </Typography>
                              <Grid item>
                                <Grid
                                  container
                                  direction="row"
                                  gap={4}
                                  alignItems="center"
                                >
                                  <IconButton
                                    disableRipple
                                    onClick={() => {
                                      const updatedArray =
                                        formik?.values?.locations?.filter(
                                          (text) => text !== item,
                                        );
                                      formik.setFieldValue(
                                        'locations',
                                        updatedArray,
                                      );
                                    }}
                                  >
                                    <CloseLight />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Card>
                          ))}
                      </Grid>
                    </Stack>
                  </Card>
                )}
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        Office Locations
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        sx={{
                          border: '1px solid #E2E2E2',
                          borderRadius: '4px',
                          p: 0,
                          m: 0,
                          width: '100%',
                        }}
                      >
                        <Grid
                          container
                          gap={1}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ width: '100%' }}
                        >
                          <AddressField updatePlaces={updatePlaces} />
                        </Grid>
                      </Stack>
                      {Boolean(
                        formik.touched.addresses && formik.errors.addresses,
                      ) && (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{ position: 'relative !important' }}
                        >
                          {formik.touched.addresses && formik.errors.addresses}
                        </Typography>
                      )}
                      {formik.values?.addresses?.length ? (
                        formik.values.addresses.map(
                          (
                            {
                              formatted_address,
                              name,
                              is_primary,
                              full_address,
                              city,
                              postal_code,
                              state,
                            },
                            index,
                          ) => (
                            <Card
                              key={`${name}-${index}`}
                              sx={{
                                p: 2,
                                pr: 10,
                                maxWidth: { md: '48%', sm: '90%' },
                                width: { md: '48%', sm: '90%' },
                                position: 'relative',
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                border: '1px solid #E2E2E2',
                                boxShadow: 'none',
                              }}
                            >
                              <Typography
                                color={darkTextColor}
                                variant="body1"
                                sx={{
                                  cursor: 'pointer',
                                  position: 'absolute',
                                  right: '5%',
                                }}
                                onClick={() =>
                                  handleRemoveAddress(formatted_address)
                                }
                              >
                                <CloseLight />
                              </Typography>
                              <Grid>
                                <Typography
                                  color={darkTextColor}
                                  variant="h6"
                                  fontSize="14px"
                                  sx={{ width: '90%' }}
                                >
                                  {name}
                                </Typography>
                                <Typography
                                  color={darkTextColor}
                                  variant="body2"
                                  fontSize="14px"
                                  sx={{ width: '95%', my: 1 }}
                                >
                                  {handleFormatedAddress(
                                    formatted_address,
                                    city,
                                  )}
                                  {city ? `${city}, ` : ''}
                                  {state ? `${state} ` : ''} {postal_code}
                                </Typography>
                              </Grid>
                              <FormControlLabel
                                checked={is_primary}
                                onChange={() =>
                                  handleSetPrimaryAddress(formatted_address)
                                }
                                value={
                                  is_primary ? 'Primary' : 'Set as primary'
                                }
                                label={
                                  is_primary ? 'Primary' : 'Set as primary'
                                }
                                control={<Radio />}
                                sx={{
                                  color: darkTextColor,
                                  fontSize: '0.5rem',
                                }}
                              />
                            </Card>
                          ),
                        )
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderBottom: '1px solid #ced4da',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontWeight="600"
                      >
                        Staff Members
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ opacity: 0.5 }}
                        variant="body2"
                      >
                        Optional
                      </Typography>
                    </Stack>
                    <Stack direction="column" sx={{ width: '100%' }} gap={2}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          border: '1px solid #E2E2E2',
                          borderRadius: '4px',
                          px: 1,
                        }}
                      >
                        <CustomTextFieldNoBorders
                          error={Boolean(
                            formikStaff.touched.name && formikStaff.errors.name,
                          )}
                          value={formikStaff.values.name}
                          placeholder="Name"
                          sx={{ flex: 1.25 }}
                          onChange={(e) => {
                            formikStaff.setFieldValue('name', e.target.value);
                          }}
                        />
                        <div
                          style={{
                            background: '#B5B5B5',
                            height: '70%',
                            width: '1px',
                          }}
                        />
                        <CustomTextFieldNoBorders
                          error={Boolean(
                            formikStaff.touched.email &&
                              formikStaff.errors.email,
                          )}
                          value={formikStaff.values.email}
                          placeholder="Type email address here"
                          sx={{ flex: 2 }}
                          onChange={(e) => {
                            formikStaff.setFieldValue('email', e.target.value);
                          }}
                        />
                        <CircleButton
                          onClick={() => formikStaff.handleSubmit()}
                        />
                      </Stack>
                      {Boolean(
                        formikStaff.touched.name && formikStaff.errors.name,
                      ) && (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{ my: -1 }}
                        >
                          {formikStaff.touched.name && formikStaff.errors.name}
                        </Typography>
                      )}
                      {Boolean(
                        formikStaff.touched.email && formikStaff.errors.email,
                      ) && (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{ my: -1 }}
                        >
                          {formikStaff.touched.email &&
                            formikStaff.errors.email}
                        </Typography>
                      )}
                      <Typography
                        color={darkTextColor}
                        variant="body2"
                        fontSize="14px"
                      >
                        Include staff members that schedule appointments for
                        your patients. For your convenience, accepted referrals
                        will be forwarded to your staff for scheduling purposes.
                      </Typography>
                      {formik.values.staffMembers?.length ? (
                        formik.values.staffMembers?.map((member, index) => (
                          <Card
                            key={`${member}-${index}`}
                            sx={{
                              px: 3,
                              py: 2,
                              border: '1px solid #E2E2E2',
                              width: '100%',
                              boxShadow: 'none',
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                              >
                                <Typography
                                  color={darkTextColor}
                                  sx={{ overflowWrap: 'anywhere' }}
                                  variant="h6"
                                  fontSize="14px"
                                >
                                  {member.name}
                                </Typography>
                                <Typography
                                  color={darkTextColor}
                                  sx={{
                                    overflowWrap: 'anywhere',
                                    opacity: '0.6',
                                  }}
                                  variant="body2"
                                  fontSize="14px"
                                >
                                  •
                                </Typography>
                                <Typography
                                  color={darkTextColor}
                                  sx={{ overflowWrap: 'anywhere' }}
                                  variant="body2"
                                  fontSize="14px"
                                >
                                  {member.email}
                                </Typography>
                              </Stack>
                              <RemoveButton
                                styles={{ alignSelf: 'center' }}
                                handleClick={() =>
                                  handleRemoveStaffMember(member.email)
                                }
                              />
                            </Stack>
                          </Card>
                        ))
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Stack>
                </Card>
                <Card
                  sx={{
                    width: '100%',
                    px: 2,
                    py: 1,
                    bgcolor: '#FFFFFF',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                >
                  <Stack direction="row" sx={{ pb: 1 }}>
                    <Stack direction="column" sx={{ minWidth: '20%' }}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        fontSize="14px"
                        fontWeight="600"
                      >
                        In-network insurances
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ opacity: 0.5 }}
                        variant="body2"
                      >
                        Optional
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        sx={{
                          border: '1px solid #E2E2E2',
                          borderRadius: '4px',
                          p: 0,
                          m: 0,
                          width: '100%',
                        }}
                      >
                        <Grid
                          container
                          gap={1}
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{ width: '100%', flexWrap: 'nowrap' }}
                        >
                          <Autocomplete
                            disablePortal
                            getOptionDisabled={(option) =>
                              !!formik.values?.insurances?.find(
                                (insurance) =>
                                  insurance?.toLowerCase() ===
                                  option.toLocaleLowerCase(),
                              )
                            }
                            freeSolo
                            id="In-network insurances"
                            name="In-network insurances"
                            placeholder="Type to search"
                            options={ListOfInsurances}
                            value={insurance}
                            onChange={(e, value) => {
                              setInsurance(value);
                            }}
                            fullWidth
                            sx={{ color: 'black', p: 0 }}
                            renderInput={(params) => (
                              <CustomTextFieldNoBorders
                                {...params}
                                onInput={(e) => {
                                  e.target.value = e.target.value.slice(0, 75);
                                }}
                                onChange={(e) => {
                                  setInsurance(e.target.value);
                                }}
                                placeholder="Search for an insurance provider"
                              />
                            )}
                          />
                          <CircleButton
                            onClick={() => handleAddInsurance(insurance)}
                          />
                        </Grid>
                      </Stack>
                      <Typography
                        color={darkTextColor}
                        variant="body2"
                        fontSize="14px"
                      >
                        Feel free to add in your insurance if it is not in the
                        list.
                      </Typography>
                      {formik.values?.insurances?.length ? (
                        formik.values?.insurances
                          .sort((a, b) => ('' + a).localeCompare(b))
                          .map((insurance) => (
                            <Card
                              sx={{
                                p: 1,
                                py: 1,
                                border: '1px solid #E2E2E2',
                                boxShadow: 'none',
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                alignItems="center"
                                gap={4}
                              >
                                <Typography
                                  color={darkTextColor}
                                  gutterBottom
                                  variant="body1"
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    margin: 0,
                                  }}
                                >
                                  {insurance} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp;
                                </Typography>
                                <RemoveButton
                                  handleClick={() =>
                                    handleRemoveInsurance(insurance)
                                  }
                                />
                              </Grid>
                            </Card>
                          ))
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Container>
      <FooterVerifiedUser />
    </Box>
  );
};

const isSaveDisabled = (compare, base, baseSepcialty, compareSepcialty) => {
  if (compare && base) {
    const {
      fellowship,
      fellowshipYear,
      addresses,
      fellowships,
      degree,
      residencyYear,
      insurances,
      staffMembers,
      status,
      ...compareClean
    } = compare;
    const {
      fellowship: fel,
      fellowshipYear: felY,
      addresses: add,
      fellowships: fellowshipsArr,
      degree: degreeClean,
      residencyYear: ResY,
      insurances: insurancesC,
      staffMembers: staffMembersC,
      status: statusClean = '',
      ...baseClean
    } = base;
    const cleanCompare = addresses
      .map(({ formatted_address, full_address, name, postal_code }) => ({
        formatted_address,
        full_address,
        name,
        postal_code,
      }))
      .sort((a, b) => (a.formatted_address > b.formatted_address ? 1 : -1));
    const cleanbase = add
      .map(({ formatted_address, full_address, name, postal_code }) => ({
        formatted_address,
        full_address,
        name,
        postal_code,
      }))
      .sort((a, b) => (a.formatted_address > b.formatted_address ? 1 : -1));

    const fellowShipBase = fellowships?.map((fel) => ({
      fellowship: fel.fellowship,
      year: fel.toString().replace(' (expected)', '').year,
    }));
    const fellowshipsCompare = fellowshipsArr?.map((fel) => ({
      fellowship: fel.fellowship,
      year: fel?.toString().replace(' (expected)', '').year,
    }));

    const insurancesBase = insurances.sort((a, b) => ('' + a).localeCompare(b));
    const insurancesCompare = insurancesC.sort((a, b) =>
      ('' + a).localeCompare(b),
    );

    //compare spec
    let checkSpec = true;
    if (baseSepcialty && compareSepcialty) {
      const newBase = baseSepcialty.slice();
      const cleanArrSpec = [];
      const cleanBaseSpec = newBase
        .map((main) => {
          if (main.class === 'main') {
            const updatedChild = newBase
              .filter(
                (sub) => sub.class === 'sub' && sub.parent === main.specialty,
              )
              .map((sub) => sub.specialty)
              .sort((a, b) => ('' + a).localeCompare(b));
            cleanArrSpec.push({
              sub: updatedChild,
              main: main.specialty,
            });
          }
        })
        .filter((spec) => spec);

      const newCompare = [...compareSepcialty];
      const cleanCompareSpec = newCompare
        .map((main) => {
          const getSub = main?.sub
            ?.map((sub) => sub.specialty)
            .sort((a, b) => ('' + a).localeCompare(b));
          return {
            sub: getSub,
            main: main.specialty,
          };
        })
        .sort((a, b) => ('' + a.main).localeCompare(b.main));

      if (
        JSON.stringify(
          cleanArrSpec?.sort((a, b) => ('' + a.main).localeCompare(b.main)),
        ) !== JSON.stringify(cleanCompareSpec)
      ) {
        checkSpec = false;
      }
    }

    const staffBase = staffMembers?.map(({ name, email }) => ({ name, email }));
    const staffClean = staffMembersC?.map(({ name, email }) => ({
      name,
      email,
    }));

    const checkDegrees =
      degreeClean === ''
        ? 0 === degree?.length
        : JSON.stringify(degreeClean) === JSON.stringify(degree);

    const checkStatus =
      statusClean === null ? status === '' : status === statusClean;
    if (
      checkSpec &&
      JSON.stringify(compareClean) === JSON.stringify(baseClean) &&
      JSON.stringify(cleanCompare) === JSON.stringify(cleanbase) &&
      JSON.stringify(insurancesBase) === JSON.stringify(insurancesCompare) &&
      checkDegrees &&
      JSON.stringify(fellowShipBase) === JSON.stringify(fellowshipsCompare) &&
      JSON.stringify(staffBase) === JSON.stringify(staffClean) &&
      checkStatus &&
      residencyYear?.toString().replace(' (expected)', '') ===
        ResY?.toString().replace(' (expected)', '')
    ) {
      return true;
    }
  }
  return false;
};

const EditProfile = () => {
  const { user, token } = useContext(UserContext);
  if (!user) {
    return <LoadingCircularProgress />;
  }
  return <EditContent />;
};

export default EditProfile;
