const DragableIcon = () => (
  <svg
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.56" width="13" height="1" fill="#212634" />
    <rect opacity="0.56" y="2" width="13" height="1" fill="#212634" />
    <rect opacity="0.56" y="4" width="13" height="1" fill="#212634" />
    <rect opacity="0.56" y="6" width="13" height="1" fill="#212634" />
  </svg>
);

export default DragableIcon;
