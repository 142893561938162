import React from 'react';
import {
  Slide,
  Dialog,
  Grid,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseButton from '../closeButton';
import { darkTextColor } from '../../constants';
import { Stack } from '@mui/system';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DotIcon = () => (
  <span
    style={{
      width: '5px',
      height: '5px',
      display: 'inline-block',
      backgroundColor: 'black',
      borderRadius: '50%',
      marginBottom: '2px',
      marginLeft: '-10px',
    }}
  />
);

export const PrivacyPolicy = (props) => {
  const { open, setOpen, confirmation } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        PaperProps={{
          sx: {
            borderRadius: '12px !important',
            p: 2,
            maxWidth: {
              md: '40vw !important',
              xs: '75vw !important',
              sm: '75vw !important',
            },
            maxHeight: '60vh !important',
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2, pb: 1, flexWrap: 'nowrap' }}
        >
          <Typography variant="h5" fontSize="24px" color={darkTextColor}>
            Privacy Policy
          </Typography>
          <IconButton onClick={() => handleClose()} color="secondary">
            <CloseButton />
          </IconButton>
        </Grid>
        <DialogContent>
          <Stack gap={2}>
            <Typography color={darkTextColor}>
              <p style={{ fontWeight: 600 }}>Introduction</p>This Privacy Policy
              describes the types of information we may collect from medLynks
              users (“Users”) on our Site, including how we will handle your
              personal data and any protected health information (“PHI”) you may
              provide to us about your patients. Please read this Privacy Policy
              carefully before providing us with PHI. If you have questions
              about this Privacy Policy, please contact us at
              support@medLynks.com. By using our Service, you acknowledge that
              you have read this Privacy Policy and consent to our handling of
              your personal data and your patient’s PHI.
              <br />
              <p> &nbsp;</p>
              Please note that our Privacy Policy does not apply to how service
              providers listed on our Site may handle your patient’s PHI. We
              encourage you to read their privacy policies and know how they
              will handle such information before interacting with them.
              <br />
              <p> &nbsp;</p>
              PHI is defined by a federal law called the Health Insurance
              Portability and Accountability Act (“HIPAA”) and generally
              includes demographic, health and/or health-related information
              that medLynks collects on behalf of patients of its Users or
              service providers.
              <br />
              <p> &nbsp;</p>
              From time to time we may update our Privacy Policy, and you are
              encouraged to learn more about these changes by reviewing our
              Privacy Policy each time you access our site.
              <br />
              <p> &nbsp;</p>
            </Typography>
            <Typography color={darkTextColor} variant="paragraph">
              <p style={{ fontWeight: 600 }}>Your Privacy Rights at MedLynks</p>
              At medLynks, we respect your ability to know, access, correct,
              transfer, restrict the processing of, and delete your patient’s
              PHI. To help protect the security of your personal data and your
              patient’s PHI, you must sign into your User account and your
              identity will be verified. <br />
              <p> &nbsp;</p>
              BY ACCESSING OR USING THE SERVICES, YOU AGREE TO THE PRACTICES AND
              POLICIES OUTLINED IN THIS PRIVACY POLICY AND YOU HEREBY CONSENT TO
              THE COLLECTION, USE, AND SHARING OF YOUR INFORMATION AND YOUR
              PATIENT’S INFORMATION, AS DESCRIBED IN THIS PRIVACY POLICY. IF YOU
              DO NOT AGREE WITH THIS PRIVACY POLICY, YOU SHOULD NOT USE THE
              SERVICES. YOU REPRESENT THAT YOU HAVE OBTAINED THE AUTHORIZATION
              OF YOUR PATIENT TO ACCEPT THIS PRIVACY POLICY ON HIS OR HER
              BEHALF. <br />
            </Typography>
            <Typography color={darkTextColor}>
              <p style={{ fontWeight: 600 }}>
                Purpose of the Collection of Your Non-Personal and Personal Data
                and PHI by medLynks
              </p>
              We will be collecting both Personal and non-Personal data to
              operate our Site and to understand how we can deliver a better
              experience.
              <br />
              <p> &nbsp;</p>At medLynks, we value your patient’s right to
              privacy and we will collect, handle and transmit your patient’s
              PHI with the utmost of care. We will be collecting PHI that we
              will de-identify, encrypt and/or aggregate when we are
              transmitting the data to service providers listed on our Site.
              <br /> <p> &nbsp;</p>We consider PHI to include any information in
              the medical record or designated record set that can be used to
              identify an individual and that was created, used, or disclosed in
              the course of providing a health care service such as diagnosis or
              treatment.
              <br /> <p> &nbsp;</p>We may collect this PHI or personal
              information from you or persons who you direct to send information
              to us, under a variety of circumstances as follows:
              <p> &nbsp;</p>
              <p style={{ marginLeft: '20px' }}>
                {' '}
                <DotIcon /> To set up your Service. To ensure our Site works
                smoothly when you access it, we may ask you to provide us with
                certain information that personally identifies you or could be
                used to personally identify you, such as your:{' '}
                <p style={{ marginLeft: '40px' }}>
                  o Account details, including email address, devices
                  registered, account status and age;
                </p>{' '}
                <p style={{ marginLeft: '40px' }}>
                  o Device Information (data from which your device could be
                  identified, such as device serial number, or about your
                  device, such as browser type); and
                </p>{' '}
                <p style={{ marginLeft: '40px' }}>
                  o Contact Information. Data such as name, email address,
                  physical address, phone number, or other contact information{' '}
                </p>{' '}
              </p>
              <p style={{ marginLeft: '20px' }}>
                <DotIcon /> To Facilitate communications and referrals between
                service providers listed on our site. (We treat PHI in
                accordance with the law and with commercially reasonable
                security best practices).{' '}
              </p>
              <p style={{ marginLeft: '20px' }}>
                {' '}
                <DotIcon /> To build and maintain a database of healthcare
                providers and related data, for use within our products and
                services and those of our business partners;
              </p>
              <p style={{ marginLeft: '20px' }}>
                <DotIcon /> To analyze and develop products and services, and to
                improve our overall business practices.
              </p>
              <p style={{ marginLeft: '20px' }}>
                <DotIcon /> To conduct administrative tasks, including security
                improvement and fraud prevention. We will be monitoring Users of
                our Site to identify potential violators of our Terms of Service
                and block their access.
              </p>
              <p style={{ marginLeft: '20px' }}>
                {' '}
                <DotIcon /> Billing, collection and payment information, such as
                bank details, credit, debit, or other payment card information;{' '}
              </p>
              <p style={{ marginLeft: '20px' }}>
                {' '}
                <DotIcon /> Other Information you may provide to us. Details
                such as the content of your communications with medLynks,
                including interactions with customer support.{' '}
              </p>
              <p> &nbsp;</p>
              You are not required to provide any personal data that we may
              request. However, if you choose not to do so, in many cases we
              will not be able to provide you with our Services or respond to
              requests you may have.
              <p> &nbsp;</p>
              From time to time, we may use your personal data to send important
              notices, such as communications about changes to our terms,
              conditions, and policies, or to respond to your communications or
              requested information. Because this information is important to
              your interaction with medLynks, you may not opt out of receiving
              these important notices.
            </Typography>
            <Typography color={darkTextColor} variant="paragraph">
              <p style={{ fontWeight: 600 }}>
                medLynks’s Use of Your Personal Data and your Patient’s PHI
              </p>
              In general, we will use your personal data and your Patient’s PHI
              to validate your identity; to collect and transmit PHI data to
              service providers on our Site on a de- identified basis; to
              communicate with you, for security and fraud prevention, and
              overall policy changes to our Site; and to comply with law.
              <p> &nbsp;</p>
              We will share your patient’s PHI with service providers on our
              Site, if you authorize us to do so by using our online physician
              referral service, or if you use any other tool or feature of our
              Service that involves exchanging information with a healthcare
              provider. When we transmit your PHI it will be encrypted, but not
              at the time of receipt by the Service Providers. It will be the
              Service Providers sole responsibility to meet all applicable HIPAA
              obligations when the information is in their custody.
              <p> &nbsp;</p>
              We will use commercially reasonable efforts to implement all
              generally accepted, industry-standard best practices for the
              protection of your personal data and your patient’s PHI. However,
              no website or platform is entirely secure, and we cannot guarantee
              that your personal information or your patient’s PHI will not be
              intercepted or accessed by others. While we have taken
              commercially reasonable precautions to protect the personal
              information that you provide to us and the PHI, by de-identifying
              the data, you should exercise discretion in what you provide to
              us, as there is always a risk that our Services may be compromised
              via a malicious attack, a system failure, a human error, or
              another vulnerability, which could result in the disclosure of
              personal information to third parties.
              <p> &nbsp;</p>
              We also may share your personal information with third-parties
              engaged to provide services to us and operate our business,
              including in the areas of website and database hosting and
              maintenance, communications, traffic patterns, information
              security, fraud detection and prevention, email management, data
              analytics, marketing, advertising, and member support. We will
              ensure those parties agree to use any such personally-
              identifiable data solely for the purpose of providing the
              specified services to us. As is standard practice on many
              websites, medLynks Site uses &quot;cookies&quot; to enhance the
              user experience on the Site and to allow us to better design the
              Site.
              <p> &nbsp;</p>
              We may also share personal information that is de-identified and
              aggregated with third parties who are not providing services to
              us, who may utilize such personal information for advertising,
              data analysis, and analytics purposes.
              <p> &nbsp;</p>
              We use various third-party data solutions, such as Amazon Web
              Services, to store your patient’s PHI data on their servers (the
              “cloud”) in connection with your use of our Services. All such
              personal information will be protected in accordance with such
              third parties’ security practices and procedures then in-effect.
              We assume that the third parties will have legally compliant
              security practices and will comply, in all respects, with HIPAA.
              <p> &nbsp;</p>
              We may work with third party partners to serve advertising for us
              on third party sites and to evaluate the effectiveness of our
              advertising. These third parties use cookies and similar
              technologies to collect information about your use of our sites,
              other sites and online services over time.
              <p> &nbsp;</p>
              Our Services may contain links to third-party sites. Any access to
              and use of such linked sites is not governed by this policy, but
              instead is governed by the privacy policies of those third-party
              sites, which we encourage you to read. We are not responsible for
              the information practices of such third-party sites.
              <p> &nbsp;</p>
              We may also disclose information about you if we are required for
              purposes of national security, law enforcement, or other issues of
              public importance. We may also disclose information about you
              where there is a lawful basis for doing so, if we determine that
              disclosure is reasonably necessary to enforce our terms and
              conditions or to protect our operations or users, or in the event
              of a reorganization, merger, or sale.
              <p> &nbsp;</p>
              In the event that medLynks is acquired by or merged with a
              third-party entity, we reserve the right, in any of these
              circumstances, to transfer or assign the information we have
              collected from our Users as part of such merger, acquisition,
              sale, or other change of control. In the unlikely event of our
              bankruptcy, insolvency, reorganization, receivership, or
              assignment for the benefit of creditors, or the application of
              laws or equitable principles affecting creditors&#39; rights
              generally, we may not be able to control how your personal
              information is treated, transferred, or used. medLynks will not
              sell your personal or PHI data.
              <p> &nbsp;</p>
              We will retain your personal data only for so long as necessary to
              fulfill the purposes for which it was collected, including as
              described in this Privacy Policy or as required by law.
              <p> &nbsp;</p>
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
