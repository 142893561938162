const AttachmentIcon = ({ size = 32 }) => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.56"
      d="M13.3374 1.16084C14.8852 2.70866 14.8852 5.22146 13.3374 6.76915L4.92473 15.1818C3.83381 16.2727 2.05672 16.2727 0.965844 15.1818C-0.125032 14.0909 -0.125074 12.3138 0.965844 11.2229L9.37851 2.81026C10.0125 2.17628 11.0539 2.17628 11.6879 2.81026C12.3219 3.44424 12.3219 4.48567 11.6879 5.11965L4.42997 12.3776C4.34292 12.4669 4.22364 12.5177 4.09883 12.5185C3.97402 12.5193 3.85409 12.4702 3.7659 12.382C3.67754 12.2936 3.62839 12.1737 3.62921 12.0489C3.63003 11.9241 3.68079 11.8048 3.77029 11.7177L11.0282 4.45981C11.3081 4.17992 11.3081 3.75001 11.0282 3.47012C10.7483 3.19022 10.3184 3.19023 10.0385 3.47012L1.62586 11.8828C0.889032 12.6196 0.889032 13.7853 1.62586 14.522C2.3627 15.2588 3.52843 15.2588 4.26505 14.522L12.6777 6.10931C13.8714 4.91558 13.8714 3.01414 12.6777 1.8207C11.484 0.626974 9.58255 0.626974 8.38911 1.8207L0.801261 9.40855C0.714205 9.49805 0.594928 9.54915 0.469957 9.54996C0.344985 9.55094 0.224896 9.50163 0.136699 9.41327C0.0483403 9.32491 -0.000964639 9.20499 1.43042e-05 9.08002C0.000827952 8.95504 0.0517602 8.83577 0.141419 8.74871L7.72927 1.16086C9.27709 -0.386953 11.7899 -0.386953 13.3376 1.16086L13.3374 1.16084Z"
      fill="black"
    />
  </svg>
);

export default AttachmentIcon;
