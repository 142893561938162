import {
  Button,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';

import AlertDialog from '../../components/alertDialog';

const textColor = '#212634';

const CancelPrompt = (props) => {
  const { openDialog, setOpenDialog, handleCancel } = props;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <AlertDialog
        title="Are you sure you want to cancel this referral?"
        open={openDialog}
        setOpen={handleClose}
      >
        <DialogContent sx={{ my: -1, width: { md: 'auto', sm: '100%' } }}>
          <Typography color={textColor} gutterBottom variant="body1">
            If you cancel now, any information you've entered will be lost and
            the referral will not be completed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            // disabled={loading}
            fullWidth
            onClick={() => handleClose()}
            sx={{
              width: '10rem',
              height: 40,
              mr: 1.5,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            fullWidth
            sx={{ width: '10rem', height: 40 }}
            onClick={() => {
              handleCancel();
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </AlertDialog>
    </>
  );
};

export default CancelPrompt;
