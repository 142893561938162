import { Box, Typography, Grid, Stack, Avatar } from '@mui/material';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  GetImageFromUrl,
} from '../../../constants';
import PatientDetails from './patientDetails';
import ReferralDetails from './referralDetails';

const bgColor = '#D9D9D9';

const PatientWraper = ({
  docData,
  formik,
  formikReferralDetail,
  page,
  files,
  setFiles,
}) => {
  const avatar = GetImageFromUrl(docData.profile_picture_url);

  return (
    <Grid
      container
      direction="column"
      sx={{ flexWrap: 'nowrap', p: 1, overflow: 'hidden', maxHeight: '81vh' }}
    >
      <Grid
        container
        direction="row"
        gap={2}
        alignItems="center"
        sx={{
          p: 2,
          px: 4,
          border: '1px solid #E2E2E2',
          borderRadius: '4px',
          flexWrap: 'nowrap',
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <Avatar
            sx={{
              width: '90px',
              height: '97.5px',
              display: 'flex',
            }}
            src={avatar}
            variant="square"
          />
        </Stack>
        <Stack
          direction="column"
          spacing={1}
          justifyContent="start"
          alignItems="flex-start"
        >
          <Typography color={darkTextColor} variant="h5">
            {CapitalizeFirstLetter(docData.firstName)}{' '}
            {CapitalizeFirstLetter(docData.lastName)}
            &nbsp;
            {docData?.preffix?.replaceAll(',', ', ') || ''}
          </Typography>

          <Typography
            color={darkTextColor}
            variant="body2"
            sx={{ opacity: '0.75' }}
          >
            {docData.specialty} • {docData.shortAddress}
          </Typography>
        </Stack>
      </Grid>
      <Grid
        container
        direction="row"
        gap={2}
        sx={{ flexWrap: 'nowrap', mt: 3 }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            gap={2}
            sx={{ py: 2, flexWrap: 'nowrap' }}
            alignItems="center"
          >
            <Avatar
              sx={{
                bgcolor:
                  page === 1 ? '#1665D8 !important' : '#47BB92 !important',
              }}
              variant="square"
            >
              {1}
            </Avatar>
            <Typography
              color={page === 1 ? '#1665D8 !important' : '#47BB92 !important'}
              variant="body1"
            >
              Patient details
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ display: 'flex' }}>
          <Grid container alignItems="center" justifyContent="center">
            <div
              style={{
                height: '2px',
                width: '4vw',
                backgroundColor: page === 2 ? '#47BB92' : bgColor,
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            gap={2}
            sx={{ py: 2, flexWrap: 'nowrap' }}
            alignItems="center"
          >
            <Avatar
              sx={{
                bgcolor:
                  page === 2 ? '#1665D8 !important' : `${bgColor} !important`,
              }}
              variant="square"
            >
              2
            </Avatar>
            <Typography
              color={
                page === 2
                  ? '#1665D8 !important'
                  : `${darkTextColor} !important`
              }
              variant="body1"
            >
              Referral details
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box
        id="patient-wraper-container"
        container
        sx={{ overflowY: 'scroll', margin: '1%', paddingRight: '5px' }}
      >
        {page === 1 ? (
          <PatientDetails docData={docData} formik={formik} page={page} />
        ) : (
          <ReferralDetails
            docData={docData}
            formik={formikReferralDetail}
            // formik={formik}
            page={page}
            files={files}
            setFiles={setFiles}
          />
        )}
      </Box>
    </Grid>
  );
};

export default PatientWraper;
