import React, { useContext, useState } from 'react';
import {
  Button,
  Typography,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../../components/alertDialog';
import CustomInputField from '../../components/customInputField';

import UserContext from '../../contexts/user/context';
import { darkTextColor, RESPONSE_STATUS } from '../../constants';
import Users from '../../services/users';
import SuccessPrompt from './successPrompt';

const ContactUs = (props) => {
  const { openDialog, setOpenDialog } = props;
  const { user, token } = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [contantUsErr, setContantUsErr] = useState('');
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleDone = () => {
    setOpenDialog(false);
    setSuccess(false);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setMessage('');
    setContantUsErr('');
  };

  const handleSendMessage = async () => {
    if (!message) {
      setContantUsErr('Message can not be empty');
      return;
    }
    try {
      setLoading(true);
      const response = await Users.sendMessage(token, message);

      if (response.status === RESPONSE_STATUS.success) {
        setMessage('');
        setContantUsErr('');
        setSuccess(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <AlertDialog
        title="Contact us"
        open={openDialog}
        setOpen={() => handleClose()}
      >
        <DialogContent sx={{ mb: 3 }}>
          <Typography color={darkTextColor} gutterBottom variant="body1">
            How can we help you?
          </Typography>
          <CustomInputField
            label="About"
            fullWidth
            width="100%"
            multiline
            name="about"
            sx={{ width: '25vw' }}
            onChange={(e) => setMessage(e.target.value)}
            type="text"
            rows={8}
            value={message}
          />
          {Boolean(contantUsErr) && (
            <Typography
              color="error"
              gutterBottom
              variant="body2"
              sx={{ position: 'absolute !important' }}
            >
              {contantUsErr}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            variant="contained"
            width="100%"
            fullWidth
            sx={{ height: 40 }}
            disabled={loading}
            onClick={() => {
              if (message) {
                handleSendMessage();
              } else {
                setContantUsErr('Message can not be empty');
              }
            }}
          >
            Send Message
          </Button>
        </DialogActions>
      </AlertDialog>
      <SuccessPrompt handleDone={handleDone} openDialog={success} />
    </>
  );
};

export default ContactUs;
