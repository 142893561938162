import {
  Tooltip,
  Button,
  Box,
  Typography,
  Stack,
  Avatar,
  Slide,
  Dialog,
  Grid,
  IconButton,
} from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  GetImageFromUrl,
} from '../../constants';
import moment from 'moment';
import React, { useState } from 'react';
import CloseButton from '../closeButton';
import { Fragment } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const goldenColor = '#E5A700';
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#FFFFFF',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EAECEE',
    boxShadow: '0px 2px 18px rgba(0, 0, 0, 0.06)',
    maxWidth: '100%',
    // zIndex: 1000,
  },
  [`& .${tooltipClasses.popper}`]: {
    zIndex: 2,
  },
}));

export const ShowAllEndorsements = ({
  endorsements,
  isFav,
  likes,
  open,
  setOpen,
  children,
  docData,
}) => {
  const handleClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
      onClick={(e) => {
        e.stopPropagation();
      }}
      PaperProps={{
        sx: {
          borderRadius: '12px !important',
          p: 2,
          zIndex: 100,
          position: 'relative',
          minWidth: '30vw',
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ flexWrap: 'nowrap', mb: 2 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Stack direction="column" spacing={0}>
          <Typography
            color={darkTextColor}
            sx={{ width: 'max-content', whiteSpace: 'nowrap' }}
            fontSize="24px"
            lineHeight="20px"
            fontWeight="600"
          >
            {`${CapitalizeFirstLetter(
              docData?.firstName,
            )} ${CapitalizeFirstLetter(docData?.lastName)}, ${
              docData?.preffix?.replaceAll(',', ', ') || ''
            }`}
          </Typography>
          <Typography
            fontSize="16px"
            color={goldenColor}
            fontWeight="600"
          >{`${likes} Endorsements`}</Typography>
        </Stack>
        <IconButton onClick={(e) => handleClose(e)} color="secondary">
          <CloseButton />
        </IconButton>
      </Grid>
      <Stack spacing={1}>
        {endorsements?.map((user) => (
          <Fragment key={user.id}>
            <Stack
              direction="row"
              sx={{ width: '100%', my: 0.5 }}
              justifyContent="space-between"
              alignItems="center"
              spacing={3}
            >
              <Stack direction="row" gap={0.5} alignItems="center">
                <Avatar
                  color="#F7F8FA"
                  src={GetImageFromUrl(user.FavoriteBy?.profile_picture_url)}
                >
                  {user?.FavoriteBy?.firstName?.charAt(0)}
                </Avatar>
                <Typography
                  color={darkTextColor}
                  sx={{ width: 'max-content', whiteSpace: 'nowrap' }}
                >
                  {`${CapitalizeFirstLetter(
                    user.FavoriteBy?.firstName,
                  )} ${CapitalizeFirstLetter(user.FavoriteBy?.lastName)} ${
                    user.FavoriteBy?.preffix?.replaceAll(',', ', ') || ''
                  }`}
                </Typography>
              </Stack>
              <Stack
                justifyContent="flex-end"
                direction="row"
                sx={{ width: '100%' }}
                spacing={1}
              >
                <StarIcon sx={{ color: goldenColor }} />
                <Typography color={darkTextColor}>
                  {moment(user.createdAt).format('D-MMM')}
                </Typography>
              </Stack>
            </Stack>
            <div
              style={{
                width: '100%',
                height: '1px',
                background: '#D9D9D9',
              }}
            />
          </Fragment>
        ))}
      </Stack>
    </Dialog>
  );
};

const EndorseHover = ({ endorsements, likes, docData }) => {
  const [open, setOpen] = useState(false);
  const sortedEndorse = endorsements.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  const handleViewAll = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{ p: 2, minWidth: '10vw' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Stack direction="column">
          <Typography sx={{ mb: 1 }} color={goldenColor}>
            Last Endorsed by:
          </Typography>
          {sortedEndorse?.map((user, i) => {
            if (i > 4) return <></>;
            return (
              <Stack
                direction="row"
                sx={{ width: '100%', my: 0.5 }}
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
              >
                <Stack
                  direction="row"
                  gap={0.5}
                  alignItems="center"
                  sx={{ mr: 1 }}
                >
                  <Avatar
                    color="#F7F8FA"
                    src={GetImageFromUrl(user.FavoriteBy?.profile_picture_url)}
                  >
                    {user?.FavoriteBy?.firstName?.charAt(0)}
                  </Avatar>
                  <Typography
                    color={darkTextColor}
                    sx={{ width: 'max-content', whiteSpace: 'nowrap' }}
                  >
                    {`${CapitalizeFirstLetter(
                      user.FavoriteBy?.firstName,
                    )} ${CapitalizeFirstLetter(user.FavoriteBy?.lastName)} ${
                      user.FavoriteBy?.preffix?.replaceAll(',', ', ') || ''
                    }`}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  sx={{ width: '100%' }}
                  spacing={1}
                  justifyContent="flex-end"
                >
                  <StarIcon sx={{ color: goldenColor }} />
                  <Typography
                    color={darkTextColor}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {moment(user.createdAt).format('D-MMM')}
                  </Typography>
                </Stack>
              </Stack>
            );
          })}
          <Button
            sx={{
              fontSize: '12px',
              p: 0.25,
              mt: 2,
              fontWeight: 500,
              height: 'min-content',
              bgcolor: 'transparent',
              border: `1px solid ${goldenColor}`,
              color: goldenColor,
            }}
            onClick={(e) => handleViewAll(e)}
          >{`View all endorsements (${likes})`}</Button>
        </Stack>
      </Box>
      <ShowAllEndorsements
        setOpen={setOpen}
        open={open}
        endorsements={sortedEndorse}
        docData={docData}
        likes={likes}
      />
    </>
  );
};
export const HoverEffect = ({
  children,
  endorsements,
  isFav,
  likes,
  docData,
}) => {
  if (!endorsements.length) {
    return children;
  }

  return (
    <BootstrapTooltip
      title={
        <EndorseHover
          docData={docData}
          endorsements={endorsements}
          isFav={isFav}
          likes={likes}
        />
      }
      sx={{
        '&.MuiTooltip-popper': {
          zIndex: 10,
        },
      }}
    >
      {children}
    </BootstrapTooltip>
  );
};

export const LikeButton = ({
  endorsements,
  RemoveFavorites,
  isFav,
  likes,
  sx,
  docData,
  favLoading = false,
}) => {
  const handleUpdateEndorse = (e) => {
    e?.stopPropagation();
    RemoveFavorites(docData.id);
  };
  const updatedEndorsements = endorsements.filter(
    (endorse) => endorse?.FavoriteBy,
  );
  const removed = endorsements.filter(
    (endorse) => endorse?.FavoriteBy === null,
  )?.length;
  if (!isFav) {
    return (
      <>
        <IconButton
          disabled={favLoading}
          onClick={(e) => handleUpdateEndorse(e)}
        >
          <HoverEffect
            docData={docData}
            endorsements={updatedEndorsements}
            isFav={isFav}
            likes={likes - removed}
          >
            <StarBorderIcon sx={{ color: '#E5A700', ...sx }} />
          </HoverEffect>
        </IconButton>
        <Typography fontSize="14px" sx={{ ml: -0.5 }} color={'#E5A700'}>
          {likes - removed}
        </Typography>
      </>
    );
  }
  return (
    <>
      <IconButton disabled={favLoading} onClick={(e) => handleUpdateEndorse(e)}>
        <HoverEffect
          docData={docData}
          isFav={isFav}
          endorsements={updatedEndorsements}
          likes={likes - removed}
        >
          <StarIcon sx={{ color: '#E5A700', ...sx }} />
        </HoverEffect>
      </IconButton>
      <Typography fontSize="14px" sx={{ ml: -0.5 }} color={'#E5A700'}>
        {likes - removed}
      </Typography>
    </>
  );
};
