import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';


const ArrowUpDownIcon = () => {
  return (
    <span
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "Center",
        alignItems: "Center",
        marginLeft: "5px"
      }}
    >
      <KeyboardArrowUpIcon fontSize="5" />
      <KeyboardArrowDownIcon fontSize="5" />
    </span>
  );
};

export default ArrowUpDownIcon;