import React, { useState, useEffect } from 'react';
import AutoComplete from 'react-autocomplete';
import { Typography } from '@mui/material';
import { darkTextColor, RESPONSE_STATUS } from '../../constants';
import Doctors from '../../services/doctors';

const ListOfInsurances = [
  {
    name: 'header',
    header: 'Popular carriers',
  },
  {
    name: 'Aetna',
    type: 'Popular carriers',
  },
  {
    name: 'Blue Cross',
    type: 'Popular carriers',
  },
  {
    name: 'Cigna',
    type: 'Popular carriers',
  },
  {
    name: 'Humana',
    type: 'Popular carriers',
  },
  {
    name: 'Medicaid',
    type: 'Popular carriers',
  },
  {
    name: 'Medicare',
    type: 'Popular carriers',
  },
  {
    name: 'United Healthcare',
    type: 'Popular carriers',
  },
];
const InsuranceSearch = ({
  name,
  setName,
  token,
  isDisabled,
  windowDimensions,
}) => {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const getFilters = async () => {
      try {
        const response = await Doctors.getInsurances(token);
        if (response.status === RESPONSE_STATUS.success) {
          const { insurances } = response?.body;
          if (insurances?.length) {
            const referenceList = [];
            let insuracesCarriers = [
              ...ListOfInsurances,
              {
                name: 'header',
                header: 'All carriers',
              },
            ];
            insurances?.forEach((insurance) => {
              if (
                !referenceList.includes(insurance?.carrier?.trim()) &&
                insurance?.carrier?.trim()
              ) {
                referenceList.push(insurance.carrier?.trim());
              }
            });
            const sortedList = referenceList.sort();
            sortedList.forEach((insurance) => {
              insuracesCarriers.push({
                name: insurance,
                type: 'All carriers',
              });
            });
            setMembers(insuracesCarriers);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (!isDisabled) {
      getFilters();
    }
  }, []);
  const handleChangeSelection = (value) => {
    setName(value);
  };
  return (
    <>
      <AutoComplete
        getItemValue={(item) => item && item?.name}
        items={members?.filter((member) => {
          if (
            member.name === 'header' &&
            member.header === 'Popular carriers'
          ) {
            if (
              members?.find(
                (x) =>
                  x?.name?.toLowerCase().includes(name?.toLowerCase()) &&
                  x.type === 'Popular carriers',
              )
            ) {
              // return member;
              return member.name === 'header';
            }
            // if (name) {
            // } else {
            //   return member;
            // }
          }
          if (member.name === 'header' && member.header === 'All carriers') {
            if (
              members?.find(
                (x) =>
                  x?.name?.toLowerCase().includes(name?.toLowerCase()) &&
                  x.type === 'All carriers',
              )
            ) {
              // return member;
              return member.name === 'header';
            }
            // if (name) {
            // } else {
            //   return member;
            // }
          }
          if (
            member?.header !== 'Popular carriers' &&
            member?.header !== 'All carriers'
          ) {
            return (
              member?.name
                ?.toLowerCase()
                ?.includes(name?.toLowerCase().trim()) ||
              member?.name === 'header'
            );
          }
          // if (member?.header !== 'Insurance') {

          // }
        })}
        // renderItem={(item, isHighlighted) => (
        //   <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
        //     {item.name}
        //   </div>
        // )}
        renderItem={(item, isHighlighted) => (
          <div
            style={{
              background: 'white',
            }}
          >
            {item?.name === 'header' ? (
              <Typography
                variant="body1"
                sx={{ opacity: 0.5, padding: '10px' }}
                color={darkTextColor}
              >
                {item?.header}
              </Typography>
            ) : (
              <div
                style={{
                  verticalAlign: 'middle',
                  cursor: 'pointer',
                  backgroundColor: isHighlighted
                    ? 'rgba(71, 187, 146, 0.12)'
                    : 'white',
                  padding: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  gap: 10,
                }}
              >
                <div style={{ width: '100%' }}>
                  <Typography
                    variant="body1"
                    color={isHighlighted ? '#185F45' : darkTextColor}
                  >
                    {item?.name}
                  </Typography>
                </div>
              </div>
            )}
          </div>
        )}
        wrapperStyle={{
          position: 'relative',
          padding: 0,
          margin: 0,
          width: '85%',
        }}
        menuStyle={{
          position: 'absolute',
          overflowX: 'visible',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)',
          zIndex: 2,
          top: '5vh',
          left: 0,
          maxHeight: '50vh',
          overflowY: 'scroll',
        }}
        value={name || ''}
        inputProps={{
          placeholder:
            windowDimensions?.width < 1250
              ? 'Insurances'
              : 'In-network insurances',
          style: {
            position: 'relative',
            // flexGrow: 1,
            width: '100%',
            border: 0,
            // height: '6vh',
            padding: 10,
            background: 'white',
            fontSize: '14px',
            borderRadius: 4,
            outline: 0,
            borderRight: '0px solid rgba(233, 232, 235, 1)',
            marginLeft: 20,
          },
        }}
        onChange={(e) => {
          if (e?.target?.value === 'header') return;
          setName(e?.target?.value);
        }}
        onSelect={(name, e, c) => {
          if (name === 'header') return;
          handleChangeSelection(name);
          // if (subMenu && subMenu) setName(name);
        }}
      />
    </>
  );
};

export default InsuranceSearch;
