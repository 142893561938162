import {
  Grid,
  Button,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AlertDialog from '../../components/alertDialog';

const textColor = '#212634';

const SuccessPrompt = (props) => {
  const { handleDone, openDialog } = props;
  const handleCancel = () => {
    handleDone();
  };

  const handleClose = () => {
    handleDone();
  };

  return (
    <AlertDialog title="Message sent" open={openDialog} setOpen={handleClose}>
      <DialogContent sx={{ my: -1, width: { md: '25vw', sm: '100%' } }}>
        <Typography color={textColor} gutterBottom variant="body1">
          Thank you for your feedback!
        </Typography>
        <Typography color={textColor} gutterBottom variant="body1">
          It has been relayed to our team, and we will respond to you (via
          email) as soon as possible.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          sx={{ width: '10rem', height: 40 }}
          onClick={() => {
            handleCancel();
          }}
        >
          Done
        </Button>
      </DialogActions>
    </AlertDialog>
  );
};

export default SuccessPrompt;
