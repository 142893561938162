const InfoIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.56">
      <path
        d="M6.00004 1C8.76144 1 11 3.23856 11 5.99996C11 7.38053 10.4402 8.63102 9.53565 9.53565C8.63102 10.4403 7.38067 11 5.99996 11C3.23856 11 1 8.76144 1 6.00004C1 3.23863 3.23856 1.00007 5.99996 1.00007L6.00004 1ZM6.00004 1.60616C3.57327 1.60616 1.60632 3.57329 1.60632 5.99988C1.60632 8.42646 3.57345 10.3936 6.00004 10.3936C8.42663 10.3936 10.3938 8.42646 10.3938 5.99988C10.3938 3.57329 8.42663 1.60616 6.00004 1.60616Z"
        fill="#212634"
        stroke="#212634"
        strokeWidth="0.5"
      />
      <path
        d="M4.24023 4.24009C4.48702 3.52204 5.21191 3.08008 5.95458 3.08008C6.89472 3.08008 7.75867 3.78584 7.75867 4.76024C7.75867 5.42528 7.34518 5.99433 6.74968 6.26763C6.45804 6.40058 6.28727 6.67046 6.28727 6.99043V7.34639H5.62203V6.99043C5.62203 6.40959 5.94631 5.90672 6.47434 5.6649C6.8317 5.50119 7.09341 5.16219 7.09341 4.76026C7.09341 4.15048 6.5305 3.74534 5.95467 3.74534C5.47078 3.74534 5.04166 4.00211 4.86484 4.46137L4.24032 4.24011L4.24023 4.24009Z"
        fill="#212634"
        stroke="#212634"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.95633 7.90234C6.23737 7.90234 6.4654 8.13038 6.4654 8.41113C6.4654 8.69216 6.23737 8.92019 5.95633 8.92019C5.67557 8.92019 5.44727 8.69216 5.44727 8.41113C5.44727 8.13036 5.6755 7.90234 5.95633 7.90234Z"
        fill="#212634"
        stroke="#212634"
        strokeWidth="0.5"
      />
    </g>
  </svg>
);

export default InfoIcon;
