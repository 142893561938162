import configuration from '../config';
import { DOCTORS_ROUTE } from '../constants/index';

const get = async (query) => {
  const response = await fetch(
    `${configuration.api.url}/${DOCTORS_ROUTE}${query}`,
    {
      headers: {
        'content-type': 'application/json',
        'Max-Forwards': '0',
      },

      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getSpecialities = async () => {
  const response = await fetch(
    `${configuration.api.url}/${DOCTORS_ROUTE}/get-specialty`,
    {
      headers: {
        'content-type': 'application/json',
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getInsurances = async (token) => {
  const response = await fetch(
    `${configuration.api.url}/${DOCTORS_ROUTE}/get-insurance-filters`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const GetFilters = async (token) => {
  const response = await fetch(
    `${configuration.api.url}/${DOCTORS_ROUTE}/get-location-filters`,
    {
      method: 'GET',

      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const Doctors = { get, getSpecialities, GetFilters, getInsurances };

export default Doctors;
