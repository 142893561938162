import { Box, Typography, Grid, Stack, Avatar } from '@mui/material';
import { darkTextColor } from '../../constants';
import PatientDetails from './patientDetails';
import ReferralDetails from './referralDetails';

const bgColor = '#D9D9D9';

const ReferPatientWrapper = ({
  formik,
  formikReferralDetail,
  page,
  files,
  setFiles,
  ownProfile = false,
  handleClear,
  disabled = false,
}) => {
  return (
    <Grid
      container
      direction="column"
      sx={{
        flexWrap: 'nowrap',
        flexGrow: 1,
        p: 3,
        overflow: 'hidden',
        height: '80vh',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          color={darkTextColor}
          variant="h4"
          fontWeight="600"
          fontSize="24px"
        >
          Refer a patient
        </Typography>
        <Typography
          sx={{ opacity: '0.6', cursor: 'pointer' }}
          color={darkTextColor}
          onClick={() => handleClear()}
        >
          Clear all fields
        </Typography>
      </Stack>
      <Grid container direction="row" gap={2} sx={{ flexWrap: 'nowrap' }}>
        <Grid item>
          <Grid
            container
            direction="row"
            gap={2}
            sx={{ py: 2, flexWrap: 'nowrap' }}
            alignItems="center"
          >
            <Avatar
              sx={{
                bgcolor:
                  page === 0 ? '#1665D8 !important' : '#47BB92 !important',
                width: '24px',
                height: '24px',
                fontSize: '14px',
              }}
              variant="square"
            >
              {1}
            </Avatar>
            <Typography
              color={page === 0 ? '#1665D8 !important' : '#47BB92 !important'}
              variant="body1"
              fontSize="14px"
            >
              Patient details
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ display: 'flex' }}>
          <Grid container alignItems="center" justifyContent="center">
            <div
              style={{
                height: '2px',
                width: '36px',
                backgroundColor: page === 1 ? '#47BB92' : bgColor,
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="row"
            gap={2}
            sx={{ py: 2, flexWrap: 'nowrap' }}
            alignItems="center"
          >
            <Avatar
              sx={{
                bgcolor:
                  page === 1 ? '#1665D8 !important' : `${bgColor} !important`,
                width: '24px',
                height: '24px',
                fontSize: '14px',
              }}
              variant="square"
            >
              2
            </Avatar>
            <Typography
              color={
                page === 1
                  ? '#1665D8 !important'
                  : `${darkTextColor} !important`
              }
              variant="body1"
              fontSize="14px"
            >
              Referral details
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box
        id="patient-wraper-container"
        container
        sx={{
          overflowY: 'auto',
          margin: '1%',
          paddingRight: '5px',
          pb: 2,
          flexGrow: 1,
        }}
      >
        {page === 0 ? (
          <PatientDetails
            disabled={disabled}
            formik={formik}
            page={page}
            ownProfile={ownProfile}
          />
        ) : (
          <ReferralDetails
            formik={formikReferralDetail}
            // formik={formik}

            files={files}
            setFiles={setFiles}
            page={page}
          />
        )}
      </Box>
    </Grid>
  );
};

export default ReferPatientWrapper;
