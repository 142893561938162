import React, { useState, useContext, useRef } from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  Container,
  Tab,
  Card,
} from '@mui/material';
import Navbar from '../../components/navbar';
import {
  darkTextColor,
  decryptNumber,
  handleFormatedAddress,
  NotificationColors,
  RESPONSE_STATUS,
} from '../../constants';

import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Users from '../../services/users';
import UserContext from '../../contexts/user/context';
import { LoadingCircularProgress } from '../../components/loadingCircularProgress';
import { LoadingButton, TabContext, TabList } from '@mui/lab';
import Map from './map';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Patients from '../../services/patients';
import ReferPatientWrapper from './referPatientWrapper';
import Favorites from '../../services/favorites';
import CloseLight from '../../Icons/closeLight';
import CancelPrompt from './cancelPrompt';
import SuccessPrompt from './successPrompt';
import moment from 'moment';
import { UserDetails } from './userDetails';
import AlertDialog from '../../components/alertDialog';

const gender = ['male', 'female'];
const urgency = ['Routine', 'Semi-urgent', 'Urgent'];

const checkFav = (favList, userId) => {
  if (favList.length) {
    const findFav = favList.find((f) => f.favoriteId === userId);
    if (findFav) {
      return true;
    }
  }
  return false;
};

export const Profile = () => {
  const [docData, setDocData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const realId = decryptNumber(id ? Number(id) : id);
  const { user, token, setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState('About');
  const [patientDetails, setPatientDetails] = useState();
  const [page, setPage] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const [files, setFiles] = useState([]);

  const [likes, setLikes] = useState(docData?.likes || 0);
  const [endorsements, setEndorsements] = useState(docData?.endorsements || []);

  const [favLoading, setFavLoading] = useState(false);
  const [isFav, setIsFav] = useState();
  const [isScrolling, setIsScrolling] = useState(false);

  const updateFav = (favorites) => {
    const fav = checkFav(favorites, docData.id);
    setIsFav(fav);
  };
  const [height, setHeight] = useState();
  const containerRef = useRef();

  const [isCancel, setIsCancel] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const [errorDialog, setErrorDialog] = useState(false);
  const [errMessage, setErrMessage] = useState('');

  const tabChangeByButton = useRef(false);
  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      tabChangeByButton.current = true;
    }
    setValue(newValue);
    const getElement = document.getElementById(newValue);
    if (getElement) {
      getElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScroll = (event) => {
    if (event.target.scrollTop > 10 && !isScrolling) {
      setIsScrolling(true);
    }
    if (event.target.scrollTop < 10 && isScrolling) {
      setIsScrolling(false);
    }
    if (tabChangeByButton.current) {
      tabChangeByButton.current = false;
      return;
    }
    const aboutDiv = document.getElementById('About');
    const locationDiv = document.getElementById('Office Locations');
    const specialtiesDiv = document.getElementById('Specialties');
    const insuranceDiv = document.getElementById('Insurances');
    const educationDiv = document.getElementById('Education & Training');

    const scroll = event.target.scrollTop;
    const containerPosition = event.target.offsetTop;

    if (aboutDiv?.offsetTop) {
      if (
        aboutDiv?.offsetTop + aboutDiv?.offsetHeight - 15 >
        containerPosition + scroll
      ) {
        setValue('About');
        return;
      }
    }
    if (locationDiv?.offsetTop) {
      if (
        locationDiv?.offsetTop + locationDiv?.offsetHeight - 15 >
        containerPosition + scroll
      ) {
        setValue('Office Locations');
        return;
      }
    }

    if (specialtiesDiv?.offsetTop) {
      if (
        specialtiesDiv?.offsetTop + specialtiesDiv?.offsetHeight - 15 >
        containerPosition + scroll
      ) {
        setValue('Specialties');
        return;
      }
    }
    if (insuranceDiv?.offsetTop) {
      if (
        insuranceDiv?.offsetTop + insuranceDiv?.offsetHeight - 5 >
        containerPosition + scroll
      ) {
        setValue('Insurances');
        return;
      }
    }
    if (educationDiv?.offsetTop) {
      if (
        educationDiv?.offsetTop + educationDiv?.offsetHeight >
        containerPosition + scroll
      ) {
        setValue('Education & Training');
        return;
      }
    }
  };
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await Users.getById(token, realId);
        if (response.status === RESPONSE_STATUS.error) {
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          setLikes(response.body?.favoritesOff?.length || 0);
          setEndorsements(response.body?.favoritesOff || []);
          setDocData(response.body);
        }
      } catch (e) {
        console.log(e.message);
        return;
      } finally {
        setLoading(false);
      }
    };

    if (realId && token) {
      getData();
    }
  }, [realId, token]);

  useEffect(() => {
    if (docData && isFav === undefined && user) {
      const getFav = checkFav(user.favorites, docData.id);
      setIsFav(getFav);
    }
  }, [docData, user]);

  const AddFavorites = async () => {
    if (myProfile) return;
    try {
      setFavLoading(true);
      const response = await Favorites.post(token, docData.id);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setUser(response.body);
        setLikes(likes + 1);
        const tempEndorsement = [...endorsements];
        tempEndorsement.unshift({
          createdAt: new Date(),
          doctorId: user.id,
          FavoriteBy: {
            firstName: user.firstName,
            lastName: user.lastName,
            prefix: user.prefix,
            profile_picture_url: user.profile_picture_url,
          },
        });
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  const RemoveFavorites = async () => {
    if (myProfile) return;
    try {
      setFavLoading(true);

      const response = await Favorites.put(token, docData.id);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setUser(response.body);
        setLikes(likes - 1);
        const tempEndorsement = endorsements.filter(
          (end) => end.doctorId !== user.id,
        );
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  const handleFav = () => {
    if (myProfile) return;
    if (!isFav) {
      AddFavorites();
    } else {
      RemoveFavorites();
    }
  };

  Yup.addMethod(Yup.string, 'higherDate', function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
      const { path, createError } = this;
      const varDate = new Date(value); //dd-mm-YYYY
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return (
        (value && varDate <= today) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  Yup.addMethod(Yup.string, 'validateDate', function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
      const { path, createError } = this;
      var date_regex =
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

      return (
        (value &&
          moment(value?.replaceAll(' ', ''), 'MM/DD/YYYY', true).isValid()) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const handleReferAPatientButton = () => {
    const element = document.getElementById('refer-patient-container');
    if (element) {
      element?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      patientEmail: '',
      canForward: true,
      sex: gender[1],
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      patientEmail: Yup.string('Patient email should be valid.')
        .max(255, 'Must be less than 255 characters')
        .email('Must be a valid email address')
        .nullable(),
      sex: Yup.string(),
      phoneNumber: Yup.string()
        .required('Phone Number is required')
        .length(14, 'Must be exactly 10 digits'),
      canForward: Yup.boolean(),
      dateOfBirth: Yup.string('Date of birth is required')
        .required('Date of birth is required')
        .validateDate('Date should be valid')
        .nullable()
        .higherDate('Date of birth should be less than current date'),
    }),
    onSubmit: (values) => {
      const tempValue = values;
      // tempValue.dateOfBirth = values.dateOfBirth;
      setPatientDetails(tempValue);
      setPage(page + 1);
      const ele = document.getElementById('patient-wraper-container');
      if (ele) {
        ele.scrollTop = 0;
      }
    },
  });

  const formikReferralDetail = useFormik({
    initialValues: {
      reason: '',
      history: '',
      levelOfUrgency: urgency[0],
      report: '',
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason for referral is required'),
      history: Yup.string().required('History of present illness is required'),
      report: Yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      // setPatientDetails(values);

      try {
        setSubmitLoading(true);
        const response = await Patients.post(
          token,
          user.id,
          docData.id,
          null,
          patientDetails.firstName,
          patientDetails.lastName,
          patientDetails.dateOfBirth,
          patientDetails.phoneNumber,
          patientDetails.patientEmail,
          patientDetails.sex,
          patientDetails.canForward,
          values.reason,
          values.history,
          values.levelOfUrgency,
          values.report,
          true, //add toggle
          files,
        );

        if (response.status === RESPONSE_STATUS.error) {
          // setErrorMessage(response.message);
          console.log(response);
          setErrorDialog(true);
          setErrMessage(response?.message);
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          // setErrorMessage(response.message);
          setSuccessMessage(
            `Patient referral sent successfully. We will notify you when ${
              docData.firstName
            } ${docData.lastName} ${docData.prefix.replaceAll(
              ',',
              ', ',
            )} responds.`,
          );
          setIsSuccess(true);
          setFiles([]);
          resetForm();
          formik.resetForm();
          setPage(0);
          return;
        }
      } catch (e) {
        console.log(e.message);
        return;
      } finally {
        setSubmitLoading(false);
      }
    },
  });

  const updateHeight = () => {
    if (containerRef.current) {
      const elementHeight =
        window.innerHeight -
        containerRef.current.getBoundingClientRect().top -
        20;
      setHeight(elementHeight);
    }
  };

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (docData) {
      setTimeout(() => {
        const getEle = document.getElementById('scroll-container');
        if (getEle) {
          const elementHeight =
            window.innerHeight - getEle.getBoundingClientRect().top - 20;
          setHeight(elementHeight);
        }
      }, 500);
    }
  }, [containerRef, docData]);

  useEffect(() => {
    const getEle = document.getElementById('scroll-container');
    if (getEle) {
      const elementHeight =
        window.innerHeight - getEle.getBoundingClientRect().top - 20;
      setHeight(elementHeight);
    }
  }, [notificationData]);

  useEffect(() => {
    updateHeight();
  }, [containerRef.current, isScrolling]);

  const handleNext = async () => {
    if (page === 0) {
      formik.handleSubmit();
      return;
    }
    formikReferralDetail.handleSubmit();
  };

  if (loading || !user) {
    return <LoadingCircularProgress />;
  }

  // const checkDisabled = () => {
  //   if (page) {
  //     return false;
  //   }
  //   const { dateOfBirth, firstName, lastName, patientEmail, phoneNumber } =
  //     formik.values;

  //   if (dateOfBirth || firstName || lastName || patientEmail || phoneNumber) {
  //     return false;
  //   }
  //   return true;
  // };

  const handleClear = () => {
    if (!page) {
      formik.resetForm();
    } else {
      setIsCancel(true);
    }
  };
  const currentYear = new Date().getFullYear();
  const myProfile = user.id === Number(realId);
  return (
    <>
      <CancelPrompt
        openDialog={isCancel}
        setOpenDialog={setIsCancel}
        formik={formik}
        formikReferralDetail={formikReferralDetail}
        setPage={setPage}
        setFiles={setFiles}
      />
      <SuccessPrompt
        openDialog={isSuccess}
        setOpenDialog={setIsSuccess}
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
        setNotificationData={setNotificationData}
      />
      <AlertDialog
        title="Error"
        open={errorDialog}
        setOpen={setErrorDialog}
        message={errMessage}
        error
      />
      <Box
        sx={{
          height: '100vh',
          overflow: { lg: 'auto' },
          bgcolor: '#F7F7F7',
        }}
      >
        <Navbar />
        {notificationData?.type && (
          <Card
            sx={{
              mt: 0,
              width: '100%',
              height: '4vh',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              bgcolor: NotificationColors[notificationData.type].bgcolor,
              borderRadius: 0,
              px: 5,
              py: 0.5,
              transition: 'all 0.3s',
            }}
          >
            <Grid direction="row" container gap={2} alignItems="center">
              <div style={{ cursor: 'pointer', marginTop: 5 }}>
                {notificationData?.emailVerification
                  ? NotificationColors['warning'].icon
                  : NotificationColors[notificationData.type].icon}
              </div>
              <Typography
                variant="body1"
                color={NotificationColors[notificationData.type].color}
              >
                {notificationData.text}
              </Typography>
            </Grid>
            <div
              onClick={() => {
                setNotificationData({});
              }}
              style={{ cursor: 'pointer' }}
            >
              <CloseLight
                color={NotificationColors[notificationData.type].color}
              />
            </div>
          </Card>
        )}
        {myProfile && (
          <Card
            sx={{
              mt: 0,
              width: '100%',
              // height: '6vh',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              bgcolor: '#47BB92',
              borderRadius: 0,
              px: 5,
              py: 0.5,
              transition: 'all 0.3s',
            }}
          >
            <Grid direction="row" container gap={2} alignItems="center">
              <Typography variant="body1" fontWeight={600}>
                Viewing your profile as a medLynks user
              </Typography>
            </Grid>
            <Button
              size="large"
              variant="contained"
              onClick={() => navigate(`/profile/edit`)}
              sx={{
                // height: 50,
                border: '1px solid white',
                color: 'white',
                width: '14rem',
                height: 40,
                bgcolor: 'transparent',
                '&:hover': {
                  color: 'white',
                },
              }}
            >
              Edit profile
            </Button>
          </Card>
        )}
        <Container maxWidth="xl">
          <Grid
            direction={{ lg: 'row', xs: 'row' }}
            gap={4}
            container
            sx={{
              pt: 2,
              px: { lg: 5, md: 1, xs: 2 },
              flexWrap: 'nowrap',
            }}
          >
            <Grid
              direction="column"
              container
              sx={{ flex: { md: 1.5 }, transition: 'all 0.5s ease-out' }}
            >
              <Stack direction="column">
                <UserDetails
                  scroll={isScrolling}
                  docData={docData}
                  isFav={isFav}
                  favLoading={favLoading}
                  handleFav={handleFav}
                  myProfile={myProfile}
                  endorsements={endorsements}
                  likes={likes}
                  RemoveFavorites={isFav ? RemoveFavorites : AddFavorites}
                />
              </Stack>
              <Grid>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderTop: 1,
                    borderColor: 'divider',
                  }}
                >
                  <TabContext value={value} sx={{ color: darkTextColor }}>
                    <TabList onChange={handleChange}>
                      {docData?.about ? (
                        <Tab
                          label="About"
                          sx={{ color: darkTextColor, textTransform: 'none' }}
                          value="About"
                        />
                      ) : (
                        <div></div>
                      )}
                      {docData?.address?.length ? (
                        <Tab
                          label="Office Locations"
                          sx={{ color: darkTextColor, textTransform: 'none' }}
                          value="Office Locations"
                        />
                      ) : (
                        <div />
                      )}
                      <Tab
                        label="Specialties"
                        sx={{ color: darkTextColor, textTransform: 'none' }}
                        value="Specialties"
                      />
                      {docData?.insurances?.length ? (
                        <Tab
                          label="Insurances"
                          sx={{ color: darkTextColor, textTransform: 'none' }}
                          value="Insurances"
                        />
                      ) : (
                        <div></div>
                      )}
                      {docData?.medical_school || docData.residency ? (
                        <Tab
                          label="Education & Training"
                          sx={{ color: darkTextColor, textTransform: 'none' }}
                          value="Education & Training"
                        />
                      ) : (
                        <div></div>
                      )}
                    </TabList>
                  </TabContext>
                </Box>
                <Stack
                  ref={containerRef}
                  id="scroll-container"
                  onScroll={(e) => handleScroll(e)}
                  sx={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    mt: 2,
                    mx: 1,
                    mb: 0,
                    pb: 0,
                    height: `${height ? `${height}px` : '23em'}`,
                  }}
                  spacing={2}
                >
                  {docData?.about ? (
                    <Grid id="About">
                      <Typography
                        sx={{ fontWeight: 600 }}
                        color={darkTextColor}
                        variant="h5"
                      >
                        About
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          my: 2,
                          overflowWrap: 'anywhere',
                        }}
                        color={darkTextColor}
                      >
                        {docData.about}
                      </Typography>
                      <Stack
                        direction="row"
                        gap={4}
                        alignItems="center"
                        sx={{ mt: -2 }}
                      >
                        {docData.officeNumber && (
                          <Stack direction="row" gap={2} alignItems="center">
                            <Typography
                              sx={{ fontWeight: 600 }}
                              color={darkTextColor}
                              variant="h6"
                              fontSize="14px"
                            >
                              Office Contacts:
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                my: 2,
                                overflowWrap: 'anywhere',
                              }}
                              color={darkTextColor}
                            >
                              {docData.officeNumber}
                            </Typography>
                          </Stack>
                        )}
                        {docData.availabilityTime && (
                          <Stack direction="row" gap={2} alignItems="center">
                            <Typography
                              sx={{ fontWeight: 600 }}
                              color={darkTextColor}
                              variant="h6"
                              fontSize="14px"
                            >
                              Referral-to-Visit Time Estimate:
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 400,
                                my: 2,
                                overflowWrap: 'anywhere',
                              }}
                              color={darkTextColor}
                            >
                              {docData.availabilityTime}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {docData?.address?.length ? (
                    <Grid id="Office Locations">
                      <Typography
                        sx={{ fontWeight: 600 }}
                        color={darkTextColor}
                        variant="h5"
                      >
                        Office Locations
                      </Typography>
                      <Stack
                        direction="row"
                        sx={{
                          width: '100%',
                          my: 2,
                        }}
                        spacing={2}
                      >
                        {docData?.address?.length ? (
                          <Map data={docData.address} />
                        ) : (
                          <></>
                        )}
                        <Stack spacing={1}>
                          {docData.address.map((add) => {
                            if (add.is_primary) {
                              return (
                                <div style={{ marginBottom: '10px' }}>
                                  <Typography
                                    variant="h6"
                                    fontWeight="500"
                                    color={darkTextColor}
                                  >
                                    Primary
                                  </Typography>
                                  <Typography
                                    fontWeight="500"
                                    color={darkTextColor}
                                  >
                                    {add.name}
                                  </Typography>
                                  <Typography
                                    color={darkTextColor}
                                    sx={{ overflowWrap: 'anywhere' }}
                                  >
                                    {handleFormatedAddress(
                                      add?.formatted_address,
                                      add?.city,
                                    )}
                                    {add.city}, {add.state} {add.postal_code}
                                  </Typography>
                                </div>
                              );
                            }
                          })}
                          {docData?.address?.filter((add) => !add.is_primary)
                            ?.length ? (
                            <Typography
                              variant="h6"
                              fontWeight="500"
                              sx={{ mt: 2 }}
                              color={darkTextColor}
                            >
                              Others
                            </Typography>
                          ) : (
                            <></>
                          )}

                          {docData.address.map((add) => {
                            if (!add.is_primary) {
                              return (
                                <div>
                                  <Typography
                                    fontWeight="500"
                                    color={darkTextColor}
                                  >
                                    {add.name}
                                  </Typography>
                                  <Typography
                                    color={darkTextColor}
                                    sx={{ overflowWrap: 'anywhere' }}
                                  >
                                    {add.formatted_address
                                      ? `${
                                          add.formatted_address?.split(',')[0]
                                        }, `
                                      : ''}
                                    {add.city}, {add.state} {add.postal_code}
                                  </Typography>
                                </div>
                              );
                            }
                          })}
                        </Stack>
                      </Stack>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid id="Specialties">
                    <Typography
                      sx={{ fontWeight: 600 }}
                      color={darkTextColor}
                      variant="h5"
                    >
                      Specialties
                    </Typography>
                    <Grid
                      container
                      direction="column"
                      sx={{ width: '100%', my: 2 }}
                      gap={2}
                    >
                      <Stack spacing={1}>
                        {docData.specialties
                          .filter((spec) => spec.class === 'main')
                          .map((spec) => (
                            <Typography color={darkTextColor}>
                              {spec.specialty}
                            </Typography>
                          ))}
                      </Stack>
                      <Stack spacing={1}>
                        {docData?.specialties?.filter(
                          (spec) => spec.class !== 'main',
                        )?.length ? (
                          <Typography
                            color={darkTextColor}
                            fontWeight={600}
                            sx={{ overflowWrap: 'anywhere' }}
                          >
                            Subspecialties
                          </Typography>
                        ) : (
                          <></>
                        )}
                        {docData.specialties
                          .filter((spec) => spec.class !== 'main')
                          .map((spec) => (
                            <Typography color={darkTextColor}>
                              {spec.specialty}
                            </Typography>
                          ))}
                      </Stack>
                    </Grid>
                  </Grid>
                  {docData?.insurances?.length ? (
                    <Grid id="Insurances">
                      <Typography
                        sx={{ fontWeight: 600 }}
                        color={darkTextColor}
                        variant="h5"
                      >
                        In-network insurances
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        sx={{
                          // width:
                          //   docData?.insurances?.length > 10 ? '100%' : '750px',
                          my: 2,
                        }}
                        rowGap={2}
                        columnGap={20}
                      >
                        {docData?.insurances
                          .sort((a, b) => ('' + a.name).localeCompare(b.name))
                          .map((insurance) => (
                            <Typography
                              color={darkTextColor}
                              sx={{
                                overflowWrap: 'anywhere',
                                textAlign: 'start',
                                width: '200px',
                              }}
                            >
                              {insurance.name}
                            </Typography>
                          ))}
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {docData?.medical_school || docData.residency ? (
                    <Grid id="Education & Training">
                      <Typography
                        sx={{ fontWeight: 600 }}
                        color={darkTextColor}
                        variant="h5"
                      >
                        Education & Training
                      </Typography>
                      <Grid
                        container
                        direction="column"
                        sx={{ width: '100%', my: 2 }}
                        gap={2}
                      >
                        {docData.medical_school ? (
                          <Stack spacing={1} direction="column">
                            <Typography color={darkTextColor} fontWeight={600}>
                              Medical School
                            </Typography>
                            <Grid
                              sx={{ ml: '2.5% !important' }}
                              direction="row"
                              container
                              gap={1}
                            >
                              <Typography color={darkTextColor}>
                                {docData.medical_school},
                              </Typography>
                              <Typography color={darkTextColor}>
                                {docData.medical_school_year}
                              </Typography>
                            </Grid>
                          </Stack>
                        ) : (
                          <></>
                        )}
                        {docData.residency ? (
                          <Stack spacing={1} direction="column">
                            <Typography color={darkTextColor} fontWeight={600}>
                              Residency
                            </Typography>
                            <Grid
                              sx={{ ml: '2.5% !important' }}
                              direction="row"
                              container
                              gap={1}
                            >
                              <Typography color={darkTextColor}>
                                {docData.residency},
                              </Typography>
                              <Typography color={darkTextColor}>
                                {docData.residency_year}
                                {docData.residency_year > currentYear
                                  ? ' (expected)'
                                  : ''}
                              </Typography>
                            </Grid>
                          </Stack>
                        ) : (
                          <></>
                        )}
                        {docData?.fellowships?.length ? (
                          <>
                            <Typography color={darkTextColor} fontWeight={600}>
                              Fellowships
                            </Typography>
                            <Stack gap={1} sx={{ ml: '2.5% !important' }}>
                              {docData?.fellowships.map((fellow) => (
                                <Stack
                                  spacing={1}
                                  direction="row"
                                  sx={{ mt: -1 }}
                                >
                                  <Typography color={darkTextColor}>
                                    {fellow.name},
                                  </Typography>
                                  <Typography color={darkTextColor}>
                                    {fellow.year}{' '}
                                    {fellow.year > currentYear
                                      ? '(expected)'
                                      : ''}
                                  </Typography>
                                </Stack>
                              ))}
                            </Stack>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Stack>
                {/* </Container> */}
              </Grid>
            </Grid>
            <Grid
              container
              id="refer-patient-container"
              sx={{
                flex: 1,
                bgcolor: '#FFFFFF',
                border: '1px solid #E2E2E2',
                borderRadius: '4px',
                position: 'relative',
                overflow: 'none',
                // filter: myProfile && 'blur(2px)',
                // filter:
                //   (myProfile || !docData.isAvailable) && 'grayscale(100%)',
                display: {
                  md: 'block',
                  sm: myProfile || !docData.isAvailable ? 'none' : 'none',
                },
                pointerEvents: (myProfile || !docData.isAvailable) && 'none',
              }}
            >
              <ReferPatientWrapper
                formik={formik}
                formikReferralDetail={formikReferralDetail}
                page={page}
                files={files}
                setFiles={setFiles}
                ownProfile={true}
                handleClear={handleClear}
                disabled={myProfile || !docData.isAvailable}
              />
              <Grid
                container
                gap={1.5}
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{
                  flexWrap: 'nowrap',
                  p: 3,
                  mt: 1,
                  bottom: '0px',
                  height: 'auto',
                  backgroundColor: '#FFFFFF',
                  position: 'absolute',
                }}
              >
                {page === 1 ? (
                  <Button
                    color="error"
                    size="small"
                    onClick={() => setPage(0)}
                    variant="contained"
                    sx={{
                      height: 40,
                      flexGrow: 1,
                      bgcolor: 'rgba(47, 47, 47, 0.14) !important',
                      color: '#2F2F2F',
                    }}
                  >
                    Previous
                  </Button>
                ) : (
                  <></>
                )}

                <LoadingButton
                  color="primary"
                  size="large"
                  disabled={!docData.isAvailable || myProfile}
                  onClick={() => handleNext()}
                  loading={submitLoading}
                  loadingPosition="end"
                  variant="contained"
                  sx={{
                    flexGrow: 4,
                    height: 40,
                    '&:disabled': {
                      color: darkTextColor,
                      opacity: 0.5,
                    },
                  }}
                >
                  {page === 1 ? 'Send referral' : 'Next'}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
