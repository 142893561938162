const Verification = () => (
  <svg
    width="259"
    height="119"
    viewBox="0 0 259 119"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_486_41126)">
      <path
        d="M26 16.5H233C237.142 16.5 240.5 19.8579 240.5 24V91C240.5 95.1421 237.142 98.5 233 98.5H26C21.8579 98.5 18.5 95.1421 18.5 91V24C18.5 19.8579 21.8579 16.5 26 16.5Z"
        fill="white"
        stroke="#F7F7F7"
      />
      <path
        d="M59.3408 59.395H48.6596C41.1109 59.395 35 65.4547 35 73.0033V79.7303C35 80.86 35.9241 81.7842 37.0539 81.7842L70.9461 81.7845C72.0758 81.7845 73 80.8604 73 79.7306V73.0033C73 65.4549 66.889 59.395 59.3404 59.395L59.3408 59.395Z"
        fill="#212634"
      />
      <path
        d="M65.5556 44.5542C65.5556 50.9352 60.3824 56.1083 54.0014 56.1083C47.6204 56.1083 42.4473 50.9352 42.4473 44.5542C42.4473 38.1731 47.6204 33 54.0014 33C60.3824 33 65.5556 38.1731 65.5556 44.5542Z"
        fill="#212634"
      />
      <rect
        opacity="0.12"
        x="89"
        y="33"
        width="135"
        height="20"
        rx="4"
        fill="#212634"
      />
      <rect
        opacity="0.12"
        x="89"
        y="62"
        width="88"
        height="20"
        rx="4"
        fill="#212634"
      />
    </g>
    <rect x="207" y="65" width="46" height="46" rx="23" fill="#1665D8" />
    <path
      d="M237.773 81.647V76H223V81.647C223 83.3866 223.857 85 225.269 85.9832L228.168 88L225.269 90.0168C223.857 91 223 92.6135 223 94.353V100H237.798V94.3021C237.798 92.5626 236.941 90.9492 235.529 89.966L232.63 87.9491L235.529 85.9323C236.941 84.9744 237.773 83.361 237.773 81.6468L237.773 81.647ZM234.092 92.0588C234.823 92.5629 235.277 93.42 235.277 94.3025V97.4285H225.521V94.3025C225.521 93.3949 225.975 92.5629 226.706 92.0588L230.387 89.5126L234.092 92.0588Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_486_41126"
        x="0"
        y="0"
        width="259"
        height="119"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_486_41126"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_486_41126"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Verification;
