import React from 'react';
import MarkerIcon from '../../Icons/markerIcon';

const Marker = () => {
  return (
    <div style={{ cursor: 'pointer' }}>
      <MarkerIcon fill={'#ED9C35'} />
    </div>
  );
};

export default Marker;
