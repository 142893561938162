import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Link,
  Typography,
  Grid,
  Card,
  Avatar,
  Tab,
  Stack,
  InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/user/context';
import Navbar from '../../components/navbar';
import './index.css';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  darkTextColor,
  NotificationColors,
  RESPONSE_STATUS,
  GetImageFromUrl,
  PatientStatus,
  DisplaySpecialties,
  DisplaySuffix,
  CapitalizeFirstLetter,
  encryptNumber,
  handleSpeciality,
  DEFAULT_FOOTER_SIZE,
} from '../../constants';
import CloseLight from '../../Icons/closeLight';
import User from '../../services/users';
import ReviewPatientReferral from './reviewPatientReferral';
import { EmptyIcon } from '../../Icons/emptyIcon';
import FindSpecialist from '../../components/findSpecialist';
import ReferralCard from './referralCard';
import Patients from '../../services/patients';
import FrowardsReferal from './reviewPatientReferral/forwardReferral';
import {
  LoadingCircularProgress,
  LoadingCircularProgressSized,
} from '../../components/loadingCircularProgress';
import CancelDialog from '../../components/cancelDialog';
import { NotificationNumber } from '../../components/notificationNumber';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomInputField from '../../components/customInputField';
import { LargeText } from '../../components/largeText/largeText';
import InvitePrompt from './invitePrompt';
import OverviewNotification from '../../components/notification/overviewNotification';
import Switch from 'react-ios-switch';
import { EmptyReferrals } from './emptyReferrals';
import { FooterVerifiedUser } from '../../components/footer';

const EmptyData = ({ user, navigate }) => (
  <Stack
    alignItems="center"
    justifyContent="center"
    direction="column"
    sx={{
      width: '100%',
      height: '43vh',
    }}
  >
    <EmptyIcon />
    <Typography variant="body1" color={darkTextColor}>
      You have no pending referrals.
    </Typography>
    {!user.doctor_verified || !user.email_verified ? (
      <Button
        color="primary"
        size="large"
        className="custom-button"
        onClick={() => navigate('/profile/edit')}
        variant="contained"
        sx={{
          height: 40,
          mt: 2,
          bgcolor: '#FFFFFF',
          border: '1px solid #47BB92',
          color: '#47BB92',
        }}
      >
        Setup profile
      </Button>
    ) : (
      <></>
    )}
  </Stack>
);

const TabLabel = ({ text, number, sx }) => {
  return (
    <Stack
      gap={1}
      direction="row"
      alignItems="center"
      sx={{ flexWrap: 'nowrap', ...sx }}
    >
      {text}
      <NotificationNumber number={number} variant="body2" />
    </Stack>
  );
};

const defaultData = [
  {
    link: '/profile/edit',
    linkText: 'Set up your profile',
    text: 'to enhance visibility and attract relevant referrals.',
    isVisible: true,
  },
  {
    link: '/find',
    linkText: 'Connect with local colleagues',
    text: 'to boost collaboration and grow your network.',
    isVisible: true,
  },
];
const Home = () => {
  const {
    user,
    token,
    setUser,
    loadingState,
    allReferrals,
    setAllReferrals,
    setAcceptReferralDialogue,
    siteReferralLoading,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [value, setValue] = React.useState('1');
  const [notificationData, setNotificationData] = useState({});
  const [notificationDataProfile, setNotificationDataProfile] = useState(false);

  const [defaultCardData, setDefaultCardData] = useState(defaultData);

  const [loading, setLoading] = useState(false);

  const [openReviewPatient, setOpenReviewPatient] = useState(false);
  const [referralStatus, setReferralStatus] = useState(false);
  const [referrals, setReferrals] = useState([]);
  const [selectedReferral, setSelectedReferral] = useState();
  const [isForward, setIsForward] = useState(false);
  const [cancel, setCancel] = useState(false);

  const [sendInviteLoading, setSendInviteLoading] = useState(false);
  const [invitePrompt, setInvitePrompt] = useState(false);
  const [completed, setCompleted] = useState(false);

  const [showError, setShowError] = useState(false);
  const [inviteError, setInviteError] = useState('');
  const idRef = useRef();

  const [heightCards, setHeightCards] = useState();
  const cardsContainerRef = useRef();

  const [height, setHeight] = useState();
  const containerRef = useRef();

  const showEmailVerificationBanner = useRef(false);
  const showProfileVerificationBanner = useRef(false);

  const [referralLoading, setReferralLoading] = useState(false);

  const greenColor = NotificationColors.success.bgcolor;
  const blackColor = NotificationColors.dark.color;

  const [isInviteFocus, setInviteFocused] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email must be valid ')
        .max(255, 'Email should be less than 255 characters')
        .required('Email is required'),
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
    }),
    onSubmit: async (values) => {
      const { email, firstName, lastName } = values;
      try {
        setSendInviteLoading(true);
        const response = await User.sendInvite(
          token,
          email,
          `${firstName} ${lastName}`,
        );
        if (response.status === RESPONSE_STATUS.success) {
          formik.setFieldValue('email', '');
          setInviteError('');

          setShowError(false);
          setCompleted(true);
        }
        if (response.status === RESPONSE_STATUS.error) {
          setInviteError(response.message);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setSendInviteLoading(false);
      }
    },
  });

  const handleRemoveDefaultCard = (index) => {
    const _tempData = defaultCardData.filter((data, i) => i !== index);
    setDefaultCardData(_tempData);
  };
  // const showEmailVerificationBanner = useRef(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawer = (referralData, status) => {
    setSelectedReferral(referralData);
    setReferralStatus(status);
    setOpenReviewPatient(true);
  };

  const updateReferrals = (body) => {
    const sorted = body
      .sort((a, b) => {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      })
      .reverse();

    setReferrals(sorted);
  };

  const updateStatus = async (
    status,
    reasonToDecline = '',
    additionalComments = '',
    referredTo = null,
    doctorName = '',
    selectedStaff,
    updatedStaff,
  ) => {
    try {
      setReferralLoading(true);
      const response = await Patients.put(
        token,
        selectedReferral.id,
        status,
        reasonToDecline,
        additionalComments,
        referredTo,
        updatedStaff,
      );

      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        const isStaffSelected = selectedStaff?.find(
          (selected) => selected === true,
        );
        const updated = allReferrals.filter(
          (referral) => referral.id !== selectedReferral.id,
        );
        updateReferrals(updated);
        setAllReferrals(updated);

        if (status === PatientStatus.Accept) {
          setNotificationData({
            type: 'success',
            emailVerification: false,
            text: `Patient referral accepted successfully. ${
              isStaffSelected
                ? 'We will send the patient’s contact details to your office staff via email.'
                : ''
            }`,
          });
        }
        if (status === PatientStatus.Decline) {
          setNotificationData({
            type: 'error',
            emailVerification: false,
            text: `Patient referral declined successfully. We will send a confirmation to ${doctorName}.`,
          });
        }
        if (status === PatientStatus.Referred) {
          setNotificationData({
            type: 'success',
            emailVerification: false,
            text: `Patient referral forwarded successfully. We will send a confirmation to ${doctorName}.`,
          });
        }

        setAcceptReferralDialogue(false);
        setIsForward(false);
        setOpenReviewPatient(false);
        return;
      }
    } catch (e) {
      console.log(e.message);
      return;
    } finally {
      setReferralLoading(false);
    }
  };

  const handleOpenCancelDialog = (id) => {
    idRef.current = id;
    setCancel(true);
  };

  const handleCancelReferral = async () => {
    const id = idRef.current;
    try {
      const response = await Patients.del(token, id);

      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        // const updated = allReferrals.filter((referral) => referral.id !== id);
        // updateReferrals(updated);
        // setAllReferrals(updated);
        updateReferrals(response?.body);
        setAllReferrals(response?.body);
        setCancel(false);
        return;
      }
    } catch (e) {
      console.log(e.message);
      return;
    }
  };

  const updateHeight = () => {
    if (containerRef.current) {
      const elementHeight =
        window.innerHeight -
        containerRef.current.getBoundingClientRect().top -
        20 -
        DEFAULT_FOOTER_SIZE;
      setHeight(elementHeight);
    }
    if (cardsContainerRef.current) {
      const cardContainerHeight =
        window.innerHeight -
        cardsContainerRef.current.getBoundingClientRect().top -
        20 -
        DEFAULT_FOOTER_SIZE;
      setHeightCards(cardContainerHeight);
    }
  };

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const newTimeout = setTimeout(updateHeight(), 250);
    return () => clearTimeout(newTimeout);
  }, [siteReferralLoading]);

  useEffect(() => {
    updateHeight();
  }, [cardsContainerRef.current]);

  useEffect(() => {
    if (allReferrals) {
      updateReferrals(allReferrals);
    }
  }, [token, allReferrals]);

  useEffect(() => {
    if (
      user &&
      user.doctor_verified === 0 &&
      !showProfileVerificationBanner.current
    ) {
      setNotificationDataProfile(true);
    }
  }, [user]);

  const handleResendVerificationEmail = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await User.resendVerificationEmail(token);

      if (response.status === RESPONSE_STATUS.success) {
        setNotificationData({});
      }

      if (response.error === RESPONSE_STATUS.error) {
        setNotificationData({
          type: 'error',
          text: response.message,
        });
      }
    } catch (e) {
      setNotificationData({
        type: 'error',
        text: e.message,
      });
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleAvalibleForRefferals = async (value) => {
    if (loading || !user.doctor_verified || !user.email_verified) return;
    try {
      setLoading(true);
      const response = await User.updateReferral(token, value);

      if (response.status === RESPONSE_STATUS.success) {
        setUser(response.body);
      }

      if (response.error === RESPONSE_STATUS.error) {
        setNotificationData({
          type: 'error',
          text: response.message,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getReferrals = async () => {
      try {
        const response = await Patients.get(token);

        if (response.status === RESPONSE_STATUS.error) {
          // setErrorMessage(response.message);
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          setAllReferrals(response?.body);
          // updateReferrals(response?.body);
          return;
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    getReferrals();
  }, []);

  useEffect(() => {
    if ((!token || !user) && !loadingState) {
      navigate('/');
    }
    if (token && user && (!user?.email_verified || !user?.doctor_verified)) {
      navigate('/verification');
    }
  }, [loadingState, navigate, token, user]);

  const handleResize = () => {};
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleEnter = (e) => {
      if ((e.code === 'Enter' || e.keyCode === 13) && isInviteFocus) {
        if (formik.values.email) {
          sendInvite();
        }
      }
    };
    window.addEventListener('keydown', handleEnter);

    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, [isInviteFocus, formik]);

  if (loadingState) {
    return <LoadingCircularProgress />;
  }

  // formik.handleSubmit();
  const sendInvite = () => {
    if (!formik.values.email) {
      formik.handleSubmit();
      setShowError(true);
      return;
    }
    if (!formik.errors.email && formik.values.email) {
      formik.setTouched({}, false);
      setInvitePrompt(true);
    } else {
      setShowError(true);
    }
  };
  const avatar = GetImageFromUrl(user.profile_picture_url);

  return (
    <>
      <InvitePrompt
        openDialog={invitePrompt}
        setOpenDialog={setInvitePrompt}
        formik={formik}
        sendInviteLoading={sendInviteLoading}
        completed={completed}
        setCompleted={setCompleted}
        inviteError={inviteError}
        setInviteError={setInviteError}
      />
      <CancelDialog
        open={cancel}
        setOpen={setCancel}
        handleCancel={handleCancelReferral}
        id={1}
      />
      <ReviewPatientReferral
        anchor={openReviewPatient}
        setAnchor={setOpenReviewPatient}
        referralData={selectedReferral}
        user={user}
        updateStatus={updateStatus}
        accept={referralStatus}
        setIsForward={setIsForward}
        referralLoading={referralLoading}
      />

      <FrowardsReferal
        anchor={isForward}
        setAnchor={setIsForward}
        referralData={selectedReferral}
        user={user}
        updateStatus={updateStatus}
        accept={referralStatus}
        isForward={isForward}
        setIsForward={setIsForward}
        referralLoading={referralLoading}
      />
      {/* {openReviewPatient && (
      )} */}
      <Box sx={{ maxHeight: '100vh', height: '100vh', overflow: 'hidden' }}>
        <Navbar />
        <FindSpecialist isInviteFocus={isInviteFocus} />
        {notificationData?.type && (
          <Card
            sx={{
              mt: 0,
              width: '100%',
              height: '4vh',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              bgcolor: NotificationColors[notificationData.type].bgcolor,
              borderRadius: 0,
              px: 5,
              py: 0.5,
              transition: 'all 0.3s',
            }}
          >
            <Grid direction="row" container gap={2} alignItems="center">
              <Stack sx={{ alignSelf: 'center' }}>
                {NotificationColors[notificationData.type].icon}
              </Stack>
              <Typography
                color={NotificationColors[notificationData.type].color}
                sx={{ fontSize: '14px' }}
              >
                {notificationData.text}
              </Typography>
            </Grid>
            <div
              onClick={() => {
                if (notificationData.emailVerification === true) {
                  showEmailVerificationBanner.current = true;
                }
                setNotificationData({});
              }}
              style={{ cursor: 'pointer' }}
            >
              <CloseLight
                opacity={'1'}
                color={NotificationColors[notificationData.type].color}
              />
            </div>
          </Card>
        )}

        <Grid container spacing={2} sx={{ py: 2, px: 3 }}>
          <Grid
            item
            sx={{
              display: {
                md: 'block',
                sm: !referrals.find((d) => d.status === null) && 'none',
                xs: !referrals.find((d) => d.status === null) && 'none',
              },
              flex: 2,
            }}
          >
            <Grid container direction="column">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600 }}
                  color={darkTextColor}
                >
                  Pending referrals
                </Typography>
                <Link
                  color="primary"
                  variant="subtitle2"
                  underline="hover"
                  onClick={() => navigate('/referrals')}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  View all referrals
                </Link>
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  typography: 'body1',
                  mt: 2,
                }}
              >
                {siteReferralLoading ? (
                  <Stack
                    sx={{
                      height: `${heightCards ? `${heightCards}px` : '50vh'}`,
                    }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <LoadingCircularProgressSized />
                  </Stack>
                ) : (
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleChange}>
                        <Tab
                          label={
                            <TabLabel
                              text={'Show all'}
                              number={
                                referrals?.filter((d) => d.status === null)
                                  ?.length || 0
                              }
                              sx={{
                                fontWeight: 450,
                                textTransform: 'none',
                                color: value === '1' ? greenColor : blackColor,
                              }}
                            />
                          }
                          value="1"
                        />
                        <Tab
                          label={
                            <TabLabel
                              text={'Sent'}
                              number={
                                referrals?.filter(
                                  (d) =>
                                    d.doctorId === user.id && d.status === null,
                                )?.length || 0
                              }
                              sx={{
                                fontWeight: 450,
                                textTransform: 'none',
                                color: value === '2' ? greenColor : blackColor,
                              }}
                            />
                          }
                          value="2"
                        />
                        <Tab
                          label={
                            <TabLabel
                              text={'Received'}
                              number={
                                referrals?.filter(
                                  (d) =>
                                    d.referredToId === user.id &&
                                    d.status === null,
                                )?.length || 0
                              }
                              sx={{
                                fontWeight: 450,
                                textTransform: 'none',
                                color: value === '3' ? greenColor : blackColor,
                              }}
                            />
                          }
                          value="3"
                        />
                      </TabList>
                    </Box>
                    <TabPanel
                      ref={cardsContainerRef}
                      id="cards-container"
                      sx={{
                        overflowY: 'auto',
                        maxHeight: `${
                          heightCards ? `${heightCards}px` : '50vh'
                        }`,
                        flex: 1,
                      }}
                      value="1"
                    >
                      <Grid container direction="column" gap={2}>
                        {referrals.length &&
                        referrals.find((d) => d.status === null) ? (
                          <>
                            {referrals.map((referralData, index) => {
                              if (
                                referralData.status !== null ||
                                referralData.doctorId === user.id
                              )
                                return <></>;
                              return (
                                <ReferralCard
                                  referralData={referralData}
                                  index={index}
                                  user={user}
                                  handleDrawer={handleDrawer}
                                  handleCancelReferral={handleOpenCancelDialog}
                                />
                              );
                            })}
                            {referrals.map((referralData, index) => {
                              if (
                                referralData.status !== null ||
                                referralData.doctorId !== user.id
                              )
                                return <></>;
                              return (
                                <ReferralCard
                                  referralData={referralData}
                                  index={index}
                                  user={user}
                                  handleDrawer={handleDrawer}
                                  handleCancelReferral={handleOpenCancelDialog}
                                />
                              );
                            })}
                          </>
                        ) : (
                          <EmptyReferrals
                            data={defaultCardData}
                            removeCard={handleRemoveDefaultCard}
                          />
                        )}
                      </Grid>
                    </TabPanel>
                    <TabPanel
                      sx={{
                        overflowY: 'auto',
                        maxHeight: `${
                          heightCards ? `${heightCards}px` : '50vh'
                        }`,
                        flex: 1,
                      }}
                      value="2"
                    >
                      <Grid container direction="column" gap={2}>
                        {referrals.length &&
                        referrals.find(
                          (d) => d.doctorId === user.id && d.status === null,
                        ) ? (
                          referrals.map((referralData, index) => {
                            if (
                              referralData.doctorId !== user.id ||
                              referralData.status !== null
                            )
                              return <></>;
                            return (
                              <ReferralCard
                                referralData={referralData}
                                index={index}
                                user={user}
                                handleDrawer={handleDrawer}
                                handleCancelReferral={handleOpenCancelDialog}
                              />
                            );
                          })
                        ) : (
                          <EmptyData user={user} />
                        )}
                      </Grid>
                    </TabPanel>
                    <TabPanel
                      sx={{
                        overflowY: 'auto',
                        maxHeight: `${
                          heightCards ? `${heightCards}px` : '50vh'
                        }`,
                        flex: 1,
                      }}
                      value="3"
                    >
                      <Grid container direction="column" gap={2}>
                        {referrals.length &&
                        referrals.find(
                          (d) =>
                            d.referredToId === user.id && d.status === null,
                        ) ? (
                          referrals.map((referralData, index) => {
                            if (
                              referralData.doctorId === user.id ||
                              referralData.status !== null
                            )
                              return <></>;
                            return (
                              <ReferralCard
                                referralData={referralData}
                                index={index}
                                user={user}
                                handleDrawer={handleDrawer}
                                handleCancelReferral={handleCancelReferral}
                              />
                            );
                          })
                        ) : (
                          <EmptyData user={user} />
                        )}
                      </Grid>
                    </TabPanel>
                  </TabContext>
                )}
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            // xs={4}
            ref={containerRef}
            id="info-container"
            sx={{
              height: `${height ? `${height}px` : '100%'}`,
              overflowY: 'auto',
              width: 'auto',
              px: 1,
              flex: 1,
              display: {
                md: 'block',
                sm: referrals.find((d) => d.status === null) && 'none',
                xs: referrals.find((d) => d.status === null) && 'none',
              },
            }}
          >
            <Card sx={{ p: 2 }}>
              <Grid container direction="column">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  sx={{ flexWrap: 'nowrap' }}
                >
                  <Avatar
                    sx={{
                      width: '120px',
                      height: '130px',
                      mr: 2.5,
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                    src={avatar}
                    variant="square"
                  />
                  <Grid>
                    <Box sx={{ ml: 2 }}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 600 }}
                        color={darkTextColor}
                      >
                        {CapitalizeFirstLetter(user.firstName)}{' '}
                        {CapitalizeFirstLetter(user.lastName)}
                        {user.prefix ? ', ' : ' '}
                        {user.prefix && DisplaySuffix(user.prefix.split(','))}
                      </Typography>

                      <Typography variant="body1" color={darkTextColor}>
                        {user.specialties &&
                          handleSpeciality(
                            DisplaySpecialties(user.specialties),
                            user.status,
                          )}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <LargeText text={user.about} />
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    backgroundColor:
                      notificationDataProfile || !user.profile_completed
                        ? 'rgba(246, 93, 94, 0.14)'
                        : '#F7F7F7',
                    borderRadius: '4px',
                    p: 1.5,
                    mt: 2,
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                  >
                    <Typography variant="body1" color={darkTextColor}>
                      {notificationDataProfile ||
                      !user.profile_completed ||
                      !user.doctor_verified ||
                      user.status === 'Resident' ||
                      user.status === 'Fellow'
                        ? 'Unavailable for new referrals'
                        : 'Available for new referrals'}
                    </Typography>
                    <Switch
                      onColor="#47BB92"
                      offColor="#F65D5E"
                      onChange={(e) => handleAvalibleForRefferals(e)}
                      disabled={
                        loading ||
                        notificationDataProfile ||
                        !user.email_verified ||
                        !user.profile_completed ||
                        user.status === 'Resident' ||
                        user.status === 'Fellow'
                      }
                      checked={user.isAvailable}
                    />
                  </Stack>
                  {user.status === 'Resident' || user.status === 'Fellow' ? (
                    <Typography
                      variant="body1"
                      sx={{ opacity: 1, fontWeight: '500' }}
                      color={darkTextColor}
                    >
                      {`${user?.status}s are unavailable to accept referrals`}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {!user.profile_completed ? (
                    <OverviewNotification
                      handleOnClick={() => navigate(`/profile/edit`)}
                      text={
                        'Please complete setup of your profile to change status and receive new patient referrals'
                      }
                    />
                  ) : (
                    <></>
                  )}
                  {notificationDataProfile ? (
                    <OverviewNotification
                      text={
                        'Identity is currently being verified. Please wait up to 24 hours.'
                      }
                    />
                  ) : (
                    <></>
                  )}
                </Grid>
                <Button
                  color="primary"
                  size="large"
                  className="custom-button"
                  onClick={() => {
                    const profileNumber = encryptNumber(user.id);
                    navigate(`/doctor/${profileNumber}`);
                  }}
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 40,
                    mt: 2,
                    bgcolor: '#FFFFFF',
                    border: '1px solid #47BB92',
                    color: '#47BB92',
                  }}
                >
                  View my profile
                </Button>
              </Grid>
            </Card>
            <Card sx={{ mt: 2, p: 2 }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 600 }}
                color={darkTextColor}
              >
                Invite colleague to medLynks
              </Typography>
              <CustomInputField
                error={Boolean(formik.touched.email && formik.errors.email)}
                onFocus={() => setInviteFocused(true)}
                inputProps={{
                  onBlur: () => {
                    setInviteFocused(false);
                  },
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    paddingRight: '110px',
                  },
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{ position: 'absolute', right: 5 }}
                  >
                    <Button
                      disabled={sendInviteLoading}
                      onClick={sendInvite}
                      sx={{
                        '&:disabled': {
                          color: 'grey !important',
                        },
                      }}
                    >
                      Send Invite
                    </Button>
                  </InputAdornment>
                }
                placeholder={'Type their work email address'}
                variant="standard"
                label="email"
                fullWidth
                width="100%"
                name="email"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                type={'text'}
                value={formik.values.email}
              ></CustomInputField>
              {Boolean(
                formik.touched.email && formik.errors.email && showError,
              ) && (
                <Typography
                  color="error"
                  gutterBottom
                  variant="body2"
                  sx={{
                    // position: 'absolute !important',
                    width: '50%',
                  }}
                >
                  {formik.touched.email && formik.errors.email}
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>
        <FooterVerifiedUser />
      </Box>
    </>
  );
};

export default Home;
