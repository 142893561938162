import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Autocomplete,
  Button,
  Card,
  Chip,
  Avatar,
  Stack,
  TextField,
  Popper,
  IconButton,
} from '@mui/material';
import CustomInputField from '../../../components/customInputField';
import TooltipIcon from '../../../Icons/tooltipIcon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomTextFieldNoBorders } from '../../../components/customTextField';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  darkTextColor,
  DoctorStatus,
  GetImageFromUrl,
  getRangeOfYears,
} from '../../../constants';
import { MedicalSchoolList } from '../../../constants/medicalSchool';
import { useEffect } from 'react';
import { CharacterLeftTypography } from '../../../components/characterLeftTypography';
import { DegreeSelectionNew } from '../../../components/degreeSelection';
import { CircleButton } from '../../EditProfile/circleButton';
import { DndProvider } from 'react-dnd';
import { DragableListContainer } from '../../../components/dragableList';
import CloseLight from '../../../Icons/closeLight';

const AboutYou = (props) => {
  const {
    formik,
    handleAddFellowShips,
    fellowShips,
    currentData,
    handleUpdateAboutData,
    handleRemoveFellowShip,
    selectedImage,
    setSelectedImage,
    setImageErr,
    imageError,
    setFellowShips,
    isDrawerOpen,
  } = props;
  const [degree, setDegree] = useState(formik.values?.degree || []);
  const [degreeCards, setDegreeCards] = useState(
    formik.values?.degree?.map((value, index) => {
      return { id: index, value: value };
    }),
  );
  const [displayPicture, setDisplayPicture] = useState();
  const yearListFromNow = getRangeOfYears(1950).reverse();

  const yearListFrom5Year = getRangeOfYears(
    1950,
    new Date().getFullYear() + 5,
  ).reverse();

  const avatar = GetImageFromUrl(selectedImage);

  useEffect(() => {
    if (selectedImage) {
      if (typeof selectedImage === 'string') {
        setDisplayPicture(GetImageFromUrl(selectedImage));
      } else {
        var file = selectedImage;
        var reader = new FileReader();
        var url = reader.readAsDataURL(file);
        reader.onloadend = function (e) {
          setDisplayPicture(reader.result);
        };
      }
    }
  }, [selectedImage]);
  const hideFiled =
    formik.values.status === 'Nurse Practitioner' ||
    formik.values.status === 'Physician Assistant';

  const hideFiledFellow =
    formik.values.status === 'Nurse Practitioner' ||
    formik.values.status === 'Physician Assistant' ||
    formik.values.status === 'Resident';

  useEffect(() => {
    if (
      formik.values.status === 'Nurse Practitioner' ||
      formik.values.status === 'Physician Assistant'
    ) {
      formik.setFieldValue('residency', '');
      formik.setFieldValue('residencyYear', 'Year completed');
      handleUpdateAboutData('fellowship', '');
      handleUpdateAboutData('year', '');
      handleRemoveFellowShip();
      setFellowShips([]);
    }
    if (formik.values.status === 'Resident') {
      handleUpdateAboutData('fellowship', '');
      handleUpdateAboutData('year', '');
      setFellowShips([]);
    }
  }, [formik.values.status]);

  const _onListChange = (newList) => {
    formik.setFieldValue('degree', newList);
  };

  const removeDegree = (value) => {
    const updatedDegrees = formik.values?.degree.filter((deg) => deg !== value);
    formik.setFieldValue('degree', updatedDegrees);
  };

  const handleAddDegree = (value, add = 'default') => {
    if (
      value?.degree ||
      degree?.degree ||
      !formik.values.degree?.includes(value?.degree) ||
      !formik.values.degree?.includes(degree?.degree)
    ) {
      let updatedArray = [...formik.values.degree];

      if (add === 'default') {
        updatedArray = [...formik.values.degree, degree];
      } else {
        updatedArray = [...formik.values.degree, value.degree];
      }
      setDegree('');
      formik.setFieldValue('degree', updatedArray);
    }
  };

  useEffect(() => {
    _onListChange(degreeCards?.map((card) => card.value) || []);
  }, [degreeCards]);

  useEffect(() => {
    if (formik.values.degree?.length !== degreeCards?.length) {
      setDegreeCards(
        formik.values?.degree?.map((value, index) => {
          return { id: index, value: value };
        }) || [],
      );
    }
  }, [formik.values.degree]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        direction="column"
        alignItems="start"
        sx={{
          alignSelf: 'start',
          ml: isDrawerOpen ? 40 : 0,
          // overflowY: 'auto',
          px: 1,
          width: '80vw',
          maxWidth: 950,
        }}
      >
        {/* <Typography color={darkTextColor} gutterBottom variant="h5">
          About you
        </Typography> */}
        <form
          onSubmit={formik.handleSubmit}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <Stack direction="column">
            <Typography
              color={darkTextColor}
              variant="h5"
              fontSize="18px"
              sx={{ fontWeight: 600 }}
            >
              About You
            </Typography>
            <Typography color={darkTextColor} fontSize="14px" sx={{ my: 1 }}>
              Add information about yourself to showcase your expertise and
              collaborate more effectively with other healthcare providers.
            </Typography>
          </Stack>
          <Grid
            maxWidth={true}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{
              bgcolor: '#FFFFFF',
              border: '1px solid #E2E2E2',
              borderRadius: '4px',
            }}
            gap={0}
          >
            <Grid
              maxWidth={true}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              gap={2}
              sx={{ py: 2, width: '100%' }}
            >
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderBottom: '1px solid #ced4da',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    fontWeight="600"
                    sx={{ minWidth: '20%' }}
                  >
                    Profile picture
                  </Typography>
                  <Grid
                    gap={2}
                    container
                    direction="row"
                    sx={{ flexWrap: 'nowrap' }}
                    alignItems="center"
                  >
                    <>
                      {selectedImage ? (
                        <>
                          {typeof selectedImage === 'string' ? (
                            <>
                              {selectedImage &&
                              selectedImage?.includes('http') ? (
                                <Avatar
                                  sx={{
                                    width: '135px',
                                    height: '100%',
                                    mr: 2.5,
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                  src={selectedImage}
                                  variant="square"
                                />
                              ) : (
                                <Avatar
                                  sx={{
                                    width: '135px',
                                    height: '100%',
                                    mr: 2.5,
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                  src={avatar}
                                  variant="square"
                                />
                              )}
                            </>
                          ) : (
                            <Avatar
                              sx={{
                                width: '135px',
                                height: '100%',
                                mr: 2.5,
                                justifyContent: 'center',
                                display: 'flex',
                              }}
                              src={displayPicture}
                              variant="square"
                            />
                          )}
                        </>
                      ) : (
                        <Avatar
                          sx={{
                            width: '135px',
                            height: '100%',
                            mr: 2.5,
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                          variant="square"
                        />
                      )}
                    </>
                    <Grid container direction="column">
                      <Button
                        color="primary"
                        variant="contained"
                        component="label"
                        sx={{ width: '8rem', height: 40, mb: 2 }}
                      >
                        Upload
                        <input
                          hidden
                          accept="image/*"
                          onChange={(event) => {
                            if (event.target.files) {
                              const name = event.target.files[0].name;
                              const fileExtension = name.split('.').pop();
                              if (
                                fileExtension === 'png' ||
                                fileExtension === 'jpeg' ||
                                fileExtension === 'svg' ||
                                fileExtension === 'jpg'
                              ) {
                                var img = new Image();
                                img.src = URL.createObjectURL(
                                  event.target.files[0],
                                );
                                img.onload = () => {
                                  if (img.width >= 200 && img.height >= 100) {
                                    setImageErr();
                                    setSelectedImage(event.target.files[0]);
                                  } else {
                                    setImageErr(
                                      `Image dimensions should be 200x100px. Image wasn’t uploaded because it is ${img.width}x${img.height}px.`,
                                    );
                                    console.log(
                                      'image dimensions are to small',
                                    );
                                  }
                                };
                              } else {
                                setImageErr(
                                  `Files extension dont match. Please upload in the following formats PNG or JPEG`,
                                );
                              }
                            }
                          }}
                          type="file"
                        />
                      </Button>
                      <Stack direction="column" sx={{ flexWrap: 'wrap' }}>
                        <Grid
                          gap={1}
                          container
                          direction="row"
                          alignItems="flex-start"
                        >
                          <TooltipIcon />
                          <Typography
                            color={darkTextColor}
                            sx={{ opacity: 0.75 }}
                            gutterBottom
                            variant="body2"
                          >
                            PNG or JPEG (min. 200x100px)
                          </Typography>
                        </Grid>
                        {imageError ? (
                          imageError.split('.').map((errMess) => (
                            <Typography
                              color="error"
                              sx={{ opacity: 0.75, m: 0 }}
                              gutterBottom
                              variant="body2"
                            >
                              {errMess}
                            </Typography>
                          ))
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderBottom: '1px solid #ced4da',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Stack direction="column" sx={{ minWidth: '20%' }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                    >
                      About
                    </Typography>
                    <Typography
                      color={darkTextColor}
                      sx={{ opacity: 0.5 }}
                      variant="body2"
                    >
                      Optional
                    </Typography>
                  </Stack>
                  <Grid item sx={{ width: '100%' }}>
                    <CharacterLeftTypography
                      touched={formik.touched.about}
                      errors={formik.errors.about}
                      value={formik.values.about}
                      handleBlur={formik.handleBlur}
                      handleChange={formik.handleChange}
                      maxLength={500}
                      rows={4}
                      label="About"
                      name="about"
                    />
                    {Boolean(formik.touched.about && formik.errors.about) && (
                      <Typography
                        color="error"
                        gutterBottom
                        variant="body2"
                        sx={{ position: 'relative !important' }}
                      >
                        {formik.touched.about && formik.errors.about}
                      </Typography>
                    )}
                    <Typography
                      color={darkTextColor}
                      variant="body2"
                      fontSize="14px"
                      sx={{ my: 2 }}
                    >
                      Describe your education, training background, and scope of
                      practice.
                    </Typography>
                  </Grid>
                </Stack>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderBottom: '1px solid #ced4da',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    fontWeight="600"
                    sx={{ minWidth: '20%' }}
                  >
                    Status
                  </Typography>
                  <Grid
                    container
                    gap={1}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%', mb: 1 }}
                  >
                    <Stack direction="row" flexWrap="wrap" gap={1}>
                      {DoctorStatus.map((text) => (
                        <Chip
                          variant="outlined"
                          color={
                            formik.values.status === text ? 'primary' : 'info'
                          }
                          sx={{ width: 'max-content', px: 2 }}
                          label={text}
                          onClick={() => formik.setFieldValue('status', text)}
                        />
                      ))}

                      {Boolean(
                        formik.touched.status && formik.errors.status,
                      ) ? (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{ position: 'relative !important' }}
                        >
                          {formik.errors.status}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Grid>
                </Stack>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderBottom: '1px solid #ced4da',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    fontWeight="600"
                    sx={{ minWidth: '20%' }}
                  >
                    Degree
                  </Typography>
                  <Grid
                    container
                    gap={1}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%', mb: 1 }}
                  >
                    <Stack
                      direction="column"
                      flexWrap="wrap"
                      gap={1}
                      sx={{ width: '100%' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          border: '1px solid #E2E2E2',
                          borderRadius: '4px',
                        }}
                      >
                        <DegreeSelectionNew
                          degree={degree}
                          setDegree={setDegree}
                          status={formik.values.status}
                          degrees={formik.values.degree}
                          handleAddDegree={handleAddDegree}
                        />
                        <CircleButton
                          onClick={() => {
                            if (degree) {
                              handleAddDegree();
                            }
                          }}
                        />
                      </Stack>

                      {Boolean(
                        formik.touched.degree && formik.errors.degree,
                      ) ? (
                        <Typography
                          color="error"
                          gutterBottom
                          variant="body2"
                          sx={{ position: 'relative !important' }}
                        >
                          {formik.errors.degree}
                        </Typography>
                      ) : (
                        <></>
                      )}
                      <Typography
                        color={darkTextColor}
                        variant="body2"
                        fontSize="14px"
                        sx={{ my: 2 }}
                      >
                        Add degrees in the order you would like them displayed
                        (if multiple).
                      </Typography>
                      {formik?.values?.degree?.length && (
                        <DndProvider backend={HTML5Backend}>
                          <DragableListContainer
                            cards={degreeCards}
                            setCards={setDegreeCards}
                            removeDegree={removeDegree}
                          />
                        </DndProvider>
                      )}
                    </Stack>
                  </Grid>
                </Stack>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderBottom: '1px solid #ced4da',
                  borderRadius: 0,
                  boxShadow: 'none',
                  overflow: 'initial',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    fontWeight="600"
                    sx={{ minWidth: '20%' }}
                  >
                    {!hideFiled ? 'Medical School' : 'Graduate School'}
                  </Typography>
                  <Grid
                    container
                    gap={1}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%', mb: 1 }}
                  >
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        sx={{
                          border: '1px solid #E2E2E2',
                          p: 0,
                          m: 0,
                          width: '100%',
                        }}
                      >
                        <Autocomplete
                          disablePortal
                          freeSolo
                          id="medicalSchool"
                          name="medicalSchool"
                          ListboxProps={{ style: { maxHeight: '20vh' } }}
                          PopperComponent={({ style, ...props }) => (
                            <Popper
                              {...props}
                              style={{ ...style, height: 0 }}
                            />
                          )}
                          // openOnFocus
                          fullWidth
                          disableClearable
                          // forcePopupIcon={false}
                          options={MedicalSchoolList}
                          value={formik.values.medicalSchool}
                          onChange={(e, value) => {
                            formik.setFieldValue('medicalSchool', value);
                          }}
                          sx={{ width: '100%', color: 'black', p: 0 }}
                          renderInput={(params) => (
                            <CustomTextFieldNoBorders
                              {...params}
                              placeholder="Search for a medical school"
                              fullWidth
                              onInput={(e) => {
                                e.target.value = e.target.value.slice(0, 75);
                              }}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  'medicalSchool',
                                  e.target.value,
                                );
                              }}
                              sx={{ border: '0px', width: '100%', p: 0 }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Autocomplete
                                    // disablePortal
                                    // openOnFocus
                                    disableClearable
                                    disablePortal
                                    id="year-select"
                                    ListboxProps={{
                                      style: { maxHeight: '20vh' },
                                    }}
                                    PopperComponent={({ style, ...props }) => (
                                      <Popper
                                        {...props}
                                        style={{ ...style, height: 0 }}
                                      />
                                    )}
                                    sx={{
                                      border: '0px',
                                      pointerEvents: 'all',
                                      cursor: 'pointer',
                                      boxShadow: '0px 0px',
                                      padding: 0,
                                      margin: 0,
                                    }}
                                    onChange={(e, value) => {
                                      formik.setFieldValue(
                                        'medicalSchoolYear',
                                        value,
                                      );
                                    }}
                                    onFocus={(event) => {
                                      event.stopPropagation();
                                    }}
                                    value={formik.values.medicalSchoolYear}
                                    options={yearListFromNow}
                                    renderInput={(params) => (
                                      <TextField
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                        {...params}
                                        placeholder="Year completed"
                                        sx={{
                                          width: '100%',
                                          minWidth: '90px',
                                          padding: 0,
                                          color: 'black !important',
                                          margin: 0,
                                          backgroundColor: '#FFFFFF',
                                          boxShadow: '0px 0px',
                                          border: '0px !important',
                                          '& .MuiOutlinedInput-root': {
                                            padding: '0px',
                                            color: 'black !important',
                                            border: '0px !important',
                                            backgroundColor: '#FFFFFF',
                                            boxShadow: '0px 0px',
                                            marginTop: '0px',
                                          },
                                          '& .MuiInputBase-input': {
                                            width: '130px !important',
                                            textAlign: 'end',
                                          },
                                          '& .MuiOutlinedInput-notchedOutline':
                                            {
                                              borderColor: '#fcfcfb !important',
                                            },
                                        }}
                                      />
                                    )}
                                  />
                                ),
                              }}
                            />
                          )}
                        />
                      </Stack>

                      <Stack direction="column">
                        {Boolean(
                          formik.touched.medicalSchool &&
                            formik.errors.medicalSchool,
                        ) ||
                        Boolean(
                          formik.touched.medicalSchoolYear &&
                            formik.errors.medicalSchoolYear,
                        ) ? (
                          <Typography
                            color="error"
                            gutterBottom
                            variant="body2"
                            sx={{ position: 'relative !important' }}
                          >
                            {formik.errors.medicalSchool
                              ? formik.errors.medicalSchool
                              : formik.errors.medicalSchoolYear}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        <Typography
                          color={darkTextColor}
                          variant="body2"
                          fontSize="14px"
                          sx={{ my: 2 }}
                        >
                          If your medical school is not listed, please type it
                          in.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Stack>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderBottom: '1px solid #ced4da',
                  borderRadius: 0,
                  boxShadow: 'none',
                  overflow: 'initial',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    fontWeight="600"
                    sx={{ minWidth: '20%' }}
                  >
                    Residency
                  </Typography>
                  <Grid
                    container
                    gap={1}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%', mb: 1 }}
                  >
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        sx={{
                          border: '1px solid #E2E2E2',
                          p: 0,
                          m: 0,
                          width: '100%',
                        }}
                      >
                        <Autocomplete
                          disablePortal
                          freeSolo
                          ListboxProps={{ style: { maxHeight: '20vh' } }}
                          PopperComponent={({ style, ...props }) => (
                            <Popper
                              {...props}
                              style={{ ...style, height: 0 }}
                            />
                          )}
                          disableClearable
                          options={[]}
                          label="Residency"
                          width="100%"
                          sx={{ width: '100%', color: 'black', p: 0 }}
                          name="residency"
                          value={formik.values.residency}
                          onInput={(e) => {
                            if (!hideFiled) {
                              e.target.value = e.target.value.slice(0, 75);
                            }
                          }}
                          onChange={(e, value) => {
                            if (!hideFiled) {
                              formik.setFieldValue('residency', value);
                            }
                          }}
                          disabled={hideFiled}
                          renderInput={(params) => (
                            <CustomTextFieldNoBorders
                              {...params}
                              placeholder="Type here"
                              fullWidth
                              value={formik.values.residency}
                              onInput={(e) => {
                                if (!hideFiled) {
                                  e.target.value = e.target.value.slice(0, 75);
                                }
                              }}
                              onChange={(e) => {
                                if (!hideFiled) {
                                  formik.setFieldValue(
                                    'residency',
                                    e.target.value,
                                  );
                                }
                              }}
                              disabled={hideFiled}
                              sx={{ border: '0px' }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Autocomplete
                                    disablePortal
                                    openOnFocus
                                    disableClearable
                                    id="year-select"
                                    ListboxProps={{
                                      style: {
                                        maxHeight: '20vh',
                                      },
                                    }}
                                    sx={{
                                      border: '0px',
                                      pointerEvents: 'all',
                                      cursor: 'pointer',
                                      boxShadow: '0px 0px',
                                      padding: 0,
                                      margin: 0,
                                    }}
                                    disabled={hideFiled}
                                    onChange={(e, value) => {
                                      if (!hideFiled) {
                                        formik.setFieldValue(
                                          'residencyYear',
                                          value,
                                        );
                                      }
                                    }}
                                    value={
                                      !hideFiled
                                        ? formik.values.residencyYear
                                        : 'Year completed'
                                    }
                                    options={
                                      formik.values.status === 'Resident'
                                        ? yearListFrom5Year
                                        : yearListFromNow
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Year completed"
                                        onClick={(event) => {
                                          event.stopPropagation();
                                        }}
                                        onFocus={(event) => {
                                          event.stopPropagation();
                                        }}
                                        value={
                                          !hideFiled
                                            ? formik.values.residencyYear
                                            : 'Year completed'
                                        }
                                        sx={{
                                          minWidth: '150px',
                                          padding: 0,
                                          color: 'black !important',
                                          margin: 0,
                                          backgroundColor: '#FFFFFF',
                                          boxShadow: '0px 0px',
                                          border: '0px !important',
                                          '& .MuiOutlinedInput-root': {
                                            padding: '0px',
                                            color: 'black !important',
                                            border: '0px !important',
                                            backgroundColor: '#FFFFFF',
                                            boxShadow: '0px 0px',
                                            marginTop: '0px',
                                          },
                                          '& .MuiInputBase-input': {
                                            width: '140px !important',
                                            textAlign: 'end',
                                          },
                                          '& .MuiOutlinedInput-notchedOutline':
                                            {
                                              borderColor: '#fcfcfb !important',
                                            },
                                        }}
                                      />
                                    )}
                                  />
                                ),
                              }}
                            />
                          )}
                        />
                      </Stack>
                      <Stack direction="column">
                        {Boolean(
                          formik.touched.residency && formik.errors.residency,
                        ) ||
                        Boolean(
                          formik.touched.residencyYear &&
                            formik.errors.residencyYear,
                        ) ? (
                          <Typography
                            color="error"
                            gutterBottom
                            variant="body2"
                            sx={{ position: 'relative !important' }}
                          >
                            {formik.errors.residency
                              ? formik.errors.residency
                              : formik.errors.residencyYear}
                          </Typography>
                        ) : (
                          <></>
                        )}
                        <Typography
                          color={darkTextColor}
                          variant="body2"
                          fontSize="14px"
                          sx={{ my: 2 }}
                        >
                          Please type in the name of your residency program.
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </Stack>
              </Card>
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  pb: 0,
                  bgcolor: '#FFFFFF',
                  borderRadius: 0,
                  boxShadow: 'none',
                  overflow: 'initial',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Stack direction="column" sx={{ minWidth: '20%' }}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      fontWeight="600"
                    >
                      Fellowships
                    </Typography>
                    <Typography
                      color={darkTextColor}
                      sx={{ opacity: 0.5 }}
                      variant="body2"
                    >
                      Optional
                    </Typography>
                  </Stack>
                  <Grid
                    container
                    gap={1}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%', mb: 1 }}
                  >
                    <Stack
                      sx={{ width: '100%' }}
                      direction="row"
                      flexWrap="wrap"
                      gap={1}
                    >
                      <Stack
                        direction="row"
                        sx={{
                          border: '1px solid #E2E2E2',
                          p: 0,
                          m: 0,
                          width: '100%',
                        }}
                      >
                        <CustomInputField
                          label="Fellowships"
                          fullWidth
                          width="100%"
                          onBlur={formik.handleBlur}
                          inputProps={{ maxLength: 60 }}
                          onChange={(event) => {
                            if (
                              !hideFiled &&
                              formik.values.status !== 'Resident'
                            ) {
                              handleUpdateAboutData(
                                'fellowship',
                                event.target.value,
                              );
                            }
                          }}
                          onInput={(e) => {
                            if (
                              !hideFiled &&
                              formik.values.status !== 'Resident'
                            ) {
                              e.target.value = e.target.value.slice(0, 75);
                            }
                          }}
                          sx={{
                            '& .MuiInputBase-input': {
                              border: '0px !important',
                              paddingRight: '205px',
                              marginTop: '0px',
                            },
                          }}
                          placeholder="Type here"
                          name="fellowship"
                          value={currentData.fellowship}
                          disabled={
                            hideFiled
                              ? true
                              : formik.values.status === 'Resident'
                              ? true
                              : false
                          }
                          endAdornment={
                            <Autocomplete
                              disablePortal
                              openOnFocus
                              disableClearable
                              key={fellowShips.length}
                              onKeyPress={(e) => {
                                e.preventDefault();
                              }}
                              id="year-select"
                              ListboxProps={{
                                style: {
                                  maxHeight: '20vh',
                                  postion: 'absolute',
                                },
                              }}
                              sx={{
                                border: '0px',
                                pointerEvents: 'all',
                                cursor: 'pointer',
                                boxShadow: '0px 0px',
                                padding: 0,
                                margin: 0,
                                maxHeight: '5vh',

                                position: 'absolute',
                                right: '10px',
                                top: '50%',
                                transform: 'translate(-0%,-45%)',
                              }}
                              disabled={
                                hideFiled
                                  ? true
                                  : formik.values.status === 'Resident'
                                  ? true
                                  : false
                              }
                              value={
                                !hideFiled ? currentData.year : 'Year completed'
                              }
                              onChange={(e, value) => {
                                if (
                                  !hideFiled &&
                                  formik.values.status !== 'Resident'
                                ) {
                                  handleUpdateAboutData('year', value);
                                }
                              }}
                              options={yearListFrom5Year}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  // onChange={(e) => {
                                  //   handleUpdateAboutData(
                                  //     'year',
                                  //     e.target.value,
                                  //   );
                                  // }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  onFocus={(event) => {
                                    event.stopPropagation();
                                  }}
                                  placeholder="Year completed"
                                  sx={{
                                    width: '100%',
                                    minWidth: '90px',
                                    color: 'black !important',
                                    margin: 0,
                                    backgroundColor: '#FFFFFF',
                                    boxShadow: '0px 0px',
                                    border: '0px !important',
                                    '& .MuiOutlinedInput-root': {
                                      padding: '0% 1%',
                                      color: 'black !important',
                                      border: '0px !important',
                                      backgroundColor: '#FFFFFF',
                                      boxShadow: '0px 0px',
                                      marginTop: '0px',
                                    },
                                    '& .MuiInputBase-input': {
                                      padding: '0px !important',
                                      color: 'black !important',
                                      border: '0px !important',
                                      backgroundColor: '#FFFFFF',
                                      boxShadow: '0px 0px',
                                      marginTop: '0px',
                                      width: '140px !important',
                                      textAlign: 'end',
                                    },

                                    '& .MuiOutlinedInput-notchedOutline': {
                                      borderColor: '#fcfcfb !important',
                                    },
                                  }}
                                />
                              )}
                            />
                          }
                        />
                        <CircleButton
                          onClick={() => handleAddFellowShips()}
                          disabled={fellowShips?.length > 4 || hideFiledFellow}
                        />
                      </Stack>
                      {fellowShips &&
                        fellowShips?.map((item) => (
                          <Card
                            justifyContent="space-between"
                            sx={{
                              width: '100%',
                              py: 1,
                              px: 3,
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              boxShadow: 0,
                              border: '1px solid #E2E2E2',
                            }}
                          >
                            <Typography color={darkTextColor} variant="body1">
                              {item.fellowship}
                            </Typography>
                            <Grid item>
                              <Grid
                                container
                                direction="row"
                                gap={4}
                                alignItems="center"
                              >
                                <Typography
                                  color={darkTextColor}
                                  sx={{ p: 0, m: 0 }}
                                  variant="body1"
                                >
                                  {new Date().getFullYear() < item.year
                                    ? `${item.year} (expected)`
                                    : item.year}
                                </Typography>
                                <IconButton
                                  disableRipple
                                  onClick={() =>
                                    handleRemoveFellowShip(item.fellowship)
                                  }
                                >
                                  <CloseLight />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Card>
                        ))}
                    </Stack>
                  </Grid>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </LocalizationProvider>
  );
};

export default AboutYou;
