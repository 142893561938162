import React, { useEffect, useState } from 'react';
import Users from '../../../services/users';

import {
  Grid,
  Link,
  Button,
  DialogContent,
  Typography,
  DialogActions,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RESPONSE_STATUS, EducationDataType } from '../../../constants/index';
import { useNavigate } from 'react-router-dom';
import AboutYou from './aboutYou';
import YourPractice from './yourPractice';
import StaffMembers from './staffMembers';
import Staffs from '../../../services/staff';
import AlertDialog from '../../../components/alertDialog';
import WarningIcon from '../../../Icons/warnings';
import { HospitalSystems } from '../../../constants/hospital_systems';
import { LoadingButton } from '@mui/lab';
import WarningDialog from '../../../components/warningDialog';

const textColor = '#212634';
const drawerWidth = 350;

const cleanString = (str) => {
  if (str) {
    return str.replace(/\\/g, '').replace('<p>', '').replace('</p>', '');
  }
  return null;
};

const initAbout = (detailed, bio) => {
  if (detailed) {
    return cleanString(detailed);
  }
  if (bio) {
    return cleanString(bio);
  }
  return '';
};

const initEducation = (type, data) => {
  if (type === EducationDataType?.MedicalScool) {
    const temp = data?.find((edu) => edu?.title?.includes('Medical'));
    if (temp) {
      return temp.school || '';
    }
  }
  if (data) {
    const temp = data.find((edu) => edu.title === type);
    if (temp) {
      return temp.school || '';
    }
  }
  return '';
};

// const initEducationLowercase = (type, data) => {
//   if (data) {
//     const temp = data.find(
//       (edu) => edu.title === type || edu.title === type.toLowerCase(),
//     );
//     if (temp) {
//       return temp.school || '';
//     }
//   }
//   return '';
// };

const getEmployer = (doctorLocation) => {
  return HospitalSystems.find((hos) => hos.abbreviation === doctorLocation);
};

const getInsurances = (insurances) => {
  if (insurances?.length) {
    let insuracesCarriers = [];
    insurances?.forEach((insurance) => {
      if (
        !insuracesCarriers.includes(insurance.carrier) &&
        insuracesCarriers?.length < 20
      ) {
        insuracesCarriers.push(insurance.carrier?.trim());
      }
    });
    insuracesCarriers = insuracesCarriers.filter((insu) => insu);
    return insuracesCarriers;
  }
  return [];
};

const SetUpAccount = (props) => {
  const { page, setPages, user, token, setUser, doctorInfoData, isDrawerOpen } =
    props;
  const doctorDetails = doctorInfoData?.doctorDetail[0];
  const doctorEducation = doctorInfoData?.doctorEducation;
  const doctorLocation = doctorInfoData?.doctorLocation;
  const doctorInsurance = doctorInfoData?.doctorInsurance;

  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);

  const [warningDialog, setWarningDialog] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const [fellowShips, setFellowShips] = useState([]);
  const [aboutData, setAboutData] = useState({
    fellowship: initEducation(EducationDataType.Fellowship, doctorEducation),
    year: '',
    residencyYear: '',
    medicalSchoolYear: [],
    degree: doctorDetails?.suffix
      ? [...doctorDetails?.suffix?.replaceAll('.', '').split(',')]
      : [],
  });
  const [profilePicture, setProfilePicture] = useState(
    `images/${doctorDetails?.health_center}/${doctorDetails?.image_path}.jpg` ||
      '',
  );
  const [staffMembers, setStaffMembers] = useState([]);

  const [errMessage, setErrMessage] = useState('');
  const employer = getEmployer(doctorInfoData?.health_center);
  const [practiceData, setPracticeData] = useState({
    healthCareEmployer: employer ? employer?.name : '',
    addresses: [],
    insurance: getInsurances(doctorInsurance),
    hospitalLocation: [],
  });
  const [loading, setLoading] = useState();

  const [imageError, setImageErr] = useState();

  const handleAddStaffMember = (member) => {
    const getTemp = staffMembers.find((x) => x.email === member.email);
    if (getTemp?.email) {
      setErrorDialog(true);
      setErrMessage('Staff member already added');
      return;
    }
    if (!getTemp?.email) {
      setStaffMembers((state) => [...state, member]);
    }
  };

  const handleRemoveStaffMember = (member) => {
    const tempMembers = staffMembers.filter((item) => item.email !== member);
    setStaffMembers(tempMembers);
  };

  const handleUpdateAboutData = (type, value) => {
    setAboutData((state) => ({
      ...state,
      [`${type}`]: value,
    }));
  };

  const handleAddDegree = (value) => {
    setAboutData((state) => ({
      ...state,
      degree: value,
    }));
    formikAboutYou.setFieldValue('degree', value);
  };

  const handleUpdatePracticeData = (type, value, error = false) => {
    if (type === 'addresses') {
      if (error) {
        setErrorDialog(true);
        setErrMessage(value);
        return;
      }

      const findSimilarAdd = practiceData.addresses.filter(
        (add) => add.full_address === value.full_address,
      );
      if (findSimilarAdd.length) {
        setErrorDialog(true);
        setErrMessage('Address already added');
        return;
      }
      const tempAdd = practiceData.addresses;
      tempAdd.push(value);

      setPracticeData((state) => ({
        ...state,
        [`${type}`]: tempAdd,
      }));
      return;
    }
    setPracticeData((state) => ({
      ...state,
      [`${type}`]: value,
    }));
  };

  const handleAddInsurance = (insurance) => {
    const tempInsurance = practiceData?.insurance;
    if (!insurance) {
      setErrorDialog(true);
      setErrMessage('Please enter an insurance');
      return;
    }
    const findSimilarAdd = practiceData?.insurance.filter(
      (add) => add?.toLowerCase() === insurance?.toLowerCase(),
    );
    if (findSimilarAdd.length) {
      setErrorDialog(true);
      setErrMessage('Insurance already added');
      return;
    }
    tempInsurance.push(insurance);
    setPracticeData((state) => ({
      ...state,
      insurance: tempInsurance,
    }));
  };

  const handleRemoveInsurance = (insurance) => {
    const tempInsurance = practiceData?.insurance.filter(
      (item) => item !== insurance,
    );

    setPracticeData((state) => ({
      ...state,
      insurance: tempInsurance,
    }));
  };

  const handleRemoveAddress = (formatted_address) => {
    const tempAdd = practiceData.addresses.filter(
      (item) => item.formatted_address !== formatted_address,
    );
    setPracticeData((state) => ({
      ...state,
      addresses: tempAdd,
    }));
  };

  const handleSetPrimaryAddress = (formatted_address) => {
    const tempAdd = practiceData.addresses.map((add) => {
      if (add.formatted_address === formatted_address) {
        add.isPrimary = true;
        return add;
      }
      add.isPrimary = false;
      return add;
    });
    setPracticeData((state) => ({
      ...state,
      addresses: tempAdd,
    }));
  };

  const handleAddFellowShip = () => {
    if (!aboutData.fellowship || !aboutData.year) {
      setErrorDialog(true);
      setErrMessage(
        'Please enter in the fellowship name and the (expected) year of completion.',
      );
      return;
    }
    if (aboutData.fellowship?.length > 256) {
      setErrMessage('Fellowship name should be below 256 character');
    }
    const isDuplicate = fellowShips.find(
      (f) => f.fellowship === aboutData.fellowship && f.year === aboutData.year,
    );
    if (isDuplicate) {
      setErrorDialog(true);
      setErrMessage('Please enter different entry');
    }
    if (!isDuplicate) {
      setFellowShips((state) => [
        ...state,
        { fellowship: aboutData.fellowship, year: aboutData.year },
      ]);
      setAboutData((state) => ({
        ...state,
        fellowship: '',
      }));
    }
  };

  const handleRemoveFellowShip = (name) => {
    setFellowShips(fellowShips.filter((item) => item.fellowship !== name));
  };

  const formikAboutYou = useFormik({
    initialValues: {
      about: doctorDetails
        ? initAbout(doctorDetails?.detailed_bio, doctorDetails?.bio)
        : '',
      status: undefined,
      medicalSchool: initEducation(
        EducationDataType.MedicalScool,
        doctorEducation,
      ),
      medicalSchoolYear: undefined,
      residencyYear: undefined,
      residency: initEducation(EducationDataType.Residency, doctorEducation),
      degree: doctorDetails?.suffix
        ? [...doctorDetails?.suffix?.replaceAll('.', '')?.split(',')]
        : [],
    },
    validationSchema: Yup.object({
      about: Yup.string().max(500),
      status: Yup.string().required('Doctor status is required'),
      medicalSchool: Yup.string().required('Medical school is required'),
      residency: Yup.string().when('status', {
        is: (status) =>
          status === 'Resident' ||
          status === 'Fellow' ||
          status === 'Attending Physician',
        then: Yup.string().required('Residency is required'),
        otherwise: Yup.string().nullable(true),
      }),
      medicalSchoolYear: Yup.string().required('Year is required'),
      residencyYear: Yup.string().when('status', {
        is: (status) =>
          status === 'Resident' ||
          status === 'Fellow' ||
          status === 'Attending Physician',
        then: Yup.string().required('Year is required'),
        otherwise: Yup.string().nullable(true),
      }),
      degree: Yup.array()
        .min(1, 'Degree field must have at least one item')
        .required('Degree is required'),
    }),
    onSubmit: async (values) => {
      const {
        about,
        residency,
        medicalSchool,
        medicalSchoolYear,
        residencyYear,
        status,
        degree,
      } = values;

      if (!profilePicture) {
        setImageErr('Photo is a required field');
        return;
      }

      let updatedFellowShip = [];
      if (fellowShips.length) {
        updatedFellowShip = fellowShips.map(({ fellowship, year }) => ({
          fellowship,
          year: year.replace('(expected)', '').trim(),
        }));
      }
      // return;

      try {
        setLoading(true);

        const response = await Users.put(
          token,
          user.id,
          about,
          residency,
          medicalSchool,
          residencyYear.replace('(expected)', '').trim(),
          medicalSchoolYear,
          updatedFellowShip,
          degree.toString(),
          profilePicture,
          typeof profilePicture === 'string',
          status,
        );

        if (response.status === RESPONSE_STATUS.success) {
          setUser(response.body);
          setPages(page + 1);
        }
      } catch (e) {
        setErrMessage(e?.message);
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
  });

  const formikPracticeData = useFormik({
    initialValues: {
      officeNumber: user?.officeNumber || '',
      availabilityTime: user?.availabilityTime || '',
    },
    validationSchema: Yup.object({
      availabilityTime: Yup.string(),
      officeNumber: Yup.string()
        .length(14, 'Must be exactly 10 digits')
        .nullable(),
    }),
    onSubmit: async (values) => {
      const {
        availabilityTime,
        officeNumber,
        // degree,
      } = values;

      if (!practiceData.addresses.length) {
        setErrorDialog(true);
        setErrMessage('Please add an address');
        return;
      }
      const isPrimarySelected = practiceData.addresses.find(
        (add) => add.isPrimary,
      );

      if (isPrimarySelected) {
        try {
          setLoading(true);
          const response = await Users.putPractice(
            token,
            user.id,
            practiceData.healthCareEmployer,
            practiceData.addresses,
            practiceData.insurance,
            practiceData.hospitalLocation,
            availabilityTime,
            officeNumber,
          );
          if (response.status === RESPONSE_STATUS.success) {
            setUser(response.body);
            setPages(page + 1);
            return;
          }
        } catch (e) {
          setErrMessage(e?.message);
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
      if (!isPrimarySelected) {
        setErrMessage('No primary address selected');
        setErrorDialog(true);
        return;
      }
    },
  });

  const handleNext = async () => {
    if (page === 0) {
      if (!profilePicture) {
        setImageErr('Photo is a required field');
      }
      formikAboutYou.handleSubmit();
    }
    if (page === 1) {
      formikPracticeData.handleSubmit();
      // if (!practiceData.addresses.length) {
      //   setErrorDialog(true);
      //   setErrMessage('Please add an address');
      //   return;
      // }
      // const isPrimarySelected = practiceData.addresses.find(
      //   (add) => add.isPrimary,
      // );

      // if (isPrimarySelected) {
      //   try {
      //     setLoading(true);
      //     const response = await Users.putPractice(
      //       token,
      //       user.id,
      //       practiceData.healthCareEmployer,
      //       practiceData.addresses,
      //       practiceData.insurance,
      //       practiceData.hospitalLocation,
      //     );
      //     if (response.status === RESPONSE_STATUS.success) {
      //       setUser(response.body);
      //       setPages(page + 1);
      //       return;
      //     }
      //   } catch (e) {
      //     setErrMessage(e?.message);
      //     console.log(e);
      //   } finally {
      //     setLoading(false);
      //   }
      // }
      // if (!isPrimarySelected) {
      //   setErrorDialog(true);
      //   setErrMessage('No primary address selected');
      //   return;
      // }
    }
    if (page === 2) {
      if (staffMembers.length) {
        try {
          setLoading(true);
          const response = await Staffs.post(token, staffMembers);
          if (response.status === RESPONSE_STATUS.success) {
            setUser(response.body);
            navigate('/verification');
            return;
          }
        } catch (e) {
          setErrMessage(e?.message);
          console.log(e);
        } finally {
          setLoading(false);
        }
      }
      setWarningDialog(true);
      setWarningMessage(
        'Are you sure you would like to proceed without adding any staff member(s)?',
      );
      return;
      // ;
    }
  };

  useEffect(() => {
    if (user?.health_center_id) {
      // getDoctorsData();
    }
    if (doctorLocation) {
      const sortedAsc = doctorLocation.sort(
        (objA, objB) =>
          Number(new Date(objB.load_date)) - Number(new Date(objA.load_date)),
      );
      const filteredByDate = sortedAsc.filter(
        (doctor) => doctor.load_date === sortedAsc[0].load_date,
      );
      let primary = false;
      const filtered = filteredByDate
        .map((doctor) => {
          if (doctor.latitude && doctor.longitude) {
            let isPrimary = false;
            if (!primary && doctor.primary === 'Y') {
              isPrimary = true;
              primary = true;
            }
            if (!doctor.name && !doctor.address) {
              return null;
            }
            return {
              name: doctor.name || doctor.address,
              formatted_address: doctor.address,
              latitude: doctor.latitude,
              longitude: doctor.longitude,
              isPrimary,
              city: doctor.city,
              postal_code: doctor.postal_code,
              full_address: doctor.full_address,
              telephone: doctor.telephone,
              state: doctor.state,
            };
          }
        })
        .filter((doctor) => doctor);

      const resArr = [];
      filtered.filter((item) => {
        var i = resArr.findIndex(
          (x) => x.formatted_address === item.formatted_address,
        );
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      if (!practiceData?.addresses?.find((x) => x.isPrimary)) {
        setPracticeData((state) => ({
          ...state,
          addresses: resArr,
        }));
      }
    }
  }, [user]);

  // useEffect(() => {
  //   const fetchImage = async () => {
  //     if (doctorDetails?.image_path) {
  //       const fileName = doctorDetails.image_path.substring(
  //         doctorDetails.image_path.lastIndexOf('/') + 1,
  //       );
  //       const avatar = GetImageFromUrl(profilePicture);
  //       console.log(avatar);
  //       fetch(avatar).then(async (response) => {
  //         const contentType = response.headers.get('content-type');
  //         const blob = await response.blob();
  //         const file = new File([blob], fileName, { contentType });
  //         if (response) {
  //           setProfilePicture(file);
  //         }
  //       });
  //     }
  //   };
  //   fetchImage();
  // }, [doctorDetails]);

  // useEffect(() => {
  //   if(errMessage && !errorDialog) {
  //     setErrorDialog(true)
  //   }
  //   if(!errMessage && errorDialog) {
  //     setErrorDialog(false)
  //   }
  // }, [])

  useEffect(() => {
    if (!openDialog) {
      setErrMessage();
    }
  }, [openDialog, setErrMessage]);

  return (
    <>
      {/* <AlertDialog
          title="Error"
          open={errorDialog}
          setOpen={setErrorDialog}
          message={errMessage}
          error
        /> */}

      <AlertDialog
        title="Error"
        open={errorDialog}
        setOpen={setErrorDialog}
        message={errMessage}
        error
      />

      <WarningDialog
        title="Warning"
        open={warningDialog}
        setOpen={setWarningDialog}
        message={warningMessage}
        error
        continueWarning={() => navigate('/verification')}
      />
      {/* {Boolean(errMessage) ? (
      ) : (
        <></>
      )} */}
      <AlertDialog
        title="Are you sure you want to skip?"
        open={openDialog}
        setOpen={setOpenDialog}
      >
        <DialogContent sx={{ mb: 3 }}>
          <Typography color={textColor} gutterBottom variant="body1">
            Without setting up your account you will not be able to receive new
            patient referrals.
          </Typography>
          <Typography
            sx={{ mt: 3 }}
            color={textColor}
            gutterBottom
            variant="body1"
          >
            You can continue the setup process later from your profile page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            variant="contained"
            sx={{
              width: '7rem',
              height: 40,
              mr: 1.5,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
            onClick={() => setOpenDialog(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            sx={{ width: '14rem', height: 40 }}
            onClick={() => navigate('/verification')}
          >
            Skip, I’ll setup later
          </Button>
        </DialogActions>
      </AlertDialog>
      {page === 0 && (
        <AboutYou
          formik={formikAboutYou}
          currentData={aboutData}
          handleUpdateAboutData={handleUpdateAboutData}
          handleAddFellowShips={handleAddFellowShip}
          handleRemoveFellowShip={handleRemoveFellowShip}
          fellowShips={fellowShips}
          selectedImage={profilePicture}
          setSelectedImage={setProfilePicture}
          handleAddDegree={handleAddDegree}
          imageError={imageError}
          setImageErr={setImageErr}
          setFellowShips={setFellowShips}
          isDrawerOpen={isDrawerOpen}
        />
      )}
      {page === 1 && (
        <YourPractice
          formik={formikPracticeData}
          practiceData={practiceData}
          handleUpdatePracticeData={handleUpdatePracticeData}
          handleRemoveAddress={handleRemoveAddress}
          handleSetPrimaryAddress={handleSetPrimaryAddress}
          handleAddInsurance={handleAddInsurance}
          handleRemoveInsurance={handleRemoveInsurance}
          isDrawerOpen={isDrawerOpen}
        />
      )}
      {page === 2 && (
        <StaffMembers
          handleAddStaffMember={handleAddStaffMember}
          handleRemoveStaffMember={handleRemoveStaffMember}
          staffMembers={staffMembers}
          isDrawerOpen={isDrawerOpen}
        />
      )}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          position: 'fixed',
          right: 0,
          bottom: '0',
          boxShadow: 3,
          background: '#FFFFFF',
          flexWrap: 'nowrap',
          height: isDrawerOpen ? '10vh' : '7.5vh',
          width: `${isDrawerOpen ? `calc( 100% - ${drawerWidth}px)` : '100%'}`,
          px: 10,
          py: 1,
        }}
      >
        {page === 2 && (
          <Grid
            container
            alignItems="center"
            direction="row"
            gap={2}
            sx={{
              alignItems: 'center',
              position: 'absolute',
              transform: 'translateY(-10vh)',
              maxWidth: '75%',
              flexWrap: 'nowrap',
            }}
          >
            <WarningIcon />
            <Typography color="error" variant="body1">
              If you don’t include anyone you will have the responsibility of
              forwarding accepted referrals to your staff or scheduling
              appointments yourself.
            </Typography>
          </Grid>
        )}
        {page && (
          <Link
            color="textSecondary"
            variant="subtitle2"
            underline="hover"
            onClick={() => setPages(page - 1)}
            sx={{
              cursor: 'pointer',
              color: textColor,
            }}
          >
            Previous
          </Link>
        )}
        <Grid gap={4} item>
          <Button
            size="large"
            onClick={() => setOpenDialog(!openDialog)}
            variant="contained"
            sx={{
              width: '12.5rem',
              height: 40,
              mr: 1.5,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
          >
            Skip, I’ll setup later
          </Button>
          <LoadingButton
            color="primary"
            size="large"
            onClick={() => handleNext()}
            variant="contained"
            sx={{ width: '10rem', height: 40 }}
            loading={loading}
            loadingPosition="end"
          >
            {page === 2 ? 'Get started' : 'Next Step'}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default SetUpAccount;
