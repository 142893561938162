const ArrowRight = ({ fill = '#212634', opacity = '0.34' }) => (
  <svg
    width="32"
    height="29"
    viewBox="0 0 32 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 14.5H30M30 14.5L16.7213 28M30 14.5L16.7213 1"
      stroke="#47BB92"
      strokeWidth="2"
    />
  </svg>
);

export default ArrowRight;
