import { Autocomplete, Chip } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import CustomTextField, { CustomTextFieldNoBorders } from '../customTextField';

const defaultStatusPref = [
  {
    title: 'Suggested degrees',
    degree: 'MD',
  },
  {
    title: 'Suggested degrees',
    degree: 'DO',
  },
  {
    title: 'Suggested degrees',
    degree: 'MBBS',
  },
  {
    title: 'Suggested degrees',
    degree: 'DMD',
  },
  {
    title: 'Suggested degrees',
    degree: 'DDS',
  },
];
const nursePractitionerPref = [
  {
    title: 'Suggested degrees',
    degree: 'DNP',
  },
  {
    title: 'Suggested degrees',
    degree: 'MSN',
  },
];

const physicalPractitionerPref = [
  {
    title: 'Suggested degrees',
    degree: 'PA-C',
  },
];

const defaultRef = [
  {
    title: 'Other degrees',
    degree: 'PhD',
  },
  {
    title: 'Other degrees',
    degree: 'MPH',
  },
  {
    title: 'Other degrees',
    degree: 'MBA',
  },
  {
    title: 'Other degrees',
    degree: 'MSc',
  },
];

export const prefixList = [
  {
    title: 'Other degrees',
    degree: 'MD',
  },
  {
    title: 'Other degrees',
    degree: 'DO',
  },
  {
    title: 'Other degrees',
    degree: 'PhD',
  },
  {
    title: 'Other degrees',
    degree: 'MBBS',
  },

  {
    title: 'Other degrees',
    degree: 'MPH',
  },

  {
    title: 'Other degrees',
    degree: 'DMD',
  },
  {
    title: 'Other degrees',
    degree: 'DDS',
  },
  {
    title: 'Other degrees',
    degree: 'MBA',
  },
  {
    title: 'Other degrees',
    degree: 'MSc',
  },
  {
    title: 'Other degrees',
    degree: 'DNP',
  },
  {
    title: 'Other degrees',
    degree: 'MSN',
  },
  {
    title: 'Other degrees',
    degree: 'PA-C',
  },
];

export const DegreeSelection = ({ value, handleAddDegree, status }) => {
  const [options, setOptions] = useState(prefixList);

  useEffect(() => {
    if (status) {
      if (status === 'Nurse Practitioner') {
        setOptions([...nursePractitionerPref, ...defaultRef]);
      } else if (status === 'Physician Assistant') {
        setOptions([...physicalPractitionerPref, ...defaultRef]);
      } else {
        setOptions([...defaultStatusPref, ...defaultRef]);
      }
    }
  }, [status]);
  const handleUpdate = (newValue) => {
    handleAddDegree([...value, newValue]);
  };

  const hanldeRemove = (newValue) => {
    const newArr = value.filter((val) => val !== newValue.option.degree);
    handleAddDegree(newArr);
  };

  const newValues = value
    ? value
        ?.map((degree) =>
          options.find((toCompare) => degree === toCompare.degree),
        )
        ?.filter((text) => text)
    : [];
  return (
    <Autocomplete
      multiple
      freeSolo
      initialValues={prefixList}
      options={options}
      groupBy={(option) => option.title}
      getOptionLabel={(option) => option.degree}
      value={newValues?.length ? newValues : []}
      renderTags={(value, getTagProps) => {
        if (value) {
          return value?.map((option, index) => (
            <Chip
              variant="outlined"
              color="primary"
              label={option.degree}
              {...getTagProps({ index })}
            />
          ));
        }
      }}
      label="Degree"
      onChange={(_event, value, eventType, newOption, test) => {
        if (eventType === 'selectOption') {
          handleUpdate(newOption.option.degree);
        }
        if (eventType === 'removeOption') {
          hanldeRemove(newOption);
        }
        // handleAddDegree(value.degree);
      }}
      sx={{ width: '100%', color: 'black', p: 0 }}
      renderInput={(params) => (
        <CustomTextField {...params} placeholder="Type here" />
      )}
    />
  );
};

export const DegreeSelectionNew = ({
  degree,
  setDegree,
  status,
  degrees,
  handleAddDegree = () => {},
}) => {
  const [options, setOptions] = useState(prefixList);

  useEffect(() => {
    if (status) {
      if (status === 'Nurse Practitioner') {
        setOptions([...nursePractitionerPref, ...defaultRef]);
      } else if (status === 'Physician Assistant') {
        setOptions([...physicalPractitionerPref, ...defaultRef]);
      } else {
        setOptions([...defaultStatusPref, ...defaultRef]);
      }
    }
  }, [status]);

  const handleChange = (e, value) => {
    if (value) {
      setDegree(value);
    }
  };
  return (
    <Autocomplete
      disablePortal
      key={degrees.length}
      // disableClearable
      options={options}
      groupBy={(option) => option.title}
      getOptionLabel={(option) => option.degree}
      getOptionDisabled={(option) => degrees?.includes(option.degree)}
      onChange={(event, value, reason, details) => {
        if (reason === 'selectOption' && details) {
          handleAddDegree(details.option, 'auto');
        }
      }}
      onInputChange={(event, value) => {
        handleChange(event, value);
      }}
      sx={{
        width: '100%',
        color: 'black',
        p: 0,
        boxShadow: 'none',
      }}
      renderInput={(params) => (
        <CustomTextFieldNoBorders
          {...params}
          sx={{ boxShadow: 'none' }}
          value={degree || ''}
          placeholder="Search for a degree"
        />
      )}
    />
  );
};
