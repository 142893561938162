import { NotificationColors } from '../../constants';
import { Typography, Grid, Card } from '@mui/material';
import CloseLight from '../../Icons/closeLight';

const Notification = ({ notificationData, setNotificationData }) => {
  return (
    <Card
      sx={{
        mt: 0,
        width: '100%',
        height: '4vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        bgcolor: NotificationColors[notificationData.type].bgcolor,
        borderRadius: 0,
        px: 5,
        py: 0.5,
        transition: 'all 0.3s',
      }}
    >
      <Grid direction="row" container gap={2} alignItems="center">
        <Typography
          variant="body1"
          color={NotificationColors[notificationData.type].color}
        >
          {notificationData.text}
        </Typography>
      </Grid>
      <div
        onClick={() => {
          setNotificationData({});
        }}
        style={{ cursor: 'pointer' }}
      >
        <CloseLight
          opacity={1}
          color={NotificationColors[notificationData.type].color}
        />
      </div>
    </Card>
  );
};

export default Notification;
