import CloseLight from '../../Icons/closeLight';

const RemoveButton = (props) => {
  const { handleClick, styles } = props;
  return (
    <div onClick={handleClick} style={{ cursor: 'pointer', ...styles }}>
      <CloseLight />
    </div>
  );
};

export default RemoveButton;
