import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  IconButton,
  Grid,
  InputAdornment,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomInputField from '../../components/customInputField';
import Logo from '../../Icons/logo';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Auth from '../../services/auth';
import { darkTextColor, RESPONSE_STATUS } from '../../constants/index';
import UserContext from '../../contexts/user/context';
import AlertDialog from '../../components/alertDialog';
import Users from '../../services/users';
import DoneIcon from '@mui/icons-material/Done';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { useState, useContext, useEffect, useRef } from 'react';
import LoginMap from '../../components/loginMap';
import ResetPassword from './resetPassword';
import EnterPasswords from './enterPassword';
import VerificationCodeField from '../../components/verificationCodeField';
import { LoadingButton } from '@mui/lab';

const textColor = '#212634';

const addPaddingZero = (time) => {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
};
const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { setToken, setUser, user, token, setAllReferrals } =
    useContext(UserContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);
  const [openPasswordResetDialogue, setOpenPasswordResetDialogue] =
    useState(false);
  const [open2Auth, setOpen2Auth] = useState(false);
  const [verificationToken, setVerificationToken] = useState();

  const [errMessage, setErrMessage] = useState();

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState('');
  const [enableAuthTimer, setEnableAuthTimer] = useState(600);

  const [authType, setAuthType] = useState(null);
  const authtimerRef = useRef();

  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const verificationId = query.get('verificationId');
  const resetPassword = query.get('resetPassword');

  const handleCoutDownEnableAuth = () => {
    if (authtimerRef?.current) {
      clearInterval(authtimerRef.current);
    }
    setEnableAuthTimer(600);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email must be valid')
        .required('Email is required')
        .max(255, 'Email should be less than 255 characters'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      const { email, password } = values;
      if (email.trim() && password.trim()) {
        setErrorMessage('');
        setAllReferrals([]);
        try {
          const response = await Auth.post(email, password);
          if (response.status === RESPONSE_STATUS.success) {
            if (response?.message === 'token sent') {
              setAuthType(response.type);
              setOpen2Auth(true);
              return;
            }
            setUser(response.user);
            setToken(response.token);
          }
        } catch (e) {
          if (e.message === 'Failed to fetch') {
            //firewall blocked
            //this should theoretically be the only way that a "Failed to fetch" error can happen without malicious API requests.
            setErrorMessage(
              "Your institution's firewall appears to have blocked login access. Please attempt logging in from a personal device.",
            );
          } else {
            setErrorMessage(e.message);
          }
          console.log(e);
        }
      }
    },
  });

  const enableVerification = async () => {
    if (verificationToken?.length === 6) {
      try {
        setLoading(true);
        const response = await Auth.verifyToken(
          formik.values.email,
          formik.values.password,
          verificationToken,
        );

        if (response.status === RESPONSE_STATUS.success) {
          setUser(response.user);
          setToken(response.token);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const handleVerification = async () => {
      try {
        const query = `?verificationId=${verificationId}`;
        const response = await Users.verify(query);

        if (response.status === RESPONSE_STATUS.error) {
          setVerified(response.message);
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          setUser(response.body);
        }
      } catch (e) {
        setVerified(e.message);
        return;
      } finally {
        setLoading(false);
      }
    };
    if (verificationId) {
      setOpenDialog(true);
      setLoading(true);
      handleVerification();
    }
  }, [setUser, verificationId]);

  useEffect(() => {
    if (resetPassword) {
      const data = JSON.parse(atob(resetPassword.split('.')[1]))?.exp * 1000;
      setOpenResetPasswordDialog(true);
    }
  }, [setUser, resetPassword]);

  useEffect(() => {
    if (token && user) {
      navigate('/overview');
    }
  }, [navigate, token, user]);

  useEffect(() => {
    authtimerRef.current = setTimeout(() => {
      if (enableAuthTimer) {
        setEnableAuthTimer(enableAuthTimer - 1);
      }
    }, 1000);
  }, [enableAuthTimer]);

  return (
    <>
      <ResetPassword
        openDialog={openPasswordResetDialogue}
        setOpenDialog={setOpenPasswordResetDialogue}
      />
      <EnterPasswords
        openDialog={openResetPasswordDialog}
        setOpenDialog={setOpenResetPasswordDialog}
        resetPassword={resetPassword}
      />
      <AlertDialog
        title="Two-factor authentication"
        open={open2Auth}
        setOpen={setOpen2Auth}
      >
        <DialogContent sx={{ mb: 3 }}>
          <Typography color={textColor} gutterBottom variant="body1">
            {authType === 1
              ? 'You will receive a 6-digit verification code via email.'
              : 'You’ll receive a 6-digit verification code by text message. Text or data rates may apply.'}
          </Typography>
          <Typography color={textColor} gutterBottom variant="body1">
            Verification code
          </Typography>
          <VerificationCodeField
            verificationToken={verificationToken}
            setVerificationToken={setVerificationToken}
            loading={loading}
            errMessageAuth={errMessage}
          />
          {enableAuthTimer ? (
            <Stack direction="row" justifyContent="space-between">
              <Typography
                color="primary"
                onClick={() => formik.handleSubmit()}
                sx={{ mt: 1, cursor: 'pointer' }}
                variant="body1"
              >
                Resend Code
              </Typography>
              <Typography color={darkTextColor} sx={{ mt: 1 }} variant="body2">
                Expires in{' '}
                {`${addPaddingZero(
                  Math.floor(enableAuthTimer / 60),
                )}:${addPaddingZero(
                  enableAuthTimer - Math.floor(enableAuthTimer / 60) * 60,
                )}`}
              </Typography>
            </Stack>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            size="large"
            variant="contained"
            disabled={loading}
            sx={{ flex: 1, height: 40 }}
            onClick={() => setOpen2Auth(false)}
          >
            Cancel
          </Button>

          <LoadingButton
            color="primary"
            size="large"
            loading={loading}
            loadingPosition="end"
            disabled={verificationToken?.length < 6}
            onClick={() => enableVerification()}
            variant="contained"
            sx={{
              height: 45,
              flexGrow: 3,
              '&:disabled': {
                color: 'black !important',
              },
            }}
          >
            {verificationToken?.length < 6
              ? `${6 - verificationToken?.length} digits left`
              : 'Log in'}
          </LoadingButton>
        </DialogActions>
      </AlertDialog>
      <AlertDialog
        title="Confirming Email"
        open={openDialog}
        setOpen={setOpenDialog}
      >
        <DialogContent sx={{ mb: 3 }}>
          {loading && (
            <Typography color={textColor} gutterBottom variant="body1">
              Please wait while we verify your email address
            </Typography>
          )}
          {!loading && !verified && (
            <Grid container direction="row" alignItems="center" gap={2}>
              <DoneIcon color="primary" sx={{ mb: 1 }} />
              <Typography color={textColor} gutterBottom variant="body1">
                Email verification completed
              </Typography>
            </Grid>
          )}

          {!loading && verified && (
            <>
              <Grid container direction="row" alignItems="center" gap={2}>
                <PriorityHighIcon color="error" sx={{ mb: 1 }} />
                <Typography color={textColor} gutterBottom variant="body1">
                  {verified}
                </Typography>
              </Grid>

              <Typography color={textColor} gutterBottom variant="body1">
                {verified === 'Email has already been verified!'
                  ? 'Feel free to login to your account.'
                  : 'Login to your account to request another verification email'}
              </Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            variant="contained"
            disabled={loading}
            sx={{ width: '14rem', height: 40 }}
            onClick={() => setOpenDialog(false)}
          >
            Done
          </Button>
        </DialogActions>
      </AlertDialog>
      <Container
        sx={{
          width: '100vw',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'start',
          height: '100vh',
          maxWidth: '100vw  !important',
          bgcolor: '#47BB92',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: { md: '30vw', sm: '100vw', xs: '100vw' },
            height: '100vh',
            position: 'absolute',
            left: 0,
            top: 0,
          }}
        >
          <form
            onSubmit={formik.handleSubmit}
            style={{
              padding: '1.5% 1.5%',
              overflow: 'hidden',
              height: '100vh',

              backgroundColor: '#FFFFFF',
              boxShadow:
                'rgba(254, 205, 211, 0.1) 0px 4px 16px, rgba(254,205,211,0.1) 0px 8px 24px, rgba(254,205,211, 0.1) 0px 16px 56px ',
            }}
          >
            <Grid
              sx={{ height: '100%', px: { md: '2.5%', sm: '25%' } }}
              container
              direction="column"
              justifyContent="center"
            >
              <div
                onClick={() => navigate('/')}
                style={{
                  position: 'absolute',
                  top: '2.5%',
                  left: '4%',
                  cursor: 'pointer',
                }}
              >
                <Logo />
              </div>
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                gap={3}
                sx={{ position: 'relative' }}
              >
                <Typography
                  color={textColor}
                  variant="h3"
                  sx={{ maxWidth: '60%', fontWeight: 600 }}
                >
                  Log in
                </Typography>
                <Box sx={{ width: '100%' }}>
                  <Typography color={textColor} gutterBottom variant="body1">
                    Work email
                  </Typography>
                  <CustomInputField
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    label="First Name"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.email}
                    fullWidth
                    width="100%"
                  />
                  {Boolean(formik.touched.email && formik.errors.email) && (
                    <Typography
                      color="error"
                      gutterBottom
                      variant="body2"
                      sx={{
                        position: 'absolute !important',
                        maxWidth: '15rem',
                      }}
                    >
                      {formik.touched.email && formik.errors.email}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ width: '100%' }}>
                  <Typography color={textColor} gutterBottom variant="body1">
                    Password
                  </Typography>
                  <CustomInputField
                    error={Boolean(
                      formik.touched.password && formik.errors.password,
                    )}
                    label="Password"
                    name="password"
                    fullWidth
                    width="100%"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ position: 'absolute', right: 5 }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {Boolean(
                    formik.touched.password && formik.errors.password,
                  ) && (
                    <Typography
                      color="error"
                      gutterBottom
                      variant="body2"
                      sx={{
                        position: 'absolute !important',
                        maxWidth: '15rem',
                      }}
                    >
                      {formik.touched.password && formik.errors.password}
                    </Typography>
                  )}
                </Box>
                <Link
                  color="primary"
                  onClick={() => setOpenPasswordResetDialogue(true)}
                  variant="body1"
                  underline="hover"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  Forgot password?
                </Link>
                <Button
                  color="primary"
                  disabled={formik.isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Log in
                </Button>
                <Typography
                  color="error"
                  gutterBottom
                  variant="body2"
                  sx={{ alignSelf: 'center', mt: -2, position: 'relative' }}
                >
                  {errorMessage || ''}
                </Typography>
              </Grid>
              <Typography
                color={textColor}
                variant="body2"
                sx={{ alignSelf: 'center', mt: 3 }}
              >
                Don’t have an account?{' '}
                <Link
                  color="textSecondary"
                  onClick={() => navigate('/sign-up')}
                  variant="subtitle2"
                  underline="hover"
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  Sign up
                </Link>
              </Typography>
            </Grid>
          </form>
        </Box>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            left: '30vw',
            top: 0,
            height: '100vh',
            width: '70vw',
            overflow: 'hidden',
            display: { md: 'flex', sm: 'none', xs: 'none' },
          }}
        >
          <LoginMap />
        </Grid>
      </Container>
    </>
  );
};

export default Login;
