import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomInputField from '../customInputField';
import { RESPONSE_STATUS } from '../../constants/index';
import AlertDialog from '../alertDialog';
import Users from '../../services/users';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import User from '../../services/users';

const textColor = '#212634';

const SendInvite = (props) => {
  const { openDialog, setOpenDialog, token, name, onlyName } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [completed, setComplated] = useState(false);
  const [sendInviteLoading, setSendInviteLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Work Email must be valid')
        .required('Work Email is required')
        .max(255, 'Work Email should be less than 255 characters'),
    }),
    onSubmit: async (values) => {
      const { email } = values;
      try {
        setSendInviteLoading(true);
        const response = await User.sendInvite(token, email, onlyName);
        if (response.status === RESPONSE_STATUS.success) {
          formik.setFieldValue('email', '');
          setComplated(true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setSendInviteLoading(false);
      }
    },
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <AlertDialog
        title="Send Invite"
        open={openDialog}
        setOpen={setOpenDialog}
      >
        <DialogContent sx={{ mb: 3, width: { md: '25vw', sm: '100%' } }}>
          {completed ? (
            <Typography color={textColor} gutterBottom variant="body1">
              You have successfully sent an invite to {name}!
            </Typography>
          ) : (
            <>
              <Grid container direction="column" gap={3}>
                <Typography color={textColor} gutterBottom variant="body1">
                  Invite {name} to medLynks!
                </Typography>
                <Grid item sx={{ flexGrow: 1 }}>
                  <Typography color={textColor} gutterBottom variant="body1">
                    Work Email
                  </Typography>
                  <CustomInputField
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    label="email"
                    fullWidth
                    width="100%"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.email}
                  />
                  {Boolean(formik.touched.email && formik.errors.email) && (
                    <Typography
                      color="error"
                      gutterBottom
                      variant="body2"
                      sx={{
                        // position: 'absolute !important',
                        width: '50%',
                      }}
                    >
                      {formik.touched.email && formik.errors.email}
                    </Typography>
                  )}
                </Grid>
                {errorMessage && (
                  <Typography
                    color="error"
                    gutterBottom
                    variant="body2"
                    // sx={{ position: 'absolute !important' }}
                  >
                    {errorMessage}
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!completed && (
            <Button
              color="secondary"
              size="large"
              variant="contained"
              // disabled={loading}

              fullWidth
              onClick={() => setOpenDialog(false)}
              sx={{
                width: '10rem',
                height: 40,
                mr: 1.5,
                bgcolor: 'rgba(47, 47, 47, 0.14) !important',
                color: '#2F2F2F',
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            color="primary"
            size="large"
            variant="contained"
            // disabled={loading}
            disabled={sendInviteLoading}
            fullWidth
            sx={{ width: '10rem', height: 40 }}
            onClick={() => {
              if (completed) {
                setOpenDialog(false);
              } else {
                formik.handleSubmit();
              }
            }}
          >
            {completed ? 'Done' : 'Send invite'}
          </Button>
        </DialogActions>
      </AlertDialog>
    </>
  );
};

export default SendInvite;
