import React, { useContext, useEffect, useState, useRef } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  Stack,
  CircularProgress,
  Pagination,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/user/context';
import Navbar from '../../components/navbar';
import {
  darkTextColor,
  DEFAULT_FOOTER_SIZE,
  getHealthCenterLocation,
} from '../../constants';
import { specialtyFilters } from '../../constants/specialties_newest';

import { EmptyIcon } from '../../Icons/emptyIcon';
import FindSpecialist from '../../components/findSpecialist';
import Map from './maps';
import ReferralPatient from './sendReferral';
import SearchCard from '../../components/serachCard';
import SendInvite from './sendInvite';
import { LoadingCircularProgress } from '../../components/loadingCircularProgress';
import { FooterVerifiedUser } from '../../components/footer';

const NumberOfCards = 25;

const calculateSearchSimilarity = (result, searchInput) => {
  const searchWords = searchInput.split(' ');
  let similarity = 0;
  for (let word of searchWords) {
    if (
      result.firstName.toLowerCase().includes(word.toLowerCase()) ||
      result.lastName.toLowerCase().includes(word.toLowerCase())
    ) {
      similarity++;
    }
  }
  return similarity;
};

function getAddress(latitude, longitude) {
  return new Promise(function (resolve, reject) {
    var request = new XMLHttpRequest();

    var method = 'GET';
    var url =
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
      latitude +
      ',' +
      longitude +
      '&sensor=true&key=AIzaSyDCshnacXxncQwvgAOdjPVWl6PHYMJK0os';
    var async = true;

    request.open(method, url, async);
    request.onreadystatechange = function () {
      if (request.readyState == 4) {
        if (request.status == 200) {
          var data = JSON.parse(request.responseText);
          var address = data.results[0];
          resolve(address);
        } else {
          reject(request.status);
        }
      }
    };
    request.send();
  });
}

const FindASpecialist = () => {
  const {
    user,
    token,
    setUser,
    allDoctors,
    loadingState,
    location,
    setLat,
    setLong,
    primary,
    setDistance,
    distance,
    setHealthcareEmployer,
    healthcareEmployer,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filterData, setFilteredData] = useState([]);
  const [openSideBar, setOpenSidebar] = useState();
  const [openInvite, setOpenInvite] = useState();

  const [selectedDoctor, setSelectedDoctor] = useState();
  const [hover, setHover] = useState();

  const [subspecialty, setSubspecialty] = useState();
  // const [healthcareEmployer, setHealthcareEmployer] = useState();
  // const [distance, setDistance] = useState('');
  const [files, setFiles] = useState([]);

  const [isFavorites, setIsFavorites] = useState(false);
  const [isMedlynks, setIsMedlynks] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(false);
  const [onFocusCard, setOnFocusCard] = useState();
  const [onClicked, setOnClicked] = useState();
  const [height, setHeight] = useState();
  const [isDistanceDisabled, setIsDistanceDisabled] = useState();

  const containerRef = useRef();
  // useEffect(() => {
  //   if (!token || !user) {
  //     navigate('/');
  //   }
  // }, [navigate, token, user]);

  // const onSuccess = async (pram) => {
  //   if (pram.coords.latitude && pram.coords.longitude) {
  //     const getUserAddress = await getAddress(
  //       pram.coords.latitude,
  //       pram.coords.longitude,
  //     );
  //     setIsDistanceDisabled(true);
  //   }
  // };

  // const onError = (pram) => {
  //   console.log(pram);
  //   setIsDistanceDisabled(true);
  // };

  // useEffect(() => {
  //   if (!('geolocation' in navigator)) {
  //     setIsDistanceDisabled(true);
  //     console.log('not supported');
  //     return;
  //   }
  //   navigator.geolocation.getCurrentPosition(onSuccess, onError);
  // }, []);
  useEffect(() => {
    if (allDoctors?.registerUsers) {
      let _doctorData = [];
      const distances = allDoctors?.distance || [];
      const primary = allDoctors?.search;
      const isSub = !!specialtyFilters.find((spec) =>
        spec.subspecialties.includes(primary),
      );
      let filteredUsers = allDoctors?.registerUsers?.users;
      if (isSub) {
        filteredUsers = filteredUsers
          .map((user) => {
            const getSimilarSpec = user.specialties.find(
              (spec) => spec.specialty === primary,
            );
            if (getSimilarSpec) {
              user['similarity'] = 1;
            } else {
              user['similarity'] = 0;
            }
            return user;
          })
          .sort((a, b) => b.similarity - a.similarity);
      }
      const toFilterOut = [];
      const myProfile = [];
      const registered = filteredUsers
        .map((doc) => {
          if (allDoctors?.unRegisteredUsers?.users) {
            const isScraped = allDoctors?.unRegisteredUsers?.users?.find(
              (user) => user.userId === doc.health_center_id,
            );
            if (isScraped) {
              toFilterOut.push(doc?.health_center_id);
            }
          }

          const getLocation = doc.address.find((add) => add.is_primary);
          let specialty = doc.specialties.find((spec) => spec.class === 'main');
          const locations = doc?.locations?.map((loc) => loc.name);
          if (!specialty && doc.specialties) {
            const subspecialty = doc.specialties.find(
              (spec) => spec.class === 'sub',
            );
            specialty = specialtyFilters.find((x) =>
              x.subspecialties.includes(subspecialty.specialty),
            );
          }
          const getDistance = distances.find(
            ({ name }) => name === getLocation?.city,
          );
          const registerUserData = {
            id: doc.id,
            firstName: doc?.firstName || '',
            lastName: doc?.lastName || '',
            specialties: doc?.specialties || '',
            specialty:
              (specialty?.specialty ? specialty?.specialty : specialty?.name) ||
              '',
            preffix: doc?.prefix || '',
            profile_picture_url: doc?.profile_picture_url || '',
            location: doc.location_name,
            address: getLocation?.full_address
              ? `${getLocation.full_address}${
                  getLocation.full_address.includes(getLocation?.city)
                    ? ', '
                    : `, ${getLocation?.city}, `
                } ${getLocation.state}, ${getLocation.postal_code}`
              : '',
            shortAddress: `${getLocation?.city}, ${getLocation?.state}`,
            lat: getLocation?.latitude || 0,
            long: getLocation?.longitude || 0,
            isRegistered: true,
            isAvailable: doc.isAvailable,
            locations: locations || [],
            city: getLocation?.city,
            street: getLocation?.name,
            postalCode: getLocation?.postal_code,
            state: getLocation?.state,
            distance:
              getDistance?.distance === undefined ? 0 : getDistance?.distance,
            likes:
              doc?.favoritesOff?.filter(
                (endorse) => endorse?.FavoriteBy !== null,
              )?.length || 0,
            endorsements:
              doc?.favoritesOff?.filter(
                (endorse) => endorse?.FavoriteBy !== null,
              ) || [],
            status: doc.status || null,
          };
          if (doc.id === user.id) {
            myProfile.push(registerUserData);
            return {};
          }
          return registerUserData;
        })
        .filter((doc) => doc?.firstName);
      _doctorData.push(...registered);
      let unRegDocArr = [];
      if (allDoctors?.unRegisteredUsers?.users) {
        const unRegistered = allDoctors?.unRegisteredUsers?.users
          .map((doc) => {
            if (
              doc?.userRegistered?.email_verified &&
              doc?.userRegistered?.doctor_verified
            ) {
              return {};
            }

            const isRegistered = toFilterOut.includes(doc.userId);
            if (isRegistered) {
              return {};
            }
            const doctorDetail = doc?.doctorDetail[0];
            let getLocation = doc?.doctorLocation?.find(
              (add) => add.primary === 'Y',
            );
            if (!getLocation && doc?.doctorLocation?.length) {
              getLocation = doc?.doctorLocation[0];
            }
            let getDistance = distances.find(
              ({ name }) => name === getLocation?.city,
            );

            if (!getDistance) {
              getDistance = distances.find(
                ({ name }) => name === getLocation?.postal_code,
              );
            }
            return {
              id: doc.userId,
              firstName: doc.first_name,
              lastName: doc.last_name,
              specialty: doc.specialty,
              preffix: doctorDetail?.suffix?.replaceAll('.', '') || '',
              profile_picture_url: `images/${doc.health_center}/${doctorDetail?.image_path}.jpg`,
              shortAddress: `${getLocation?.city}, ${getLocation?.state}`,
              location: getHealthCenterLocation(doc.health_center),
              health_center: doc.health_center,
              address: getLocation?.full_address || '',
              lat: getLocation?.latitude || 0,
              long: getLocation?.longitude || 0,
              isRegistered: false,
              city: getLocation?.city,
              street: getLocation?.address,
              postalCode: getLocation?.postal_code,
              state: getLocation?.state,
              isAvailable: false,
              specialties: [],
              distance:
                getDistance?.distance === undefined
                  ? 100
                  : getDistance?.distance,
              similarity: doc.similarity,
              status: null,
            };
          })
          .filter((doc) => doc?.firstName);
        unRegDocArr = [...unRegistered];
        _doctorData.push(...unRegistered);
      }
      if (location) {
        const finalData =
          _doctorData.sort((a, b) =>
            a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0,
          ) || [];
        setData([...myProfile, ...finalData]);
      } else {
        const sortedRegistered = registered.sort((a, b) => {
          const aAvailable = a.isAvailable;
          const bAvailable = b.isAvailable;
          const aSimilarity = calculateSearchSimilarity(a, primary);
          const bSimilarity = calculateSearchSimilarity(b, primary);

          // Sort by availability first
          if (aAvailable && !bAvailable) {
            return -1;
          } else if (!aAvailable && bAvailable) {
            return 1;
          }

          // Sort by search similarity
          if (aSimilarity > bSimilarity) {
            return -1;
          } else if (aSimilarity < bSimilarity) {
            return 1;
          }

          // Sort by likes if search similarity is the same
          return b.likes - a.likes;
        });
        const sortedUnRegistered = unRegDocArr?.sort((a, b) => {
          const aSimilarity = calculateSearchSimilarity(a, primary);
          const bSimilarity = calculateSearchSimilarity(b, primary);

          if (aSimilarity > bSimilarity) {
            return -1;
          } else if (aSimilarity < bSimilarity) {
            return 1;
          }
        });
        setData([...myProfile, ...sortedRegistered, ...sortedUnRegistered]);
      }
    }
  }, [allDoctors]);

  useEffect(() => {
    if (data) {
      let newData = data;
      if (healthcareEmployer) {
        newData = newData.filter(
          (d) => d?.location && d.location === healthcareEmployer,
        );
        if (!newData.length) {
          newData = data.filter(
            (d) => d?.locations && d.locations?.includes(healthcareEmployer),
          );
        }
      }
      if (isMedlynks || isFavorites || isAvailable || subspecialty) {
        newData = newData.filter((d) => d.isRegistered);
        if (subspecialty) {
          newData = newData.filter((d) =>
            d?.specialties?.find(
              (spec) => spec.specialty === subspecialty && spec.class === 'sub',
            ),
          );
        }
        if (isFavorites) {
          const userFav = user?.favorites.map((fav) => fav.favoriteId);
          newData = newData.filter((f) => userFav.includes(f?.id));
        }
        if (isAvailable) {
          newData = newData.filter((f) => f.isAvailable);
        }
      }
      setPage(1);
      setFilteredData(newData);
    }
  }, [
    isMedlynks,
    data,
    isFavorites,
    user,
    isAvailable,
    healthcareEmployer,
    subspecialty,
  ]);

  const handleInviteDoctor = (docData) => {
    setSelectedDoctor(docData);
    setOpenInvite(true);
  };

  const handleClickCard = (docData) => {
    setSelectedDoctor(docData);
    setOpenSidebar(true);
  };

  const clear = () => {
    setSubspecialty();
    setHealthcareEmployer();
    setDistance();
    setIsFavorites(false);
    setIsMedlynks(false);
    setIsAvailable(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    if (onFocusCard !== onClicked) {
      setOnClicked();
    }
  }, [onFocusCard]);

  useEffect(() => {
    if (!data?.length) {
      clear();
    }
  }, [data]);
  const updateHeight = () => {
    const elementHeight =
      window.innerHeight -
      containerRef.current.getBoundingClientRect().top -
      10 -
      DEFAULT_FOOTER_SIZE;
    if (elementHeight !== height) {
      setHeight(elementHeight);
    }
  };

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (filterData?.length && containerRef.current) {
      const getEle = document.getElementById('serach-card-container');
      if (getEle) {
        const elementHeight =
          window.innerHeight -
          getEle.getBoundingClientRect().top -
          10 -
          DEFAULT_FOOTER_SIZE;
        if (elementHeight !== height) {
          setHeight(elementHeight);
        }
      }
    }
  }, [filterData, containerRef.current, loadingState]);

  // useEffect(() => {
  //   console.log(hover);
  //   if (hover !== undefined) {
  //     const serachCard = document.getElementById(`doctorCard-${hover}`);
  //     const findDoc = filterData.findIndex((d) => d.id === hover);
  //     if (Math.floor(findDoc / NumberOfCards) + 1 !== page) {
  //       setPage(Math.floor(findDoc / NumberOfCards) + 1);
  //       return;
  //     }
  //     console.log('setting for', hover);
  //     if (serachCard && containerRef.current) {
  //       containerRef.current.scrollTop = serachCard.offsetTop;
  //     }
  //   }
  // }, [hover, page]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [page]);

  useEffect(() => {
    if (location) {
      setIsDistanceDisabled(false);
    } else {
      setIsDistanceDisabled(true);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      setLat();
      setLong();
    });
  }, []);

  if (!user) {
    return <LoadingCircularProgress />;
  }

  return (
    <>
      <ReferralPatient
        token={token}
        anchor={openSideBar}
        setAnchor={setOpenSidebar}
        referralData={selectedDoctor}
        user={user}
        files={files}
        setFiles={setFiles}
      />
      <SendInvite
        anchor={openInvite}
        setAnchor={setOpenInvite}
        docData={selectedDoctor}
      />
      <Box
        sx={{
          maxHeight: '100vh',
          height: '100vh',
          overflow: 'hidden',
          bgcolor: '#F8F9F8',
        }}
      >
        <Navbar />
        <FindSpecialist
          subspecialty={subspecialty}
          healthcareEmployer={healthcareEmployer}
          distance={distance}
          isFavorites={isFavorites}
          isMedlynks={isMedlynks}
          isAvailable={isAvailable}
          setIsMedlynks={setIsMedlynks}
          setIsAvailable={setIsAvailable}
          clear={clear}
        />
        <Stack
          container
          direction="row"
          spacing={0.25}
          sx={{ p: 3.5, maxHeight: '80vh', py: 1 }}
        >
          <Grid item sx={{ width: { md: '65%', sm: '100%' } }}>
            <Grid container direction="column">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <Grid container direction="column" gap={2}>
                  {loadingState ? (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{ height: '70vh' }}
                    >
                      <CircularProgress color="primary" />
                    </Stack>
                  ) : (
                    <>
                      {data.length ? (
                        <Grid
                          gap={2}
                          container
                          sx={{
                            // bgcolor: '#FFFFFF',
                            p: 2,
                            borderRadius: '4px 0px 4px 0px',
                            overflow: 'hidden',
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 500 }}
                              color={darkTextColor}
                            >
                              {filterData.length} Specialists found
                            </Typography>
                            {filterData?.length > NumberOfCards && (
                              <Pagination
                                count={
                                  filterData.length / NumberOfCards >= 1
                                    ? Math.ceil(
                                        (filterData.length + 1) / NumberOfCards,
                                      )
                                    : 1
                                }
                                color="primary"
                                variant="outlined"
                                shape="rounded"
                                page={page}
                                onChange={handlePageChange}
                              />
                            )}
                          </Grid>
                          <Stack
                            direction="column"
                            gap={1}
                            id="serach-card-container"
                            ref={containerRef}
                            sx={{
                              overflowY: 'auto',
                              width: '100%',
                              height: `${height ? `${height}px` : '50vh'}`,
                              paddingRight: 1,
                              // position: 'relative',
                            }}
                          >
                            {filterData &&
                              filterData
                                ?.slice(
                                  (page - 1) * NumberOfCards,
                                  (page - 1) * NumberOfCards + NumberOfCards,
                                )
                                .map((docData, index) => (
                                  <SearchCard
                                    setOnFocusCard={setOnFocusCard}
                                    index={index}
                                    hover={hover}
                                    handleClickCard={handleClickCard}
                                    handleInviteDoctor={handleInviteDoctor}
                                    docData={docData}
                                    onClicked={onClicked}
                                  />
                                ))}
                          </Stack>
                        </Grid>
                      ) : (
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          direction="column"
                          sx={{ width: '100%', height: '70vh' }}
                        >
                          <EmptyIcon />
                          <Typography variant="body1" color={darkTextColor}>
                            No results found
                          </Typography>
                          {!user.doctor_verified || !user.email_verified ? (
                            <Button
                              color="primary"
                              size="large"
                              className="custom-button"
                              onClick={() => navigate('/profile/edit')}
                              variant="contained"
                              sx={{
                                height: 40,
                                mt: 2,
                                bgcolor: '#FFFFFF',
                                border: '1px solid #47BB92',
                                color: '#47BB92',
                              }}
                            >
                              Setup profile
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Stack>
                      )}
                    </>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: { md: '45%', lg: '50%', xl: '60%' },
              display: { md: 'block', xs: 'none' },
            }}
          >
            {filterData && (
              <Map
                distance={distance}
                handleClickCard={handleClickCard}
                handleInviteDoctor={handleInviteDoctor}
                data={
                  filterData?.slice(
                    (page - 1) * NumberOfCards,
                    (page - 1) * NumberOfCards + NumberOfCards,
                  ) || []
                }
                doctorsSorted={filterData}
                onFocusCard={onFocusCard}
                setHover={setHover}
                setOnClicked={setOnClicked}
                onClicked={onClicked}
                hover={hover}
                height={height}
              />
            )}
          </Grid>
        </Stack>
        <FooterVerifiedUser />
      </Box>
    </>
  );
};

export default FindASpecialist;
