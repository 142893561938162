import {
  Box,
  Button,
  Typography,
  Grid,
  Chip,
  MenuItem,
  Select,
  IconButton,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomChip from '../../../components/customChip';
import {
  darkTextColor,
  encryptNumber,
  PatientStatus,
  ReasonToDecline,
} from '../../../constants';
import ArrowIcon from '../../../Icons/arrowIcon';
import EmailIcon from '../../../Icons/emailcon';
import MedicalIcon from '../../../Icons/medicalIcon';
import PhoneIcon from '../../../Icons/phoneIcon';
import { ReferralInfo } from './referralInfo';

const DeclineReferral = ({
  referralData,
  user,
  reason,
  handleForwardReferral,
  handleReason,
  handleCancel,
  updateStatus,
  referralLoading,
}) => {
  const navigate = useNavigate();
  const [disabledButton, setDisabledButton] = useState(false);
  return (
    <Grid container justifyContent="space-between" sx={{ height: '100%' }}>
      <div style={{ overflow: 'scroll', maxHeight: '77vh' }}>
        <ReferralInfo referralData={referralData} />
        <Grid
          container
          gap={1}
          sx={{ border: '1px solid #E2E2E2', p: 2, borderRadius: '4px', mt: 2 }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 600, fontSize: 16 }}
              color={darkTextColor}
            >
              Reason for decline
            </Typography>
          </Grid>
          <Select
            value={reason}
            onChange={(event) => handleReason(event.target.value)}
            displayEmpty
            sx={{
              width: '100%',
              color: darkTextColor,
              fontSize: 'body1',
              height: 40,
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            {ReasonToDecline.map((item) => (
              <MenuItem
                variant="body1"
                key={item}
                sx={{ color: darkTextColor }}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {referralData.canForward && (
          <Grid
            container
            gap={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              border: '1px solid #E2E2E2',
              p: 2,
              borderRadius: '4px',
              mt: 2,
              flexWrap: 'nowrap',
            }}
          >
            <Grid container direction="column">
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, fontSize: 16 }}
                color={darkTextColor}
              >
                Forward patient referral
              </Typography>
              <Typography variant="body1" color={darkTextColor}>
                Find a more appropriate specialist
              </Typography>
            </Grid>
            <IconButton
              onClick={() => handleForwardReferral()}
              style={{ cursor: 'pointer' }}
            >
              <ArrowIcon />
            </IconButton>
          </Grid>
        )}
      </div>
      <Grid
        container
        gap={1.5}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          flexWrap: 'nowrap',
          p: 2,
          mt: 1,
          position: 'absolute',
          bottom: '0px',
          right: 0,
          height: 'auto',
          width: '100%',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Button
          color="error"
          size="large"
          onClick={() => handleCancel()}
          variant="contained"
          sx={{
            height: 40,
            flexGrow: 1,
            bgcolor: 'rgba(47, 47, 47, 0.14) !important',
            color: '#2F2F2F',
          }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          size="large"
          disabled={referralLoading}
          onClick={() => {
            updateStatus(
              PatientStatus.Decline,
              null,
              null,
              null,
              `${referralData.ReferredBy.firstName} ${referralData.ReferredBy.lastName} ${referralData.ReferredBy.prefix}`,
            );
          }}
          variant="contained"
          sx={{ flexGrow: 4, height: 40 }}
        >
          Decline
        </Button>
      </Grid>
    </Grid>
  );
};

export default DeclineReferral;
