const TooltipIcon = (props) => {
  const { fill, opacity = 0.56, size = 14 } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={fill ? fill : ''}
      style={{ cursor: 'pointer' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M7.00004 1C10.3137 1 13 3.68627 13 6.99996C13 8.65663 12.3283 10.1572 11.2428 11.2428C10.1572 12.3283 8.6568 13 6.99996 13C3.68627 13 1 10.3137 1 7.00004C1 3.68635 3.68627 1.00009 6.99996 1.00009L7.00004 1ZM7.00004 1.72739C4.08792 1.72739 1.72758 4.08794 1.72758 6.99985C1.72758 9.91176 4.08814 12.2723 7.00004 12.2723C9.91195 12.2723 12.2725 9.91176 12.2725 6.99985C12.2725 4.08794 9.91195 1.72739 7.00004 1.72739Z"
          fill={fill ? fill : '#212634'}
          stroke={fill ? fill : '#212634'}
          strokeWidth="0.5"
        />
        <path
          d="M4.89062 4.88835C5.18677 4.02669 6.05663 3.49634 6.94784 3.49634C8.076 3.49634 9.11275 4.34325 9.11275 5.51254C9.11275 6.31057 8.61656 6.99343 7.90196 7.3214C7.55199 7.48094 7.34707 7.8048 7.34707 8.18875V8.61591H6.54878V8.18875C6.54878 7.49175 6.93792 6.88831 7.57156 6.59812C8.00038 6.40167 8.31444 5.99487 8.31444 5.51256C8.31444 4.78082 7.63895 4.29465 6.94795 4.29465C6.36728 4.29465 5.85233 4.60277 5.64015 5.15389L4.89073 4.88837L4.89062 4.88835Z"
          fill={fill ? fill : '#212634'}
          stroke={fill ? fill : '#212634'}
          strokeWidth="0.5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.94682 9.28198C7.28406 9.28198 7.5577 9.55562 7.5577 9.89252C7.5577 10.2298 7.28406 10.5034 6.94682 10.5034C6.6099 10.5034 6.33594 10.2298 6.33594 9.89252C6.33594 9.5556 6.60981 9.28198 6.94682 9.28198Z"
          fill={fill ? fill : '#212634'}
          stroke={fill ? fill : '#212634'}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  );
};

export default TooltipIcon;
