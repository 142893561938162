import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomInputField from '../../components/customInputField';
import { RESPONSE_STATUS } from '../../constants/index';
import AlertDialog from '../../components/alertDialog';
import Users from '../../services/users';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const textColor = '#212634';

const EnterPasswords = (props) => {
  const { openDialog, setOpenDialog, resetPassword } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isExpired, setIsExpired] = useState(false);
  const [values, setValues] = useState({
    showPassword: false,
    showRepeatPassword: false,
  });
  const [completed, setComplated] = useState(false);
  const [showPasswordReqs, setShowPasswordReqs] = useState(true);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowRepeatPassword = () => {
    setValues({
      ...values,
      showRepeatPassword: !values.showRepeatPassword,
    });
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      reEnterPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('Password is a required field')
        .max(24, 'Password should be less than 24 characters')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          'Password must contain at least 8 characters, at least one uppercase, at least one lowercase, at least one number, and one special case character.',
        ),
      reEnterPassword: Yup.string()
        .min(8, 'Password should be at least 8 characters')
        .required('RepeatPassword is a required field'),
    }),
    onSubmit: async (values) => {
      if (values.password !== values.reEnterPassword) {
        setErrorMessage('Passwords dont match!');
        return;
      }
      try {
        const response = await Users.resetPasswordLogin(
          resetPassword,
          values.password,
        );
        if (response.status === RESPONSE_STATUS.error) {
          setErrorMessage(response.message);
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          setComplated(true);
        }
      } catch (e) {
        console.log(e);
        setErrorMessage(e.message);
      }
    },
  });

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (resetPassword) {
      const data = JSON.parse(atob(resetPassword.split('.')[1]))?.exp * 1000;
      if (data - new Date().getTime() < 0) {
        setIsExpired(true);
      }
    }
  }, [resetPassword]);

  useEffect(() => {
    if (formik.values.password && !formik.errors.password) {
      setShowPasswordReqs(false)
    }
    else {
      setShowPasswordReqs(true)
    }
  }, [formik]);

  return (
    <>
      <AlertDialog
        title="Reset Password"
        open={openDialog}
        setOpen={setOpenDialog}
      >
        <DialogContent sx={{ mb: 3, width: { md: '25vw', sm: '100%' } }}>
          {completed ? (
            <Typography color={textColor} gutterBottom variant="body1">
              You have successfully changed your password! Please login to
              continue
            </Typography>
          ) : (
            <>
              {!isExpired ? (
                <Grid container direction="column" gap={3}>
                  <Grid item sx={{ flexGrow: 1 }}>
                    <Typography color={textColor} gutterBottom variant="body1">
                      Create password
                    </Typography>
                    <CustomInputField
                      error={Boolean(
                        formik.touched.password && formik.errors.password,
                      )}
                      label="Password"
                      fullWidth
                      width="100%"
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type={values.showPassword ? 'text' : 'password'}
                      value={formik.values.password}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{ position: 'absolute', right: 5 }}
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  {showPasswordReqs && (
                    <Typography
                      color="error"
                      gutterBottom
                      variant="body2"
                      sx={
                        {
                          // position: 'absolute !important',
                          // width: '50%',
                        }
                      }
                    >
                    Password must contain at least 8 characters, at least one uppercase, at least one lowercase, at least one number, and one special case character.
                    </Typography>
                  )}
                  </Grid>
                  <Grid item sx={{ flexGrow: 1 }}>
                    <Typography color={textColor} gutterBottom variant="body1">
                      Repeat password
                    </Typography>
                    <CustomInputField
                      error={Boolean(
                        formik.touched.reEnterPassword &&
                          formik.errors.reEnterPassword,
                      )}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{ position: 'absolute', right: 5 }}
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowRepeatPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showRepeatPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      variant="standard"
                      label="RepeatPassword"
                      fullWidth
                      width="100%"
                      name="reEnterPassword"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type={values.showRepeatPassword ? 'text' : 'password'}
                      value={formik.values.reEnterPassword}
                    ></CustomInputField>
                    {Boolean(
                      formik.touched.reEnterPassword &&
                        formik.errors.reEnterPassword,
                    ) && (
                      <Typography
                        color="error"
                        gutterBottom
                        variant="body2"
                        sx={{ position: 'absolute !important' }}
                      >
                        {formik.touched.reEnterPassword &&
                          formik.errors.reEnterPassword}
                      </Typography>
                    )}
                  </Grid>
                  {errorMessage && (
                    <Typography
                      color="error"
                      gutterBottom
                      variant="body2"
                      // sx={{ position: 'absolute !important' }}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                </Grid>
              ) : (
                <Typography color={textColor} gutterBottom variant="h6">
                  Password reset link has expired
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            // disabled={loading}
            fullWidth
            onClick={() => setOpenDialog(false)}
            sx={{
              width: '10rem',
              height: 40,
              mr: 1.5,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            // disabled={loading}
            disabled={isExpired}
            fullWidth
            sx={{ width: '10rem', height: 40 }}
            onClick={() => {
              if (completed) {
                setOpenDialog(false);
              } else {
                formik.handleSubmit();
              }
            }}
          >
            Done
          </Button>
        </DialogActions>
      </AlertDialog>
    </>
  );
};

export default EnterPasswords;
