import { useState } from 'react';
import {
  Box,
  Typography,
  Tooltip,
  Grid,
  Stack,
  InputAdornment,
} from '@mui/material';
import CustomInputField from '../../../components/customInputField';
import { darkTextColor } from '../../../constants';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FileUploader } from 'react-drag-drop-files';
import Close from '../../../Icons/close';
import AttachmentIcon from '../../../Icons/attachmentIcon';
import TooltipIcon from '../../../Icons/tooltipIcon';
import { CharacterLeftTypography } from '../../../components/characterLeftTypography';

const urgencyArr = ['Routine', 'Semi-urgent', 'Urgent'];
const fileTypes = ['JPEG', 'PNG', 'GIF', 'PDF', 'JPG'];

const ReferralDetails = ({ formik, page, files, setFiles }) => {
  const handleChange = (newFile) => {
    if (newFile && newFile != undefined) {
      let newFiles = [];
      Array.from(newFile).forEach((file) => {
        // Use this approach if you want to change file object else forEach is redundant
        newFiles.push(file);
      });
      setFiles([...files, ...newFiles]);
    } else {
      console.log('file error');
    }
  };
  const urgency = formik.values.levelOfUrgency;
  const handleUrgencyChange = (value) => {
    formik.setFieldValue('levelOfUrgency', value);
  };

  const handleRemoveFile = (fileName) => {
    const _tempFiles = files.filter((file) => file.name !== fileName);
    setFiles(_tempFiles);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        direction="column"
        gap={0.5}
        sx={{ flexWrap: 'nowrap', overflowY: 'auto' }}
      >
        <Grid container direction="column">
          <Grid item sx={{ width: '100%', mt: 1 }}>
            <Typography color={darkTextColor} variant="body1">
              Reason for referral
            </Typography>
            <CustomInputField
              error={Boolean(formik.touched.about && formik.errors.about)}
              label="Reason for referral"
              fullWidth
              width="100%"
              multiline
              name="reason"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              rows={2}
              inputProps={{
                maxLength: 80,
              }}
              value={formik.values.reason}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{ position: 'absolute', bottom: -8, right: 5 }}
                >
                  {formik?.values?.reason?.length ? (
                    <Typography
                      color={darkTextColor}
                      sx={{ opacity: 0.75 }}
                      gutterBottom
                      variant="body2"
                    >
                      {80 - formik.values.reason.length} characters left
                    </Typography>
                  ) : (
                    <></>
                  )}
                </InputAdornment>
              }
            />
            {Boolean(formik.touched.reason && formik.errors.reason) && (
              <Typography
                color="error"
                gutterBottom
                variant="body2"
                sx={{ position: 'relative !important' }}
              >
                {formik.touched.reason && formik.errors.reason}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Grid item sx={{ width: '100%', mt: 1 }}>
            <Typography color={darkTextColor} variant="body1">
              History of present illness
            </Typography>
            <CharacterLeftTypography
              touched={formik.touched.history}
              errors={formik.errors.history}
              value={formik.values.history}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              maxLength={240}
              rows={3}
              label="History of present illness"
              name="history"
            />
            {Boolean(formik.touched.history && formik.errors.history) && (
              <Typography
                color="error"
                gutterBottom
                variant="body2"
                sx={{ position: 'relative !important' }}
              >
                {formik.touched.history && formik.errors.history}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Typography color={darkTextColor} gutterBottom variant="body1">
            Level of urgency
          </Typography>
          <Grid container sx={{ flexGrow: 1 }} direction="row" spacing={2}>
            <Grid item sx={{ flexGrow: 1 }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                direction="column"
                onClick={() => handleUrgencyChange(urgencyArr[0])}
                sx={{
                  p: 1,
                  px: 2,
                  borderRadius: '4px',
                  cursor: 'pointer',
                  background:
                    urgency === urgencyArr[0]
                      ? 'rgba(71, 187, 146, 0.12)'
                      : '#FFFFFF',
                  border:
                    urgency === urgencyArr[0]
                      ? '1px solid #47BB92'
                      : '1px solid #E2E2E2',
                }}
              >
                <Typography variant="body1" color={darkTextColor}>
                  Routine
                </Typography>
                <Typography
                  variant="body2"
                  color={darkTextColor}
                  sx={{ opacity: 0.75 }}
                >
                  Next available
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                direction="column"
                onClick={() => handleUrgencyChange(urgencyArr[1])}
                sx={{
                  p: 1,
                  px: 2,
                  borderRadius: '4px',
                  cursor: 'pointer',
                  background:
                    urgency === urgencyArr[1]
                      ? 'rgba(71, 187, 146, 0.12)'
                      : '#FFFFFF',
                  border:
                    urgency === urgencyArr[1]
                      ? '1px solid #47BB92'
                      : '1px solid #E2E2E2',
                }}
              >
                <Typography variant="body1" color={darkTextColor}>
                  Semi-urgent
                </Typography>
                <Typography
                  variant="body2"
                  color={darkTextColor}
                  sx={{ opacity: 0.75 }}
                >
                  Within 1 month
                </Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                direction="column"
                onClick={() => handleUrgencyChange(urgencyArr[2])}
                sx={{
                  p: 1,
                  px: 2,
                  borderRadius: '4px',
                  cursor: 'pointer',
                  background:
                    urgency === urgencyArr[2]
                      ? 'rgba(71, 187, 146, 0.12)'
                      : '#FFFFFF',
                  border:
                    urgency === urgencyArr[2]
                      ? '1px solid #47BB92'
                      : '1px solid #E2E2E2',
                }}
              >
                <Typography variant="body1" color={darkTextColor}>
                  Urgent
                </Typography>
                <Typography
                  variant="body2"
                  color={darkTextColor}
                  sx={{ opacity: 0.75 }}
                >
                  Within 2 weeks
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Grid item sx={{ width: '100%', my: 1 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography color={darkTextColor} variant="body1">
                Diagnostic reports
              </Typography>
              <Typography
                color={darkTextColor}
                sx={{ opacity: 0.5 }}
                variant="body1"
              >
                Optional
              </Typography>
            </Stack>
            <CharacterLeftTypography
              touched={formik.touched.report}
              errors={formik.errors.report}
              value={formik.values.report}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              maxLength={1000}
              rows={4}
              label="Diagnostic reports"
              name="report"
            />
            {Boolean(formik.touched.report && formik.errors.report) && (
              <Typography
                color="error"
                gutterBottom
                variant="body2"
                sx={{ position: 'relative !important' }}
              >
                {formik.touched.report && formik.errors.report}
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* <Grid></Grid> */}
        <Stack direction="row" justifyContent="space-between">
          <Stack spacing={1} container direction="row" alignItems="flex-start">
            <Typography color={darkTextColor} variant="body1">
              Add attachments
            </Typography>
            <Tooltip title="Add patient files" arrow>
              <div style={{ alignSelf: 'center', display: 'flex' }}>
                <TooltipIcon />
              </div>
            </Tooltip>
          </Stack>
          <Typography
            color={darkTextColor}
            sx={{ opacity: 0.5 }}
            variant="body1"
          >
            Optional
          </Typography>
        </Stack>
        <FileUploader
          multiple={true}
          handleChange={handleChange}
          name="file"
          children={
            <Box
              sx={{
                border: '1px dashed #DADADA',
                minHeight: '100px',
                minWidth: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#F3F3F3',
              }}
            >
              <Typography variant="body1" color={darkTextColor}>
                Drag & drop files or click here to upload
              </Typography>
            </Box>
          }
          types={fileTypes}
        />
        {files?.length &&
          files.map((file) => (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              sx={{ border: '1px solid #E2E2E2', mx: 0.5, p: 1 }}
            >
              <Stack spacing={2} direction="row" alignItems="center">
                <AttachmentIcon />
                <Typography variant="body2" color={darkTextColor}>
                  {file.name}
                </Typography>
              </Stack>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => handleRemoveFile(file.name)}
              >
                <Close size={20} />
              </div>
            </Grid>
          ))}
      </Grid>
    </LocalizationProvider>
  );
};

export default ReferralDetails;
