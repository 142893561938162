import { Grid, Typography, Button, InputAdornment } from '@mui/material';
import { useState, forwardRef, useRef } from 'react';
import VerificationCodeField from '../../components/verificationCodeField';
import Users from '../../services/users';
import { useContext } from 'react';
import UserContext from '../../contexts/user/context';
import { darkTextColor, RESPONSE_STATUS } from '../../constants';
import CustomInputField from '../../components/customInputField';
import { LoadingButton } from '@mui/lab';
import { IMaskInput } from 'react-imask';
import { useEffect } from 'react';

// const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
//   const { onChange, ...other } = props;
//   return (
//     <IMaskInput
//       {...other}
//       mask="(#00) 000-0000"
//       definitions={{
//         '#': /[1-9]/,
//       }}
//       inputRef={ref}
//       onAccept={(value) => onChange({ target: { name: props.name, value } })}
//       overwrite
//     />
//   );
// });

const addPaddingZero = (time) => {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
};

export const EmailVerification = ({
  twoFactorType,
  setAnchor,
  setTwoFactorType,
  user,
  setOpenDialog,
}) => {
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [verificationToken, setVerificationToken] = useState();
  const { token, setUser } = useContext(UserContext);

  // const [disableEmail, setDisabledEmail] = useState(false);
  const emailtimerRef = useRef();
  const [enableAuthTimer, setEnableAuthTimer] = useState();
  const [enableAuthLoading, setEnableAuthLoading] = useState();
  const [errMessageAuth, setErrMessageAuth] = useState();

  const enableVerification = async () => {
    if (verificationToken?.length === 6) {
      try {
        setEnableAuthLoading(true);
        const response = await Users.verifyTwoFactor(
          token,
          'Email',
          verificationToken,
        );

        if (response.status === RESPONSE_STATUS.error) {
          setErrMessageAuth(response.message);
        }
        if (response.status === RESPONSE_STATUS.success) {
          setUser(response.body);
          setVerificationToken();
          setAnchor(false);
          setTwoFactorType();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setEnableAuthLoading(false);
      }
    }
  };

  const handleCoutDownEnableEmail = (fromStart = false) => {
    if (emailtimerRef?.current) {
      clearInterval(emailtimerRef.current);
    }
    setEnableAuthTimer(600);
  };

  const handleSendCode = async () => {
    try {
      const query = `?type=${twoFactorType}`;
      setSendCodeLoading(true);
      const response = await Users.enableTwoFactor(token, query);

      if (response.status === RESPONSE_STATUS.success) {
        handleCoutDownEnableEmail();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setSendCodeLoading(false);
    }
  };

  useEffect(() => {
    emailtimerRef.current = setTimeout(() => {
      if (enableAuthTimer) {
        setEnableAuthTimer(enableAuthTimer - 1);
      }
    }, 1000);
  }, [enableAuthTimer]);

  useEffect(() => {
    if (errMessageAuth && verificationToken.length < 5) {
      setErrMessageAuth();
    }
  }, [verificationToken]);

  return (
    <>
      <div
        style={{
          margin: '30px 0px',
          width: '100%',
          borderRadius: '4px',
          height: '1px',
          backgroundColor: 'rgb(217, 217, 217)',
        }}
      />
      <Typography
        sx={{ my: 2 }}
        color={darkTextColor}
        gutterBottom
        variant="body1"
      >
        You’ll receive a 6-digit verification code by email.
      </Typography>
      <Grid item sx={{ flexGrow: 1, my: 1 }}>
        <Typography color={darkTextColor} gutterBottom variant="body1">
          Email address
        </Typography>
        <CustomInputField
          label="email"
          fullWidth
          width="100%"
          name="email"
          disabled
          value={user.email}
          endAdornment={
            <InputAdornment
              position="end"
              sx={{ position: 'absolute', right: 5 }}
            >
              {enableAuthTimer && enableAuthTimer > 570 ? (
                <Button
                  aria-label="toggle password visibility"
                  disabled
                  sx={{
                    color: 'grey !important',
                    height: '30px',
                  }}
                >
                  {enableAuthTimer ? 'Send code' : ''}
                </Button>
              ) : (
                <LoadingButton
                  aria-label="toggle password visibility"
                  onClick={() => handleSendCode()}
                  loading={sendCodeLoading}
                  loadingPosition="end"
                  onMouseDown={(e) => e.preventDefault()}
                  sx={{
                    height: '30px',
                    '&:hover': {
                      backgroundColor: 'rgba(0,0,0,0.01)',
                      boxShadow: 'none',
                    },
                    '& 	.MuiLoadingButton-loadingIndicatorEnd': {
                      color: 'grey !important',
                    },
                  }}
                >
                  {enableAuthTimer ? 'Resend code' : 'Send code'}
                </LoadingButton>
              )}
            </InputAdornment>
          }
        />

        {enableAuthTimer ? (
          <Typography color={darkTextColor} sx={{ mt: 1 }} variant="body2">
            Expires in{' '}
            {`${addPaddingZero(
              Math.floor(enableAuthTimer / 60),
            )}:${addPaddingZero(
              enableAuthTimer - Math.floor(enableAuthTimer / 60) * 60,
            )}`}
          </Typography>
        ) : (
          <></>
        )}
      </Grid>
      <Typography color={darkTextColor} sx={{ mt: 4 }} variant="body1">
        Verification code
      </Typography>
      <VerificationCodeField
        verificationToken={verificationToken}
        setVerificationToken={setVerificationToken}
        loading={enableAuthLoading}
        errMessageAuth={errMessageAuth}
      />
      {errMessageAuth ? (
        <Typography sx={{ my: 2 }} color="error" gutterBottom variant="body1">
          {errMessageAuth}
        </Typography>
      ) : (
        <></>
      )}
      <Grid
        container
        gap={1.5}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          flexWrap: 'nowrap',
          p: 2,
          mt: 1,
          position: 'absolute',
          bottom: '0px',
          right: 0,
          height: 'auto',
          width: '100%',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Button
          color="error"
          size="small"
          onClick={() => {
            setOpenDialog(true);
          }}
          variant="contained"
          sx={{
            height: 45,
            flexGrow: 1,
            bgcolor: 'rgba(246,93,94, 0.2) !important',
            color: 'rgb(246,93,94)',
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          size="large"
          loading={enableAuthLoading}
          loadingPosition="end"
          disabled={verificationToken?.length < 6}
          onClick={() => enableVerification()}
          variant="contained"
          sx={{
            height: 45,
            flexGrow: 3,
            '&:disabled': {
              color: 'black !important',
            },
          }}
        >
          {verificationToken?.length < 6
            ? `${6 - verificationToken?.length} digits left`
            : 'Enable Email authentication'}
        </LoadingButton>
      </Grid>
    </>
  );
};
