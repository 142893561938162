const Location = () => (
  <svg
    width="13"
    height="18"
    viewBox="0 0 13 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.45649 10.254L2.43839 10.225L2.41742 10.1981C1.63018 9.18579 1.1569 7.90284 1.1569 6.5045C1.1569 3.24617 3.68394 0.666667 6.74456 0.666667C9.80517 0.666667 12.3321 3.24607 12.3321 6.5045C12.3321 7.9029 11.859 9.18581 11.0717 10.198L11.0507 10.225L11.0325 10.254L6.74452 17.1148L2.45649 10.254Z"
      stroke="black"
      strokeWidth="1.33333"
    />
    <rect x="4.24023" y="4" width="5" height="5" rx="2.5" fill="black" />
  </svg>
);

export default Location;
