import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Autocomplete,
  Popper,
  Card,
  Stack,
} from '@mui/material';
import { CustomTextFieldNoBorders } from '../../../components/customTextField';
import Selection from './selection';
import { specialtyFilters } from '../../../constants/specialties_newest';
import AlertDialog from '../../../components/alertDialog';
import { CircleButton } from '../../EditProfile/circleButton';
import { darkTextColor } from '../../../constants';

const ConfirmPage = (props) => {
  const {
    formik,
    removeSubSelection,
    specialtiesSelected,
    removeSelection,
    handleAdd,
    handleAddSubselection,
    errMessage,
    setErrMessage,
    isDrawerOpen,
  } = props;
  const [specialities, setSpecialities] = useState();
  // const selection = useRef('');
  const [selection, setSelection] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const getDoctorsData = () => {
      const toAdd = specialtyFilters.map((spec) => spec.name);
      setSpecialities(toAdd.sort());
    };
    getDoctorsData();
  }, []);

  useEffect(() => {
    if (errMessage) {
      setOpenDialog(true);
    }
  }, [errMessage, setOpenDialog]);

  useEffect(() => {
    if (!openDialog) {
      setErrMessage();
    }
  }, [openDialog, setErrMessage]);

  return (
    <>
      {Boolean(errMessage) ? (
        <AlertDialog
          title="Error"
          open={openDialog}
          setOpen={setOpenDialog}
          message={errMessage}
          error
        />
      ) : (
        <></>
      )}
      <Grid
        container
        direction="column"
        alignItems="start"
        sx={{
          alignSelf: 'start',
          ml: isDrawerOpen ? 40 : 0,
          overflowY: 'auto',
          px: 1,
          width: '80vw',
          maxWidth: 950,
        }}
        gap={2}
      >
        <Typography color={darkTextColor} variant="h5">
          Confirm profile
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          <Grid
            maxWidth={true}
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            sx={{
              bgcolor: '#FFFFFF',
              border: '1px solid #E2E2E2',
              borderRadius: '4px',
              mb: 2,
              py: 2,
            }}
            gap={2}
          >
            <Card
              sx={{
                width: '100%',
                px: 2,
                py: 1,
                bgcolor: '#FFFFFF',
                borderBottom: '1px solid #ced4da',
                borderRadius: 0,
                boxShadow: 'none',
              }}
            >
              <Stack direction="row" sx={{ pb: 1 }}>
                <Stack direction="column" sx={{ minWidth: '20%' }}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    fontWeight="600"
                  >
                    Your Names
                  </Typography>
                </Stack>
                <Stack direction="row" sx={{ width: '100%' }} gap={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      border: `1px solid ${
                        formik.errors.firstName ? '#F65D5E' : '#E2E2E2'
                      }`,
                      borderRadius: '4px',
                      px: 1,
                    }}
                  >
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      sx={{ opacity: 0.6 }}
                    >
                      First Name
                    </Typography>
                    <CustomTextFieldNoBorders
                      value={formik.values.firstName}
                      onChange={(e) => {
                        formik.setFieldValue('firstName', e.target.value);
                      }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{
                      border: `1px solid ${
                        formik.errors.lastName ? '#F65D5E' : '#E2E2E2'
                      }`,
                      borderRadius: '4px',
                      px: 1,
                    }}
                  >
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      sx={{ opacity: 0.6 }}
                    >
                      Last Name
                    </Typography>
                    <CustomTextFieldNoBorders
                      value={formik.values.lastName}
                      onChange={(e) => {
                        formik.setFieldValue('lastName', e.target.value);
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Card>

            {specialities && (
              <Card
                sx={{
                  width: '100%',
                  px: 2,
                  py: 1,
                  bgcolor: '#FFFFFF',
                  borderRadius: 0,
                  boxShadow: 'none',
                }}
              >
                <Stack direction="row" sx={{ pb: 1 }}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    fontWeight="600"
                    sx={{ minWidth: '20%' }}
                  >
                    Specialties
                  </Typography>
                  <Grid
                    container
                    gap={1}
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: '100%', mb: 1 }}
                  >
                    <Stack
                      direction="column"
                      flexWrap="wrap"
                      gap={1}
                      sx={{ width: '100%' }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          border: '1px solid #E2E2E2',
                          borderRadius: '4px',
                        }}
                      >
                        <Autocomplete
                          disablePortal
                          id="Specialties"
                          PopperComponent={({ style, ...props }) => (
                            <Popper
                              {...props}
                              style={{ ...style, height: 0 }}
                            />
                          )}
                          getOptionDisabled={(option) => {
                            const test = specialtiesSelected?.find(
                              (spec) => spec.specialty === option,
                            );
                            if (test) {
                              return true;
                            }
                            return false;
                          }}
                          ListboxProps={{ style: { maxHeight: '30vh' } }}
                          options={specialities}
                          value={selection || ''}
                          // onChange={(e, value) => {
                          //   if (specialtiesSelected.includes(value)) return;
                          //   handleAdd(value);
                          //   setSelection();
                          // }}
                          onInputChange={(event, value, reason) => {
                            if (
                              reason === 'reset' &&
                              !specialtiesSelected.includes(value) &&
                              specialtiesSelected.length < 2
                            ) {
                              handleAdd(value);
                              setSelection();
                            } else {
                              setSelection(value);
                            }
                            // console.log(reason, specialtiesSelected);
                          }}
                          sx={{ width: '100%', color: 'black', p: 0 }}
                          renderInput={(params) => (
                            <CustomTextFieldNoBorders
                              {...params}
                              value={selection || ''}
                              placeholder="Type to search"
                            />
                          )}
                        />
                        <CircleButton
                          onClick={() => {
                            if (selection) {
                              handleAdd(selection);
                              setSelection();
                            }
                          }}
                          disabled={!specialities?.includes(selection)}
                        />
                      </Stack>
                      {specialtiesSelected?.length ? (
                        <>
                          <Typography
                            sx={{ color: darkTextColor }}
                            fontSize="14px"
                            variant="body2"
                          >
                            Feel free to add in your subspecialty if it is not
                            in the list.
                          </Typography>
                          {specialtiesSelected.map((item, index) => (
                            <React.Fragment key={index}>
                              <Selection
                                selected={item}
                                index={index}
                                removeSelection={removeSelection}
                                specialities={specialities}
                                removeSubSelection={removeSubSelection}
                                handleAddSubselection={handleAddSubselection}
                              />
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Grid>
                </Stack>
              </Card>
            )}
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default ConfirmPage;
