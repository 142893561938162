import {
  Button,
  Typography,
  Grid,
  MenuItem,
  Select,
  Stack,
  Avatar,
  Radio,
} from '@mui/material';
import {
  darkTextColor,
  GetImageFromUrl,
  PatientStatus,
  ReasonToDecline,
  RESPONSE_STATUS,
} from '../../../constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AppDrawer from '../../../components/appDrawer';
import SearchIcon from '../../../Icons/serachIcon';
import { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/user/context';
import Users from '../../../services/users';
import { CharacterLeftTypography } from '../../../components/characterLeftTypography';

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

const FrowardsReferal = ({
  referralData,
  user,
  updateStatus,
  anchor,
  setAnchor,
  referralLoading,
}) => {
  const { token } = useContext(UserContext);
  const [isFav, setIsFav] = useState(false);
  const [favArray, setFavArray] = useState([]);
  const [search, setSearch] = useState('');
  const debouncedSearchQuery = useDebounce(search, 500);

  const formik = useFormik({
    initialValues: {
      reasonForDecline: ReasonToDecline[0],
      selected: null,
      comments: '',
    },
    validationSchema: Yup.object({
      reasonForDecline: Yup.string().required('Reason for decline is required'),
      selected: Yup.number().required('Doctor is required'),
      comments: Yup.string(),
    }),
    onSubmit: (values) => {
      const { reasonForDecline, comments, selected } = values;
      const getDoc = favArray?.find((d) => d.id === selected);

      updateStatus(
        PatientStatus.Referred,
        reasonForDecline,
        comments,
        selected,
        `${getDoc.firstName} ${getDoc.lastName} ${getDoc.prefix}`,
      );
    },
  });

  const updateReason = (value) => {
    formik.setFieldValue('reasonForDecline', value);
  };

  const handleChangeSelection = (value) => {
    formik.setFieldValue('selected', value);
  };

  const getFav = async () => {
    try {
      const query = `?primary=${' '}&notInclude=${referralData.doctorId}`;
      const response = await Users.recommendations(token, query);

      if (response.status === RESPONSE_STATUS.success) {
        setFavArray(response.body);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const searchDebounce = async (keyword) => {
    return await new Promise((resolve) =>
      setTimeout(async () => {
        if (search) {
          try {
            const query = `?primary=${
              search ? search : ' '
            }&isFavorite=${isFav}&notInclude=${referralData.doctorId}`;
            const response = await Users.recommendations(token, query);
            if (response.status === RESPONSE_STATUS.success) {
              resolve({
                data: response.body,
              });
            }
          } catch (e) {
            console.log(e);
          } finally {
            //   setTimeout(() => {
            //     setLoading(false);
            //   }, 1000);
          }
        }
      }, 1000),
    );
  };
  useEffect(() => {
    if (anchor) {
      getFav();
    }
  }, [anchor]);

  useEffect(() => {
    searchDebounce(debouncedSearchQuery).then((response) => {
      setFavArray(response.data);
    });
  }, [token, user, isFav, search, debouncedSearchQuery, referralData]);

  return (
    <AppDrawer
      title="Review patient referral"
      anchor={anchor}
      setAnchor={setAnchor}
    >
      <Grid
        container
        justifyContent="space-between"
        sx={{ height: '100%', overflow: 'scroll' }}
        id="app-drawer-forward"
      >
        <div style={{ width: '100%', overflow: 'scroll' }}>
          <Grid
            container
            gap={1}
            sx={{
              border: '1px solid #E2E2E2',
              p: 2,
              borderRadius: '4px',
              mt: 2,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, width: '100%', fontSize: 16 }}
                color={darkTextColor}
              >
                Reason for decline
              </Typography>
            </Grid>
            <Select
              value={formik.values.reasonForDecline}
              onChange={(event) => updateReason(event.target.value)}
              displayEmpty
              sx={{
                width: '100%',
                color: darkTextColor,
                fontSize: 'body1',
                height: 40,
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {ReasonToDecline.map((item) => (
                <MenuItem
                  variant="body1"
                  key={item}
                  sx={{ color: darkTextColor }}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid
            container
            gap={1}
            sx={{
              border: '1px solid #E2E2E2',
              p: 2,
              borderRadius: '4px',
              mt: 2,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ flexWrap: 'nowrap', width: '100%' }}
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, fontSize: 16 }}
                color={darkTextColor}
              >
                Find a specialist
              </Typography>
            </Grid>
            <div className="forwardReferralSearch">
              <div className="loction-icon ">
                <SearchIcon />
              </div>
              <input
                onChange={(e) => setSearch(e.target.value)}
                type="search"
                className="forwardReferralSearchInput"
                placeholder="Specialty, doctor..."
              />
            </div>
            <div
              style={{ maxHeight: '30vh', overflowY: 'auto', width: '100%' }}
            >
              {favArray.length &&
                favArray.map((docData) => {
                  const avatar = GetImageFromUrl(docData.profile_picture_url);
                  const getLocation = docData.address.find(
                    (add) => add.is_primary,
                  );
                  return (
                    <Grid
                      container
                      direction="row"
                      alignItems="space-between"
                      sx={{
                        flexWrap: 'nowrap',
                        p: 2,
                        py: 1,
                        width: '100%',
                        bgcolor:
                          formik.values.selected === docData.id &&
                          'rgba(71, 187, 146, 0.12)',
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="space-evenly"
                        sx={{ flexWrap: 'nowrap' }}
                      >
                        <Avatar
                          sx={{
                            width: '48px',
                            height: '52px',
                            mr: 2.5,
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                          src={avatar}
                          variant="square"
                        />
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              gap={4}
                            >
                              <Typography color={darkTextColor} variant="body1">
                                {docData.firstName} {docData.lastName}
                                &nbsp;
                                {docData.preffix}
                              </Typography>
                            </Grid>
                          </Stack>

                          <Stack direction="row" spacing={1}>
                            <Typography
                              color={darkTextColor}
                              variant="body2"
                              sx={{ opacity: '0.75' }}
                            >
                              {(docData.specialties &&
                                docData.specialties.filter(
                                  (spec) => spec.class === 'main',
                                ).specialty) ||
                                docData.specialties[0].specialty}
                            </Typography>
                            <div style={{ color: darkTextColor }}>•</div>
                            <Typography
                              color={darkTextColor}
                              variant="body2"
                              sx={{ opacity: '0.75', ml: 1 }}
                            >
                              {getLocation?.city}, {getLocation?.state}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Radio
                        onChange={() => handleChangeSelection(docData.id)}
                        checked={formik.values.selected === docData.id}
                      />
                    </Grid>
                  );
                })}
            </div>
          </Grid>
          <Grid
            container
            gap={1}
            sx={{
              border: '1px solid #E2E2E2',
              p: 2,
              borderRadius: '4px',
              mt: 2,
            }}
          >
            <Grid item sx={{ flexGrow: 1 }}>
              <Typography
                color={darkTextColor}
                variant="body1"
                sx={{ fontWeight: 600, fontSize: 16 }}
              >
                Additional comments
              </Typography>
              <CharacterLeftTypography
                touched={formik.touched.comments}
                errors={formik.errors.comments}
                value={formik.values.comments}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
                maxLength={240}
                rows={3}
                label="Additional comments"
                name="comments"
              />
              {Boolean(formik.touched.comments && formik.errors.comments) && (
                <Typography
                  color="error"
                  gutterBottom
                  variant="body2"
                  sx={{ position: 'relative !important' }}
                >
                  {formik.touched.comments && formik.errors.comments}
                </Typography>
              )}
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          gap={1.5}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            flexWrap: 'nowrap',
            p: 2,
            mt: 1,
            position: 'absolute',
            bottom: '0px',
            right: 0,
            height: 'auto',
            width: '100%',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Button
            color="error"
            size="large"
            onClick={() => setAnchor(false)}
            variant="contained"
            sx={{
              height: 40,
              flexGrow: 1,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => formik.handleSubmit()}
            variant="contained"
            disabled={referralLoading || !formik.values.selected}
            sx={{ flexGrow: 4, height: 40 }}
          >
            Forward
          </Button>
        </Grid>
      </Grid>
    </AppDrawer>
  );
};

export default FrowardsReferal;
