import {
  Typography,
  Grid,
  Stack,
  Card,
  Button,
  Tooltip,
  Box,
} from '@mui/material';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  encryptNumber,
  GetImageFromUrl,
  handleFormatedAddress,
  handleSpeciality,
  RESPONSE_STATUS,
} from '../../constants';
import HealthBuildingLogo from '../../Icons/healthBuildingLogo';
import UserContext from '../../contexts/user/context';
import { useContext, useState } from 'react';
import Favorites from '../../services/favorites';
import { Specialties } from '../../constants/specialties';
import { useNavigate } from 'react-router-dom';
import SendInvite from './sendInvite';
import { CustomAvatar } from '../customAvatar';
import Location from '../../Icons/location';
import InfoIcon from '../../Icons/infoIcon';
import CalenderIcon from '../../Icons/calenderIcon';
import InsuranceColoredIcon from '../../Icons/insuranceColoredIcon';
import { LikeButton } from '../endorsements/hoverEffect';
import UnavailableIcon from '../../Icons/unAvailableIcon';

const GetStandardisedSpecialties = (incoming) => {
  const generalize = Specialties.find(
    (specialties) => specialties.specialty === incoming,
  );
  if (generalize) {
    return generalize.Specialty;
  }
  return '';
};
const checkFav = (favList, userId) => {
  if (favList.length) {
    const findFav = favList.find((f) => f.favoriteId === userId);
    if (findFav) {
      return true;
    }
  }
  return false;
};

const SearchCard = ({
  handleClickCard,
  handleInviteDoctor,
  docData,
  hover,
  index,
  setOnFocusCard,
  onClicked,
}) => {
  const { user, token, setUser, insurance } = useContext(UserContext);
  const avatar = GetImageFromUrl(docData.profile_picture_url);
  const [openDialog, setOpenDialog] = useState(false);

  const [endorsements, setEndorsements] = useState(docData.endorsements);
  const [endorseLoading, setEndorseLoading] = useState(false);

  const navigate = useNavigate();
  // const [isFav, setIsFav] = useState(docData)
  const getFav = docData.isRegistered
    ? checkFav(user.favorites, docData.id)
    : false;
  const [isFav, setIsFav] = useState(getFav);
  const updateFav = (favorites) => {
    const fav = checkFav(favorites, docData.id);
    setIsFav(fav);
  };

  const [likes, setLikes] = useState(docData?.likes || 0);

  const handleStuff = () => {
    handleInviteDoctor();
  };
  const AddFavorites = async (e, value) => {
    if (e?.stopPropagation) {
      e?.stopPropagation();
    }
    try {
      setEndorseLoading(true);

      const response = await Favorites.post(token, docData.id);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setUser(response.body);
        setLikes(likes + 1);
        const tempEndorsement = [...endorsements];
        tempEndorsement.unshift({
          createdAt: new Date(),
          doctorId: user.id,
          FavoriteBy: {
            firstName: user.firstName,
            lastName: user.lastName,
            prefix: user.prefix,
            profile_picture_url: user.profile_picture_url,
          },
        });
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setEndorseLoading(false);
    }
  };

  const RemoveFavorites = async (e, value) => {
    if (e?.stopPropagation) {
      e?.stopPropagation();
    }
    try {
      setEndorseLoading(true);
      const response = await Favorites.put(token, docData.id);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setUser(response.body);
        setLikes(likes - 1);
        const tempEndorsement = endorsements.filter(
          (end) => end.doctorId !== user.id,
        );
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setEndorseLoading(false);
    }
  };

  return (
    <div>
      <SendInvite
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        name={`${CapitalizeFirstLetter(
          docData.firstName,
        )} ${CapitalizeFirstLetter(docData.lastName)} ${
          docData?.preffix?.replaceAll(',', ', ') || ''
        }`}
        onlyName={`${CapitalizeFirstLetter(
          docData.firstName,
        )} ${CapitalizeFirstLetter(docData.lastName)}`}
        token={token}
      />

      <Card
        key={`${docData.id}-${docData.id}`}
        id={`doctorCard-${docData.id}`}
        onMouseEnter={() => setOnFocusCard(docData.id)}
        onMouseLeave={() => setOnFocusCard()}
        sx={{
          p: 1,
          px: 1.5,
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '10px',
          height: 'auto',
          cursor: docData?.isRegistered && 'pointer',
          border: '1px solid #E2E2E2',
          '&:hover': {
            backgroundColor: 'rgba(71, 187, 146, 0.12)',
          },
        }}
        onClick={() => {
          if (docData.isRegistered) {
            const profileNumber = encryptNumber(docData.id);
            navigate(`/doctor/${profileNumber}`);
          }
        }}
      >
        <div>
          <Grid
            container
            direction="row"
            alignItems="center"
            sx={{ flexWrap: 'nowrap' }}
          >
            <CustomAvatar
              sx={{
                width: '88px',
                height: '106px',
                mr: 2.5,
                justifyContent: 'center',
                display: 'flex',
                borderRadius: '4px',
              }}
              variant="square"
              curretDoc={docData}
            />
            <Grid container direction="column" justifyContent="space-between">
              <Stack direction="row" justifyContent="space-between">
                <Stack
                  direction={{ lg: 'row', md: 'column' }}
                  alignItems={{ lg: 'center', md: 'start' }}
                  justifyContent="center"
                  gap={0}
                >
                  <Typography
                    color={darkTextColor}
                    variant="h6"
                    fontSize="16px"
                  >
                    {CapitalizeFirstLetter(docData.firstName)}&nbsp;
                    {CapitalizeFirstLetter(docData.lastName)}
                    {docData?.preffix ? ',' : ''}&nbsp;
                    {docData?.preffix?.replaceAll(',', ', ') || ''}&nbsp; &nbsp;
                  </Typography>
                </Stack>
                {docData.isRegistered ? (
                  <Stack direction="row" alignItems="center" spacing={0}>
                    {isFav ? (
                      <LikeButton
                        endorsements={endorsements}
                        RemoveFavorites={RemoveFavorites}
                        sx={{ width: 18, height: 18 }}
                        isFav={isFav}
                        likes={likes}
                        docData={docData}
                        favLoading={endorseLoading}
                      />
                    ) : (
                      <LikeButton
                        endorsements={endorsements}
                        RemoveFavorites={AddFavorites}
                        reverse
                        sx={{ width: 18, height: 18 }}
                        isFav={isFav}
                        likes={likes}
                        docData={docData}
                        favLoading={endorseLoading}
                      />
                    )}
                    <Tooltip title="Endorse" arrow>
                      <Box
                        sx={{ mx: 0.5, display: 'flex', alignItems: 'center' }}
                      >
                        <InfoIcon />
                      </Box>
                    </Tooltip>
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
              <Typography
                color={darkTextColor}
                variant="body2"
                sx={{ opacity: '0.75' }}
              >
                {docData.isRegistered
                  ? handleSpeciality(docData.specialty, docData.status)
                  : GetStandardisedSpecialties(docData.specialty)}
              </Typography>
              <Stack direction="row">
                {docData?.street ? (
                  <Stack
                    sx={{ width: '40%' }}
                    direction="row"
                    alignItems="center"
                    spacing={1}
                  >
                    <div style={{ minWidth: '13px', minHeight: '18px' }}>
                      <Location fill={'#212634'} />
                    </div>
                    <Stack>
                      <Typography
                        sx={{ m: 0, p: 0 }}
                        color={darkTextColor}
                        variant="body2"
                      >
                        {handleFormatedAddress(docData.street, docData.city)}
                      </Typography>
                      <Typography
                        sx={{ m: 0, p: 0 }}
                        color={darkTextColor}
                        variant="body2"
                      >
                        {docData.city ? `${docData.city}, ` : ''}
                        {docData.state ? `${docData.state} ` : ''}{' '}
                        {docData.postalCode}
                      </Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <></>
                )}
                <Stack
                  sx={{
                    width: '40%',
                  }}
                  direction="row"
                  alignItems="center"
                  gap={1}
                >
                  <div
                    style={{
                      minWidth: '10px',
                      minHeight: '12px',
                      marginTop: '5px',
                    }}
                  >
                    <HealthBuildingLogo fill={'#212634'} />
                  </div>

                  <Typography color="#212634" variant="body2">
                    &nbsp;
                    {docData.location || 'No healthcare employer found'}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" gap={2}>
                {docData.isRegistered ? (
                  <Card
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '0px 8px',
                      gap: '4px',

                      minWidth: '188px',
                      maxWidth: '100%',
                      height: '18px',

                      background: docData.isAvailable ? '#DDF3EB' : '#FEF1F1',
                      borderRadius: '4px',
                    }}
                  >
                    {docData.isAvailable ? (
                      <CalenderIcon />
                    ) : (
                      <UnavailableIcon />
                    )}

                    <Typography
                      color={docData.isAvailable ? 'primary' : 'error'}
                      variant="body2"
                    >
                      {docData.isAvailable
                        ? 'Available for new referrals'
                        : 'Unavailable for new referrals'}
                    </Typography>
                  </Card>
                ) : (
                  <Stack
                    direction="row"
                    sx={{ width: '100%' }}
                    justifyContent="space-between"
                  >
                    <Stack direction="row" gap={2}>
                      <Card
                        sx={{
                          background: '#F8F9FA',
                          border: '1px solid #EAECEE',
                          borderRadius: '4px',
                          padding: '0px 8px',
                          boxShadow: 'none',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Tooltip
                          title="Currently not a registered medLynks user, but we encourage you to extend an invite to our network!"
                          arrow
                        >
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <Typography
                              sx={{ fontWeight: 500 }}
                              color={darkTextColor}
                              variant="body2"
                            >
                              Not registered
                            </Typography>
                            <InfoIcon />
                          </Stack>
                        </Tooltip>
                      </Card>
                      {insurance && (
                        <Stack direction="row" alignItems="center" gap={1}>
                          <InsuranceColoredIcon />
                          <Stack direction="row" alignItems="center" gap={0.5}>
                            <Typography
                              fontSize="14px"
                              fontWeight="500"
                              color={darkTextColor}
                            >
                              In-network
                            </Typography>
                            <Typography fontSize="14px" color={darkTextColor}>
                              •
                            </Typography>
                            <Typography fontSize="14px" color={darkTextColor}>
                              {insurance}
                            </Typography>
                          </Stack>
                        </Stack>
                      )}
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography
                        variant="body2"
                        onClick={() => handleInviteDoctor(docData)}
                        sx={{
                          color: '#47BB92',
                          width: 'fit-content',
                          cursor: 'pointer',
                        }}
                      >
                        Invite
                      </Typography>
                      <Button
                        color="primary"
                        className="custom-button"
                        onClick={() => handleClickCard(docData)}
                        variant="contained"
                        sx={{
                          fontSize: '12px',
                          p: 0.25,
                          m: 0,
                          fontWeight: 500,
                          height: 'min-content',
                          bgcolor: 'transparent',
                          border: '1px solid #47BB92',
                          color: '#47BB92',
                        }}
                      >
                        Refer patient
                      </Button>
                    </Stack>
                  </Stack>
                )}
                {insurance && docData.isRegistered && (
                  <Stack direction="row" alignItems="center" gap={1}>
                    <InsuranceColoredIcon />
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography
                        fontSize="14px"
                        fontWeight="500"
                        color={darkTextColor}
                      >
                        In-network
                      </Typography>
                      <Typography fontSize="14px" color={darkTextColor}>
                        •
                      </Typography>
                      <Typography fontSize="14px" color={darkTextColor}>
                        {insurance}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
        </div>
      </Card>
    </div>
  );
};

export default SearchCard;
