import { Box, Typography, Stack } from '@mui/material';
import { darkTextColor } from '../../constants';
import { useEffect, useState } from 'react';

const InsightBox = ({ color, type, data, user }) => {
  const [total, setTotal] = useState();
  const [accepted, setAccepted] = useState();
  const [decline, setDecline] = useState();

  if (type === 'Sent') {
  }

  useEffect(() => {
    if (data?.length) {
      if (type === 'Sent') {
        const getTotal = data?.filter((ref) => ref.doctorId === user.id);
        const getTotalAccepted = getTotal?.filter(
          (ref) =>
            ref.status === 'accept' || ref?.forwardRef?.status === 'accept',
        );

        const getTotalDeclined = getTotal?.filter(
          (ref) =>
            ref.status === 'decline' || ref?.forwardRef?.status === 'decline',
        );

        setDecline(getTotalDeclined.length);
        setAccepted(getTotalAccepted.length);

        setTotal(getTotal?.length);
      }
      if (type === 'Received') {
        const getTotal = data?.filter((ref) => ref.referredToId === user.id);
        const getTotalAccepted = getTotal?.filter(
          (ref) =>
            ref.status === 'accept' || ref?.forwardRef?.status === 'accept',
        );

        const getTotalDeclined = getTotal?.filter(
          (ref) =>
            ref.status === 'decline' || ref?.forwardRef?.status === 'decline',
        );

        setDecline(getTotalDeclined.length);
        setAccepted(getTotalAccepted.length);

        setTotal(getTotal?.length);
      }
    } else {
      setTotal(0);
    }
  }, [data, type, user]);

  return (
    <Box
      sx={{
        border: '1px solid #E2E2E2',
        px: 4,
        py: 2,
        flex: 4,
        borderRadius: '4px',
        borderLeft: `4px solid ${color}`,
      }}
    >
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack direction="column" spacing={1}>
          <Typography color="#000000" sx={{ opacity: 0.56 }}>
            {type}
          </Typography>
          <Typography variant="h2" color={darkTextColor} sx={{ fontWeight: 400 }}>
            {total}
          </Typography>
          <Typography variant="h5" color={darkTextColor} sx={{ fontWeight: 400 }}>
            Referrals
          </Typography>
        </Stack>
        <Stack direction="column" spacing={0.5} sx={{ width: '60%' }}>
          {(decline || accepted) && decline + accepted !== 0 ? (
            <>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body1" color={color}>
                  {Math.round((accepted / (accepted + decline)) * 100)}%
                </Typography>
                <Typography variant="body1" color={darkTextColor}>
                  {Math.round((decline / (accepted + decline)) * 100)}%
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1}>
                <div
                  style={{
                    width: `${(accepted / (accepted + decline)) * 100}%`,
                    height: '10px',
                    borderRadius: '100px',
                    backgroundColor: color,
                  }}
                />
                <div
                  style={{
                    width: `${(decline / (accepted + decline)) * 100}%`,
                    height: '10px',
                    borderRadius: '100px',
                    backgroundColor: darkTextColor,
                  }}
                />
              </Stack>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Stack direction="row" spacing={1} alignItems="center">
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '100%',
                      backgroundColor: '#FFFFFF',
                      border: `1px solid ${color}`,
                    }}
                  />

                  <Typography variant="body2" color={darkTextColor}>
                    {accepted} Accepted
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" color={darkTextColor}>
                    {decline} Declined
                  </Typography>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '100%',
                      backgroundColor: '#FFFFFF',
                      border: `1px solid ${darkTextColor}`,
                    }}
                  />
                </Stack>
              </Stack>
            </>
          ) : (
            <div
              style={{
                width: '84%',
                height: '10px',
                borderRadius: '100px',
                backgroundColor: '#000000',
                opacity: 0.5,
                alignItems: 'self-end',
              }}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default InsightBox;
