// import './style.css';
import { Fragment } from 'react';
import Simple from './simple';

const SlidingCarousel = () => {
  return (
    <Fragment>
      <section
        style={{
          margin: '20px 0 20px 0',
          width: '100%',
        }}
      >
        <Simple />
      </section>
    </Fragment>
  );
};

export default SlidingCarousel;
