import { darkTextColor, NotificationColors } from '../../constants';
import { Typography, Grid, Card, Stack, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowIcon from '../../Icons/arrowIcon';

const OverviewNotification = ({ text, handleOnClick }) => {
  return (
    <Stack
      sx={{
        bgcolor: '#FFFFFF;',
        p: 2,
        mt: 2,
        borderRadius: '4px',
      }}
      direction="row"
      alignItems="center"
      spacing={2}
    >
      <InfoOutlinedIcon sx={{ color: darkTextColor }} />
      <Typography variant="body1" color={darkTextColor}>
        {text}
      </Typography>

      {handleOnClick && (
        <IconButton onClick={() => handleOnClick()}>
          <ArrowIcon fill={darkTextColor} opacity={1} />
        </IconButton>
      )}
    </Stack>
  );
};

export default OverviewNotification;
