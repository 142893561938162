import React from 'react';

import GoogleMapReact from 'google-map-react';
import Marker from './marker';

const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y),
    );
  }
};

const Map = ({ data }) => {
  if (!data?.length) {
    return <></>;
  }
  const getPrimary = data.find((add) => add.is_primary);

  return (
    <div className="map-container-patient">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyDCshnacXxncQwvgAOdjPVWl6PHYMJK0os',
          language: 'en',
          region: 'US',
        }}
        defaultCenter={{
          lat: Number(getPrimary.latitude),
          lng: Number(getPrimary.longitude),
        }}
        defaultZoom={17}
        distanceToMouse={distanceToMouse}
      >
        {getPrimary ? (
          <Marker
            lat={Number(getPrimary.latitude)}
            lng={Number(getPrimary.longitude)}
          />
        ) : (
          <></>
        )}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
