import { Box, Typography, Grid, Stack, Link } from '@mui/material';
import { darkTextColor, encryptNumber } from '../../../constants';
import moment from 'moment';
import CustomChip from '../../../components/customChip';
import PhoneIcon from '../../../Icons/phoneIcon';
import EmailIcon from '../../../Icons/emailcon';
import { useNavigate } from 'react-router-dom';

export const ReferralInfo = ({ referralData }) => {
  const navigate = useNavigate();

  return (
    <Grid
      container
      gap={1}
      sx={{ border: '1px solid #E2E2E2', p: 2, borderRadius: '4px' }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid gap={1} container direction="row" alignItems="flex-start">
          <Box sx={{ width: '100%' }}>
            <Stack
              direction="row"
              alignItems="center"
              sx={{ width: '100%' }}
              spacing={2}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                  whiteSpace: 'nowrap',
                }}
                color={darkTextColor}
              >
                {referralData.firstname} {referralData.lastname}
              </Typography>
              <Grid
                sx={{
                  flexWrap: 'nowrap',
                  width: '100%',
                }}
                direction="row"
                container
                gap={1}
              >
                <CustomChip type={referralData.levelOfUrgency} />
                {referralData.status === null && (
                  <CustomChip type={'Pending'} />
                )}
              </Grid>
            </Stack>
            <Typography variant="p" color={darkTextColor}>
              By {referralData.ReferredBy.firstName}{' '}
              {referralData.ReferredBy.lastName}
              {', '}
              {referralData.ReferredBy.prefix.split(',').map((x) => `${x}, `)}
              {moment(referralData.createdAt).fromNow()}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          backgroundColor: '#F7F7F7',
          borderRadius: '4px',
          width: '100%',
          p: 1.5,
          my: 1,
        }}
      >
        <Typography variant="body2" color={darkTextColor}>
          {referralData.reason}
        </Typography>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Grid
          sx={{ mt: 1 }}
          gap={1.5}
          container
          direction="row"
          alignItems="center"
        >
          <div style={{ marginTop: 3 }}>
            <PhoneIcon />
          </div>
          <Box>
            <Typography
              variant="body2"
              sx={{ opacity: '0.6' }}
              color={darkTextColor}
            >
              Patient’s phone number
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 600 }}
              color={darkTextColor}
            >
              {referralData.phoneNumber}
            </Typography>
          </Box>
        </Grid>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Grid
          sx={{ mt: 1 }}
          gap={1.5}
          container
          direction="row"
          alignItems="center"
        >
          <div style={{ marginTop: 3 }}>
            <EmailIcon />
          </div>
          <Box>
            <Typography
              variant="body2"
              sx={{ opacity: 0.6 }}
              color={darkTextColor}
            >
              Patient’s email address
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontWeight: 600 }}
              color={darkTextColor}
            >
              {referralData.patientEmail}
            </Typography>
          </Box>
        </Grid>
      </Box>
      {/* <div
          style={{
            backgroundColor: '#F7F7F7',
            width: '100%',
            height: 5,
            margin: '5px',
          }}
        /> */}
      <Stack sx={{ p: 1.5, pb: 0.5, width: '100%' }} alignItems="center">
        <Link
          color="primary"
          size="large"
          component="button"
          onClick={() => {
            const profileNumber = encryptNumber(referralData.id);
            navigate(`/patient/${profileNumber}`);
          }}
          variant="contained"
          underline="hover"
          fullWidth
          sx={{ fontSize: '14px', fontWeight: '500' }}
        >
          View full referral details
        </Link>
      </Stack>
    </Grid>
  );
};
