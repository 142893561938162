const ListOfInsurancesCount = [
  'Blue Cross',
  'Cigna',
  'Humana',
  'Aetna',
  'MultiPlan',
  'United Healthcare',
  'Coventry',
  'Medicare',
  'Healthcare Finest Network',
  'Health Alliance',
  'Tricare',
  'Private HealthCare Systems',
  'Meridian',
  'Unicare',
  'Healthlink',
  'Union Health',
  'Imagine Health',
  'Medicaid',
  'Veteran Affairs',
  'Healthcare Solutions Team',
  'The Alliance',
  'Wellcare',
  'County Care',
  'Healthsmart',
  'AARP',
  'Ascension',
  'Preferred Plan',
  'Illinicare',
  'Molina Healthcare',
  'Three Rivers Provider Network ',
  'Zing',
  'Bright',
  'Corvel',
  'Perferred Network Access',
  'Oscar',
  'Workers Compensation',
  'Ambetter',
  'Ingalls Provider Group',
  'Little Company of Mary Health Providers',
  'Swedish Covenant Physician Partners',
  'Hinsdale Physician',
  'IDPA',
  'Health Link',
  'MoreCare',
  'Stratose',
  'Magellan',
  'Prime Health',
  'Illinois Medicaid',
  'Choice',
  'Interplan Health Group',
  'Healthsping',
  'Integrated Health Plan',
  'Kidcare',
  'Illinois Health Connect',
  'National Perferred Provider Network',
  'Illinois Health Partners',
  'Principal Healthcare',
  'Managed Care',
  'Indiana Health Network',
  'Private Healthcare System',
  'Access',
  'ACO',
  'Health Network',
  'Rush Prudential',
  'Metlife',
  'Prudential',
  'Health Star',
  'One Health Plan',
  'Wellmark',
  'Health Management Network',
  'Met Elect',
  'Metrahealth',
  'John Hancock',
  'Health Preferred',
  'Preferred Care Network',
  'Healthcare Compare',
  'Lincoln National',
  'New York Life',
  'Land of Lincoln',
  'Highland Healthcare',
  'Motorola',
  'Health Marketing',
  'Traverlers',
  'Advocate',
  'Illinois Healthy Woman',
  'Health Payors',
  'MacNeal Health Providers',
  'MidAmerica',
  'Take Care',
  'Health Win',
  'Northwestern Healthcare',
  'Sagamore Health Network',
  'Sanus Health ',
  'Heritage',
  'Hinsdale',
  'Integrated Healthcare',
  'Medcare',
  'Trustmark',
  'Health Risk',
  'Health Spring',
  'Health Sync',
  'John Alden',
  'Homan Group',
  'Integra',
  'Loyola University Physician Foundation',
  'Sterling Medicare',
  'Highland Managed Care',
  'Human Affairs International',
  'Innovative Resource Group',
  'Laborers Health and Welfare',
  'MEDCO',
  'Merit',
  'State Farm',
];

const ListOfInsurances = [
  'AARP',
  'ACO',
  'Access',
  'Advocate',
  'Aetna',
  'Ambetter',
  'Ascension',
  'Blue Cross',
  'Bright',
  'Choice',
  'Cigna',
  'Corvel',
  'County Care',
  'Coventry',
  'Health Alliance',
  'Health Link',
  'Health Management Network',
  'Health Marketing',
  'Health Network',
  'Health Payors',
  'Health Preferred',
  'Health Risk',
  'Health Spring',
  'Health Star',
  'Health Sync',
  'Health Win',
  'Healthcare Compare',
  'Healthcare Finest Network',
  'Healthcare Solutions Team',
  'Healthlink',
  'Healthsmart',
  'Healthsping',
  'Heritage',
  'Highland Healthcare',
  'Highland Managed Care',
  'Hinsdale',
  'Hinsdale Physician',
  'Homan Group',
  'Human Affairs International',
  'Humana',
  'IDPA',
  'Illinicare',
  'Illinois Health Connect',
  'Illinois Health Partners',
  'Illinois Healthy Woman',
  'Illinois Medicaid',
  'Imagine Health',
  'Indiana Health Network',
  'Ingalls Provider Group',
  'Innovative Resource Group',
  'Integra',
  'Integrated Health Plan',
  'Integrated Healthcare',
  'Interplan Health Group',
  'John Alden',
  'John Hancock',
  'Kidcare',
  'Laborers Health and Welfare',
  'Land of Lincoln',
  'Lincoln National',
  'Little Company of Mary Health Providers',
  'Loyola University Physician Foundation',
  'MEDCO',
  'MacNeal Health Providers',
  'Magellan',
  'Managed care',
  'Medcare',
  'Medicaid',
  'Medicare',
  'Meridian',
  'Merit',
  'Met Elect',
  'Metlife',
  'Metrahealth',
  'MidAmerica',
  'Molina Healthcare',
  'MoreCare',
  'Motorola',
  'MultiPlan',
  'National Perferred Provider Network',
  'New York Life',
  'Northwestern Healthcare',
  'One Health Plan',
  'Oscar',
  'Perferred Network Access',
  'Preferred Care Network',
  'Preferred Plan',
  'Prime Health',
  'Principal Healthcare',
  'Private HealthCare Systems',
  'Private Healthcare System',
  'Prudential',
  'Rush Prudential',
  'Sagamore Health Network',
  'Sanus Health',
  'State Farm',
  'Sterling Medicare',
  'Stratose',
  'Swedish Covenant Physician Partners',
  'Take Care',
  'The Alliance',
  'Three Rivers Provider Network',
  'Traverlers',
  'Tricare',
  'Trustmark',
  'Unicare',
  'Union Health',
  'United Healthcare',
  'Veteran Affairs',
  'Wellcare',
  'Wellmark',
  'Workers Compensation',
  'Zing',
];

export default ListOfInsurances;
