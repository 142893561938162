export const EmptyIcon = () => (
  <svg
    width="358"
    height="177"
    viewBox="0 0 358 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_473_67440)">
      <rect
        x="18.5"
        y="16.5"
        width="279"
        height="82"
        rx="7.5"
        fill="white"
        stroke="#F7F7F7"
      />
      <rect
        opacity="0.45"
        x="35"
        y="42"
        width="32"
        height="32"
        rx="16"
        fill="#212634"
      />
      <rect
        opacity="0.34"
        x="79"
        y="33"
        width="135"
        height="20"
        rx="4"
        fill="#212634"
      />
      <rect
        opacity="0.12"
        x="79"
        y="62"
        width="88"
        height="20"
        rx="4"
        fill="#212634"
      />
    </g>
    <g filter="url(#filter1_d_473_67440)">
      <rect
        x="60.5"
        y="74.5"
        width="279"
        height="82"
        rx="7.5"
        fill="white"
        stroke="#F7F7F7"
      />
      <rect
        opacity="0.45"
        x="77"
        y="100"
        width="32"
        height="32"
        rx="16"
        fill="#212634"
      />
      <rect
        opacity="0.34"
        x="121"
        y="91"
        width="135"
        height="20"
        rx="4"
        fill="#212634"
      />
      <rect
        opacity="0.12"
        x="121"
        y="120"
        width="88"
        height="20"
        rx="4"
        fill="#212634"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_473_67440"
        x="0"
        y="0"
        width="316"
        height="119"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_473_67440"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_473_67440"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_473_67440"
        x="42"
        y="58"
        width="316"
        height="119"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_473_67440"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_473_67440"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
