import { createContext } from 'react';

export const UserContext = createContext({
  user: null,
  token: null,
  setToken: () => {},
  setUser: () => {},
  setAllDoctors: () => {},
  allDoctors: [],
  loadingState: false,
  setLoadingState: () => {},
  handleLogout: () => {},
  serachDoctorsResult: [],
  setSerachDoctorsResult: () => {},
  allReferrals: [],
  setAllReferrals: () => {},
  socket: null,
  setSocket: () => {},
  chats: [],
  setChats: () => {},
  location: null,
  setLocation: () => {},
  lat: null,
  setLat: () => {},
  long: null,
  setLong: () => {},
  setAcceptReferralDialogue: () => {},
  acceptReferralDialogue: false,
  setPrimary: () => {},
  primary: null,
  setHealthcareEmployer: () => {},
  healthcareEmployer: null,
  setDistance: () => {},
  distance: null,
  insurance: null,
  setInsurance: () => {},
  siteReferralLoading: true,
  chatLoading: true,
  setMessageChannels: () => {},
  messageChannels: [],
});

UserContext.displayName = 'User';

export default UserContext;
