import Close from '../../Icons/close';

const CloseButton = (props) => {
  const { styles, handleClick = () => {} } = props;
  return (
    <div style={{ cursor: 'pointer', ...styles }} onClick={() => handleClick()}>
      <Close />
    </div>
  );
};

export default CloseButton;
