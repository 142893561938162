const ErrorIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33361 13.5002C6.20559 13.5002 6.07758 13.4512 5.98015 13.3537C5.78461 13.1581 5.78461 12.8422 5.98015 12.6467L9.98015 8.64665C10.1757 8.45112 10.4916 8.45112 10.6872 8.64665C10.8827 8.84219 10.8827 9.15814 10.6872 9.35368L6.68718 13.3537C6.58963 13.4512 6.46162 13.5002 6.33361 13.5002Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
    <path
      d="M10.3336 13.5002C10.2056 13.5002 10.0776 13.4512 9.98015 13.3537L5.98015 9.35368C5.78461 9.15815 5.78461 8.8422 5.98015 8.64666C6.17569 8.45111 6.49163 8.45112 6.68718 8.64666L10.6872 12.6467C10.8827 12.8422 10.8827 13.1581 10.6872 13.3537C10.5896 13.4512 10.4616 13.5002 10.3336 13.5002Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
    <path
      d="M15.0815 16.9999H1.5855C0.895553 16.9999 0.333496 16.4384 0.333496 15.7479V3.25246C0.333496 2.56206 0.895553 2 1.58595 2H15.0808C15.7713 2 16.3334 2.56206 16.3334 3.25246V15.7479C16.3334 16.4384 15.7719 16.9999 15.0814 16.9999H15.0815ZM1.58607 2.99989C1.44712 2.99989 1.33361 3.11339 1.33361 3.25234V15.7478C1.33361 15.8868 1.44656 15.9998 1.58562 15.9998H15.0816C15.2207 15.9998 15.3336 15.8868 15.3336 15.7478V3.25234C15.3336 3.11339 15.2201 2.99989 15.0812 2.99989H1.58607Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
    <path
      d="M15.8335 6H0.833496C0.55749 6 0.333496 5.77601 0.333496 5.5C0.333496 5.22399 0.55749 5 0.833496 5H15.8335C16.11 5 16.3335 5.22399 16.3335 5.5C16.3335 5.77601 16.11 6 15.8335 6Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
    <path
      d="M3.3335 4C3.05749 4 2.8335 3.77601 2.8335 3.5V1.5C2.8335 1.22399 3.05749 1 3.3335 1C3.6095 1 3.8335 1.22399 3.8335 1.5V3.5C3.8335 3.77601 3.6095 4 3.3335 4Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
    <path
      d="M13.3335 4C13.057 4 12.8335 3.77601 12.8335 3.5V1.5C12.8335 1.22399 13.057 1 13.3335 1C13.6099 1 13.8335 1.22399 13.8335 1.5V3.5C13.8335 3.77601 13.6099 4 13.3335 4Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
  </svg>
);

export default ErrorIcon;
