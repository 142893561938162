import { Card, Link, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  darkTextColor,
  DisplaySpecialties,
  GetPrimaryUserAddress,
  CapitalizeFirstLetter,
  handleSpeciality,
} from '../../constants';
import HealthBuildingLogo from '../../Icons/healthBuildingLogo';
import { CustomAvatar } from '../customAvatar';

export const Registered = ({ doctor }) => {
  const navigate = useNavigate();
  const userPrimaryAddress =
    GetPrimaryUserAddress(doctor.address) || doctor?.address[0];

  return (
    <Card
      sx={{
        mt: 3,
        p: 2,
        display: 'flex',
        flexDirection: 'row',
      }}
      key={doctor.index}
    >
      <Grid container direction="row" sx={{ flexWrap: 'nowrap' }}>
        <CustomAvatar
          curretDoc={doctor}
          sx={{
            width: '120px',
            height: '130px',
            mr: 2.5,
            justifyContent: 'center',
            display: 'flex',
          }}
        />
        <Grid container direction="column" justifyContent="space-between">
          <Typography color={darkTextColor} variant="h5">
            {CapitalizeFirstLetter(doctor.firstName)}{' '}
            {CapitalizeFirstLetter(doctor.lastName)}
            {doctor?.prefix ? ', ' : ''}
            {doctor?.prefix?.replaceAll(',', ', ') || ''}
          </Typography>
          <Typography
            color={darkTextColor}
            variant="body2"
            sx={{ opacity: '0.75', mt: -2 }}
          >
            {handleSpeciality(
              DisplaySpecialties(doctor.specialties),
              doctor.status,
            )}
          </Typography>
          <Typography color={darkTextColor} variant="body2">
            {userPrimaryAddress?.city && `${userPrimaryAddress?.city}, `}
            {userPrimaryAddress?.state && `${userPrimaryAddress?.state} `}
            {userPrimaryAddress?.postal_code}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItem: 'center',
            }}
            color={'#615B99'}
            variant="body2"
          >
            <HealthBuildingLogo
              fill={doctor.location_name ? '#615B99' : '#212634'}
            />
            &nbsp;
            {doctor.location_name
              ? doctor.location_name
              : 'No healthcare employer found'}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
        }}
        alignItems="center"
      >
        <Link
          color="textSecondary"
          onClick={() => navigate('/login')}
          variant="subtitle2"
          underline="hover"
          sx={{
            cursor: 'pointer',
            whiteSpace: 'nowrap',
          }}
        >
          {'Login'}
        </Link>
      </Grid>
    </Card>
  );
};
