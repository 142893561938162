import Dot from './dot';
import { Box, Container, Typography, Grid, Card, Stack } from '@mui/material';

const bgImage = 'assets/login_map.png';
const loginAvatar = 'assets/login_avatar.png';

const textColor = '#212634';

const MedicalIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.20665 15.0634H9.33189C10.2618 15.0634 11.0189 14.3059 11.0189 13.3756V11.8489H12.5456C13.4759 11.8489 14.2334 11.0918 14.2334 10.1615V9.03624C14.2334 8.10597 13.4759 7.34884 12.546 7.34884H11.0189V5.82209C11.0189 4.89182 10.2618 4.13428 9.33148 4.13428H8.20624C7.27637 4.13428 6.51883 4.89181 6.51883 5.82209V7.34884H4.99209C4.06182 7.34884 3.30469 8.10597 3.30469 9.03624V10.1615C3.30469 11.0918 4.06182 11.8489 4.99209 11.8489H6.51883V13.3756C6.51883 14.3059 7.27637 15.0634 8.20665 15.0634ZM4.99209 10.563C4.77052 10.563 4.59052 10.383 4.59052 10.1614V9.03617C4.59052 8.8146 4.77052 8.6346 4.99209 8.6346H7.16178C7.51735 8.6346 7.80471 8.34725 7.80471 7.99168V5.82198C7.80471 5.60001 7.98471 5.42001 8.20668 5.42001H9.33192C9.55349 5.42001 9.73349 5.60001 9.73349 5.82198V7.99168C9.73349 8.34724 10.0208 8.6346 10.3764 8.6346H12.5461C12.7677 8.6346 12.9477 8.8146 12.9477 9.03617V10.1614C12.9477 10.383 12.7677 10.563 12.5461 10.563H10.3764C10.0209 10.563 9.73349 10.8503 9.73349 11.2059V13.3756C9.73349 13.5976 9.55349 13.7776 9.33152 13.7776H8.20628C7.98471 13.7776 7.80471 13.5976 7.80471 13.3756V11.2059C7.80471 10.8503 7.51736 10.563 7.16178 10.563H4.99209Z"
        fill="#ED9C35"
      />
      <path
        d="M12.5723 17.9088L17.716 18.5493C18.0247 18.5861 18.3198 18.3976 18.4102 18.0973C18.5014 17.7979 18.3626 17.4762 18.0825 17.3362C17.8528 17.2211 17.2918 16.4858 16.596 14.465C17.5158 13.0206 18.0005 11.3618 18.0005 9.64737C18.0005 9.29181 17.7132 9.00445 17.3576 9.00445C17.0021 9.00445 16.7147 9.2918 16.7147 9.64737C16.7147 11.2088 16.2453 12.7163 15.357 14.0086C15.2432 14.1744 15.2129 14.3835 15.2767 14.5744C15.6516 15.7117 15.9975 16.5112 16.3006 17.0778L12.5532 16.6113C12.4341 16.5971 12.3147 16.6153 12.2065 16.6645C11.568 16.9563 10.8956 17.1602 10.2083 17.2675C9.80917 17.3305 9.40236 17.3615 9.00043 17.3615C4.74658 17.3615 1.28581 13.9008 1.28581 9.64693C1.28581 5.39309 4.74658 1.93232 9.00043 1.93232C12.2533 1.93232 15.1733 3.98898 16.2675 7.05022C16.3869 7.38439 16.7542 7.55955 17.0892 7.43847C17.4233 7.31901 17.5977 6.95134 17.4774 6.61676C16.2012 3.04586 12.7946 0.646484 9 0.646484C4.03708 0.646484 0 4.68356 0 9.64648C0 14.6094 4.03708 18.6465 9 18.6465C9.46857 18.6465 9.94117 18.6094 10.4065 18.5371C11.1507 18.4209 11.8784 18.2094 12.5721 17.9087L12.5723 17.9088Z"
        fill="#ED9C35"
      />
    </svg>
  );
};
const LoginMap = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh', width: 'min-content', overflow: 'none', flexWrap: 'nowrap' }}
    >
      <Card
        sx={{
          p: 2,
          pr: 10,
          position: 'relative',
          // bottom: '12%',
          transform: 'translate(10%, 105%)',
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          overflow: 'initial',
          alignSelf: 'flex-end',
        }}
      >
        <div
          style={{
            position: 'absolute',
            // top: '-50%',
            bottom: '-10%',
            overflow: 'auto',
            background: '#FFFFFF',
            height: '21px',
            width: '21px',
            left: '50%',
            transform: 'rotate(-45deg)',
            zIndex: 100,
          }}
        />
        <img src={loginAvatar} style={{ width: '60px', height: '60px' }} />
        <Grid container direction="column" gap={0} justifyContent="center">
          <Typography
            color={textColor}
            gutterBottom
            variant="h5"
            sx={{ margin: 0 }}
          >
            Paul Adams, MD
          </Typography>
          <Typography color={textColor} gutterBottom variant="body2">
            General Surgeon • Chicago, IL
          </Typography>
        </Grid>
      </Card>
      <div
        style={{
          position: 'relative',
          top: '4.35%',
          zIndex: 0,
          alignSelf: 'baseline',
          transform: 'translateX(-50%)',
        }}
      >
        <Dot />
      </div>
      <img
        style={{ width: '27rem', position: 'relative', zIndex: 2 }}
        src={bgImage}
      />
      <Stack
      direction={'row'}
      justifyContent={'space-between'}
        sx={{
          alignSelf: 'flex-end',
        }}
      >
        <Card
          sx={{
            p: 2,
            position: 'relative',
            top: '12%',
            transform: 'translateX(-30%)',
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            overflow: 'none',
            alignSelf: 'flex-start',
            width: '21em'
          }}
        >
          <MedicalIcon />
          <Grid container direction="column">
            <Typography
              color={textColor}
              gutterBottom
              variant="h6"
              sx={{ margin: 0 }}
            >
              New patient referral
            </Typography>
            <Typography color={textColor} gutterBottom variant="body2">
              by Dr. John Williams, 7 hours ago
            </Typography>
          </Grid>
        </Card>
        <div
          style={{
            position: 'relative',
            bottom: '3%',
            zIndex: 0,
            display: 'flex',
            alignSelf: 'flex-end',
            transform: 'translate(45%, -25%)',
          }}
        >
          <Dot />
        </div>
      </Stack>
    </Grid>
  );
};

export default LoginMap;
