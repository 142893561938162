import React, { useState } from 'react';

import GoogleMapReact from 'google-map-react';
import { GetImageFromUrl } from '../../constants';
import MarkerIcon from '../../Icons/markerIcon';
import Marker from './marker';

const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y),
    );
  }
};

const MapUnRegistered = ({ data }) => {
  if (!data?.length) {
    return <></>;
  }
  const getPrimary = data.find((loc) => loc.primary === 'Y') || data[0] || null;
  return (
    <div className="map-container-patient">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyDCshnacXxncQwvgAOdjPVWl6PHYMJK0os',
          language: 'en',
          region: 'US',
        }}
        defaultCenter={{
          lat: Number(getPrimary.latitude),
          lng: Number(getPrimary.longitude),
        }}
        defaultZoom={17}
        distanceToMouse={distanceToMouse}
      >
        {getPrimary ? (
          <Marker
            lat={Number(getPrimary.latitude)}
            lng={Number(getPrimary.longitude)}
          />
        ) : (
          <></>
        )}
      </GoogleMapReact>
    </div>
  );
};

export default MapUnRegistered;
