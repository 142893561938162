import {
  Grid,
  Typography,
  Avatar,
  Stack,
  Button,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import { ArrowBack, Close } from '@mui/icons-material';
import {
  darkTextColor,
  getAddress,
  getSpecialties,
  CapitalizeFirstLetter,
  GetImageFromUrl,
  encryptNumber,
} from '../../constants';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import HealthBuildingLogo from '../../Icons/healthBuildingLogo';
import Available from '../../Icons/available';
import { LikeButton } from '../../components/endorsements/hoverEffect';
import InfoIcon from '../../Icons/infoIcon';

export const UserDetails = ({
  scroll,
  docData,
  isFav,
  favLoading,
  handleFav,
  myProfile,
  endorsements,
  likes,
  RemoveFavorites,
}) => {
  const navigate = useNavigate();

  if (!scroll) {
    return (
      <Stack direction="column" sx={{ transition: 'all 3.5s', height: 'auto' }}>
        <Stack direction="row" gap={1} sx={{ mb: 1 }}>
          <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
            <ArrowBack sx={{ color: darkTextColor }} />
          </IconButton>
          <Typography
            color={darkTextColor}
            variant="h6"
            sx={{ fontWeight: 600 }}
          >
            Go back
          </Typography>
        </Stack>
        <Grid
          container
          direction="row"
          alignItems="space-between"
          sx={{
            flexWrap: 'nowrap',
            height: '9em',
          }}
        >
          <Avatar
            sx={{
              height: '100%',
              maxHeight: '175px',
              minWidth: '120px',
              minHeight: '120px',
              mr: 2.5,
              justifyContent: 'center',
              display: 'flex',
              borderRadius: '4px',
            }}
            src={GetImageFromUrl(docData?.profile_picture_url || '')}
            variant="square"
          >
            {docData.firstName.split()[0]}
          </Avatar>
          <Stack
            direction="column"
            justifyContent="space-between"
            gap={{ md: 0, lg: 1 }}
          >
            <Stack direction="column" justifyContent="space-between">
              <Grid
                sx={{ mt: -1 }}
                container
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Typography
                  fontWeight="600"
                  fontSize="32px"
                  color={darkTextColor}
                  variant="h4"
                >
                  {CapitalizeFirstLetter(docData.firstName)}&nbsp;
                  {CapitalizeFirstLetter(docData.lastName)}
                  {docData?.prefix ? ',' : ''}&nbsp;
                  {docData?.prefix?.replaceAll(',', ', ')}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <LikeButton
                    endorsements={endorsements}
                    RemoveFavorites={RemoveFavorites}
                    sx={{ width: 18, height: 18 }}
                    isFav={isFav}
                    likes={likes}
                    docData={docData}
                  />

                  <Tooltip title="Endorse" arrow>
                    <Box
                      sx={{ mx: 0.5, display: 'flex', alignItems: 'center' }}
                    >
                      <InfoIcon />
                    </Box>
                  </Tooltip>
                </Stack>
              </Grid>
              <Typography
                color={darkTextColor}
                variant="h6"
                fontSize="14px"
                sx={{
                  opacity: '0.75',
                  fontWeight: 400,
                }}
              >
                {getSpecialties(docData.specialties)}
              </Typography>
            </Stack>
            <Typography
              color={darkTextColor}
              variant="h6"
              sx={{ fontWeight: 400, fontSize: '1em' }}
            >
              {getAddress(docData.address)}
            </Typography>
            <Stack alignItems="center" spacing={0.5} direction="row">
              <HealthBuildingLogo
                fill={docData.location_name ? '#615B99' : '#212634'}
              />
              <Typography
                sx={{
                  mt: 1,
                  display: 'flex',
                  alignItem: 'center',
                  opacity: 1,
                }}
                color={docData.location_name ? '#615B99' : '#212634'}
                variant="body2"
              >
                &nbsp;
                {docData.location_name
                  ? docData.location_name
                  : 'No healthcare employer found'}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid sx={{ my: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%' }}
          >
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{ flexWrap: 'nowrap' }}
            >
              {docData.isAvailable ? (
                <div style={{ marginTop: '2px' }}>
                  <Available size={12} />
                </div>
              ) : (
                <div style={{ marginTop: '5px' }}>
                  <Close color="error" />
                </div>
              )}

              <Typography
                color={docData.isAvailable ? 'primary' : 'error'}
                variant="h6"
                fontWeight="400"
                fontSize="14px"
              >
                {docData.isAvailable
                  ? 'Available for new referrals'
                  : 'Not Available for new referrals'}
              </Typography>
            </Stack>
            <Stack direction="row" gap={2} justifyContent="flex-end">
              <Button
                color="primary"
                className="custom-button"
                onClick={() =>
                  navigate(`/messages?doctorId=${encryptNumber(docData.id)}`)
                }
                variant="contained"
                disabled={myProfile}
                sx={{
                  height: 50,
                  bgcolor: 'transparent',
                  border: `1px solid ${darkTextColor}`,
                  color: darkTextColor,
                  fontSize: '14px',
                  lineHeight: '14px',
                  '&:disabled': {
                    border: `1px solid grey`,
                    color: 'grey',
                    opacity: 0.5,
                  },
                  '&:hover': {
                    border: `1px solid ${darkTextColor}`,
                    color: darkTextColor,
                    bgcolor: 'transparent',
                  },
                }}
              >
                Send message
              </Button>
              <Button
                color="primary"
                className="custom-button"
                onClick={() => handleFav()}
                variant="contained"
                disabled={myProfile || favLoading}
                sx={{
                  height: 50,
                  bgcolor: 'transparent',
                  border: `1px solid ${darkTextColor}`,
                  color: darkTextColor,
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  '&:disabled': {
                    border: `1px solid grey`,
                    color: 'grey',
                    opacity: 0.5,
                  },
                  '&:hover': {
                    border: `1px solid ${darkTextColor}`,
                    color: darkTextColor,
                    bgcolor: 'transparent',
                  },
                }}
              >
                {isFav ? (
                  <StarIcon sx={{ color: darkTextColor }} />
                ) : (
                  <StarBorderIcon sx={{ color: darkTextColor }} />
                )}
                Endorse
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    );
  }
  return (
    <Stack direction="column" sx={{ transition: 'all 3.5s', height: 'auto' }}>
      <Stack direction="row" gap={1} sx={{ mb: 1 }}>
        <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
          <ArrowBack sx={{ color: darkTextColor }} />
        </IconButton>
        <Typography color={darkTextColor} variant="h6" sx={{ fontWeight: 600 }}>
          Go back
        </Typography>
      </Stack>

      <Grid
        container
        direction="row"
        alignItems="space-between"
        sx={{
          flexWrap: 'nowrap',
          mb: 1,
        }}
      >
        <Avatar
          sx={{
            height: '65px',
            width: '65px',
            mr: 2.5,
            justifyContent: 'center',
            display: 'flex',
            borderRadius: '4px',
          }}
          src={GetImageFromUrl(docData?.profile_picture_url || '')}
          variant="square"
        >
          {docData.firstName.split()[0]}
        </Avatar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            gap={{ md: 0, lg: 1 }}
          >
            <Stack direction="column" justifyContent="space-between">
              <Grid
                sx={{ mt: -1 }}
                container
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Typography
                  fontWeight="600"
                  fontSize="18px"
                  color={darkTextColor}
                  variant="h4"
                >
                  {CapitalizeFirstLetter(docData.firstName)}&nbsp;
                  {CapitalizeFirstLetter(docData.lastName)},&nbsp;
                  {docData?.prefix?.replaceAll(',', ', ')}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <LikeButton
                    endorsements={endorsements}
                    RemoveFavorites={RemoveFavorites}
                    sx={{ width: 18, height: 18 }}
                    isFav={isFav}
                    likes={likes}
                    docData={docData}
                  />
                  <Tooltip title="Endorse" arrow>
                    <Box
                      sx={{ mx: 0.5, display: 'flex', alignItems: 'center' }}
                    >
                      <InfoIcon />
                    </Box>
                  </Tooltip>
                </Stack>
              </Grid>
              <Typography
                color={darkTextColor}
                variant="h6"
                fontSize="14px"
                sx={{
                  opacity: '0.75',
                  fontWeight: 400,
                }}
              >
                {getSpecialties(docData.specialties)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                sx={{ flexWrap: 'nowrap' }}
              >
                {docData.isAvailable ? (
                  <div style={{ marginTop: '2px' }}>
                    <Available size={12} />
                  </div>
                ) : (
                  <div style={{ marginTop: '5px' }}>
                    <Close color="error" />
                  </div>
                )}

                <Typography
                  color={docData.isAvailable ? 'primary' : 'error'}
                  variant="h6"
                  fontWeight="400"
                  fontSize="14px"
                >
                  {docData.isAvailable
                    ? 'Available for new referrals'
                    : 'Not Available for new referrals'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" gap={2} justifyContent="flex-end">
            <Button
              color="primary"
              className="custom-button"
              onClick={() =>
                navigate(`/messages?doctorId=${encryptNumber(docData.id)}`)
              }
              variant="contained"
              disabled={myProfile}
              sx={{
                height: 40,
                bgcolor: 'transparent',
                border: `1px solid ${darkTextColor}`,
                color: darkTextColor,
                fontSize: '14px',
                lineHeight: '14px',
                '&:disabled': {
                  border: `1px solid grey`,
                  color: 'grey',
                  opacity: 0.5,
                },
                '&:hover': {
                  border: `1px solid ${darkTextColor}`,
                  color: darkTextColor,
                  bgcolor: 'transparent',
                },
              }}
            >
              Send message
            </Button>
            <Button
              color="primary"
              className="custom-button"
              onClick={() => handleFav()}
              variant="contained"
              disabled={myProfile || favLoading}
              sx={{
                height: 40,
                bgcolor: 'transparent',
                border: `1px solid ${darkTextColor}`,
                color: darkTextColor,
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                '&:disabled': {
                  border: `1px solid grey`,
                  color: 'grey',
                  opacity: 0.5,
                },
                '&:hover': {
                  border: `1px solid ${darkTextColor}`,
                  color: darkTextColor,
                  bgcolor: 'transparent',
                },
              }}
            >
              {isFav ? (
                <StarIcon sx={{ color: darkTextColor }} />
              ) : (
                <StarBorderIcon sx={{ color: darkTextColor }} />
              )}
              Endorse
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Stack>
  );
};

export const UserDetails2 = ({
  scroll,
  docData,
  isFav,
  favLoading,
  handleFav,
  myProfile,
  endorsements,
  likes,
  RemoveFavorites,
}) => {
  const navigate = useNavigate();

  if (true) {
    return (
      <Stack direction="column" sx={{ transition: 'all 3.5s', height: 'auto' }}>
        <Stack direction="row" gap={1} sx={{ mb: 1 }}>
          <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
            <ArrowBack sx={{ color: darkTextColor }} />
          </IconButton>
          <Typography
            color={darkTextColor}
            variant="h6"
            sx={{ fontWeight: 600 }}
          >
            Go back
          </Typography>
        </Stack>
        <Grid
          container
          direction="row"
          alignItems="space-between"
          sx={{
            flexWrap: 'nowrap',
            height: !scroll ? '9em' : '6em',
            width: '100%',
            transition: 'all 100ms',
          }}
        >
          <Avatar
            sx={{
              height: '100%',
              maxHeight: !scroll ? '175px' : '65px',
              minWidth: !scroll ? '120px' : '65px',
              minHeight: !scroll ? '120px' : '65px',
              mr: 2.5,
              justifyContent: 'center',
              display: 'flex',
              borderRadius: '4px',
            }}
            src={GetImageFromUrl(docData?.profile_picture_url || '')}
            variant="square"
          >
            {docData.firstName.split()[0]}
          </Avatar>

          {!scroll ? (
            <Stack
              direction="column"
              justifyContent="space-between"
              gap={{ md: 0, lg: 1 }}
            >
              <Stack direction="column" justifyContent="space-between">
                <Grid
                  sx={{ mt: -1 }}
                  container
                  direction="row"
                  alignItems="center"
                  gap={1}
                >
                  <Typography
                    fontWeight="600"
                    fontSize={!scroll ? '32px' : '18px'}
                    color={darkTextColor}
                    variant="h4"
                  >
                    {CapitalizeFirstLetter(docData.firstName)}&nbsp;
                    {CapitalizeFirstLetter(docData.lastName)}, &nbsp;
                    {docData?.prefix?.replaceAll(',', ', ')}
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={0}>
                    <LikeButton
                      endorsements={endorsements}
                      RemoveFavorites={RemoveFavorites}
                      sx={{ width: 18, height: 18 }}
                      isFav={isFav}
                      likes={likes}
                      docData={docData}
                    />
                    <Tooltip title="Endorse" arrow>
                      <Box
                        sx={{ mx: 0.5, display: 'flex', alignItems: 'center' }}
                      >
                        <InfoIcon />
                      </Box>
                    </Tooltip>
                  </Stack>
                </Grid>
                <Typography
                  color={darkTextColor}
                  variant="h6"
                  fontSize="14px"
                  sx={{
                    opacity: '0.75',
                    fontWeight: 400,
                  }}
                >
                  {getSpecialties(docData.specialties)}
                </Typography>
                {!scroll ? (
                  <></>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="center"
                      sx={{ flexWrap: 'nowrap' }}
                    >
                      {docData.isAvailable ? (
                        <div style={{ marginTop: '2px' }}>
                          <Available size={12} />
                        </div>
                      ) : (
                        <div style={{ marginTop: '5px' }}>
                          <Close color="error" />
                        </div>
                      )}

                      <Typography
                        color={docData.isAvailable ? 'primary' : 'error'}
                        variant="h6"
                        fontWeight="400"
                        fontSize="14px"
                      >
                        {docData.isAvailable
                          ? 'Available for new referrals'
                          : 'Not Available for new referrals'}
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </Stack>
              {!scroll ? (
                <>
                  <Typography
                    color={darkTextColor}
                    variant="h6"
                    sx={{ fontWeight: 400, fontSize: '1em' }}
                  >
                    {getAddress(docData.address)}
                  </Typography>
                  <Stack alignItems="center" spacing={0.5} direction="row">
                    <HealthBuildingLogo
                      fill={docData.location_name ? '#615B99' : '#212634'}
                    />
                    <Typography
                      sx={{
                        mt: 1,
                        display: 'flex',
                        alignItem: 'center',
                        opacity: 1,
                      }}
                      color={docData.location_name ? '#615B99' : '#212634'}
                      variant="body2"
                    >
                      &nbsp;
                      {docData.location_name
                        ? docData.location_name
                        : 'No healthcare employer found'}
                    </Typography>
                  </Stack>
                </>
              ) : (
                <></>
              )}
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Stack
                direction="column"
                justifyContent="space-between"
                gap={{ md: 0, lg: 1 }}
              >
                <Stack direction="column" justifyContent="space-between">
                  <Grid
                    sx={{ mt: -1 }}
                    container
                    direction="row"
                    alignItems="center"
                    gap={1}
                  >
                    <Typography
                      fontWeight="600"
                      fontSize="18px"
                      color={darkTextColor}
                      variant="h4"
                    >
                      {CapitalizeFirstLetter(docData.firstName)}&nbsp;
                      {CapitalizeFirstLetter(docData.lastName)}, &nbsp;
                      {docData?.prefix?.replaceAll(',', ', ')}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={0}>
                      <LikeButton
                        endorsements={endorsements}
                        RemoveFavorites={RemoveFavorites}
                        sx={{ width: 18, height: 18 }}
                        isFav={isFav}
                        likes={likes}
                        docData={docData}
                      />
                      <Tooltip title="Endorse" arrow>
                        <Box
                          sx={{
                            mx: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <InfoIcon />
                        </Box>
                      </Tooltip>
                    </Stack>
                  </Grid>
                  <Typography
                    color={darkTextColor}
                    variant="h6"
                    fontSize="14px"
                    sx={{
                      opacity: '0.75',
                      fontWeight: 400,
                    }}
                  >
                    {getSpecialties(docData.specialties)}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ width: '100%' }}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{ flexWrap: 'nowrap' }}
                  >
                    {docData.isAvailable ? (
                      <div style={{ marginTop: '2px' }}>
                        <Available size={12} />
                      </div>
                    ) : (
                      <div style={{ marginTop: '5px' }}>
                        <Close color="error" />
                      </div>
                    )}

                    <Typography
                      color={docData.isAvailable ? 'primary' : 'error'}
                      variant="h6"
                      fontWeight="400"
                      fontSize="14px"
                    >
                      {docData.isAvailable
                        ? 'Available for new referrals'
                        : 'Not Available for new referrals'}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction="row" gap={2} justifyContent="flex-end">
                <Button
                  color="primary"
                  className="custom-button"
                  onClick={() =>
                    navigate(`/messages?doctorId=${encryptNumber(docData.id)}`)
                  }
                  variant="contained"
                  disabled={myProfile}
                  sx={{
                    height: 40,
                    bgcolor: 'transparent',
                    border: `1px solid ${darkTextColor}`,
                    color: darkTextColor,
                    fontSize: '14px',
                    '&:disabled': {
                      border: `1px solid grey`,
                      color: 'grey',
                      opacity: 0.5,
                    },
                    '&:hover': {
                      border: `1px solid ${darkTextColor}`,
                      color: darkTextColor,
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  Send message
                </Button>
                <Button
                  color="primary"
                  className="custom-button"
                  onClick={() => handleFav()}
                  variant="contained"
                  disabled={myProfile || favLoading}
                  sx={{
                    height: 40,
                    bgcolor: 'transparent',
                    border: `1px solid ${darkTextColor}`,
                    color: darkTextColor,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    '&:disabled': {
                      border: `1px solid grey`,
                      color: 'grey',
                      opacity: 0.5,
                    },
                    '&:hover': {
                      border: `1px solid ${darkTextColor}`,
                      color: darkTextColor,
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  {isFav ? (
                    <StarIcon sx={{ color: darkTextColor }} />
                  ) : (
                    <StarBorderIcon sx={{ color: darkTextColor }} />
                  )}
                  Endorse
                </Button>
              </Stack>
            </Stack>
          )}
        </Grid>
        {!scroll ? (
          <Grid sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                sx={{ flexWrap: 'nowrap' }}
              >
                {docData.isAvailable ? (
                  <div style={{ marginTop: '2px' }}>
                    <Available size={12} />
                  </div>
                ) : (
                  <div style={{ marginTop: '5px' }}>
                    <Close color="error" />
                  </div>
                )}

                <Typography
                  color={docData.isAvailable ? 'primary' : 'error'}
                  variant="h6"
                  fontWeight="400"
                  fontSize="14px"
                >
                  {docData.isAvailable
                    ? 'Available for new referrals'
                    : 'Not Available for new referrals'}
                </Typography>
              </Stack>
              <Stack direction="row" gap={2} justifyContent="flex-end">
                <Button
                  color="primary"
                  className="custom-button"
                  onClick={() =>
                    navigate(`/messages?doctorId=${encryptNumber(docData.id)}`)
                  }
                  variant="contained"
                  disabled={myProfile}
                  sx={{
                    height: 50,
                    bgcolor: 'transparent',
                    border: `1px solid ${darkTextColor}`,
                    color: darkTextColor,
                    fontSize: '14px',
                    '&:disabled': {
                      border: `1px solid grey`,
                      color: 'grey',
                      opacity: 0.5,
                    },
                    '&:hover': {
                      border: `1px solid ${darkTextColor}`,
                      color: darkTextColor,
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  Send message
                </Button>
                <Button
                  color="primary"
                  className="custom-button"
                  onClick={() => handleFav()}
                  variant="contained"
                  disabled={myProfile || favLoading}
                  sx={{
                    height: 50,
                    bgcolor: 'transparent',
                    border: `1px solid ${darkTextColor}`,
                    color: darkTextColor,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '14px',
                    '&:disabled': {
                      border: `1px solid grey`,
                      color: 'grey',
                      opacity: 0.5,
                    },
                    '&:hover': {
                      border: `1px solid ${darkTextColor}`,
                      color: darkTextColor,
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  {isFav ? (
                    <StarIcon sx={{ color: darkTextColor }} />
                  ) : (
                    <StarBorderIcon sx={{ color: darkTextColor }} />
                  )}
                  Endorse
                </Button>
              </Stack>
            </Stack>
          </Grid>
        ) : (
          <></>
        )}
      </Stack>
    );
  }
  return (
    <Stack direction="column" sx={{ transition: 'all 3.5s', height: 'auto' }}>
      <Stack direction="row" gap={1} sx={{ mb: 1 }}>
        <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
          <ArrowBack sx={{ color: darkTextColor }} />
        </IconButton>
        <Typography color={darkTextColor} variant="h6" sx={{ fontWeight: 600 }}>
          Go back
        </Typography>
      </Stack>

      <Grid
        container
        direction="row"
        alignItems="space-between"
        sx={{
          flexWrap: 'nowrap',
          mb: 1,
        }}
      >
        <Avatar
          sx={{
            height: '65px',
            width: '65px',
            mr: 2.5,
            justifyContent: 'center',
            display: 'flex',
            borderRadius: '4px',
          }}
          src={GetImageFromUrl(docData?.profile_picture_url || '')}
          variant="square"
        >
          {docData.firstName.split()[0]}
        </Avatar>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Stack
            direction="column"
            justifyContent="space-between"
            gap={{ md: 0, lg: 1 }}
          >
            <Stack direction="column" justifyContent="space-between">
              <Grid
                sx={{ mt: -1 }}
                container
                direction="row"
                alignItems="center"
                gap={1}
              >
                <Typography
                  fontWeight="600"
                  fontSize="18px"
                  color={darkTextColor}
                  variant="h4"
                >
                  {CapitalizeFirstLetter(docData.firstName)}&nbsp;
                  {CapitalizeFirstLetter(docData.lastName)}, &nbsp;
                  {docData?.prefix?.replaceAll(',', ', ')}
                </Typography>
                <Stack direction="row" alignItems="center" spacing={0}>
                  <LikeButton
                    endorsements={endorsements}
                    RemoveFavorites={RemoveFavorites}
                    sx={{ width: 18, height: 18 }}
                    isFav={isFav}
                    likes={likes}
                    docData={docData}
                  />
                  <Typography
                    fontSize="14px"
                    sx={{ ml: -0.5 }}
                    color={'#E5A700'}
                  >
                    {likes}
                  </Typography>
                  <Tooltip title="Endorse" arrow>
                    <Box
                      sx={{ mx: 0.5, display: 'flex', alignItems: 'center' }}
                    >
                      <InfoIcon />
                    </Box>
                  </Tooltip>
                </Stack>
              </Grid>
              <Typography
                color={darkTextColor}
                variant="h6"
                fontSize="14px"
                sx={{
                  opacity: '0.75',
                  fontWeight: 400,
                }}
              >
                {getSpecialties(docData.specialties)}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                sx={{ flexWrap: 'nowrap' }}
              >
                {docData.isAvailable ? (
                  <div style={{ marginTop: '2px' }}>
                    <Available size={12} />
                  </div>
                ) : (
                  <div style={{ marginTop: '5px' }}>
                    <Close color="error" />
                  </div>
                )}

                <Typography
                  color={docData.isAvailable ? 'primary' : 'error'}
                  variant="h6"
                  fontWeight="400"
                  fontSize="14px"
                >
                  {docData.isAvailable
                    ? 'Available for new referrals'
                    : 'Not Available for new referrals'}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" gap={2} justifyContent="flex-end">
            <Button
              color="primary"
              className="custom-button"
              onClick={() =>
                navigate(`/messages?doctorId=${encryptNumber(docData.id)}`)
              }
              variant="contained"
              disabled={myProfile}
              sx={{
                height: 40,
                bgcolor: 'transparent',
                border: `1px solid ${darkTextColor}`,
                color: darkTextColor,
                fontSize: '14px',
                '&:disabled': {
                  border: `1px solid grey`,
                  color: 'grey',
                  opacity: 0.5,
                },
                '&:hover': {
                  border: `1px solid ${darkTextColor}`,
                  color: darkTextColor,
                  bgcolor: 'transparent',
                },
              }}
            >
              Send message
            </Button>
            <Button
              color="primary"
              className="custom-button"
              onClick={() => handleFav()}
              variant="contained"
              disabled={myProfile || favLoading}
              sx={{
                height: 40,
                bgcolor: 'transparent',
                border: `1px solid ${darkTextColor}`,
                color: darkTextColor,
                display: 'flex',
                alignItems: 'center',
                fontSize: '14px',
                '&:disabled': {
                  border: `1px solid grey`,
                  color: 'grey',
                  opacity: 0.5,
                },
                '&:hover': {
                  border: `1px solid ${darkTextColor}`,
                  color: darkTextColor,
                  bgcolor: 'transparent',
                },
              }}
            >
              {isFav ? (
                <StarIcon sx={{ color: darkTextColor }} />
              ) : (
                <StarBorderIcon sx={{ color: darkTextColor }} />
              )}
              Endorse
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </Stack>
  );
};
