const Dot = () => (
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 120 0)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 104 0)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 88 0)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 72 0)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 56 0)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 40 0)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 24 0)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 8 0)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 120 16)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 104 16)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 88 16)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 72 16)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 56 16)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 40 16)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 24 16)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 8 16)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 120 32)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 104 32)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 88 32)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 72 32)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 56 32)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 40 32)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 24 32)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 8 32)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 120 48)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 104 48)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 88 48)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 72 48)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 56 48)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 40 48)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 24 48)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 8 48)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 120 64)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 104 64)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 88 64)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 72 64)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 56 64)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 40 64)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 24 64)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 8 64)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 120 80)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 104 80)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 88 80)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 72 80)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 56 80)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 40 80)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 24 80)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 8 80)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 120 96)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 104 96)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 88 96)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 72 96)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 56 96)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 40 96)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 24 96)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 8 96)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 120 112)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 104 112)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 88 112)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 72 112)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 56 112)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 40 112)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 24 112)"
      fill="#78CDAF"
    />
    <rect
      width="8"
      height="8"
      rx="2"
      transform="matrix(-1 0 0 1 8 112)"
      fill="#78CDAF"
    />
  </svg>
);

export default Dot;
