const Close = ({ size = 32 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="4" fill="white" />
    <path
      d="M24 9.61143L22.3886 8L16 14.3886L9.61143 8L8 9.61143L14.3886 16L8 22.3886L9.61143 24L16 17.6114L22.3886 24L24 22.3886L17.6114 16L24 9.61143Z"
      fill="#212634"
    />
  </svg>
);

export default Close;
