import React, { useState } from 'react';
import AppDrawer from '../../../components/appDrawer';
import DoctorInfo from './doctorInfo';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Grid } from '@mui/material';
import PatientWraper from './patientWraper';
import dayjs from 'dayjs';
import Patients from '../../../services/patients';
import { CapitalizeFirstLetter, RESPONSE_STATUS } from '../../../constants';
import { CustomButton } from '../../../components/customButton';
import { Cancel } from '@mui/icons-material';
import CancelPrompt from './cancelPrompt';
import SuccessPrompt from './successPrompt';
import moment from 'moment';

const getCurrentStaff = (user) => {
  if (user?.staffs?.length) {
    return Array(user.staffs.length)
      .fill()
      .map((_, i) => true);
  }
  return [];
};

const gender = ['male', 'female'];
const urgency = ['Routine', 'Semi-urgent', 'Urgent'];

const ReferralPatient = (props) => {
  const { token, anchor, setAnchor, referralData, user, files, setFiles } =
    props;
  const [page, setPage] = useState(0);
  const [doctorEmail, setDoctorEmail] = useState();
  const [patientDetails, setPatientDetails] = useState();

  const [openDialog, setOpenDialog] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  Yup.addMethod(Yup.string, 'higherDate', function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
      const { path, createError } = this;
      const varDate = new Date(value); //dd-mm-YYYY
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return (
        (value && varDate <= today) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  Yup.addMethod(Yup.string, 'validateDate', function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
      const { path, createError } = this;
      var date_regex =
        /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

      return (
        (value &&
          moment(value?.replaceAll(' ', ''), 'MM/DD/YYYY', true).isValid()) ||
        createError({ path, message: errorMessage })
      );
    });
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phoneNumber: '',
      patientEmail: '',
      canForward: true,
      sex: gender[1],
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      patientEmail: Yup.string('')
        .email('Patient email should be valid.')
        .max(255, 'Must be less than 255 characters')
        .nullable(),
      sex: Yup.string(),
      phoneNumber: Yup.string()
        .required('Phone Number is required')
        .length(14, 'Must be exactly 10 digits'),
      canForward: Yup.boolean(),
      dateOfBirth: Yup.string('Date of birth is required')
        .required('Date of birth is invalid')
        .validateDate('Date should be valid')
        .nullable()
        .higherDate('Date of birth should be less than current date'),
    }),
    onSubmit: (values) => {
      const tempValue = values;
      setPatientDetails(tempValue);
      setPage(page + 1);
      const ele = document.getElementById('patient-wraper-container');
      if (ele) {
        ele.scrollTop = 0;
      }
    },
  });

  const formikReferralDetail = useFormik({
    initialValues: {
      reason: '',
      history: '',
      levelOfUrgency: urgency[0],
      report: '',
    },
    validationSchema: Yup.object({
      reason: Yup.string().required('Reason for referral is required'),
      history: Yup.string().required('History of present illness is required'),
      report: Yup.string(),
    }),
    onSubmit: async (values) => {
      // setPatientDetails(values);

      try {
        const response = await Patients.post(
          token,
          user.id,
          referralData.id,
          doctorEmail.email,
          patientDetails.firstName,
          patientDetails.lastName,
          patientDetails.dateOfBirth,
          patientDetails.phoneNumber,
          patientDetails.patientEmail,
          patientDetails.sex,
          patientDetails.canForward,
          values.reason,
          values.history,
          values.levelOfUrgency,
          values.report,
          true, //add toggle
        );

        if (response.status === RESPONSE_STATUS.error) {
          // setErrorMessage(response.message);
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          // setErrorMessage(response.message);
          setIsSuccess(true);
          setSuccessMessage(
            `Invitation to medLynks and notification of patient referral sent to ${CapitalizeFirstLetter(
              referralData?.firstName,
            )} ${CapitalizeFirstLetter(
              referralData?.lastName,
            )}. Please note that registration is necessary to view patient details and accept the referral, for security purposes`,
          );
          return;
        }
      } catch (e) {
        console.log(e.message);
        return;
      }
    },
  });

  const formikDoctorEmail = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email must be valid')
        .required('Email is required')
        .max(255, 'Email should be less than 255 characters'),
    }),
    onSubmit: async (values) => {
      setDoctorEmail(values);
      setPage(page + 1);
    },
  });

  const handleNext = async () => {
    if (page === 0) {
      formikDoctorEmail.handleSubmit();
      return;
    }
    if (page === 1) {
      formik.handleSubmit();
      return;
    }
    formikReferralDetail.handleSubmit();
  };

  const handleCancel = () => {
    setOpenDialog(false);
    formik.resetForm();
    formik.setTouched({}, false);
    formikReferralDetail.setTouched({}, false);
    formikReferralDetail.resetForm();
    formikDoctorEmail.setTouched({}, false);
    formikDoctorEmail.resetForm();
    setFiles([]);
    setDoctorEmail('');
    setIsSuccess(false);
    setSuccessMessage('');
    setAnchor(false);
    setPage(0);
  };
  return (
    <>
      <SuccessPrompt
        openDialog={isSuccess}
        setOpenDialog={setIsSuccess}
        successMessage={successMessage}
        handleCancel={handleCancel}
      />
      <CancelPrompt
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        formik={formik}
        setFiles={setFiles}
        formikReferralDetail={formikReferralDetail}
        setAnchor={setAnchor}
        setPage={setPage}
        setDoctorEmail={setDoctorEmail}
        formikDoctorEmail={formikDoctorEmail}
      />
      <AppDrawer
        title="Refer a patient"
        anchor={anchor}
        setAnchor={() => setOpenDialog(true)}
      >
        {page === 0 ? (
          <DoctorInfo docData={referralData} formik={formikDoctorEmail} />
        ) : (
          <></>
        )}
        {page > 0 ? (
          <PatientWraper
            docData={referralData}
            formik={formik}
            formikReferralDetail={formikReferralDetail}
            page={page}
            files={files}
            setFiles={setFiles}
          />
        ) : (
          <></>
        )}

        <Grid
          container
          gap={1.5}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            flexWrap: 'nowrap',
            p: 2,
            mt: 1,
            position: 'absolute',
            bottom: '0px',
            right: 0,
            height: 'auto',
            width: '100%',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Button
            color="error"
            size="small"
            onClick={() => setOpenDialog(true)}
            variant="contained"
            sx={{
              height: 40,
              flexGrow: 1,
              bgcolor: 'rgba(246,93,94, 0.2) !important',
              color: 'rgb(246,93,94)',
            }}
          >
            Cancel
          </Button>

          {page === 2 ? (
            <Button
              color="error"
              size="small"
              onClick={() => setPage(1)}
              variant="contained"
              sx={{
                height: 40,
                flexGrow: 1,
                bgcolor: 'rgba(47, 47, 47, 0.14) !important',
                color: '#2F2F2F',
              }}
            >
              Back
            </Button>
          ) : (
            <></>
          )}

          <Button
            color="primary"
            size="large"
            onClick={() => handleNext()}
            variant="contained"
            sx={{ flexGrow: 4, height: 40 }}
          >
            {/* Next */}
            {page === 2 ? 'Send Email' : 'Next'}
          </Button>
        </Grid>
      </AppDrawer>
    </>
  );
};

export default ReferralPatient;
