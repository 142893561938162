import configuration from '../config';
import { PatientStatus, PATIENTS_ROUTE } from '../constants/index';

const post = async (
  token,
  doctorId,
  referredToId,
  doctorEmail,
  firstname,
  lastname,
  dateOfBirth,
  phoneNumber,
  patientEmail,
  sex,
  canForward,
  reason,
  history,
  levelOfUrgency,
  report,
  canRefer,
  files,
) => {
  const formData = new FormData();

  formData.append('doctorId', doctorId.toString());
  formData.append('referredToId', referredToId.toString());
  formData.append('doctorEmail', doctorEmail);
  formData.append('firstname', firstname);
  formData.append('lastname', lastname);
  formData.append('dateOfBirth', dateOfBirth);
  formData.append('phoneNumber', phoneNumber);
  formData.append('patientEmail', patientEmail);
  formData.append('sex', sex);
  formData.append('canForward', canForward);
  formData.append('reason', reason?.trim());
  formData.append('history', history?.trim());
  formData.append('levelOfUrgency', levelOfUrgency);
  formData.append('report', report);
  formData.append('canRefer', canForward);

  if (files) {
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
  }

  const response = await fetch(`${configuration.api.url}/${PATIENTS_ROUTE}`, {
    method: 'POST',
    body: formData,

    headers: {
      Authorization: `Bearer ${token}`,
      'Max-Forwards': '0',
    },
  });

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const get = async (token) => {
  const response = await fetch(`${configuration.api.url}/${PATIENTS_ROUTE}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Max-Forwards': '0',
    },
    method: 'GET',
  });

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getPastRef = async (token) => {
  const response = await fetch(
    `${configuration.api.url}/${PATIENTS_ROUTE}/history/past`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getById = async (token, id) => {
  const response = await fetch(
    `${configuration.api.url}/${PATIENTS_ROUTE}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const del = async (token, id) => {
  const response = await fetch(
    `${configuration.api.url}/${PATIENTS_ROUTE}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'DELETE',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const put = async (
  token,
  id,
  status,
  reasonToDecline,
  additionalComments,
  referredTo,
  selectedStaff,
) => {
  const urlencoded = new URLSearchParams();

  urlencoded.append('status', status);
  urlencoded.append('additionalComments', additionalComments);

  if (status === PatientStatus.Decline || status === PatientStatus.Referred) {
    urlencoded.append('reasonToDecline', reasonToDecline);
  }
  if (status === PatientStatus.Referred) {
    urlencoded.append('referredTo', referredTo);
  }
  if (selectedStaff) {
    urlencoded.append('selectedStaff', JSON.stringify(selectedStaff));
  }
  const response = await fetch(
    `${configuration.api.url}/${PATIENTS_ROUTE}/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      body: urlencoded,
      method: 'PUT',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const Patients = {
  post,
  put,
  get,
  del,
  getById,
  getPastRef,
};

export default Patients;
