import { Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useContext } from 'react';
import UserContext from '../../contexts/user/context';

import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import Favorites from '../../services/favorites';
import { darkTextColor, RESPONSE_STATUS } from '../../constants';
import { useEffect } from 'react';

const checkFav = (favList, userId) => {
  if (favList.length) {
    const findFav = favList.find((f) => f.favoriteId === userId);
    if (findFav) {
      return true;
    }
  }
  return false;
};

export const EndorseButton = ({
  doctorId,
  totalLikes,
  doctorEndorsements,
  mainColor = '#E5A700',
}) => {
  const { user, token, setUser } = useContext(UserContext);
  const [isFav, setIsFav] = useState();
  const [favLoading, setFavLoading] = useState(false);
  const [likes, setLikes] = useState(totalLikes || 0);
  const [endorsements, setEndorsements] = useState(doctorEndorsements || []);

  const updateFav = (favorites) => {
    const fav = checkFav(favorites, doctorId);
    setIsFav(fav);
  };

  const AddFavorites = async () => {
    try {
      setFavLoading(true);
      const response = await Favorites.post(token, doctorId);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setUser(response.body);
        setLikes(likes + 1);
        const tempEndorsement = [...endorsements];
        tempEndorsement.unshift({
          createdAt: new Date(),
          doctorId: user.id,
          FavoriteBy: {
            firstName: user.firstName,
            lastName: user.lastName,
            prefix: user.prefix,
            profile_picture_url: user.profile_picture_url,
          },
        });
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  useEffect(() => {
    if (doctorId && user) {
      const getFav = checkFav(user.favorites, doctorId);
      setIsFav(getFav);
    }
  }, [doctorId, user]);

  const RemoveFavorites = async () => {
    try {
      setFavLoading(true);

      const response = await Favorites.put(token, doctorId);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setUser(response.body);
        setLikes(likes - 1);
        const tempEndorsement = endorsements.filter(
          (end) => end.doctorId !== user.id,
        );
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };
  const handleFav = () => {
    if (!isFav) {
      AddFavorites();
    } else {
      RemoveFavorites();
    }
  };
  return (
    <Tooltip title={isFav ? 'Remove endorsement' : 'Endorse'}>
      <Button
        color="primary"
        className="custom-button"
        onClick={() => handleFav()}
        variant="contained"
        disabled={favLoading}
        sx={{
          height: 40,
          bgcolor: 'transparent',
          border: `1px solid ${mainColor}`,
          color: mainColor,
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
          '&:disabled': {
            border: `1px solid grey`,
            color: 'grey',
            opacity: 0.5,
          },
          '&:hover': {
            border: `1px solid ${mainColor}`,
            color: mainColor,
            bgcolor: 'transparent',
          },
        }}
      >
        {isFav ? (
          <StarIcon sx={{ color: mainColor }} />
        ) : (
          <StarBorderIcon sx={{ color: mainColor }} />
        )}
        Endorse
      </Button>
    </Tooltip>
  );
};
