import {
  Box,
  Button,
  Link,
  Typography,
  Grid,
  Card,
  Tab,
  Stack,
  Avatar,
} from '@mui/material';
import CustomInputField from '../../../components/customInputField';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  GetImageFromUrl,
} from '../../../constants';
import HealthBuildingLogo from '../../../Icons/healthBuildingLogo';

const DoctorInfo = ({ docData, formik }) => {
  const avatar = GetImageFromUrl(docData?.profile_picture_url);

  if(!docData) {
    return <></>
  }
  
  return (
    <form>
      <Grid container direction="column" sx={{ flexWrap: 'nowrap' }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          gap={1}
          sx={{
            p: 2,
            py: 4,
            my: 2,
            border: '1px solid #E2E2E2',
            borderRadius: '4px',
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', mb: 2 }}
          >
            <Avatar
              sx={{
                width: '120px',
                height: '130px',
                justifyContent: 'center',
                display: 'flex',
              }}
              src={avatar}
              variant="square"
            />
          </Stack>
          <Stack
            direction="column"
            sx={{ width: '75%', textAlign: 'center' }}
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{ width: '100%', textAlign: 'center' }}
              color={darkTextColor}
              variant="h5"
            >
              {CapitalizeFirstLetter(docData.firstName)}{' '}
              {CapitalizeFirstLetter(docData.lastName)}
              &nbsp;
              {docData?.preffix?.replaceAll(',', ', ') || ''}
            </Typography>

            <Typography
              color={darkTextColor}
              variant="body2"
              sx={{ opacity: '0.75', width: '100%', textAlign: 'center' }}
            >
              {docData.specialty}
            </Typography>
            <Stack>
              <Typography
                sx={{ m: 0, p: 0 }}
                color={darkTextColor}
                variant="body2"
              >
                {docData.street}
              </Typography>
              <Typography
                sx={{ m: 0, p: 0 }}
                color={darkTextColor}
                variant="body2"
              >
                {docData.city ? `${docData.city}, ` : ''}
                {docData.state ? `${docData.state} ` : ''} {docData.postalCode}
              </Typography>
            </Stack>
            <Typography
              sx={{
                mt: 1,
                display: 'flex',
                alignItem: 'center',
                opacity: docData.location ? 1 : 0.56,
                textAlign: 'center',
              }}
              color={docData.location ? '#615B99' : '#212634'}
              variant="body2"
            >
              <HealthBuildingLogo
                fill={docData.location ? '#615B99' : '#212634'}
              />
              &nbsp;
              {docData.location || 'No healthcare employer found'}
            </Typography>
          </Stack>
        </Grid>
        <Grid
          container
          gap={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            border: '1px solid #E2E2E2',
            p: 2,
            borderRadius: '4px',
            mt: 2,
            flexWrap: 'nowrap',
          }}
        >
          <Grid container direction="column">
            <Typography color={darkTextColor} gutterBottom variant="body1">
              Please enter {docData.firstName} {docData.lastName}’s work email
              below
            </Typography>
            <CustomInputField
              error={Boolean(formik.touched.email && formik.errors.email)}
              label="Work email"
              fullWidth
              width="100%"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              placeholder="example@email.com"
              value={formik.values.email}
            />
            {Boolean(formik.touched.email && formik.errors.email) && (
              <Typography color="error" gutterBottom variant="body2">
                {formik.touched.email && formik.errors.email}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default DoctorInfo;
