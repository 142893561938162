import { CircularProgress, Stack } from '@mui/material';

export const LoadingCircularProgress = () => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100vw', height: '100vh', overflow: 'hidden' }}
    >
      <CircularProgress color="primary" />;
    </Stack>
  );
};

export const LoadingCircularProgressSized = () => {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ py: 4 }}>
      <CircularProgress color="primary" />;
    </Stack>
  );
};
