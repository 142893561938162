import React, { useState, useEffect } from 'react';
import AutoComplete from 'react-autocomplete';
import { Typography } from '@mui/material';
import { darkTextColor, RESPONSE_STATUS } from '../../constants';
import Doctors from '../../services/doctors';
import { useContext } from 'react';
import UserContext from '../../contexts/user/context';

const LocationSearch = ({
  name,
  setName,
  token,
  isDisabled,
  windowDimensions,
}) => {
  const { lat } = useContext(UserContext);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const getFilters = async () => {
      try {
        const response = await Doctors.GetFilters(token);
        if (response.status === RESPONSE_STATUS.success) {
          const { city, postal_code, state } = response.body;
          let cityData = city
            .map(({ city }) => {
              if (city) {
                return {
                  name: city,
                  type: 'City',
                };
              }
            })
            .filter((x) => x)
            .sort((a, b) => (a.name > b.name ? 1 : -1));

          cityData.unshift({
            name: 'header',
            header: 'City',
          });
          const ZipCode = postal_code
            .map(({ postal_code }) => {
              if (postal_code && !postal_code.includes('-')) {
                return {
                  name: postal_code,
                  type: 'Zip Code',
                };
              }
            })
            .filter((x) => x)
            .sort((a, b) => (a.name > b.name ? 1 : -1));
          ZipCode.unshift({
            name: 'header',
            header: 'Zip Code',
          });

          const stateData = state
            .map(({ state }) => {
              if (state) {
                return {
                  name: state,
                  type: 'State',
                };
              }
            })
            .filter((x) => x)
            .sort((a, b) => (a.name > b.name ? 1 : -1));
          stateData.unshift({
            name: 'header',
            header: 'State',
          });
          setMembers([...cityData, ...ZipCode, ...stateData]);
        }
      } catch (e) {
        console.log(e);
      }
    };
    if (!isDisabled) {
      getFilters();
    }
  }, []);

  const handleChangeSelection = (value) => {
    setName(value);
  };
  return (
    <>
      <AutoComplete
        getItemValue={(item) => item && item?.name}
        items={members?.filter((member) => {
          if (name?.length > 1) {
            if (member.name === 'header' && member.header === 'City') {
              if (
                members?.find(
                  (x) =>
                    x?.name.toLowerCase().includes(name.toLowerCase()) &&
                    x.type === 'City',
                )
              ) {
                return member.name === 'header';
              }
            }
            if (member.name === 'header' && member.header === 'Zip Code') {
              if (
                members?.find(
                  (x) =>
                    x?.name.toLowerCase().includes(name.toLowerCase()) &&
                    x.type === 'Zip Code',
                )
              ) {
                return member.name === 'header';
              }
            }
            if (member.name === 'header' && member.header === 'State') {
              if (
                members?.find(
                  (x) =>
                    x?.name.toLowerCase().includes(name.toLowerCase()) &&
                    x.type === 'State',
                )
              ) {
                return member.name === 'header';
              }
            }
            if (
              member?.header !== 'City' &&
              member?.header !== 'Zip Code' &&
              member?.header !== 'State'
            ) {
              return (
                member?.name
                  ?.toLowerCase()
                  ?.includes(name?.toLowerCase().trim()) ||
                member?.name === 'header'
              );
            }
          }
        })}
        renderItem={(item, isHighlighted) => {
          if (name) {
            return (
              <div
                style={{
                  background: 'white',
                }}
              >
                {item.name === 'header' ? (
                  <Typography
                    variant="body1"
                    sx={{ opacity: 0.5, padding: '10px' }}
                    color={darkTextColor}
                  >
                    {item.header}
                  </Typography>
                ) : (
                  <div
                    style={{
                      verticalAlign: 'middle',
                      cursor: 'pointer',
                      backgroundColor: isHighlighted
                        ? 'rgba(71, 187, 146, 0.12)'
                        : 'white',
                      padding: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: '100%',
                      gap: 10,
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <Typography
                        variant="body1"
                        color={isHighlighted ? '#185F45' : darkTextColor}
                      >
                        {item.name}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            );
            // return <p>{item.name}</p>;
          }
          // return <p styles={{ display: 'none' }}>{item.name}</p>;
          return <> </>;
        }}
        wrapperStyle={{
          position: 'relative',
          padding: 0,
          margin: 0,
          width: '90%',
        }}
        menuStyle={{
          position: 'absolute',
          overflowX: 'visible',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)',
          zIndex: 2,
          top: '5vh',
          left: 0,
          maxHeight: '50vh',
          overflowY: 'scroll',
        }}
        value={name || ''}
        inputProps={{
          autocomplete: 'chrome-off',
          placeholder: lat
            ? 'Map Area'
            : windowDimensions?.width < 1250
            ? 'Location'
            : 'City, state or zip code',
          style: {
            position: 'relative',
            // flexGrow: 1,
            width: '100%',
            border: 0,
            // height: '6vh',
            padding: 10,
            background: 'white',
            fontSize: '14px',
            borderRadius: 4,
            outline: 0,
            borderRight: '1px solid rgba(233, 232, 235, 1)',
            marginLeft: 20,
          },
        }}
        label="search-loc"
        onChange={(e) => {
          if (e?.target?.value === 'header') return;
          setName(e?.target?.value);
        }}
        onSelect={(name, e, c) => {
          if (name === 'header') return;
          handleChangeSelection(name);
          // if (subMenu && subMenu) setName(name);
        }}
      />
    </>
  );
};

export default LocationSearch;
