import { Card, Link, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  darkTextColor,
  GetEmployerName,
  GetStandardisedSpecialties,
  SuffixWithPeriod,
} from '../../constants';
import HealthBuildingLogo from '../../Icons/healthBuildingLogo';
import { CustomAvatar } from '../customAvatar';

export const UnRegistered = ({ curretDoc, doctor, location }) => {
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        mt: 3,
        p: 2,
        display: 'flex',
        flexDirection: 'row',
      }}
      key={doctor.index}
    >
      <Grid container direction="row" sx={{ flexWrap: 'nowrap' }}>
        <CustomAvatar
          curretDoc={curretDoc}
          sx={{
            width: '120px',
            height: '130px',
            mr: 2.5,
            justifyContent: 'center',
            display: 'flex',
          }}
        />
        <Grid container direction="column" justifyContent="space-between">
          <Typography color={darkTextColor} variant="h5">
            {doctor.first_name} {doctor.last_name}
            {curretDoc?.suffix ? ',' : ''}
            {SuffixWithPeriod(curretDoc?.suffix?.replaceAll('.', ''))}
          </Typography>
          <Typography
            color={darkTextColor}
            variant="body2"
            sx={{ opacity: '0.75', mt: -2 }}
          >
            {GetStandardisedSpecialties(doctor.specialty)}
          </Typography>
          {location ? (
            <Typography color={darkTextColor} variant="body2">
              {location?.city}, {location?.state} {location?.postal_code}
            </Typography>
          ) : (
            <></>
          )}
          <Typography
            sx={{
              mt: 1,
              display: 'flex',
              alignItem: 'center',
            }}
            color={'#615B99'}
            variant="body2"
          >
            <HealthBuildingLogo />
            &nbsp;
            {GetEmployerName(doctor.health_center)}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
        }}
        alignItems="center"
      >
        <Link
          color="textSecondary"
          onClick={() => {
            navigate(`/sign-up?id=${doctor.userId}`);
          }}
          variant="subtitle2"
          underline="hover"
          sx={{
            cursor: 'pointer',
            whiteSpace: 'nowrap',
          }}
        >
          {'Yes, this is me'}
        </Link>
      </Grid>
    </Card>
  );
};
