import './App.css';
import React from 'react';
// import Home from "./Home";
import Marketing from './pages/Marketing';
import DoctorsSearch from './pages/DoctorsSearch';
import SignUp from './pages/SignUp';
import UserProvider from './contexts/user/provider';
import Login from './pages/Login';
import Home from './pages/Home';

import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import FindASpecialist from './pages/FindASpecialist';
import Referrals from './pages/Referrals';
import Verification from './pages/Verification';
import { Profile } from './pages/Profile';
import Patient from './pages/Patient';
import EditProfile from './pages/EditProfile';
import Settings from './pages/Settings';
import Messages from './pages/Messages';
import IdleTimerContainer from './components/idleTimer';
import AppLogout from './components/logout';

function App() {
  return (
    <Router>
      <div className="App">
        <UserProvider>
          <AppLogout>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <IdleTimerContainer />
              <Routes>
                <Route exact path="/" element={<Marketing />} />
                <Route exact path="/doctors" element={<DoctorsSearch />} />
                <Route exact path="/sign-up" element={<SignUp />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/overview" element={<Home />} />
                <Route exact path="/find" element={<FindASpecialist />} />
                <Route exact path="/referrals" element={<Referrals />} />
                <Route exact path="/verification" element={<Verification />} />
                <Route exact path="/doctor/:id" element={<Profile />} />
                <Route exact path="/patient/:id" element={<Patient />} />
                <Route exact path="/profile/edit" element={<EditProfile />} />
                <Route exact path="/settings/:type" element={<Settings />} />
                <Route exact path="/messages" element={<Messages />} />
              </Routes>
            </ThemeProvider>
          </AppLogout>
        </UserProvider>
      </div>
    </Router>
  );
}

export default App;
