import update from 'immutability-helper';
import { useEffect } from 'react';
import { useCallback, useState } from 'react';
import { Card } from './card.js';
const style = {
  width: '100%',
};
export const DragableListContainer = ({ cards, setCards, removeDegree }) => {
  {
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        }),
      );
    }, []);

    const renderCard = useCallback(
      (card, index) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            text={card.value}
            moveCard={moveCard}
            removeDegree={removeDegree}
          />
        );
      },
      [cards],
    );
    return (
      <>
        <div style={style}>{cards?.map((card, i) => renderCard(card, i))}</div>
      </>
    );
  }
};
