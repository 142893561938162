const MoreOptions = () => (
  <svg
    width="18"
    height="4"
    viewBox="0 0 18 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.56" width="4" height="4" rx="2" fill="#212634" />
    <rect opacity="0.56" x="7" width="4" height="4" rx="2" fill="#212634" />
    <rect opacity="0.56" x="14" width="4" height="4" rx="2" fill="#212634" />
  </svg>
);

export default MoreOptions;
