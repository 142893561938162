import { useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import CustomInputField from '../../components/customInputField';
import AlertDialog from '../../components/alertDialog';

const textColor = '#212634';

const InvitePrompt = (props) => {
  const {
    openDialog,
    setOpenDialog,
    formik,
    sendInviteLoading,
    completed,
    setCompleted,
    inviteError,
    setInviteError,
  } = props;
  const [errorMessage, setErrorMessage] = useState('');

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClose = () => {
    setOpenDialog(false);
    formik.setFieldValue('firstName', '');
    formik.setFieldValue('lastName', '');
    if (inviteError) {
      setInviteError('');
    }
    if (completed) {
      setTimeout(() => {
        setCompleted(false);
      }, 250);
    }
  };

  return (
    <>
      <AlertDialog title="Send Invite" open={openDialog} setOpen={handleClose}>
        <DialogContent sx={{ my: -1, width: { md: '25vw', sm: '100%' } }}>
          {completed ? (
            <Typography color={textColor} gutterBottom variant="body1">
              You have successfully sent an invite to {formik.values.firstName}{' '}
              {formik.values.lastName}!
            </Typography>
          ) : (
            <>
              <Grid container direction="column" gap={1}>
                <Typography color={textColor} gutterBottom variant="body1">
                  Invite {formik.values.email} to medLynks!
                </Typography>
                <Stack>
                  <Typography color={textColor} gutterBottom variant="body1">
                    First Name
                  </Typography>
                  <CustomInputField
                    error={Boolean(
                      formik.touched.firstName && formik.errors.firstName,
                    )}
                    label="firstName"
                    fullWidth
                    width="100%"
                    name="firstName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.firstName}
                  />
                  {Boolean(
                    formik.touched.firstName && formik.errors.firstName,
                  ) && (
                    <Typography color="error" variant="body2">
                      {formik.touched.firstName && formik.errors.firstName}
                    </Typography>
                  )}
                </Stack>
                <Stack>
                  <Typography color={textColor} gutterBottom variant="body1">
                    Last Name
                  </Typography>
                  <CustomInputField
                    error={Boolean(
                      formik.touched.lastName && formik.errors.lastName,
                    )}
                    label="lastName"
                    fullWidth
                    width="100%"
                    name="lastName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values.lastName}
                  />
                  {Boolean(
                    formik.touched.lastName && formik.errors.lastName,
                  ) && (
                    <Typography color="error" variant="body2">
                      {formik.touched.lastName && formik.errors.lastName}
                    </Typography>
                  )}
                </Stack>
                {(errorMessage || inviteError) && (
                  <Typography
                    color="error"
                    gutterBottom
                    variant="body2"
                    // sx={{ position: 'absolute !important' }}
                  >
                    {errorMessage ? errorMessage : inviteError}
                  </Typography>
                )}
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!completed && (
            <Button
              color="secondary"
              size="large"
              variant="contained"
              // disabled={loading}

              fullWidth
              onClick={() => handleClose()}
              sx={{
                width: '10rem',
                height: 40,
                mr: 1.5,
                bgcolor: 'rgba(47, 47, 47, 0.14) !important',
                color: '#2F2F2F',
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            color="primary"
            size="large"
            variant="contained"
            // disabled={loading}
            disabled={sendInviteLoading}
            fullWidth
            sx={{ width: '10rem', height: 40 }}
            onClick={() => {
              if (completed) {
                handleClose();
              } else {
                formik.handleSubmit();
              }
            }}
          >
            {completed ? 'Done' : 'Send invite'}
          </Button>
        </DialogActions>
      </AlertDialog>
    </>
  );
};

export default InvitePrompt;
