import { Typography } from '@mui/material';
import { useState } from 'react';
import { darkTextColor } from '../../constants';

export const LargeText = ({ text }) => {
  const [showall, setShowAll] = useState(false);
  if (text) {
    if (text.length < 150) {
      return (
        <Typography
          variant="body1"
          color={darkTextColor}
          sx={{ overflowWrap: 'anywhere', mt: 2 }}
        >
          {text}
        </Typography>
      );
    }
    return (
      <Typography
        variant="body1"
        color={darkTextColor}
        sx={{ overflowWrap: 'anywhere', mt: 2 }}
      >
        {showall ? text : `${text?.slice(0, 150)}...`}{' '}
        <span
          onClick={() => setShowAll(!showall)}
          style={{ color: '#47bb92', cursor: 'pointer' }}
        >
          {showall ? 'Show less' : 'Show more'}
        </span>
      </Typography>
    );
  }
  return null;
};
