import * as React from 'react';
import { SwipeableDrawer, Typography, Button, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { darkTextColor } from '../../constants';
import Close from '../../Icons/close';

const CustomSwipeableDrawer = styled((props) => <SwipeableDrawer {...props} />)(
  () => ({
    '& .MuiPaper-root': {
      marginRight: '1vh',
      marginTop: '1vh',
      marginBottom: '1vh',
      borderRadius: '12px',
      height: '98vh',
      width: '32vw',
      minWidth: '500px',
      // width: { md: '30vw', sm: '100vw' },
    },
  }),
);

export default function AppDrawer(props) {
  const { anchor, setAnchor, children, title, buttons = false } = props;

  return (
    <div>
      <CustomSwipeableDrawer
        anchor="right"
        open={anchor}
        onClose={() => {}}
        onOpen={() => {}}
      >
        <Box
          role="presentation"
          sx={{ p: 2, py: 2, overflow: 'hidden', maxHeight: '95vh' }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ position: 'relative' }}
          >
            <Typography
              color={darkTextColor}
              gutterBottom
              variant="h5"
              sx={{ fontWeight: 600 }}
            >
              {title}
            </Typography>
            <div onClick={() => setAnchor(false)} style={{ cursor: 'pointer' }}>
              <Close />
            </div>
          </Grid>
          {children}
          {/* <Grid
            container
            gap={1.5}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
              flexWrap: 'nowrap',
              p: 2,
              mt: 1,
              position: 'absolute',
              bottom: '0px',
              right: 0,
              height: 'auto',
              width: '100%',
              backgroundColor: '#FFFFFF',
            }}
          >
            <Button
              color="error"
              size="large"
              // onClick={() => handleCancel()}
              variant="contained"
              sx={{
                height: 40,
                flexGrow: 1,
                bgcolor: 'rgba(47, 47, 47, 0.14) !important',
                color: '#2F2F2F',
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="large"
              // onClick={() => setPage(page + 1)}
              variant="contained"
              sx={{ flexGrow: 4, height: 40 }}
            >
              Next
            </Button>
          </Grid> */}
        </Box>
      </CustomSwipeableDrawer>
    </div>
  );
}
