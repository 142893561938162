import { Stack } from '@mui/system';
import { Tooltip, Box } from '@mui/material';
import React from 'react';
import InfoIcon from '../../Icons/infoIcon';
import { LikeButton } from '../../components/endorsements/hoverEffect';
import Favorites from '../../services/favorites';
import { RESPONSE_STATUS } from '../../constants';

export const EndorsementColumn = ({ data, user, favs, token }) => {
  const dataUse =
    data?.ReferredBy?.id === user?.id ? data?.ReferredTo : data?.ReferredBy;
  const isFavCheck = favs?.find((fa) => fa?.favoriteId === dataUse?.id) || [];
  const [likes, setLikes] = React.useState(dataUse?.favoritesOff.length || 0);
  const [endorsements, setEndorsements] = React.useState(
    dataUse?.favoritesOff || [],
  );
  const [isFav, setIsFav] = React.useState(!!isFavCheck?.id);
  const [favLoading, setFavLoading] = React.useState(false);

  const checkFav = (favList, userId) => {
    if (favList.length) {
      const findFav = favList.find((f) => f.favoriteId === userId);
      if (findFav) {
        return true;
      }
    }
    return false;
  };

  const updateFav = (favorites, docId) => {
    const fav = checkFav(favorites, docId);
    setIsFav(fav);
  };

  const AddFavorites = async (docId) => {
    try {
      setFavLoading(true);
      const response = await Favorites.post(token, docId);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites, docId);
        setLikes(likes + 1);
        const tempEndorsement = [...endorsements];
        tempEndorsement.unshift({
          createdAt: new Date(),
          doctorId: user.id,
          FavoriteBy: {
            firstName: user.firstName,
            lastName: user.lastName,
            prefix: user.prefix,
            profile_picture_url: user.profile_picture_url,
          },
        });
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  const RemoveFavorites = async (docId) => {
    try {
      setFavLoading(true);

      const response = await Favorites.put(token, docId);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites, docId);
        setLikes(likes - 1);
        const tempEndorsement = endorsements.filter(
          (end) => end.doctorId !== user.id,
        );
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  return (
    <Stack direction="row" alignItems="center" spacing={0}>
      <LikeButton
        endorsements={endorsements}
        RemoveFavorites={isFav ? RemoveFavorites : AddFavorites}
        sx={{ width: 18, height: 18 }}
        favLoading={favLoading}
        isFav={isFav}
        likes={likes}
        docData={dataUse}
      />
      <Tooltip title="Endorse" arrow>
        <Box sx={{ mx: 0.5, display: 'flex', alignItems: 'center' }}>
          <InfoIcon />
        </Box>
      </Tooltip>
    </Stack>
  );
};
