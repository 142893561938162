export const Specialties = [
  {
    specialty: 'Addiction Medicine',
    health_center: 'amita',
    Specialty: 'Addiction Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Addiction Medicine',
    health_center: 'nm',
    Specialty: 'Addiction Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Adolescent Medicine',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Adolescent Medicine',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Adolescent Psychiatry',
    health_center: 'advocate',
    Specialty: 'Psychiatry',
    Subspecialty: 'Adolescent Psychiatry',
  },
  {
    specialty: 'Adult and Pediatric Allergy and Immunology',
    health_center: 'amita',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Adult Reconstruction',
    health_center: 'northshore',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Advanced Heart Failure and Transplant Cardiology',
    health_center: 'amita',
    Specialty: 'Cardiology',
    Subspecialty: 'Advanced Heart Failure and Transplant',
  },
  {
    specialty: 'Advanced Heart Failure and Transplant Cardiology',
    health_center: 'advocate',
    Specialty: 'Cardiology',
    Subspecialty: 'Advanced Heart Failure and Transplant',
  },
  {
    specialty: 'Allergy',
    health_center: 'uic',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Allergy & Immunology',
    health_center: 'elmhurst',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Allergy & Immunology, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Allergy & Immunology, Pediatric Allergy',
    health_center: 'elmhurst',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Allergy and Immunology',
    health_center: 'advocate',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Allergy and Immunology',
    health_center: 'amita',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Allergy and Immunology',
    health_center: 'nm',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Allergy and Immunology',
    health_center: 'chicago',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Allergy and Immunology',
    health_center: 'uic',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty: 'Allergy and Immunology,Pediatric Allergy & Immunology',
    health_center: 'chicago',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Allergy and Immunology,Pediatrics',
    health_center: 'chicago',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Allergy, Immunology & Asthma',
    health_center: 'northshore',
    Specialty: 'Allergy and Immunology',
    Subspecialty: '',
  },
  {
    specialty:
      'Allergy, Immunology & Asthma, Pediatric Allergy, Immunology & Asthma',
    health_center: 'northshore',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Alternative Medicine',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Anatomic Pathology',
    health_center: 'northshore',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Anatomic Pathology',
    health_center: 'advocate',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Anatomic Pathology and Laboratory Medicine',
    health_center: 'amita',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Andrology and Infertility',
    health_center: 'nm',
    Specialty: 'Urology',
    Subspecialty: 'Andrology and Infertility',
  },
  {
    specialty: 'Anesthesia',
    health_center: 'northshore',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesia, Cardiology',
    health_center: 'northshore',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology',
    health_center: 'advocate',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology',
    health_center: 'amita',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology',
    health_center: 'chicago',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology',
    health_center: 'elmhurst',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology',
    health_center: 'nm',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology, Pain Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology, Pediatric Anesthesiology',
    health_center: 'elmhurst',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology,Critical Care Medicine',
    health_center: 'chicago',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology,Pain Management',
    health_center: 'chicago',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Anesthesiology,Pediatric Anesthesiology',
    health_center: 'chicago',
    Specialty: 'Anesthesiology',
    Subspecialty: '',
  },
  {
    specialty: 'Audiology',
    health_center: 'elmhurst',
    Specialty: 'Audiology',
    Subspecialty: '',
  },
  {
    specialty: 'Audiology',
    health_center: 'uic',
    Specialty: 'Audiology',
    Subspecialty: '',
  },
  {
    specialty: 'Bariatric & Obesity Medicine',
    health_center: 'elmhurst',
    Specialty: 'Bariatric & Obesity Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Bariatric & Obesity Medicine, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Bariatric & Obesity Medicine',
    Subspecialty: '',
  },
  {
    specialty:
      'Bariatric & Obesity Medicine, Internal Medicine, Lifestyle Medicine',
    health_center: 'elmhurst',
    Specialty: 'Bariatric & Obesity Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Bariatric Surgery',
    health_center: 'nm',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Bariatric Surgery',
    health_center: 'advocate',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Bariatric Surgery',
    health_center: 'amita',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Bariatric Surgery',
    health_center: 'uic',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Bariatric Surgery Program',
    health_center: 'uic',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Bariatric Surgery,Gastrointestinal Surgery (GI Surgery),General Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Bariatric Surgery,General Surgery,Gastrointestinal Surgery (GI Surgery)',
    health_center: 'chicago',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Bariatric Surgery,Surgery,Gastrointestinal Surgery (GI Surgery),General Surgery,Surgical Oncology',
    health_center: 'chicago',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Bariatric Surgery,Surgery,General Surgery,Gastrointestinal Surgery (GI Surgery)',
    health_center: 'chicago',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Behavioral Health Integration',
    health_center: 'elmhurst',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Behavioral Neurology',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Behavioral Sleep Medicine,Sleep Medicine,Psychology',
    health_center: 'chicago',
    Specialty: 'Sleep Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Behavioral/Developmental Pediatrics',
    health_center: 'northshore',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Body Imaging',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Breast Surgery',
    health_center: 'nm',
    Specialty: 'Breast Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Breast Surgery',
    health_center: 'elmhurst',
    Specialty: 'Breast Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Breast Surgery,General Surgery',
    health_center: 'chicago',
    Specialty: 'Breast Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Breast Surgery,General Surgery,Surgery,Surgical Oncology,Endocrine Surgery',
    health_center: 'chicago',
    Specialty: 'Breast Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Breast Surgery,General Surgery,Surgical Oncology',
    health_center: 'chicago',
    Specialty: 'Breast Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Breast Surgery,Surgery,Surgical Oncology,General Surgery',
    health_center: 'chicago',
    Specialty: 'Breast Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Cancer Services',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiac Behavioral Health',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiac Electrophysiology',
    health_center: 'advocate',
    Specialty: 'Cardiology',
    Subspecialty: 'Clinical Cardiac Electrophysiology (EP)',
  },
  {
    specialty: 'Cardiac Electrophysiology',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: 'Clinical Cardiac Electrophysiology (EP)',
  },
  {
    specialty: 'Cardiac Electrophysiology',
    health_center: 'nm',
    Specialty: 'Cardiology',
    Subspecialty: 'Clinical Cardiac Electrophysiology (EP)',
  },
  {
    specialty: 'Cardiac Electrophysiology, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: 'Clinical Cardiac Electrophysiology (EP)',
  },
  {
    specialty: 'Cardiac Electrophysiology,Cardiology (Heart Care)',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Clinical Cardiac Electrophysiology (EP)',
  },
  {
    specialty:
      'Cardiac Electrophysiology,Cardiology (Heart Care),Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Clinical Cardiac Electrophysiology (EP)',
  },
  {
    specialty: 'Cardiac Imaging,Cardiology (Heart Care)',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Advanced Imaging (Echo)',
  },
  {
    specialty: 'Cardiac Surgery',
    health_center: 'nm',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery',
  },
  {
    specialty: 'Cardiac Surgery (Heart Surgery)',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery',
  },
  {
    specialty: 'Cardiac Surgery (Heart Surgery),Surgery',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery',
  },
  {
    specialty: 'Cardiac Surgery (Heart Surgery),Surgery,Transplant',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery',
  },
  {
    specialty: 'Cardiac Surgery (Heart Surgery),Thoracic Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery, Thoracic Surgery',
  },
  {
    specialty: 'Cardiology',
    health_center: 'advocate',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiology',
    health_center: 'nm',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiology',
    health_center: 'northshore',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiology',
    health_center: 'uic',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiology (Heart Care)',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiology (Heart Care),Cardiac Electrophysiology',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Clinical Cardiac Electrophysiology (EP)',
  },
  {
    specialty: 'Cardiology (Heart Care),Cardiac Imaging',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Advanced Imaging (Echo)',
  },
  {
    specialty: 'Cardiology (Heart Care),Geriatric and Palliative Medicine',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiology (Heart Care),Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiology (Heart Care),Interventional Cardiology',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional',
  },
  {
    specialty:
      'Cardiology (Heart Care),Interventional Cardiology,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional',
  },
  {
    specialty: 'Cardiology (Heart Care),Pediatric Cardiology',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Cardiology (Heart Care),Transplant',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Advanced Heart Failure and Transplant',
  },
  {
    specialty: 'Cardiovascular & Thoracic Surgery',
    health_center: 'advocate',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery, Thoracic Surgery',
  },
  {
    specialty: 'Cardiovascular Disease',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiovascular Disease',
    health_center: 'advocate',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiovascular Disease (Cardiology)',
    health_center: 'amita',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiovascular Disease, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Cardiovascular Disease, Internal Medicine, Nuclear Cardiology',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: 'Nuclear Cardiology',
  },
  {
    specialty: 'Cardiovascular Disease, Interventional Cardiology',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional',
  },
  {
    specialty: 'Cardiovascular Surgery',
    health_center: 'advocate',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery',
  },
  {
    specialty: 'Cardiovascular Surgery',
    health_center: 'northshore',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery',
  },
  {
    specialty: 'Cardiovascular Surgery',
    health_center: 'elmhurst',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery',
  },
  {
    specialty: 'Child & Adolescent Psychiatry',
    health_center: 'elmhurst',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child and Adolescent Psychiatry',
  },
  {
    specialty: 'Child & Adolescent Psychiatry, Psychiatry',
    health_center: 'elmhurst',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child and Adolescent Psychiatry',
  },
  {
    specialty: 'Child Abuse Pediatrics',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Child and Adolescent Psychiatry',
    health_center: 'amita',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child and Adolescent Psychiatry',
  },
  {
    specialty: 'Child and Adolescent Psychiatry',
    health_center: 'nm',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child and Adolescent Psychiatry',
  },
  {
    specialty: 'Child/Adolescent Psychiatry',
    health_center: 'advocate',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child and Adolescent Psychiatry',
  },
  {
    specialty: 'Child/Adolescent Psychology',
    health_center: 'advocate',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child and Adolescent Psychiatry',
  },
  {
    specialty: 'Chiropractic Medicine',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Cardiac Electrophysiology',
    health_center: 'amita',
    Specialty: 'Cardiology',
    Subspecialty: 'Clinical Cardiac Electrophysiology (EP)',
  },
  {
    specialty: 'Clinical Genetics',
    health_center: 'advocate',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Neurophysiology',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Neurophysiology',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Nurse Specialist',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Pathology',
    health_center: 'advocate',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Pathology',
    health_center: 'northshore',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Psychology',
    health_center: 'amita',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Psychology',
    health_center: 'elmhurst',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Clinical Psychology, Neuropsychology',
    health_center: 'elmhurst',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Colon & Rectal Surgery, General Surgery',
    health_center: 'elmhurst',
    Specialty: 'Colorectal Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Colon &amp; Rectal Surgery',
    health_center: 'uic',
    Specialty: 'Colorectal Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Colon and Rectal Surgery',
    health_center: 'amita',
    Specialty: 'Colorectal Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Colon and Rectal Surgery',
    health_center: 'nm',
    Specialty: 'Colorectal Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Colon and Rectal Surgery',
    health_center: 'advocate',
    Specialty: 'Colorectal Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Colon and Rectal Surgery,Gastrointestinal Surgery (GI Surgery)',
    health_center: 'chicago',
    Specialty: 'Colorectal Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Colon and Rectal Surgery,Gastrointestinal Surgery (GI Surgery),General Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Colorectal Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Colon and Rectal Surgery,Surgery,Gastrointestinal Surgery (GI Surgery)',
    health_center: 'chicago',
    Specialty: 'Colorectal Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Complex General Surgical Oncology',
    health_center: 'amita',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Complex General Surgical Oncology',
    health_center: 'elmhurst',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty:
      'Complex General Surgical Oncology, General Surgery, Breast Surgery',
    health_center: 'elmhurst',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Complex Pediatric Otolaryngology',
    health_center: 'amita',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty: 'Concussion Management Program',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Concussion Program',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Consultation-Liaison Psychiatry',
    health_center: 'nm',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Cornea and External Disease',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Cornea',
  },
  {
    specialty: 'Craniofacial Center',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Critical Care Medicine',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Critical Care Medicine',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Critical Care Medicine',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Critical Care Medicine',
    health_center: 'chicago',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Critical Care Medicine, Nephrology, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Cytopathology',
    health_center: 'nm',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Dental Care at Mile Square',
    health_center: 'uic',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Dentistry',
    health_center: 'amita',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Dentistry',
    health_center: 'northshore',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Dentistry',
    health_center: 'advocate',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Dentistry',
    health_center: 'nm',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Dentistry and Endodontics',
    health_center: 'amita',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Dentistry-Endodontic',
    health_center: 'advocate',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Dermatology',
    health_center: 'advocate',
    Specialty: 'Dermatology',
    Subspecialty: '',
  },
  {
    specialty: 'Dermatology',
    health_center: 'amita',
    Specialty: 'Dermatology',
    Subspecialty: '',
  },
  {
    specialty: 'Dermatology',
    health_center: 'chicago',
    Specialty: 'Dermatology',
    Subspecialty: '',
  },
  {
    specialty: 'Dermatology',
    health_center: 'elmhurst',
    Specialty: 'Dermatology',
    Subspecialty: '',
  },
  {
    specialty: 'Dermatology',
    health_center: 'nm',
    Specialty: 'Dermatology',
    Subspecialty: '',
  },
  {
    specialty: 'Dermatology',
    health_center: 'uic',
    Specialty: 'Dermatology',
    Subspecialty: '',
  },
  {
    specialty: 'Dermatology (Skin)',
    health_center: 'northshore',
    Specialty: 'Dermatology',
    Subspecialty: '',
  },
  {
    specialty: 'Dermatology,Dermatologic Oncology (Skin Cancer)',
    health_center: 'chicago',
    Specialty: 'Dermatology',
    Subspecialty: 'Dermatologic Oncology',
  },
  {
    specialty: 'Dermatology,Pathology,Dermatologic Oncology (Skin Cancer)',
    health_center: 'chicago',
    Specialty: 'Dermatology',
    Subspecialty: 'Dermatologic Oncology',
  },
  {
    specialty: 'Dermatology,Pediatric Dermatology',
    health_center: 'chicago',
    Specialty: 'Dermatology',
    Subspecialty: 'Pediatric Dermatology',
  },
  {
    specialty: 'Dermatopathology',
    health_center: 'amita',
    Specialty: 'Dermatology',
    Subspecialty: 'Dermatopathology',
  },
  {
    specialty: 'Dermatopathology',
    health_center: 'nm',
    Specialty: 'Dermatology',
    Subspecialty: 'Dermatopathology',
  },
  {
    specialty: 'Developmental - Behavioral Pediatrics',
    health_center: 'amita',
    Specialty: 'Pediatrics',
    Subspecialty: 'Developmental Pediatrics',
  },
  {
    specialty: 'Developmental & Behavioral Pediatrics',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: 'Developmental Pediatrics',
  },
  {
    specialty: 'Developmental Pediatrics',
    health_center: 'advocate',
    Specialty: 'Pediatrics',
    Subspecialty: 'Developmental Pediatrics',
  },
  {
    specialty: 'Diabetes and Endocrinology',
    health_center: 'uic',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Diabetes and Metabolism',
    health_center: 'uic',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Diabetes Management',
    health_center: 'elmhurst',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Diagnostic Neuroradiology',
    health_center: 'nm',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Diagnostic Radiology',
    health_center: 'advocate',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Diagnostic Radiology',
    health_center: 'amita',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Diagnostic Radiology',
    health_center: 'nm',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Electrodiagnostic Medicine',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine',
    health_center: 'advocate',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine',
    health_center: 'amita',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine',
    health_center: 'chicago',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine',
    health_center: 'elmhurst',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine',
    health_center: 'nm',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine',
    health_center: 'northshore',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine',
    health_center: 'uic',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine, Immediate Care',
    health_center: 'elmhurst',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine, Sexual Abuse Adult & Peds',
    health_center: 'elmhurst',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine,Family Medicine',
    health_center: 'chicago',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Emergency Medicine,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Endocrine Surgery',
    health_center: 'nm',
    Specialty: 'Endocrine Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Endocrine Surgery,General Surgery,Surgery,Surgical Oncology',
    health_center: 'chicago',
    Specialty: 'Endocrine Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Endocrine Surgery,General Surgery,Surgical Oncology',
    health_center: 'chicago',
    Specialty: 'Endocrine Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Endocrinology',
    health_center: 'chicago',
    Specialty: 'Endocrinology',
    Subspecialty: '',
  },
  {
    specialty: 'Endocrinology',
    health_center: 'nm',
    Specialty: 'Endocrinology',
    Subspecialty: '',
  },
  {
    specialty: 'Endocrinology',
    health_center: 'uic',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Endocrinology Diabetes and Metabolism',
    health_center: 'elmhurst',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Endocrinology, Diabetes and Metabolism',
    health_center: 'advocate',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Endocrinology, Diabetes and Metabolism',
    health_center: 'amita',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Endocrinology, Diabetes, and Metabolism',
    health_center: 'uic',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Endocrinology,Pediatric Endocrinology',
    health_center: 'chicago',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Endocrinology/Metabolic Diseases',
    health_center: 'northshore',
    Specialty: 'Endocrinology',
    Subspecialty: 'Diabetes and Metabolism',
  },
  {
    specialty: 'Endovascular Surgical Neuroradiology',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Neuroradiology',
  },
  {
    specialty: 'Epilepsy',
    health_center: 'amita',
    Specialty: 'Neurology',
    Subspecialty: 'Epilepsy',
  },
  {
    specialty: 'Epilepsy',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: 'Epilepsy',
  },
  {
    specialty: 'Esophageal Gastroenterology',
    health_center: 'nm',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Motility Disorders / Esophageal',
  },
  {
    specialty: 'Facial Plastic Reconstructive Surgery',
    health_center: 'elmhurst',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Facial Plastics and Reconstructive',
  },
  {
    specialty: 'Facial Plastic Surgery',
    health_center: 'advocate',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Facial Plastics and Reconstructive',
  },
  {
    specialty: 'Facial Plastic Surgery',
    health_center: 'nm',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Facial Plastics and Reconstructive',
  },
  {
    specialty: 'Family Medicine',
    health_center: 'advocate',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine',
    health_center: 'amita',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine',
    health_center: 'chicago',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine',
    health_center: 'elmhurst',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine',
    health_center: 'nm',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine',
    health_center: 'northshore',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine',
    health_center: 'uic',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine, Bariatric & Obesity Medicine',
    health_center: 'elmhurst',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine, Geriatrics',
    health_center: 'elmhurst',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine, Geriatrics, Palliative Medicine',
    health_center: 'elmhurst',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine, Sports Medicine',
    health_center: 'elmhurst',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine,Emergency Medicine',
    health_center: 'chicago',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine,General Surgery',
    health_center: 'chicago',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine,OB/GYN',
    health_center: 'chicago',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine,Pain Management',
    health_center: 'chicago',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine,Pathology',
    health_center: 'chicago',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine,Primary Care',
    health_center: 'chicago',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine,Sports Medicine',
    health_center: 'chicago',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Family Medicine/Immediate Care',
    health_center: 'elmhurst',
    Specialty: 'Family Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Female Pelvic Medicine and Reconstructive Surgery',
    health_center: 'amita',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Foot & Ankle',
    health_center: 'northshore',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Foot & Ankle',
  },
  {
    specialty: 'Foot and Ankle Surgery',
    health_center: 'nm',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Foot & Ankle',
  },
  {
    specialty: 'Gastroenterology',
    health_center: 'advocate',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology',
    health_center: 'elmhurst',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology',
    health_center: 'nm',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology',
    health_center: 'uic',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology',
    health_center: 'amita',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology (GI)',
    health_center: 'northshore',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology (GI), Internal Medicine',
    health_center: 'northshore',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: '',
  },
  {
    specialty: 'Gastroenterology,Interventional Endoscopy',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Advanced Interventional Endoscopy (Interventional)',
  },
  {
    specialty: 'Gastroenterology,Transplant',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / transplant hepatology',
  },
  {
    specialty: 'Gastrointestinal & General Surgery',
    health_center: 'northshore',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Gastrointestinal Behavioral Health Psychology',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Gastrointestinal Surgery',
    health_center: 'nm',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty:
      'Gastrointestinal Surgery (GI Surgery),General Surgery,Surgical Oncology,Surgery',
    health_center: 'chicago',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty:
      'Gastrointestinal Surgery (GI Surgery),Surgery,Surgical Oncology,General Surgery',
    health_center: 'chicago',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty:
      'Gastrointestinal Surgery (GI Surgery),Surgical Oncology,General Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty:
      'Gastrointestinal Surgery (GI Surgery),Surgical Oncology,Surgery,General Surgery',
    health_center: 'chicago',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'General Dentistry',
    health_center: 'elmhurst',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'General Pediatrics',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'General Pediatrics,Pediatrics',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery',
    health_center: 'advocate',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery',
    health_center: 'amita',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery',
    health_center: 'elmhurst',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery',
    health_center: 'nm',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery',
    health_center: 'uic',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery, Bariatric & Obesity Medicine',
    health_center: 'elmhurst',
    Specialty: 'Bariatric Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery, Breast Surgery',
    health_center: 'elmhurst',
    Specialty: 'Breast Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'General Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Genetic Counselor',
    health_center: 'elmhurst',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Genetics',
    health_center: 'northshore',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Genetics',
    health_center: 'chicago',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Genetics',
    health_center: 'advocate',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Genetics,Pediatric Chronic Disease',
    health_center: 'chicago',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Genitourinary Reconstructive Surgery',
    health_center: 'nm',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatric and Palliative Medicine',
    health_center: 'chicago',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatric and Palliative Medicine,Infectious Diseases',
    health_center: 'chicago',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty:
      'Geriatric and Palliative Medicine,Internal Medicine,Primary Care',
    health_center: 'chicago',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatric Medicine',
    health_center: 'amita',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatric Medicine',
    health_center: 'nm',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatric Medicine',
    health_center: 'advocate',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatrics',
    health_center: 'uic',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatrics',
    health_center: 'northshore',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatrics',
    health_center: 'elmhurst',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatrics, Internal Medicine',
    health_center: 'northshore',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatrics, Palliative Care',
    health_center: 'northshore',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Geriatrics, Rheumatology (Arthritis)',
    health_center: 'northshore',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Glaucoma',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Glaucoma',
  },
  {
    specialty: 'Gynecologic Oncology',
    health_center: 'amita',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Gynecologic Oncology',
  },
  {
    specialty: 'Gynecologic Oncology',
    health_center: 'advocate',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Gynecologic Oncology',
  },
  {
    specialty: 'Gynecologic Oncology',
    health_center: 'nm',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Gynecologic Oncology',
  },
  {
    specialty: 'Gynecologic Oncology',
    health_center: 'northshore',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Gynecologic Oncology',
  },
  {
    specialty: 'Gynecologic Oncology',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Gynecologic Oncology',
  },
  {
    specialty: 'Gynecologic Oncology (Gynecologic Cancer),OB/GYN',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Gynecologic Oncology',
  },
  {
    specialty: 'Gynecology',
    health_center: 'nm',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Gynecology',
    health_center: 'advocate',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Gynecology',
    health_center: 'northshore',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Gynecology',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Obstetrics / Gynecology (OB-GYN)',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Gynecology, Reproductive Endocrinology & Infertility',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Hand & Microvascular Surgery, Orthopedic Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Hand & Upper Extremity Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Hand & Upper Extremity Surgery, Orthopedic Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Hand Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Hand Surgery',
    health_center: 'nm',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Hand Surgery',
    health_center: 'northshore',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Hand Surgery',
    health_center: 'advocate',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Hand Surgery, Orthopedic Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Head and Neck Surgery',
    health_center: 'nm',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Head and Neck / Oncology',
  },
  {
    specialty: 'Health Psychology',
    health_center: 'amita',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Heart Failure and Heart Transplantation',
    health_center: 'nm',
    Specialty: 'Cardiology',
    Subspecialty: 'Advanced Heart Failure and Transplant',
  },
  {
    specialty: 'Hematology',
    health_center: 'amita',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology',
  },
  {
    specialty: 'Hematology',
    health_center: 'nm',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology',
  },
  {
    specialty: 'Hematology',
    health_center: 'northshore',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology',
  },
  {
    specialty: 'Hematology',
    health_center: 'advocate',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology',
  },
  {
    specialty: 'Hematology & Oncology',
    health_center: 'elmhurst',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology; Oncology',
  },
  {
    specialty: 'Hematology & Oncology, Hospice & Palliative Medicine',
    health_center: 'elmhurst',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology; Oncology',
  },
  {
    specialty: 'Hematology and Medical Oncology',
    health_center: 'nm',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology; Oncology',
  },
  {
    specialty: 'Hematology and Oncology',
    health_center: 'uic',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology; Oncology',
  },
  {
    specialty: 'Hematology and Oncology (Cancer)',
    health_center: 'chicago',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology; Oncology',
  },
  {
    specialty:
      'Hematology and Oncology (Cancer),Geriatric and Palliative Medicine,Geriatric Oncology (Cancer in Older Adults)',
    health_center: 'chicago',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology; Oncology',
  },
  {
    specialty: 'Hematology and Oncology (Cancer),Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology; Oncology',
  },
  {
    specialty: 'Hematology/Oncology',
    health_center: 'advocate',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology; Oncology',
  },
  {
    specialty: 'Hematopathology',
    health_center: 'nm',
    Specialty: 'Pathology',
    Subspecialty: 'Hematopathology',
  },
  {
    specialty: 'Hepatobiliary Surgery',
    health_center: 'nm',
    Specialty: 'General Surgery',
    Subspecialty: 'Surgical Oncology',
  },
  {
    specialty: 'Hepatology',
    health_center: 'nm',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Hepatology',
    health_center: 'uic',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Hepatology',
    health_center: 'elmhurst',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Hepatology (Liver Disease)',
    health_center: 'uic',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Hepatology (Liver Disease),Gastroenterology,Transplant',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty:
      'Hepatology (Liver Disease),Gastroenterology,Transplant,Transplant Oncology',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Hepatology (Liver Disease),Transplant,Gastroenterology',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty:
      'Hepatology (Liver Disease),Transplant,Gastroenterology,Transplant Oncology',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Hepatology(Liver Disease)',
    health_center: 'uic',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Hospice & Palliative Medicine',
    health_center: 'elmhurst',
    Specialty: 'Hospice and Palliative Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Hospice and Palliative Medicine',
    health_center: 'amita',
    Specialty: 'Hospice and Palliative Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Hospice and Palliative Medicine',
    health_center: 'advocate',
    Specialty: 'Hospice and Palliative Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Hospital Based Internal Medicine',
    health_center: 'northshore',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty: 'Hospital Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty: 'Hospital Medicine',
    health_center: 'nm',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty: 'Hospital Medicine,Cardiology (Heart Care),Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty: 'Hospital Medicine,General Surgery,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty:
      'Hospital Medicine,Geriatric and Palliative Medicine,Family Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty: 'Hospital Medicine,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty:
      'Hospital Medicine,Nephrology (Kidney Disease),Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty: 'Hospitalist',
    health_center: 'advocate',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty: 'Immediate Care',
    health_center: 'nm',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Individual & Relational Psychotherapy',
    health_center: 'northshore',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Disease',
    health_center: 'advocate',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Disease',
    health_center: 'amita',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Disease',
    health_center: 'nm',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Diseases',
    health_center: 'uic',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Diseases',
    health_center: 'elmhurst',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Diseases',
    health_center: 'northshore',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Diseases',
    health_center: 'chicago',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Diseases,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Infectious Diseases,Ophthalmology',
    health_center: 'chicago',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Inflammatory Bowel Disease',
    health_center: 'nm',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Inflammatory Bowel Disease (IBD)',
  },
  {
    specialty: 'Integrative Medicine',
    health_center: 'nm',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Integrative Medicine, Internal Medicine',
    health_center: 'northshore',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Med ICU Hospitalist',
    health_center: 'northshore',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospital Medicine',
  },
  {
    specialty: 'Internal Med/Research',
    health_center: 'northshore',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine',
    health_center: 'advocate',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine',
    health_center: 'amita',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine',
    health_center: 'nm',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine',
    health_center: 'northshore',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine',
    health_center: 'uic',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine (General Medicine)',
    health_center: 'rush',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine & Pediatrics',
    health_center: 'elmhurst',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine, Geriatric Medicine',
    health_center: 'elmhurst',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine, Geriatrics',
    health_center: 'elmhurst',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine, Geriatrics, Pediatrics',
    health_center: 'elmhurst',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine, Hospice & Palliative Medicine',
    health_center: 'elmhurst',
    Specialty: 'Hospice and Palliative Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine, Pediatrics',
    health_center: 'elmhurst',
    Specialty: 'Internal Medicine; Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine, Pediatrics',
    health_center: 'northshore',
    Specialty: 'Internal Medicine; Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Family Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Geriatric and Palliative Medicine',
    health_center: 'chicago',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Nephrology (Kidney Disease)',
    health_center: 'chicago',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Neurology',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Occupational Medicine',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Pediatrics',
    health_center: 'chicago',
    Specialty: 'Internal Medicine; Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Pediatrics,Primary Care',
    health_center: 'chicago',
    Specialty: 'Internal Medicine; Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Primary Care',
    health_center: 'chicago',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty:
      'Internal Medicine,Primary Care,Geriatric and Palliative Medicine',
    health_center: 'chicago',
    Specialty: 'Geriatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine,Pulmonary Medicine (Pulmonology)',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Internal Medicine/Hospitalist',
    health_center: 'elmhurst',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospitalist',
  },
  {
    specialty: 'Internal Medicine/Hospitalist, Pediatrics',
    health_center: 'elmhurst',
    Specialty: 'Internal Medicine',
    Subspecialty: 'Hospitalist',
  },
  {
    specialty: 'Internal Medicine/Pediatrics',
    health_center: 'nm',
    Specialty: 'Internal Medicine; Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Interventional Cardiology',
    health_center: 'amita',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional Cardiology',
  },
  {
    specialty: 'Interventional Cardiology',
    health_center: 'nm',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional Cardiology',
  },
  {
    specialty: 'Interventional Cardiology',
    health_center: 'advocate',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional Cardiology',
  },
  {
    specialty: 'Interventional Cardiology',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional Cardiology',
  },
  {
    specialty: 'Interventional Cardiology (Internal Medicine)',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional Cardiology',
  },
  {
    specialty: 'Interventional Cardiology, Cardiovascular Disease',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional Cardiology',
  },
  {
    specialty: 'Interventional Cardiology,Cardiology (Heart Care)',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Interventional Cardiology',
  },
  {
    specialty: 'Interventional Endoscopy,Gastroenterology',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Advanced Interventional Endoscopy (Interventional)',
  },
  {
    specialty: 'Interventional Gastroenterology',
    health_center: 'nm',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Advanced Interventional Endoscopy (Interventional)',
  },
  {
    specialty: 'Interventional Neuroradiology',
    health_center: 'amita',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Neuroradiology',
  },
  {
    specialty: 'Interventional Neuroradiology',
    health_center: 'nm',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Neuroradiology',
  },
  {
    specialty: 'Interventional Pulmonology',
    health_center: 'nm',
    Specialty: 'Pulmonology',
    Subspecialty: 'Interventional Pulmonology',
  },
  {
    specialty: 'Interventional Pulmonology',
    health_center: 'elmhurst',
    Specialty: 'Pulmonology',
    Subspecialty: 'Interventional Pulmonology',
  },
  {
    specialty: 'Interventional Radiology',
    health_center: 'advocate',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Interventional Radiology',
    health_center: 'northshore',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Interventional Radiology',
    health_center: 'uic',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Interventional Radiology and Diagnostic Radiology',
    health_center: 'amita',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Interventional Radiology, Radiology, Diagnostic',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Interventional Radiology,Radiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty:
      'Interventional Radiology,Radiology,Vascular and Interventional Radiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty:
      'Interventional Radiology,Vascular and Interventional Radiology,Radiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Joint Replacement & Adult Reconstruction Surgery',
    health_center: 'nm',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Joints',
  },
  {
    specialty: 'Laryngology',
    health_center: 'nm',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Laryngology',
  },
  {
    specialty: 'Liver Transplant',
    health_center: 'uic',
    Specialty: 'General Surgery',
    Subspecialty: 'Transplant Surgery',
  },
  {
    specialty: 'Maternal and Fetal Medicine',
    health_center: 'amita',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Maternal-Fetal Medicine',
  },
  {
    specialty: 'Maternal Fetal Medicine',
    health_center: 'nm',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Maternal-Fetal Medicine',
  },
  {
    specialty: 'Maternal Fetal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Maternal-Fetal Medicine',
  },
  {
    specialty: 'Maternal-Fetal Medicine',
    health_center: 'northshore',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Maternal-Fetal Medicine',
  },
  {
    specialty: 'Maternal-Fetal Medicine,OB/GYN',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Maternal-Fetal Medicine',
  },
  {
    specialty: 'Maternal–Fetal Medicine',
    health_center: 'advocate',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Maternal-Fetal Medicine',
  },
  {
    specialty: 'Medical Genetics',
    health_center: 'nm',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Medical Genetics & Genomics',
    health_center: 'elmhurst',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Medical Oncology',
    health_center: 'nm',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Oncology',
  },
  {
    specialty: 'Medical Oncology',
    health_center: 'amita',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Oncology',
  },
  {
    specialty: 'Medical Retina',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Retina / Uveitis',
  },
  {
    specialty: 'Medicina Ocupacional y Ambiental (Inglés)',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Melanoma and Sarcoma Surgery',
    health_center: 'nm',
    Specialty: 'General Surgery',
    Subspecialty: 'Surgical Oncology',
  },
  {
    specialty: 'Midwife',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Midwifery',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Midwifery',
    health_center: 'elmhurst',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Midwifery,OB/GYN',
    health_center: 'chicago',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Minimally Invasive Gynecologic Surgery,OB/GYN',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Minimally Invasive Gynecologic Surgery',
  },
  {
    specialty: 'Minimally Invasive Gynecological Surgery',
    health_center: 'nm',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Minimally Invasive Gynecologic Surgery',
  },
  {
    specialty: 'Minimally Invasive Urologic Surgery',
    health_center: 'nm',
    Specialty: 'Urology',
    Subspecialty: 'Endourology (minimally invasive)',
  },
  {
    specialty: 'Mohs Surgery',
    health_center: 'nm',
    Specialty: 'Dermatology',
    Subspecialty: 'Mohs Surgery',
  },
  {
    specialty: 'Molecular Diagnostics',
    health_center: 'northshore',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Movement Disorders',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: 'Movement Disorders',
  },
  {
    specialty: 'Multiple Sclerosis and Neuroimmunology',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: 'Neuro-Immunology / MS',
  },
  {
    specialty: 'Neonatal - Perinatal Medicine',
    health_center: 'amita',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Neonatal Medicine',
    health_center: 'northshore',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Neonatal Perinatal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Neonatal-Perinatal Medicine',
    health_center: 'advocate',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Neonatal-Perinatal Medicine',
    health_center: 'uic',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Neonatology',
    health_center: 'amita',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Neonatology',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Neonatology',
    health_center: 'nm',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Neonatology,Pediatrics',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Nephrology',
    health_center: 'advocate',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Nephrology',
    health_center: 'amita',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Nephrology',
    health_center: 'elmhurst',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Nephrology',
    health_center: 'nm',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Nephrology',
    health_center: 'uic',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Nephrology (Kidney Disease)',
    health_center: 'chicago',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Nephrology (Kidney Disease),Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Nephrology (Kidney Disease),Transplant',
    health_center: 'chicago',
    Specialty: 'Nephrology',
    Subspecialty: 'Kidney Transplantation',
  },
  {
    specialty: 'Nephrology (Kidneys)',
    health_center: 'northshore',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Nephrology, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Nephrology',
    Subspecialty: '',
  },
  {
    specialty: 'Neuro Critical Care',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: 'Neurocritical Care',
  },
  {
    specialty: 'Neuro-Oncology',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: 'Neuro-Oncology',
  },
  {
    specialty: 'Neuro-Oncology (Brain & Spinal Tumors),Neurology',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: 'Neuro-Oncology',
  },
  {
    specialty: 'Neuro-Ophthalmology',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Neuro-Ophthalmology',
  },
  {
    specialty: 'Neuroinfectious Disease',
    health_center: 'nm',
    Specialty: 'Infectious Disease (ID)',
    Subspecialty: '',
  },
  {
    specialty: 'Neurological Surgery',
    health_center: 'amita',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurological Surgery',
    health_center: 'nm',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurological Surgery',
    health_center: 'elmhurst',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurological Surgery, Neurological & Spine Surgery',
    health_center: 'elmhurst',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurological Surgery, Spine Surgery',
    health_center: 'elmhurst',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Neurology',
    health_center: 'amita',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology',
    health_center: 'elmhurst',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology',
    health_center: 'uic',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology',
    health_center: 'advocate',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology (Brain & Nervous System)',
    health_center: 'northshore',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology and Neurosurgery',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology with Special Qualification in Child Neurology',
    health_center: 'amita',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology, Clinical Neurophysiology',
    health_center: 'elmhurst',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology, Clinical Neurophysiology, Critical Care EEG/QEEG',
    health_center: 'elmhurst',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology, Neurocritical Care',
    health_center: 'elmhurst',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology, Neurocritical Care, Vascular Neurology',
    health_center: 'elmhurst',
    Specialty: 'Neurology',
    Subspecialty: 'Neurocritical Care',
  },
  {
    specialty: 'Neurology, Vascular Neurology',
    health_center: 'elmhurst',
    Specialty: 'Neurology',
    Subspecialty: 'Stroke / Neurovascular',
  },
  {
    specialty: 'Neurology, Vascular Neurology, Neurocritical Care',
    health_center: 'elmhurst',
    Specialty: 'Neurology',
    Subspecialty: 'Neurocritical Care',
  },
  {
    specialty: 'Neurology,Neurocritical Care',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: 'Neurocritical Care',
  },
  {
    specialty: 'Neurology,Neurocritical Care,Vascular Neurology',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: 'Neurocritical Care',
  },
  {
    specialty: 'Neurology,Sleep Medicine',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurology,Vascular Neurology',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: 'Stroke / Neurovascular',
  },
  {
    specialty: 'Neurology,Vascular Neurology,Neurocritical Care',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: 'Stroke / Neurovascular',
  },
  {
    specialty: 'Neuromuscular Medicine',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: 'Neuromuscular',
  },
  {
    specialty: 'Neuromuscular Medicine',
    health_center: 'amita',
    Specialty: 'Neurology',
    Subspecialty: 'Neuromuscular',
  },
  {
    specialty: 'Neuropathology',
    health_center: 'nm',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Neurophysiology',
    health_center: 'amita',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Neuropsychology',
    health_center: 'nm',
    Specialty: 'Psychology',
    Subspecialty: 'Neuropsychology',
  },
  {
    specialty: 'Neuropsychology',
    health_center: 'advocate',
    Specialty: 'Psychology',
    Subspecialty: 'Neuropsychology',
  },
  {
    specialty: 'Neuropsychology',
    health_center: 'amita',
    Specialty: 'Psychology',
    Subspecialty: 'Neuropsychology',
  },
  {
    specialty: 'Neuroradiology',
    health_center: 'amita',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Neuroradiology',
    health_center: 'northshore',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Neuroradiology',
    health_center: 'advocate',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Neuroradiology,Radiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Neurosurgery',
    health_center: 'advocate',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurosurgery',
    health_center: 'northshore',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurosurgery',
    health_center: 'uic',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurosurgery',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Neurosurgery,Neuro-Oncology (Brain & Spinal Tumors),Head and Neck Cancer,Surgery',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Neurosurgical Oncology (Tumors)',
  },
  {
    specialty:
      'Neurosurgery,Neuro-Oncology (Brain & Spinal Tumors),Spine Surgery,Pediatric Neurosurgery,Surgery,Pediatric Neurology',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Neurosurgical Oncology (Tumors)',
  },
  {
    specialty:
      'Neurosurgery,Neuro-Oncology (Brain & Spinal Tumors),Surgery,Pediatric Neurosurgery',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Neurosurgical Oncology (Tumors)',
  },
  {
    specialty: 'Neurosurgery,Neurocritical Care,Surgery',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurosurgery,Spine Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty:
      'Neurosurgery,Surgery,Spine Surgery,Neuro-Oncology (Brain & Spinal Tumors)',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Neurosurgical Oncology',
    health_center: 'nm',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Neurosurgical Oncology (Tumors)',
  },
  {
    specialty: 'Neurotology',
    health_center: 'amita',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Neurotology / Otology',
  },
  {
    specialty: 'Neurotology',
    health_center: 'nm',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Neurotology / Otology',
  },
  {
    specialty: 'Nuclear Medicine',
    health_center: 'advocate',
    Specialty: 'Nuclear Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Nuclear Medicine',
    health_center: 'nm',
    Specialty: 'Nuclear Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Nuclear Medicine',
    health_center: 'northshore',
    Specialty: 'Nuclear Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Nuclear Medicine',
    health_center: 'amita',
    Specialty: 'Nuclear Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Nuclear Radiology',
    health_center: 'amita',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'NULL',
    health_center: 'elmhurst',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Nurse Practitioner',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Nurse Practitioner',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Nutrition &amp; Wellness',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'OB/GYN',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'OB/GYN,Anesthesiology',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty:
      'OB/GYN,Contraception & Family Planning,Pediatric & Adolescent Gynecology',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'OB/GYN,Maternal-Fetal Medicine',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Maternal-Fetal Medicine',
  },
  {
    specialty: 'OB/GYN,Minimally Invasive Gynecologic Surgery',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Minimally Invasive Gynecologic Surgery',
  },
  {
    specialty:
      'OB/GYN,Reproductive Endocrinology and Infertility,General Surgery',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty:
      'OB/GYN,Urogynecology and Pelvic Reconstructive Surgery,Minimally Invasive Gynecologic Surgery',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Obesity Medicine',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Obstetrics',
    health_center: 'northshore',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Obstetrics & Gynecology',
    health_center: 'advocate',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Obstetrics & Gynecology',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty:
      'Obstetrics & Gynecology, Female Pelvic Medicine and Reconstructive Surgery',
    health_center: 'rush',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Obstetrics &amp; Gynecology',
    health_center: 'uic',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Obstetrics and Gynecology',
    health_center: 'amita',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Obstetrics and Gynecology',
    health_center: 'nm',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Obstetrics/Gynecology (OB/GYN)',
    health_center: 'northshore',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Obstetrics/Gynecology (OB/GYN), Reproductive Endocrinology',
    health_center: 'northshore',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Occupational Environmental Medicine',
    health_center: 'uic',
    Specialty: 'Occupational Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Occupational Medicine',
    health_center: 'nm',
    Specialty: 'Occupational Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Occupational Medicine',
    health_center: 'advocate',
    Specialty: 'Occupational Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Occupational Medicine',
    health_center: 'elmhurst',
    Specialty: 'Occupational Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Occupational Medicine',
    health_center: 'chicago',
    Specialty: 'Occupational Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Occupational Medicine,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Occupational Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Ocular Inflammation and Uveitis',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Retina / Uveitis',
  },
  {
    specialty: 'Oncology',
    health_center: 'advocate',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Oncology',
  },
  {
    specialty: 'Oncology',
    health_center: 'amita',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Oncology',
  },
  {
    specialty: 'Oncology (Cancer)',
    health_center: 'northshore',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Oncology',
  },
  {
    specialty: 'Oncology (Cancer), Palliative Medicine',
    health_center: 'northshore',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Oncology',
  },
  {
    specialty: 'Ophthalmic Plastic, Orbital and Reconstructive Surgery',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty:
      'Oculoplastics (ophthalmic plastic and reconstructive surgery)',
  },
  {
    specialty: 'Ophthalmology',
    health_center: 'advocate',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology',
    health_center: 'amita',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology',
    health_center: 'chicago',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology',
    health_center: 'elmhurst',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology',
    health_center: 'uic',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology (Eyes)',
    health_center: 'northshore',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology, Eye Plastic & Orbital Surgery',
    health_center: 'elmhurst',
    Specialty: 'Ophthalmology',
    Subspecialty:
      'Oculoplastics (ophthalmic plastic and reconstructive surgery)',
  },
  {
    specialty: 'Ophthalmology, Pediatric Ophthalmology',
    health_center: 'elmhurst',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology, Retinal-Vitreous Disorders',
    health_center: 'elmhurst',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Ophthalmology,Pediatric Ophthalmology',
    health_center: 'chicago',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Optometry',
    health_center: 'chicago',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Oral & Maxillofacial Surgery',
    health_center: 'elmhurst',
    Specialty: 'Oral & Maxillofacial Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Oral & Maxillofacial Surgery, Oral & Maxillofacial Surgery',
    health_center: 'elmhurst',
    Specialty: 'Oral & Maxillofacial Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Oral &amp; Maxillofacial Surgery',
    health_center: 'uic',
    Specialty: 'Oral & Maxillofacial Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Oral and Maxillofacial Surgery',
    health_center: 'amita',
    Specialty: 'Oral & Maxillofacial Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Oral and Maxillofacial Surgery',
    health_center: 'nm',
    Specialty: 'Oral & Maxillofacial Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Oral/Maxillofacial Surgery',
    health_center: 'advocate',
    Specialty: 'Oral & Maxillofacial Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Orthodontics',
    health_center: 'chicago',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopaedic Oncology',
    health_center: 'nm',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Orthopedic Oncology',
  },
  {
    specialty: 'Orthopaedic Sports Medicine',
    health_center: 'nm',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopaedic Surgery',
    health_center: 'amita',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopaedic Surgery',
    health_center: 'nm',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopaedic Surgery',
    health_center: 'northshore',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopaedic Surgery,Orthopaedic Oncology',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Orthopedic Oncology',
  },
  {
    specialty:
      'Orthopaedic Surgery,Orthopaedic Oncology,Pediatric Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Orthopedic Oncology',
  },
  {
    specialty: 'Orthopaedic Surgery,Pediatric Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Pediatric Orthopedics',
  },
  {
    specialty: 'Orthopaedic Surgery,Spine Surgery',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Orthopaedic Surgery,Sports Medicine',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Sports',
  },
  {
    specialty: 'Orthopaedic Surgery,Trauma Surgery',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Trauma',
  },
  {
    specialty: 'Orthopaedic Trauma',
    health_center: 'northshore',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Trauma',
  },
  {
    specialty: 'Orthopaedic Trauma',
    health_center: 'nm',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Trauma',
  },
  {
    specialty: 'Orthopaedics',
    health_center: 'uic',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopedic Spine Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Orthopedic Spine Surgery, Orthopedic Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Orthopedic Surgery',
    health_center: 'advocate',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopedic Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Orthopedic Surgery, Foot & Ankle Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Foot & Ankle',
  },
  {
    specialty: 'Orthopedic Surgery, Hand & Microvascular Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Orthopedic Surgery, Hand & Upper Extremity Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Orthopedic Surgery, Hand Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Orthopedic Surgery, Hand Surgery, Hand & Microvascular Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Orthopedic Surgery, Orthopedic Spine Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Orthopedic Surgery, Spine Surgery',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Orthopedic Surgery, Sports Medicine',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Sports',
  },
  {
    specialty: 'Orthopedic Surgery, Surgery, Hand & Upper Extremity',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Otolaryngology',
    health_center: 'nm',
    Specialty: 'Otolaryngology',
    Subspecialty: '',
  },
  {
    specialty: 'Otolaryngology',
    health_center: 'uic',
    Specialty: 'Otolaryngology',
    Subspecialty: '',
  },
  {
    specialty: 'Otolaryngology - Ear, Nose & Throat (ENT)',
    health_center: 'northshore',
    Specialty: 'Otolaryngology',
    Subspecialty: '',
  },
  {
    specialty:
      'Otolaryngology - Ear, Nose & Throat (ENT), Pediatric Otolaryngology',
    health_center: 'northshore',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty: 'Otolaryngology – Head and Neck Surgery',
    health_center: 'advocate',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Head and Neck / Oncology',
  },
  {
    specialty: 'Otolaryngology (ENT)',
    health_center: 'amita',
    Specialty: 'Otolaryngology',
    Subspecialty: '',
  },
  {
    specialty: 'Otolaryngology (ENT)',
    health_center: 'elmhurst',
    Specialty: 'Otolaryngology',
    Subspecialty: '',
  },
  {
    specialty: 'Otolaryngology (ENT)',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: '',
  },
  {
    specialty: 'Otolaryngology (ENT), Facial Plastic Reconstructive Surgery',
    health_center: 'elmhurst',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Facial Plastics and Reconstructive',
  },
  {
    specialty: 'Otolaryngology (ENT), Pediatric Otolaryngology (ENT)',
    health_center: 'elmhurst',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty:
      'Otolaryngology (ENT),Head and Neck Surgery,Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Head and Neck / Oncology',
  },
  {
    specialty:
      'Otolaryngology (ENT),Head and Neck Surgery,Head and Neck Cancer,Endocrine Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Head and Neck / Oncology',
  },
  {
    specialty:
      'Otolaryngology (ENT),Head and Neck Surgery,Head and Neck Cancer,Surgery',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Head and Neck / Oncology',
  },
  {
    specialty:
      'Otolaryngology (ENT),Head and Neck Surgery,Head and Neck Cancer,Surgery,Endocrine Surgery',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Head and Neck / Oncology',
  },
  {
    specialty:
      'Otolaryngology (ENT),Laryngeal Surgery (Voice Care),Head and Neck Surgery,Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Laryngology',
  },
  {
    specialty:
      'Otolaryngology (ENT),Pediatric Otolaryngology (ENT),Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty:
      'Otolaryngology (ENT),Pediatric Otolaryngology (ENT),Surgery,Head and Neck Surgery,Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty: 'Otolaryngology (ENT),Surgery',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: '',
  },
  {
    specialty: 'Otolaryngology (ENT),Surgery,Head and Neck Surgery',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: '',
  },
  {
    specialty: 'Otolaryngology and Facial Plastic Surgery',
    health_center: 'amita',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Facial Plastics and Reconstructive',
  },
  {
    specialty: 'Pain Management',
    health_center: 'uic',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Pain Management,Anesthesiology',
    health_center: 'chicago',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Pain Management,Neurology',
    health_center: 'chicago',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Pain Management,Physical Medicine and Rehabilitation',
    health_center: 'chicago',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Pain Medicine',
    health_center: 'amita',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Pain Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Pain Medicine',
    health_center: 'nm',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Pain Medicine',
    health_center: 'advocate',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Palliative Care',
    health_center: 'northshore',
    Specialty: 'Hospice and Palliative Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Palliative Medicine',
    health_center: 'nm',
    Specialty: 'Hospice and Palliative Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Pastoral Care',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology',
    health_center: 'amita',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology',
    health_center: 'chicago',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology',
    health_center: 'nm',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology',
    health_center: 'uic',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology-Anatomic',
    health_center: 'advocate',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology, Anatomic & Clinical',
    health_center: 'elmhurst',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology, Anatomic & Clinical, Cytopathology',
    health_center: 'elmhurst',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology,General Surgery',
    health_center: 'chicago',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology,Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Pathology,Neuro-Oncology (Brain & Spinal Tumors)',
    health_center: 'chicago',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Patient &amp; Guest Experience',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric - Hospitalist',
    health_center: 'northshore',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric & Adolescent Gynecology,OB/GYN',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Allergy & Immunology',
    health_center: 'elmhurst',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Pediatric Allergy & Immunology',
    health_center: 'chicago',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Pediatric Allergy and Immunology',
    health_center: 'advocate',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Pediatric Allergy, Immunology & Asthma',
    health_center: 'northshore',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Pediatric Allergy, Immunology, &amp; Pulmonology',
    health_center: 'uic',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Pediatric Anesthesiology',
    health_center: 'nm',
    Specialty: 'Anesthesiology',
    Subspecialty: 'Pediatric Anesthesiology',
  },
  {
    specialty: 'Pediatric Anesthesiology',
    health_center: 'amita',
    Specialty: 'Anesthesiology',
    Subspecialty: 'Pediatric Anesthesiology',
  },
  {
    specialty: 'Pediatric Anesthesiology',
    health_center: 'elmhurst',
    Specialty: 'Anesthesiology',
    Subspecialty: 'Pediatric Anesthesiology',
  },
  {
    specialty: 'Pediatric Cardiac Surgery',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Pediatric Cardiac Surgery',
  },
  {
    specialty:
      'Pediatric Cardiac Surgery,Cardiac Surgery (Heart Surgery),Surgery',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Pediatric Cardiac Surgery',
  },
  {
    specialty: 'Pediatric Cardiology',
    health_center: 'advocate',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Pediatric Cardiology',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Pediatric Cardiology',
    health_center: 'nm',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Pediatric Cardiology',
    health_center: 'uic',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Pediatric Cardiology',
    health_center: 'amita',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Pediatric Cardiology',
    health_center: 'elmhurst',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Pediatric Cardiology',
    health_center: 'northshore',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Pediatric Cardiology,Pediatrics',
    health_center: 'chicago',
    Specialty: 'Cardiology',
    Subspecialty: 'Pediatric Cardiology',
  },
  {
    specialty: 'Pediatric Cardiovascular Surgery',
    health_center: 'advocate',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Pediatric Cardiac Surgery',
  },
  {
    specialty: 'Pediatric Cardiovascular Thoracic Surgery',
    health_center: 'nm',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Pediatric Cardiac Surgery',
  },
  {
    specialty: 'Pediatric Critical Care',
    health_center: 'advocate',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Critical Care',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Critical Care Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Critical Care Medicine',
    health_center: 'nm',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty:
      'Pediatric Critical Care,Pediatric Palliative and Hospice Medicine',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Dentistry',
    health_center: 'advocate',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Dentistry',
    health_center: 'amita',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Dentistry',
    health_center: 'nm',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Dentistry',
    health_center: 'elmhurst',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Dermatology',
    health_center: 'nm',
    Specialty: 'Dermatology',
    Subspecialty: 'Pediatric Dermatology',
  },
  {
    specialty: 'Pediatric Dermatology,Dermatology',
    health_center: 'chicago',
    Specialty: 'Dermatology',
    Subspecialty: 'Pediatric Dermatology',
  },
  {
    specialty: 'Pediatric Emergency Medicine',
    health_center: 'elmhurst',
    Specialty: 'Emergency Medicine',
    Subspecialty: 'Pediatric Emergency Medicine',
  },
  {
    specialty: 'Pediatric Emergency Medicine',
    health_center: 'advocate',
    Specialty: 'Emergency Medicine',
    Subspecialty: 'Pediatric Emergency Medicine',
  },
  {
    specialty: 'Pediatric Emergency Medicine',
    health_center: 'nm',
    Specialty: 'Emergency Medicine',
    Subspecialty: 'Pediatric Emergency Medicine',
  },
  {
    specialty: 'Pediatric Emergency Medicine',
    health_center: 'amita',
    Specialty: 'Emergency Medicine',
    Subspecialty: 'Pediatric Emergency Medicine',
  },
  {
    specialty: 'Pediatric Emergency Medicine',
    health_center: 'chicago',
    Specialty: 'Emergency Medicine',
    Subspecialty: 'Pediatric Emergency Medicine',
  },
  {
    specialty: 'Pediatric Emergency Medicine,Emergency Medicine',
    health_center: 'chicago',
    Specialty: 'Emergency Medicine',
    Subspecialty: 'Pediatric Emergency Medicine',
  },
  {
    specialty: 'Pediatric Endocrinology',
    health_center: 'chicago',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Pediatric Endocrinology',
    health_center: 'elmhurst',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Pediatric Endocrinology',
    health_center: 'nm',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Pediatric Endocrinology',
    health_center: 'amita',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Pediatric Endocrinology',
    health_center: 'northshore',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Pediatric Endocrinology',
    health_center: 'advocate',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Pediatric Endocrinology',
    health_center: 'uic',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Pediatric Endocrinology,Endocrinology',
    health_center: 'chicago',
    Specialty: 'Endocrinology',
    Subspecialty: 'Pediatric Endocrinology',
  },
  {
    specialty: 'Pediatric Gastroenterology',
    health_center: 'nm',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Gastroenterology',
    health_center: 'uic',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Gastroenterology',
    health_center: 'advocate',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Gastroenterology',
    health_center: 'amita',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Gastroenterology',
    health_center: 'elmhurst',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Gastroenterology',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Gastroenterology',
    health_center: 'northshore',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Gastroenterology,Gastroenterology',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty:
      'Pediatric Gastroenterology,Gastroenterology,Pediatric Hepatology (Liver Disease)',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty:
      'Pediatric Gastroenterology,Pediatric Hepatology (Liver Disease)',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric General Surgery',
    health_center: 'advocate',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Genetics',
    health_center: 'uic',
    Specialty: 'Medical Genetics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Hematology & Oncology',
    health_center: 'elmhurst',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty: 'Pediatric Hematology & Oncology (Pediatric Cancer)',
    health_center: 'chicago',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty:
      'Pediatric Hematology & Oncology (Pediatric Cancer),Pediatric Hematology',
    health_center: 'chicago',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty: 'Pediatric Hematology & Oncology (Pediatric Cancer),Pediatrics',
    health_center: 'chicago',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty: 'Pediatric Hematology and Oncology',
    health_center: 'uic',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty: 'Pediatric Hematology and Oncology',
    health_center: 'nm',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty: 'Pediatric Hematology-Oncology',
    health_center: 'amita',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty: 'Pediatric Hematology/Oncology',
    health_center: 'advocate',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty: 'Pediatric Hematology/Oncology',
    health_center: 'uic',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Pediatric Hematology / Oncology',
  },
  {
    specialty:
      'Pediatric Hepatology (Liver Disease),Pediatric Gastroenterology',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Hospital Medicine',
    health_center: 'nm',
    Specialty: 'Pediatrics',
    Subspecialty: 'Pediatric Hospital Medicine',
  },
  {
    specialty: 'Pediatric Hospital Medicine',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: 'Pediatric Hospital Medicine',
  },
  {
    specialty: 'Pediatric Hospital Medicine,Neonatology,Pediatrics',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: 'Pediatric Hospital Medicine',
  },
  {
    specialty: 'Pediatric Hospital Medicine,Pediatrics',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: 'Pediatric Hospital Medicine',
  },
  {
    specialty: 'Pediatric Hospitalist',
    health_center: 'advocate',
    Specialty: 'Pediatrics',
    Subspecialty: 'Pediatric Hospital Medicine',
  },
  {
    specialty: 'Pediatric Immunology Allergy &amp; Pulmonology',
    health_center: 'uic',
    Specialty: 'Allergy and Immunology',
    Subspecialty: 'Pediatric Allergy and Immunology',
  },
  {
    specialty: 'Pediatric Infectious Disease',
    health_center: 'nm',
    Specialty: 'Infectious Disease',
    Subspecialty: 'Pediatric Infectious Disease',
  },
  {
    specialty: 'Pediatric Infectious Disease',
    health_center: 'advocate',
    Specialty: 'Infectious Disease',
    Subspecialty: 'Pediatric Infectious Disease',
  },
  {
    specialty: 'Pediatric Infectious Disease',
    health_center: 'northshore',
    Specialty: 'Infectious Disease',
    Subspecialty: 'Pediatric Infectious Disease',
  },
  {
    specialty: 'Pediatric Infectious Diseases',
    health_center: 'elmhurst',
    Specialty: 'Infectious Disease',
    Subspecialty: 'Pediatric Infectious Disease',
  },
  {
    specialty: 'Pediatric Infectious Diseases',
    health_center: 'uic',
    Specialty: 'Infectious Disease',
    Subspecialty: 'Pediatric Infectious Disease',
  },
  {
    specialty: 'Pediatric Infectious Diseases',
    health_center: 'chicago',
    Specialty: 'Infectious Disease',
    Subspecialty: 'Pediatric Infectious Disease',
  },
  {
    specialty: 'Pediatric Infectious Diseases',
    health_center: 'amita',
    Specialty: 'Infectious Disease',
    Subspecialty: 'Pediatric Infectious Disease',
  },
  {
    specialty: 'Pediatric Intensive Care',
    health_center: 'uic',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Neonatology',
    health_center: 'uic',
    Specialty: 'Pediatrics',
    Subspecialty: 'Neonatology',
  },
  {
    specialty: 'Pediatric Nephrology',
    health_center: 'nm',
    Specialty: 'Nephrology',
    Subspecialty: 'Pediatric Nephrology',
  },
  {
    specialty: 'Pediatric Nephrology',
    health_center: 'chicago',
    Specialty: 'Nephrology',
    Subspecialty: 'Pediatric Nephrology',
  },
  {
    specialty: 'Pediatric Nephrology',
    health_center: 'advocate',
    Specialty: 'Nephrology',
    Subspecialty: 'Pediatric Nephrology',
  },
  {
    specialty: 'Pediatric Nephrology',
    health_center: 'elmhurst',
    Specialty: 'Nephrology',
    Subspecialty: 'Pediatric Nephrology',
  },
  {
    specialty: 'Pediatric Nephrology',
    health_center: 'uic',
    Specialty: 'Nephrology',
    Subspecialty: 'Pediatric Nephrology',
  },
  {
    specialty: 'Pediatric Nephrology,Pediatric Chronic Disease',
    health_center: 'chicago',
    Specialty: 'Nephrology',
    Subspecialty: 'Pediatric Nephrology',
  },
  {
    specialty: 'Pediatric Nephrology,Pediatric Transplant Surgery',
    health_center: 'chicago',
    Specialty: 'Nephrology',
    Subspecialty: 'Pediatric Nephrology',
  },
  {
    specialty: 'Pediatric Neurological Surgery',
    health_center: 'nm',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Pediatric Neurological Surgery',
  },
  {
    specialty: 'Pediatric Neurology',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: 'Pediatric Neurology',
  },
  {
    specialty: 'Pediatric Neurology',
    health_center: 'advocate',
    Specialty: 'Neurology',
    Subspecialty: 'Pediatric Neurology',
  },
  {
    specialty: 'Pediatric Neurology',
    health_center: 'northshore',
    Specialty: 'Neurology',
    Subspecialty: 'Pediatric Neurology',
  },
  {
    specialty: 'Pediatric Neurology',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: 'Pediatric Neurology',
  },
  {
    specialty: 'Pediatric Neurology',
    health_center: 'elmhurst',
    Specialty: 'Neurology',
    Subspecialty: 'Pediatric Neurology',
  },
  {
    specialty: 'Pediatric Neurology',
    health_center: 'uic',
    Specialty: 'Neurology',
    Subspecialty: 'Pediatric Neurology',
  },
  {
    specialty: 'Pediatric Neurology,Neurology',
    health_center: 'chicago',
    Specialty: 'Neurology',
    Subspecialty: 'Pediatric Neurology',
  },
  {
    specialty: 'Pediatric Neuropsychology',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Neuroradiology',
    health_center: 'nm',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Pediatric Neurosurgery',
    health_center: 'advocate',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Pediatric Neurological Surgery',
  },
  {
    specialty: 'Pediatric Ophthalmology',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Pediatrics / Strabismus',
  },
  {
    specialty: 'Pediatric Ophthalmology',
    health_center: 'advocate',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Pediatrics / Strabismus',
  },
  {
    specialty: 'Pediatric Ophthalmology',
    health_center: 'elmhurst',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Pediatrics / Strabismus',
  },
  {
    specialty: 'Pediatric Orthopaedic Surgery',
    health_center: 'nm',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Pediatric Orthopedics',
  },
  {
    specialty: 'Pediatric Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Pediatric Orthopedics',
  },
  {
    specialty: 'Pediatric Orthopaedic Surgery,Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Pediatric Orthopedics',
  },
  {
    specialty: 'Pediatric Orthopedic Surgery',
    health_center: 'advocate',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Pediatric Orthopedics',
  },
  {
    specialty: 'Pediatric Orthopedics',
    health_center: 'elmhurst',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Pediatric Orthopedics',
  },
  {
    specialty: 'Pediatric Otolaryngology',
    health_center: 'nm',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty: 'Pediatric Otolaryngology',
    health_center: 'advocate',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty: 'Pediatric Otolaryngology (ENT)',
    health_center: 'elmhurst',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty: 'Pediatric Otolaryngology (ENT), Otolaryngology (ENT)',
    health_center: 'elmhurst',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty: 'Pediatric Otolaryngology (ENT),Head and Neck Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty:
      'Pediatric Otolaryngology (ENT),Otolaryngology (ENT),Head and Neck Surgery',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty:
      'Pediatric Otolaryngology (ENT),Surgery,Head and Neck Surgery,Otolaryngology (ENT)',
    health_center: 'chicago',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Pediatric Otolaryngology',
  },
  {
    specialty: 'Pediatric Pain Medicine',
    health_center: 'nm',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Palliative Medicine',
    health_center: 'advocate',
    Specialty: 'Hospice and Palliative Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Physical Medicine and Rehabilitation',
    health_center: 'nm',
    Specialty: 'Physical Medicine and Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Plastic & Reconstructive Surge',
    health_center: 'elmhurst',
    Specialty: 'Plastic Surgery',
    Subspecialty: 'Pediatric Plastic Surgery',
  },
  {
    specialty: 'Pediatric Plastic & Reconstructive Surgery',
    health_center: 'chicago',
    Specialty: 'Plastic Surgery',
    Subspecialty: 'Pediatric Plastic Surgery',
  },
  {
    specialty:
      'Pediatric Plastic & Reconstructive Surgery,Plastic and Reconstructive Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Plastic Surgery',
    Subspecialty: 'Pediatric Plastic Surgery',
  },
  {
    specialty: 'Pediatric Plastic Surgery',
    health_center: 'nm',
    Specialty: 'Plastic Surgery',
    Subspecialty: 'Pediatric Plastic Surgery',
  },
  {
    specialty: 'Pediatric Plastic/Reconstructive Surgery',
    health_center: 'advocate',
    Specialty: 'Plastic Surgery',
    Subspecialty: 'Pediatric Plastic Surgery',
  },
  {
    specialty: 'Pediatric Primary Care',
    health_center: 'uic',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatric Psychiatry',
    health_center: 'chicago',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child / Adolescent',
  },
  {
    specialty: 'Pediatric Psychiatry,Developmental & Behavioral Pediatrics',
    health_center: 'chicago',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child / Adolescent',
  },
  {
    specialty: 'Pediatric Psychiatry,Psychiatry',
    health_center: 'chicago',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child / Adolescent',
  },
  {
    specialty: 'Pediatric Psychology',
    health_center: 'advocate',
    Specialty: 'Psychology',
    Subspecialty: 'Child / Adolescent',
  },
  {
    specialty: 'Pediatric Pulmonary Medicine',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Pediatric Pulmonology',
  },
  {
    specialty: 'Pediatric Pulmonary Medicine,Pediatrics',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Pediatric Pulmonology',
  },
  {
    specialty: 'Pediatric Pulmonology',
    health_center: 'nm',
    Specialty: 'Pulmonology',
    Subspecialty: 'Pediatric Pulmonology',
  },
  {
    specialty: 'Pediatric Pulmonology',
    health_center: 'elmhurst',
    Specialty: 'Pulmonology',
    Subspecialty: 'Pediatric Pulmonology',
  },
  {
    specialty: 'Pediatric Pulmonology',
    health_center: 'northshore',
    Specialty: 'Pulmonology',
    Subspecialty: 'Pediatric Pulmonology',
  },
  {
    specialty: 'Pediatric Pulmonology',
    health_center: 'advocate',
    Specialty: 'Pulmonology',
    Subspecialty: 'Pediatric Pulmonology',
  },
  {
    specialty: 'Pediatric Pulmonology',
    health_center: 'amita',
    Specialty: 'Pulmonology',
    Subspecialty: 'Pediatric Pulmonology',
  },
  {
    specialty: 'Pediatric Radiology',
    health_center: 'nm',
    Specialty: 'Radiology',
    Subspecialty: 'Pediatric Radiology',
  },
  {
    specialty: 'Pediatric Radiology',
    health_center: 'advocate',
    Specialty: 'Radiology',
    Subspecialty: 'Pediatric Radiology',
  },
  {
    specialty: 'Pediatric Radiology, Radiology, Diagnostic',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Pediatric Radiology',
  },
  {
    specialty: 'Pediatric Rheumatology',
    health_center: 'nm',
    Specialty: 'Rheumatology',
    Subspecialty: 'Pediatric Rheumatology',
  },
  {
    specialty: 'Pediatric Rheumatology',
    health_center: 'advocate',
    Specialty: 'Rheumatology',
    Subspecialty: 'Pediatric Rheumatology',
  },
  {
    specialty: 'Pediatric Rheumatology',
    health_center: 'chicago',
    Specialty: 'Rheumatology',
    Subspecialty: 'Pediatric Rheumatology',
  },
  {
    specialty: 'Pediatric Rheumatology',
    health_center: 'elmhurst',
    Specialty: 'Rheumatology',
    Subspecialty: 'Pediatric Rheumatology',
  },
  {
    specialty: 'Pediatric Rheumatology,Rheumatology',
    health_center: 'chicago',
    Specialty: 'Rheumatology',
    Subspecialty: 'Pediatric Rheumatology',
  },
  {
    specialty: 'Pediatric Sleep Medicine',
    health_center: 'advocate',
    Specialty: 'Sleep Medicine',
    Subspecialty: 'Pediatric Sleep Medicine',
  },
  {
    specialty: 'Pediatric Sleep Medicine',
    health_center: 'nm',
    Specialty: 'Sleep Medicine',
    Subspecialty: 'Pediatric Sleep Medicine',
  },
  {
    specialty: 'Pediatric Sports Medicine',
    health_center: 'nm',
    Specialty: 'Sports Medicine',
    Subspecialty: 'Pediatric Sports Medicine',
  },
  {
    specialty: 'Pediatric Sports Medicine',
    health_center: 'elmhurst',
    Specialty: 'Sports Medicine',
    Subspecialty: 'Pediatric Sports Medicine',
  },
  {
    specialty:
      'Pediatric Sports Medicine,Pediatric Orthopaedic Surgery,Orthopaedic Surgery,Sports Medicine',
    health_center: 'chicago',
    Specialty: 'Sports Medicine',
    Subspecialty: 'Pediatric Sports Medicine',
  },
  {
    specialty: 'Pediatric Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Surgery',
    health_center: 'northshore',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Surgery',
    health_center: 'nm',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Surgery',
    health_center: 'amita',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Surgery',
    health_center: 'elmhurst',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Surgery',
    health_center: 'uic',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Surgery',
    health_center: 'advocate',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Surgery,Critical Care Medicine,Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: 'Pediatric General Surgery',
  },
  {
    specialty: 'Pediatric Transplant Hepatology',
    health_center: 'nm',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Pediatric Gastroenterology',
  },
  {
    specialty: 'Pediatric Urology',
    health_center: 'amita',
    Specialty: 'Urology',
    Subspecialty: 'Pediatric Urology',
  },
  {
    specialty: 'Pediatric Urology',
    health_center: 'advocate',
    Specialty: 'Urology',
    Subspecialty: 'Pediatric Urology',
  },
  {
    specialty: 'Pediatric Urology',
    health_center: 'uic',
    Specialty: 'Urology',
    Subspecialty: 'Pediatric Urology',
  },
  {
    specialty: 'Pediatric Urology',
    health_center: 'nm',
    Specialty: 'Urology',
    Subspecialty: 'Pediatric Urology',
  },
  {
    specialty: 'Pediatric Urology,Urology',
    health_center: 'chicago',
    Specialty: 'Urology',
    Subspecialty: 'Pediatric Urology',
  },
  {
    specialty: 'Pediatrics',
    health_center: 'advocate',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics',
    health_center: 'amita',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics',
    health_center: 'elmhurst',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics',
    health_center: 'nm',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics',
    health_center: 'northshore',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics',
    health_center: 'uic',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics, Internal Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics,Internal Medicine,Primary Care',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics,OB/GYN',
    health_center: 'chicago',
    Specialty: 'Pediatrics',
    Subspecialty: '',
  },
  {
    specialty: 'Pediatrics/Hospitalist',
    health_center: 'elmhurst',
    Specialty: 'Pediatrics',
    Subspecialty: 'Pediatric Hospital Medicine',
  },
  {
    specialty: 'Periodontics',
    health_center: 'nm',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Periodontics',
    health_center: 'elmhurst',
    Specialty: 'Dentistry',
    Subspecialty: '',
  },
  {
    specialty: 'Peripheral Vascular Specialists',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine & Rehabilitation',
    health_center: 'advocate',
    Specialty: 'Physical Medicine & Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine & Rehabilitation',
    health_center: 'elmhurst',
    Specialty: 'Physical Medicine & Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine & Rehabilitation',
    health_center: 'northshore',
    Specialty: 'Physical Medicine & Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine & Rehabilitation, Electrodiagnostic Medicine',
    health_center: 'elmhurst',
    Specialty: 'Physical Medicine & Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine & Rehabilitation, Pain Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pain Medicine / Management',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine & Rehabilitation, Sports Medicine',
    health_center: 'elmhurst',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine and Rehabilitation',
    health_center: 'amita',
    Specialty: 'Physical Medicine & Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine and Rehabilitation',
    health_center: 'chicago',
    Specialty: 'Physical Medicine & Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine and Rehabilitation',
    health_center: 'nm',
    Specialty: 'Physical Medicine & Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Medicine and Rehabilitation,Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Physical Medicine & Rehabilitation',
    Subspecialty: '',
  },
  {
    specialty: 'Physical Therapy',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Physician Assistant',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Physician Assistant',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic & Reconstructive Surgery',
    health_center: 'northshore',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic & Reconstructive Surgery',
    health_center: 'elmhurst',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic & Reconstructive Surgery, Hand Surgery',
    health_center: 'elmhurst',
    Specialty: 'Plastic Surgery',
    Subspecialty: 'Hand Surgery',
  },
  {
    specialty: 'Plastic and Reconstructive Surgery',
    health_center: 'amita',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic and Reconstructive Surgery',
    health_center: 'chicago',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic and Reconstructive Surgery,Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic and Reconstructive Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty:
      'Plastic and Reconstructive Surgery,Surgery,Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Plastic Surgery',
    Subspecialty: 'Microvascular Surgery / Microsurgery',
  },
  {
    specialty: 'Plastic Surgery',
    health_center: 'nm',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic Surgery',
    health_center: 'advocate',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic Surgery',
    health_center: 'elmhurst',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Plastic Surgery Within the Head & Neck',
    health_center: 'elmhurst',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Facial Plastics and Reconstructive',
  },
  {
    specialty: 'Plastic/Reconstructive Surgery',
    health_center: 'advocate',
    Specialty: 'Plastic Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatric Surgery',
    health_center: 'elmhurst',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatric Surgery, Foot & Ankle Surgery',
    health_center: 'elmhurst',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatry',
    health_center: 'advocate',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatry',
    health_center: 'amita',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatry',
    health_center: 'nm',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatry',
    health_center: 'chicago',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatry (Feet)',
    health_center: 'northshore',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatry,Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Podiatry,Surgery',
    health_center: 'chicago',
    Specialty: 'Podiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Preventive Cardiology',
    health_center: 'nm',
    Specialty: 'Cardiology',
    Subspecialty: '',
  },
  {
    specialty: 'Primary Care',
    health_center: 'uic',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Primary Care Services',
    health_center: 'uic',
    Specialty: 'Internal Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatric-Mental Health Nurse Practitioner',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry',
    health_center: 'advocate',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry',
    health_center: 'amita',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry',
    health_center: 'nm',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry',
    health_center: 'chicago',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry',
    health_center: 'elmhurst',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry - Adult',
    health_center: 'northshore',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry - Child & Adolescent',
    health_center: 'northshore',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child / Adolescent',
  },
  {
    specialty: 'Psychiatry Services',
    health_center: 'uic',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry, Addiction Psychiatry',
    health_center: 'elmhurst',
    Specialty: 'Psychiatry',
    Subspecialty: 'Addiction',
  },
  {
    specialty: 'Psychiatry, Child & Adolescent Psychiatry',
    health_center: 'elmhurst',
    Specialty: 'Psychiatry',
    Subspecialty: 'Child / Adolescent',
  },
  {
    specialty: 'Psychiatry, Geriatric Psychiatry',
    health_center: 'elmhurst',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry,Anesthesiology',
    health_center: 'chicago',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychiatry,Neurology',
    health_center: 'chicago',
    Specialty: 'Psychiatry',
    Subspecialty: '',
  },
  {
    specialty: 'Psychology',
    health_center: 'advocate',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Psychology',
    health_center: 'nm',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Psychology',
    health_center: 'northshore',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Psychology',
    health_center: 'chicago',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Psychology,Psychiatry',
    health_center: 'chicago',
    Specialty: 'Psychology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary',
    health_center: 'uic',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary Disease',
    health_center: 'amita',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary Disease',
    health_center: 'advocate',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary Disease',
    health_center: 'elmhurst',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary Disease, Critical Care Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty:
      'Pulmonary Disease, Critical Care Medicine, Interventional Pulmonology',
    health_center: 'elmhurst',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty: 'Pulmonary Disease, Critical Care Medicine, Sleep Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty: 'Pulmonary Disease, Pulmonary Disease',
    health_center: 'elmhurst',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary Disease, Sleep Medicine',
    health_center: 'elmhurst',
    Specialty: 'Pulmonology',
    Subspecialty: 'Sleep-Disordered Breathing',
  },
  {
    specialty: 'Pulmonary Medicine',
    health_center: 'advocate',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary Medicine (Pulmonology)',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary Medicine (Pulmonology),Critical Care Medicine',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty:
      'Pulmonary Medicine (Pulmonology),Critical Care Medicine,Hospital Medicine',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty:
      'Pulmonary Medicine (Pulmonology),Critical Care Medicine,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty:
      'Pulmonary Medicine (Pulmonology),Critical Care Medicine,Interventional Pulmonology',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty:
      'Pulmonary Medicine (Pulmonology),Critical Care Medicine,Sleep Medicine',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty:
      'Pulmonary Medicine (Pulmonology),Critical Care Medicine,Transplant',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty: 'Pulmonary Medicine (Pulmonology),Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Pulmonary Medicine (Pulmonology),Transplant',
    health_center: 'chicago',
    Specialty: 'Pulmonology',
    Subspecialty: 'Lung Transplantation',
  },
  {
    specialty: 'Pulmonary/Critical Care',
    health_center: 'northshore',
    Specialty: 'Pulmonology',
    Subspecialty: 'Critical Care Medicine',
  },
  {
    specialty: 'Pulmonology',
    health_center: 'nm',
    Specialty: 'Pulmonology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology',
    health_center: 'amita',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology',
    health_center: 'nm',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology',
    health_center: 'advocate',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology',
    health_center: 'chicago',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology',
    health_center: 'northshore',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology',
    health_center: 'elmhurst',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology',
    health_center: 'uic',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology,Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiation Oncology,Neuro-Oncology (Brain & Spinal Tumors)',
    health_center: 'chicago',
    Specialty: 'Radiation Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology',
    health_center: 'amita',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology',
    health_center: 'northshore',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology',
    health_center: 'uic',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology, Diagnostic',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology, Diagnostic, Breast Imaging',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Breast',
  },
  {
    specialty: 'Radiology, Diagnostic, Radiology Neuroradiology',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Radiology, Diagnostic, Vascular & Interventional Radiology',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Radiology, Research',
    health_center: 'northshore',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology,Breast Imaging',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Breast',
  },
  {
    specialty: 'Radiology,Family Medicine',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology,Head and Neck Cancer',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology,Neuro-Oncology (Brain & Spinal Tumors)',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Radiology,Neuroradiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Radiology,Neuroradiology,Pediatric Neuroradiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Neuroradiology',
  },
  {
    specialty: 'Radiology,Pediatric Radiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology,Pulmonary Medicine (Pulmonology)',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Radiology,Surgery',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: '',
  },
  {
    specialty: 'Refractive Surgery',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: '',
  },
  {
    specialty: 'Rehabilitation Services',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Reproductive Endocrinology',
    health_center: 'northshore',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Reproductive Endocrinology & Infertility',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty:
      'Reproductive Endocrinology & Infertility, Obstetrics & Gynecology',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Reproductive Endocrinology &amp; Infertility',
    health_center: 'uic',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Reproductive Endocrinology and Infertility',
    health_center: 'nm',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Reproductive Endocrinology and Infertility',
    health_center: 'advocate',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Reproductive Endocrinology and Infertility',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Reproductive Endocrinology and Infertility,OB/GYN',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Reproductive Endocrinology/Infertility',
    health_center: 'amita',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Reproductive Endocriniology & Infertility (REI)',
  },
  {
    specialty: 'Reproductive Genetics',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Research',
    health_center: 'northshore',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Resident Physician',
    health_center: 'advocate',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Rheumatology',
    health_center: 'amita',
    Specialty: 'Rheumatology',
    Subspecialty: '',
  },
  {
    specialty: 'Rheumatology',
    health_center: 'chicago',
    Specialty: 'Rheumatology',
    Subspecialty: '',
  },
  {
    specialty: 'Rheumatology',
    health_center: 'nm',
    Specialty: 'Rheumatology',
    Subspecialty: '',
  },
  {
    specialty: 'Rheumatology',
    health_center: 'advocate',
    Specialty: 'Rheumatology',
    Subspecialty: '',
  },
  {
    specialty: 'Rheumatology',
    health_center: 'elmhurst',
    Specialty: 'Rheumatology',
    Subspecialty: '',
  },
  {
    specialty: 'Rheumatology',
    health_center: 'uic',
    Specialty: 'Rheumatology',
    Subspecialty: '',
  },
  {
    specialty: 'Rheumatology (Arthritis)',
    health_center: 'northshore',
    Specialty: 'Rheumatology',
    Subspecialty: '',
  },
  {
    specialty: 'Rheumatology,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Rheumatology',
    Subspecialty: '',
  },
  {
    specialty: 'Rhinology & Skull Base Surgery',
    health_center: 'nm',
    Specialty: 'Otolaryngology',
    Subspecialty: 'Rhinology',
  },
  {
    specialty: 'Robotic Surgery',
    health_center: 'uic',
    Specialty: 'General Surgery',
    Subspecialty: 'Laparoscopic Surgery / MIS',
  },
  {
    specialty: 'Shoulder and Elbow Surgery',
    health_center: 'nm',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Shoulder & Elbow',
  },
  {
    specialty: 'Sickle Cell',
    health_center: 'uic',
    Specialty: 'Hematology / Oncology',
    Subspecialty: 'Hematology',
  },
  {
    specialty: 'Sleep Medicine',
    health_center: 'amita',
    Specialty: 'Sleep Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sleep Medicine',
    health_center: 'nm',
    Specialty: 'Sleep Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sleep Medicine',
    health_center: 'advocate',
    Specialty: 'Sleep Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sleep Medicine',
    health_center: 'elmhurst',
    Specialty: 'Sleep Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sleep Medicine, Pulmonary Disease, Critical Care Medicine',
    health_center: 'elmhurst',
    Specialty: 'Sleep Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sleep Medicine,Internal Medicine',
    health_center: 'chicago',
    Specialty: 'Sleep Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sleep Psychologist',
    health_center: 'nm',
    Specialty: 'Sleep Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sleep Science Center',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Sleep Surgery',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Spine Surgery',
    health_center: 'nm',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Spine Surgery',
    health_center: 'northshore',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty:
      'Spine Surgery,Neurosurgery,Neuro-Oncology (Brain & Spinal Tumors),Surgery',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty:
      'Spine Surgery,Neurosurgery,Surgery,Neuro-Oncology (Brain & Spinal Tumors)',
    health_center: 'chicago',
    Specialty: 'Neurological Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Spine Surgery,Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Orthopedic Surgery',
    Subspecialty: 'Spine',
  },
  {
    specialty: 'Sports Medicine',
    health_center: 'nm',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sports Medicine',
    health_center: 'northshore',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sports Medicine',
    health_center: 'advocate',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sports Medicine',
    health_center: 'amita',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Sports Medicine,Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty:
      'Sports Medicine,Orthopaedic Surgery,Pediatric Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty:
      'Sports Medicine,Physical Medicine and Rehabilitation,Orthopaedic Surgery',
    health_center: 'chicago',
    Specialty: 'Sports Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Stroke Center',
    health_center: 'uic',
    Specialty: 'Neurology',
    Subspecialty: 'Stroke / Neurovascular',
  },
  {
    specialty: 'Supportive Care',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Surgery of the Hand - Hand Surgery',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Surgery,Endocrine Surgery,General Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Surgery,General Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Surgery,Surgical Oncology,General Surgery',
    health_center: 'chicago',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Surgery,Trauma Surgery,Plastic and Reconstructive Surgery',
    health_center: 'chicago',
    Specialty: 'Trauma Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Surgery,Urology,Pediatric Urology',
    health_center: 'chicago',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Surgery,Vascular Surgery,General Surgery',
    health_center: 'chicago',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Surgical Critical Care',
    health_center: 'amita',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Surgical Critical Care',
    health_center: 'advocate',
    Specialty: 'General Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Surgical Oncology',
    health_center: 'nm',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Surgical Oncology',
    health_center: 'northshore',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Surgical Oncology',
    health_center: 'uic',
    Specialty: 'Surgical Oncology',
    Subspecialty: '',
  },
  {
    specialty: 'Surgical Pathology',
    health_center: 'nm',
    Specialty: 'Pathology',
    Subspecialty: '',
  },
  {
    specialty: 'Surgical Retina',
    health_center: 'nm',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Retina / Uveitis',
  },
  {
    specialty: 'Surgical Services',
    health_center: 'uic',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Therapy',
    health_center: 'amita',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Thoracic & Cardiac Surgery',
    health_center: 'elmhurst',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery; Thoracic Surgery',
  },
  {
    specialty: 'Thoracic and Cardiac Surgery',
    health_center: 'amita',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery; Thoracic Surgery',
  },
  {
    specialty: 'Thoracic and Cardiac Surgery',
    health_center: 'advocate',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery; Thoracic Surgery',
  },
  {
    specialty: 'Thoracic Imaging',
    health_center: 'nm',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Thoracic Surgery',
    health_center: 'northshore',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Thoracic Surgery',
  },
  {
    specialty: 'Thoracic Surgery',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Thoracic Surgery',
  },
  {
    specialty: 'Thoracic Surgery',
    health_center: 'nm',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Thoracic Surgery',
  },
  {
    specialty: 'Thoracic Surgery',
    health_center: 'advocate',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Thoracic Surgery',
  },
  {
    specialty: 'Thoracic Surgery,Cardiac Surgery (Heart Surgery)',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery; Thoracic Surgery',
  },
  {
    specialty:
      'Thoracic Surgery,Cardiac Surgery (Heart Surgery),Transplant,Surgery',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Cardiac Surgery; Thoracic Surgery',
  },
  {
    specialty: 'Thoracic Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Thoracic Surgery',
  },
  {
    specialty: 'Thoracic Surgery,Transplant',
    health_center: 'chicago',
    Specialty: 'Cardiothoracic Surgery',
    Subspecialty: 'Thoracic Surgery',
  },
  {
    specialty: 'Thyroid and Parathyroid Surgery',
    health_center: 'nm',
    Specialty: 'Endocrine Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Transplant',
    health_center: 'uic',
    Specialty: 'Transplant Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Transplant',
    health_center: 'chicago',
    Specialty: 'Transplant Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Transplant Hepatology',
    health_center: 'nm',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Transplant Surgery',
    health_center: 'advocate',
    Specialty: 'Transplant Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Transplant,Gastroenterology,Hepatology (Liver Disease)',
    health_center: 'chicago',
    Specialty: 'Gastroenterology',
    Subspecialty: 'Hepatology / Transplant Hepatology',
  },
  {
    specialty: 'Transplant,Surgery',
    health_center: 'chicago',
    Specialty: 'Transplant Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Transplant,Surgery,Transplant Oncology',
    health_center: 'chicago',
    Specialty: 'Transplant Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Transplantation Surgery',
    health_center: 'nm',
    Specialty: 'Transplant Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Trauma Surgery',
    health_center: 'advocate',
    Specialty: 'Trauma Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Trauma Surgery',
    health_center: 'chicago',
    Specialty: 'Trauma Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Trauma Surgery',
    health_center: 'nm',
    Specialty: 'Trauma Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Trauma Surgery,Critical Care Medicine',
    health_center: 'chicago',
    Specialty: 'Trauma Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Trauma Surgery,General Surgery,Critical Care Medicine',
    health_center: 'chicago',
    Specialty: 'Trauma Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Trauma Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Trauma Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Urgent Care',
    health_center: 'advocate',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Urgent Care',
    health_center: 'uic',
    Specialty: 'Emergency Medicine',
    Subspecialty: '',
  },
  {
    specialty: 'Urogynecology',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Urogynecology',
    health_center: 'northshore',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Urogynecology',
    health_center: 'advocate',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Urogynecology and Pelvic Reconstructive Surgery',
    health_center: 'nm',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Urogynecology and Pelvic Reconstructive Surgery,OB/GYN',
    health_center: 'chicago',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Urogynecology, Obstetrics & Gynecology',
    health_center: 'elmhurst',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Urologic Oncology',
    health_center: 'nm',
    Specialty: 'Urology',
    Subspecialty: 'Urologic Oncology',
  },
  {
    specialty: 'Urology',
    health_center: 'advocate',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology',
    health_center: 'amita',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology',
    health_center: 'nm',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology',
    health_center: 'northshore',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology',
    health_center: 'elmhurst',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology',
    health_center: 'uic',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology',
    health_center: 'chicago',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology, Pediatric Urology',
    health_center: 'elmhurst',
    Specialty: 'Urology',
    Subspecialty: 'Pediatric Urology',
  },
  {
    specialty: 'Urology, Urologic Oncology',
    health_center: 'elmhurst',
    Specialty: 'Urology',
    Subspecialty: 'Urologic Oncology',
  },
  {
    specialty: 'Urology,General Surgery',
    health_center: 'chicago',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology,Surgery',
    health_center: 'chicago',
    Specialty: 'Urology',
    Subspecialty: '',
  },
  {
    specialty: 'Urology,Surgery,Urologic Oncology (Urologic Cancer)',
    health_center: 'chicago',
    Specialty: 'Urology',
    Subspecialty: 'Urologic Oncology',
  },
  {
    specialty: 'Urology,Urologic Oncology (Urologic Cancer)',
    health_center: 'chicago',
    Specialty: 'Urology',
    Subspecialty: 'Urologic Oncology',
  },
  {
    specialty: 'Urology,Urologic Oncology (Urologic Cancer),Surgery',
    health_center: 'chicago',
    Specialty: 'Urology',
    Subspecialty: 'Urologic Oncology',
  },
  {
    specialty: 'Vascular & Interventional Radiology',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Vascular & Interventional Radiology, Radiology',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Vascular & Interventional Radiology, Radiology, Diagnostic',
    health_center: 'elmhurst',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Vascular and Interventional Radiology',
    health_center: 'nm',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Vascular and Interventional Radiology',
    health_center: 'amita',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Vascular and Interventional Radiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Vascular and Interventional Radiology,Radiology',
    health_center: 'chicago',
    Specialty: 'Radiology',
    Subspecialty: 'Interventional Radiology',
  },
  {
    specialty: 'Vascular Medicine',
    health_center: 'northshore',
    Specialty: '',
    Subspecialty: '',
  },
  {
    specialty: 'Vascular Neurology',
    health_center: 'advocate',
    Specialty: 'Neurology',
    Subspecialty: 'Stroke / Neurovascular',
  },
  {
    specialty: 'Vascular Neurology and Stroke',
    health_center: 'nm',
    Specialty: 'Neurology',
    Subspecialty: 'Stroke / Neurovascular',
  },
  {
    specialty: 'Vascular Surgery',
    health_center: 'amita',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Vascular Surgery',
    health_center: 'advocate',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Vascular Surgery',
    health_center: 'elmhurst',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Vascular Surgery',
    health_center: 'nm',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Vascular Surgery',
    health_center: 'uic',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Vascular Surgery',
    health_center: 'northshore',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Vascular Surgery',
    health_center: 'chicago',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Vascular Surgery,Surgery',
    health_center: 'chicago',
    Specialty: 'Vascular Surgery',
    Subspecialty: '',
  },
  {
    specialty: 'Vitreo-Retinal Surgery',
    health_center: 'elmhurst',
    Specialty: 'Ophthalmology',
    Subspecialty: 'Retina / Uveitis',
  },
  {
    specialty: "Women's Pelvic Health",
    health_center: 'uic',
    Specialty: 'Obstetrics / Gynecology (OB-GYN)',
    Subspecialty: 'Female Pelvic & Reconstructive Surgery (Urogynecology)',
  },
  {
    specialty: 'Wound & Ostomy Care',
    health_center: 'elmhurst',
    Specialty: 'Wound Care',
    Subspecialty: '',
  },
  {
    specialty: 'Wound Care',
    health_center: 'advocate',
    Specialty: 'Wound Care',
    Subspecialty: '',
  },
  {
    specialty: 'Wound Care',
    health_center: 'uic',
    Specialty: 'Wound Care',
    Subspecialty: '',
  },
  {
    specialty: '',
    health_center: 'chicago',
    Specialty: 'Wound Care',
    Subspecialty: '',
  },
];
