import {
  Typography,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TableSortLabel,
  Pagination,
} from '@mui/material';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  encryptNumber,
  getAge,
  GetComparator,
  StableSort,
  TableHeaders,
} from '../../constants';
import * as moment from 'moment';
import { CustomButton } from '../../components/customButton';
import { NotificationNumber } from '../../components/notificationNumber';
import { Stack } from '@mui/system';
import TooltipIcon from '../../Icons/tooltipIcon';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomChip from '../../components/customChip';
import upDownArrowIcon from '../../Icons/arrowUpDownIcon';

const rowsPerPage = 5;
const RefferedTo = ({ referralData }) => {
  let text = 'to';

  if (referralData.forwardRef?.ReferredBy) {
    text = 'Forwarded to';
  }
  if (
    referralData?.unRegisteredUser &&
    referralData?.referredToIdUnRegistered
  ) {
    return `${text} ${CapitalizeFirstLetter(
      referralData?.unRegisteredUser?.first_name,
    )}
  ${CapitalizeFirstLetter(referralData?.unRegisteredUser?.last_name)}${
      referralData?.unRegisteredUser?.doctorDetail[0]?.suffix ? ', ' : ''
    }${
      referralData?.unRegisteredUser?.doctorDetail[0]?.suffix
        ? referralData?.unRegisteredUser?.doctorDetail[0]?.suffix
            ?.replaceAll('.', '')
            ?.replaceAll(',', ', ')
        : ''
    }`;
  }
  return `${text} ${CapitalizeFirstLetter(referralData?.ReferredTo?.firstName)}
  ${CapitalizeFirstLetter(referralData?.ReferredTo?.lastName)},${
    referralData?.ReferredTo?.prefix
      ? ` ${referralData?.ReferredTo?.prefix?.replaceAll(',', ', ')}`
      : ''
  }`;
};

const ReferralTable = ({
  tableData,
  heading,
  handleDrawer,
  handleCancelReferral,
  user,
}) => {
  const [page, setPage] = React.useState(0);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePagePagination = (event, newPage) => {
    setPage(newPage - 1);
  };

  return (
    <>
      <Typography
        color={darkTextColor}
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItem: 'center',
          fontWeight: 505,
          textTransform: 'uppercase',
        }}
        gutterBottom
        variant="h7"
      >
        {heading}&nbsp;{<NotificationNumber number={tableData.length} />}
      </Typography>

      <Table>
        <colgroup>
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '30%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            {TableHeaders.map((header, i) => (
              <TableCell
                style={{
                  fontWeight: '400',
                  fontSize: '1em',
                  textTransform: 'None',
                }}
                key={header.label}
              >
                <TableSortLabel
                  active={header.orderBy === orderBy}
                  direction={header.orderBy === orderBy ? order : 'asc'}
                  onClick={() => handleRequestSort(i, header.orderBy)}
                  hideSortIcon={orderBy === orderBy}
                  IconComponent={upDownArrowIcon}
                  sx={{
                    color: header.orderBy === orderBy && '#000000 !important',
                    fontWeight: header.orderBy === orderBy && '550',
                  }}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ alignItems: 'center', cursor: 'pointer' }}>
          {StableSort(tableData, GetComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((data, i) => (
              <TableRow
                onClick={(e) => {
                  if (e.target.id === 'accept') {
                    handleDrawer(data, true);
                  } else if (e.target.id === 'decline') {
                    handleDrawer(data, false);
                  } else if (e.target.id === 'cancel') {
                    handleCancelReferral(data.id);
                  } else {
                    const profileNumber = encryptNumber(data.id);
                    navigate(`/patient/${profileNumber}`);
                  }
                }}
                className="mui-table-row"
                sx={{ m: 5 }}
              >
                <TableCell>
                  <Typography color={darkTextColor}>
                    {data.firstname}&nbsp;{data.lastname}
                  </Typography>
                  <Typography color={darkTextColor}>
                    {getAge(data?.dateOfBirth)} {data?.sex}
                  </Typography>
                </TableCell>
                <TableCell>
                  <CustomChip
                    type={data?.levelOfUrgency}
                    sx={{
                      height: '1.5em',
                      width: 'fit-content',
                      fontSize: '1.1em',
                    }}
                  />
                </TableCell>
                <TableCell>
                  {data.status === null && (
                    <CustomChip
                      type={'Pending'}
                      sx={{
                        height: '1.5em',
                        width: 'fit-content',
                        fontSize: '1.1em',
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {data?.ReferralFrom && data?.referralFrom === user?.id ? (
                    <Typography variant="body1" color={darkTextColor}>
                      <span>{moment(data.createdAt).fromNow()}</span>
                      {', '}
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ flexWrap: 'nowrap' }}
                      >
                        <span>
                          {`to ${CapitalizeFirstLetter(
                            data?.ReferredBy?.firstName,
                          )} ${CapitalizeFirstLetter(
                            data?.ReferredBy?.lastName,
                          )}, ${
                            data?.ReferredBy?.prefix?.replaceAll(',', ', ') ||
                            ''
                          }
                     `}
                          {`Forwarded to ${CapitalizeFirstLetter(
                            data?.ReferredTo?.firstName,
                          )} ${CapitalizeFirstLetter(
                            data?.ReferredTo?.lastName,
                          )}, ${
                            data?.ReferredTo?.prefix?.replaceAll(',', ', ') ||
                            ''
                          }
                      `}
                        </span>
                        <Tooltip
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                color: '#FFFFFF',
                                backgroundColor: '#212634',
                              },
                            },
                            arrow: {
                              sx: {
                                color: '#212634',
                              },
                            },
                          }}
                          title="Responding within less than 5 days will improve your chances of receiving new referrals."
                        >
                          <p style={{ marginTop: '5px' }}>
                            <TooltipIcon />
                          </p>
                        </Tooltip>
                      </Stack>
                    </Typography>
                  ) : (
                    <Typography color={darkTextColor}>
                      <span>{moment(data.createdAt).fromNow()}</span>
                      {', '}
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        sx={{ flexWrap: 'nowrap' }}
                      >
                        <span>
                          {data.forwardRef?.ReferredBy ? (
                            `by ${CapitalizeFirstLetter(
                              data?.forwardRef?.ReferredBy?.firstName,
                            )}
                      ${CapitalizeFirstLetter(
                        data?.forwardRef?.ReferredBy?.lastName,
                      )}${data?.forwardRef?.ReferredBy?.prefix ? ',' : ''}
                      ${
                        data?.forwardRef?.ReferredBy?.prefix?.replaceAll(
                          ',',
                          ', ',
                        ) || ''
                      }${data.forwardRef?.ReferredBy ? ', ' : ''}`
                          ) : (
                            <></>
                          )}
                          {heading === 'Sent' ? (
                            <RefferedTo referralData={data} />
                          ) : (
                            `${
                              data.forwardRef?.ReferredBy
                                ? 'Forwarded by'
                                : 'by'
                            }  ${CapitalizeFirstLetter(
                              data?.ReferredBy?.firstName,
                            )}${' '}
                      ${CapitalizeFirstLetter(
                        data?.ReferredBy?.lastName || '',
                      )}${data?.ReferredBy?.prefix ? ',' : ''}${
                              data?.ReferredBy?.prefix
                                ? ` ${data?.ReferredBy?.prefix?.replaceAll(
                                    ',',
                                    ', ',
                                  )}`
                                : ''
                            }`
                          )}
                        </span>
                        <Tooltip
                          arrow
                          componentsProps={{
                            tooltip: {
                              sx: {
                                color: '#FFFFFF',
                                backgroundColor: '#212634',
                              },
                            },
                            arrow: {
                              sx: {
                                color: '#212634',
                              },
                            },
                          }}
                          title="Responding within less than 5 days will improve your chances of receiving new referrals."
                        >
                          <p style={{ marginTop: '5px' }}>
                            <TooltipIcon />
                          </p>
                        </Tooltip>
                      </Stack>
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          color: '#FFFFFF',
                          backgroundColor: '#212634',
                        },
                      },
                      arrow: {
                        sx: {
                          color: '#212634',
                        },
                      },
                    }}
                    title={'Click to open referral details'}
                  >
                    <Typography
                      color={darkTextColor}
                      sx={{
                        bgcolor: 'transparent',
                        overflowWrap: 'anywhere',
                      }}
                      className="referral-table-reason"
                    >
                      {data.reason}
                    </Typography>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Stack
                    direction={{ md: 'row', sm: 'column' }}
                    alignItems="center"
                    spacing={2}
                    sx={{
                      flexWrap: { md: 'nowrap', sm: 'wrap' },
                      justifyContent: { md: 'end', sm: 'center' },
                    }}
                  >
                    {heading === 'Received' ? (
                      <Grid
                        container
                        gap={2}
                        sx={{
                          width: 'min-content',
                          flexWrap: { md: 'nowrap', sm: 'wrap' },
                        }}
                      >
                        <CustomButton
                          id="decline"
                          type="error"
                          width="8em"
                          size="large"
                          text="Decline"
                          onClick={() => {
                            handleDrawer(data, false);
                          }}
                        />
                        <CustomButton
                          id="accept"
                          size="large"
                          width="8em"
                          text="Accept"
                          onClick={() => {
                            handleDrawer(data, true);
                          }}
                        />
                      </Grid>
                    ) : (
                      <CustomButton
                        id="cancel"
                        size="large"
                        width="8em"
                        text="Cancel"
                        type="cancel"
                        onClick={() => {
                          handleCancelReferral(data.id);
                        }}
                      />
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Stack
        sx={{
          color: 'black !important',
          position: 'relative',
          borderTop: '1px solid #E2E2E2',
          p: 1,
          height: '50px',
        }}
        direction="row"
      >
        <div
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
          }}
        >
          <Pagination
            count={
              tableData?.length / rowsPerPage >= 1
                ? Math.ceil(tableData?.length / rowsPerPage)
                : 1
            }
            color="primary"
            variant="outlined"
            shape="rounded"
            onChange={handleChangePagePagination}
          />
        </div>
      </Stack>
    </>
  );
};

export default ReferralTable;
