const InsuranceColoredIcon = () => (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.57142 0L0.725746 1.14286L0 1.31989V5.79414V6.17705C0.0301022 6.73547 0.135712 7.28725 0.314285 7.81708C0.436989 8.21452 0.601782 8.59742 0.805609 8.95993C1.11428 9.51223 1.49896 10.0181 1.94846 10.4628C2.09693 10.6 2.24566 10.737 2.40561 10.8628L2.54285 10.9656V10.9658C3.15484 11.424 3.84107 11.7737 4.57139 12C5.54177 11.6944 6.42928 11.1707 7.1658 10.4686C7.29718 10.3373 7.42855 10.2059 7.54871 10.063L7.54845 10.0628C8.58062 8.8806 9.14714 7.36355 9.14283 5.79444V1.32581L6.70281 0.628602L4.57142 0ZM7.26275 4.14288C7.37091 4.25028 7.43188 4.3962 7.43188 4.54849C7.43188 4.70104 7.37091 4.84696 7.26275 4.95436L4.40561 7.81149C4.29847 7.91965 4.15229 7.98037 4 7.98037C3.84771 7.98037 3.70153 7.91966 3.59439 7.81149L2.16582 6.38293C1.94159 6.15869 1.94159 5.79543 2.16582 5.57143C2.3898 5.34745 2.75307 5.34745 2.97705 5.57143L4.00001 6.62299L6.45153 4.16585C6.55867 4.05769 6.70484 3.99672 6.85714 3.99672C7.00943 3.99672 7.15561 4.05769 7.26275 4.16585V4.14288Z"
      fill="#47BB92"
    />
  </svg>
);

export default InsuranceColoredIcon;
