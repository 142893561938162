import React from 'react';
import {
  Link,
  Typography,
  Card,
  Avatar,
  Stack,
  IconButton,
} from '@mui/material';
import { darkTextColor } from '../../constants';
import CloseLight from '../../Icons/closeLight';
const CardPrompt = ({ linkText, link, text, removeCard, index }) => {
  return (
    <Card sx={{ width: '100%', p: 2, bgcolor: 'rgba(71, 187, 146,0.15)' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" fontSize="14px" color={darkTextColor}>
          <Link href={link}>{linkText}</Link>
          {` ${text}`}
        </Typography>
        <IconButton onClick={() => removeCard(index)}>
          <CloseLight />
        </IconButton>
      </Stack>
    </Card>
  );
};
export const EmptyReferrals = ({ data, removeCard }) => {
  return (
    <Stack
      alignItems="flex-start"
      justifyContent="start"
      direction="column"
      sx={{
        width: '100%',
        height: '100%',
      }}
      gap={0.5}
    >
      <Typography
        variant="body1"
        fontSize="16px"
        fontWeight="600"
        color={darkTextColor}
      >
        No pending referrals...yet!
      </Typography>
      <Typography variant="body1" fontSize="14px" color={darkTextColor}>
        Time to connect with colleagues and send or receive referrals.
      </Typography>
      <Stack direction="row" gap={2} sx={{ mt: 3, mb: 1 }}>
        <Avatar
          sx={{
            bgcolor: '#EBEBEB !important',
            color: '#000000',
          }}
          variant="rounded"
        >
          1
        </Avatar>
        <Stack>
          <Typography
            variant="body1"
            fontSize="14px"
            fontWeight="600"
            color={darkTextColor}
          >
            Select the Right Specialist
          </Typography>
          <Typography
            variant="body1"
            fontSize="14px"
            color={darkTextColor}
            sx={{ opacity: '0.7' }}
          >
            Use efficient search function with map and filters; evaluate patient
            needs and expertise on user profile.
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} sx={{ my: 1 }}>
        <Avatar
          sx={{
            bgcolor: '#EBEBEB !important',
            color: '#000000',
          }}
          variant="rounded"
        >
          2
        </Avatar>
        <Stack>
          <Typography
            variant="body1"
            fontSize="14px"
            fontWeight="600"
            color={darkTextColor}
          >
            Compile Patient Information
          </Typography>
          <Typography
            variant="body1"
            fontSize="14px"
            color={darkTextColor}
            sx={{ opacity: '0.7' }}
          >
            Assemble medical history, symptoms, and test results for a
            comprehensive referral.
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} sx={{ my: 1 }}>
        <Avatar
          sx={{
            bgcolor: '#EBEBEB !important',
            color: '#000000',
          }}
          variant="rounded"
        >
          3
        </Avatar>
        <Stack>
          <Typography
            variant="body1"
            fontSize="14px"
            fontWeight="600"
            color={darkTextColor}
          >
            Craft an Effective Referral Message
          </Typography>
          <Typography
            variant="body1"
            fontSize="14px"
            color={darkTextColor}
            sx={{ opacity: '0.7' }}
          >
            Concisely communicate purpose, key patient details, and special
            considerations.
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} sx={{ my: 1, mb: 3 }}>
        <Avatar
          sx={{
            bgcolor: '#EBEBEB !important',
            color: '#000000',
          }}
          variant="rounded"
        >
          4
        </Avatar>
        <Stack>
          <Typography
            variant="body1"
            fontSize="14px"
            fontWeight="600"
            color={darkTextColor}
          >
            Track Referral Progress
          </Typography>
          <Typography
            variant="body1"
            fontSize="14px"
            color={darkTextColor}
            sx={{ opacity: '0.7' }}
          >
            Track referrals, follow up as needed, and maintain prompt
            communication.
          </Typography>
        </Stack>
      </Stack>
      {data?.length && (
        <Typography
          variant="body1"
          fontSize="14px"
          fontWeight="600"
          color={darkTextColor}
          sx={{ my: 2 }}
        >
          More you can do now
        </Typography>
      )}
      <Stack direction="column" sx={{ width: '100%' }} gap={2}>
        {data &&
          data.map((card, index) => {
            if (card.isVisible) {
              return (
                <CardPrompt
                  link={card.link}
                  linkText={card.linkText}
                  text={card.text}
                  index={index}
                  removeCard={removeCard}
                />
              );
            }
          })}
      </Stack>
    </Stack>
  );
};
