import { Button } from '@mui/material';
import { darkTextColor } from '../../constants';

export const CircleButton = ({ onClick, text = 'Add', disabled = false }) => {
  return (
    <Button
      color="primary"
      size="large"
      onClick={(e) => onClick(e)}
      variant="contained"
      disabled={disabled}
      sx={{
        m: 1,
        height: '40px',
        borderRadius: '100px',
        color: '#47BB92',
        border: '1px solid #47BB92',
        background: 'transparent',
        '&:hover': {
          color: 'white',
        },
        '&:disabled': {
          color: darkTextColor,
          border: '1px solid rgba(55, 65, 81, 0.12)',
        },
      }}
    >
      {text}
    </Button>
  );
};
