import { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Autocomplete,
  Card,
  Popper,
} from '@mui/material';
import { CustomTextFieldNoBorders } from '../../../components/customTextField';
import CloseLight from '../../../Icons/closeLight';
import { specialtyFilters } from '../../../constants/specialties_newest';
import { CircleButton } from '../../EditProfile/circleButton';

const textColor = '#212634';

const RemoveButton = (props) => {
  const { handleClick } = props;
  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      <CloseLight />
    </div>
  );
};

const getUserSpecialty = (spec) => {
  if (spec) {
    return (
      specialtyFilters
        .find((inco) => inco.name === spec)
        ?.subspecialties?.sort() || []
    );
  }
  return [];
};

const Selection = (props) => {
  const {
    selected,
    specialities,
    handleAddSubselection,
    removeSelection,
    removeSubSelection,
  } = props;
  const [selectedSub, setSelectedSub] = useState();

  const handleAddSubSpecialty = (value) => {
    setSelectedSub(value);
  };
  const handleAddSub = (selectSub = selectedSub) => {
    if (
      selectSub &&
      selected?.sub?.length < 20 &&
      !selected?.sub?.includes(selectSub)
    ) {
      setSelectedSub();
      handleAddSubselection(selected.specialty, selectSub);
    }
  };

  const subSpec = getUserSpecialty(selected?.specialty);
  return (
    <Card
      key={specialities}
      sx={{
        width: '100%',
        borderRadius: 0,
        border: '1px solid #E2E2E2',
        boxShadow: 'none',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#D4EEDD',
          px: 3,
          py: 2,
          borderBottom: '1px solid #E2E2E2',
        }}
      >
        <Typography
          color={textColor}
          variant="h6"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          fontWeight="500"
          fontSize="14px"
        >
          {selected.specialty} &nbsp; &nbsp;{' '}
          {
            <RemoveButton
              handleClick={() => removeSelection(selected.specialty)}
            />
          }
        </Typography>
      </Box>
      <Grid sx={{ mt: 2, mr: 2, p: 3, pt: 1 }} container direction="column">
        <Grid
          container
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ border: '1px solid #E2E2E2', borderRadius: '4px' }}
        >
          <Grid item sx={{ flexGrow: 9 }}>
            <Autocomplete
              freeSolo
              getOptionDisabled={(option) => selected?.sub.includes(option)}
              options={subSpec}
              PopperComponent={({ style, ...props }) => (
                <Popper {...props} style={{ ...style, height: 0 }} />
              )}
              value={selectedSub || ''}
              ListboxProps={{ style: { maxHeight: '20vh' } }}
              onInputChange={(event, value, reason) => {
                console.log(value, subSpec, reason);
                if (reason === 'reset' && subSpec.includes(value)) {
                  handleAddSub(value?.slice(0, 255));
                } else {
                  setSelectedSub(value?.slice(0, 255));
                }
              }}
              sx={{
                width: '100%',
                color: 'black',
                p: 0,
              }}
              renderInput={(params) => (
                <CustomTextFieldNoBorders
                  value={selectedSub || ''}
                  {...params}
                  placeholder="Search for a subspecialty (Optional)"
                />
              )}
            />
          </Grid>
          <CircleButton onClick={() => handleAddSub()} />
        </Grid>
        <Grid container direction="row" gap={2} sx={{ mt: 2 }}>
          {selected?.sub.length ? (
            selected.sub.map((subSelection, index) => (
              <Card
                key={`${subSelection}-${index}`}
                sx={{
                  p: 1,
                  border: '1px solid #E2E2E2',
                  width: '100%',
                  boxShadow: 'none',
                  borderRadius: '0px',
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    color={textColor}
                    gutterBottom
                    variant="body1"
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      margin: 0,
                    }}
                    fontSize="14px"
                  >
                    {subSelection} &nbsp; &nbsp;{' '}
                  </Typography>
                  <RemoveButton
                    handleClick={() =>
                      removeSubSelection(selected.specialty, subSelection)
                    }
                  />
                </Grid>
              </Card>
            ))
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default Selection;
