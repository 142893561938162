import WaitingVerification from '../Home/waitingVerification';
import { useNavigate } from 'react-router-dom';

const Verification = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/overview');
  };

  return <WaitingVerification handleContinue={handleContinue} />;
};

export default Verification;
