import { Typography, Card } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';
import Carousel from 'react-multi-carousel';
import { darkTextColor } from '../../constants';
import './simple.css';

const SaveTimeImg = 'assets/saveTime.svg';
const ImprovePatientCare = 'assets/improvePatientCare.svg';
const GrowYourPractice = 'assets/growYourPractice.svg';
const IntegrateEasily = 'assets/integrateEasily.svg';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 4,
  },
};

const sampleData = [
  {
    title: 'Save time and resources',
    text: 'Streamline the referral process so you can focus on providing the best care possible to your patients.',
    img: SaveTimeImg,
  },
  {
    title: 'Improve patient care',
    text: 'Match patients with the right specialist and enhance care with our directory and real-time tracking.',
    img: ImprovePatientCare,
  },
  {
    title: 'Grow your practice',
    text: 'Build personal networks with specialists and increase your patient referrals by marketing yourself to other doctors.',
    img: GrowYourPractice,
  },
  {
    title: 'Integrate easily',
    text: 'Easily integrate into your existing workflow, making it easy to get started and start improving your patient care.',
    img: IntegrateEasily,
  },
];
const Tile = ({ title, text, img }) => {
  return (
    <Card
      sx={{
        backgroundColor: '#E0E6E0',
        p: 3,
        maxWidth: '330px',
        minHeight: '380px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        userSelect: 'none',
        marginRight: '10px',
      }}
    >
      <Stack
        direction="column"
        gap={1}
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        <img
          src={img}
          style={{ width: '40%', marginBottom: '15px' }}
          alt="card-image"
        />
        <Typography
          color={darkTextColor}
          fontSize="16px"
          fontWeight="600"
          sx={{ textAlign: 'center' }}
        >
          {title}
        </Typography>
        <Typography color={darkTextColor} sx={{ textAlign: 'center' }}>
          {text}
        </Typography>
      </Stack>
    </Card>
  );
};

class Simple extends React.Component {
  state = { additionalTransfrom: 0 };
  render() {
    return (
      <Carousel
        ssr={false}
        responsive={responsive}
        arrows={false}
        containerClass="carousel-container-with-scrollbar"
      >
        {sampleData.map(({ title, text, img }) => (
          <Tile title={title} text={text} img={img} />
        ))}
      </Carousel>
    );
  }
}

export default Simple;
