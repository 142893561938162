import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { darkTextColor } from '../../constants';
import { Grid, IconButton } from '@mui/material';
import CloseRounded from '@mui/icons-material/CloseRounded';

const names = ['Less than 5 miles', 'Less than 10 miles', 'Less than 25 miles'];
const distance = [5, 10, 25];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const getValue = (placeHolder, value) => {
  return placeHolder === 'Distance' ? names[distance.indexOf(value)] : value;
};
export const CustomSelect = ({
  placeHolder,
  flexGrow = 1,
  options = names,
  width,
  currentState,
  isDisabled,
  setState,
}) => {
  const [value, setValue] = React.useState(currentState);
  const SelectRef = React.useRef();
  const handleChange = (event) => {
    console.log('change');
    const {
      target: { value },
    } = event;
    if (placeHolder === 'Distance') {
      setState(distance[names.indexOf(value)]);
      setValue(value);
    } else {
      setState(value);
      setValue(value);
    }
  };

  return (
    <Grid sx={{ maxWidth: width }}>
      <FormControl sx={{ width: width }}>
        <Select
          ref={SelectRef}
          displayEmpty
          autoWidth
          disabled={isDisabled}
          value={
            value === '' ? placeHolder : getValue(placeHolder, currentState)
          }
          onChange={handleChange}
          sx={{
            color: darkTextColor,
            border: '1px solid #E2E2E2',
            borderRadius: '4px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'pre',
            '& .MuiSelect-outlined': {
              padding: '10px',
            },
          }}
          endAdornment={
            currentState && (
              <IconButton
                variant="plain"
                onClick={() => {
                  setValue(undefined);
                  setState(undefined);
                  if (SelectRef.current) {
                    console.log(SelectRef.current);
                    SelectRef.current.target.value = '';
                  }
                }}
              >
                <CloseRounded />
              </IconButton>
            )
          }
          renderValue={(selected) => {
            if (!selected || !value) {
              return <em style={{ opacity: 0.5 }}>{placeHolder}</em>;
            }
            return <p sx={{ width: '100%' }}>{selected}</p>;
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
                maxWidth: width,
              },
            },
          }}
          placeholder={placeHolder}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              sx={{
                color: darkTextColor,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'pre',
                width: width,
              }}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
