import {
  Box,
  Button,
  Typography,
  Grid,
  Checkbox,
  DialogContent,
  DialogActions,
  Stack,
} from '@mui/material';
import { useState } from 'react';
import AlertDialog from '../../../components/alertDialog';
import CustomInputField from '../../../components/customInputField';
import {
  darkTextColor,
  PatientStatus,
  RESPONSE_STATUS,
} from '../../../constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Staffs from '../../../services/staff';
import { useContext } from 'react';
import UserContext from '../../../contexts/user/context';
import SuccessPrompt from '../../../components/successPrompt';
import WarningDialog from '../../../components/warningDialog';
import { CharacterLeftTypography } from '../../../components/characterLeftTypography';
import { ReferralInfo } from './referralInfo';

const AcceptReferral = ({
  referralData,
  user,
  selectedStaff,
  selectAll,
  updateSelectedStaff,
  deselectAll,
  handleCancel,
  updateStatus,
  referralLoading,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [warning, setWarning] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');
  const { token, setUser, acceptReferralDialogue, setAcceptReferralDialogue } =
    useContext(UserContext);

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email must be valid')
        .required('Email is required')
        .max(255, 'Email should be less than 255 characters'),
      name: Yup.string().required('Name is a required field'),
    }),
    onSubmit: async (values) => {
      const { email, name } = values;
      const response = await Staffs.addStaff(token, name, email);
      if (response.status === RESPONSE_STATUS.success) {
        setUser(response.body);
        setOpenDialog(false);
        formik.resetForm();
        setErrMessage('');
      }

      if (response.status === RESPONSE_STATUS.error) {
        setErrMessage(response.message);
      }
    },
  });

  const staffSelected = selectedStaff.find((selected) => selected === true);

  const handleAccept = () => {
    if (staffSelected) {
      setAcceptReferralDialogue(true);
    } else {
      setWarning(true);
    }
  };

  const handleClose = () => {
    setErrMessage('');
    setOpenDialog(false);
    formik.resetForm();
  };

  const handleDone = () => {
    const updatedStaff = user.staffs
      .map((staff, i) => {
        if (selectedStaff[i]) {
          return staff;
        }
      })
      .filter((staff) => staff)
      .map((staff) => staff?.id);

    updateStatus(
      PatientStatus.Accept,
      '',
      additionalComments,
      null,
      '',
      selectedStaff,
      updatedStaff,
    );
  };

  return (
    <>
      <WarningDialog
        title={'Warning'}
        open={warning}
        setOpen={setWarning}
        continueWarning={() => handleDone()}
        error
        closeButtonText={'Cancel'}
        continueButtonText={'Continue'}
        message={
          'Are you sure you would like to proceed without selecting a staff member to forward to? If so, you will be responsible for contacting the patient to schedule an appointment or will have to manually forward the referral to your staff.'
        }
        referralLoading={referralLoading}
      />
      <SuccessPrompt
        setOpenDialog={() => handleDone()}
        openDialog={acceptReferralDialogue}
        successMessage={
          'Patient referral accepted successfully. We will send the patient’s contact details to your office staff via email.'
        }
        referralLoading={referralLoading}
        handleCancel={() => handleDone()}
      />
      <AlertDialog
        title="Add staff"
        open={openDialog}
        setOpen={() => handleClose()}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ mb: 3 }}>
            <Typography color={darkTextColor} gutterBottom variant="body1">
              Include staff members that schedule appointments for your
              patients. For your convenience, accepted referrals will be
              forwarded to your staff for scheduling purposes.
            </Typography>
            <CustomInputField
              label="Name"
              error={Boolean(formik.touched.name && formik.errors.name)}
              fullWidth
              width="100%"
              name="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              placeholder="Name"
              value={formik.values.name}
            />
            {Boolean(formik.touched.name && formik.errors.name) && (
              <Typography color="error" gutterBottom variant="body2">
                {formik.touched.name && formik.errors.name}
              </Typography>
            )}
            <CustomInputField
              label="Work email"
              fullWidth
              width="100%"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              placeholder="Work email"
              error={Boolean(formik.touched.email && formik.errors.email)}
              value={formik.values.email}
            />
            {Boolean(formik.touched.email && formik.errors.email) && (
              <Typography color="error" gutterBottom variant="body2">
                {formik.touched.email && formik.errors.email}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Stack
              direction="column"
              alignItems="center"
              gap={1}
              sx={{ width: '100%' }}
            >
              <Button
                color="primary"
                size="large"
                variant="contained"
                width="100%"
                fullWidth
                sx={{ height: 40, mt: -3 }}
                type="submit"
              >
                Add Staff
              </Button>
              <Typography color="error" gutterBottom variant="body2">
                {errMessage}
              </Typography>
            </Stack>
          </DialogActions>
        </form>
      </AlertDialog>
      <Grid container justifyContent="space-between" sx={{ height: '100%' }}>
        <Box sx={{ overflow: 'scroll', maxHeight: '77vh' }}>
          <ReferralInfo referralData={referralData} />
          <Grid
            container
            gap={1}
            sx={{
              border: '1px solid #E2E2E2',
              p: 2,
              borderRadius: '4px',
              mt: 1,
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, fontSize: 16 }}
                color={darkTextColor}
              >
                Share confirmation with
              </Typography>
              {user?.staffs?.length ? (
                <Typography
                  variant="body2"
                  gutterBottom
                  sx={{ cursor: 'pointer' }}
                  color={darkTextColor}
                  onClick={() => {
                    if (staffSelected) {
                      deselectAll();
                    } else {
                      selectAll();
                    }
                  }}
                >
                  {staffSelected ? 'Deselect all' : 'Select all'}
                </Typography>
              ) : (
                <></>
              )}
            </Grid>
            {user?.staffs?.length ? (
              user.staffs.map((staff, index) => (
                <Grid
                  key={`${staff.name}-${index}`}
                  sx={{
                    width: '100%',
                    flexWrap: 'nowrap',
                    bgcolor: selectedStaff[index] && 'rgba(71,187,146,0.14)',
                    borderRadius: '100px',
                  }}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    sx={{ flexWrap: 'nowrap' }}
                  >
                    <Checkbox
                      checked={selectedStaff[index]}
                      onChange={(event, value) =>
                        updateSelectedStaff(value, index)
                      }
                    />
                    <Typography variant="body2" color={darkTextColor}>
                      {staff.name}
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <></>
            )}
            <Button
              color="primary"
              size="large"
              className="custom-button"
              onClick={() => setOpenDialog(!openDialog)}
              variant="contained"
              sx={{
                height: 40,
                mt: 0.5,
                bgcolor: '#FFFFFF',
                border: '1px solid #47BB92',
                color: '#47BB92',
              }}
            >
              + Add staff
            </Button>
          </Grid>
          {selectedStaff?.find((staff) => staff) ? (
            <Grid
              container
              gap={1}
              sx={{
                border: '1px solid #E2E2E2',
                p: 2,
                borderRadius: '4px',
                mt: 2,
              }}
            >
              <Grid item sx={{ flexGrow: 1 }}>
                <Typography
                  color={darkTextColor}
                  variant="body1"
                  sx={{ fontWeight: 600, fontSize: 16 }}
                >
                  Additional comments to staff
                </Typography>
                <CharacterLeftTypography
                  value={additionalComments}
                  handleChange={(e) => setAdditionalComments(e.target.value)}
                  maxLength={240}
                  rows={3}
                  label="Additional comments"
                  name="comments"
                />
                {Boolean(formik.touched.comments && formik.errors.comments) && (
                  <Typography
                    color="error"
                    gutterBottom
                    variant="body2"
                    sx={{ position: 'relative !important' }}
                  >
                    {formik.touched.comments && formik.errors.comments}
                  </Typography>
                )}
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
        <Grid
          container
          gap={1.5}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            flexWrap: 'nowrap',
            p: 2,
            mt: 1,
            position: 'absolute',
            bottom: '0px',
            right: 0,
            height: 'auto',
            width: '100%',
            backgroundColor: '#FFFFFF',
          }}
        >
          <Button
            color="error"
            size="large"
            onClick={() => handleCancel()}
            variant="contained"
            sx={{
              height: 40,
              flexGrow: 1,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            onClick={() => handleAccept()}
            variant="contained"
            disabled={referralLoading}
            sx={{ flexGrow: 4, height: 40 }}
          >
            Accept
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default AcceptReferral;
