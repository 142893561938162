import { InputBase } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const CustomInputField = styled((props) => <InputBase {...props} />)(
  ({ theme, width, height }) => ({
    'label + &': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      height: height && `${height}px`,
      position: 'relative',
      backgroundColor: '#FFFFFF',
      border: '0.5px solid #ced4da',
      fontSize: 16,
      marginTop: '5px',
      width: `${width ? width : 'auto'}`,
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      color: 'black',
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        border: '2px solid #47BB92',
        borderColor: theme.palette.primary.main,
      },
    },
  }),
);

export default CustomInputField;
