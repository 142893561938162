import configuration from '../config';
import { STAFFS_ROUTE } from '../constants/index';

const post = async (token, staff) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('staff', JSON.stringify(staff));

  const response = await fetch(
    `${configuration.api.url}/${STAFFS_ROUTE}/add-staff`,
    {
      method: 'Post',
      body: urlencoded,
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );
  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const addStaff = async (token, name, email) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('name', name);
  urlencoded.append('email', email);

  const response = await fetch(`${configuration.api.url}/${STAFFS_ROUTE}`, {
    method: 'Post',
    body: urlencoded,
    headers: {
      Authorization: `Bearer ${token}`,
      'Max-Forwards': '0',
    },
  });
  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const update = async (token, staff) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('staff', JSON.stringify(staff));

  const response = await fetch(
    `${configuration.api.url}/${STAFFS_ROUTE}/update`,
    {
      method: 'Post',
      body: urlencoded,
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );
  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const Staffs = {
  addStaff,
  post,
  update,
};

export default Staffs;
