import * as React from 'react';
import {
  Slide,
  Dialog,
  Grid,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Stack,
  Tooltip,
} from '@mui/material';
import CloseButton from '../closeButton';
import { darkTextColor } from '../../constants';
import TooltipIcon from '../../Icons/tooltipIcon';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CancelDialog = (props) => {
  const { open, setOpen, handleCancel, id } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {}}
      PaperProps={{
        sx: {
          borderRadius: '12px !important',
          p: 2,
        },
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1.5 }}
      >
        <Typography variant="h5" color={darkTextColor}>
          Are you sure you want to cancel this referral?
        </Typography>
        <IconButton onClick={() => handleClose()} color="secondary">
          <CloseButton />
        </IconButton>
      </Grid>

      <DialogContent sx={{ width: '28vw', py: 1 }}>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography
            color={darkTextColor}
            gutterBottom
            variant="h6"
            sx={{ fontWeight: 400 }}
          >
            If you cancel now, any information you've entered will be lost and
            the referral will not be completed.
          </Typography>
          <Tooltip
            title="When canceled, the referral will be permanently deleted from the recipient’s inbox"
            arrow
          >
            <div
              style={{
                alignSelf: 'center',
                display: 'flex',
                marginBottom: '5px',
              }}
            >
              <TooltipIcon />
            </div>
          </Tooltip>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1} sx={{ width: '100%' }}>
          <Button
            color="error"
            size="large"
            width="100%"
            onClick={() => setOpen(false)}
            variant="contained"
            sx={{
              height: 40,
              flex: 1,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
          >
            Cancel
          </Button>
          <Button
            color="error"
            size="large"
            variant="contained"
            width="100%"
            fullWidth
            sx={{ height: 40, flex: 2 }}
            onClick={() => handleCancel(id)}
          >
            Cancel referral
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;
