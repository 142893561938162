const MarkerIcon = ({
  fill = '#ED9C35',
  width = '23',
  height = '32',
  stroke = '#212634',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1188 0C4.98114 0 0 5.1592 0 11.5636C0 14.3209 0.934137 16.8562 2.49053 18.8575L10.4961 31.6663C10.8075 32.1112 11.4745 32.1112 11.7414 31.6663L19.7469 18.8575C21.3037 16.8562 22.2374 14.3209 22.2374 11.5636C22.2374 5.15904 17.2565 0 11.1188 0Z"
      fill={fill}
    />
    <path
      d="M2.91453 18.5925L2.90095 18.5708L2.88522 18.5505C1.395 16.6343 0.5 14.2064 0.5 11.5636C0.5 5.41729 5.275 0.5 11.1188 0.5C16.9626 0.5 21.7374 5.41713 21.7374 11.5636C21.7374 14.2065 20.8428 16.6343 19.3523 18.5505L19.3365 18.5707L19.3229 18.5925L11.3174 31.4013L11.3173 31.4013L11.3126 31.4091C11.2802 31.4631 11.2207 31.5 11.1355 31.5C11.052 31.5 10.9679 31.4627 10.9115 31.3876L2.91453 18.5925Z"
      stroke={stroke}
      strokeOpacity="0.34"
    />
  </svg>
);

export default MarkerIcon;
