export const configuration = {
  api: {
    //prod
    // url: 'https://api.medlynks.com',

    //dev
    // url: 'https://api.dev-medlynks.com',

    //local
    url: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000',
  },
  cdn: {
    // url: process.env.REACT_APP_CDN || 'https://df5qsy5uxe5b2.cloudfront.net/',

    //dep
    url: process.env.REACT_APP_CDN || 'https://d16csg9o052s5f.cloudfront.net/',

    urlDefaultImages: 'https://d3tdlsl4ufgi16.cloudfront.net/',
  },
  numberMultiplyer: {
    array: [20, 58, 24, 43],
  },
};

export default configuration;
