import * as React from 'react';
import {
  Slide,
  Dialog,
  Grid,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CloseButton from '../closeButton';
import { darkTextColor } from '../../constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = (props) => {
  const { title, open, setOpen, children, error = false, message = '' } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {}}
        PaperProps={{
          sx: {
            borderRadius: '12px !important',
            p: 2,
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 3, pb: !error && 1, py: error && 0.5, flexWrap: 'nowrap' }}
        >
          <Typography variant="h5" color={darkTextColor}>
            {title}
          </Typography>
          <IconButton onClick={() => handleClose()} color="secondary">
            <CloseButton />
          </IconButton>
        </Grid>
        {error ? (
          <>
            <DialogContent sx={{ minWidth: '20vw', py: 1 }}>
              <Typography
                color={darkTextColor}
                gutterBottom
                variant="h6"
                sx={{ fontWeight: 400, whiteSpace: 'pre-line' }}
              >
                {message}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                color="error"
                size="large"
                variant="contained"
                width="100%"
                fullWidth
                sx={{ height: 40 }}
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>{children}</>
        )}
      </Dialog>
    </div>
  );
};

export default AlertDialog;
