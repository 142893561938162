import { darkTextColor } from '../../constants';
import {
  Box,
  Grid,
  Typography,
  Divider,
  List,
  Avatar,
  Drawer,
  CircularProgress,
} from '@mui/material';
import Logo from '../../Icons/logo';
import Checkmark from '../../Icons/checkmark';
import { Fragment } from 'react';

const drawerWidth = 350;
const ResponsiveDrawer = ({ createAccountPage, page, screenWidth }) => {
  return (
    <Drawer
      variant={`${screenWidth ? 'permanent' : 'persistent'}`}
      // variant="permanent"
      sx={{
        boxShadow: 3,
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
        },
      }}
      open={false}
    >
      <Grid sx={{ ml: 5, p: 2, mt: 5 }}>
        <Logo />
      </Grid>
      <Divider />
      <List sx={{ ml: 5 }}>
        {['Confirm Profile', 'Create account', 'Set up account'].map(
          (text, index) => {
            let _textColor = darkTextColor;
            let bgColor = '#D9D9D9';
            const completed = index < page;
            if (index === page) {
              _textColor = '#1665D8';
              bgColor = '#1665D8';
            }

            if (completed) {
              _textColor = '#47BB92';
              bgColor = '#47BB92';
            }
            return (
              <Fragment key={text}>
                <Grid
                  container
                  direction="row"
                  gap={3}
                  sx={{ p: 2 }}
                  alignItems="center"
                >
                  <Avatar
                    sx={{
                      borderRadius: '4px',
                      bgcolor: `${bgColor} !important`,
                      color: index === page ? '#FFFFFF' : darkTextColor,
                    }}
                    variant="square"
                  >
                    {completed ? <Checkmark /> : index + 1}
                  </Avatar>
                  <Typography
                    color={_textColor}
                    variant="body1"
                    fontWeight="500"
                  >
                    {text}
                  </Typography>
                </Grid>
                {page === 2 &&
                  index === 2 &&
                  ['About you', 'Your practice', 'Staff members'].map(
                    (title, i) => (
                      <Typography
                        key={title}
                        color={darkTextColor}
                        sx={{
                          opacity: i === createAccountPage ? 1 : 0.5,
                          ml: 9,
                          my: 2,
                        }}
                        variant="body1"
                      >
                        {title}
                      </Typography>
                    ),
                  )}
              </Fragment>
            );
          },
        )}
      </List>
    </Drawer>
  );
};

export default ResponsiveDrawer;
