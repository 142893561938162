import React, { useContext } from 'react';
import Logo from '../../Icons/logo';
import {
  darkTextColor,
  GetImageFromUrl,
  RESPONSE_STATUS,
} from '../../constants';
import {
  Toolbar,
  AppBar,
  Box,
  Tooltip,
  MenuItem,
  Menu,
  Avatar,
  AvatarGroup,
  Typography,
  IconButton,
  Grid,
  Stack,
  Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import UserContext from '../../contexts/user/context';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { NotificationNumber } from '../notificationNumber';
import ContactUs from '../contactUs/contactUs';
import { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Users from '../../services/users';
import Dot from './dot';

// const pages = ['Overview', 'Find a specialist', 'Referrals', 'Messages'];

const pages = [
  {
    route: 'overview',
    label: 'Overview',
  },
  {
    route: 'find',
    label: 'Find a specialist',
  },
  {
    route: 'referrals',
    label: 'Referrals',
  },
  {
    route: 'messages',
    label: 'Messages',
  },
];

const settings = ['Settings', 'Profile', 'Contact us'];

const Navbar = () => {
  const { user, token, handleLogout, allReferrals, chats } =
    useContext(UserContext);
  const [anchorElNav, setAnchorElNav] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [pendingReferrals, setPendingReferrals] = useState();
  const [newMessages, setNewMessages] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  const [showResendEmailBanner, setShowResetEmailBanner] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenNavMenu = () => {
    setAnchorElNav(true);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSettingClick = (setting) => {
    if (setting === settings[0]) {
      navigate(`/settings/account`);
    }

    if (setting === settings[1]) {
      // const profileNumber = encryptNumber(user.id);
      navigate(`/profile/edit`);
    }

    if (setting === settings[2]) {
      setAnchorElUser(false);
      setOpenDialog(true);
    }
  };
  const handleResendVerificationEmail = async () => {
    if (loading) return;
    try {
      setLoading(true);
      const response = await Users.resendVerificationEmail(token);
      if (response.status === RESPONSE_STATUS.success) {
        setShowResetEmailBanner(false);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (user?.email_verified) return;
      if (!user?.email_verified) {
        setShowResetEmailBanner(true);
      }
    }
  }, [user]);

  useEffect(() => {
    if (allReferrals && allReferrals?.length) {
      const findPending = allReferrals.filter((ref) => !ref.status);
      if (findPending.length !== pendingReferrals) {
        setPendingReferrals(findPending.length);
      }
    }
  }, [allReferrals, pendingReferrals]);

  useEffect(() => {
    if (chats.length) {
      let pendingMessages = 0;
      chats.forEach((chat) => {
        chat.chat?.forEach((message) => {
          if (!message.readAt && message.senderId !== user.id) {
            pendingMessages += 1;
          }
        });
      });
      setNewMessages(pendingMessages);
    }
  }, [chats]);

  const avatar = GetImageFromUrl(user.profile_picture_url);
  const route = location.pathname.substring(1);

  return (
    <>
      <ContactUs openDialog={openDialog} setOpenDialog={setOpenDialog} />
      <AppBar
        position="static"
        color="secondary"
        sx={{
          height: { md: '7vh', sm: 'auto', xs: 'auto' },
          minHeight: '60px',
          borderBottom: '2px solid #F7F7F7',
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: 'flex', md: 'none' },
                alignItems: 'center',
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => handleOpenNavMenu()}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
              <Box
                onClick={() => navigate('/overview')}
                sx={{ cursor: 'pointer', mt: '5px' }}
              >
                <Logo />
              </Box>
            </Box>
            <Box
              onClick={() => navigate('/overview')}
              style={{ cursor: 'pointer' }}
              sx={{ display: { xs: 'none', md: 'block' }, mt: '6px' }}
            >
              <Logo />
            </Box>
            <Grid
              container
              gap={4}
              sx={{
                ml: 10,
                flexGrow: 1,
                width: 'auto',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              <Stack direction="row" spacing={2}>
                {pages.map((page) => (
                  <Stack key={page.route} direction="row" spacing={1}>
                    <Typography
                      key={page.route}
                      color={route === page.route ? 'primary' : darkTextColor}
                      onClick={() => navigate(`/${page.route}`)}
                      textAlign="center"
                      sx={{ cursor: 'pointer', fontWeight: 500 }}
                    >
                      {page.label}
                    </Typography>
                    {page.route === 'referrals' && pendingReferrals && (
                      <NotificationNumber number={pendingReferrals} />
                    )}
                    {page.route === 'messages' && newMessages && (
                      <NotificationNumber number={newMessages} />
                    )}
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={anchorElNav}
              // onClose={handleCloseUserMenu}
              onClose={(e) => setAnchorElNav(false)}
              sx={{
                display: { xs: 'block', md: 'none' },
                mt: '45px',
                padding: '10px',
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.label}
                  onClick={() => navigate(`/${page.route}`)}
                >
                  <Typography
                    color={route === page.route ? 'primary' : darkTextColor}
                    textAlign="center"
                  >
                    {page.label}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
            <Box sx={{ flexGrow: 0 }}>
              <Stack direction="row" gap={2}>
                {showResendEmailBanner ? (
                  <Box
                    sx={{
                      bgcolor: 'rgba(234, 236, 238, 0.32)',
                      border: '1px solid #EAECEE',
                      p: 1,
                      px: 2,
                      borderRadius: '100px',
                      width: '100%',
                      opacity: loading && 0.5,
                      userSelect: loading && 'none',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Stack direction="row" gap={1} alignItems="center">
                      <InfoOutlinedIcon sx={{ color: '#212634' }} />
                      <Typography
                        color="#000000"
                        variant="body2"
                        sx={{ fontSize: '14px' }}
                      >
                        Email is not verified{' '}
                        <span
                          style={{
                            color: '#47BB92',
                            fontWeight: '500',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleResendVerificationEmail()}
                        >
                          Resend
                        </span>
                      </Typography>
                    </Stack>
                  </Box>
                ) : (
                  <></>
                )}
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <AvatarGroup
                      style={{
                        border: '0.1px solid lightgray',
                        borderRadius: '30%/50%',
                      }}
                    >
                      <Dot />
                      <Avatar
                        alt="Remy Sharp"
                        color="#696969"
                        src={avatar}
                        style={{ marginTop: '3.5%', marginRight: '3.5%' }}
                      >
                        {user?.firstName?.charAt(0)}
                      </Avatar>
                    </AvatarGroup>
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px', padding: '10px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <Stack sx={{ px: 2, py: 1 }} direction="column">
                    <Stack
                      direction="column"
                      alignItems="left"
                      sx={{ textAlign: 'left', mb: 2 }}
                    >
                      <Typography color={darkTextColor} fontWeight={600}>
                        {user.firstName} {user.lastName}
                      </Typography>
                      <Typography color={darkTextColor}>
                        {user.email}
                      </Typography>
                    </Stack>
                    <div
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        alignSelf: 'center',
                        height: '1px',
                        backgroundColor: '#D9D9D9',
                        marginBottom: '5px',
                      }}
                    />
                    {settings.map((setting) => (
                      <MenuItem
                        key={setting}
                        onClick={() => handleSettingClick(setting)}
                        sx={{ px: 0 }}
                      >
                        <Typography
                          fontWeight={500}
                          color={darkTextColor}
                          textAlign="center"
                        >
                          {setting}
                        </Typography>
                      </MenuItem>
                    ))}
                    <div
                      style={{
                        width: '100%',
                        borderRadius: '4px',
                        alignSelf: 'center',
                        height: '1px',
                        backgroundColor: '#D9D9D9',
                        marginBottom: '5px',
                      }}
                    />
                    <MenuItem sx={{ px: 0 }} onClick={() => handleLogout()}>
                      <Typography color="error" textAlign="center">
                        Sign out
                      </Typography>
                    </MenuItem>
                  </Stack>
                </Menu>
              </Stack>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};
export default Navbar;
