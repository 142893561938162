const EmailIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9884 8.55727C16.7704 4.51319 13.559 1.27095 9.51886 1.0159C7.21708 0.871272 4.96709 1.71869 3.34296 3.3427C1.71871 4.96683 0.870703 7.21776 1.01603 9.51861C1.27086 13.551 4.50581 16.7616 8.54117 16.9872C8.69366 16.9958 8.84674 17 8.99935 17C9.19262 17 9.38541 16.9931 9.57598 16.9794C9.80789 16.963 9.98237 16.7615 9.9658 16.5295C9.94923 16.2974 9.74445 16.1217 9.51582 16.1396C9.21001 16.1615 8.89772 16.164 8.58815 16.1463C4.97837 15.9446 2.08441 13.0725 1.85673 9.46546C1.72667 7.40582 2.48539 5.3913 3.93859 3.938C5.39165 2.48495 7.4037 1.72702 9.46605 1.85614C13.08 2.08441 15.9526 4.98469 16.1478 8.60229C16.2325 10.1727 15.8179 11.6875 14.9488 12.9826C14.6164 13.4778 14.0975 13.5329 13.7217 13.4181C13.345 13.3035 12.9425 12.9674 12.9425 12.3678L12.9426 8.99924C12.9426 6.82598 11.1744 5.05772 9.00106 5.05772C6.8278 5.05772 5.05954 6.82589 5.05954 8.99924C5.05954 11.1726 6.82771 12.9408 9.00106 12.9408C10.2572 12.9408 11.3779 12.35 12.1001 11.4319V12.3679C12.1 13.2408 12.6403 13.9695 13.4764 14.2239C14.3112 14.4784 15.163 14.1747 15.648 13.4518C16.6196 12.0037 17.0831 10.3112 16.9885 8.55688L16.9884 8.55727ZM9.00094 12.0991C7.292 12.0991 5.90156 10.7087 5.90156 8.9997C5.90156 7.29075 7.29191 5.90032 9.00094 5.90032C10.7099 5.90032 12.1003 7.29066 12.1003 8.9997C12.1003 10.7086 10.71 12.0991 9.00094 12.0991Z"
      fill="black"
      stroke="black"
      strokeWidth="0.25"
    />
  </svg>
);

export default EmailIcon;
