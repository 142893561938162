const Dot = () => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="translate(10 3)"
      fill="#696969"
    />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="translate(20 3)"
      fill="#696969"
    />
    <circle
      cx="2"
      cy="2"
      r="2"
      transform="translate(30 3)"
      fill="#696969"
    />
  </svg>
);

export default Dot;