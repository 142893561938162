import React, { useState, useContext } from 'react';
import './styles.css';

import GoogleMapReact from 'google-map-react';
import Marker from './marker2';
// import Marker from './marker';

import { GetImageFromUrl } from '../../constants';
import { useEffect } from 'react';
import { ContactlessOutlined } from '@mui/icons-material';
import { isOptionGroup } from '@mui/base';
import UserContext from '../../contexts/user/context';
import { useRef } from 'react';
import { useCallback } from 'react';

const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    return Math.sqrt(
      (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y),
    );
  }
};

function makeid() {
  var text = '';
  var possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

// const setZoom = (distance) => {
//   if (distance) {
//     if (distance === 5) {
//       return 12;
//     }
//     if (distance === 10) {
//       return 11;
//     }
//   }
//   return 14;
// };

const Map = ({
  data,
  setHover,
  onFocusCard,
  setOnClicked,
  onClicked,
  handleClickCard,
  handleInviteDoctor,
  distance,
  doctorsSorted,
  hover,
  height,
}) => {
  const [center, setCenter] = useState({
    lat: 41.821832,
    lng: -87.673177,
  });
  const { lat, long, setLat, setLong, setLocation, allDoctors, location } =
    useContext(UserContext);
  const [sortedData, setSortedData] = useState([]);
  const [defaultCenter, setDefaultCenter] = useState();
  const mapRef = useRef();
  const [zoom, setZoom] = useState(12);
  const initRef = useRef(false);
  const [bounds, setBounds] = useState();

  const handleUpdateDrag = (e) => {
    let ne = e.getBounds().getNorthEast();
    let sw = e.getBounds().getSouthWest();
    setBounds({ lat: [sw.lat(), ne.lat()], lng: [sw.lng(), ne.lng()] });

    // const newCenter = mapRef?.current?.getCenter();
    // setZoom(14);
    // setZoom(mapRef.current.getZoom());
    if (mapRef?.current?.map) {
      setCenter({
        lat: mapRef?.current?.map.center.lat(),
        lng: mapRef?.current?.map.center.lng(),
      });
    }

    setLocation();
    setLat([sw.lat(), ne.lat()]);
    setLong([sw.lng(), ne.lng()]);
  };

  useEffect(() => {
    let filterData = [];
    data.forEach((doctor) => {
      if (
        doctor.lat &&
        doctor.long &&
        (doctor.lat !== -1 || doctor.long !== -1)
      ) {
        if (filterData?.length) {
          const findDoctor = filterData?.find(
            (d) =>
              d?.lat?.toFixed(4) === Number(doctor?.lat)?.toFixed(4) &&
              d?.long?.toFixed(4) === Number(doctor?.long)?.toFixed(4),
          );

          if (findDoctor) {
            const findIndex = filterData?.findIndex(
              (d, i) =>
                d?.lat?.toFixed(4) === Number(doctor?.lat)?.toFixed(4) &&
                d?.long?.toFixed(4) === Number(doctor?.long)?.toFixed(4),
            );
            filterData[findIndex]?.doctors.push(doctor);
          } else {
            filterData.push({
              id: makeid(),
              lat: Number(doctor.lat),
              long: Number(doctor.long),
              distance: doctor.distance,
              doctors: [doctor],
            });
          }
        } else {
          filterData.push({
            id: makeid(),
            lat: Number(doctor.lat),
            long: Number(doctor.long),
            distance: doctor.distance,
            doctors: [doctor],
          });
        }
      }
    });
    setSortedData(
      filterData.sort((a, b) =>
        a.distance > b.distance ? 1 : b.distance > a.distance ? -1 : 0,
      ),
    );
  }, [data]);

  const onZoomChanged = (e) => {
    let ne = mapRef?.current?.map_.getBounds().getNorthEast();
    let sw = mapRef?.current?.map_.getBounds().getSouthWest();
    setBounds({ lat: [sw.lat(), ne.lat()], lng: [sw.lng(), ne.lng()] });
    if (initRef.current) {
      initRef.current = false;
      return;
    }
    if (mapRef?.current?.map_.getCenter()) {
      setZoom(mapRef.current?.map_.getZoom());
      setCenter({
        lat: mapRef?.current?.map_.center.lat(),
        lng: mapRef?.current?.map_.center.lng(),
      });
    }

    setLocation();
    setLat([sw.lat(), ne.lat()]);
    setLong([sw.lng(), ne.lng()]);
  };

  useEffect(() => {
    if (data?.length && !allDoctors.isMap && !lat) {
      initRef.current = true;

      let totalCount = 0;
      let lat = 0;
      let long = 0;
      for (let i = 0; i < data.length; i++) {
        if (
          data[i].lat &&
          data[i].long &&
          (data[i].lat !== -1 || data[i].long !== -1)
        ) {
          // totalCount += 1;
          long = data[i].long;
          lat = data[i].lat;
          break;
        }
      }
      // setZoom(14);
      setZoom(11);
      setCenter({
        lat: Number(lat),
        lng: Number(long),
      });
      setDefaultCenter({
        lat: Number(lat),
        lng: Number(long),
      });
    }
  }, [data]);

  // const findFocusDoctor = data.find((x) => x.id === onFocusCard);

  // useEffect(() => {
  //   const findFocusDoctor = data.find((x) => x.id === onFocusCard);

  //   if (findFocusDoctor) {
  //     setCenter({
  //       lat: Number(findFocusDoctor?.lat) || 41.881832,
  //       lng: Number(findFocusDoctor?.long) || -87.623177,
  //     });
  //   }
  // }, [onFocusCard]);

  useEffect(() => {
    if (distance) {
      if (distance === 5) {
        setZoom(12);
      }
      if (distance === 10) {
        setZoom(11);
      }
    }
  }, [allDoctors, distance]);

  return (
    <div
      className="map-container"
      id="map-container"
      style={{ maxHeight: `${height ? `${height + 60}px` : '70vh'}` }}
    >
      <GoogleMapReact
        ref={mapRef}
        id="find-map"
        bootstrapURLKeys={{
          key: 'AIzaSyDCshnacXxncQwvgAOdjPVWl6PHYMJK0os',
          language: 'en',
          region: 'US',
        }}
        onClick={() => {
          setOnClicked();
        }}
        // defaultCenter={{
        //   lat: findFocusDoctor ? findFocusDoctor?.lat || 41.881832 : 41.881832,
        //   lng: findFocusDoctor
        //     ? findFocusDoctor?.long || -87.623177
        //     : -87.623177,
        // }}
        debounced={true}
        onZoomAnimationStart={() => {
          setHover();
          setOnClicked();
        }}
        onZoomAnimationEnd={(e) => onZoomChanged(e)}
        defaultCenter={defaultCenter}
        onDragEnd={(e) => handleUpdateDrag(e)}
        options={{
          fullscreenControl: false,
          scrollwheel: hover || onClicked ? false : true,
          clickableIcons: false,
        }}
        zoom={zoom}
        defaultZoom={zoom}
        distanceToMouse={distanceToMouse}
        center={center}
      >
        {sortedData &&
          sortedData.map((data, index) => {
            return (
              <Marker
                onFocusCard={onFocusCard}
                docData={data}
                draggable={true}
                // onDragend={(t, map, coord) => {
                //   console.log(coord);
                // }}
                bounds={bounds}
                key={index}
                index={index}
                lat={data.lat}
                lng={data.long}
                setHover={setHover}
                setOnClicked={setOnClicked}
                onClicked={onClicked}
                handleClickCard={handleClickCard}
                handleInviteDoctor={handleInviteDoctor}
                hover={hover}
              />
            );
          })}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
