import configuration from '../config';
import { USERS_ROUTE } from '../constants/index';

const Role = {
  User: 'user',
  Manager: 'manager',
};

const post = async (
  firstName,
  lastName,
  password,
  email,
  specialties,
  validated,
  doctorId,
  image = null,
  termsAccepted,
) => {
  const formData = new FormData();

  formData.append('firstName', firstName.toString());
  formData.append('lastName', lastName.toString());
  formData.append('password', password.toString());
  formData.append('email', email.toString());
  formData.append('specialties', JSON.stringify(specialties));
  formData.append('doctor_verified', validated.toString());
  formData.append('doctor_db_id', doctorId);
  formData.append('role', Role.User);
  formData.append('terms', termsAccepted);

  if (image) {
    formData.append('validationImage', image[0]);
  }
  const endpoint = 'register';
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/${endpoint}`,
    {
      headers: {
        'Max-Forwards': '0',
      },
      method: 'POST',
      body: formData,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const updatePassword = async (token, password, newPassword) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('newPassword', newPassword.toString());
  urlencoded.append('password', password.toString());

  const endpoint = 'update/password';
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/${endpoint}`,
    {
      method: 'POST',
      body: urlencoded,

      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const edit = async (
  token,
  about,
  residency,
  medicalSchool,
  residency_year,
  medical_school_year,
  fellowships,
  preffix,
  image = null,
  isPictureString,
  healthCareEmployer,
  addresses,
  insurance,
  hospitalLocation,
  specialtiesSelected,
  status,
  firstName,
  lastName,
  availability,
  officeNumber,
  availabilityTime,
  staff,
) => {
  const formData = new FormData();
  formData.append('about', about);
  formData.append('residency', residency);
  formData.append('medical_school', medicalSchool);
  formData.append('residency_year', residency_year.toString());
  formData.append('medical_school_year', medical_school_year.toString());
  formData.append('fellowships', JSON.stringify(fellowships));
  formData.append('preffix', preffix);
  formData.append('isPictureString', isPictureString);
  formData.append('healthCareEmployer', healthCareEmployer);
  formData.append('addresses', JSON.stringify(addresses));
  formData.append('insurance', JSON.stringify(insurance));
  formData.append('hospitalLocations', JSON.stringify(hospitalLocation));
  formData.append('specialties', JSON.stringify(specialtiesSelected));
  formData.append('status', status);
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('availability', availability);
  formData.append('officeNumber', officeNumber);
  formData.append('availabilityTime', availabilityTime);
  formData.append('staff', JSON.stringify(staff));
  if (image) {
    formData.append('profilePicture', image);
  }
  const response = await fetch(`${configuration.api.url}/${USERS_ROUTE}/edit`, {
    method: 'Put',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
      'Max-Forwards': '0',
    },
  });

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const put = async (
  token,
  id,
  about,
  residency,
  medicalSchool,
  residency_year,
  medical_school_year,
  fellowships,
  preffix,
  image = null,
  isPictureString,
  status,
) => {
  const formData = new FormData();
  formData.append('about', about);
  formData.append('residency', residency);
  formData.append('medical_school', medicalSchool);
  formData.append('residency_year', residency_year.toString());
  formData.append('medical_school_year', medical_school_year.toString());
  formData.append('fellowships', JSON.stringify(fellowships));
  formData.append('preffix', preffix);
  formData.append('isPictureString', isPictureString);
  formData.append('status', status);

  if (image) {
    formData.append('profilePicture', image);
  }
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/${id}`,
    {
      method: 'Put',
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const putPractice = async (
  token,
  id,
  healthCareEmployer,
  addresses,
  insurance,
  hospitalLocation,
  availabilityTime,
  officeNumber,
) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('healthCareEmployer', healthCareEmployer);
  urlencoded.append('addresses', JSON.stringify(addresses));
  urlencoded.append('insurance', JSON.stringify(insurance));
  urlencoded.append('hospitalLocations', JSON.stringify(hospitalLocation));
  urlencoded.append('availabilityTime', availabilityTime);
  urlencoded.append('officeNumber', officeNumber);

  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/update-practice/${id}`,
    {
      method: 'Put',
      body: urlencoded,
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const verify = async (query) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/verify-user${query}`,
    {
      headers: {
        'content-type': 'application/json',
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const resetPaswwordConfirmation = async (query) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/reset-password-link${query}`,
    {
      headers: {
        'content-type': 'application/json',
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getDoctors = async (
  token,
  primary,
  location,
  subspecialty,
  healthcareEmployer,
  distance,
  isSub,
  lat,
  long,
  isButton = false,
  insurance,
) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('primary', primary);
  if (isSub) {
    urlencoded.append('isSub', 1);
  }
  if (location) {
    urlencoded.append('location', location);
  }
  if (subspecialty) {
    urlencoded.append('subspecialty', subspecialty);
  }
  if (distance) {
    urlencoded.append('distance', distance);
  }
  if (insurance) {
    urlencoded.append('insurance', insurance);
  }
  if (healthcareEmployer) {
    urlencoded.append('healthcareEmployer', healthcareEmployer);
  }
  if (lat && !location && !isButton) {
    urlencoded.append('lat', lat.toString());
  }
  if (long && !location && !isButton) {
    urlencoded.append('long', long.toString());
  }
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/get-doctors`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'POST',

      body: urlencoded,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const resetPasswordLogin = async (token, password) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('key', token);
  urlencoded.append('password', password);

  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/reset-password-login`,
    {
      headers: {
        'Max-Forwards': '0',
      },
      method: 'Put',
      body: urlencoded,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const resendVerificationEmail = async (token) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/resend-verification-email`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const updateReferral = async (token, referrals) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('referral', referrals);

  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/update-referral`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'Put',

      body: urlencoded,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const get = async (token, query) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const recommendations = async (token, query) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/recommendations${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getById = async (token, id) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/doctor/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const sendInvite = async (token, email, onlyName = '') => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('email', email.toString());
  urlencoded.append('name', onlyName.toString());

  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/send-invite`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'POST',
      body: urlencoded,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const changeEmailToken = async (token, query) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/update/email${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const disableTwoFactor = async (token, password) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('password', password.toString());

  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/update/twoFactor`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'POST',
      body: urlencoded,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error(response.message);
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const verifyEmailToken = async (token, verificationTokenEmail) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('token', verificationTokenEmail.toString());
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/verify/email`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'POST',
      body: urlencoded,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const enableTwoFactor = async (token, query) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/auth${query}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'GET',
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const verifyTwoFactor = async (token, twoFactorType, verificationToken) => {
  const urlencoded = new URLSearchParams();
  urlencoded.append('type', twoFactorType.toString());
  urlencoded.append('token', verificationToken.toString());

  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/verify/token`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'POST',

      body: urlencoded,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      throw new Error('Invalid response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const sendMessage = async (token, message) => {
  const formData = new URLSearchParams();
  formData.append('message', message);

  const endpoint = 'contact-us';
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/${endpoint}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Max-Forwards': '0',
      },
      method: 'POST',

      body: formData,
    },
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error('Invalid Response');
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const Users = {
  sendMessage,
  resetPaswwordConfirmation,
  resendVerificationEmail,
  put,
  post,
  putPractice,
  verify,
  resetPasswordLogin,
  updateReferral,
  getDoctors,
  get,
  getById,
  edit,
  enableTwoFactor,
  verifyTwoFactor,
  updatePassword,
  verifyEmailToken,
  changeEmailToken,
  disableTwoFactor,
  sendInvite,
  recommendations,
};

export default Users;
