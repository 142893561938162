import React from 'react';
import { Grid } from '@mui/material';
import { CircleButton } from '../../EditProfile/circleButton';
import { CustomTextFieldNoBorders } from '../../../components/customTextField';
const { withScriptjs } = require('react-google-maps');
const {
  StandaloneSearchBox,
} = require('react-google-maps/lib/components/places/StandaloneSearchBox');

const googleMapURL =
  'https://maps.googleapis.com/maps/api/js?key=AIzaSyDCshnacXxncQwvgAOdjPVWl6PHYMJK0os&v=4.exp&libraries=geometry,drawing,places&language=en&region=US';

const AddressField = (props) => {
  const { updatePlaces } = props;
  const refs = {};
  const [value, setValue] = React.useState('');
  const place = React.useRef();
  const onSearchBoxMounted = (ref) => {
    refs.searchBox = ref;
  };

  const onPlacesChanged = () => {
    const places = refs.searchBox.getPlaces();
    setValue(
      `${
        places[0].formatted_address.includes(places[0].name)
          ? ''
          : `${places[0].name}, `
      }${places[0].formatted_address}`,
    );
    place.current = places;
  };

  return (
    <Grid
      container
      alignItems="flex-end"
      justifyContent="space-between"
      direction="row"
      gap={1}
    >
      <Grid item sx={{ flexGrow: 10 }}>
        <Field
          googleMapURL={googleMapURL}
          loadingElement={<div style={{ height: `100%` }} />}
          onPlacesChanged={onPlacesChanged}
          onSearchBoxMounted={onSearchBoxMounted}
          value={value}
          setValue={setValue}
        />
      </Grid>
      <CircleButton
        onClick={() => {
          setValue('');
          updatePlaces(place.current);
          place.current = '';
        }}
      />
    </Grid>
  );
};

const Field = withScriptjs((props) => (
  <StandaloneSearchBox
    ref={props.onSearchBoxMounted}
    onPlacesChanged={props.onPlacesChanged}
  >
    <CustomTextFieldNoBorders
      fullWidth
      width="100%"
      name="about"
      placeholder="Address"
      type="text"
      value={props.value}
      onChange={(e) => {
        props.setValue(e.target.value);
      }}
    />
  </StandaloneSearchBox>
));

export default AddressField;
