import { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  DialogContent,
  DialogActions,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomInputField from '../../components/customInputField';
import { RESPONSE_STATUS } from '../../constants/index';
import AlertDialog from '../../components/alertDialog';
import Users from '../../services/users';

const textColor = '#212634';

const ResetPassword = (props) => {
  const { openDialog, setOpenDialog } = props;
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email must be valid')
        .max(255, 'Email should be less than 255 characters'),
    }),
    onSubmit: async (values) => {
      try {
        const query = `?email=${values.email}`;
        const response = await Users.resetPaswwordConfirmation(query);
        if (response.status === RESPONSE_STATUS.error) {
          setErrorMessage(response.message);
          return;
        }
        if (response.status === RESPONSE_STATUS.success) {
          formik.resetForm();
          setErrorMessage('');
          setOpenDialog(false);
        }
      } catch (e) {
        setErrorMessage(e.message);
      }
    },
  });

  const handleClose = () => {
    setOpenDialog(false);
    setErrorMessage('');
    formik.resetForm();
  };

  return (
    <>
      <AlertDialog
        title="Reset Password"
        open={openDialog}
        setOpen={handleClose}
      >
        <DialogContent
          sx={{ mb: 3, mt: -2, width: { md: '25vw', sm: '100%' } }}
        >
          <Typography sx={{ mb: 3 }} color={textColor} variant="body1">
            Please enter your email. <br />
            The password reset link will be sent to your email if the account
            exists. The link is valid for 30 minutes.
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Typography color={textColor} gutterBottom variant="body1">
              Work email
            </Typography>
            <CustomInputField
              error={Boolean(formik.touched.email && formik.errors.email)}
              label="First Name"
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="text"
              value={formik.values.email}
              fullWidth
              width="100%"
            />
            {Boolean(formik.touched.email && formik.errors.email) && (
              <Typography
                color="error"
                gutterBottom
                variant="body2"
                sx={{ position: 'absolute !important' }}
              >
                {formik.touched.email && formik.errors.email}
              </Typography>
            )}
            {errorMessage && !formik.errors.email && (
              <Typography
                color="error"
                gutterBottom
                variant="body2"
                sx={{ position: 'absolute !important' }}
              >
                {errorMessage}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            // disabled={loading}
            fullWidth
            onClick={() => handleClose()}
            sx={{
              width: '10rem',
              height: 40,
              mr: 1.5,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            // disabled={loading}
            fullWidth
            sx={{ width: '10rem', height: 40 }}
            onClick={() => formik.handleSubmit()}
          >
            Done
          </Button>
        </DialogActions>
      </AlertDialog>
    </>
  );
};

export default ResetPassword;
