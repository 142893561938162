const JepgImage = () => (
  <svg
    width="28"
    height="32"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.72874 15.5884C5.63194 15.5884 5.54752 15.6224 5.47382 15.6902C5.40011 15.758 5.36372 15.8417 5.36372 15.9418V18.3748C5.36372 18.4873 5.33305 18.5917 5.27099 18.6876C5.20892 18.7836 5.12783 18.8614 5.0277 18.9192C4.92666 18.9772 4.81914 19.006 4.70332 19.006C4.60652 19.006 4.52044 18.9911 4.44507 18.963C4.3697 18.934 4.2936 18.8843 4.21675 18.8148C4.17038 18.7685 4.11903 18.728 4.06287 18.6931C4.00653 18.6584 3.94945 18.641 3.89163 18.641C3.81792 18.641 3.74348 18.6741 3.66811 18.7395C3.59274 18.8049 3.55469 18.886 3.55469 18.9827C3.55469 19.0481 3.57778 19.1159 3.62415 19.1856C3.74422 19.3518 3.89884 19.4793 4.08763 19.568C4.27716 19.6564 4.48147 19.7012 4.70165 19.7012C4.96064 19.7012 5.19413 19.6398 5.40268 19.5157C5.61123 19.3915 5.77749 19.2294 5.90088 19.0291C6.02427 18.8289 6.08635 18.6103 6.08635 18.3744V15.9414C6.08635 15.8413 6.05328 15.7569 5.98789 15.6898C5.92397 15.6224 5.83697 15.5884 5.72872 15.5884L5.72874 15.5884Z"
      fill="#47BB92"
    />
    <path
      d="M9.19723 15.7617C9.02174 15.6459 8.83313 15.5879 8.63197 15.5879H7.31115C7.2101 15.5879 7.12661 15.6219 7.05881 15.6897C6.99102 15.7575 6.95703 15.8411 6.95703 15.9413V19.2897C6.95703 19.3898 6.98843 19.4742 7.04976 19.5413C7.11109 19.6091 7.19053 19.643 7.28732 19.643C7.38837 19.643 7.46854 19.6091 7.5306 19.5413C7.59267 19.4735 7.62334 19.3898 7.62334 19.2897V18.1948H8.63137C8.83254 18.1948 9.02041 18.1361 9.19664 18.0186C9.37213 17.9002 9.51437 17.743 9.6228 17.546C9.73031 17.3491 9.78499 17.1315 9.78499 16.8913C9.78499 16.6481 9.73124 16.4279 9.6228 16.2309C9.51492 16.034 9.37249 15.8785 9.19719 15.7617L9.19723 15.7617ZM9.02007 17.2017C8.97371 17.3001 8.91589 17.3805 8.84624 17.4425C8.77678 17.5046 8.70548 17.5353 8.63196 17.5353H7.62392V16.2549H8.63196C8.70567 16.2549 8.77679 16.2856 8.84624 16.3451C8.9157 16.4048 8.97371 16.4833 9.02007 16.5793C9.06644 16.6754 9.08953 16.7805 9.08953 16.8921C9.08953 16.9998 9.06718 17.1032 9.02007 17.2017V17.2017Z"
      fill="#47BB92"
    />
    <path
      d="M12.7441 16.2547C12.8451 16.2547 12.9286 16.225 12.9964 16.1653C13.0642 16.1057 13.0982 16.0272 13.0982 15.9311C13.0982 15.8301 13.0642 15.7482 12.9964 15.6845C12.9286 15.6208 12.8449 15.5894 12.7441 15.5894H10.8033C10.7023 15.5894 10.6188 15.6233 10.551 15.6911C10.4832 15.7589 10.4492 15.8426 10.4492 15.9427V19.2911C10.4492 19.3912 10.4832 19.4757 10.551 19.5427C10.6188 19.6098 10.7025 19.6445 10.8033 19.6445H12.7441C12.8451 19.6445 12.9286 19.6138 12.9964 19.5518C13.0642 19.4897 13.0982 19.411 13.0982 19.3142C13.0982 19.2257 13.0642 19.147 12.9964 19.0792C12.9286 19.0114 12.8449 18.9775 12.7441 18.9775H11.1741V17.9057H12.4833C12.5843 17.9057 12.6678 17.8759 12.7356 17.8163C12.8034 17.7566 12.8374 17.6781 12.8374 17.5813C12.8374 17.4812 12.8034 17.3991 12.7356 17.3354C12.6678 17.2717 12.5841 17.2403 12.4833 17.2403H11.1741V16.2555H12.7441V16.2547Z"
      fill="#47BB92"
    />
    <path
      d="M16.7544 17.4532H15.8043C15.7042 17.4532 15.6198 17.4863 15.5527 17.5517C15.485 17.6171 15.451 17.7006 15.451 17.8007C15.451 17.9017 15.485 17.9845 15.5527 18.0497C15.6205 18.1151 15.7042 18.1482 15.8043 18.1482H16.3489V18.8922C16.2669 18.9229 16.1768 18.951 16.0593 18.9709C15.9202 18.994 15.7927 19.0057 15.6769 19.0057C15.4262 19.0057 15.197 18.9429 14.9901 18.8171C14.7832 18.6922 14.6194 18.5224 14.4976 18.3105C14.3759 18.0979 14.3147 17.8661 14.3147 17.6154C14.3147 17.3605 14.3761 17.1279 14.4976 16.9177C14.6194 16.7075 14.7832 16.5386 14.9901 16.4138C15.197 16.288 15.4253 16.2252 15.6769 16.2252C15.797 16.2252 15.9102 16.2368 16.0187 16.2599C16.1262 16.283 16.2289 16.3153 16.3257 16.3583C16.3563 16.374 16.3885 16.3857 16.4208 16.3931C16.4539 16.4005 16.4853 16.4047 16.5169 16.4047C16.6137 16.4047 16.6923 16.3683 16.7544 16.2946C16.8157 16.2209 16.8471 16.1381 16.8471 16.0456C16.8471 15.9918 16.8322 15.9388 16.8034 15.8867C16.7744 15.8346 16.7313 15.7933 16.6735 15.7626C16.5303 15.6856 16.3739 15.6268 16.2043 15.5888C16.0338 15.5498 15.8583 15.5308 15.6771 15.5308C15.3841 15.5308 15.1094 15.5845 14.8545 15.693C14.5996 15.8014 14.3753 15.9512 14.1824 16.1415C13.9896 16.3317 13.8374 16.5536 13.728 16.8043C13.6179 17.0559 13.5625 17.3256 13.5625 17.6152C13.5625 17.9049 13.6179 18.1755 13.728 18.4262C13.8381 18.6778 13.9896 18.8987 14.1824 19.0899C14.3753 19.2811 14.5996 19.4309 14.8545 19.5384C15.1094 19.6459 15.3841 19.7006 15.6771 19.7006C15.9087 19.7006 16.1299 19.6692 16.3408 19.6055C16.551 19.5417 16.7456 19.4556 16.9225 19.3472C16.9689 19.3158 17.0103 19.2761 17.0474 19.2255C17.0838 19.175 17.1021 19.1113 17.1021 19.0343V17.8062C17.1021 17.7061 17.069 17.6217 17.0036 17.5546C16.9373 17.4872 16.8546 17.4532 16.7544 17.4532L16.7544 17.4532Z"
      fill="#47BB92"
    />
    <path
      d="M17.7682 13.2412H17.3792V5.44537C17.3792 5.11767 17.3081 4.80639 17.1906 4.51674C17.1832 4.42899 17.1525 4.3479 17.0988 4.28416C17.0657 4.2171 17.0217 4.1543 16.982 4.08964C16.9573 4.049 16.9366 4.00762 16.9092 3.96791C16.8265 3.8495 16.7347 3.73626 16.6312 3.63281C16.6305 3.63208 16.6296 3.63041 16.6288 3.62949L13.7489 0.749607C13.5271 0.527749 13.2657 0.349115 13.0041 0.238115C13.0024 0.237376 13.0008 0.237376 13 0.236453C12.9967 0.23479 12.9943 0.233128 12.991 0.231465C12.9877 0.229802 12.9836 0.231465 12.9803 0.229802C12.6513 0.0786957 12.2995 0 11.9338 0H1.42353C0.638088 0 0 0.63804 0 1.42353V22.5765C0 23.3619 0.63804 24 1.42353 24L15.9558 23.9998C16.7404 23.9998 17.3794 23.3618 17.3794 22.5763V22.3446H17.7684C18.4669 22.3446 19.0345 21.7761 19.0345 21.0785V14.5076C19.0345 13.8089 18.4659 13.2413 17.7684 13.2413L17.7682 13.2412ZM15.9666 4.1378H13.6551C13.4267 4.1378 13.2413 3.95233 13.2413 3.72401V1.41251L15.9666 4.1378ZM16.5516 22.5763C16.5516 22.9049 16.2843 23.1722 15.9556 23.1722H1.42333C1.0947 23.1722 0.82738 22.9049 0.82738 22.5763V1.42334C0.82738 1.09471 1.09468 0.827386 1.42333 0.827386H11.9336C12.0998 0.827386 12.2572 0.858789 12.4135 0.906081V3.72392C12.4135 4.40835 12.9704 4.96529 13.6549 4.96529H16.4736C16.52 5.11917 16.5514 5.2773 16.5514 5.44519V13.241H9.87257C9.75342 13.241 9.64332 13.1955 9.56149 13.1145L9.23045 12.7834C8.99215 12.5451 8.67423 12.4143 8.33416 12.4143L2.92084 12.4141C2.22237 12.4141 1.65474 12.9818 1.65474 13.6803V21.0788C1.65474 21.7773 2.22331 22.3449 2.92084 22.3449L16.5513 22.3451L16.5516 22.5763ZM18.2067 21.0785C18.2067 21.3201 18.0098 21.517 17.7682 21.517H2.92142C2.6798 21.517 2.48288 21.3201 2.48288 21.0785V13.6799C2.48288 13.4383 2.6798 13.2414 2.92142 13.2414H8.33379C8.45294 13.2414 8.56304 13.2868 8.64487 13.3679L8.97591 13.699C9.21421 13.9373 9.53213 14.0681 9.8722 14.0681H17.7683C18.0099 14.0681 18.2068 14.265 18.2068 14.5066L18.2067 21.0785Z"
      fill="#47BB92"
    />
    <path
      d="M2.89762 11.2713H10.3458C11.0303 11.2713 11.5872 10.7144 11.5872 10.03V3.40934C11.5872 2.72491 11.0303 2.16797 10.3458 2.16797H2.89762C2.21319 2.16797 1.65625 2.72491 1.65625 3.40934V10.03C1.65625 10.7144 2.21319 11.2713 2.89762 11.2713ZM2.48383 3.40934C2.48383 3.18102 2.6693 2.99555 2.89762 2.99555H10.3458C10.5742 2.99555 10.7596 3.18102 10.7596 3.40934V10.03C10.7596 10.2583 10.5742 10.4438 10.3458 10.4438H2.89762C2.6693 10.4438 2.48383 10.2583 2.48383 10.03V3.40934Z"
      fill="#47BB92"
    />
    <path
      d="M3.72237 9.61659H9.51543C9.74375 9.61659 9.92922 9.43112 9.92922 9.2028V8.37522C9.92922 8.26512 9.88544 8.16001 9.8084 8.08224L8.15325 6.42708C7.99179 6.26563 7.72948 6.26563 7.56822 6.42708L7.0327 6.96241L5.67051 5.60021C5.52402 5.45298 5.29403 5.43728 5.12927 5.56142L3.47411 6.80279C3.36992 6.88056 3.30859 7.00396 3.30859 7.13382V9.20277C3.30859 9.43109 3.49406 9.61656 3.72238 9.61656L3.72237 9.61659ZM4.13616 7.34074L5.33856 6.43873L6.73972 7.83989C6.90118 8.00135 7.16349 8.00135 7.32475 7.83989L7.86027 7.30509L9.10164 8.54646V8.789H4.13616V7.34074Z"
      fill="#47BB92"
    />
    <path
      d="M9.11518 5.34022C9.49498 5.34022 9.80458 5.03062 9.80458 4.65082C9.80458 4.27103 9.49497 3.96143 9.11518 3.96143C8.73539 3.96143 8.42578 4.27103 8.42578 4.65082C8.42578 5.03081 8.73446 5.34022 9.11518 5.34022ZM9.11518 4.51264C9.19129 4.51264 9.25336 4.57471 9.25336 4.65082C9.25336 4.80304 8.97774 4.80304 8.97774 4.65082C8.97701 4.57471 9.03907 4.51264 9.11518 4.51264Z"
      fill="#47BB92"
    />
  </svg>
);

export default JepgImage;
