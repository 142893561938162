import {
  Box,
  Button,
  Link,
  Typography,
  Grid,
  Stack,
  Avatar,
} from '@mui/material';
import CustomInputField from '../../components/customInputField';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  GetImageFromUrl,
  RESPONSE_STATUS,
} from '../../constants';
import HealthBuildingLogo from '../../Icons/healthBuildingLogo';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AppDrawer from '../../components/appDrawer';
import { useState } from 'react';
import Users from '../../services/users';
import { useContext } from 'react';
import UserContext from '../../contexts/user/context';

const SendInvite = ({ docData, anchor, setAnchor }) => {
  const { token } = useContext(UserContext);
  const [sendInviteLoading, setSendInviteLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [inviteError, setInviteError] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Email must be valid')
        .required('Email is required')
        .max(255, 'Email should be less than 255 characters'),
    }),
    onSubmit: async (values) => {
      const { email } = values;
      try {
        setSendInviteLoading(true);
        const response = await Users.sendInvite(
          token,
          email,
          `${docData?.firstName} ${docData?.lastName}`,
        );
        if (response.status === RESPONSE_STATUS.success) {
          setCompleted(true);
          formik.setFieldValue('email', '');
        }
        if (response.status === RESPONSE_STATUS.error) {
          setInviteError(response.message);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setSendInviteLoading(false);
      }
      // setAnchor(false);
    },
  });

  const handleClose = () => {
    setAnchor(false);
    formik.setTouched({}, false);
    formik.setFieldValue('email', '');
    if (inviteError) {
      setInviteError('');
    }
    setCompleted(false);
  };
  if (!docData) {
    return <></>;
  }
  const avatar = GetImageFromUrl(docData?.profile_picture_url || '');

  return (
    <AppDrawer
      title="Invite to medLynks"
      anchor={anchor}
      setAnchor={handleClose}
    >
      <form>
        <Grid container direction="column" sx={{ flexWrap: 'nowrap' }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap={1}
            sx={{
              p: 2,
              py: 4,
              my: 2,
              border: '1px solid #E2E2E2',
              borderRadius: '4px',
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ width: '100%', mb: 2 }}
            >
              <Avatar
                sx={{
                  width: '120px',
                  height: '130px',
                  justifyContent: 'center',
                  display: 'flex',
                }}
                src={avatar}
                variant="square"
              />
            </Stack>
            <Stack
              direction="column"
              sx={{ width: '75%', textAlign: 'center' }}
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{ width: '100%', textAlign: 'center' }}
                color={darkTextColor}
                variant="h5"
              >
                {CapitalizeFirstLetter(docData.firstName)}{' '}
                {CapitalizeFirstLetter(docData.lastName)}
                &nbsp;
                {docData?.preffix?.replaceAll(',', ', ') || ''}
              </Typography>

              <Typography
                color={darkTextColor}
                variant="body2"
                sx={{ opacity: '0.75', width: '100%', textAlign: 'center' }}
              >
                {docData.specialty}
              </Typography>
              <Stack>
                <Typography
                  sx={{ m: 0, p: 0 }}
                  color={darkTextColor}
                  variant="body2"
                >
                  {docData.street}
                </Typography>
                <Typography
                  sx={{ m: 0, p: 0 }}
                  color={darkTextColor}
                  variant="body2"
                >
                  {docData.city ? `${docData.city}, ` : ''}
                  {docData.state ? `${docData.state} ` : ''}{' '}
                  {docData.postalCode}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  mt: 1,
                  display: 'flex',
                  alignItem: 'center',
                  opacity: docData.location ? 1 : 0.56,
                  textAlign: 'center',
                }}
                color={docData.location ? '#615B99' : '#212634'}
                variant="body2"
              >
                <HealthBuildingLogo
                  fill={docData.location ? '#615B99' : '#212634'}
                />
                &nbsp;
                {docData.location || 'No healthcare employer found'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            container
            gap={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              border: '1px solid #E2E2E2',
              p: 2,
              borderRadius: '4px',
              mt: 2,
              flexWrap: 'nowrap',
            }}
          >
            <Grid container direction="column">
              {completed ? (
                <Typography color={darkTextColor} gutterBottom variant="body1">
                  You have successfully sent an invite to {docData?.firstName}{' '}
                  {docData?.lastName}!
                </Typography>
              ) : (
                <>
                  <Typography
                    color={darkTextColor}
                    gutterBottom
                    variant="body1"
                  >
                    Please enter {docData.firstName} {docData.lastName}’s work
                    email below
                  </Typography>
                  <CustomInputField
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    label="Work email"
                    fullWidth
                    width="100%"
                    name="email"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="text"
                    placeholder="example@email.com"
                    value={formik.values.email}
                  />
                  {Boolean(formik.touched.email && formik.errors.email) && (
                    <Typography color="error" gutterBottom variant="body2">
                      {formik.touched.email && formik.errors.email}
                    </Typography>
                  )}
                  {inviteError && (
                    <Typography
                      color="error"
                      gutterBottom
                      variant="body2"
                      // sx={{ position: 'absolute !important' }}
                    >
                      {inviteError}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid
        container
        gap={1.5}
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          flexWrap: 'nowrap',
          p: 2,
          mt: 1,
          position: 'absolute',
          bottom: '0px',
          right: 0,
          height: 'auto',
          width: '100%',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Button
          color="primary"
          size="large"
          onClick={() => {
            if (completed) {
              handleClose();
              setTimeout(() => {
                setCompleted(false);
                formik.setTouched({}, false);
              }, 500);
            } else {
              formik.handleSubmit();
            }
          }}
          disabled={sendInviteLoading}
          variant="contained"
          sx={{
            height: 40,
            width: '100%',
            '&:disabled': {
              color: 'grey !important',
            },
          }}
        >
          {completed ? 'Done' : 'Send'}
        </Button>
      </Grid>
    </AppDrawer>
  );
};

export default SendInvite;
