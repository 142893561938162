import React, { useContext, useState } from 'react';
import {
  Button,
  Typography,
  DialogActions,
  DialogContent,
  Chip,
} from '@mui/material';
import AlertDialog from '../../components/alertDialog';
import CustomInputField from '../../components/customInputField';
import { Stack } from '@mui/system';
import UserContext from '../../contexts/user/context';
import { darkTextColor, RESPONSE_STATUS } from '../../constants';
import Users from '../../services/users';
import SuccessPrompt from '../contactUs/successPrompt';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Feedback = (props) => {
  const { openDialog, setOpenDialog } = props;
  const { token } = useContext(UserContext);
  const [message, setMessage] = useState('');
  const [contantUsErr, setContantUsErr] = useState('');
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleDone = () => {
    setOpenDialog(false);
    setSuccess(false);
  };
  const handleClose = () => {
    setOpenDialog(false);
    setMessage('');
    setContantUsErr('');
  };

  // const handleChange = (value) => {
  //   formik.setFieldValue('selected', value);
  // };

  // const handleSendMessage = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await Users.sendMessage(token, message);

  //     if (response.status === RESPONSE_STATUS.success) {
  //       setMessage('');
  //       setContantUsErr('');
  //       setSuccess(true);
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      selected: null,
      comments: '',
    },
    validationSchema: Yup.object({
      selected: Yup.string().required('A category is required for flagging'),
      comments: Yup.string(),
    }),
    onSubmit: async (values) => {
      const { selected, comments } = values;
      try {
        setLoading(true);
        const response = await Users.sendMessage(
          token,
          `selected: ${selected}, comments: ${comments}`,
        );

        if (response.status === RESPONSE_STATUS.success) {
          setMessage('');
          setContantUsErr('');
          setSuccess(true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
  });

  const SelectedIssue = [
    'Technical Issues',
    'Referral Process',
    'Communication',
    'Responsiveness',
    'Core Quality',
    'Professionalism',
    'Other',
  ];

  return (
    <>
      <AlertDialog
        title="Report a bad encounter"
        open={openDialog}
        setOpen={() => handleClose()}
      >
        <DialogContent sx={{ mb: 3 }}>
          <Typography color={darkTextColor} gutterBottom variant="body1">
            We're sorry you had a negative experience using medLynks. Please
            select a category for your bad encounter and provide any additional
            details that may help us better understand your experience.
          </Typography>
          <Stack direction="row" flexWrap="wrap" gap={1}>
            {SelectedIssue.map((text) => (
              <Chip
                error={Boolean(formik.touched.email && formik.errors.email)}
                variant="outlined"
                sx={{ width: 'max-content', px: 2 }}
                color={formik.values.selected === text ? 'primary' : 'info'}
                label={text}
                name="selected"
                onClick={() => formik.setFieldValue('selected', text)}
              />
            ))}
          </Stack>
          <Stack direction="column" alignItems="flex-start" sx={{ my: 4 }}>
            <Typography color={darkTextColor} variant="body1">
              Additional details (optional)
            </Typography>
            <CustomInputField
              error={Boolean(formik.touched.email && formik.errors.email)}
              label="comments"
              name="comments"
              fullWidth
              width="100%"
              onChange={formik.handleChange}
              multiline
              placeholder="Describe shortly here"
              type="text"
              rows={8}
              value={formik.values.comments}
            />
            {Boolean(contantUsErr) && (
              <Typography
                color="error"
                gutterBottom
                variant="body2"
                sx={{ position: 'absolute !important' }}
              >
                {contantUsErr}
              </Typography>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            variant="outlined"
            width="100%"
            fullWidth
            sx={{ height: 40 }}
            disabled={loading}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            width="100%"
            fullWidth
            sx={{ height: 40 }}
            disabled={loading}
            onClick={() => {
              if (formik.values.selected) {
                formik.handleSubmit();
              } else {
                setContantUsErr('Selection can not be null');
              }
            }}
          >
            Submit Feedback
          </Button>
        </DialogActions>
      </AlertDialog>
      <SuccessPrompt handleDone={handleDone} openDialog={success} />
    </>
  );
};

export default Feedback;
