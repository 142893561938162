import { alpha, styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

const CustomTextField = styled(TextField)(
  ({ theme, width, padding = '2.5px 8px' }) => ({
    'label + &': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: '#FFFFFF',
      border: '0.1px solid #E2E2E2 !important',
      fontSize: 16,
      marginTop: '5px',
      width: `${width ? width : 'auto'}`,
      padding: padding,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      color: 'black',
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0)} 0 0 0 0.2rem`,
        border: '1px solid #47BB92',
        borderColor: theme.palette.primary.main,
      },
      // input: {
      //   padding: '0px',
      // },
    },
  }),
);

export const CustomTextFieldNoBorders = styled(TextField)(
  ({ theme, width, padding = '2.5px 8px' }) => ({
    'label + &': {
      marginTop: theme.spacing(0.5),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0px solid !important',
    },
    '& .MuiAutocomplete-clearIndicator': {
      borderRadius: 0,
      borderRight: '1px solid #B5B5B5 !important',
      // marginLeft: 1,
    },

    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      position: 'relative',
      boxShadow: 'none',
      border: '0px solid #E2E2E2 !important',
      fontSize: 16,
      // marginTop: '5px',
      width: `${width ? width : 'auto'}`,
      padding: padding,
      borderColor: theme.palette.primary.main,
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      color: 'black',
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0)} 0 0 0 0.2rem`,
        border: '0px solid #47BB92',
        borderColor: theme.palette.primary.main,
      },
      // input: {
      //   padding: '0px',
      // },
    },
  }),
);

export default CustomTextField;
