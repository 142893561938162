import Navbar from '../../components/navbar';
import {
  Box,
  Button,
  Link,
  Typography,
  Grid,
  Stack,
  Container,
  IconButton,
  Avatar,
  Card,
  Tooltip,
  Chip,
} from '@mui/material';
import { useContext, useState, useEffect, useRef } from 'react';
import UserContext from '../../contexts/user/context';
import { ArrowBack } from '@mui/icons-material';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  decryptNumber,
  encryptNumber,
  getAge,
  NotificationColors,
  getChipColor,
  getHealthCenterLocation,
  GetImageFromUrl,
  getStatusSubtitle,
  PatientStatus,
  prettyStatus,
  RESPONSE_STATUS,
} from '../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import HealthBuildingLogo from '../../Icons/healthBuildingLogo';
import Patients from '../../services/patients';
import Map from './map';
import PdfImage from '../../Icons/pdfImage';
import JepgImage from '../../Icons/jepgImage';
import CustomChip from '../../components/customChip';
import { CustomButton } from '../../components/customButton';
import ReviewPatientReferral from '../Home/reviewPatientReferral';
import FrowardsReferal from '../Home/reviewPatientReferral/forwardReferral';
import Notification from '../../components/notification';
import CancelDialog from '../../components/cancelDialog';
import MapUnRegistered from './mapUnRegistered';
import { LoadingCircularProgress } from '../../components/loadingCircularProgress';
import { LikeButton } from '../../components/endorsements/hoverEffect';
import InfoIcon from '../../Icons/infoIcon';
import Favorites from '../../services/favorites';
import FlagIcon from '@mui/icons-material/Flag';
import FlagFeedback from '../../components/flagFeedback/flagFeedback';
import { EndorseButton } from '../../components/endorseButton';

const getSpecialties = (data) => {
  if (data.length) {
    let specString = '';
    data.forEach((spec) => {
      if (spec.class === 'main') {
        if (specString) {
          specString += `, ${spec.specialty}`;
        } else {
          specString = spec.specialty;
        }
      }
    });
    return specString;
  }
  return '';
};

const checkFav = (favList, userId) => {
  if (favList.length) {
    const findFav = favList.find((f) => f.favoriteId === userId);
    if (findFav) {
      return true;
    }
  }
  return false;
};

function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string?.slice(1);
  }
  return '';
}

const getReferralStatus = (status) => {
  if (status === null) {
    return 'Pending';
  }
  if (status === 'accept') {
    return 'Accepted';
  }
  if (status === 'decline') {
    return 'Declined';
  }
  return capitalizeFirstLetter(status);
};

const UnRegisteredUserInfo = ({ data, user, docData }) => {
  return (
    <>
      <Typography color={darkTextColor} variant="h5" sx={{ fontWeight: 600 }}>
        {user.id === data.ReferredBy.id ? 'Referred to' : 'Referred by'}
      </Typography>
      <Grid
        container
        direction="row"
        alignItems="space-between"
        sx={{ flexWrap: 'nowrap', height: 'fit-content' }}
      >
        <Avatar
          sx={{
            width: '120px',
            height: docData.isRegistered ? '145px' : '130px',
            mr: 2.5,
            justifyContent: 'center',
            display: 'flex',
            borderRadius: '4px',
          }}
          src={GetImageFromUrl(docData?.doctorDetail[0].image_path || '')}
          variant="square"
        >
          {docData.first_name}
        </Avatar>
        <Stack
          direction="column"
          justifyContent="space-between"
          alignItems="space-between"
          gap={2}
        >
          <Stack direction="column" justifyContent="space-between" gap={1}>
            <Grid container direction="row" alignItems="center" gap={4}>
              <Typography
                sx={{ fontWeight: 600 }}
                color={darkTextColor}
                variant="h6"
              >
                {CapitalizeFirstLetter(docData.first_name)}&nbsp;
                {CapitalizeFirstLetter(docData.last_name)}
                &nbsp;
                {docData?.doctorDetail[0]?.suffix?.replaceAll(',', ', ')}
              </Typography>
            </Grid>
            <Typography
              color={darkTextColor}
              variant="body1"
              sx={{ opacity: '0.75', fontWeight: 400 }}
            >
              {docData.specialty}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                mt: 1,
                display: 'flex',
                alignItem: 'center',
                opacity: 1,
              }}
              color={docData.health_center ? '#615B99' : '#212634'}
              variant="body2"
            >
              <HealthBuildingLogo
                fill={docData.health_center ? '#615B99' : '#212634'}
              />
              &nbsp;
              {docData.health_center
                ? getHealthCenterLocation(docData.health_center)
                : 'No healthcare employer found'}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      {docData?.doctorDetail[0]?.bio ? (
        <Grid>
          <Typography
            sx={{ fontWeight: 600 }}
            color={darkTextColor}
            variant="h6"
          >
            About
          </Typography>
          <Typography sx={{ fontWeight: 400, my: 2 }} color={darkTextColor}>
            {docData?.doctorDetail[0]?.bio}
          </Typography>
        </Grid>
      ) : (
        <></>
      )}
      {docData?.doctorLocation && (
        <Grid sx={{ width: '100%' }}>
          <Typography
            sx={{ fontWeight: 600 }}
            color={darkTextColor}
            variant="h6"
          >
            Office Locations
          </Typography>
          <Stack
            direction="row"
            sx={{
              width: '100%',
              my: 2,
            }}
            spacing={2}
          >
            {docData?.doctorLocation?.length ? (
              <MapUnRegistered data={docData?.doctorLocation} />
            ) : (
              <></>
            )}
            {docData?.doctorLocation?.length ? (
              <>
                {docData?.doctorLocation.map((add) => {
                  if (add.primary === 'Y') {
                    return (
                      <div style={{ marginBottom: '10px' }}>
                        <Typography fontWeight="500" color={darkTextColor}>
                          {add.name}
                        </Typography>
                        <Typography color={darkTextColor}>
                          {add.city}, {add.state} {add.postal_code}
                        </Typography>
                      </div>
                    );
                  }
                })}
              </>
            ) : (
              <></>
            )}
          </Stack>
        </Grid>
      )}
    </>
  );
};

const Patient = () => {
  const {
    user,
    token,
    setUser,
    allReferrals,
    setAllReferrals,
    setAcceptReferralDialogue,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [data, setData] = useState();
  const params = useParams();
  const { id } = params;
  const realId = decryptNumber(id ? Number(id) : id);

  const [docData, setDocData] = useState();
  const [loading, setLoading] = useState(true);
  const [height, setHeight] = useState();
  const containerRef = useRef();
  const [isForward, setIsForward] = useState(false);
  const [referralStatus, setReferralStatus] = useState(false);
  const [openReviewPatient, setOpenReviewPatient] = useState(false);
  const [notificationData, setNotificationData] = useState({});
  const [cancel, setCancel] = useState(false);

  const [referralLoading, setReferralLoading] = useState(false);
  const [likes, setLikes] = useState(docData?.favoritesOff?.length || 0);
  const [endorsements, setEndorsements] = useState(docData?.endorsements || []);
  const [favLoading, setFavLoading] = useState(false);
  const [isFav, setIsFav] = useState();
  const [openFlag, setOpenFlag] = useState(false);
  const [flaggedDoc, setFlaggedDoc] = useState('');

  const updateFav = (favorites) => {
    const fav = checkFav(favorites, docData.id);
    setIsFav(fav);
  };

  useEffect(() => {
    if (docData && isFav === undefined && user) {
      const getFav = checkFav(user.favorites, docData.id);
      setIsFav(getFav);
    }
  }, [docData, user]);

  const AddFavorites = async () => {
    try {
      setFavLoading(true);
      const response = await Favorites.post(token, docData.id);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setLikes(likes + 1);
        const tempEndorsement = [...endorsements];
        tempEndorsement.unshift({
          createdAt: new Date(),
          doctorId: user.id,
          FavoriteBy: {
            firstName: user.firstName,
            lastName: user.lastName,
            prefix: user.prefix,
            profile_picture_url: user.profile_picture_url,
          },
        });
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  const RemoveFavorites = async () => {
    try {
      setFavLoading(true);

      const response = await Favorites.put(token, docData.id);
      if (response.status === RESPONSE_STATUS.error) {
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        updateFav(response.body.favorites);
        setLikes(likes - 1);
        const tempEndorsement = endorsements.filter(
          (end) => end.doctorId !== user.id,
        );
        setEndorsements(tempEndorsement);
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setFavLoading(false);
    }
  };

  const handleDrawer = (referralData, status) => {
    setReferralStatus(status);
    setOpenReviewPatient(true);
  };

  const updateStatus = async (
    status,
    reasonToDecline = '',
    additionalComments = '',
    referredTo = null,
    doctorName = '',
    selectedStaff,
    updatedStaff,
  ) => {
    try {
      setReferralLoading(true);

      const response = await Patients.put(
        token,
        data.id,
        status,
        reasonToDecline,
        additionalComments,
        referredTo,
        updatedStaff,
      );

      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        const updated = allReferrals.filter(
          (referral) => referral.id !== data.id,
        );
        setAllReferrals(updated);
        if (response?.patient) {
          const referral = response?.patient;
          if (user.id === referral.ReferredBy.id) {
            if (referral.ReferredTo) {
              setDocData(referral.ReferredTo);
            }
          } else {
            setDocData(referral.ReferredBy);
          }
          setData(referral);
        }
        if (status === PatientStatus.Accept) {
          setNotificationData({
            type: 'success',
            emailVerification: true,
            text: 'Patient referral accepted successfully. We will send the patient’s contact details to your office staff via email.',
          });
        }
        if (status === PatientStatus.Decline) {
          setNotificationData({
            type: 'error',
            emailVerification: true,
            text: 'Patient referral declined successfully.',
          });
        }
        if (status === PatientStatus.Referred) {
          setNotificationData({
            type: 'success',
            emailVerification: true,
            text: `Patient referral forwarded successfully. We will send a confirmation to ${doctorName}`,
          });
        }
        setAcceptReferralDialogue(false);

        setIsForward(false);
        setOpenReviewPatient(false);
        return;
      }
    } catch (e) {
      console.log(e.message);
      return;
    } finally {
      setReferralLoading(false);
    }
  };

  const getData = async () => {
    try {
      const patient = await Patients.getById(token, realId);
      if (patient) {
        const referral = patient?.body;
        if (user.id === referral.ReferredBy.id) {
          if (referral.ReferredTo) {
            setDocData(referral.ReferredTo);
            setLikes(referral.ReferredTo?.favoritesOff?.length || 0);
            setEndorsements(referral.ReferredTo?.favoritesOff || []);
          } else {
            setDocData(referral.unRegisteredUser);
          }
        } else {
          setDocData(referral.ReferredBy);
          setLikes(referral.ReferredBy?.favoritesOff?.length || 0);
          setEndorsements(referral.ReferredBy?.favoritesOff || []);
        }
        setData(referral);
      }
    } catch {
      console.log('error');
    } finally {
      setLoading(false);
    }
  };

  const updateHeight = () => {
    const elementHeight =
      window.innerHeight -
      containerRef.current.getBoundingClientRect().top -
      10;
    setHeight(elementHeight);
  };

  useEffect(() => {
    function handleResize() {
      updateHeight();
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (data && containerRef.current) {
      const getEle = document.getElementById('patient-details-container');
      if (getEle) {
        const elementHeight =
          window.innerHeight - getEle.getBoundingClientRect().top - 10;
        setHeight(elementHeight);
      }
    }
  }, [data, containerRef.current]);

  const handleCancelReferral = async (id) => {
    try {
      const response = await Patients.del(token, id);

      if (response.status === RESPONSE_STATUS.error) {
        // setErrorMessage(response.message);
        return;
      }
      if (response.status === RESPONSE_STATUS.success) {
        setAllReferrals(response?.body);
        navigate('/');
        if (response?.patient) {
          const referral = response?.patient;
          if (user.id === referral.ReferredBy.id) {
            setDocData(referral.ReferredTo);
          } else {
            setDocData(referral.ReferredBy);
          }
          setData(referral);
        }
        setAllReferrals(response?.body);
        return;
      }
    } catch (e) {
      console.log(e.message);
      return;
    }
  };

  const handleOpenFlag = (e) => {
    setOpenFlag(true);
  };

  const handleCloseFlag = (e) => {
    e.stopPropagation();
    setOpenFlag(false);
    setFlaggedDoc('');
  };

  useEffect(() => {
    if (realId && allReferrals && !data && user) {
      getData();
    }
  }, [realId, allReferrals]);

  if (!user || !data || !docData) {
    return <LoadingCircularProgress />;
  }

  return (
    <>
      <FlagFeedback
        flaggedDoc={flaggedDoc}
        openDialog={openFlag}
        setOpenDialog={setOpenFlag}
      />

      <CancelDialog
        open={cancel}
        setOpen={setCancel}
        handleCancel={handleCancelReferral}
        id={data.id}
      />
      <ReviewPatientReferral
        anchor={openReviewPatient}
        setAnchor={setOpenReviewPatient}
        referralData={data}
        user={user}
        updateStatus={updateStatus}
        accept={referralStatus}
        setIsForward={setIsForward}
        referralLoading={referralLoading}
      />

      <FrowardsReferal
        anchor={isForward}
        setAnchor={setIsForward}
        referralData={data}
        user={user}
        updateStatus={updateStatus}
        accept={referralStatus}
        isForward={isForward}
        setIsForward={setIsForward}
        referralLoading={referralLoading}
      />
      <Box
        sx={{
          height: '100vh',
          overflow: { sm: 'scroll', xs: 'scroll' },
          bgcolor: '#F7F7F7',
        }}
      >
        <Navbar />
        {notificationData?.type ? (
          <Notification
            notificationData={notificationData}
            setNotificationData={setNotificationData}
          />
        ) : (
          <></>
        )}
        <Container
          maxWidth="xl"
          sx={{
            p: { lg: 4, md: 2, sm: 2 },
            minHeight: '90vh',
          }}
        >
          <Stack direction="row" gap={1} sx={{ mb: 1 }}>
            <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
              <ArrowBack sx={{ color: darkTextColor }} />
            </IconButton>
            <Typography
              color={darkTextColor}
              variant="h5"
              sx={{ fontWeight: 600, fontSize: '2em' }}
            >
              Patient Referral &mdash;&nbsp;{data.firstname}&nbsp;
              {data.lastname}&nbsp;
            </Typography>
          </Stack>
          {data.status === null ? (
            <Grid
              container
              sx={{
                flex: 1,
                bgcolor: '#FFFFFF',
                // px: 2,
                border: '1px solid #E2E2E2',
                borderRadius: '4px',
                width: '100%',
                minHeight: '10%',
                position: 'relative',
                overflow: 'none',
                my: 2,
              }}
            >
              <Grid
                sx={{
                  flexWrap: 'nowrap',
                  py: 2,
                  px: 3,
                  width: '100%',
                }}
                justifyContent="space-between"
                alignItems="center"
                direction="row"
                container
                gap={1}
              >
                <Stack direction="row" gap={2}>
                  <CustomChip type={data.levelOfUrgency} />
                  {data.status === null && <CustomChip type={'Pending'} />}
                </Stack>
                {user.id === data?.referredToId ? (
                  <>
                    {!data.status ? (
                      <Grid
                        container
                        gap={2}
                        sx={{
                          width: 'min-content',
                          flexWrap: 'nowrap',
                        }}
                      >
                        <CustomButton
                          type="neutral"
                          size="large"
                          text="Forward"
                          disabled={!data.canForward}
                          onClick={() => {
                            setIsForward(true);
                          }}
                        />
                        <CustomButton
                          type="error"
                          size="large"
                          text="Decline"
                          onClick={() => handleDrawer(data, false)}
                        />
                        <CustomButton
                          size="large"
                          text="Accept"
                          onClick={() => handleDrawer(data, true)}
                        />
                      </Grid>
                    ) : (
                      <Typography variant="h6" color={darkTextColor}>
                        {prettyStatus(data.status)}
                      </Typography>
                    )}
                  </>
                ) : (
                  <CustomButton
                    size="large"
                    text="Cancel"
                    type="error"
                    onClick={() => setCancel(true)}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              sx={{
                flex: 1,
                bgcolor: '#FFFFFF',
                // px: 2,
                border: '1px solid #E2E2E2',
                borderRadius: '4px',
                width: '100%',
                minHeight: '10%',
                position: 'relative',
                overflow: 'none',
                my: 2,
              }}
            >
              <Grid
                sx={{
                  flexWrap: 'nowrap',
                  py: 2,
                  px: 3,
                  width: '100%',
                }}
                justifyContent="space-between"
                alignItems="center"
                direction="row"
                container
                gap={1}
              >
                <Stack
                  direction="row"
                  gap={2}
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <CustomChip type={data.levelOfUrgency} />
                  <Chip
                    label={getReferralStatus(data?.status)}
                    sx={{
                      color:
                        data?.status === null
                          ? NotificationColors.pending.color
                          : data?.status === 'accept'
                          ? NotificationColors.success.color
                          : data?.status === 'decline'
                          ? NotificationColors.error.color
                          : NotificationColors.dark.color,
                      backgroundColor:
                        data?.status === null
                          ? NotificationColors.pending.color
                          : data?.status === 'accept'
                          ? NotificationColors.success.bgcolor
                          : data?.status === 'decline'
                          ? NotificationColors.error.bgcolor
                          : NotificationColors.dark.bgcolor,
                      fontWeight: 450,
                      p: 0,
                      borderRadius: '4px',
                      height: '2.2em',
                      width: 'fit-content',
                      fontSize: '1em',
                    }}
                  />
                </Stack>

                <Stack direction="row" alignItems="center" spacing={2}>
                  {docData.deletedAt ? (
                    <></>
                  ) : (
                    <EndorseButton
                      doctorId={docData?.id}
                      totalLikes={docData?.favoritesOff?.length || 0}
                      doctorEndorsements={docData?.favoritesOff}
                    />
                  )}
                  <Button
                    aria-label="flag"
                    variant="outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      const elem =
                        data?.doctorId == user?.id
                          ? data?.ReferredTo
                          : data?.ReferredBy;
                      const flaggedDocName =
                        elem?.firstName + ' ' + elem?.lastName;
                      setFlaggedDoc(flaggedDocName);
                      handleOpenFlag();
                    }}
                    sx={{
                      borderRadius: 0,
                      border: '1px solid',
                      color: '#000000',
                      borderColor: NotificationColors.dark.bgcolor,
                      '& .MuiButton-startIcon': { margin: 0 },
                    }}
                    startIcon={<FlagIcon />}
                  ></Button>
                </Stack>
              </Grid>
            </Grid>
          )}
          <Grid
            direction={{ md: 'row', xs: 'column' }}
            gap={4}
            container
            sx={{ flexWrap: 'nowrap' }}
          >
            <Grid
              direction="column"
              container
              gap={1}
              sx={{
                flex: { lg: 1.9, md: 1.75 },
                overflowY: 'scroll',
                maxHeight: { xs: height ? `${height}px` : 'auto', sm: '100%' },
                flexWrap: 'nowrap',
              }}
            >
              <Typography
                color={darkTextColor}
                variant="h5"
                sx={{ fontWeight: 600 }}
              >
                Patient Details
              </Typography>
              <Stack
                direction={{ md: 'column', lg: 'row' }}
                gap={{ md: 2, lg: 10 }}
                sx={{ mt: 1 }}
              >
                <Stack direction="column" gap={0.5}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    sx={{ fontWeight: 600 }}
                  >
                    Patient
                  </Typography>
                  <Typography color={darkTextColor}>
                    {data.firstname}&nbsp;{data.lastname},&nbsp;
                    {getAge(data.dateOfBirth)}
                  </Typography>
                </Stack>
                <Stack direction="column" gap={0.5}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    sx={{ fontWeight: 600 }}
                  >
                    Sex
                  </Typography>
                  <Typography color={darkTextColor} variant="body1">
                    {CapitalizeFirstLetter(data?.sex)}
                  </Typography>
                </Stack>
                <Stack direction="column" gap={0.5}>
                  <Typography
                    color={darkTextColor}
                    variant="body1"
                    sx={{ fontWeight: 600 }}
                  >
                    Phone number
                  </Typography>
                  <Typography color={darkTextColor} variant="body1">
                    {data?.phoneNumber}
                  </Typography>
                </Stack>
                {data?.patientEmail ? (
                  <Stack direction="column" gap={0.5}>
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      sx={{ fontWeight: 600 }}
                    >
                      Email address
                    </Typography>
                    <Typography color={darkTextColor} variant="body1">
                      {data?.patientEmail}
                    </Typography>
                  </Stack>
                ) : (
                  <></>
                )}
              </Stack>
              <div
                style={{
                  margin: '25px 0px',
                  height: '2px',
                  width: '100%',
                  backgroundColor: '#E2E2E2',
                }}
              />
              <Stack direction="column" gap={3}>
                <Typography
                  color={darkTextColor}
                  variant="h5"
                  sx={{ fontWeight: 600 }}
                >
                  Referral Details
                </Typography>
                <Stack direction="row" gap={5} sx={{ mt: -2 }}>
                  <Stack
                    direction="column"
                    gap={0.5}
                    sx={{ maxWidth: '50%', minWidth: '50%' }}
                  >
                    <Typography
                      color={darkTextColor}
                      variant="body1"
                      sx={{ fontWeight: 600 }}
                    >
                      Chief complaint
                    </Typography>
                    <Typography
                      color={darkTextColor}
                      sx={{ overflowWrap: 'anywhere' }}
                      variant="body1"
                    >
                      {data?.reason}
                    </Typography>
                  </Stack>
                  <Stack direction="column" gap={1}>
                    <Stack direction="column" gap={0.5}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        sx={{ fontWeight: 600 }}
                      >
                        Level of urgency
                      </Typography>
                      <Typography
                        color={
                          getChipColor(data?.levelOfUrgency)?.color ||
                          darkTextColor
                        }
                        variant="body1"
                      >
                        {data?.levelOfUrgency}
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        variant="body2"
                        sx={{ opacity: 0.7 }}
                      >
                        {getStatusSubtitle(data?.levelOfUrgency) || ''}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="row" gap={5}>
                  <Stack direction="column" gap={3} sx={{ minWidth: '50%' }}>
                    <Stack direction="column" gap={0.5}>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        sx={{ fontWeight: 600 }}
                      >
                        History of present illness
                      </Typography>
                      <Typography
                        color={darkTextColor}
                        sx={{ overflowWrap: 'anywhere' }}
                        variant="body1"
                      >
                        {data?.history}
                      </Typography>
                    </Stack>

                    {data?.report ? (
                      <Stack direction="column" gap={0.5}>
                        <Typography
                          color={darkTextColor}
                          variant="body1"
                          sx={{ fontWeight: 600 }}
                        >
                          Diagnostic reports
                        </Typography>
                        <Typography color={darkTextColor} variant="body1">
                          {data?.report}
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}
                    {data?.additionalComments ? (
                      <Stack direction="column" gap={0.5}>
                        <Typography
                          color={darkTextColor}
                          variant="body1"
                          sx={{ fontWeight: 600 }}
                        >
                          Additional comments
                        </Typography>
                        <Typography color={darkTextColor} variant="body1">
                          {data?.additionalComments}
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}
                    {data.files ? (
                      <Stack direction="column" gap={0.5}>
                        <Typography
                          color={darkTextColor}
                          variant="body1"
                          sx={{ fontWeight: 600 }}
                        >
                          Attachments
                        </Typography>
                        <Grid
                          direction="row"
                          gap={2}
                          sx={{ mt: 0.5 }}
                          container
                        >
                          {data.files ? (
                            data.files.split(',').map((file) => (
                              <Card
                                sx={{
                                  p: 2,
                                  minWidth: '200px',
                                  width: 'fit-content',
                                  maxWidth: '350px',
                                }}
                              >
                                <Stack
                                  direction="row"
                                  sx={{ flexWrap: 'nowrap', width: '100%' }}
                                  gap={1}
                                  alignItems="center"
                                >
                                  {file.split('.').pop().toLowerCase() ===
                                  'pdf' ? (
                                    <PdfImage />
                                  ) : (
                                    <JepgImage />
                                  )}
                                  <Link
                                    // href={`${configuration.cdn.url}${file}`}
                                    href={file}
                                    // href={`${
                                    //   file?.split('?Expires=')?.length
                                    //     ? file?.split('?Expires=')[0]
                                    //     : file
                                    // }`}
                                  >
                                    <Typography
                                      color={darkTextColor}
                                      variant="body2"
                                      // onClick={()=> }
                                    >
                                      {file
                                        ?.split('?Expires=')[0]
                                        ?.replace(/^.*[\\\/]/, '')}
                                    </Typography>
                                  </Link>
                                </Stack>
                              </Card>
                            ))
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </Stack>
                  <Stack>
                    {data.forwardRef?.ReferredBy ? (
                      <Stack>
                        <Typography
                          color={darkTextColor}
                          variant="body1"
                          sx={{ fontWeight: 600 }}
                        >
                          Referred by
                        </Typography>
                        <Typography color={darkTextColor} variant="body1">
                          {CapitalizeFirstLetter(
                            data.forwardRef?.ReferredBy?.firstName,
                          )}
                          &nbsp;
                          {CapitalizeFirstLetter(
                            data.forwardRef?.ReferredBy?.lastName,
                          )}
                          &nbsp;
                          {data.forwardRef?.ReferredBy?.prefix?.replaceAll(
                            ',',
                            ', ',
                          )}
                        </Typography>
                      </Stack>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            {data.ReferredTo ? (
              <Grid
                container
                sx={{
                  flex: 1,
                  bgcolor: '#FFFFFF',
                  // px: 2,
                  p: 2,
                  border: '1px solid #E2E2E2',
                  borderRadius: '4px',
                  // maxHeight: '82vh',
                  position: 'relative',
                  overflow: 'scroll',
                  height: 'fit-content',
                  maxHeight: {
                    xs: height ? `${height}px` : 'auto',
                    sm: '100%',
                  },
                }}
                id="patient-details-container"
                ref={containerRef}
                alignContent="start"
                gap={2}
              >
                {data.forwardRef?.ReferredBy ? (
                  <Typography
                    color={darkTextColor}
                    variant="h5"
                    sx={{ fontWeight: 600 }}
                  >
                    {user.id === data.ReferredBy.id
                      ? 'Forwarded to'
                      : 'Forwarded by'}
                  </Typography>
                ) : (
                  <Typography
                    color={darkTextColor}
                    variant="h5"
                    sx={{ fontWeight: 600 }}
                  >
                    {user.id === data.ReferredBy.id
                      ? 'Referred to'
                      : 'Referred by'}
                  </Typography>
                )}
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  sx={{ flexWrap: 'nowrap', height: 'fit-content' }}
                >
                  <Avatar
                    sx={{
                      width: '120px',
                      height: docData.isRegistered ? '145px' : '130px',
                      mr: 2.5,
                      justifyContent: 'center',
                      display: 'flex',
                      borderRadius: '4px',
                    }}
                    src={GetImageFromUrl(docData?.profile_picture_url || '')}
                    variant="square"
                  >
                    {docData.firstName.split()[0]}
                  </Avatar>
                  <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="space-between"
                    gap={0}
                  >
                    <Stack
                      direction="column"
                      justifyContent="space-between"
                      gap={1}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        gap={1}
                      >
                        <Typography
                          sx={{ fontWeight: 600 }}
                          color={darkTextColor}
                          variant="h6"
                        >
                          {CapitalizeFirstLetter(docData.firstName)}&nbsp;
                          {CapitalizeFirstLetter(docData.lastName)}
                          &nbsp;
                          {docData?.prefix?.replaceAll(',', ', ')}
                        </Typography>
                        {docData.deletedAt ? (
                          <></>
                        ) : (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0}
                          >
                            <LikeButton
                              endorsements={endorsements}
                              RemoveFavorites={
                                isFav ? RemoveFavorites : AddFavorites
                              }
                              sx={{ width: 18, height: 18 }}
                              isFav={isFav}
                              likes={likes}
                              docData={docData}
                            />
                            <Tooltip title="Endorse" arrow>
                              <Box
                                sx={{
                                  mx: 0.5,
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <InfoIcon />
                              </Box>
                            </Tooltip>
                          </Stack>
                        )}
                      </Grid>
                      <Typography
                        color={darkTextColor}
                        variant="body1"
                        sx={{ opacity: '0.75', fontWeight: 400 }}
                      >
                        {getSpecialties(docData.specialties)}
                      </Typography>
                    </Stack>
                    <Stack>
                      <Typography
                        sx={{
                          mt: 1,
                          display: 'flex',
                          alignItem: 'center',
                          opacity: 1,
                        }}
                        color={docData.location_name ? '#615B99' : '#212634'}
                        variant="body2"
                      >
                        <HealthBuildingLogo
                          fill={docData.location_name ? '#615B99' : '#212634'}
                        />
                        &nbsp;
                        {docData.location_name
                          ? docData.location_name
                          : 'No healthcare employer found'}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                {docData.deletedAt ? (
                  <></>
                ) : (
                  <Stack direction="row" gap={2} sx={{ width: '100%' }}>
                    <Button
                      color="primary"
                      className="custom-button"
                      onClick={() =>
                        navigate(
                          `/messages?doctorId=${encryptNumber(docData.id)}`,
                        )
                      }
                      variant="contained"
                      sx={{
                        // height: 50,
                        width: '100%',
                        minHeight: 20,
                        height: '40px',
                        bgcolor: 'transparent',
                        border: '1px solid #525252',
                        color: '#525252',
                      }}
                    >
                      Send message
                    </Button>
                    <Button
                      color="primary"
                      className="custom-button"
                      onClick={() => {
                        const profileNumber = encryptNumber(docData.id);
                        navigate(`/doctor/${profileNumber}`);
                      }}
                      variant="contained"
                      sx={{
                        // height: 50,
                        width: '100%',
                        minHeight: 20,
                        height: '40px',
                        bgcolor: 'transparent',
                        border: '1px solid #525252',
                        color: '#525252',
                      }}
                    >
                      View full profile
                    </Button>
                  </Stack>
                )}
                {docData.about ? (
                  <Grid>
                    <Typography
                      sx={{ fontWeight: 600 }}
                      color={darkTextColor}
                      variant="h6"
                    >
                      About
                    </Typography>
                    <Typography
                      sx={{ fontWeight: 400, my: 2 }}
                      color={darkTextColor}
                    >
                      {docData.about}
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid sx={{ width: '100%' }}>
                  <Typography
                    sx={{ fontWeight: 600 }}
                    color={darkTextColor}
                    variant="h6"
                  >
                    Office Locations
                  </Typography>
                  <Stack
                    direction="row"
                    sx={{
                      width: '100%',
                      my: 2,
                    }}
                    spacing={2}
                  >
                    {docData?.address?.length ? (
                      <Map data={docData.address} />
                    ) : (
                      <></>
                    )}
                    {docData?.address?.length ? (
                      <>
                        {docData?.address.map((add) => {
                          if (add.is_primary) {
                            return (
                              <div style={{ marginBottom: '10px' }}>
                                <Typography
                                  fontWeight="500"
                                  color={darkTextColor}
                                >
                                  {add.name}
                                </Typography>
                                <Typography color={darkTextColor}>
                                  {add.formatted_address}, {add.state}{' '}
                                  {add.postal_code}
                                </Typography>
                              </div>
                            );
                          }
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                sx={{
                  flex: 1,
                  bgcolor: '#FFFFFF',
                  // px: 2,
                  p: 2,
                  border: '1px solid #E2E2E2',
                  borderRadius: '4px',
                  // maxHeight: '82vh',
                  position: 'relative',
                  overflow: 'scroll',
                  height: 'fit-content',
                  maxHeight: {
                    xs: height ? `${height}px` : 'auto',
                    sm: '100%',
                  },
                }}
                id="patient-details-container"
                ref={containerRef}
                alignContent="start"
                gap={2}
              >
                <UnRegisteredUserInfo
                  data={data}
                  user={user}
                  docData={docData}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Patient;
