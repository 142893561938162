import React, { useState, useRef } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import UserContext from '../../contexts/user/context';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from '../alertDialog';
import { darkTextColor } from '../../constants';

const inactivityTimerLimitMINS = 30;
const IdleTimerContainer = () => {
  const { token, handleLogout, setToken, setUser } = useContext(UserContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const sessionTimeoutRef = useRef(null);
  const navigate = useNavigate();

  const onIdle = () => {
    if (token) {
      sessionTimeoutRef.current = setTimeout(
        logOut,
        inactivityTimerLimitMINS * 1000 * 60,
      );
    }
  };

  const logOut = () => {
    sessionStorage.clear();
    setToken();
    setUser();
    navigate('/login');
    // setModalIsOpen(true);
    clearTimeout(sessionTimeoutRef.current);
  };
  const stayActive = () => {
    clearTimeout(sessionTimeoutRef.current);
  };
  const handleLogOut = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <IdleTimerProvider
        timeout={1000 * 5}
        onActive={() => stayActive()}
        onIdle={() => onIdle()}
      />
      <AlertDialog
        title="Inactive"
        open={modalIsOpen}
        setOpen={() => handleLogOut()}
      >
        <DialogContent sx={{ mb: 3, width: { md: '25vw', sm: '100%' } }}>
          <Typography color={darkTextColor} gutterBottom variant="body1">
            You've been idle for more than 30 minutes!
          </Typography>
          <Typography color={darkTextColor} gutterBottom variant="body1">
            You have been redirected to the login page.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            variant="contained"
            fullWidth
            sx={{ width: '10rem', height: 40 }}
            onClick={() => handleLogOut()}
          >
            Okay
          </Button>
        </DialogActions>
      </AlertDialog>
      {/* ) : (
        <></>
      )} */}
      {/* {modalIsOpen && (
        <Card>
          <h1 style={{ color: 'black' }}>Log out</h1>
          <button onClick={() => handleLogOut()}>logout</button>
        </Card>
      )} */}
      {/* <Modal open={modalIsOpen} onClose={() => console.log('close')}>
        <h2>You've been idle for a while!</h2>
        <p>You will be logged out soon</p>
        <>
          <button onClick={logOut}>Log me out</button>
          <button onClick={stayActive}>Keep me signed in</button>
        </>
      </Modal> */}
    </>
  );
};

export default IdleTimerContainer;
