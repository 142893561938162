import * as React from 'react';
import {
  Slide,
  Dialog,
  Grid,
  Typography,
  IconButton,
  Stack,
  Avatar,
  Card,
} from '@mui/material';
import {
  darkTextColor,
  GetImageFromUrl,
  RESPONSE_STATUS,
  useDebounce,
} from '../../constants';
import { useState } from 'react';
import SearchIcon from '../../Icons/serachIcon';
import { useContext } from 'react';
import UserContext from '../../contexts/user/context';
import Users from '../../services/users';
import { useEffect } from 'react';
import { useRef } from 'react';
import Close from '../../Icons/close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewMessage = (props) => {
  const { title, open, setOpen, getUser, allChats, updateSelected } = props;
  const [isFav, setIsFav] = useState(false);
  const [search, setSearch] = useState('');
  const [favArray, setFavArray] = useState([]);
  const [selected, setSelected] = useState();
  const [error, setError] = useState();
  const { token, user } = useContext(UserContext);
  const debouncedSearchQuery = useDebounce(search, 500);
  const inputRef = useRef();

  const [width, setWidth] = useState();
  const [hovering, setHovering] = useState();
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeSelection = (value) => {
    setSelected(value);
  };

  const clear = () => {
    setOpen(false);
    setSearch('');
    setSelected();
    setFavArray([]);
    setError('');
  };
  const handleAdd = (value) => {
    setSelected(value);

    const isPresent = allChats.find((chat) => chat?.user?.id === value);
    if (isPresent) {
      updateSelected(value, isPresent);
    } else {
      getUser(value);
    }
    clear();
  };

  const getFav = async () => {
    try {
      const query = `?primary=${' '}&isFavorite=true&notInclude=${user.id}`;
      const response = await Users.get(token, query);

      if (response.status === RESPONSE_STATUS.success) {
        setFavArray(response.body);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const searchDebounce = async (keyword) => {
    return await new Promise((resolve) =>
      setTimeout(async () => {
        if (search) {
          try {
            const query = `?primary=${search ? search : ' '}&notInclude=${
              user.id
            }&isAvailable=false`;
            const response = await Users.get(token, query);

            if (response.status === RESPONSE_STATUS.success) {
              setSelected();
              resolve({
                data: response.body,
              });
            }
          } catch (e) {
            console.log(e);
          } finally {
            //   setTimeout(() => {
            //     setLoading(false);
            //   }, 1000);
          }
        }
      }, 1000),
    );
  };

  useEffect(() => {
    getFav();
  }, []);

  useEffect(() => {
    searchDebounce(debouncedSearchQuery).then((response) => {
      setFavArray(response.data);
    });
    // if (search.length > 2 || !favArray.length) {
    // }
  }, [token, user, isFav, search, debouncedSearchQuery]);

  const handleResize = () => {
    const ele = document.getElementById('search-input-container');
    if (ele) {
      setWidth(ele?.getBoundingClientRect()?.width);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 500);
  }, []);

  useEffect(() => {
    const handleEnter = (e) => {
      if (e.code === 'Enter' || e.keyCode === 13) {
        return;
      }
    };
    window.addEventListener('keydown', handleEnter);

    return () => {
      window.removeEventListener('keydown', handleEnter);
    };
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {}}
        sx={{
          overflow: 'unset',
          '& .MuiPaper-root ': {
            overflow: 'unset',
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: '12px !important',
            p: 2,
            width: { md: '35vw', sm: '50vw' },
          },
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ py: 3, pb: 0, flexWrap: 'nowrap' }}
        >
          <Typography variant="h5" color={darkTextColor}>
            {title}
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Grid>
        <Grid
          container
          gap={1}
          sx={{
            borderRadius: '4px',
            minWidth: '20vw',
            py: 1,
            pb: 3,
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ flexWrap: 'nowrap', width: '100%' }}
          >
            <Typography
              variant="body1"
              sx={{ fontWeight: 600 }}
              color={darkTextColor}
            ></Typography>
          </Grid>
          <div
            className="forwardReferralSearch"
            id="search-input-container"
            onChange={(e) => {
              e.stopPropagation();
            }}
            onSubmit={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="loction-icon ">
              <SearchIcon />
            </div>
            <input
              id="search-input"
              ref={inputRef}
              value={search}
              onSubmit={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                setSearch(e.target.value);
                e.stopPropagation();
              }}
              type="search"
              className="forwardReferralSearchInput"
              placeholder="Search by specialist name"
            />
          </div>
          {favArray.length && (
            <Stack
              sx={{
                maxHeight: '30vh',
                overflowY: 'auto',
                width: 'auto',
                position: 'absolute',
                marginTop: '60px',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)',
                background: '#FFFFFF',
                width: width ? `${width}px` : 'auto',
                py: 1,
              }}
              gap={1}
            >
              {favArray.map((docData) => {
                const avatar = GetImageFromUrl(docData.profile_picture_url);
                const getLocation = docData.address.find(
                  (add) => add.is_primary,
                );
                return (
                  <Card
                    onClick={() => handleAdd(docData.id)}
                    sx={{
                      borderRadius: '0px',
                      boxShadow: 'none',
                      background: docData.id === hovering && '#DEF2EB',
                      cursor: 'pointer',
                    }}
                    onMouseEnter={() => setHovering(docData.id)}
                    onMouseLeave={() => setHovering(null)}
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="space-between"
                      sx={{
                        flexWrap: 'nowrap',
                        p: 2,
                        py: 1,
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="space-evenly"
                        sx={{ flexWrap: 'nowrap' }}
                      >
                        <Avatar
                          sx={{
                            width: '48px',
                            height: '52px',
                            mr: 2.5,
                            justifyContent: 'center',
                            display: 'flex',
                          }}
                          src={avatar}
                          variant="square"
                        />
                        <Grid
                          container
                          direction="column"
                          justifyContent="space-between"
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Grid
                              container
                              direction="row"
                              alignItems="center"
                              gap={4}
                            >
                              <Typography color={darkTextColor} variant="body1">
                                {docData.firstName} {docData.lastName}
                                &nbsp;
                                {docData.preffix}
                              </Typography>
                            </Grid>
                          </Stack>
                          <Stack direction="row">
                            <Stack direction="column"></Stack>
                            <Typography
                              color={darkTextColor}
                              variant="body2"
                              sx={{ opacity: '0.75' }}
                            >
                              {docData.specialties &&
                                docData.specialties
                                  .filter((spec) => spec.class === 'main')
                                  .map((specialty, index) => {
                                    return `${index !== 0 ? ', ' : ''}${
                                      specialty.specialty
                                    }`;
                                  })}
                            </Typography>
                            <Typography
                              color={darkTextColor}
                              variant="body2"
                              sx={{ opacity: '0.75', ml: 1 }}
                            >
                              • {getLocation?.city}, {getLocation?.state}
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })}
            </Stack>
          )}
          {Boolean(error) && (
            <Typography
              color="error"
              gutterBottom
              variant="body2"
              sx={{ position: 'relative !important' }}
            >
              {error}
            </Typography>
          )}
        </Grid>
      </Dialog>
    </div>
  );
};

export default NewMessage;
