import { URGENCY, NotificationColors, getChipColor } from '../../constants';
import { Chip } from '@mui/material';

const CustomChip = ({ type, sx }) => {
  const getColors = getChipColor(type);
  if (type) {
    return (
      <Chip
        label={type?.slice(0, 20)}
        sx={{
          color: getColors.color,
          backgroundColor: getColors.bgcolor,
          fontWeight: 500,
          p: 0,
          borderRadius: '4px',
          ...sx,
        }}
      />
    );
  }
  return <></>;
};

export default CustomChip;
