import Checkmark from '../Icons/checkmark';
import ErrorIcon from '../Icons/errorIcon';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import StarRateIcon from '@mui/icons-material/StarRate';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import configuration from '../config';
import { HospitalSystems } from './hospital_systems';
import { useEffect, useState } from 'react';
import { Specialties } from './specialties';

export const DOCTORS_ROUTE = 'doctors';
export const USERS_ROUTE = 'users';
export const AUTH_ROUTE = 'auth';
export const PATIENTS_ROUTE = 'patients';
export const FAVORITES_ROUTE = 'favorites';
export const DISTANCES_ROUTE = 'distances';
export const DOMAINS_ROUTE = 'domains';

export const STAFFS_ROUTE = 'staffs';
export const CHAT_ROUTE = 'chats';

export const DEFAULT_FOOTER_SIZE = 35;
export const MESSAGES_FOOTER_SIZE = 65;

export const DoctorStatus = [
  'Attending Physician',
  'Fellow',
  'Resident',
  'Nurse Practitioner',
  'Physician Assistant',
];

export const defaultAvailability = [
  '1-2 Weeks',
  '1 month',
  '2 months',
  '3 months',
  '6 months',
  'Custom',
];
export const RESPONSE_STATUS = {
  success: 'success',
  error: 'error',
};
// 'MD',
// 'DO',
// 'PhD',
// 'MBBS',
// 'MPH',
// 'MBA',
// 'DMD',
// 'DDS',
// 'BSN',
// 'RN',
// 'APRN',
// 'PA',
// 'MSc',

export const prefixList = [
  'MD',
  'DO',
  'PhD',
  'MBBS',
  'MPH',
  'DMD',
  'DDS',
  'MBA',
  'MSc',
  'DNP',
  'MSN',
  'PA-C',
];

export const PatientStatus = {
  Accept: 'accept',
  Decline: 'decline',
  Referred: 'referred',
};

export const EducationDataType = {
  MedicalScool: 'medical school',
  Residency: 'residency',
  Fellowship: 'fellowship',
};

export const handleFormatedAddress = (formatted_address, city) => {
  if (formatted_address) {
    const newAdd = formatted_address?.split(',')[0]?.replaceAll(city, '');
    if (newAdd) {
      return `${newAdd}, `;
    }
  }
  return '';
};

export const getHealthCenterLocation = (healthCenter) => {
  if (healthCenter) {
    return (
      HospitalSystems.find((hos) => hos.abbreviation === healthCenter)?.name ||
      ''
    );
  }
  return '';
};
export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay], // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export const getChipColor = (type) => {
  if (type === URGENCY.Urgent) {
    return NotificationColors.error;
  }
  if (type === URGENCY.SemiUrgent) {
    return NotificationColors.warning;
  }
  if (type === URGENCY.Routine) {
    return NotificationColors.dark;
  }
  return NotificationColors.pending;
};

export const getStatusSubtitle = (type) => {
  if (type === URGENCY.Urgent) {
    return 'Within 2 weeks';
  }
  if (type === URGENCY.SemiUrgent) {
    return 'Within 1 month';
  }
  if (type === URGENCY.Routine) {
    return 'Next available';
  }
  return '';
};
export const prettyStatus = (status) => {
  if (status === 'accept') {
    return 'Accepted';
  }
  if (status === 'decline') {
    return 'Declined';
  }
};

export const CapitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string?.slice(1);
  }
  return string;
};

export const DisplaySpecialties = (specialties) => {
  if (specialties) {
    const getSpecialties = specialties
      .filter((spec) => spec.class === 'main')
      .map((specialty, index) => {
        return `${specialty.specialty}`;
      });
    return getSpecialties.toString().replaceAll(',', ', ');
  }
  return '';
};

export const getSpecialties = (data) => {
  if (data.length) {
    let specString = '';
    data.forEach((spec) => {
      if (spec.class === 'main') {
        if (specString) {
          specString += `, ${spec.specialty}`;
        } else {
          specString = spec.specialty;
        }
      }
    });
    return specString;
  }
  return '';
};

export const DisplaySuffix = (suffix) => {
  if (suffix) {
    const getSuffix = suffix.map((pre, index) => {
      return `${index !== 0 ? `, ${pre}` : pre}`;
    });
    return getSuffix;
  }
  return '';
};

export const addPaddingZero = (time) => {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
};

export const GetImageFromUrl = (url) => {
  if (typeof url !== 'string') {
    return url;
  }
  if (!url) {
    return '';
  }
  if (url.includes('http')) {
    return url;
  }
  return `${configuration.cdn.urlDefaultImages}${url}`;
};

export const SuffixWithPeriod = (str) => {
  if (!str) {
    return '';
  }
  if (str?.charAt(str.length - 1) !== '.') {
    return ` ${str}`;
  }
  if (str) {
    return ` ${str}`;
  }
};

export const getEmployerList = () => {
  return HospitalSystems.map((hos) => hos.name);
};
export const getEmployer = (doctorLocation) => {
  return HospitalSystems.find((hos) => hos.name === doctorLocation);
};

export const getRangeOfYears = (
  startYear,
  currentYear = new Date().getFullYear(),
) => {
  const years = [];
  startYear = startYear || 1980;
  const yearNow = new Date().getFullYear();
  while (startYear <= currentYear) {
    if (startYear > yearNow) {
      years.push(`${startYear++} (expected)`);
    } else {
      years.push(`${startYear++}`);
    }
  }
  return years;
};
export const GetEmployerName = (doctorLocation) => {
  return (
    HospitalSystems.find((hos) => hos.abbreviation === doctorLocation)?.name ||
    ''
  );
};

export const GetPrimaryUserAddress = (addresses) => {
  return addresses.find((address) => address.is_primary) || '';
};

export const GetStandardisedSpecialties = (incoming) => {
  const generalize = Specialties.find(
    (specialties) => specialties.specialty === incoming,
  );
  if (generalize) {
    return generalize.Specialty;
  }
  return '';
};

function uniqueArray4(a) {
  return [...new Set(a)];
}

export const getUserSpcialty = (spec) => {
  if (spec) {
    let getSpec = [];
    Specialties.map((inco) => {
      if (inco?.Specialty) {
        if (inco?.Specialty === spec) {
          if (inco.Subspecialty.includes(',')) {
            getSpec.push(...inco.Subspecialty.split(','));
          } else {
            getSpec.push(inco.Subspecialty);
          }
        }
      }
    });

    return uniqueArray4(getSpec.filter((y) => y));
  }
  return '';
};

export const StableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const DescendingComparator = (a, b, orderBy) => {
  let aData = a[orderBy] ? a[orderBy] : 'Pending';
  let bData = b[orderBy] ? b[orderBy] : 'Pending';

  if (bData < aData) {
    return -1;
  }
  if (bData > aData) {
    return 1;
  }
  return 0;
};

export const LocationsArrayToString = (locations) => {
  if (locations.length) {
    const locArray = locations.map((element) => element.name);
    return locArray?.toString()?.replaceAll(',', ', ') || '';
  }
  return '';
};

export const getAge = (dateString) => {
  let now = new Date();
  let birthdate = new Date(dateString);
  let diffTime = Math.abs(now - birthdate);
  let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays === 1) {
    return '1 day old';
  }
  if (diffDays <= 31) {
    return `${diffDays} days old`;
  }
  if (diffDays <= 2 * 365 + 1) {
    let months = Math.floor(diffDays / 30.0);
    if (months === 1) {
      return '1 month old';
    }
    return `${months} months old`;
  }

  let ageInYears = Math.floor(diffDays / 365.0);
  return `${ageInYears} y.o.`;
};

export const getAddress = (data) => {
  if (data.length) {
    let address = data.find((spec) => spec.is_primary);
    return `${address.name}, ${address.city}, ${address.state} ${address.postal_code}`;
  }
  return '';
};

export const GetComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => DescendingComparator(a, b, orderBy)
    : (a, b) => -DescendingComparator(a, b, orderBy);
};

export const darkTextColor = '#212634';

export const encryptNumber = (number) => {
  if (typeof number === 'number') {
    let tempNumber = number;
    configuration.numberMultiplyer.array.forEach((num) => {
      tempNumber *= num;
    });
    return tempNumber;
  }
};

export const decryptNumber = (number) => {
  if (typeof number === 'number') {
    let tempNumber = number;
    configuration.numberMultiplyer.array.reverse().forEach((num) => {
      tempNumber /= num;
    });
    return tempNumber;
  }
};

export const handleSpeciality = (spec, status) => {
  if (status) {
    return `${spec} (${status})`;
  }
  return `${spec}`;
};

export const ReasonToDecline = [
  'Not my specialty/practice focus',
  'Scheduling difficulties',
];

export const URGENCY = {
  Routine: 'Routine',
  SemiUrgent: 'Semi-urgent',
  Urgent: 'Urgent',
};

export const NotificationColors = {
  error: {
    bgcolor: 'rgba(246,93,94, 0.12)',
    color: 'rgb(246,93,94)',
    borderColor: '#F1BDBD',
    icon: <ErrorIcon />,
  },
  success: {
    bgcolor: 'rgba(71,187,146, 0.95)',
    color: 'rgb(255,255,255)',
    icon: <Checkmark fill="rgb(71,187,146)" />,
  },
  warning: {
    bgcolor: 'rgba(	253, 152, 0, 0.12)',
    color: 'rgb(	253, 152, 0)',
    borderColor: '#FECB80',
    icon: <WarningAmberIcon style={{ color: 'rgb(246,93,94)' }} />,
  },
  pending: {
    bgcolor: 'rgba(63,52,141, 0.12)',
    color: 'rgb(63,52,141)',
    borderColor: 'rgba(63,52,141, 0.6)',
    icon: '',
  },
  dark: {
    bgcolor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0)',
    borderColor: 'rgba(0, 0, 0, 0.6)',
  },
  cancel: {
    bgcolor: 'rgba(47, 47, 47, 0.14)',
    color: '#2F2F2F',
  },
  endorse: {
    bgcolor: 'rgba(255, 186, 52, 0.0)',
    color: 'rgb(255, 186, 52)',
    borderColor: 'rgb(255, 186, 52)',
    icon: <StarBorderIcon style={{ color: 'rgb(255,186, 52)' }} />,
  },
};

export const TableHeaders = [
  {
    label: 'Patient',
    orderBy: 'firstname',
    bold: true,
    sorting: true,
  },
  {
    label: 'Urgency',
    orderBy: 'levelOfUrgency',
    bold: false,
    sorting: false,
  },
  {
    label: 'Status',
    orderBy: 'status',
    bold: false,
    sorting: false,
  },
  {
    label: 'Referred',
    orderBy: 'createdAt',
    bold: false,
    sorting: false,
  },
  {
    label: 'Chief Complaint',
    orderBy: 'reason',
    bold: false,
    sorting: false,
  },
  {
    label: ' ',
    orderBy: '',
    bold: false,
    sorting: false,
  },
];
