export const HospitalSystems = [
  {
    abbreviation: 'advocate',
    name: 'Advocate Aurora Health',
    locations: [
      'Advocate Christ Medical Center',
      'Advocate Good Samaritan Hospital',
      'Advocate Illinois Masonic Medical Center',
      'Advocate Lutheran General Hospital',
      'Advocate Sherman Hospital',
    ],
  },
  {
    abbreviation: 'community',
    name: 'Community Healthcare',
    locations: [],
  },
  {
    abbreviation: 'amita',
    name: 'AMITA Health (Ascension & AdventHealth)',
    locations: [
      'Ascension Alexian Brothers',
      'Ascension Resurrection',
      'Ascension Mercy',
      'Ascension St. Alexius',
      'Ascension St. Francis',
      'Ascension St. Joseph - Chicago',
      'Ascension St. Joseph - Elgin',
      'Ascension St. Joseph - Joliet',
      'Ascension St. Mary - Chicago',
      'Ascension St. Mary - Kankakee',
      'AdventHealth Bolingbrook',
      'AdventHealth GlenOaks',
      'AdventHealth Hinsdale',
      'AdventHealth LaGrange',
    ],
  },
  {
    abbreviation: 'chicago',
    name: 'UChicago Medicine',
    locations: [
      'University of Chicago Medical Center',
      "Comer Children's Hospital",
      'UChicago Medicine Ingalls Memorial',
    ],
  },
  {
    abbreviation: 'elmhurst',
    name: 'Edward-Elmhurst Health',
    locations: [
      'Edward Hospital',
      'Elmhurst Hospital',
      'Linden Oaks Behavioral Health',
    ],
  },
  {
    abbreviation: 'loyola',
    name: 'Loyola Medicine',
    locations: [
      'Loyola University Medical Center',
      'MacNeal Hospital',
      'Gottlieb Memorial Hospital',
    ],
  },
  {
    abbreviation: 'nm',
    name: 'Northwestern Medicine',
    locations: [
      'Northwestern Memorial Hospital',
      "Prentice Women's Hospital",
      'Northwestern Medicine Lake Forest Hospital',
      'Northwestern Medicine Central DuPage Hospital',
      'Northwestern Medicine McHenry Hospital',
      'Northwestern Medicine Woodstock Hospital',
      "Ann & Robert H. Lurie Children's Hospital of Chicago",
      'Northwestern Medicine Delnor Hospital',
      'Northwestern Medicine Huntley Hospital',
      'Northwestern Medicine Kishwaukee Hospital',
    ],
  },
  {
    abbreviation: 'northshore',
    name: 'NorthShore University HealthSystem',
    locations: [
      'Evanston Hospital',
      'Glenbrook Hospital',
      'Highland Park Hospital',
      'Northwest Community Hospital',
      'Skokie Hospital',
      'Swedish Hospital',
    ],
  },
  {
    abbreviation: 'rush',
    name: 'Rush University System for Health',
    locations: [
      'Rush University Medical Center',
      'Rush Oak Park Hospital',
      'Rush Copley Medical Center',
    ],
  },
  {
    abbreviation: 'uic',
    name: 'University of Illinois (UI) Health',
    locations: [
      'The University of Illinois Hospital & Health Sciences System',
      'Illinois Eye and Ear Infirmary',
    ],
  },
];
