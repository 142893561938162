import React, { useState, useEffect } from 'react';
import AutoComplete from 'react-autocomplete';
import { Avatar, Stack, Typography } from '@mui/material';
import {
  CapitalizeFirstLetter,
  darkTextColor,
  GetImageFromUrl,
  getUserSpcialty,
  RESPONSE_STATUS,
  useDebounce,
} from '../../constants';
import Users from '../../services/users';
import { specialtyFilters } from '../../constants/specialties_newest';

const DoctorRow = ({ doctor }) => {
  if (doctor?.header === 'Doctors') return <></>;
  const avatar = GetImageFromUrl(doctor.icon);
  return (
    <>
      <div
        style={{
          border: 'none',
          backgroundColor: 'none',
          padding: 0,
          dipslay: 'inline-block',
        }}
      >
        <Avatar
          sx={{
            width: '45px',
            height: '50px',
          }}
          src={avatar}
          variant="square"
        >
          {doctor.firstName.charAt(0)}
        </Avatar>
      </div>
      <Stack direction="column">
        <Typography variant="body1" color={darkTextColor}>
          {doctor.firstName}&nbsp;{doctor.lastName}
        </Typography>
        <Stack direction="row" gap={1}>
          <Typography variant="body2" color={darkTextColor}>
            {doctor?.specialty?.specialty}&nbsp;&nbsp;•
          </Typography>
          <Typography variant="body2" color={darkTextColor}>
            {doctor?.address?.city}, {doctor?.address?.state}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

let list = [];
const AutoCompleteSearch = ({
  isSub,
  setIsSub,
  name,
  setName,
  token,
  isDisabled,
  windowDimensions,
}) => {
  const [members, setMembers] = useState([]);
  const debouncedSearchQuery = useDebounce(name, 500);

  const [isSelected, setIsSelected] = useState();
  const [boundingClient, setBoundingClient] = useState();
  const [isSettingSub, setIsSettingSub] = useState();

  useEffect(() => {
    const tempMembers = members.filter(
      (mem) => mem?.header !== 'specialties (a-z)' && mem?.type !== 'specialty',
    );
    const specialtiesDataSorted = specialtyFilters.map((spec) => ({
      name: spec.name,
      type: 'specialty',
      childern: spec?.subspecialties || [],
    }));

    const subSpecialtiesDataSorted = specialtyFilters
      .filter((spec) => spec.subspecialties.length)
      .map((spec) =>
        spec?.subspecialties?.map((sub) => ({
          name: sub,
          displayName: `${sub} (${spec.name})`,
          type: 'subspecialty',
        })),
      )
      .flat();
    subSpecialtiesDataSorted.unshift({
      name: 'header',
      header: 'subspecialties (a-z)',
    });
    specialtiesDataSorted.unshift({
      name: 'header',
      header: 'specialties (a-z)',
    });
    // setMembers([...tempMembers, ...tempRes]);
    list = [
      ...specialtiesDataSorted,
      ...subSpecialtiesDataSorted,
      ...tempMembers,
    ];
    setMembers([
      ...specialtiesDataSorted,
      ...subSpecialtiesDataSorted,
      ...tempMembers,
    ]);
  }, []);

  const searchDebounce = async (keyword) => {
    return await new Promise((resolve) =>
      setTimeout(async () => {
        if (name) {
          try {
            const query = `?primary=${name ? name : ' '}`;
            const response = await Users.get(token, query);

            if (response.status === RESPONSE_STATUS.success) {
              resolve({
                data: response.body,
              });
            }
          } catch (e) {
            console.log(e);
          }
        }
      }, 1000),
    );
  };

  useEffect(() => {
    if (!isDisabled) {
      searchDebounce(debouncedSearchQuery).then((response) => {
        // setFavArray(response.data);
        if (response?.data?.length) {
          const tempMembers = members.filter(
            (mem) => mem?.header !== 'Doctors' && mem?.type !== 'Doctors',
          );
          const tempRes = response.data.map((doctor) => {
            const getAddress =
              doctor?.address.find((add) => add.is_primary) ||
              doctor?.address[0];
            const getSpecialty =
              doctor?.specialties.find((add) => add.class === 'main') ||
              doctor?.specialties[0];
            return {
              name: `${CapitalizeFirstLetter(
                doctor.firstName,
              )} ${CapitalizeFirstLetter(doctor.lastName)}`,
              firstName: CapitalizeFirstLetter(doctor.firstName),
              lastName: CapitalizeFirstLetter(doctor.lastName),
              icon: doctor.profile_picture_url,
              address: getAddress,
              specialty: getSpecialty,
              type: 'Doctors',
            };
          });
          if (tempRes.length) {
            tempRes.unshift({ name: 'header', header: 'Doctors' });
            setMembers([...list, ...tempRes]);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, name, debouncedSearchQuery, isDisabled]);

  const handleOpenNestedSelect = (e, item) => {
    if (item?.name !== isSelected?.name || !isSelected) {
      const getSubSpec = getUserSpcialty(item?.name);
      const subSpec = getSubSpec || [];
      if (subSpec.length) {
        setIsSelected(item);
        setBoundingClient(e.target.getBoundingClientRect());
      } else if (item.type === 'specialty' && item.childern) {
        setIsSelected(item);
        setBoundingClient(e.target.getBoundingClientRect());
      } else {
        setIsSelected();
        setBoundingClient();
      }
    }
  };

  const handleMouseLeave = () => {
    setIsSelected();
    setBoundingClient();
  };

  const handleChangeSelection = (value) => {
    if (isSettingSub) {
      setName(isSettingSub);
      setIsSub(true);
      setIsSettingSub();
      return;
    }
    setIsSub(false);
    setName(value);
  };

  return (
    <>
      <AutoComplete
        getItemValue={(item) => item.name}
        items={members.filter((member) => {
          if (
            member.name === 'header' &&
            member.header === 'specialties (a-z)'
          ) {
            if (
              members?.find(
                (x) =>
                  x?.name.toLowerCase().includes(name.toLowerCase()) &&
                  x.type === 'specialty',
              )
            ) {
              return member.name === 'header';
            }
          }
          if (
            member.name === 'header' &&
            member.header === 'subspecialties (a-z)' &&
            name.length > 2
          ) {
            if (
              members?.find(
                (x) =>
                  x?.name.toLowerCase().includes(name.toLowerCase()) &&
                  x.type === 'subspecialty',
              )
            ) {
              return member.name === 'header';
            }
          }
          if (member.name === 'header' && member?.header === 'Doctors') {
            if (
              members?.find(
                (x) =>
                  x?.name.toLowerCase().includes(name.toLowerCase()) &&
                  x.type === 'Doctors',
              )
            ) {
              return member.name === 'header';
            }
          }
          if (
            member?.header !== 'Doctors' &&
            member.type === 'subspecialty' &&
            name.length > 2
          ) {
            return (
              member.name.toLowerCase().includes(name.toLowerCase()) ||
              member.name === 'header' ||
              member?.childern?.find(
                (sub) => sub.toLowerCase() === name.toLowerCase(),
              )
            );
          }
          if (
            member?.header !== 'Doctors' &&
            member?.header !== 'specialties (a-z)' &&
            member?.header !== 'subspecialties (a-z)' &&
            member.type !== 'subspecialty'
          ) {
            return (
              member.name.toLowerCase().includes(name.toLowerCase()) ||
              member.name === 'header' ||
              member?.childern?.find(
                (sub) => sub.toLowerCase() === name.toLowerCase(),
              )
            );
          }
        })}
        renderItem={(item, isHighlighted) => (
          <div
            style={{
              background: 'white',
            }}
            onMouseOver={(e) => {
              if (isHighlighted) {
                handleOpenNestedSelect(e, item);
              }
            }}
          >
            {item.header ? (
              <Typography
                variant="body1"
                sx={{ opacity: 0.5, padding: '10px' }}
                color={darkTextColor}
              >
                {item.header}
              </Typography>
            ) : (
              <div
                style={{
                  verticalAlign: 'middle',
                  cursor: 'pointer',
                  backgroundColor: isHighlighted
                    ? 'rgba(71, 187, 146, 0.12)'
                    : 'white',
                  padding: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',
                  gap: 10,
                }}
              >
                {item.type === 'Doctors' ? (
                  <DoctorRow doctor={item} />
                ) : (
                  <div style={{ width: '100%' }}>
                    {item.type === 'subspecialty' ? (
                      <Typography
                        variant="body1"
                        color={isHighlighted ? '#185F45' : darkTextColor}
                      >
                        {item.displayName}
                      </Typography>
                    ) : (
                      <>
                        <Typography
                          variant="body1"
                          color={isHighlighted ? '#185F45' : darkTextColor}
                        >
                          {item.name}
                        </Typography>
                        {item?.childern?.length &&
                        boundingClient?.right &&
                        isHighlighted ? (
                          <div
                            style={{
                              verticalAlign: 'middle',
                              cursor: 'pointer',
                              backgroundColor: 'white',
                              margin: 0,
                              color: 'black',
                              alignItems: 'center',
                              position: 'fixed',
                              left: boundingClient.right,
                              top: boundingClient.y,
                              boxShadow: '3px 3px 4px 0px rgba(0,0,0,0.32)',
                            }}
                          >
                            {item?.childern?.map((sub) => (
                              <div
                                style={{
                                  background: 'white',
                                }}
                                onClick={() => {
                                  setIsSettingSub(sub);
                                }}
                              >
                                <div
                                  className="submenu-item"
                                  style={{
                                    verticalAlign: 'middle',
                                    cursor: 'pointer',
                                    backgroundColor: 'white',
                                    padding: 10,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: 10,
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    color={darkTextColor}
                                  >
                                    {sub}
                                  </Typography>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        wrapperStyle={{
          position: 'relative',
          padding: 0,
          margin: 0,
          width: '90%',
        }}
        menuStyle={{
          position: 'absolute',
          overflowX: 'visible',
          boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12)',
          zIndex: 2,
          top: '5vh',
          left: 0,
          maxHeight: '50vh',
          overflowY: 'scroll',
        }}
        value={name || ''}
        inputProps={{
          placeholder:
            windowDimensions?.width < 1250
              ? 'Specialty'
              : 'Specialty, subspecialty, doctor...',
          style: {
            position: 'relative',
            // flexGrow: 2,
            width: '100%',
            border: 0,
            // height: '6vh',
            padding: 10,
            background: 'white',
            fontSize: '14px',
            borderRadius: 4,
            outline: 0,
            borderRight: '1px solid rgba(233, 232, 235, 1)',
            // -webkit-appearance: none;
            marginLeft: 20,
            '&::-webkit-scrollbar': { backgroundColor: '#ffffff !important' },
          },
        }}
        onChange={(e) => {
          setIsSelected();
          if (e.target.value === 'header') return;
          setName(e.target.value);
        }}
        onSelect={(name, e, c) => {
          setIsSelected();
          if (name === 'header') return;
          handleChangeSelection(name);
          // if (subMenu && subMenu) setName(name);
        }}
      />
      {/* {isSelected && (
        <div
          style={{
            position: 'absolute',
            display: `${isSelected?.type !== 'Doctor' ? '' : 'none'}`,
          }}
        >
          <Typography variant="body1" color={darkTextColor}>
            {isSelected.name}
          </Typography>
        </div>
      )} */}
    </>
  );
};

export default AutoCompleteSearch;
