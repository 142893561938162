export const faq = [
  {
    question: 'How does the referral process work on the platform?',
    answer: [
      'You may efficiently navigate the directory using various filters (specialty / subspecialty, location, healthcare system / hospital, or distance). For active medLynks users, you may navigate to their profile to learn more about their credentials and expertise. From the user profile, you can easily initiate a referral – this requires basic patient information, the reason for referral and HPI, and the option to attach relevant reports.',
      'For scraped users in the directory that are not yet active on the platform, we encourage you to send an invite or even initiate a referral – please keep in mind, however, that a referral to an inactive user requires registration and onboarding before accepting.',
      `Our goal is to streamline the referral process, connect healthcare professionals digitally, and reduce referral leakage. After a referral has been sent, the recipient is notified via email. If accepted, the referral details will be forwarded via email to the specialist’s staff, with instructions to contact your patient and schedule an appointment.`,
    ],
  },
  {
    question:
      'What types of doctors and specialists are available on the platform?',
    answer: [
      `Currently, our directory is composed of physicians from most major medical specialties, across the largest healthcare employers in Chicago and its surrounding suburbs. It lacks smaller or private groups that are unaffiliated with the largest medical employers, as it is difficult to comprehensively gather this data.`,
      `The vision is for medLynks to expand into a comprehensive referral network for all healthcare professionals. This includes integrating allied health professionals that participate (via sending or receiving) any types of healthcare-related referrals. On that note, all healthcare professionals are welcome to join the platform. Additionally, expansion plans include integrating other locations across the country, particularly larger metropolitan areas.`,
    ],
  },
  {
    question:
      'Is the platform HIPAA compliant and secure for patient information?',
    answer: [
      `Our platform ensures HIPAA compliance through our multi-step encryption and security approach, powered by AWS. AWS is used by sites like Doximity and ZocDoc, providing firewall, VPN, and SSL certificates. The MedLynks team also takes an extra step to encrypt any messages, attachments, and patient information using the NSA’s most advanced encryption techniques in our own databases. In addition, we also provide (and recommend) users enable multi-factor authentication to their MedLynks account for added security.`,
    ],
  },
  {
    question:
      'How do I get started with using the platform for my referrals, and what are the costs involved?',
    answer: [
      `We are thrilled that you are interested in joining medLynks, and we are confident that this community will bring value to your practice. At this time, joining medLynks is free with no hidden costs or credit card information required.`,
      `To get started, please search for your name from the landing page. If your profile has been scraped, you can claim it and proceed through the registration process. If you cannot find your profile, you may create one. Whitelisted email addresses (associated with most major employers) simply require email verification; non-whitelisted email domains require identity verification by uploading a picture of your work badge or a document that confirms your identity as a healthcare provider.`,
      `We encourage you to fully complete the onboarding process, as this allows you to become a fully active user and enables you to begin accepting patient referrals. However, you may choose to partially complete the onboarding process (with an incomplete profile) and engage in limited access to the platform.`,
    ],
  },
  {
    question:
      'What insurance plans are accepted by the specialists on the platform?',
    answer: [
      `Accepted health insurance carriers (based on the physician’s official employer website) are associated with each unique profile. When onboarding, these carriers are automatically inputted but can be modified as the user sees fit. In the Find a Specialist search function, you may filter by insurance carrier to direct you more efficiently to a specialist that best fits your patient’s needs. Although most insurance carriers are represented, more granular health insurance plans are not yet integrated into the platform but will be added as we continue to scale.`,
    ],
  },
];
