import { Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import IgIcon from '../../Icons/igIcon';
import TwitterIcon from '../../Icons/fbIcon';
import { useState } from 'react';
import { PrivacyPolicy } from './privacyPolicy';
import { Terms } from './terms';
import { DEFAULT_FOOTER_SIZE, MESSAGES_FOOTER_SIZE } from '../../constants';

const twitterIcon = 'assets/twitterIcon.svg';

export const Footer = () => {
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);
  const handleOpenTerms = () => {
    if (openPrivacy) {
      setOpenPrivacy(false);
    }
    setOpenTerms(true);
  };

  const handleOpenPrivacy = () => {
    setOpenPrivacy(true);
  };
  return (
    <div
      style={{
        backgroundColor: '#2B7C60',
      }}
    >
      <PrivacyPolicy open={openPrivacy} setOpen={setOpenPrivacy} />
      <Terms open={openTerms} setOpen={setOpenTerms} />
      <Container maxWidth="xl">
        <Stack
          sx={{ width: '100%', px: 4, py: 4 }}
          direction="column"
          gap={2}
          justifyContent="space-between"
        >
          <Typography fontSize="12px">
            As further detailed in this website’s Terms of use, the content
            provided here and elsewhere on the medLynks site is provided for
            general information purposes only. Nothing contained on or offered
            by or through this website, including text messages, should be
            construed as medical advice and should not be relied upon for
            medical diagnosis or treatment. MedLynks does not recommend or
            endorse any particular healthcare provider whose information appears
            on this Website.
          </Typography>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" gap={3}>
              <Typography fontSize="16px" fontWeight="600">
                Copyright © 2023 medLynks, LLC. All Rights Reserved.
              </Typography>
              <Typography
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                fontSize="14px"
                onClick={() => handleOpenTerms()}
              >
                Terms of Use
              </Typography>
              <Typography
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                fontSize="14px"
                onClick={() => handleOpenPrivacy()}
              >
                Privacy Policy
              </Typography>
            </Stack>

            <Stack direction="row" gap={4} alignItems="center">
              {/* <a
                href="https://twitter.com/medLynks"
                target="_blank"
                rel="noreferrer"
                style={{ width: '26%' }}
              >
                <IgIcon />
              </a> */}
              <a
                href="https://twitter.com/medLynks"
                target="_blank"
                rel="noreferrer"
                style={{ width: '26%' }}
              >
                <TwitterIcon />
              </a>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};

export const FooterVerifiedUser = ({
  showText = false,
  position = 'fixed',
}) => {
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacy, setOpenPrivacy] = useState(false);

  const handleOpenTerms = () => {
    if (openPrivacy) {
      setOpenPrivacy(false);
    }
    setOpenTerms(true);
  };

  const handleOpenPrivacy = () => {
    setOpenPrivacy(true);
  };
  return (
    <div
      style={{
        backgroundColor: '#2B7C60',
        position: position,
        bottom: '0%',
        left: '0%',
        height: showText
          ? `${MESSAGES_FOOTER_SIZE}px`
          : `${DEFAULT_FOOTER_SIZE}px`,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <PrivacyPolicy open={openPrivacy} setOpen={setOpenPrivacy} />
      <Terms open={openTerms} setOpen={setOpenTerms} />

      <Container maxWidth="xl" sx={{ display: 'flex' }}>
        <Stack
          sx={{ width: '100%' }}
          direction="column"
          gap={0}
          justifyContent="space-between"
        >
          {showText ? (
            <Typography fontSize="10px">
              We expect all of our users to be respectful of our service and the
              participants, and to that end, we expect all users to comply with
              the guidelines set forth in the Terms of Use. Violation of those
              guidelines may be grounds for suspension or termination from the
              medLynks platform.
            </Typography>
          ) : (
            <></>
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" gap={3}>
              <Typography fontSize="12px" fontWeight="600">
                Copyright © 2023 medLynks, LLC. All Rights Reserved.
              </Typography>
              <Typography
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                fontSize="10px"
                onClick={() => handleOpenTerms()}
              >
                Terms of Use
              </Typography>
              <Typography
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                fontSize="10px"
                onClick={() => handleOpenPrivacy()}
              >
                Privacy Policy
              </Typography>
            </Stack>
            <Stack direction="row" gap={3} alignItems="center">
              <a
                href="https://www.instagram.com/medlynks/"
                target="_blank"
                rel="noreferrer"
                style={{ width: '26%' }}
              >
                <IgIcon width={26} />
              </a>
              <a
                href="https://twitter.com/medLynks"
                target="_blank"
                rel="noreferrer"
                style={{ width: '16%' }}
              >
                <TwitterIcon width={28} height={20} />
              </a>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
};
