import React, { useEffect, useRef, useState } from 'react';
import MarkerIcon from '../../Icons/markerIcon';
import { Typography, Grid, Card, Avatar, Stack } from '@mui/material';
import { darkTextColor, encryptNumber, GetImageFromUrl } from '../../constants';
import HealthBuildingLogo from '../../Icons/healthBuildingLogo';
import { useNavigate } from 'react-router-dom';

const getFill = (isRegistered, onFocus, onClicked, id) => {
  if (onFocus || onClicked === id) {
    return '#47bb92';
  }
  return isRegistered ? '#ED9C35' : '#83868D';
};
// transform: `translate(${transformX}%, -50%)`,
const cardPosition = {
  left: '10%,-50%',
  right: '-100%,-50%',
  top: '-50%, -100%',
  topSingle: '-50%, -100%',
  bottom: '-50%, 12%',
  bottomSingle: '-50%, 25%',
};

const Marker = ({
  key,
  index,
  text,
  tooltip,
  $hover,
  docData,
  setHover,
  onFocusCard,
  setOnClicked,
  onClicked,
  handleClickCard,
  handleInviteDoctor,
  hover,
  bounds,
  ...props
}) => {
  const navigate = useNavigate();
  const markerRef = useRef();
  const [transformX, setTransformX] = useState(10);
  const markerId = docData?.doctors[0]?.id;
  const handleClick = () => {
    if (markerId === onClicked) {
      setOnClicked();
      return;
    }
    setOnClicked(markerId);
  };
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (docData.doctors.find((doc) => doc.id === onFocusCard)) {
      setIsFocused(true);
    } else {
      setIsFocused(false);
    }
  }, [onFocusCard]);

  const handleMouseEnter = () => {
    const { lat, lng } = props;
    const mapContainer = document.getElementById('map-container');
    if (mapContainer && lat && lng && bounds) {
      const totalLng = bounds.lng[1] - bounds.lng[0];
      const totalLat = bounds.lat[1] - bounds.lat[0];

      const diffLng = lng - bounds.lng[0];
      const diffLat = lat - bounds.lat[0];

      const percentagePlacement = diffLng / totalLng;
      const percentagePlacementLat = diffLat / totalLat;
      // const rightSide = percentagePlacement * mapContainer.offsetWidth;

      const bottomSide = percentagePlacementLat * mapContainer.offsetHeight;
      const topSide = (1 - percentagePlacementLat) * mapContainer.offsetHeight;
      const leftSide = (1 - percentagePlacement) * mapContainer.offsetWidth;

      if (bottomSide < 150) {
        if (docData?.doctors?.length > 1) {
          setTransformX('top');
        } else {
          setTransformX('topSingle');
        }
      } else if (topSide < 150) {
        if (docData?.doctors?.length > 1) {
          setTransformX('bottom');
        } else {
          setTransformX('bottomSingle');
        }
      } else if (leftSide > 355) {
        setTransformX('left');
      } else {
        setTransformX('right');
      }
    }
    setHover(markerId);
  };
  return (
    <div
      style={{
        cursor: 'pointer',
        height: '100px',
        width: '100px',
        position: 'relative',
        zIndex: markerId === hover && 10000,
      }}
      onMouseLeave={() => setHover()}
    >
      {((markerId === hover && !onClicked) || onClicked === markerId) && (
        <div
          style={{
            // transform: `translate(${transformX}%, -50%)`,
            transform: `translate(${cardPosition[transformX]})`,
            // transform: `translate(-45%, 25%)`,

            position: 'absolute',
            overflowY: 'scroll',
            top: '5%',
            maxHeight: '290px',
            zIndex: 10000,
            display: 'flex',
            flexDirection: 'column',
            paddingRight: '5px',
            borderRadius: '4px',
          }}
        >
          {docData.doctors.map((doc) => {
            const avatar = GetImageFromUrl(doc.profile_picture_url);
            return (
              <div
                id="map-card"
                // sx={{
                //   display: 'flex',
                //   alignItems: 'center',
                //   p: 2,
                //   my: 1,
                //   // height: 'max-content',
                //   // minHeight: '130px',
                //   // maxHeight: '200px',
                //   width: '310px',
                //   // mb: 10,
                // }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px',
                  margin: '5px 5px',
                  background: '#FFFFFF',
                  minWidth: '310px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (doc.isRegistered) {
                    const profileNumber = encryptNumber(doc.id);

                    navigate(`/doctor/${profileNumber}`);
                    return;
                  }
                  handleInviteDoctor(doc);
                }}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="space-between"
                  sx={{
                    flexWrap: 'nowrap',
                    positon: 'relative',
                    height: 'max-content',
                  }}
                >
                  <Avatar
                    sx={{
                      width: '70px',
                      height: '90px',
                      mr: 1,
                      justifyContent: 'center',
                      display: 'flex',
                    }}
                    src={avatar}
                    variant="square"
                  >
                    {doc.firstName.split()[0]}
                  </Avatar>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-between"
                    sx={{ mt: -0.5 }}
                  >
                    <Typography color={darkTextColor} variant="h6">
                      {doc.firstName} {doc.lastName}&nbsp;
                      {doc.preffix}
                    </Typography>
                    <Typography
                      color={darkTextColor}
                      variant="body2"
                      sx={{ opacity: '0.75' }}
                    >
                      {doc.specialty}
                    </Typography>
                    {/* <Typography color={darkTextColor} variant="body2">
                {doc.address}
              </Typography> */}
                    <Stack>
                      {/* <Typography
                  sx={{ m: 0, p: 0 }}
                  color={darkTextColor}
                  variant="body2"
                >
                  {doc.street}
                </Typography> */}
                      <Typography
                        sx={{ m: 0, p: 0 }}
                        color={darkTextColor}
                        variant="body2"
                      >
                        {doc.city ? `${doc.city}, ` : ''}
                        {doc.state ? `${doc.state} ` : ''} {doc.postalCode}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{
                        mt: 1,
                        display: 'flex',
                        alignItem: 'center',
                        opacity: doc.location ? 1 : 0.56,
                      }}
                      color={doc.location ? '#615B99' : '#212634'}
                      variant="body2"
                    >
                      <HealthBuildingLogo
                        fill={doc.location ? '#615B99' : '#212634'}
                      />
                      &nbsp;
                      {doc.location || 'No healthcare employer found'}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </div>
      )}
      <div
        onClick={() => handleClick()}
        onMouseEnter={(e) => handleMouseEnter()}
        ref={markerRef}
        id="map-marker"
        style={{
          transition: 'all .1s linear',
          position: 'absolute',
          top: '5%',
          zIndex:
            isFocused || onClicked === markerId || markerId === hover ? 2 : 1,
          transform:
            isFocused || onClicked === markerId || markerId === hover
              ? 'scale(1.05)'
              : '',
        }}
      >
        <MarkerIcon
          fill={getFill(docData?.isRegistered, isFocused, onClicked, markerId)}
          width={isFocused || onClicked === markerId ? 26 : 23}
          height={isFocused || onClicked === markerId ? 35 : 32}
        />
        {docData?.doctors?.length > 1 ? (
          <Typography
            sx={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-55%,-60%)',
            }}
            // color={darkTextColor}
          >
            {docData.doctors.length}
          </Typography>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Marker;
