import { useEffect, useState, useContext } from 'react';
import {
  Box,
  Card,
  Typography,
  Stack,
  Select,
  FormControl,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { darkTextColor } from '../../constants';
import UserContext from '../../contexts/user/context';
import InsightBox from './insightBox';
import moment from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const options = [
  {
    text: 'Last 30 days',
    value: 30,
  },
  {
    text: 'Last 3 months',
    value: 91,
  },
  {
    text: 'Last 6 months',
    value: 183,
  },
  {
    text: 'Last year',
    value: 365,
  },
  {
    text: 'Show all',
    value: 1000000,
  },
];
const Insights = ({ data }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [responseTime, setResponseTime] = useState({ time: 0, text: 'Days' });

  const [filterTime, setFilterTime] = useState(183);
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user) {
      const _tempData = filteredData.filter(
        (patient) => patient.status && patient.referredToId === user.id,
      );
      if (_tempData.length) {
        let totalHouse = 0;
        _tempData.forEach((patient) => {
          var a = moment(patient.updatedAt);
          var b = moment(patient.createdAt);
          totalHouse += a.diff(b, 'hours');
        });
        let getData = moment
          .duration(totalHouse / _tempData.length, 'hours')
          .humanize();

        if (getData === 'a few seconds') {
        }
        setResponseTime({
          time: getData.split(' ')[0],
          text: getData.split(' ')?.slice(1).join(' '),
        });
      }
      setResponseTime({ time: 0, text: 'days' });
    }
  }, [filteredData, user]);

  useEffect(() => {
    if (data?.length) {
      const now = new Date();
      const filtered = data?.filter((d) => {
        const diff =
          Math.abs(new Date(d.createdAt).getTime() - now.getTime()) /
          (24 * 60 * 60 * 1000);
        if (diff < filterTime) {
          return d;
        }
      });
      setFilteredData(filtered);
    }
  }, [data, filterTime]);

  const handleChange = (event) => {
    setFilterTime(event.target.value);
  };

  return (
    <>
      <Box sx={{ p: 5, my: -4 }}>
        <Card sx={{ p: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color={darkTextColor} gutterBottom variant="h5">
              Insights
            </Typography>
            <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 180 }}>
              <Select
                value={filterTime}
                onChange={handleChange}
                displayEmpty
                sx={{
                  color: darkTextColor,
                  fontSize: 'body1',
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ color: darkTextColor }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                    >
                      <Tooltip title="Calendar">
                        <CalendarTodayIcon />
                      </Tooltip>
                      <span>{option.text}</span>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={{ xl: 10, md: 3 }}>
            <Box
              sx={{
                border: '1px solid #E2E2E2',
                flex: 2,
                borderRadius: '4px',
                borderLeft: '4px solid #E2E2E2',
              }}
            >
              <Stack
                sx={{ p: 2, height: '100%' }}
                diretion="column"
                justifyContent="space-between"
              >
                <Typography color="#000000" sx={{ opacity: 0.56 }}>
                  Avg. Response time
                </Typography>
                {filteredData && (
                  <>
                    <Typography
                      variant="h2"
                      color={darkTextColor}
                      sx={{ fontWeight: 400 }}
                    >
                      {responseTime.time}
                    </Typography>
                    <Typography
                      variant="h5"
                      color={darkTextColor}
                      sx={{ fontWeight: 400 }}
                    >
                      {responseTime.text}
                    </Typography>
                  </>
                )}
              </Stack>
            </Box>
            <InsightBox
              color="#144FD9"
              type="Sent"
              data={filteredData}
              user={user}
            />
            <InsightBox
              color="#47BB92"
              type="Received"
              data={filteredData}
              user={user}
            />
          </Stack>
        </Card>
      </Box>
    </>
  );
};

export default Insights;
