import React, { useEffect, useRef, useReducer, useState } from 'react';
function doSubmit(submittedValues) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1500);
  });
}

function clampIndex(index) {
  if (index >= 5) {
    return 5;
  } else if (index <= 0) {
    return 0;
  } else {
    return index;
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'INPUT':
      return {
        ...state,
        inputValues: [
          ...state.inputValues.slice(0, action.payload.index),
          action.payload.value,
          ...state.inputValues.slice(action.payload.index + 1),
        ],
        focusedIndex: clampIndex(
          action.payload.value !== ''
            ? clampIndex(state.focusedIndex + 1)
            : clampIndex(state.focusedIndex),
        ),
      };

    case 'BACK':
      return {
        ...state,
        inputValues: [...state.inputValues],
        status: state.status,
        focusedIndex: clampIndex(state.focusedIndex - 1),
      };

    case 'PASTE':
      return {
        ...state,
        inputValues: state.inputValues.map(
          (_, index) => action.payload.pastedValue[index] || '',
        ),
      };

    case 'FOCUS':
      return {
        ...state,
        focusedIndex: clampIndex(action.payload.focusedIndex),
      };

    case 'VERIFY':
      return {
        ...state,
        status: 'pending',
      };

    case 'VERIFY_SUCCESS':
      return {
        ...state,
        status: 'idle',
      };

    default:
      throw new Error('unknown action');
  }
}

const initialState = {
  inputValues: Array(6).fill(''),
  focusedIndex: 0,
  status: 'idle',
};

export const VerificationCodeField = ({
  verificationToken,
  setVerificationToken,
  loading,
  errMessageAuth,
}) => {
  const [{ inputValues, focusedIndex, status }, dispatch] = useReducer(
    reducer,
    initialState,
  );
  const [borderColor, setBorderColor] = useState('#47BB92');
  const [inputClass, setInputClass] = useState('auth-field-input');
  function handleInput(index, value) {
    dispatch({ type: 'INPUT', payload: { index, value } });
  }

  function handleBack(focusedIndex) {
    dispatch({ type: 'BACK' });
    handleFocus(focusedIndex - 1);
  }

  function handlePaste(pastedValue) {
    dispatch({ type: 'PASTE', payload: { pastedValue } });

    if (pastedValue.length === 6) {
      dispatch({ type: 'VERIFY' });
      doSubmit(pastedValue.split('')).then(() =>
        dispatch({ type: 'VERIFY_SUCCESS' }),
      );
    }
  }

  function handleFocus(focusedIndex) {
    dispatch({ type: 'FOCUS', payload: { focusedIndex } });
  }

  function handleSubmit(e) {
    e.preventDefault();

    dispatch({ type: 'VERIFY' });
    doSubmit(inputValues).then(() => dispatch({ type: 'VERIFY_SUCCESS' }));
  }

  useEffect(() => {
    setVerificationToken(inputValues.toString().replaceAll(',', ''));
  }, [inputValues]);

  useEffect(() => {
    if (errMessageAuth) {
      setBorderColor('#F65D5E');
    } else {
      setBorderColor('#47BB92');
    }
  }, [errMessageAuth]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="inputs">
        {inputValues.map((value, index) => {
          return (
            <Input
              key={index}
              index={index}
              value={value}
              disabled={loading}
              onChange={handleInput}
              onBackspace={(number) => handleBack(number)}
              onPaste={handlePaste}
              isFocused={index === focusedIndex}
              onFocus={(number) => handleFocus(number)}
              isDisabled={status === 'pending'}
              borderColor={borderColor}
            />
          );
        })}
      </div>
    </form>
  );
};

function Input({
  index,
  value,
  onChange,
  onPaste,
  onBackspace,
  isFocused,
  onFocus,
  isDisabled,
  disabled,
  borderColor,
}) {
  const ref = useRef();
  useEffect(() => {
    requestAnimationFrame(() => {
      if (ref.current !== document.activeElement && isFocused) {
        ref.current.focus();
      }
    });
  }, [isFocused]);

  function handleChange(e) {
    onChange(index, e.target.value);
  }

  function handlePaste(e) {
    onPaste(e.clipboardData.getData('text'));
  }

  function handleKeyDown(e) {
    if (e.key === 'Backspace') {
      onBackspace(index);
    }
  }

  function handleFocus(e) {
    if (isFocused) {
      e.target.setSelectionRange(0, 1);
      onFocus(index);
    }
  }

  return (
    <input
      ref={ref}
      type="text"
      className="input-verification"
      style={{
        border: value ? `2px solid ${borderColor}` : '1px solid #E2E2E2',
        color: borderColor,
        borderRadius: '4px',
      }}
      value={value}
      onChange={handleChange}
      onPaste={handlePaste}
      onKeyDown={handleKeyDown}
      maxLength={1}
      onFocus={(e) => handleFocus(e)}
      disabled={isDisabled || disabled}
    />
  );
}

export default VerificationCodeField;
