import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  DialogActions,
  DialogContent,
  Tooltip,
  MenuItem,
  Menu,
  Avatar,
  AvatarGroup,
  IconButton,
  Stack,
} from '@mui/material';
import Logo from '../../Icons/logo';
import Verification from '../../Icons/verification';
import AlertDialog from '../../components/alertDialog';
import CustomInputField from '../../components/customInputField';
import { LoadingButton } from '@mui/lab';

import UserContext from '../../contexts/user/context';
import { LoadingCircularProgress } from '../../components/loadingCircularProgress';
import Users from '../../services/users';
import {
  darkTextColor,
  GetImageFromUrl,
  RESPONSE_STATUS,
} from '../../constants';
import SuccessPrompt from '../../components/contactUs/successPrompt';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Dot from '../../components/navbar/dot';

const textColor = '#212634';

const settings = ['Contact us'];

const UserDetails = ({ user, setOpenDialog, handleLogout }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const avatar = GetImageFromUrl(user.profile_picture_url);
  const handleSettingClick = (setting) => {
    if (setting === settings[0]) {
      setAnchorElUser(false);
      setOpenDialog(true);
    }
  };
  return (
    <Stack sx={{ position: 'absolute', right: '2em' }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <AvatarGroup
            style={{
              border: '0.1px solid lightgray',
              borderRadius: '30%/50%',
            }}
          >
            <div style={{ width: '50px', height: '50px' }}>
              <Dot />
            </div>
            <Avatar
              alt="Remy Sharp"
              color="#696969"
              src={avatar}
              style={{ marginTop: '3.5%', marginRight: '3.5%' }}
            >
              {user?.firstName?.charAt(0)}
            </Avatar>
          </AvatarGroup>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px', padding: '10px' }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <Stack sx={{ px: 2, py: 1 }} direction="column">
          <Stack
            direction="column"
            alignItems="left"
            sx={{ textAlign: 'left', mb: 2 }}
          >
            <Typography color={darkTextColor} fontWeight={600}>
              {user.firstName} {user.lastName}
            </Typography>
            <Typography color={darkTextColor}>{user.email}</Typography>
          </Stack>
          <div
            style={{
              width: '100%',
              borderRadius: '4px',
              alignSelf: 'center',
              height: '1px',
              backgroundColor: '#D9D9D9',
              marginBottom: '5px',
            }}
          />
          {settings.map((setting) => (
            <MenuItem
              key={setting}
              onClick={() => handleSettingClick(setting)}
              sx={{ px: 0 }}
            >
              <Typography
                fontWeight={500}
                color={darkTextColor}
                textAlign="center"
              >
                {setting}
              </Typography>
            </MenuItem>
          ))}
          <div
            style={{
              width: '100%',
              borderRadius: '4px',
              alignSelf: 'center',
              height: '1px',
              backgroundColor: '#D9D9D9',
              marginBottom: '5px',
            }}
          />
          <MenuItem sx={{ px: 0 }} onClick={() => handleLogout()}>
            <Typography color="error" textAlign="center">
              Sign out
            </Typography>
          </MenuItem>
        </Stack>
      </Menu>
    </Stack>
  );
};
const WaitingVerification = (props) => {
  const { user, token, handleLogout } = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const [isTimeout, setIsTimeout] = useState(false);
  const [contantUsErr, setContantUsErr] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleDone = () => {
    setOpenDialog(false);
    setSuccess(false);
  };

  // useEffect(() => {
  //   if (user && user.email_verified && user.doctor_verified) {
  //     navigate('/overview');
  //   }
  // }, [navigate, token, user]);

  const handleResendVerificationEmail = async () => {
    try {
      setLoading(true);
      const response = await Users.resendVerificationEmail(token);

      if (response.status === RESPONSE_STATUS.success) {
        setIsTimeout(true);
        setTimeout(() => {
          setIsTimeout(false);
        }, 30000);
      }

      if (response.error === RESPONSE_STATUS.error) {
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (user) {
      if (user.email_verified && user.doctor_verified) {
        navigate('/overview');
      }
    }
  }, [user]);

  if (!user) {
    return <LoadingCircularProgress />;
  }

  let sendEmail = false;
  let text =
    'Thank you for signing up! We are working on confirming your identity and will send you an email within the next 24 hours.';

  if (!user?.email_verified) {
    sendEmail = true;
    text =
      'Thank you for signing up! Please confirm your email by clicking on the confirmation link sent to your email.';
  }

  if (!user.email_verified && !user.doctor_verified) {
    text =
      'Thank you for signing up! We are working on confirming your identity and will email you within the next 24 hours. Please confirm your email by clicking on the confirmation link sent to your email.';
  }

  const handleClose = () => {
    setOpenDialog(false);
    setMessage('');
    setContantUsErr('');
  };

  const handleSendMessage = async () => {
    if (!message) {
      setContantUsErr('Message can not be empty');
      return;
    }
    try {
      setLoading(true);
      const response = await Users.sendMessage(token, message);

      if (response.status === RESPONSE_STATUS.success) {
        setMessage('');
        setContantUsErr('');
        setSuccess(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AlertDialog
        title="Contact us"
        open={openDialog}
        setOpen={() => handleClose()}
      >
        <DialogContent sx={{ mb: 3 }}>
          <Typography color={textColor} gutterBottom variant="body1">
            How can we help you?
          </Typography>
          <CustomInputField
            label="About"
            fullWidth
            width="100%"
            multiline
            name="about"
            sx={{ width: { md: '25vw', sm: '100%' } }}
            onChange={(e) => setMessage(e.target.value)}
            type="text"
            rows={8}
            value={message}
          />
          {Boolean(contantUsErr) && (
            <Typography
              color="error"
              gutterBottom
              variant="body2"
              sx={{ position: 'absolute !important' }}
            >
              {contantUsErr}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            size="large"
            variant="contained"
            width="100%"
            fullWidth
            sx={{ height: 40 }}
            disabled={loading}
            onClick={() => {
              if (message) {
                handleSendMessage();
              } else {
                setContantUsErr('Message can not be empty');
              }
            }}
          >
            Send Message
          </Button>
        </DialogActions>
      </AlertDialog>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          minHeight: '100vh',
          minWidth: '100vw',
          backgroundSize: 'cover !important',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            flexWrap: 'nowrap',
            px: 10,
            py: 2,
            height: '10vh',
          }}
        >
          <Logo />
          <UserDetails
            user={user}
            setOpenDialog={setOpenDialog}
            handleLogout={handleLogout}
          />
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={2}
          sx={{
            height: '90vh',
            overflow: 'hidden',
            backgroundColor: '#F7F7F7',
          }}
        >
          <Verification />

          {!user.email_verified && !user.doctor_verified ? (
            <Typography
              color={textColor}
              variant="h5"
              sx={{ maxWidth: '60%', fontWeight: 600 }}
            >
              Waiting for identity verification and email confirmation
            </Typography>
          ) : (
            <Typography
              color={textColor}
              variant="h5"
              sx={{ maxWidth: '60%', fontWeight: 600 }}
            >
              {!user.email_verified
                ? 'Waiting for email confirmation'
                : 'Waiting for identity verification'}
            </Typography>
          )}
          <Typography
            color={textColor}
            variant="body2"
            sx={{ maxWidth: '30%', fontWeight: 600, textAlign: 'center' }}
          >
            {text}
          </Typography>
          <Stack spacing={2} direction="row">
            <Button
              size="large"
              onClick={() => setOpenDialog(!openDialog)}
              variant="contained"
              sx={{
                width: '10rem',
                height: 40,
                mr: 1.5,
                bgcolor: 'rgba(47, 47, 47, 0.14) !important',
                color: '#2F2F2F',
              }}
            >
              Contact us
            </Button>
            {sendEmail && (
              <LoadingButton
                color="primary"
                onClick={() => handleResendVerificationEmail()}
                size="large"
                type="submit"
                variant="contained"
                disabled={isTimeout}
                loading={loading}
                loadingPosition="end"
                sx={{ width: '12rem', height: 40 }}
              >
                Resend Email
              </LoadingButton>
            )}

            {/* <Button
              color="primary"
              size="large"
              onClick={() => handleContinue()}
              variant="contained"
              sx={{ width: '10rem', height: 40 }}
            >
              Go Home
            </Button> */}
          </Stack>
        </Grid>
      </Box>
      <SuccessPrompt handleDone={handleDone} openDialog={success} />
    </>
  );
};

export default WaitingVerification;
