const DefaultImage = () => (
  <svg
    width="92"
    height="92"
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0.5H120C121.933 0.5 123.5 2.067 123.5 4V120C123.5 121.933 121.933 123.5 120 123.5H4C2.06701 123.5 0.5 121.933 0.5 120V4C0.5 2.06701 2.067 0.5 4 0.5Z"
      fill="#F7F8FA"
      stroke="#E2E2E2"
    />
    <g opacity="0.56">
      <path
        d="M76.5007 78H46.8093C44.1558 78 42 75.8442 42 73.1907V56.0728C42 53.4193 44.1558 51.2636 46.8093 51.2636H53.9468L55.3842 46.8045C55.5377 46.3255 55.98 46 56.4837 46H66.827C67.3308 46 67.7731 46.3255 67.9266 46.8045L69.364 51.2636H76.5015C79.155 51.2636 81.3107 53.4193 81.3107 56.0728V73.1907C81.3102 75.8442 79.1544 78 76.5009 78H76.5007ZM46.8093 53.5789C45.4333 53.5789 44.3094 54.6968 44.3094 56.0788V73.1967C44.3094 74.5727 45.4273 75.6966 46.8093 75.6966H76.5007C77.8766 75.6966 79.0005 74.5787 79.0005 73.1967V56.0725C79.0005 54.6965 77.8826 53.5726 76.5007 53.5726H68.5222C68.0185 53.5726 67.5762 53.2471 67.4227 52.7681L65.9853 48.309H57.3245L55.8871 52.7681C55.7336 53.2471 55.2913 53.5726 54.7876 53.5726H46.8091L46.8093 53.5789Z"
        fill="black"
      />
      <path
        d="M61.6538 73.1846C56.9367 73.1846 53.0977 69.3457 53.0977 64.6284C53.0977 59.9111 56.9365 56.0723 61.6538 56.0723C66.3711 56.0723 70.21 59.9111 70.21 64.6284C70.21 69.3457 66.3711 73.1846 61.6538 73.1846ZM61.6538 58.388C58.2142 58.388 55.4136 61.1887 55.4136 64.6283C55.4136 68.0679 58.2142 70.8749 61.6538 70.8749C65.0934 70.8749 67.8941 68.0742 67.8941 64.6346C67.8941 61.195 65.0934 58.388 61.6538 58.388Z"
        fill="black"
      />
      <path
        d="M75.1693 57.6445H72.0922C71.4533 57.6445 70.9375 57.1285 70.9375 56.4899C70.9375 55.851 71.4536 55.3352 72.0922 55.3352H75.1693C75.8081 55.3352 76.3239 55.8513 76.3239 56.4899C76.3239 57.1287 75.8081 57.6445 75.1693 57.6445Z"
        fill="black"
      />
    </g>
  </svg>
);

export default DefaultImage;
