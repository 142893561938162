const WarningIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3711 5.88588C15.9682 4.93321 15.3912 4.07762 14.6569 3.34306C13.9224 2.60863 13.0667 2.03179 12.1141 1.62891C11.1275 1.21162 10.0799 1 9 1C7.92014 1 6.87246 1.21161 5.88588 1.62891C4.93321 2.03179 4.07762 2.60877 3.34306 3.34306C2.60863 4.07762 2.03179 4.93325 1.62891 5.88588C1.21162 6.87246 1 7.92014 1 9C1 10.0799 1.21161 11.1275 1.62891 12.1141C2.03179 13.0668 2.60877 13.9224 3.34306 14.6569C4.07762 15.3914 4.93325 15.9682 5.88588 16.3711C6.87246 16.7884 7.92014 17 9 17C10.0799 17 11.1275 16.7884 12.1141 16.3711C13.0668 15.9682 13.9224 15.3912 14.6569 14.6569C15.3914 13.9224 15.9682 13.0667 16.3711 12.1141C16.7884 11.1275 17 10.0799 17 9C17 7.92014 16.7884 6.87246 16.3711 5.88588V5.88588ZM8.99993 16.0577C5.10828 16.0577 1.94236 12.8916 1.94236 9.0001C1.94236 5.10862 5.10845 1.94254 8.99993 1.94254C12.8914 1.94254 16.0575 5.10862 16.0575 9.0001C16.0575 12.8916 12.8914 16.0577 8.99993 16.0577V16.0577Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
    <path
      d="M9.84843 12.2318C9.84843 12.7002 9.46868 13.0799 9.00039 13.0799C8.5321 13.0799 8.15234 12.7001 8.15234 12.2318C8.15234 11.7635 8.5321 11.3838 9.00039 11.3838C9.46868 11.3838 9.84843 11.7635 9.84843 12.2318Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
    <path
      d="M9.84847 5.30904V6.08723C9.84847 6.36561 9.83433 6.64708 9.80647 6.92411L9.50884 9.88667C9.48246 10.1488 9.26384 10.3465 9.00041 10.3465C8.73697 10.3465 8.51837 10.1488 8.49197 9.88667L8.19434 6.92411C8.16648 6.64708 8.15234 6.36547 8.15234 6.08723V5.30904C8.15234 5.09406 8.32747 4.91895 8.54244 4.91895H9.45834C9.67332 4.91895 9.84843 5.09407 9.84843 5.30904H9.84847Z"
      fill="#F65D5E"
      stroke="#F65D5E"
      strokeWidth="0.5"
    />
  </svg>
);

export default WarningIcon;
