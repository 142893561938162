import { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  DialogContent,
  DialogActions,
  InputAdornment,
  IconButton,
  Stack,
} from '@mui/material';
import * as Yup from 'yup';
import { RESPONSE_STATUS } from '../../../constants/index';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import User from '../../../services/users';
import CustomInputField from '../../../components/customInputField';
import AlertDialog from '../../../components/alertDialog';

const textColor = '#212634';

const CancelPrompt = (props) => {
  const {
    openDialog,
    setOpenDialog,
    setAnchor,
    formik,
    setFiles,
    formikReferralDetail,
    setPage,
    setDoctorEmail,
    formikDoctorEmail,
  } = props;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    setOpenDialog(false);
    formik.resetForm();
    formik.setTouched({}, false);
    formikReferralDetail.setTouched({}, false);
    formikReferralDetail.resetForm();
    formikDoctorEmail.setTouched({}, false);
    formikDoctorEmail.resetForm();
    setFiles([]);
    setDoctorEmail('');
    setAnchor(false);
    setPage(0);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <AlertDialog
        title="Are you sure you want to cancel this referral?"
        open={openDialog}
        setOpen={handleClose}
      >
        <DialogContent sx={{ my: -1, width: { md: 'auto', sm: '100%' } }}>
          <Typography color={textColor} gutterBottom variant="body1">
            If you cancel now, any information you've entered will be lost and
            the referral will not be completed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            // disabled={loading}
            fullWidth
            onClick={() => handleClose()}
            sx={{
              width: '10rem',
              height: 40,
              mr: 1.5,
              bgcolor: 'rgba(47, 47, 47, 0.14) !important',
              color: '#2F2F2F',
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            fullWidth
            sx={{ width: '10rem', height: 40 }}
            onClick={() => {
              handleCancel();
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </AlertDialog>
    </>
  );
};

export default CancelPrompt;
